<h4 class="close-button-panel position-relative" mat-dialog-title>
    <div class="float-start font-weight-bold">
        {{ 'order-list.add-to-order-item-title' | translate }}
    </div>
    <div class="float-end text-end close-button ms-2">
        <span class="pointer" (click)="dialogRef.close()">
            <fa-icon [icon]="faTimesCircle"></fa-icon>
        </span>
    </div>
    <div class="clearfix"></div>
</h4>
<div mat-dialog-content class="mb-5 overflow-hidden">
    <div class="md-5 lg-4 xl-6 text-center search-row order-10 mb-2 mb-md-0">
        <div
            class="input-group md-form form-sm form-2 ps-0 w-100 ms-auto me-auto mb-1 mb-md-0 search-group"
        >
            <ng-template #typeAheadTemplate let-model="item" let-index="index">
                <!--<p>{{mediaUrl}}{{model.MediaUrl}}</p>-->
                <div *ngIf="model.ProductID; else notAProduct">
                    <div class="typeahead-result" [id]="model.ProductID">
                        <ng-container
                            *ngIf="model.MediaUrl === mediaUrl + 'interface/thumbnail/'"
                        >
                            <img
                                src="assets/images/missing.jpg"
                                style="height: auto; margin-top: 5px; max-height: 25px"
                                onError="this.src='assets/images/roksh_logo.svg'"
                            />
                        </ng-container>
                        <ng-container
                            *ngIf="model.MediaUrl !== mediaUrl + 'interface/thumbnail/'"
                        >
                            <img
                                class="search-result-img"
                                [src]="model.MediaUrl"
                                onError="this.src='assets/images/roksh_logo.svg'"
                            />
                        </ng-container>

                        <div class="item-body">
                            <p class="item-heading" [innerHtml]="model.ProductName"></p>
                            <div
                                class="item-price"
                                *ngIf="
                                    model.IsBulk &&
                                        model.MinUnitPrice &&
                                        model.MinUnitPrice > 0;
                                    else notBulk
                                "
                            >
                                {{ model.MinUnitPrice | currencyFormatted }}
                                {{ 'common.currency' | translate }}/kg
                                <!---tól-->
                            </div>
                            <ng-template #notBulk>
                                <div class="item-price">
                                    {{ model.Price | currencyFormatted }}
                                    {{ 'common.currency' | translate }}
                                    <!---tól-->
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <ng-template #notAProduct>
                    <div>
                        <strong>{{ model.ProductName }}</strong>
                    </div>
                </ng-template>
            </ng-template>
            <input
                class="form-control search-inp my-0 py-1"
                type="text"
                placeholder="{{ 'common.search' | translate }}"
                [(ngModel)]="searchText"
                [typeaheadAsync]="true"
                [typeahead]="typeAheadResults"
                [typeaheadMinLength]="2"
                (typeaheadOnSelect)="typeaheadOnSelect($event)"
                typeaheadOptionField="ProductName"
                typeaheadWaitMs="100"
                [typeaheadItemTemplate]="typeAheadTemplate"
            />
            <div class="input-group-append">
                <span
                    class="input-group-text btn-turquoise provider-productsearch"
                    (click)="searchClick()"
                >
                    <fa-icon [icon]="faSearch"></fa-icon>
                </span>
            </div>
        </div>
    </div>
</div>
