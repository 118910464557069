import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
    BasketDto,
    BasketService,
    ConfigureSessionDto,
    ServiceBaseService,
    SessionService,
} from 'projects/library-shared/src/public-api';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-basket-provider',
    templateUrl: './basket-provider.component.html',
    styleUrls: ['./basket-provider.component.scss'],
})
export class BasketProviderComponent implements OnInit {
    @Input() basket: BasketDto;
    @Output() backToShopping = new EventEmitter<void>();

    siteImageUrl: string;

    constructor(
        public baseService: ServiceBaseService,
        private sessionService: SessionService,
        private router: Router,
        private translateService: TranslateService,
        private basketService: BasketService
    ) {}

    get basketPrice(): number {
        return this.basketService.calculateTotalPrice(this.basket);
    }

    get providerLogo(): string {
        return this.siteImageUrl + 'logos/' + this.basket.providerName + '-logo-s.png';
    }

    ngOnInit(): void {
        this.siteImageUrl = this.baseService.getSiteImageUrl();
    }

    selectShop(shop: string): void {
        this.backToShopping.emit();

        const configureSessionDto = new ConfigureSessionDto({
            userSelectedShops: [shop],
            redirectToDashboardNeeded: true,
        });

        this.sessionService.configureUserSession(configureSessionDto).subscribe(() => {
            this.translateService.get('router.dashboard').subscribe((dashboard) => {
                void this.router.navigate([this.basket.providerName, dashboard]);
            });
        });
    }
}
