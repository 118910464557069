<div id="deliveryTimePanel" [formGroup]="checkoutForm">
    <div formGroupName="deliveryTimeForm">
        <mat-expansion-panel
            style="margin-bottom: 5px"
            [disabled]="deliveryTimeStepIsDisabled"
            [class.activated-step]="deliveryTimeStepIsActive"
            [expanded]="deliveryTimeStepIsExpanded"
            (opened)="setStep(deliveryStepIndex)"
            hideToggle
        >
            <mat-expansion-panel-header
                class="checkout-panel-header"
                style="height: 90px !important; width: calc(100% - 10px)"
            >
                <div class="checkout-header-img">
                    <img
                        src="assets/svg/rokshImages/icons/checkout-clock.svg"
                        class="expansion-header"
                    />
                </div>
                <div class="column w-100">
                    <mat-panel-title>
                        {{ 'checkout.slot-data' | translate }}
                    </mat-panel-title>
                    <mat-panel-description class="col-12 checkout-header-description">
                        <ng-container *ngIf="deliveryDaysAvailable">
                            {{ deliveryDaysControl.value }}
                        </ng-container>
                        <ng-container *ngIf="deliveryTimeSlotsAvailable">
                            {{ deliveryTimeSlotsControl.value }}
                        </ng-container>
                    </mat-panel-description>
                </div>

                <img
                    src="assets/svg/valid.svg"
                    class="d-none"
                    style="margin: auto 10px; height: 1rem"
                />
            </mat-expansion-panel-header>

            <app-provider-timeslots
                *ngIf="zipIsValid"
                [selectionAllowed]="true"
                [availableTimeSlotDto]="availableTimeSlotsDto"
                [instantDeliveryAvailable]="instantDeliveryApproved"
                [areaHasInstantProvider]="areaHasInstantProvider$ | async"
                [postCode]="postCode"
                [instantTimeSlots]="instantWorktimes"
                [configuredInstantTimeSlots]="configuredInstantWorktimes"
                [isModifyDeliveryTime]="false"
                (onSetDeliveryTimeSlot)="setDeliveryTimeSlot($event)"
                (onTimeSlotDaySelection)="showAvailableTimeSlots($event)"
                (onSelectInstantDelivery)="selectInstantDelivery($event)"
            >
            </app-provider-timeslots>

            <mat-action-row class="text-center px-4 py-3">
                <span
                    mat-button
                    class="col-12 col-md-6 m-auto m-md-0 btn btn-tovabb btn-rounded mb-2"
                    (click)="nextStep()"
                >
                    {{ 'common.next' | translate }}
                </span>
            </mat-action-row>
        </mat-expansion-panel>
    </div>
</div>
