<div *ngIf="providers" class="overflow-y-scroll border-top">
    <div
        *ngFor="let provider of providers"
        (click)="handleChange(provider)"
        class="d-flex align-items-center py-3 border-bottom ps-4 hover"
    >
        <div
            class="provide-img border p-2 me-3 d-flex justify-content-between align-items-center"
        >
            <img
                src="{{ siteImageUrl }}logos/{{
                    provider.providerCode.toLowerCase()
                }}-logo-s.png"
                alt="logo"
                class="w-100 h-100"
            />
        </div>
        <h5 class="p-0 m-0">{{ provider.name }}</h5>
    </div>
</div>
