<h1 class="close-button-panel text-size-20" mat-dialog-title>
    <div class="text-end close-button">
        <span class="pointer" (click)="dialogRef.close()">
            <fa-icon [icon]="faTimesCircle"></fa-icon>
        </span>
    </div>
</h1>
<div class="row">
    <div class="row w-100">
        <mat-panel-title class="col-12">
            <strong class="w-100">
                <img class="expansion-header-svg" src="assets/svg/Kiszallitas.svg" />
                {{ 'checkout.delivery-data' | translate }}
            </strong>
        </mat-panel-title>
    </div>
    <img
        src="assets/svg/valid.svg"
        class="d-none"
        style="margin: auto 10px; height: 1rem"
    />

    <form class="col-12 justify-content-center" [formGroup]="deliveryAddressForm">
        <div class="row">
            <div class="clearfix"></div>
            <mat-form-field appearance="outline" class="field py-0 col">
                <mat-label>{{ 'common.post-code' | translate }}</mat-label>
                <input
                    formControlName="PostCode"
                    class="address-form-input"
                    matInput
                    maxlength="4"
                    placeholder="{{ 'common.post-code' | translate }}"
                    id="irsz"
                    required
                />
                <img *ngIf="zipIsValid" src="assets/svg/valid.svg" class="valid-icon" />
                <mat-error
                    *ngIf="
                        deliveryAddressForm.get('PostCode').errors &&
                        deliveryAddressForm.get('PostCode').touched
                    "
                >
                    {{ 'common.post-code-required' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="field py-0 col">
                <mat-label>{{ 'common.city-name' | translate }}</mat-label>
                <input
                    formControlName="City"
                    matInput
                    class="checkout-form-input"
                    placeholder="{{ 'common.city-name' | translate }}"
                    maxlength="49"
                    id="addr"
                    required
                />
                <img
                    *ngIf="addressValidated"
                    src="assets/svg/valid.svg"
                    class="valid-icon"
                />
                <mat-error
                    *ngIf="
                        deliveryAddressForm.get('City').errors &&
                        deliveryAddressForm.get('City').touched
                    "
                >
                    {{ 'common.city-name-required' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="field py-0 col">
                <mat-label>{{ 'common.street' | translate }}</mat-label>
                <input
                    formControlName="StreetName"
                    matInput
                    class="checkout-form-input"
                    placeholder="{{ 'common.street' | translate }}"
                    maxlength="49"
                    id="addr"
                    required
                />
                <img
                    *ngIf="addressValidated"
                    src="assets/svg/valid.svg"
                    class="valid-icon"
                />
                <mat-error
                    *ngIf="
                        deliveryAddressForm.get('StreetName').errors &&
                        deliveryAddressForm.get('StreetName').touched
                    "
                >
                    {{ 'common.street-required' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="field py-0 col">
                <mat-label>{{ 'common.housenumber' | translate }}</mat-label>
                <input
                    formControlName="HouseNumber"
                    matInput
                    class="checkout-form-input"
                    placeholder="{{ 'common.housenumber' | translate }}"
                    maxlength="49"
                    id="addr"
                    required
                />
                <img
                    *ngIf="addressValidated"
                    src="assets/svg/valid.svg"
                    class="valid-icon"
                />
                <mat-error
                    *ngIf="
                        deliveryAddressForm.get('HouseNumber').errors &&
                        deliveryAddressForm.get('HouseNumber').touched
                    "
                >
                    {{ 'common.housenumber-required' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-12 p-0">
            <mat-action-row *ngIf="isShowNextButton" class="text-center w-100">
                <span
                    mat-button
                    class="m-md-0 btn btn-tovabb btn-rounded"
                    (click)="nextStep()"
                    >{{ 'common.next' | translate }}</span
                >
            </mat-action-row>
        </div>
    </form>
</div>
