import { NgModule } from '@angular/core';
import { AddToCartButtonComponent } from './add-to-cart/add-to-cart-button.component';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { ProductDtoCardComponent } from './product-dto-card/product-dto-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { DiscountBadgeComponent } from './discount-badge/discount-badge.component';
import { BasketItemComponent } from './basket-item/basket-item.component';
import { BasketComponent } from './basket/basket.component';
import { MatMenuModule } from '@angular/material/menu';
import { HtmlMarketingModule } from '../html-marketing/html-marketing.module';

@NgModule({
    declarations: [
        AddToCartButtonComponent,
        ProductDtoCardComponent,
        DiscountBadgeComponent,
        BasketItemComponent,
        BasketComponent,
    ],
    exports: [
        AddToCartButtonComponent,
        ProductDtoCardComponent,
        DiscountBadgeComponent,
        BasketItemComponent,
        BasketComponent,
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        TranslateModule,
        IonicModule,
        LazyLoadImageModule,
        SharedPipesModule,
        MatMenuModule,
        HtmlMarketingModule,
    ],
})
export class ProductModule {}
