import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from '../snackbar/snackbar.service';
import { GeoLocation } from '../../models/geolocation/geolocation';
import { ValidatedAddress } from '../../models/address/validatedAddress';
import { ServiceBaseService } from '../service-base.service';
import { AddressElements } from '../../models/address/addressElements';

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    private shopServiceUrl: string;
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    defaultLocation: GeoLocation = new GeoLocation(19.040236, 47.497913);

    constructor(
        private http: HttpClient,
        private serviceBaseService: ServiceBaseService,
        private translate: TranslateService,
        private snackBarService: SnackBarService
    ) {
        this.shopServiceUrl = this.serviceBaseService.getBaseUrl();
    }

    getLocation(address: string): Observable<string> {
        return this.http.get<string>(this.shopServiceUrl + `order/GetLocation`, {
            headers: this.headers,
            params: { address: address },
        });
    }

    validateAddress(
        postCode: string,
        cityName: string,
        streetName: string,
        houseNumber: string
    ): Observable<ValidatedAddress> {
        const addressToQuery =
            postCode + ' ' + cityName + ' ' + streetName + ' ' + houseNumber;
        const scrWidth = window.innerWidth;
        return this.getLocation(addressToQuery).pipe(
            map((res) => {
                const googleRes = JSON.parse(res);
                if (googleRes) {
                    //If the user's address not valid at all
                    if (
                        googleRes == null ||
                        googleRes.length == 0 ||
                        !googleRes.Results[0]
                    ) {
                        this.translate
                            .get('checkout.invalid-address')
                            .subscribe((text) =>
                                this.snackBarService.openErrorSnackBar(text)
                            );

                        if (scrWidth < 992) window.scroll(0, 0);
                        return new ValidatedAddress(false, this.defaultLocation);
                    }

                    const { pCode, street_name, city, streetNumberStr } =
                        this.getAddressElements(googleRes);

                    //If the user's address not on the zioCode
                    if (pCode != postCode) {
                        this.translate
                            .get('checkout.address-is-not-on-postcode')
                            .subscribe((text) =>
                                this.snackBarService.openErrorSnackBar(text)
                            );

                        if (scrWidth < 992) window.scroll(0, 0);
                        return new ValidatedAddress(false, this.defaultLocation);
                    }

                    const location = new GeoLocation(
                        googleRes.Results[0].Geometry.Location.Lng,
                        googleRes.Results[0].Geometry.Location.Lat
                    );

                    //If the user's address not on the streetName
                    if (!street_name) {
                        this.translate
                            .get('error.street-name')
                            .subscribe((text) =>
                                this.snackBarService.openErrorSnackBar(text)
                            );

                        if (scrWidth < 992) window.scroll(0, 0);
                        return new ValidatedAddress(false, this.defaultLocation);
                    }

                    const newAddress =
                        city +
                        ' ' +
                        streetName +
                        ' ' +
                        (streetNumberStr !== null ? streetNumberStr : houseNumber);
                    return new ValidatedAddress(
                        true,
                        location,
                        newAddress,
                        street_name,
                        city
                    );
                } else {
                    return new ValidatedAddress(false, this.defaultLocation);
                }
            }),
            catchError(() => {
                return of(new ValidatedAddress(false, this.defaultLocation));
            })
        );
    }

    geoCode(): Observable<AddressElements> {
        return new Observable((observer) => {
            this.getCurrentPosition().subscribe((positionResponse) => {
                if (positionResponse.coords !== undefined) {
                    const userPosition = new GeoLocation(
                        positionResponse.coords.latitude,
                        positionResponse.coords.longitude
                    );

                    this.reverseGeocode(
                        userPosition.latitude,
                        userPosition.longitude
                    ).subscribe((res) => {
                        const googleRes = JSON.parse(res);
                        if (googleRes) {
                            const { pCode, street_name, city, streetNumberStr } =
                                this.getAddressElements(googleRes);

                            const address: AddressElements = {
                                postCode: pCode,
                                city: city,
                                streetName: street_name,
                                houseNumber: streetNumberStr,
                            };

                            observer.next(address);
                        } else {
                            observer.next(null);
                        }
                    });
                } else {
                    observer.next(null);
                }
            });
        });
    }
    geoCodeForMobile(latitude: number, longitude: number): Observable<AddressElements> {
        return new Observable((observer) => {
            if (latitude && longitude) {
                const userPosition = new GeoLocation(latitude, longitude);

                this.reverseGeocode(
                    userPosition.latitude,
                    userPosition.longitude
                ).subscribe((res) => {
                    const googleRes = JSON.parse(res);
                    if (googleRes) {
                        const { pCode, street_name, city, streetNumberStr } =
                            this.getAddressElements(googleRes);

                        const address: AddressElements = {
                            postCode: pCode,
                            city: city,
                            streetName: street_name,
                            houseNumber: streetNumberStr,
                        };

                        observer.next(address);
                    } else {
                        observer.next(null);
                    }
                });
            } else {
                observer.next(null);
            }
        });
    }

    public getCurrentPosition(): Observable<GeolocationPosition> {
        return new Observable((observer) => {
            if (window.navigator && window.navigator.geolocation) {
                window.navigator.geolocation.getCurrentPosition(
                    (position) => {
                        observer.next(position);
                        observer.complete();
                    },
                    (error) => observer.error(error)
                );
            } else {
                observer.error('Unsupported Browser');
            }
        });
    }

    reverseGeocode(latitude: number, longitude: number) {
        return this.http.get<string>(
            this.shopServiceUrl + `order/GetLocationWithLatLong`,
            {
                headers: this.headers,
                params: { latitude: latitude, longtitude: longitude },
            }
        );
    }

    private getAddressElements(googleRes: any) {
        const address = this.getAddressFromGoogleRes(googleRes);
        let pCode = null;
        if (address.find((addr) => addr.Types[0] === 'postal_code'))
            pCode = address.find((addr) => addr.Types[0] === 'postal_code').short_name;

        let streetNumberStr = null;
        if (address.find((addr) => addr.Types[0] === 'street_number'))
            streetNumberStr = address.find(
                (addr) => addr.Types[0] === 'street_number'
            ).short_name;

        let city = null;
        if (address.find((addr) => addr.Types[0] === 'locality'))
            city = address.find(
                (addr) =>
                    addr.Types[0] === 'locality' ||
                    addr.Types[0] === 'administrative_area_level_2' ||
                    addr.Types[0] === 'administrative_area_level_1'
            ).short_name;
        let street_name = null;
        if (address.find((addr) => addr.Types[0] === 'route'))
            street_name = address.find((addr) => addr.Types[0] === 'route').long_name;
        return { pCode, street_name, city, streetNumberStr };
    }

    private getAddressFromGoogleRes(googleRes: any) {
        if (googleRes.Results?.length > 1) {
            const location_type = googleRes.Results.find(
                (googleResults) =>
                    googleResults.Geometry.location_type === 'ROOFTOP' ||
                    googleResults.Geometry.location_type === 'RANGE_INTERPOLATED' ||
                    googleResults.Geometry.location_type === 'GEOMETRIC_CENTER'
            );

            return location_type.address_components;
        } else {
            return googleRes.Results[0].address_components;
        }
    }
}
