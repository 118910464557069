import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { ConfigureSessionDto, MasterBasket, SessionClient } from '../../index';
import { ServiceBaseService } from '../service-base.service';
import { SessionService } from '../session/session.service';

@Injectable({ providedIn: 'root' })
export class SharedHomeService {
    private baseUrl: string;
    private headers: HttpHeaders;
    public masterBasket: MasterBasket = null;
    public lowestPrice: number;

    isSessionSet: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    rootProviderSelected = '';

    //A felhasználó által kiválasztott elemek,
    selectedProviderNames: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    selectedProviderCodes: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    dataBaseAvailableProviderNames: BehaviorSubject<string[]> = new BehaviorSubject<
        string[]
    >([]);
    sessionConfiguration: BehaviorSubject<string> = new BehaviorSubject<string>('');
    ownWebShopProviderCode: BehaviorSubject<string> = new BehaviorSubject<string>(
        ServiceBaseService.getOwnWebShopProviderCode()
    );
    isOwnProviderWebShop: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isCategoryMenuDisplayed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        window.location.pathname === '/' ||
        window.location.pathname.includes(this.translate.instant('router.checkout'))
            ? false
            : true
    );

    actualOrderPage = '';

    // Hide/Show Header in different pages
    public isHeaderDisplayed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );
    public isFooterDisplayed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        true
    );

    constructor(
        @Inject(DOCUMENT) private dom: Document,
        private http: HttpClient,
        private serviceBaseService: ServiceBaseService,
        private sessionService: SessionService,
        private router: Router,
        private translate: TranslateService,
        private sessionClient: SessionClient
    ) {
        if (this.ownWebShopProviderCode.value) {
            this.isOwnProviderWebShop.next(true);
        }

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                event.url === '/' ||
                event.url.includes('/?') ||
                event.url.includes('selectShop') ||
                event.url.includes('archie') ||
                event.url.includes(this.translate.instant('router.checkout'))
                    ? this.isCategoryMenuDisplayed.next(false)
                    : this.isCategoryMenuDisplayed.next(true);
            }
        });

        this.baseUrl = this.serviceBaseService.getBaseUrl();
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        forkJoin([
            this.translate.get('router.user'),
            this.translate.get('router.my-order'),
        ]).subscribe(([user, myOrder]: [string, string]) => {
            this.actualOrderPage = '/' + user + '/' + myOrder;
        });
    }

    // We set the shops to the session, only if user havent chooose yet
    // If User comes from an URL- that represents a webshop
    setBaseShop(providerCode = '') {
        const url = new URL(window.location.href);

        if (this.isOwnProviderWebShop.value) {
            this.rootProviderSelected = this.ownWebShopProviderCode.value;
        } else {
            this.rootProviderSelected = providerCode ?? url.searchParams.get('kereskedo');
        }

        const configureSessionDto = new ConfigureSessionDto({
            userSelectedShops: [],
            redirectToDashboardNeeded: false,
            setUserSelectedShopsOnFirstSiteLoad: true,
            shopsSelectedForRoot: this.rootProviderSelected,
            ownWebshopProviderCode: this.ownWebShopProviderCode.value,
        });

        this.sessionService
            .configureUserSession(configureSessionDto)
            .subscribe((session) => {
                this.selectedProviderNames.next(session.selectedShops);
                this.selectedProviderCodes.next(session.selectedShopCodes);

                this.dataBaseAvailableProviderNames.next(
                    session.dataBaseAvailableProviderNames
                );
                this.sessionConfiguration.next(session.globalFilterConfiguration);

                this.isSessionSet.next(true);
            });
        return null;
    }

    getUserSession() {
        if (this.isSessionSet) {
            return this.sessionClient.getSession();
        }
    }

    getProviderDataByProviderID(providerid: number) {
        if (providerid) {
            return this.http.get<any>(
                this.baseUrl + 'auth/GetProviderDataByID/' + providerid,
                {
                    headers: this.headers,
                }
            );
        }
    }

    getProviderDataByShopName(shopname: string) {
        if (shopname) {
            return this.http.get<any>(this.baseUrl + 'auth/GetProviderData/' + shopname, {
                headers: this.headers,
                params: { shopname: shopname },
            });
        }
    }

    /*
     * General utils for managing cookies in Typescript.
     */
    setCookie(name: string, val: string) {
        const date = new Date();
        const value = val;

        // Set it expire in 7 days
        date.setTime(date.getTime() + 70 * 24 * 60 * 60 * 1000);

        // Set it
        document.cookie =
            name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
    }

    getCookie(name: string) {
        const value = '; ' + document.cookie;
        const parts = value.split('; ' + name + '=');

        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
    }

    deleteCookie(name: string) {
        const date = new Date();

        // Set it expire in -1 days
        date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

        // Set it
        document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/';
    }

    setFooterDisplayed(displayed: boolean): void {
        this.isFooterDisplayed.next(displayed);
    }

    setHeaderDisplayed(displayed: boolean): void {
        this.isHeaderDisplayed.next(displayed);
    }
}
