<div
    [class.longer]="isShoppingListCard && isProviderSelected"
    [class.shorter]="
        (!isShoppingListCard && !isProviderSelected && !isArchie) || commercialBlock
    "
    [class.commercial-block]="commercialBlock"
    class="my-2 product-card d-flex flex-column justify-content-between position-relative px-3"
    [class.m-0]="isArchie"
    [style.width]="preferredWidth"
    itemprop="itemListElement"
    itemscope
    itemtype="http://schema.org/ListItem"
>
    <meta itemprop="position" [attr.content]="1" />
    <div itemprop="item" itemscope itemtype="http://schema.org/Product">
        <div
            *ngIf="product.isProductRokshDiscounted && product.rokshDiscountPrice > 0"
            class="level-container pointer"
            [ngClass]="{
                'disable-level-container':
                    product.rokshDiscountLevel > currentRokshDiscountLevelIndex
            }"
        >
            <span *ngIf="!product.isOneProductForFree" class="on-hover-text">
                {{ 'common.roksh-discount-tooltip-1' | translate }}
                {{ product.rokshDiscountPrice | currencyFormatted }}
                {{ 'common.currency' | translate }}
                {{ 'common.roksh-discount-tooltip-2' | translate }}
            </span>
            <span *ngIf="product.isOneProductForFree" class="on-hover-text">
                {{ 'common.roksh-discount-product-card-free-hover-text' | translate }}
            </span>
            <p
                class="not-hover-text"
                [ngClass]="{
                    'pt-2': product.rokshDiscountLevel <= currentRokshDiscountLevelIndex
                }"
            >
                <ng-container
                    *ngIf="product.rokshDiscountLevel > currentRokshDiscountLevelIndex"
                >
                    <span
                        >{{ 'common.level' | translate }}
                        {{ product.rokshDiscountLevel }} </span
                    ><br />
                </ng-container>
                <span *ngIf="!product.isOneProductForFree"
                    >- {{ product.rokshDiscountPrice | currencyFormatted }}
                    {{ 'common.currency' | translate }}</span
                >
                <span class="level-text" *ngIf="product.isOneProductForFree">
                    {{ 'common.roksh-discount-product-card-free-text' | translate }}
                </span>
            </p>
        </div>

        <div *ngIf="!isShoppingListCard; else deleteFromShoppingList">
            <fa-icon
                *ngIf="isAuth && !isFavoriteProgress"
                size="lg"
                [icon]="isFavoriteProduct ? fasHeart : farHeart"
                (click)="onProductFavoriteChange()"
                class="favorite-heart"
            >
            </fa-icon>
            <fa-icon
                *ngIf="isAuth && isFavoriteProgress"
                [icon]="faSpinner"
                size="lg"
                [spin]="true"
                class="favorite-heart"
            >
            </fa-icon>
        </div>
        <ng-template #deleteFromShoppingList>
            <img
                *ngIf="isAuth && !isFavoriteProgress"
                src="{{ siteImageUrl }}images/shared-img/user-basket/delete_product.svg"
                (click)="onDelete()"
                class="favorite-heart text-grey m-2"
                alt="delete product"
            />
        </ng-template>

        <div class="d-flex flex-column">
            <div
                class="icons d-flex"
                [ngClass]="{
                    'card-has-level':
                        product.isProductRokshDiscounted && product.rokshDiscountPrice > 0
                }"
                *ngIf="!isMobile; else mobileIcons"
            >
                <img
                    class="pic-icon"
                    *ngFor="let icon of product.icons | slice : 0 : 3; let ind = index"
                    [src]="icon.blobURL.replace('_large', '')"
                    [title]="icon.iconTooltip"
                />
            </div>
            <ng-template #mobileIcons>
                <div class="d-flex pb-1">
                    <div
                        class="icon d-flex justify-content-start py-1"
                        [id]="tooltipId"
                        [ngClass]="{
                            'card-has-level':
                                product.isProductRokshDiscounted &&
                                product.rokshDiscountPrice > 0
                        }"
                    >
                        <ion-popover triggerAction="click" [trigger]="tooltipId">
                            <ng-template>
                                <ion-content class="ion-no-padding" scroll-y="false">
                                    <div
                                        *ngFor="let icon of product.icons; let i = index"
                                        class="py-2 px-3"
                                    >
                                        <div class="d-flex align-items-center">
                                            <img
                                                [title]="icon.iconTooltip"
                                                [src]="icon.blobURL"
                                                [alt]="icon.iconTooltip"
                                                class="big-icon pe-3"
                                            />
                                            <p *ngIf="icon.iconTooltip" class="p-0 m-0">
                                                {{ icon.iconTooltip }}
                                            </p>
                                        </div>
                                    </div>
                                </ion-content>
                            </ng-template>
                        </ion-popover>
                        <div
                            *ngFor="let icon of product.icons; let i = index"
                            class="h-100"
                        >
                            <img
                                [title]="icon.iconTooltip"
                                [src]="icon.blobURL"
                                [alt]="icon.iconTooltip"
                                class="h-100 me-2 icon-image"
                            />
                        </div>
                    </div>
                </div>
            </ng-template>
            <div
                *ngIf="product.isOffer === true && product.originalPriceIfOffer > 0"
                class="badge percentage position-absolute mt-1"
            >
                -
                {{ discountPercentage }}%
            </div>

            <div
                class="product-card-img w-100 d-flex justify-content-center align-items-center"
            >
                <a (click)="onCardClicked($event)" class="h-100">
                    <ng-container class="h-100">
                        <img
                            #img
                            alt="{{ product.sEOName }}"
                            [src]="product.mediaUrlS"
                            itemprop="image"
                            class="h-100 w-100"
                            (error)="imageError()"
                        />
                    </ng-container>

                    <span
                        class="badge badge-info text-12"
                        *ngIf="
                            product &&
                            product.secondaryUnitPrice &&
                            product.secondaryUnitPrice > 0 &&
                            product.displayUnit
                        "
                    >
                        {{ product.secondaryUnitPrice | currencyFormatted }}
                        {{ 'common.currency' | translate }}/
                        {{ product.displayUnit }}
                    </span>
                    <span
                        class="badge badge-info text-12"
                        *ngIf="
                            (!product.secondaryUnitPrice ||
                                product.secondaryUnitPrice === 0) &&
                            product.unitPrice > 0 &&
                            product.priceUnitType
                        "
                        >{{ product.unitPrice }} {{ 'common.currency' | translate }}/
                        {{ product.priceUnitType }}</span
                    >
                </a>
            </div>
            <div class="product-card-text font-weight-600 text-size-12 mb-1">
                <small
                    *ngIf="product.isOffer && product.productProvider[0]?.offerValidTo"
                    class="offer-info"
                >
                    {{ 'common.offer-ends-at' | translate }}:
                    {{ product.productProvider[0].offerValidTo | date : 'yyyy/MM/dd' }}
                    {{ 'common.offer-time-hour-constant' | translate }}
                </small>
                <br />
                <a (click)="onCardClicked($event)" class="w-100 text-black">
                    <span itemprop="name" attr.content="{{ product.productName }}">
                        {{ product.productName }}
                    </span>
                </a>
            </div>

            <div *ngIf="product.price !== 0; else unavailableProduct">
                <div
                    *ngIf="!product.selectedShopIsBulk; else bulkPrice"
                    class="d-flex flex-column justify-content-end"
                >
                    <div>
                        <div
                            itemprop="offers"
                            itemscope
                            itemtype="http://schema.org/Offer"
                            class="product-card-price position-relative"
                            [ngClass]="{
                                'badge badge-danger offer-price-container d-inline':
                                    product.isOffer === true,
                                'add-to-cart-btn-disabled-card-price text-start':
                                    isFavoriteProduct
                            }"
                        >
                            <span
                                itemprop="price"
                                style="margin-right: 2px"
                                *ngIf="!product.depositFee"
                            >
                                {{ product.price | currencyFormatted }}
                            </span>

                            <span
                                itemprop="price"
                                style="margin-right: 2px"
                                *ngIf="
                                    product.depositFee && !hasAnyProviderDepositProduct
                                "
                            >
                                {{
                                    product.price - product.depositFee | currencyFormatted
                                }}
                            </span>

                            <span class="text-size-12" *ngIf="!product.displayUnit">
                                {{ 'common.currency' | translate }}/{{
                                    'common.piece' | translate
                                }}
                            </span>
                            <span class="text-size-12" *ngIf="product.displayUnit">
                                {{ 'common.currency' | translate }}/{{
                                    product.displayUnit
                                }}
                            </span>
                        </div>
                        <span
                            *ngIf="
                                product.productProvider?.length > 0 &&
                                product.productProvider[0].originalPriceIfOffer > 0
                            "
                            class="old-price-container"
                        >
                            &nbsp;
                            <span style="margin-right: 2px">
                                {{
                                    product.productProvider[0].originalPriceIfOffer
                                        | currencyFormatted
                                }}
                                {{ 'common.currency' | translate }}
                            </span>
                        </span>
                    </div>
                    <div class="d-block mt-n1">
                        <span
                            class="text-size-10 deposit-text"
                            *ngIf="
                                product.depositFee &&
                                (!product.providerDepositProductDtoList ||
                                    product.providerDepositProductDtoList.length <= 0)
                            "
                        >
                            ({{ 'common.deposit-type-name' | translate }}
                            {{ 'common.deposit-plp-before-text' | translate }}
                            {{ product.depositFee | currencyFormatted }}
                            {{ 'common.deposit-plp-after-text' | translate }})
                        </span>
                        <span
                            class="text-size-10 deposit-text"
                            *ngIf="hasAnyProviderDepositProduct && isAtCountryCode"
                        >
                            ({{
                                notOptionalProviderDepositProduct?.article == 0
                                    ? ('deposit.mehrweg' | translate)
                                    : ('deposit.einweg' | translate)
                            }}
                            {{ 'common.deposit-plp-before-text' | translate }}
                            {{
                                notOptionalProviderDepositProduct?.price
                                    | currencyFormatted
                            }}
                            {{
                                'common.not-optional-deposit-plp-after-text' | translate
                            }})
                        </span>

                        <span
                            class="text-size-10 deposit-text"
                            *ngIf="hasAnyProviderDepositProduct && !isAtCountryCode"
                        >
                            {{ notOptionalProviderDepositProduct?.description }}
                        </span>
                    </div>
                </div>
                <ng-template #bulkPrice>
                    <div class="d-flex align-items-end">
                        <div
                            itemprop="offers"
                            itemscope
                            itemtype="http://schema.org/Offer"
                            class="product-card-price"
                            [ngClass]="{
                                'badge badge-danger offer-price-container d-inline':
                                    product.isOffer === true
                            }"
                        >
                            <span itemprop="price" style="margin-right: 2px">
                                {{ product.unitPrice | currencyFormatted }}
                            </span>
                            <span class="text-size-12" *ngIf="!product.displayUnit"
                                >{{ 'common.currency' | translate }}/kg</span
                            >
                            <span class="text-size-12" *ngIf="product.displayUnit"
                                >{{ 'common.currency' | translate }}/{{
                                    product.displayUnit
                                }}</span
                            >
                        </div>
                        <div
                            *ngIf="
                                product.originalPriceIfOffer &&
                                product.originalPriceIfOffer > 0
                            "
                            class="old-price-container"
                            style="position: relative"
                        >
                            &nbsp;
                            <span style="margin-right: 2px">
                                {{ product.originalPriceIfOffer | currencyFormatted }}
                                {{ 'common.currency' | translate }}
                            </span>
                        </div>
                    </div>
                </ng-template>
            </div>
            <ng-template #unavailableProduct>
                <div class="text-danger w-100" [class.mt-5]="isProviderSelected">
                    {{ 'common.this-product-is-not-available-anymore' | translate }}
                </div>
            </ng-template>
        </div>
    </div>
    <div
        class="w-100 d-flex justify-content-center pb-2"
        *ngIf="product.price > 0 && !commercialBlock"
        (click)="$event.stopImmediatePropagation()"
    >
        <lib-add-to-cart-button
            [isArchie]="isArchie"
            [providerId]="productProviderId"
            [providerName]="productProviderName"
            [prod]="product"
            [isForShoppingList]="isShoppingListCard"
            [buttonWidth]="'160px'"
            [masterBasketId]="masterBasketId"
            [selectedBasketItemID]="basketItemId"
            [selectedQuantity]="selectedQuantity"
            [addressDialogComponent]="addressDialogComponent"
            [providerDialogComponent]="providerDialogComponent"
            [selectedShoppingListQuantity]="selectedShoppingListQuantity"
            (onAdd)="onAddOrReplace($event)"
        >
        </lib-add-to-cart-button>
    </div>
</div>
