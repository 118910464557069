<p
    class="d-none d-xl-inline font-weight-600 color-turquoise app-postcode provider-header"
    [ngClass]="{ 'text-dark  text-size-14': onlyTextShown && isValidZip }"
>
    <ng-container *ngIf="isValidZip"
        ><span *ngIf="onlyTextShown"
            >{{ 'shop-select-dialog.delivery-here' | translate }}: </span
        >{{ zipCode }}</ng-container
    >
    <ng-container *ngIf="!isValidZip">{{ 'common.delivery' | translate }}</ng-container>
</p>
<img
    *ngIf="!onlyTextShown || !isValidZip"
    src="assets/svg/Kiszallitas.svg"
    alt="Kiszállítás ikon"
    class="delivery-svg me-2 provider-header-icon"
/>
<span
    class="d-md-none mobil-post-txt provider-header"
    [ngClass]="{ 'text-dark text-size-14': onlyTextShown && isValidZip }"
>
    <ng-container *ngIf="isValidZip"
        ><span *ngIf="onlyTextShown"
            >{{ 'shop-select-dialog.delivery-here' | translate }}: </span
        >{{ zipCode }}
        <fa-icon *ngIf="onlyTextShown" [icon]="faAngleRight"></fa-icon>
    </ng-container>
    <ng-container *ngIf="!isValidZip">{{ 'common.delivery' | translate }}</ng-container>
</span>
