import { Component, Input, OnInit } from '@angular/core';
import {
    ProductBlockDto,
    ProductDto,
    ServiceBaseService,
} from 'projects/library-shared/src/public-api';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-popular-products-new',
    templateUrl: './popular-products-new.component.html',
    styleUrls: ['./popular-products-new.component.css'],
})
export class PopularProductsNewComponent implements OnInit {
    @Input() customBlock: ProductBlockDto;

    BackGUID: string;
    popularProducts: ProductDto[];
    baseBackUrl = '';

    faChevronRight = faChevronRight;
    mediaServiceUrl = '';

    constructor(public baseService: ServiceBaseService) {}

    ngOnInit(): void {
        this.mediaServiceUrl = this.baseService.getMediaServiceUrl();
        this.baseBackUrl = this.mediaServiceUrl + 'interface/medium';
        if (this.customBlock) {
            this.popularProducts = this.customBlock.ProductList;
            this.BackGUID = `url(${this.baseBackUrl}/${this.customBlock.BackGUID})`;
        }
    }
}
