import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AdditionalShopInfo,
    BasketService,
    CountryCodes,
    DataLayerGa4Service,
    IMyOrder,
    OrderService,
    PaymentService,
    ServiceBaseService,
} from 'projects/library-shared/src/public-api';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
    parsedResult: any;
    faCheckCircle = faCheckCircle;

    ecomSent = false;
    status = 'PROGRESS';
    isIPNBEResponsed = false;
    isPayOnDelivery = false;
    isCashOnDelivery = false;
    orderCode: string;

    currentOrder: IMyOrder;
    additionalShopInfo: AdditionalShopInfo = new AdditionalShopInfo();

    constructor(
        private route: ActivatedRoute,
        private paymentService: PaymentService,
        private dataLayerGa4Service: DataLayerGa4Service,
        private location: Location,
        private orderService: OrderService,
        protected basketService: BasketService,
        private router: Router
    ) {
        this.status = 'PROGRESS';
    }

    ngOnInit(): void {
        const paymentID = this.route.snapshot.queryParams['paymentId'];
        const status = this.route.snapshot.queryParams['status'];
        this.orderCode = this.route.snapshot.queryParams['ordercode'];
        if (status && status === 'failed') {
            this.status = 'FAILED';
            this.isIPNBEResponsed = true;
        } else {
            this.getPaymentResult(paymentID);
        }
    }

    getPaymentResult(paymentID) {
        this.paymentService.payResult(paymentID).subscribe(
            (data) => {
                if (
                    data.TagObject.PayStatusID.toUpperCase() == 'INPROGRESS' &&
                    !this.ecomSent
                ) {
                    setTimeout(() => {
                        this.getPaymentResult(paymentID);
                    }, 1500);
                    return;
                }

                this.isIPNBEResponsed = true;
                this.status = data.TagObject.PayStatusID;
                this.status = this.status.toUpperCase();
                const WeShopTransactionGUID = data.TagObject.WeShopTransactionGUID;
                this.orderCode = data.TagObject2;

                if (this.status.toUpperCase() == 'RESERVED' && !this.ecomSent) {
                    if (data.TagObject.ProviderCode == 'PAYONDELIVERY') {
                        this.isPayOnDelivery = true;
                        this.sendEmails(WeShopTransactionGUID);
                    } else if (data.TagObject.ProviderCode == 'CASHONDELIVERY') {
                        this.isCashOnDelivery = true;
                        this.sendEmails(WeShopTransactionGUID);
                    }
                    this.getCurrentOrder(this.orderCode);

                    (async () => {
                        await delay(2000);
                        this.additionalShopInfo.savedPrice = -1;
                        this.additionalShopInfo.totalPrice =
                            data.AdditionalInfos.TotalPrice;
                        this.additionalShopInfo.providerID =
                            data.AdditionalInfos.ProviderID;

                        const orderPaymentHistoryCreated = new Date(
                            data.TagObject.Created
                        );
                        const dataLayerTriggerTimeLimit = new Date();
                        const currentTime = new Date();
                        dataLayerTriggerTimeLimit.setMinutes(
                            currentTime.getMinutes() - 60
                        );

                        if (orderPaymentHistoryCreated <= dataLayerTriggerTimeLimit) {
                            console.log('Created is older than 60 minutes');
                        } else {
                            this.dataLayerGa4Service.sendDataLayer(
                                this.orderCode,
                                true,
                                this.additionalShopInfo.providerID.toString(),
                                this.additionalShopInfo
                            );
                        }
                    })();

                    (async () => {
                        await delay(4000);
                        this.emptyCurrentBasket(data.AdditionalInfos.ProviderID);
                    })();
                } else {
                    this.location.go('/payment/spresult/failed');
                }
                this.parsedResult = WeShopTransactionGUID;
                this.ecomSent = true;
            },
            () => {
                this.router.navigate(['/']);
            }
        );

        function delay(ms: number) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        }
    }

    startPay(oCode: string) {
        if (ServiceBaseService.countrycode === CountryCodes.at) {
            this.orderService.startPaymentWidget(oCode);
        } else {
            this.orderService.startPayment(oCode).subscribe((redirectSimpleUrl) => {
                window.location = redirectSimpleUrl;
            });
        }
    }

    private sendEmails(TransactionGUID: string) {
        this.paymentService.SendEmailsByTransactionGUID(TransactionGUID).subscribe();
    }

    private getCurrentOrder(orderCode: string) {
        this.orderService.getOrderForUser(orderCode).subscribe((order) => {
            this.currentOrder = order as IMyOrder;
        });
    }

    private emptyCurrentBasket(providerID: number) {
        this.basketService.emptyBasket(providerID).subscribe();
    }
}
