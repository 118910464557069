import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
    AuthenticationService,
    BasketService,
    FormatterService,
    IMyOrder,
    LogService,
    MyOrderItemProductDto,
    OrderService,
    PageType,
    ProviderDTO,
    ServiceBaseService,
    SessionService,
    UserShared,
} from 'projects/library-shared/src/public-api';

import { faAngleRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { OrderCustomerStatus } from '../../../types/ordercustomerstatus';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-orderlist',
    templateUrl: './order-list.component.html',
    styleUrls: ['./order-list.component.scss'],
    providers: [LogService],
})
export class OrderListComponent implements OnInit {
    faAngleRight = faAngleRight;
    faSpinner = faSpinner;
    isSubmitted: boolean;
    siteImageUrl = '';

    massAddButtonText = '';
    @Output() onaddorreplace: EventEmitter<any> = new EventEmitter();

    dividingLine: boolean;
    mobile: boolean;
    user: UserShared = new UserShared();
    myOpenOrders: IMyOrder[] = [];
    myClosedOrders: IMyOrder[] = [];
    products: MyOrderItemProductDto[];
    now = new Date();

    isDeliveryAvailableByPostCode = true;
    isPostCodeSetted = false;
    isProviderSelectedForShopping = false;
    pageType = PageType.OrderList;

    @Output() onadd: EventEmitter<any> = new EventEmitter();

    constructor(
        private authService: AuthenticationService,
        private orderService: OrderService,
        private sessionService: SessionService,
        public basketService: BasketService,
        public formatterService: FormatterService,
        public baseService: ServiceBaseService,
        private title: Title,
        private translate: TranslateService
    ) {
        this.translate
            .get('common.add-all-to-basket')
            .subscribe((translation) => (this.massAddButtonText = translation));
        this.translate.get('common.order-list-title').subscribe((title) => {
            this.title.setTitle(title);
        });
        this.dividingLine = false;
    }

    ngOnInit(): void {
        this.siteImageUrl = this.baseService.getSiteImageUrl();
        this.sessionService.sessionSubject.subscribe((sessionSubject) => {
            this.isDeliveryAvailableByPostCode =
                sessionSubject.isDeliveryAvailableByPostCode;
        });

        if (window.screen.width <= 360) {
            this.mobile = true;
        }
        this.user = this.authService.getLoggedInUser();

        this.orderService._canceledOrderSubject.subscribe((orderCode) => {
            if (
                Array.isArray(this.myOpenOrders) &&
                this.myOpenOrders.length !== 0 &&
                Array.isArray(this.myClosedOrders)
            ) {
                const indexOfObject = this.myOpenOrders.findIndex((order) => {
                    return order.OrderCode === orderCode;
                });
                this.myClosedOrders.unshift(
                    ...this.myOpenOrders.splice(indexOfObject, 1)
                );
            }
        });
    }

    getMyOrders(selectedProvider: ProviderDTO) {
        this.orderService
            .getOrdersForUser(selectedProvider?.id)
            .subscribe((orders: IMyOrder[]) => {
                this.myClosedOrders = [];
                this.myOpenOrders = [];

                orders.forEach((ord: IMyOrder) => {
                    if (
                        ord.OrderCustomerStatus === OrderCustomerStatus.DELIVERED ||
                        ord.OrderCustomerStatus === OrderCustomerStatus.DELETED
                    )
                        this.myClosedOrders.push(ord);
                    else this.myOpenOrders.push(ord);
                });

                this.dividingLine =
                    this.myOpenOrders.length > 0 && this.myClosedOrders.length > 0;
            });
    }
}
