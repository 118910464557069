<div class="p-4 overflow-y-scroll">
    <h1 class="fw-bold pb-4 md-block d-md-none">
        {{ 'basket.your-baskets' | translate }}
    </h1>
    <ng-container *ngFor="let basket of selectedBaskets">
        <div class="d-flex justify-content-between align-items-start w-100">
            <div class="d-flex">
                <div class="provider-img me-3">
                    <img
                        src="{{ siteImageUrl }}logos/{{ basket.providerName }}-logo-s.png"
                        alt="provider logo"
                        class="h-100 w-100 p-2"
                    />
                </div>
                <div>
                    <h4 class="m-0 p-0 fw-bold">{{ basket.providerName }}</h4>
                    <p class="m-0 p-0">
                        {{ 'common.quantity' | translate }}:
                        {{ basket.basketItemList.length }}
                        {{ 'common.piece' | translate }}
                    </p>
                </div>
            </div>
            <div>
                <h5 class="fw-bolder p-0 m-0">
                    {{ basketPrice(basket) | currencyFormatted }}&nbsp;{{
                        'common.currency' | translate
                    }}
                </h5>
            </div>
        </div>
        <div class="w-100 pb-5 pt-2">
            <button
                class="btn btn-blue w-100"
                (click)="selectBasket(basket)"
                [disabled]="basket.basketItemList.length <= 0"
            >
                {{ 'basket.open-basket' | translate }}
            </button>
        </div>
    </ng-container>
</div>
