<div *ngIf="shoppingList">
    <app-user-basket-details
        [canModify]="false"
        id="found"
        *ngIf="shoppingList"
        class="row bg-white mb-2 py-1 d-block"
        [userBasket]="shoppingList"
        (close)="close()"
    >
    </app-user-basket-details>
</div>
