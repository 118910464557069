import {
    CategoryService,
    CategoryShared,
    ContainerIds,
    HtmlMarketingElementsExtendsDto,
    MarketingService,
    ServiceBaseService,
    SessionService,
    SnackBarService,
} from 'projects/library-shared/src/public-api';
import { HomeService } from '../../services/home/home.service';
import { CreateDialogService } from '../../services/create-dialog.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-category-menu',
    templateUrl: './category-menu.component.html',
    styleUrls: ['./category-menu.component.scss'],
})
export class CategoryMenuComponent implements OnInit {
    isCategoryMenuDisplayed = true;
    siteImageUrl = '';

    shop: string;
    shopID: number;
    zipCode: string;
    userSelectedShops: string[];
    isDeliveryAvailableByPostCode: boolean;
    isPostCodeSetted = false;
    isDeliveryAvailableToSelectedShopByPostCode = true;

    categoryList: CategoryShared[] = [];
    activeCategory = '';
    activeChildCategory = '';
    dummyArray = new Array(12);

    isViewInited = false;
    eventHandlersAdded = false;

    protected readonly ContainerIds = ContainerIds;
    htmlMarketingElementDto$: Observable<HtmlMarketingElementsExtendsDto>;

    constructor(
        private categoryService: CategoryService,
        private homeService: HomeService,
        private sessionService: SessionService,
        public baseService: ServiceBaseService,
        private createDialogService: CreateDialogService,
        private router: Router,
        private translateService: TranslateService,
        private cdr: ChangeDetectorRef,
        private snackBarService: SnackBarService,
        private marketingService: MarketingService
    ) {
        this.siteImageUrl = this.baseService.getSiteImageUrl();
    }

    ngOnInit() {
        this.getCategoryList();

        this.router.events
            .pipe(filter((e: Event): e is RouterEvent => e instanceof RouterEvent))
            .subscribe((e: RouterEvent) => {
                this.translateService.get('router.offer').subscribe((text) => {
                    if (e instanceof NavigationEnd) {
                        const categoryFullUrl = e.url.split(text + '/').pop();
                        if (categoryFullUrl) {
                            this.activeCategory = categoryFullUrl.split('/')[0];
                        }
                        if (categoryFullUrl && categoryFullUrl.split('/')[1])
                            this.activeChildCategory = categoryFullUrl.split('/')[1];
                    }
                });
            });

        this.homeService.isCategoryMenuDisplayed.subscribe((isCategoryMenuDisplayed) => {
            this.isCategoryMenuDisplayed = isCategoryMenuDisplayed;
        });

        this.homeService.isSessionSet.subscribe((isSet) => {
            if (isSet) {
                this.sessionService.sessionSubject.subscribe((session) => {
                    if (session) {
                        this.zipCode = session.zipCode;
                        this.userSelectedShops = session.selectedShops;
                        this.shopID = session.selectedShopIds[0];
                        if (this.userSelectedShops && this.userSelectedShops.length === 1)
                            this.shop = session.selectedShopNamesToShow[0];
                    }
                });
            }
        });

        this.sessionService.isPostCodeSet.subscribe((result) => {
            this.isPostCodeSetted = result;
        });

        this.htmlMarketingElementDto$ = this.marketingService.getHtmlMarketingElements(
            ContainerIds.leftSideMenu
        );
    }

    subscribeToCollapse(): void {
        if (this.eventHandlersAdded) {
            return;
        }

        this.eventHandlersAdded = true;

        const accordionItems = document.querySelectorAll('.accordion-collapse');
        accordionItems.forEach((category: HTMLElement) => {
            category.addEventListener('shown.bs.collapse', () =>
                this.scrollToActiveCategory(category.id)
            );
        });
    }

    showAvailableTimeSlots() {
        let stayHere = true;
        this.sessionService.sessionSubject.subscribe((sessionSubject) => {
            this.isDeliveryAvailableByPostCode =
                sessionSubject.isDeliveryAvailableByPostCode;
            this.isDeliveryAvailableToSelectedShopByPostCode =
                sessionSubject.availableShops?.some(
                    (shop) =>
                        shop.toLowerCase() ===
                        sessionSubject?.selectedShops[0]?.toLowerCase()
                );
        });

        let canShowAvailableTimeSlots = false;

        if (
            !this.isDeliveryAvailableToSelectedShopByPostCode ||
            (!this.isDeliveryAvailableByPostCode &&
                (!this.sessionService.sessionSubject.value?.zipCode ||
                    !this.sessionService.sessionSubject.value?.isValidZip ||
                    this.sessionService.sessionSubject.value?.zipCode.trim() == ''))
        ) {
            let alertMessage = '';
            if (
                !this.isDeliveryAvailableToSelectedShopByPostCode &&
                this.isPostCodeSetted
            ) {
                this.translateService
                    .get('messages.we-dont-deliver-for-this-zip')
                    .subscribe((text) => {
                        alertMessage = text;
                        stayHere = false;
                    });
            }

            if (!this.isPostCodeSetted) {
                this.translateService
                    .get('messages.time-slot-without-zip')
                    .subscribe((text) => {
                        alertMessage = text;
                        stayHere = false;
                    });
            }

            this.createDialogService
                .openAddressDialog(stayHere, alertMessage)
                .afterClosed()
                .subscribe(() => {
                    if (!this.isPostCodeSetted) {
                        this.translateService
                            .get('product-details.add-your-postcode-to-explore')
                            .subscribe((text) => {
                                this.snackBarService.openErrorSnackBar(text);
                            });

                        return;
                    } else {
                        canShowAvailableTimeSlots = true;
                    }
                });
        } else {
            canShowAvailableTimeSlots = true;
        }
        if (!canShowAvailableTimeSlots) {
            return;
        }

        this.createDialogService.showAvailableTimeSlotsDialog(this.shop, this.shopID);
    }

    onClickGiveAddress() {
        this.createDialogService.openAddressDialog().afterClosed().subscribe();
    }

    private getCategoryList(): void {
        this.categoryService.categories.subscribe((categoryList) => {
            this.categoryList = categoryList;
        });
    }

    public scrollToActiveCategory(selectedCategoryId: string): void {
        this.isViewInited = true;
        this.cdr.detectChanges();

        if (!this.activeCategory) {
            return;
        }

        const selectedCategory = document.getElementById(selectedCategoryId);
        const scrollContainer = document.getElementById('scroll-container');
        const separatorLine = document.getElementById('separator');

        const scrollOffset =
            selectedCategory?.parentElement.offsetTop - separatorLine?.offsetHeight;

        scrollContainer.scroll({
            top: scrollOffset,
            behavior: 'smooth',
        });

        this.activeChildCategory = '';
    }
}
