import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ProvidersService } from '../../services/provider.service';
import { ProviderDTO } from '../../index';
import { ServiceBaseService } from '../../services/service-base.service';
import { ProviderSelectModalComponent } from './provider-select-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { SessionService } from '../../services/session/session.service';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
    selector: 'lib-provider-select',
    templateUrl: './provider-select.component.html',
    styleUrls: ['./provider-select.component.css'],
})
export class ProviderSelectComponent implements OnInit, OnDestroy {
    siteImageUrl: string;

    @Input() selectedProvider: ProviderDTO;
    @Input() infoText = '';
    @Output() providerChanged = new EventEmitter<ProviderDTO>();
    doNotShow = true;
    private providersSub: Subscription;
    private sessionSub: Subscription;

    constructor(
        private providerService: ProvidersService,
        private session: SessionService,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        baseService: ServiceBaseService
    ) {
        this.siteImageUrl = baseService.getSiteImageUrl();
    }

    ngOnInit(): void {
        this.providersSub = this.providerService
            .getAllProviders()
            .pipe(take(1))
            .subscribe(
                (providers) => {
                    if (providers.length === 1) {
                        this.providerChanged.next(providers[0]);
                        this.doNotShow = true;
                        if (this.providersSub) this.providersSub.unsubscribe();

                        return;
                    } else {
                        this.sessionSub =
                            this.session.isProviderSelectedForShopping.subscribe(
                                (isSelected) => {
                                    if (isSelected) {
                                        this.doNotShow = true;

                                        const sessionSubj =
                                            this.session.sessionSubject.getValue();
                                        const provider = new ProviderDTO();
                                        if (sessionSubj && sessionSubj.selectedShopIds) {
                                            provider.id = sessionSubj.selectedShopIds[0];
                                            provider.name = sessionSubj.selectedShops[0];
                                            provider.nameToShow =
                                                sessionSubj.selectedShopNamesToShow[0];
                                            provider.providerCode =
                                                sessionSubj.selectedShopCodes[0];
                                            this.providerChanged.next(provider);
                                        }

                                        if (this.sessionSub)
                                            this.sessionSub.unsubscribe();
                                        if (this.providersSub)
                                            this.providersSub.unsubscribe();
                                    } else {
                                        this.activatedRoute.queryParams.subscribe(
                                            (params) => {
                                                const provider =
                                                    params.providerName as string;

                                                if (provider) {
                                                    this.selectedProvider =
                                                        providers.find((current) => {
                                                            return (
                                                                current.name.toLowerCase() ===
                                                                provider.toLowerCase()
                                                            );
                                                        });
                                                    this.providerChanged.next(
                                                        this.selectedProvider
                                                    );
                                                    this.doNotShow = true;

                                                    if (this.sessionSub)
                                                        this.sessionSub.unsubscribe();
                                                    if (this.providersSub)
                                                        this.providersSub.unsubscribe();
                                                } else {
                                                    this.providerChanged.next(null);
                                                    delete this.selectedProvider;
                                                    this.doNotShow = false;
                                                }
                                            }
                                        );
                                    }
                                },
                                () => {
                                    this.doNotShow = false;
                                }
                            );
                    }
                },
                () => {
                    this.doNotShow = false;
                }
            );
    }

    openProviderChooser(): void {
        const dialog = this.dialog.open(ProviderSelectModalComponent, {
            minWidth: this.session.isMobile ? '90vw' : '250px',
            maxWidth: '95vw',
            maxHeight: '650px',
            panelClass: ['animate__animated', 'animate__bounceIn'],
        });
        dialog.componentInstance.closeEmitter = (data) => {
            this.selectedProvider = data;
            this.providerChanged.next(data);
            dialog.close();
        };
    }

    ngOnDestroy(): void {
        if (this.providersSub) this.providersSub.unsubscribe();
        if (this.sessionSub) this.sessionSub.unsubscribe();
    }
}
