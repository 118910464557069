import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
    AuthenticationService,
    BasketService,
    ProviderSettingsService,
    ProviderSettingType,
    SEOService,
    ServiceBaseService,
    SessionService,
    StyleService,
} from 'projects/library-shared/src/public-api';
import { Subscription } from 'rxjs';
import { FooterService } from './components/footer/footer.service';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs/operators';
import { HomeService } from './services/home/home.service';
import { register } from 'swiper/element/bundle';
import * as Sentry from '@sentry/angular-ivy';
import { CreateDialogService } from './services/create-dialog.service';

register();

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
@Sentry.TraceClassDecorator()
export class AppComponent implements OnInit, OnDestroy {
    openMenu = false;

    footerStatus: Subscription;
    isFooterFloating = false;
    isCategoryMenuDisplayed = true;
    setBaseShopNeeded = true;
    isUserMenuOpened = false;
    isFooterDisplayed = true;

    constructor(
        @Inject(DOCUMENT) private dom: Document,
        private session: SessionService,
        private homeService: HomeService,
        private footerService: FooterService,
        private basketService: BasketService,
        private router: Router,
        private translate: TranslateService,
        private providerSettingsService: ProviderSettingsService,
        private styleService: StyleService,
        private seoService: SEOService,
        private authService: AuthenticationService,
        private dialogService: CreateDialogService
    ) {
        translate.setDefaultLang(ServiceBaseService.getLanguageCode());
        translate.use(ServiceBaseService.getLanguageCode());
        if (ServiceBaseService.isProviderOwnWebShop()) {
            this.providerSettingsService
                .getProviderThemes(ServiceBaseService.getOwnWebShopProviderCode())
                .pipe(take(1))
                .subscribe((settings) => {
                    if (settings) {
                        const newSettings = this.providerSettingsService.settings
                            .getValue()
                            .concat(
                                settings.filter(
                                    (x) =>
                                        x.ProviderSettingType ===
                                            ProviderSettingType.Text ||
                                        x.ProviderSettingType ===
                                            ProviderSettingType.Image
                                )
                            );
                        this.providerSettingsService.settings.next(newSettings);

                        settings
                            .filter(
                                (x) => x.ProviderSettingType === ProviderSettingType.Css
                            )
                            .forEach((setting) => {
                                this.styleService.setStyle(
                                    setting.Section,
                                    setting.Key,
                                    setting.Value
                                );
                            });
                    }
                });
        }
    }

    ngOnInit() {
        console.log('AppComponent HELLLLLLLO');
        this.authService.subscribeForGoogleLogin((user) => {
            if (user.ToSAccepted && user.PrivacyAccepted)
                this.dialogService.closeOpenedDialog();
        });
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((navEnd: NavigationEnd) => {
                navEnd.url.includes('archie')
                    ? (this.isFooterDisplayed = false)
                    : (this.isFooterDisplayed = true);

                if (
                    navEnd.url.includes('felhasznalo') ||
                    navEnd.url.includes('benutzer')
                ) {
                    return;
                }

                if (
                    navEnd.url !== '/' &&
                    this.session.sessionSubject.getValue()?.selectedShops?.length > 0
                ) {
                    this.session.isProviderSelectedForShopping.next(true);
                } else {
                    this.session.isProviderSelectedForShopping.next(false);
                }
            });

        this.seoService.subscribeCanonicalUrlUpdateToNavigationEndEvent();

        this.router.events
            .pipe(filter((event) => event instanceof ActivationEnd))
            .subscribe((event: ActivationEnd) => {
                if (this.setBaseShopNeeded) {
                    this.homeService
                        .setBaseShop(event.snapshot.params['providercode'])
                        ?.pipe(take(1)) /// Please do not remove these two lines,
                        ?.subscribe(); /// bc a lot of request will go to the BE !!!
                    this.setBaseShopNeeded = false;
                }
            });
        this.homeService.isCategoryMenuDisplayed.subscribe((isCategoryMenuDisplayed) => {
            this.isCategoryMenuDisplayed = isCategoryMenuDisplayed;
        });

        this.homeService.isUserMenuOpen.subscribe((userMenu) => {
            this.isUserMenuOpened = userMenu;
        });

        this.basketService.openMenuSubject.subscribe((openMenu) => {
            this.openMenu = openMenu;
        });
        this.translate.get('router.search').subscribe((text) => {
            if (this.dom.URL.indexOf(text) > -1) {
                const metatag: HTMLMetaElement = this.dom.createElement('meta');
                metatag.setAttribute('NAME', 'ROBOTS');
                this.dom.head.appendChild(metatag);
                metatag.setAttribute('CONTENT', 'NOINDEX, NOFOLLOW');
            }
        });

        this.footerStatus = this.footerService
            .getFooterStatusListener()
            .subscribe((isFloat) => (this.isFooterFloating = isFloat));

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    ngOnDestroy(): void {
        if (this.footerStatus) {
            this.footerStatus.unsubscribe();
        }
    }
}
