import { Injectable } from '@angular/core';
import { CountryCodes } from '../types/country-codes';
import { CountryISO } from 'ngx-intl-tel-input';

@Injectable({ providedIn: 'root' })
export class ServiceBaseService {
    CountryCodes = CountryCodes;

    baseUrl = 'http:localhost:5000/';
    revolutmode = 'sandbox';
    siteImageUrl = 'https://weshopsiteimagesmaster.azureedge.net/';
    mediaServiceUrl = 'https://productimagesmaster.azureedge.net/';

    public static ownWebShopProviderCode = '';
    public static appInsightsAPIKey = '582dd3ed-7db6-48cf-8ef1-a27ea49acf53'; //developer insights
    public static translationBaseUrl = 'https://at-roksh-backend-master.roksh.at/';

    public static isOwnWebShopMobile = false;

    public static DOMAIN_PROVIDER_CODE_DICT = {
        'shop.aldi.hu': 'aldi',
        'hofer.roksh.at': 'hofer',
        'shopalditest.roksh.com': 'aldi',
    };

    public static get getEnvironment(): string {
        // Develop env

        const developEnv = 'dev';
        const developUrlParts: string[] = ['localhost'];

        if (developUrlParts.some((urlPart) => location.origin.includes(urlPart))) {
            return developEnv;
        }

        // Master env

        const masterEnv = 'master';
        const masterUrlParts: string[] = ['master'];

        if (masterUrlParts.some((urlPart) => location.origin.includes(urlPart))) {
            return `${masterEnv}-${this.countrycode}`;
        }

        // Staging env

        const stagingEnv = 'staging';
        const stagingUrlParts: string[] = ['staging', 'shopalditest.roksh.com', 'draft'];

        if (stagingUrlParts.some((urlPart) => location.origin.includes(urlPart))) {
            return `${stagingEnv}-${this.countrycode}`;
        }

        // Production env

        const productionEnv = 'prod';
        const productionUrlParts: string[] = ['roksh', 'shop.aldi.hu', 'gojaja'];

        if (productionUrlParts.some((urlPart) => location.origin.includes(urlPart))) {
            console.log('productionEnv', productionEnv, this.countrycode);
            return `${productionEnv}-${this.countrycode}`;
        }

        return developEnv;
    }

    public static get isMobileApp(): boolean {
        return (
            location.origin.includes('roksh-application') ||
            localStorage.getItem('welcome') != null
        );
    }

    public static get hostName(): string {
        return location.hostname;
    }

    public static get countrycode(): CountryCodes {
        const locale =
            localStorage.getItem('locale') ?? window.navigator.language.split('-')[0];

        if (
            (location.origin.includes('roksh-application') ||
                location.origin.includes('localhost')) &&
            locale === 'de'
        ) {
            return CountryCodes.at;
        }

        return location.origin.includes('at-') || location.origin.includes('roksh.at')
            ? CountryCodes.at
            : CountryCodes.hu;
    }

    public static get languagecode(): string {
        const locale =
            localStorage.getItem('locale') ?? window.navigator.language.split('-')[0];
        if (
            (location.origin.includes('roksh-application') ||
                location.origin.includes('localhost')) &&
            locale === 'de'
        ) {
            return 'de';
        }

        return location.origin.includes('at-') || location.origin.includes('roksh.at')
            ? 'de'
            : 'hu';
    }

    public static get datecode(): string {
        const locale =
            localStorage.getItem('locale') ?? window.navigator.language.split('-')[0];
        if (
            (location.origin.includes('roksh-application') ||
                location.origin.includes('localhost')) &&
            locale === 'de'
        ) {
            return 'de-DE';
        }

        return location.origin.includes('at-') || location.origin.includes('roksh.at')
            ? 'de-DE'
            : 'hu-HU';
    }

    checkoutPhoneCountries: CountryISO[] = [CountryISO.Austria];
    public static _baseUrl;

    constructor() {
        console.log('EGYÁLTALÁN ?location.origin', location.origin);

        /***********shopservice urls************/
        this.baseUrl = location.origin.includes('localhost')
            ? 'https://localhost:5001/'
            : this.baseUrl;
        console.log('location.origin', location.origin);

        this.baseUrl = location.origin.includes('gojaja')
            ? 'https://shopservice.gojaja.com/'
            : this.baseUrl;

        this.baseUrl =
            location.origin.includes('roksh.com') ||
            location.origin.includes('shop.aldi.hu')
                ? 'https://shopservice.roksh.com/'
                : this.baseUrl;
        this.baseUrl = location.origin.includes('master.roksh.com')
            ? 'https://shopservice-master.roksh.com/'
            : this.baseUrl;
        this.baseUrl =
            location.origin.includes('staging.roksh.com') ||
            location.origin.includes('justfun4') || //to demo purposes for MOL, we can remove this url later
            location.origin.includes('shopalditest')
                ? 'https://shopservice-staging.roksh.com/'
                : this.baseUrl;
        this.baseUrl = location.origin.includes('delivery-test')
            ? 'https://roksh-delivery-test-shopservice.azurewebsites.net/'
            : this.baseUrl;
        this.baseUrl = location.origin.includes('provider-roksh')
            ? 'https://provider-roksh-shopservice.azurewebsites.net/'
            : this.baseUrl;
        this.baseUrl = location.origin.includes('roksh.at')
            ? 'https://shopservice.roksh.at/'
            : this.baseUrl;
        this.baseUrl = location.origin.includes('master.roksh.at')
            ? 'https://shopservice-master.roksh.at/'
            : this.baseUrl;
        this.baseUrl = location.origin.includes('staging.roksh.at')
            ? 'https://shopservice-staging.roksh.at/'
            : this.baseUrl;
        this.baseUrl = location.origin.includes('b2b.roksh.com')
            ? 'https://weshopapp-roksh-shopservice-b2b.azurewebsites.net/'
            : this.baseUrl;

        if (location.origin.includes('roksh-application')) {
            this.baseUrl = ServiceBaseService.getMobileBaseUrl() + '/';
        }

        this.baseUrl =
            localStorage.getItem('remoteBackendForDevelopment')?.length > 0
                ? localStorage.getItem('remoteBackendForDevelopment')
                : this.baseUrl;

        ServiceBaseService._baseUrl = this.baseUrl;

        /***********siteimage urls************/
        this.siteImageUrl = location.origin.includes('localhost')
            ? 'https://weshopsiteimagesmaster.azureedge.net/'
            : this.siteImageUrl;
        this.siteImageUrl =
            location.origin.includes('roksh.com') ||
            location.origin.includes('gojaja') ||
            location.origin.includes('shop.aldi.hu')
                ? 'https://weshopsiteimages.azureedge.net/'
                : this.siteImageUrl;
        this.siteImageUrl = location.origin.includes('master.roksh.com')
            ? 'https://weshopsiteimagesmaster.azureedge.net/'
            : this.siteImageUrl;
        this.siteImageUrl =
            location.origin.includes('staging.roksh.com') ||
            location.origin.includes('justfun4') || //to demo purposes for MOL, we can remove this url later
            location.origin.includes('shopalditest')
                ? 'https://weshopsiteimagesmaster.azureedge.net/'
                : this.siteImageUrl;
        this.siteImageUrl = location.origin.includes('delivery-test')
            ? 'https://weshopsiteimagesmaster.azureedge.net/'
            : this.siteImageUrl;
        this.siteImageUrl = location.origin.includes('provider-test')
            ? 'https://weshopsiteimagesmaster.azureedge.net/'
            : this.siteImageUrl;
        this.siteImageUrl = location.origin.includes('roksh.at')
            ? 'https://atweshopsiteimages.azureedge.net/'
            : this.siteImageUrl;
        this.siteImageUrl = location.origin.includes('master.roksh.at')
            ? 'https://atweshopsiteimagesmaster.azureedge.net/'
            : this.siteImageUrl;
        this.siteImageUrl = location.origin.includes('staging.roksh.at')
            ? 'https://atweshopsiteimagesmaster.azureedge.net/'
            : this.siteImageUrl;
        this.siteImageUrl = location.origin.includes('b2b.roksh.com')
            ? 'https://weshopsiteimagesb2b.azureedge.net/'
            : this.siteImageUrl;

        if (location.origin.includes('roksh-application')) {
            this.siteImageUrl = ServiceBaseService.getMobileSiteImageUrl();
        }

        /***********mediaservice urls************/
        this.mediaServiceUrl = location.origin.includes('localhost')
            ? 'https://productimagesmaster.azureedge.net/'
            : this.mediaServiceUrl;
        this.mediaServiceUrl =
            location.origin.includes('roksh.com') ||
            location.origin.includes('gojaja') ||
            location.origin.includes('shop.aldi.hu')
                ? 'https://huproductimages.azureedge.net/'
                : this.mediaServiceUrl;
        this.mediaServiceUrl = location.origin.includes('master.roksh.com')
            ? 'https://productimagesmaster.azureedge.net/'
            : this.mediaServiceUrl;
        this.mediaServiceUrl =
            location.origin.includes('staging.roksh.com') ||
            location.origin.includes('justfun4') || //to demo purposes for MOL, we can remove this url later
            location.origin.includes('shopalditest')
                ? 'https://productimagesmaster.azureedge.net/'
                : this.mediaServiceUrl;
        this.mediaServiceUrl = location.origin.includes('delivery-test')
            ? 'https://productimagesmaster.azureedge.net/'
            : this.mediaServiceUrl;
        this.mediaServiceUrl = location.origin.includes('provider-test')
            ? 'https://productimagesmaster.azureedge.net/'
            : this.mediaServiceUrl;
        this.mediaServiceUrl = location.origin.includes('roksh.at')
            ? 'https://atproductimages.azureedge.net/'
            : this.mediaServiceUrl;
        this.mediaServiceUrl = location.origin.includes('master.roksh.at')
            ? 'https://atproductimagesmaster.azureedge.net/'
            : this.mediaServiceUrl;
        this.mediaServiceUrl = location.origin.includes('staging.roksh.at')
            ? 'https://atproductimagesmaster.azureedge.net/'
            : this.mediaServiceUrl;
        this.mediaServiceUrl = location.origin.includes('b2b.roksh.com')
            ? 'https://huproductimagesb2b.azureedge.net/'
            : this.mediaServiceUrl;

        if (location.origin.includes('roksh-application')) {
            this.mediaServiceUrl = ServiceBaseService.getMobileProductImageUrl();
        }

        /************countrycode**************/
        //ServiceBaseService.countrycode =
        //    location.origin.includes('at-') || location.origin.includes('roksh.at')
        //        ? CountryCodes.at
        //        : ServiceBaseService.countrycode;

        /************countryiso**************/
        this.checkoutPhoneCountries =
            location.origin.includes('at-') || location.origin.includes('roksh.at')
                ? [CountryISO.Austria]
                : this.checkoutPhoneCountries;

        this.revolutmode =
            (location.origin.includes('roksh.com') ||
                location.origin.includes('gojaja') ||
                location.origin.includes('roksh.at') ||
                location.origin.includes('shop.aldi.hu')) &&
            !location.origin.includes('staging.roksh') &&
            !location.origin.includes('master.roksh') &&
            !location.origin.includes('draft.roksh') &&
            !location.origin.includes('shopalditest.roksh.com')
                ? 'prod'
                : this.revolutmode;
    }

    public static getCountryCode(): CountryCodes {
        return this.countrycode;
    }

    public static getLanguageCode(): string {
        return this.languagecode;
    }

    public static getMobileLanguageCode(): string {
        return localStorage.getItem('locale');
    }

    public static getMobileBaseUrl(): string {
        if (localStorage.getItem('remoteBackendForDevelopment')?.length > 0) {
            return localStorage.getItem('remoteBackendForDevelopment');
        }

        if (location.origin.includes('localhost')) {
            return 'https://localhost:5001';
        }

        const locale =
            localStorage.getItem('locale') ?? window.navigator.language.split('-')[0];
        if (location.origin.includes('staging') || location.origin.includes('draft')) {
            if (locale === 'hu') {
                return 'https://shopservice-staging.roksh.com';
            } else if (locale === 'de') {
                return 'https://shopservice-staging.roksh.at';
            }
        }
        if (location.origin.includes('master') || location.origin.includes('draft')) {
            if (locale === 'hu') {
                return 'https://shopservice-master.roksh.com';
            } else if (locale === 'de') {
                return 'https://shopservice-master.roksh.at';
            }
        } else {
            if (locale === 'hu' && location.origin.includes('roksh.com')) {
                return 'https://shopservice.roksh.com';
            } else if (locale === 'hu' && location.origin.includes('gojaja')) {
                return 'https://shopservice.gojaja.com';
            } else if (locale === 'de') {
                return 'https://shopservice.roksh.at';
            }
        }
        return 'https://localhost:5001';
    }

    public static getDateCode(): string {
        return this.datecode;
    }

    public static getMobileSiteImageUrl(): string {
        if (location.origin.includes('localhost')) {
            return 'https://weshopsiteimagesmaster.azureedge.net/';
        }

        const locale =
            localStorage.getItem('locale') ?? window.navigator.language.split('-')[0];
        if (location.origin.includes('staging') || location.origin.includes('draft')) {
            if (locale === 'hu') {
                return 'https://weshopsiteimagesmaster.azureedge.net/';
            } else if (locale === 'de') {
                return 'https://atweshopsiteimagesmaster.azureedge.net/';
            }
        } else {
            if (locale === 'hu') {
                return 'https://weshopsiteimages.azureedge.net/';
            } else if (locale === 'de') {
                return 'https://atweshopsiteimages.azureedge.net/';
            }
        }
        return 'https://weshopsiteimagesmaster.azureedge.net/';
    }

    public static getMobileProductImageUrl(): string {
        if (location.origin.includes('localhost')) {
            return 'https://productimagesmaster.azureedge.net/';
        }

        const locale =
            localStorage.getItem('locale') ?? window.navigator.language.split('-')[0];
        if (location.origin.includes('staging') || location.origin.includes('draft')) {
            if (locale === 'hu') {
                return 'https://productimagesmaster.azureedge.net/';
            } else if (locale === 'de') {
                return 'https://atproductimagesmaster.azureedge.net/';
            }
        } else {
            if (locale === 'hu') {
                return 'https://huproductimages.azureedge.net/';
            } else if (locale === 'de') {
                return 'https://atproductimages.azureedge.net/';
            }
        }
        return 'https://productimagesmaster.azureedge.net/';
    }

    public static getTranslationBaseUrl(): string {
        /***********translationBaseUrl urls************/
        this.translationBaseUrl = location.origin.includes('localhost')
            ? 'https://at-roksh-backend-master.roksh.at/'
            : this.translationBaseUrl;
        this.translationBaseUrl =
            location.origin.includes('roksh.com') ||
            location.origin.includes('gojaja') ||
            location.origin.includes('shop.aldi.hu')
                ? 'https://roksh-backend.roksh.com/'
                : this.translationBaseUrl;
        this.translationBaseUrl = location.origin.includes('master.roksh.com')
            ? 'https://roksh-backend-master.roksh.com/'
            : this.translationBaseUrl;
        this.translationBaseUrl =
            location.origin.includes('staging.roksh.com') ||
            location.origin.includes('roksh-application-staging') ||
            location.origin.includes('roksh-application-draft') ||
            location.origin.includes('roksh-application-master') ||
            location.origin.includes('justfun4') || //to demo purposes for MOL, we can remove this url later
            location.origin.includes('shopalditest')
                ? 'https://roksh-backend-staging.roksh.com/'
                : this.translationBaseUrl;
        this.translationBaseUrl = location.origin.includes('delivery-test')
            ? 'https://roksh-backend.roksh.com/'
            : this.translationBaseUrl;
        this.translationBaseUrl = location.origin.includes('provider-test')
            ? 'https://roksh-backend.roksh.com/'
            : this.translationBaseUrl;
        this.translationBaseUrl = location.origin.includes('roksh.at')
            ? 'https://roksh-backend.roksh.at/'
            : this.translationBaseUrl;
        this.translationBaseUrl = location.origin.includes('master.roksh.at')
            ? 'https://at-roksh-backend-master.roksh.at/'
            : this.translationBaseUrl;
        this.translationBaseUrl = location.origin.includes('staging.roksh.at')
            ? 'https://roksh-backend-staging.roksh.at/'
            : this.translationBaseUrl;
        this.translationBaseUrl = location.origin.includes('b2b.roksh.com')
            ? 'https://roksh-backend-b2b.roksh.com/'
            : this.translationBaseUrl;

        return this.translationBaseUrl;
    }

    public static getStaticBaseUrl(): string {
        let baseUrl = 'https://localhost:5001';
        baseUrl = location.origin.includes('localhost')
            ? 'https://localhost:5001'
            : baseUrl;
        baseUrl = location.origin.includes('gojaja')
            ? 'https://shopservice.gojaja.com'
            : baseUrl;
        baseUrl =
            location.origin.includes('roksh.com') ||
            location.origin.includes('shop.aldi.hu')
                ? 'https://shopservice.roksh.com'
                : baseUrl;
        baseUrl = location.origin.includes('master.roksh.com')
            ? 'https://shopservice-master.roksh.com'
            : baseUrl;
        baseUrl =
            location.origin.includes('staging.roksh.com') ||
            location.origin.includes('justfun4') || //to demo purposes for MOL, we can remove this url later
            location.origin.includes('shopalditest')
                ? 'https://shopservice-staging.roksh.com'
                : baseUrl;
        baseUrl = location.origin.includes('delivery-test')
            ? 'https://roksh-delivery-test-shopservice.azurewebsites.net'
            : baseUrl;
        baseUrl = location.origin.includes('provider-roksh')
            ? 'https://provider-roksh-shopservice.azurewebsites.net'
            : baseUrl;
        baseUrl = location.origin.includes('roksh.at')
            ? 'https://shopservice.roksh.at'
            : baseUrl;
        baseUrl = location.origin.includes('master.roksh.at')
            ? 'https://shopservice-master.roksh.at'
            : baseUrl;
        baseUrl = location.origin.includes('staging.roksh.at')
            ? 'https://shopservice-staging.roksh.at'
            : baseUrl;
        baseUrl = location.origin.includes('b2b.roksh.com')
            ? 'https://weshopapp-roksh-shopservice-b2b.azurewebsites.net'
            : baseUrl;

        if (location.origin.includes('roksh-application')) {
            baseUrl = this.getMobileBaseUrl();
        }

        return baseUrl;
    }

    getBaseUrl(): string {
        return this.baseUrl;
    }

    getSiteImageUrl(): string {
        return this.siteImageUrl;
    }

    getMediaServiceUrl(): string {
        return this.mediaServiceUrl;
    }

    getCheckoutPhoneCountries(): CountryISO[] {
        return location.origin.includes('roksh.at')
            ? [CountryISO.Austria]
            : [CountryISO.Hungary];
    }

    getRevolutMode(): string {
        return this.revolutmode;
    }

    public static getOwnWebShopProviderCode(): string {
        /***********OwnWebShop ProviderCode************/

        // for testing
        //if (location.origin.includes('localhost')) this.ownWebShopProviderCode = 'aldi';

        if (location.origin.includes('shop.aldi.hu'))
            this.ownWebShopProviderCode = 'aldi';
        if (location.origin.includes('justfun4'))
            this.ownWebShopProviderCode = 'mollmarket-hu'; //to demo purposes for MOL, we can remove this url later
        if (location.origin.includes('shopalditest'))
            this.ownWebShopProviderCode = 'aldi';
        if (location.origin.includes('hofer.roksh.at'))
            this.ownWebShopProviderCode = 'hofer';
        return this.ownWebShopProviderCode;
    }

    public static isProviderOwnWebShop(): boolean {
        let isProviderOwnWebShop = false;
        // for testing
        //if (location.origin.includes('localhost')) isProviderOwnWebShop = true;
        if (location.origin.includes('shop.kikotokereso.hu')) isProviderOwnWebShop = true;
        if (location.origin.includes('shop.aldi.hu')) isProviderOwnWebShop = true;
        if (location.origin.includes('justfun4')) isProviderOwnWebShop = true; //to demo purposes for MOL, we can remove this url later
        if (location.origin.includes('shopalditest')) isProviderOwnWebShop = true;
        if (location.origin.includes('hofer.roksh.at')) isProviderOwnWebShop = true;
        return isProviderOwnWebShop;
    }

    public static getAppInsightKey(): string {
        ///Just the production environments
        if (
            location.origin.includes('https://roksh') ||
            location.origin.includes('gojaja') ||
            location.origin.includes('https://www.roksh') ||
            location.origin.includes('https://shop.aldi.hu') ||
            location.origin.includes('https://www.shop.aldi.hu') ||
            location.origin.includes('https://hofer.roksh.at') ||
            location.origin.includes('https://www.hofer.roksh.at') ||
            location.origin.includes('https://roksh-application.roksh.com') ||
            location.origin.includes('https://www.roksh-application.roksh.com')
        )
            this.appInsightsAPIKey = 'd761f890-13f7-4b21-bb1b-bcc8a996bc6d';

        if (location.origin.includes('localhost'))
            this.appInsightsAPIKey = '582dd3ed-7db6-48cf-8ef1-a27ea49acf53'; //developer insights
        return this.appInsightsAPIKey;
    }

    public static getMobileTranslationBaseUrl(): string {
        const locale =
            localStorage.getItem('locale') ?? window.navigator.language.split('-')[0];

        if (
            location.origin.includes('localhost') ||
            location.origin.includes('staging') ||
            location.origin.includes('draft')
        ) {
            if (locale === 'hu') {
                return 'https://roksh-backend-staging.roksh.com/';
            } else if (locale === 'de') {
                return 'https://roksh-backend-staging.roksh.at/';
            }
        } else {
            if (locale === 'hu') {
                return 'https://roksh-backend.roksh.com/';
            } else if (locale === 'de') {
                return 'https://roksh-backend.roksh.at/';
            }
        }

        return 'https://roksh-backend-staging.roksh.com/';
    }
}
