<div class="order-container px-3 px-md-5">
    <div class="form-container pt-4 d-flex justify-content-between align-items-end">
        <h3 class="pb-0 mb-0 pt-3">
            {{ 'common.my-orders' | translate }}
        </h3>
        <div class="d-flex justify-content-end">
            <a
                href="{{
                    'static-links.roksh-knowledge'
                        | translate
                        | providerTheme : 'static-links.roksh-knowledge'
                        | async
                }}"
                target="_blank"
                class="pull-right"
                ><u>{{ 'common.i-have-question' | translate }}</u></a
            >
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="row">
        <div class="col-12 col-md-5">
            <lib-provider-select
                (providerChanged)="getMyOrders($event)"
            ></lib-provider-select>
        </div>
    </div>

    <div *ngIf="dividingLine">
        <h2 class="font-weight-600 mt-3">
            {{ 'orders.in-progress' | translate }}
        </h2>
    </div>

    <div *ngFor="let order of myOpenOrders; let i = index">
        <app-order-card [order]="order" [isOneOrderView]="false"></app-order-card>
    </div>

    <div *ngIf="dividingLine">
        <h2 class="font-weight-600 mt-3">
            {{ 'orders.closed-orders' | translate }}
        </h2>
    </div>

    <div *ngFor="let order of myClosedOrders; let i = index">
        <app-order-card [order]="order" [isOneOrderView]="false"></app-order-card>
    </div>
</div>
