import { Component } from '@angular/core';
import { CreateDialogService } from '../../../services/create-dialog.service';

@Component({
    selector: 'app-add-address-button',
    templateUrl: './add-address-button.component.html',
    styleUrls: ['./add-address-button.component.css'],
})
export class AddAddressButtonComponent {
    constructor(private createDialogService: CreateDialogService) {}

    onClickGiveAddress() {
        this.createDialogService.openAddressDialog().afterClosed().subscribe();
    }
}
