<div class="py-3 pointer" (click)="openProviderChooser()" *ngIf="!doNotShow">
    <div
        class="d-flex border-bottom border-top py-2 align-items-center justify-content-between"
    >
        <div
            class="provide-img d-flex align-items-center justify-content-center p-2 me-3"
            [class.border]="selectedProvider"
        >
            <img
                *ngIf="selectedProvider; else noProvider"
                src="{{ siteImageUrl }}logos/{{
                    selectedProvider.providerCode.toLowerCase()
                }}-logo-s.png"
                alt="logo"
                class="w-100 h-100"
            />
            <ng-template #noProvider>
                <img
                    class="w-100 h-100"
                    src="{{ siteImageUrl }}images/shared-img/provider-images/provider.svg"
                    alt="provider"
                />
            </ng-template>
        </div>
        <div class="w-100">
            <h5 class="p-0 m-0 fw-bold">
                {{
                    selectedProvider
                        ? selectedProvider.nameToShow
                        : ('checkout.choose-shop' | translate)
                }}
            </h5>
            <small class="text-grey m-0 lh-sm">
                {{ infoText | translate }}
            </small>
        </div>
        <div>
            <img
                src="{{ siteImageUrl }}images/shared-img/provider-images/chevron.svg"
                alt="chevron"
            />
        </div>
    </div>
</div>
