import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BasketService } from '../../../services/basket.service';
import { ServiceBaseService } from '../../../services/service-base.service';

@Component({
    selector: 'lib-discount-summary',
    templateUrl: './discount-summary.component.html',
    styleUrls: ['./discount-summary.component.scss'],
})
export class DiscountSummaryComponent implements OnInit {
    @Output() showRokshDiscountInfoEmitter = new EventEmitter<boolean>();

    private currentRokshDiscountLevelBasketValue: number;
    nextRokshDiscountLevelBasketValue: number;
    currentTotalPrice: number;
    currentDepositPrice: number;
    nextRokshDiscountLevelIndex: number;
    currentRokshDiscount: number;
    countryCode = ServiceBaseService.getCountryCode();

    constructor(private basketService: BasketService) {}

    ngOnInit(): void {
        this.basketService.nextRokshDiscountLevelBasketValueSubject.subscribe(
            (nextRokshDiscountLevelBasketValue) => {
                this.nextRokshDiscountLevelBasketValue =
                    nextRokshDiscountLevelBasketValue;
            }
        );

        this.basketService.currentTotalPriceSubject.subscribe((currentTotalPrice) => {
            this.currentTotalPrice = currentTotalPrice;
        });

        this.basketService.currentDepositPriceSubject.subscribe((currentDepositPrice) => {
            this.currentDepositPrice = currentDepositPrice;
        });

        this.basketService.currentRokshDiscountLevelBasketValueSubject.subscribe(
            (currentRokshDiscountLevelBasketValue) => {
                this.currentRokshDiscountLevelBasketValue =
                    currentRokshDiscountLevelBasketValue;
            }
        );

        this.basketService.nextRokshDiscountLevelIndexSubject.subscribe(
            (nextRokshDiscountLevelIndex) => {
                this.nextRokshDiscountLevelIndex = nextRokshDiscountLevelIndex;
            }
        );

        this.basketService.currentRokshDiscountSubject.subscribe(
            (currentRokshDiscount) => {
                this.currentRokshDiscount = currentRokshDiscount;
            }
        );
    }

    showRokshDiscountInfo() {
        this.showRokshDiscountInfoEmitter.next(true);
    }

    public get getProgressBarLength(): number {
        if (
            this.nextRokshDiscountLevelBasketValue <
            this.currentTotalPrice + this.currentDepositPrice
        ) {
            return 100;
        }

        return (
            ((this.currentTotalPrice +
                this.currentDepositPrice -
                this.currentRokshDiscountLevelBasketValue) /
                (this.nextRokshDiscountLevelBasketValue -
                    this.currentRokshDiscountLevelBasketValue)) *
            100
        );
    }
}
