<h1 mat-dialog-title class="title">
    {{ title }}
</h1>

<div mat-dialog-actions class="d-flex justify-content-around mb-1">
    <div class="row w-100">
        <div class="col-6">
            <button class="back-btn btn-rounded mb-1" (click)="onDismiss()">
                {{ 'common.back' | translate }}
            </button>
        </div>
        <div class="col-6">
            <button class="btn cancel-btn mb-1" (click)="onConfirm()">
                {{ 'common.delete' | translate }}
            </button>
        </div>
    </div>
</div>
