<mat-card class="mb-3">
    <div class="row flex-nowrap w-100">
        <div class="col-12 col-lg-6 p-2 p-md-4 header position-relative">
            <div class="d-flex justify-content-between">
                <div class="w-50 d-flex flex-column justify-content-between h-100">
                    <div class="h-100">
                        <div class="font-weight-bold text-size-22">
                            <span>{{ myOrder.OrderCode }}</span>
                        </div>
                        <div class="text-size-16">
                            <span class="text-black-50"
                                >{{ 'common.quantity' | translate }}:</span
                            >
                            &nbsp;
                            <span>{{ myOrder.OrderedItems?.length }} </span>
                            {{ 'common.piece' | translate }}
                        </div>

                        <div
                            class="badge-container-desktop"
                            *ngIf="getDiscountPrice() > 0"
                        >
                            <span class="badge badge-info">
                                <span class="discount-text">{{
                                    'common.roksh-discount-badge-order-list' | translate
                                }}</span>
                                <span>
                                    -
                                    {{ getDiscountPrice() | currencyFormatted }}
                                    {{ 'common.currency' | translate }}</span
                                >
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    class="w-50 text-end position-relative d-flex flex-column justify-content-around"
                >
                    <div class="text-black-50 calendar text-size-13">
                        <fa-icon [icon]="faCalendar"></fa-icon>
                        <span class="ms-1"
                            >{{ myOrder.DeliveryDay }} - &nbsp;{{
                                myOrder.DeliveryTimeSlot
                            }}
                            &nbsp;{{ 'common.hour' | translate }}</span
                        >
                    </div>
                    <div class="no-wrap">
                        <div>
                            <span class="text-black-50">
                                <strong class="text-size-12">{{
                                    (
                                        'order-list.ordercustomerstatus-' +
                                            OrderCustomerStatusStr
                                        | lowercase
                                        | translate
                                    ).toUpperCase() + ' '
                                }}</strong>
                            </span>
                            <img
                                src="assets/svg/{{ OrderCustomerStatusStr }}.png"
                                alt="{{ 'info.alt-desktop-logo' | translate }}"
                                class="me-1"
                                style="height: 12px"
                            />
                        </div>
                        <div class="header-price text-black-50">
                            <span style="white-space: nowrap"
                                >{{ 'common.delivery-final-price' | translate }}:</span
                            >
                            &nbsp;
                            <span style="white-space: nowrap">
                                {{ deliveryFinalPrice }}
                                {{ 'common.currency' | translate }}
                            </span>
                        </div>
                        <div class="header-price font-weight-bold text-black-50">
                            <span>{{ 'common.tip' | translate }}:</span>
                            &nbsp;
                            <span>
                                {{ myOrder.Tip | currencyFormatted }}
                                {{ 'common.currency' | translate }}
                            </span>
                        </div>
                        <div class="header-price font-weight-bold text-black-50">
                            <span>{{ 'common.system-usage-fee' | translate }}:</span>
                            &nbsp;
                            <span>
                                {{ myOrder.SystemUsageFee | currencyFormatted }}
                                {{ 'common.currency' | translate }}
                            </span>
                        </div>
                        <div class="header-price text-black-50">
                            <span>{{ 'common.delivery-price' | translate }}:</span>
                            &nbsp;
                            <span>
                                {{ myOrder.DeliveryFee | currencyFormatted }}
                                {{ 'common.currency' | translate }}
                            </span>
                        </div>

                        <div class="header-price font-weight-bold text-black-50">
                            <span>{{ 'common.final-price' | translate }}:</span>
                            &nbsp;
                            <span>
                                {{
                                    myOrder.CustomerPaidTotalPrice ?? finalPrice
                                        | currencyFormatted
                                }}
                                {{ 'common.currency' | translate }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-100 d-flex pt-3 align-items-end">
                <button
                    class="add-to-cart-btn btn-rounded mb-1 text-size-16 font-weight-600"
                    (click)="onAddAll(myOrder)"
                >
                    <fa-icon
                        *ngIf="myOrder.AddIsInProgress"
                        [icon]="faSpinner"
                        [spin]="true"
                    ></fa-icon>
                    <fa-icon
                        *ngIf="!myOrder.AddIsInProgress"
                        [icon]="faShoppingCart"
                    ></fa-icon>
                    {{ massAddButtonText }}
                </button>
                <div *ngIf="!isOneOrderView">
                    <a
                        class="text-orange text-size-16 details-btn ps-5"
                        [routerLink]="[
                            '/',
                            'router.user' | translate,
                            'router.my-order' | translate
                        ]"
                        [queryParams]="{
                            orderCode: order.OrderCode,
                            providerName: order.ProviderName
                        }"
                        >{{ 'common.go-to-details' | translate }}</a
                    >
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 details">
            <div class="row border-bottom">
                <div class="mobile-screen-custom-row text-start">
                    <div class="col-10 p-2">
                        <strong>{{ 'order-list.delivery-address' | translate }}:</strong
                        >&nbsp;
                        <span class="text-black-50">{{
                            myOrder.DeliveryAddressSummary
                        }}</span>
                    </div>
                    <div *ngIf="myOrder.IsModifyDeliveryAndAddress" class="col-2 p-2">
                        <button mat-button [matMenuTriggerFor]="menuDeliveryAddress">
                            <fa-icon
                                [icon]="faEllipsisV"
                                size="xs"
                                class="icon-color"
                            ></fa-icon>
                        </button>
                        <mat-menu #menuDeliveryAddress="matMenu" xPosition="before">
                            <button
                                mat-menu-item
                                (click)="onClickModifyDelveryAddressAndTimeSlot(myOrder)"
                            >
                                {{ 'common.edit' | translate }}
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>

            <div class="row border-bottom">
                <div class="mobile-screen-custom-row text-start">
                    <div class="col-10 p-2">
                        <strong>{{ 'order-list.contact-data' | translate }}:</strong
                        >&nbsp;
                        <span class="text-black-50">
                            {{ myOrder.OrdererName }}, {{ myOrder.OrdererPhone }},
                            {{ myOrder.OrdererEmail }}
                        </span>
                    </div>
                    <div *ngIf="myOrder.IsModifyCommentAndContact" class="col-2 p-2">
                        <button mat-button [matMenuTriggerFor]="menu">
                            <fa-icon
                                [icon]="faEllipsisV"
                                size="xs"
                                class="icon-color"
                            ></fa-icon>
                        </button>
                        <mat-menu #menu="matMenu" xPosition="before">
                            <button
                                mat-menu-item
                                (click)="onMenuClickEditContactDetails(myOrder)"
                            >
                                {{ 'common.edit' | translate }}
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>

            <div class="row border-bottom">
                <div class="mobile-screen-custom-row text-start">
                    <div class="col-10 p-2">
                        <strong>{{ 'order-list.delivery-timeslot' | translate }}:</strong
                        >&nbsp;
                        <span class="text-black-50"
                            >{{ myOrder.DeliveryDay }} &nbsp;{{
                                myOrder.DeliveryTimeSlot
                            }}
                            &nbsp;{{ 'common.hour' | translate }}</span
                        >
                    </div>
                    <div *ngIf="myOrder.IsModifyDeliveryAndAddress" class="col-2 p-2">
                        <button mat-button [matMenuTriggerFor]="menuDelivery">
                            <fa-icon
                                [icon]="faEllipsisV"
                                size="xs"
                                class="icon-color"
                            ></fa-icon>
                        </button>
                        <mat-menu #menuDelivery="matMenu" xPosition="before">
                            <button
                                mat-menu-item
                                (click)="onClickModifyTimeSlot(myOrder)"
                            >
                                {{ 'common.edit' | translate }}
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div class="border-bottom text-start">
                <div>
                    <div class="py-2" *ngFor="let delivery of myOrder.DeliveryInvoices">
                        <span text-black-50
                            >{{ 'order-list.delivery-invoice' | translate }}:
                        </span>
                        <a target="_blank" href="{{ delivery }}"
                            ><u class="color-turquoise">{{
                                'order-list.open' | translate
                            }}</u></a
                        >
                    </div>
                </div>
                <div>
                    <div class="py-2" *ngFor="let product of myOrder.ProductInvoices">
                        <span text-black-50
                            >{{ 'order-list.product-invoice' | translate }}:
                        </span>
                        <a target="_blank" href="{{ product }}"
                            ><u class="color-turquoise">{{
                                'order-list.open' | translate
                            }}</u></a
                        >
                    </div>
                </div>
                <div>
                    <div class="py-2" *ngFor="let modify of myOrder.ModifyInvoices">
                        <span text-black-50
                            >{{ 'order-list.modify-invoice' | translate }}:
                        </span>
                        <a target="_blank" href="{{ modify }}"
                            ><u class="color-turquoise">{{
                                'order-list.open' | translate
                            }}</u></a
                        >
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="mobile-screen-custom-row text-start">
                    <div class="col-10 p-2">
                        <strong
                            >{{ 'order-list.comment-to-deliverer' | translate }}:</strong
                        >&nbsp;
                        <span class="text-black-50"
                            >{{ myOrder.Comment }} <br />{{
                                myOrder.ExtraProductItemsText
                            }}
                        </span>
                    </div>
                    <div *ngIf="myOrder.IsModifyCommentAndContact" class="col-2 p-2">
                        <button mat-button [matMenuTriggerFor]="menuComment">
                            <fa-icon
                                [icon]="faEllipsisV"
                                size="xs"
                                class="icon-color"
                            ></fa-icon>
                        </button>
                        <mat-menu #menuComment="matMenu" xPosition="before">
                            <button
                                mat-menu-item
                                (click)="
                                    onMenuClickEditComment(
                                        myOrder.OrderCode,
                                        myOrder.Comment
                                    )
                                "
                            >
                                {{ 'common.edit' | translate }}
                            </button>
                            <button
                                mat-menu-item
                                (click)="onMenuClickDeleteComment(myOrder.OrderCode)"
                            >
                                {{ 'common.delete' | translate }}
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div *ngIf="myOrder.IsShowCancelOrderButton" class="row">
                <div class="border-bottom col-12"></div>
                <div class="mb-0">
                    <a class="text-black-50" (click)="orderCancellation(myOrder)">
                        <u> {{ 'order-list.cancel-order' | translate }} </u></a
                    >
                </div>
            </div>
        </div>
    </div>
</mat-card>

<div *ngIf="isOneOrderView" class="row">
    <div class="col-12 col-lg-6 me-lg-0 pe-lg-0 mobile-screen-product">
        <div *ngIf="addBtnAvailable">
            <hr />

            <div class="mx-auto text-center mb-2 mb-md-0 pointer add-new-item">
                <a (click)="onAddItem()">
                    {{ 'common.add' | translate }}
                    <img
                        src="assets/svg/add.png"
                        alt="{{ 'info.alt-desktop-logo' | translate }}"
                        class="me-1"
                        style="height: 16px"
                    />
                </a>
            </div>

            <hr />
        </div>

        <div *ngIf="realTimeIsOn; else notCollecting">
            <div *ngIf="this.myOrder.OnlineConsultation && timeLeft">
                <button
                    *ngIf="myOrder.ReplacementItems.length === 0"
                    class="btn-success btn-wide btn-rounded me-4 my-2"
                    (click)="approveOrder()"
                >
                    {{ 'orders.approve-order' | translate }}
                </button>
                {{ formattedTimer }}
            </div>
            <div *ngIf="myOrder.ReplacementItems.length > 0">
                <div class="color-turquoise mb-2 mt-3 font-size-18">
                    <strong>{{ 'orders.approve-replacement-items' | translate }}</strong>
                </div>
                <div *ngFor="let item of myOrder.ReplacementItems; let i = index">
                    <app-orderitem-card
                        [orderItem]="item"
                        [order]="myOrder"
                        [btnReplace]="true"
                    ></app-orderitem-card>
                </div>
            </div>
            <div *ngIf="myOrder.MissingItems.length > 0">
                <div class="color-turquoise mb-2 mt-3 font-size-18">
                    <strong>{{ 'orders.missing-items-title' | translate }}</strong>
                </div>
                <div class="my-1">
                    {{ 'orders.missing-items-text' | translate }}
                </div>
                <div *ngFor="let item of myOrder.MissingItems; let i = index">
                    <app-orderitem-card
                        [orderItem]="item"
                        [order]="myOrder"
                    ></app-orderitem-card>
                </div>
            </div>
            <div *ngIf="myOrder.CollectedItems.length > 0">
                <div class="color-turquoise mb-2 mt-3 font-size-18">
                    <strong>{{ 'orders.collected-items-title' | translate }}</strong>
                </div>
                <div *ngFor="let item of myOrder.CollectedItems; let i = index">
                    <app-orderitem-card
                        [orderItem]="item"
                        [order]="myOrder"
                    ></app-orderitem-card>
                </div>
            </div>
            <div *ngIf="myOrder.UnCollectedItems.length > 0">
                <div class="color-turquoise mb-2 mt-3 font-size-18">
                    <strong>{{ 'orders.not-collected-items-title' | translate }}</strong>
                </div>
                <div *ngFor="let item of myOrder.UnCollectedItems; let i = index">
                    <app-orderitem-card
                        [orderItem]="item"
                        [order]="myOrder"
                    ></app-orderitem-card>
                </div>
            </div>
        </div>
        <ng-template #notCollecting>
            <div *ngFor="let item of myOrder.OrderedItems; let i = index">
                <app-orderitem-card
                    [orderItem]="item"
                    [order]="myOrder"
                ></app-orderitem-card>
            </div>
        </ng-template>
    </div>
</div>
