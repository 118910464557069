import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { faHeart as fasHeart, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ProductDto, ProviderDepositProductDto } from '../../../index';
import { FavoriteListService } from '../../../services/favorite-list.service';
import { AuthenticationService } from '../../../auth/auth.service';
import { FormatterService } from '../../../services/formatter.service';
import { SessionService } from '../../../services/session/session.service';
import { BasketService } from '../../../services/basket.service';
import { ServiceBaseService } from '../../../services/service-base.service';
import { DataLayerGa4Service } from '../../../services/data-layer/data-layer-ga4.service';
import { ComponentType } from '@angular/cdk/overlay';
import { CountryCodes } from '../../../types/country-codes';

@Component({
    selector: 'lib-product-dto-card',
    templateUrl: './product-dto-card.component.html',
    styleUrls: ['./product-dto-card.component.scss'],
})
export class ProductDtoCardComponent implements OnInit, OnDestroy {
    @Input() isArchie = false;
    @Input() productProviderId: number;
    @Input() productProviderName: string;
    @Input() isShoppingListCard = false;
    @Input() isFavoriteListCard = false;
    @Input() product: ProductDto;
    @Input() masterBasketId: number;
    @Input() basketItemId: number;
    @Input() selectedQuantity: number;
    @Input() selectedShoppingListQuantity: number;
    @Input() commercialBlock = false;
    @Input() addressDialogComponent: ComponentType<unknown>;
    @Input() providerDialogComponent: ComponentType<unknown>;
    @Input() preferredWidth = '180px';
    @Output() addOrReplaceEmitter: EventEmitter<boolean> = new EventEmitter();
    @Output() onRemove: EventEmitter<any> = new EventEmitter();
    @Output() deleteEmitter = new EventEmitter<number>();
    @Output() cardClicked = new EventEmitter<number>();
    @ViewChild('img') img: ElementRef;
    isFavoriteProduct = false;
    favoriteBasketItemId: number;
    favoriteItemSub: Subscription;
    isFavoriteProgress = false;
    isAuth = false;
    authSub: Subscription;
    shop?: string;
    currentRokshDiscount: number;
    currentRokshDiscountLevelIndex: number;
    isProviderSelected: boolean;

    farHeart = farHeart;
    fasHeart = fasHeart;
    faSpinner = faSpinner;
    mediaServiceUrl = '';

    hasAnyProviderDepositProduct = false;
    notOptionalProviderDepositProduct: ProviderDepositProductDto;
    isMobile: boolean;
    tooltipId = (Math.random() + 1).toString(36).substring(7);
    discountPercentage: string;
    siteImageUrl: string;

    CountryCodes = CountryCodes;
    countryCode = this.CountryCodes.hu;
    public get isAtCountryCode(): boolean {
        return this.countryCode === CountryCodes.at;
    }

    constructor(
        private favoriteListService: FavoriteListService,
        private dataLayerGa4Service: DataLayerGa4Service,
        private authService: AuthenticationService,
        private formatterService: FormatterService,
        private route: ActivatedRoute,
        private sessionService: SessionService,
        private basketService: BasketService,
        baseService: ServiceBaseService
    ) {
        this.siteImageUrl = baseService.getSiteImageUrl();
        this.mediaServiceUrl = baseService.getMediaServiceUrl();
        this.countryCode = ServiceBaseService.getCountryCode();
        this.isMobile = this.sessionService.isMobile;
    }

    ngOnInit() {
        this.sessionService.isProviderSelectedForShopping.subscribe(
            (value) => (this.isProviderSelected = value)
        );

        if (this.product.providerDepositProductDtoList?.length > 0) {
            this.hasAnyProviderDepositProduct = true;
            this.notOptionalProviderDepositProduct =
                this.product.providerDepositProductDtoList.find(
                    (ProviderDepositProduct) =>
                        !ProviderDepositProduct.depositUnitTypeIsOptional
                );
        }

        this.discountPercentage = this.formatterService.percentageOfDiscount(
            this.product.selectedShopIsBulk,
            this.product.price,
            this.product.depositFee,
            this.product.unitPrice,
            this.product.originalPriceIfOffer
        );

        this.authSub = this.authService.getAuthStatusListener().subscribe((isAuth) => {
            this.isAuth = isAuth;
        });

        this.favoriteItemSub =
            this.favoriteListService.favoriteProductListObservable.subscribe(
                (favoriteList) => {
                    if (favoriteList != null) {
                        this.isFavoriteProduct = favoriteList.some((favoritItem) => {
                            if (
                                this.product.productID ===
                                favoritItem.originalProduct.productID
                            ) {
                                this.favoriteBasketItemId = favoritItem.basketItemId;
                                return true;
                            }
                            return false;
                        });
                    } else {
                        this.isFavoriteProduct = false;
                    }
                }
            );

        this.route.params.subscribe((params) => {
            this.shop = params['providercode'];
        });

        this.basketService.currentRokshDiscountSubject.subscribe(
            (currentRokshDiscount) => {
                this.currentRokshDiscount = currentRokshDiscount;
            }
        );

        this.basketService.currentRokshDiscountLevelIndexSubject.subscribe(
            (currentRokshDiscountLevelIndex) => {
                this.currentRokshDiscountLevelIndex = currentRokshDiscountLevelIndex;
            }
        );
    }

    onAddOrReplace(stat: any) {
        this.addOrReplaceEmitter.emit(stat);
    }

    onProductFavoriteChange() {
        if (!this.isFavoriteProgress) {
            this.isFavoriteProgress = true;
            if (this.isFavoriteProduct) {
                this.removeProductFromFavorite(+this.product.productID);
            } else {
                this.addProductToFavorite(+this.product.productID);
                this.dataLayerGa4Service.datalayerUniversalPush(
                    'Functions',
                    'Add to favorite',
                    this.product.productName
                );
            }
        }
    }

    private removeProductFromFavorite(productId: number) {
        this.favoriteListService.removeProductFromFavorite(productId).subscribe(
            () => {
                this.isFavoriteProgress = false;
                this.isFavoriteProduct = false;
                this.onRemove.emit(productId);
            },
            (err) => {
                this.isFavoriteProgress = false;
                console.error(err);
            }
        );
    }

    private addProductToFavorite(productId: number) {
        this.favoriteListService.addProductToFavorite(productId).subscribe(
            () => {
                this.isFavoriteProgress = false;
                this.isFavoriteProduct = true;
            },
            (err) => {
                this.isFavoriteProgress = false;
                console.error(err);
            }
        );
    }

    ngOnDestroy() {
        if (this.favoriteItemSub) {
            this.favoriteItemSub.unsubscribe();
        }
        if (this.authSub) {
            this.authSub.unsubscribe();
        }
    }

    onDelete(): void {
        this.deleteEmitter.next(
            this.product.productID ? this.product.productID : this.product['productID']
        );
    }

    onCardClicked(event: Event): void {
        this.cardClicked.emit(this.product.productID);
    }

    imageError(): void {
        (this.img.nativeElement as HTMLImageElement).src =
            this.siteImageUrl + 'images/shared-img/images/roksh_logo.svg';
    }
}
