<div class="p-4 h-100 position-relative overflow-y-sm-scroll">
    <div *ngIf="isNewChat">
        <h1 class="fw-bold" [innerHTML]="'archie.self-intruduction' | translate"></h1>
        <p class="mt-3">
            {{ 'archie.choose-a-provider' | translate }}
            {{ 'archie.max-provider' | translate }}
        </p>
    </div>
    <div *ngIf="!isNewChat">
        <h1 class="fw-bold">
            {{ 'common.choose-from-our-shops' | translate }}
        </h1>
        <p class="mt-3">
            {{ 'archie.max-provider' | translate }}
        </p>
    </div>
    <div class="provider-container overflow-y-md-scroll pt-3" *ngIf="providers">
        <form [formGroup]="formGroup" class="d-flex flex-column flex-md-row flex-wrap">
            <label
                *ngFor="let provider of providers"
                class="py-2 label-transition"
                [class.opacity-50]="
                    selectedProviders.length === MAXIMUM_PROVIDERS_AMOUNT &&
                    !selectedProviders.includes(provider)
                "
            >
                <input
                    #inputElement
                    type="checkbox"
                    [checked]="false"
                    class="d-none"
                    formControlName="provider"
                    [value]="provider"
                    (change)="onSelect($event, provider)"
                />
                <div
                    class="d-flex flex-md-column align-items-center mx-md-3 provider-max-width justify-content-start justify-content-md-between"
                >
                    <div
                        class="provider-image p-2 d-flex justify-content-center align-items-center position-relative"
                    >
                        <img
                            src="{{ siteImageUrl }}logos/{{ provider.name }}-logo-s.png"
                            alt="provider logo"
                            class="h-100 w-100"
                        />
                        <div
                            class="unchecked position-absolute d-flex justify-content-center align-items-center"
                            [class.checked]="inputElement.checked"
                        >
                            <fa-icon
                                [icon]="faCheck"
                                size="xs"
                                *ngIf="inputElement.checked"
                            ></fa-icon>
                        </div>
                    </div>
                    <h6 class="mx-3 font-weight-600 provider-name">
                        {{ provider.name }}
                    </h6>
                </div>
            </label>
            <div class="fake-item d-block d-md-none"></div>
        </form>
    </div>
    <div
        class="position-fixed footer-container w-100 p-4 d-flex justify-content-md-start"
    >
        <button
            *ngIf="isNewChat"
            class="btn btn-outline-warning w-100 w-md-25"
            [disabled]="selectedProviders.length === 0"
            (click)="onProvidersSelected()"
        >
            <span *ngIf="!isLoading">{{ 'application.btn-next' | translate }}</span>
            <span
                *ngIf="isLoading"
                class="w-100 d-flex justify-content-center align-items-center"
                ><div
                    class="loader d-flex justify-content-center align-items-center"
                ></div
            ></span>
        </button>
        <button
            *ngIf="!isNewChat"
            class="btn btn-outline-warning w-100 w-md-25"
            [disabled]="selectedProviders.length === 0"
            (click)="onProvidersUpdate()"
        >
            <span *ngIf="!isLoading">{{ 'application.btn-next' | translate }}</span>
            <span
                *ngIf="isLoading"
                class="w-100 d-flex justify-content-center align-items-center"
                ><div
                    class="loader d-flex justify-content-center align-items-center"
                ></div
            ></span>
        </button>
    </div>
</div>
