import { Component, EventEmitter, OnInit } from '@angular/core';
import { HomeService } from '../../services/home/home.service';
import {
    ArchieConversationThreadDto,
    ArchieNavigationLevel,
    ArchieProviderDto,
    AuthenticationService,
    BasketDto,
    BasketService,
    ProductDto,
    ServiceBaseService,
    SessionService,
    SharedModalService,
    SnackBarService,
} from 'projects/library-shared/src/public-api';
import { CreateDialogService } from '../../services/create-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductDetailsDialogComponent } from '../product-details/product-details-dialog/product-details-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { faArrowLeft, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

@Component({
    selector: 'app-archie',
    templateUrl: './archie-desktop.component.html',
    styleUrls: ['./archie-desktop.component.scss'],
})
export class ArchieDesktopComponent implements OnInit {
    navigationLevelEmitter: EventEmitter<ArchieNavigationLevel> = new EventEmitter();
    navigationLevel = ArchieNavigationLevel.PROVIDER_SELECT;
    basketNavigationLevel = ArchieNavigationLevel.ALL_BASKET;
    hideBasketPages = [
        ArchieNavigationLevel.PROVIDER_SELECT,
        ArchieNavigationLevel.UPDATE_PROVIDER,
    ];

    siteImageUrl = '';
    selectedShopName: string;
    selectedZipCode: string;
    shopID: number;
    isDeliveryAvailableByPostCode = true;
    isDeliveryAvailableToSelectedShopByPostCode = true;
    isPostCodeSet = false;
    selectedProviders: ArchieProviderDto[];
    archieConversationThreadID: string;
    selectedBasket: BasketDto;
    selectedBaskets: BasketDto[];
    faArrow = faArrowLeft;
    faSwap = faRefresh;

    constructor(
        private homeService: HomeService,
        private sessionService: SessionService,
        private createDialogService: CreateDialogService,
        private baseService: ServiceBaseService,
        private translateService: TranslateService,
        private snackBarService: SnackBarService,
        private dialog: MatDialog,
        private basketService: BasketService,
        private router: Router,
        private authService: AuthenticationService,
        private sharedModalService: SharedModalService
    ) {
        this.siteImageUrl = this.baseService.getSiteImageUrl();
        this.navigationLevelEmitter.subscribe((level) => {
            const navigationGroup = [
                ArchieNavigationLevel.PROVIDER_SELECT,
                ArchieNavigationLevel.CHAT,
                ArchieNavigationLevel.PRODUCTS_VIEW,
                ArchieNavigationLevel.UPDATE_PROVIDER,
            ];
            const basketLevelGroup = [
                ArchieNavigationLevel.ALL_BASKET,
                ArchieNavigationLevel.BASKET,
            ];
            if (navigationGroup.includes(level)) this.navigationLevel = level;
            if (basketLevelGroup.includes(level)) this.basketNavigationLevel = level;
        });
    }

    ngOnInit(): void {
        this.homeService.isSessionSet.subscribe((isSet) => {
            if (isSet) {
                this.sessionService.sessionSubject.subscribe((session) => {
                    if (session) {
                        this.selectedZipCode = session.zipCode;
                        this.shopID = session.selectedShopIds[0];

                        if (
                            session.selectedShopNamesToShow &&
                            session.selectedShopNamesToShow.length === 1
                        )
                            this.selectedShopName = session.selectedShopNamesToShow[0];
                    }
                });
            }
        });

        this.sessionService.isProviderSelectedForShopping.next(false);
        this.homeService.isFooterDisplayed.next(false);
        this.homeService.isCategoryMenuDisplayed.next(false);
        this.homeService.isUserMenuDisplayed.next(false);
    }

    showAvailableTimeSlots(event?: number): void {
        const providerID = event != 0 ? event : this.shopID;
        const providerName =
            event != 0
                ? this.selectedProviders.find((provider) => provider.providerId === event)
                      .providerName
                : this.selectedShopName;
        let stayHere = true;
        this.sessionService.sessionSubject.subscribe((sessionSubject) => {
            this.isDeliveryAvailableByPostCode =
                sessionSubject.isDeliveryAvailableByPostCode;
            this.isDeliveryAvailableToSelectedShopByPostCode =
                sessionSubject.availableShops?.some(
                    (shop) =>
                        shop.toLowerCase() ===
                        sessionSubject?.selectedShops[0]?.toLowerCase()
                );
        });

        let canShowAvailableTimeSlots = false;

        if (
            !this.isDeliveryAvailableToSelectedShopByPostCode ||
            (!this.isDeliveryAvailableByPostCode &&
                (!this.sessionService.sessionSubject.value?.zipCode ||
                    !this.sessionService.sessionSubject.value?.isValidZip ||
                    this.sessionService.sessionSubject.value?.zipCode.trim() == ''))
        ) {
            let alertMessage = '';
            if (!this.isDeliveryAvailableToSelectedShopByPostCode && this.isPostCodeSet) {
                this.translateService
                    .get('messages.we-dont-deliver-for-this-zip')
                    .subscribe((text) => {
                        alertMessage = text;
                        stayHere = false;
                    });
            }

            if (!this.isPostCodeSet) {
                this.translateService
                    .get('messages.time-slot-without-zip')
                    .subscribe((text) => {
                        alertMessage = text;
                        stayHere = false;
                    });
            }

            this.createDialogService
                .openAddressDialog(stayHere, alertMessage)
                .afterClosed()
                .subscribe(() => {
                    if (!this.isPostCodeSet) {
                        this.translateService
                            .get('product-details.add-your-postcode-to-explore')
                            .subscribe((text) => {
                                this.snackBarService.openErrorSnackBar(text);
                            });

                        return;
                    } else {
                        canShowAvailableTimeSlots = true;
                    }
                });
        } else {
            canShowAvailableTimeSlots = true;
        }
        if (!canShowAvailableTimeSlots) {
            return;
        }

        this.createDialogService.showAvailableTimeSlotsDialog(
            providerName,
            providerID,
            true
        );
    }

    handleClickProduct(event: {
        product: ProductDto;
        providerId: number;
        providerName: string;
    }): void {
        this.dialog.open(ProductDetailsDialogComponent, {
            maxWidth: '1155px',
            minHeight: '620px',
            width: '90vw',
            data: {
                seoName: event.product.sEOName,
                providerId: event.providerId,
                providerName: event.providerName,
                isArchie: true,
            },
            panelClass: 'position-relative',
        });
    }

    onProvidersSelected(providers: ArchieConversationThreadDto): void {
        this.selectedProviders = providers.providerDto;
        this.basketService.masterBasketSubject.subscribe((masterBasket) => {
            if (!masterBasket) {
                return;
            }

            this.selectedBaskets = masterBasket.basketList.filter((basket) =>
                this.selectedProviders.find(
                    (provider) => provider.providerId === basket.providerID
                )
            );
        });
    }

    onBasketSelected(basket: BasketDto): void {
        this.selectedBasket = basket;
        this.basketService.masterBasketSubject.subscribe((baskets) => {
            const basketId = this.selectedBasket.basketID;
            this.selectedBasket = baskets.basketList.find(
                (basket) => basket.basketID === basketId
            );
        });
        this.navigationLevelEmitter.next(ArchieNavigationLevel.BASKET);
    }

    goBackToAllBaskets(): void {
        this.navigationLevelEmitter.next(ArchieNavigationLevel.ALL_BASKET);
    }

    navigateToShopCheckout(): void {
        if (this.selectedBasket.totalPrice <= 0) {
            this.translateService
                .get('messages.products-in-your-basket-not-available')
                .subscribe((text) => {
                    this.snackBarService.openErrorSnackBar(text);

                    return;
                });
        }

        const isUser = this.authService.getLoggedInUser();
        if (isUser) {
            this.basketService.openMenuSubject.next(false);
            this.translateService.get('router.cassa').subscribe((text) => {
                void this.router.navigate(
                    [text, this.selectedBasket?.providerCode.toLowerCase()],
                    { queryParams: { isArchie: true } }
                );
            });
        } else {
            void this.sharedModalService.presentSignInModal((loggedIn) => {
                if (loggedIn) {
                    this.basketService.openMenuSubject.next(false);
                    this.translateService.get('router.cassa').subscribe((text) => {
                        void this.router.navigate([
                            text,
                            this.selectedBasket?.providerCode.toLowerCase(),
                        ]);
                    });
                } else {
                    this.translateService
                        .get('messages.please-login-to-checkout')
                        .subscribe((text) => {
                            this.snackBarService.openErrorSnackBar(text);
                        });
                }
            });
        }
    }

    ngOnDestroy(): void {
        this.homeService.isHeaderDisplayed.next(true);
        this.homeService.isFooterDisplayed.next(true);
        this.homeService.isCategoryMenuDisplayed.next(true);
        this.homeService.isUserMenuDisplayed.next(true);
    }

    onModifyProviders(): void {
        this.navigationLevelEmitter.emit(ArchieNavigationLevel.UPDATE_PROVIDER);
    }
}
