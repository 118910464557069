<div
    class="list-container px-3 px-md-5 pt-4 d-flex justify-content-start w-100"
    [class.extra-padding]="isRokshDiscountAvailable"
>
    <div class="w-100">
        <div class="provider-select">
            <h3 class="pt-3">{{ 'common.my-favourite-products' | translate }}</h3>
            <lib-provider-select
                (providerChanged)="setLatestProvider($event)"
            ></lib-provider-select>
        </div>
        <ng-container *ngIf="favoritesByProvider">
            <span *ngIf="!isLoading && favoritesByProvider.length === 0">{{
                'my-account.no-favorite-found' | translate
            }}</span>
            <div class="w-100 pt-5" *ngFor="let favoriteItem of favoritesByProvider">
                <div
                    class="d-flex justify-content-between align-items-center"
                    *ngIf="favoritesByProvider.length > 1"
                >
                    <div class="d-flex align-items-center">
                        <div class="provider-logo p-2">
                            <img
                                class="w-100 h-100 of-cover"
                                src="{{ siteImageUrl }}logos/{{
                                    favoriteItem.providerName
                                }}-logo-s.png"
                                alt="provider-logo"
                            />
                        </div>
                        <h4 class="ps-2 p-0 m-0">
                            {{ favoriteItem.providerName }}
                        </h4>
                    </div>
                    <div class="d-flex">
                        <a class="go-to-shop me-3" (click)="goToProvider(favoriteItem)">
                            {{ 'common.go-to-shop' | translate }}
                            <fa-icon [icon]="faChevronRight"></fa-icon>
                        </a>
                        <div class="d-flex">
                            <a
                                class="arrows d-flex justify-content-center align-items-center mx-1"
                                (click)="navigateCard(-200, carousel)"
                            >
                                <fa-icon [icon]="faChevronLeft"></fa-icon>
                            </a>
                            <a
                                class="arrows d-flex justify-content-center align-items-center mx-1"
                                (click)="navigateCard(200, carousel)"
                            >
                                <fa-icon [icon]="faChevronRight"></fa-icon>
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    class="d-flex ps-1"
                    [class.product-container]="favoritesByProvider.length > 1"
                    [class.flex-wrap]="favoritesByProvider.length === 1"
                    #carousel
                >
                    <ng-container *ngFor="let product of favoriteItem.favoriteItemDtos">
                        <app-product-card-wrapper
                            class="pe-3"
                            [isFavoriteList]="true"
                            [product]="product.originalProduct"
                            [masterBasketId]="product.masterBasketId"
                            [basketItemId]="product.basketItemId"
                            [providerName]="favoriteItem.providerName"
                            (removeEmitter)="
                                removeFavoriteProduct($event, favoriteItem.providerName)
                            "
                        >
                        </app-product-card-wrapper
                    ></ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</div>
