import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BasketItemDTO, ProductDto } from '../../../index';
import { ComponentType } from '@angular/cdk/overlay';
import {
    faCircleCheck,
    faMinus,
    faPlus,
    faShoppingCart,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { BasketService } from '../../../services/basket.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../../auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LogService } from '../../../services/log.service';
import { SessionService } from '../../../services/session/session.service';
import { TranslateService } from '@ngx-translate/core';
import {
    DataLayerGa4Service,
    GA4EventType,
} from '../../../services/data-layer/data-layer-ga4.service';
import { ServiceBaseService } from '../../../services/service-base.service';
import { debounceTime, finalize, takeUntil, tap } from 'rxjs/operators';
import { UserBasketService } from '../../../services/user-basket.service';
import { ProviderDepositProductDto } from '../../../models/DTO/provider-deposit-product-dto.model';
import { ProviderDepositProduct } from '../../../models/product/provider-deposit-product.model';
import { FormatterService } from '../../../services/formatter.service';
import { take } from 'rxjs/internal/operators/take';

@Component({
    selector: 'lib-add-to-cart-button',
    templateUrl: './add-to-cart-button.component.html',
    styleUrls: ['./add-to-cart-button.component.scss'],
})
export class AddToCartButtonComponent implements OnInit, OnDestroy {
    @Input() isForShoppingList: boolean;
    product: ProductDto;
    @Input() set prod(prod: ProductDto) {
        this.product = prod;
        if (prod) {
            this.calculatedBasketItem = this.calculateBasketItem(prod);
        }
    }

    @Input() cartIcon = true;
    @Input() basketItem: BasketItemDTO;
    @Input() buttonText = '';
    @Input() massAddButtonText = '';
    @Input() buttonWidth = '';
    @Input() buttonHeight = '32px';
    @Input() quantityWidth = '75px';
    @Input() selectHeight = '31px';
    @Input() selectFontSize = '18px';
    @Input() buttonPadding = '';
    @Input() buttonPaddingY = '4px';
    @Input() isOriginPlaceBasket = false;
    @Input() showUserListIcon = true;
    @Input() quantityList: [number, string][];
    @Input() isForMassAdd = false;
    @Input() addToCartProhibited: boolean;
    @Input() addressDialogComponent: ComponentType<unknown>;
    @Input() providerDialogComponent: ComponentType<unknown>;
    @Input() masterBasketId: number;
    @Input() plusMinusBtnClass = '';
    @Input() selectedBasketItemID: number;
    @Input() selectedQuantity: number;
    @Input() selectedShoppingListQuantity: number;
    @Input() isArchie: boolean;
    @Input() providerId: number;
    @Input() providerName: string;
    @Output() onAdd: EventEmitter<boolean> = new EventEmitter();

    faMinus = faMinus;
    faPlus = faPlus;
    faSpinner = faSpinner;
    faShoppingCart = faShoppingCart;
    faCheck = faCircleCheck;
    isDeliveryAvailableByPostCode: boolean;
    inProgress = false;
    inOwnWebShop = false;
    quantityChangeInProgress = false;
    calculatedBasketItem: BasketItemDTO = null;
    quantityRefreshSubject = new Subject<number>();
    quantityRefreshSub: Subscription;
    isAuth = false;
    authSub: Subscription;
    isPostCodeSet = false;
    isProviderSelectedForShopping = false;
    optionalProviderDepositProductDto: ProviderDepositProductDto;
    optionalProviderDepositProduct: ProviderDepositProduct;
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    private requestSub = new Subscription();
    private SHOW_DISCOUNT_MS = 1500;
    private discountTimeout: NodeJS.Timeout;
    private discountLocked: boolean;
    private selectedProvider: number;

    constructor(
        public basketService: BasketService,
        public userBasketService: UserBasketService,
        private dialog: MatDialog,
        private authService: AuthenticationService,
        private snackBar: MatSnackBar,
        private log: LogService,
        private sessionService: SessionService,
        private translate: TranslateService,
        private formatterService: FormatterService,
        private dataLayerService: DataLayerGa4Service
    ) {
        if (ServiceBaseService.isProviderOwnWebShop()) this.inOwnWebShop = true;
        this.sessionService.lockDiscountMobile.subscribe(
            (value) => (this.discountLocked = value)
        );
    }

    ngOnInit(): void {
        this.log.mainClass = 'AddToCartBtnComponent';
        if (this.buttonText === '') {
            this.translate
                .get('common.add-to-basket')
                .subscribe((text) => (this.buttonText = text));
            this.cartIcon = true;
        }

        if (this.massAddButtonText === '') {
            this.translate
                .get('common.add-all-to-basket')
                .subscribe((text) => (this.massAddButtonText = text));
        }

        this.sessionService.sessionSubject
            .pipe(takeUntil(this.destroyed$))
            .subscribe((session) => {
                if (session?.selectedShopIds)
                    this.selectedProvider = session.selectedShopIds[0];
            });

        this.basketService.refreshCalculatedBasketItemSubject
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.calculatedBasketItem = this.calculateBasketItem(this.product);
            });

        this.authSub = this.authService
            .getAuthStatusListener()
            .subscribe((isAuth) => (this.isAuth = isAuth));

        this.quantityRefreshSub = this.setQuantityRefreshDebounce();

        this.sessionService.isPostCodeSet.subscribe((result) => {
            this.isPostCodeSet = result;
        });

        this.sessionService.isProviderSelectedForShopping.subscribe((result) => {
            if (this.providerId) {
                this.isProviderSelectedForShopping = true;
            } else {
                this.isProviderSelectedForShopping = result;
            }
        });
    }

    calculateBasketItem(product: ProductDto): BasketItemDTO {
        let basketItem =
            this.basketItem ??
            this.basketService.getSelectedBasketItem(product.productID, this.providerId);

        if (!basketItem) {
            basketItem = new BasketItemDTO({
                maxWeightStep: product.maxWeightStep,
                minWeightStep: product.minWeightStep,
                sEOName: product.categorySEOName,
                productID: product.productID,
                isBulk: product.isBulk,
                unit: product.displayUnit,
                basketItemID: this.selectedBasketItemID
                    ? this.selectedBasketItemID
                    : product.basketItemID,
                price: product.unitPrice,
                unitPrice: product.unitPrice,
                productName: product.productName,
                brand: product.brand,
                progID: product.categorySEOName,
            });
        }
        if (!this.isForShoppingList) {
            this.selectedQuantity = basketItem.unitValue;
            this.selectedBasketItemID = basketItem.basketItemID;
        }

        this.quantityList = this.basketService.createQuantityList(
            basketItem.minWeightStep,
            basketItem.maxWeightStep,
            basketItem.isBulk,
            basketItem.unit
        );

        this.quantityList.forEach((quantity) => {
            quantity[1] = quantity[1].replace('.', ',');
        });

        this.quantityChangeInProgress = false;
        return basketItem;
    }

    addToCartOrReplace(): void {
        if (!this.isPostCodeSet) {
            this.onAdd.emit(false);

            return;
        }
        if (!this.inProgress) {
            if (this.discountTimeout) clearTimeout(this.discountTimeout);

            if (!this.sessionService.isMobile) {
                let canAddToCartByPostCode = false;
                let canAddToCartByShopSelected = false;

                //if there is no postcode selected we show the poscode dialog
                this.sessionService.sessionSubject.subscribe((sessionSubject) => {
                    this.isDeliveryAvailableByPostCode =
                        sessionSubject.isDeliveryAvailableByPostCode;
                });
                if (
                    !this.isDeliveryAvailableByPostCode &&
                    (!this.sessionService.sessionSubject.value?.zipCode ||
                        !this.sessionService.sessionSubject.value?.isValidZip ||
                        this.sessionService.sessionSubject.value?.zipCode.trim() == '')
                ) {
                    this.dialog
                        .open(this.addressDialogComponent, {
                            data: { stayHere: true },
                        })
                        .afterClosed()
                        .subscribe(() => {
                            if (!this.isPostCodeSet) {
                                this.translate
                                    .get('messages.add-your-postcode-b4-add-to-basket')
                                    .subscribe((text) => {
                                        this.snackBar.open(text, null, {
                                            verticalPosition: 'top',
                                            horizontalPosition: 'center',
                                            duration: 3000,
                                        });
                                    });
                                return;
                            } else {
                                canAddToCartByPostCode = true;
                            }
                            this.addToCartOrReplace();
                        });
                } else {
                    canAddToCartByPostCode = true;
                }

                if (!canAddToCartByPostCode) {
                    return;
                }

                // if there is no provider selected we show the providerselector dialog
                if (
                    !this.isDeliveryAvailableByPostCode &&
                    this.isPostCodeSet &&
                    !this.isProviderSelectedForShopping
                ) {
                    this.dialog
                        .open(this.providerDialogComponent, {
                            maxWidth: '480px',
                            minWidth: '300px',
                            maxHeight: '85vh',
                            panelClass: 'my-custom-panel',
                            position: { top: '10vh' },
                        })
                        .afterClosed()
                        .subscribe(() => {
                            if (!this.isProviderSelectedForShopping) {
                                this.translate
                                    .get('messages.select-shop-b4-add-to-basket')
                                    .subscribe((text) => {
                                        this.snackBar.open(text, null, {
                                            verticalPosition: 'top',
                                            horizontalPosition: 'center',
                                            duration: 3000,
                                        });
                                        return;
                                    });
                            } else {
                                canAddToCartByShopSelected = true;
                            }
                        });
                } else {
                    canAddToCartByShopSelected = true;
                }
                if (!canAddToCartByShopSelected) {
                    return;
                }
            }

            if (!this.isForShoppingList) {
                this.selectedQuantity = 1;
            } else {
                this.selectedQuantity = this.selectedShoppingListQuantity;
            }

            if (
                !this.selectedQuantity &&
                !this.calculatedBasketItem.isBulk &&
                this.calculatedBasketItem.minWeightStep &&
                !this.calculatedBasketItem.startStepValue
            ) {
                this.selectedQuantity = this.quantityList[0][0];
            }

            this.inProgress = true;

            let price: number;
            if (this.product.isBulk) {
                price = this.product.minUnitPrice;
            } else {
                price = this.product.price;
            }

            if (!price) {
                price = this.product.minPrice;
            }

            this.basketService.setHideDiscount(false);
            this.requestSub = this.basketService
                .addToMasterBasket(
                    this.product.productID,
                    this.selectedQuantity,
                    this.providerId ? this.providerId : this.selectedProvider
                )
                .pipe(
                    tap(
                        () => {
                            this.onAdd.emit(true);
                            this.alertDepositText(this.product);
                            this.dataLayerService.eCommerceItem(
                                {
                                    brand: this.product.brand,
                                    category: this.product.categorySEOName,
                                    productName: this.product.productName,
                                    productID: this.product.productID,
                                    unitPrice: this.product.price,
                                    quantity: this.selectedQuantity,
                                },
                                GA4EventType.add_to_cart
                            );
                        },
                        (err) => {
                            this.log.error('Adding Product to basket', err);
                        }
                    )
                )
                .subscribe((basket) => {
                    if (this.selectedQuantity > 1) {
                        const masterBasket = basket.basketList.find((list) => {
                            return list.providerID === this.selectedProvider;
                        });

                        const basketItem = masterBasket.basketItemList.find((item) => {
                            return item.productID === this.product.productID;
                        });

                        if (basketItem?.basketItemID) {
                            this.basketService
                                .updateQuantity(
                                    basketItem.basketItemID,
                                    this.selectedQuantity
                                )
                                .subscribe(() => {
                                    this.inProgress = false;
                                    this.setDiscountHideTimeout();
                                });

                            return;
                        } else {
                            this.inProgress = false;
                            this.setDiscountHideTimeout();
                        }
                    }
                    this.inProgress = false;
                    this.setDiscountHideTimeout();
                    if (this.isArchie) {
                        this.translate
                            .get('archie.product-placed-to-provider', {
                                provider: this.providerName,
                            })
                            .subscribe((successText) => {
                                this.snackBar.open(successText, null, {
                                    verticalPosition: 'top',
                                    horizontalPosition: 'center',
                                    duration: 3000,
                                });
                            });
                    }
                });
        }
    }

    private alertDepositText(product): void {
        let optionalProviderDepositProductQuantity = 0;
        let optionalProviderDepositProductPrice = 0;

        if (product.providerDepositProductDtoList?.length > 0) {
            this.optionalProviderDepositProductDto =
                product.providerDepositProductDtoList.find(
                    (providerDepositProduct) =>
                        providerDepositProduct.depositUnitTypeIsOptional
                );
            optionalProviderDepositProductQuantity =
                this.optionalProviderDepositProductDto.maxQuantity;
            optionalProviderDepositProductPrice =
                this.optionalProviderDepositProductDto.price;
        }

        if (
            product?.ProductProvider &&
            product?.ProductProvider[0]?.ProductProviderProviderDepositProductList &&
            product?.ProductProvider[0]?.ProductProviderProviderDepositProductList
                ?.length > 0
        ) {
            this.optionalProviderDepositProduct =
                product.productProvider[0].ProductProviderProviderDepositProductList.find(
                    (ProviderDepositProduct) =>
                        ProviderDepositProduct.ProviderDepositProduct.depositUnitType
                            .isOptional
                ).ProviderDepositProduct;

            optionalProviderDepositProductQuantity =
                this.optionalProviderDepositProduct?.maxQuantity;
            optionalProviderDepositProductPrice =
                this.optionalProviderDepositProduct?.price;
        }

        if (
            optionalProviderDepositProductQuantity > 0 &&
            optionalProviderDepositProductPrice > 0
        ) {
            this.translate
                .get('messages.deposit-crate-on-add-to-basket-message', {
                    piece: optionalProviderDepositProductQuantity,
                    price: this.formatterService.formatCurrency(
                        optionalProviderDepositProductPrice
                    ),
                })
                .subscribe((text) => {
                    this.snackBar.open(text, null, {
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                        duration: 7000,
                        panelClass: 'warning-dialog',
                    });
                });
        }
    }

    selectQuantity(): void {
        if (this.discountTimeout) clearTimeout(this.discountTimeout);
        this.basketService.setHideDiscount(false);
        this.basketService
            .updateQuantity(this.selectedBasketItemID, this.selectedQuantity)
            .pipe(finalize(() => (this.quantityChangeInProgress = false)))
            .subscribe(() => {
                this.setDiscountHideTimeout();
                this.dataLayerService.eCommerceItem(
                    {
                        brand: this.product.brand,
                        category: this.product.categorySEOName,
                        productName: this.product.productName,
                        productID: this.product.productID,
                        price: this.product.price,
                        quantity: this.selectedQuantity,
                    },
                    GA4EventType.add_to_cart
                );
            });
    }

    private setDiscountHideTimeout() {
        if (!this.discountLocked) {
            this.discountTimeout = setTimeout(() => {
                this.basketService.setHideDiscount(true);
            }, this.SHOW_DISCOUNT_MS);
        }
    }

    stepQuantity(dx: number): void {
        if (this.quantityChangeInProgress) {
            return;
        }
        if (this.discountTimeout) clearTimeout(this.discountTimeout);
        this.basketService.setHideDiscount(false);

        this.quantityChangeInProgress = true;
        let index = this.quantityList.findIndex((x) => x[0] === this.selectedQuantity);
        if (index === this.quantityList.length - 1 && dx === 1) {
            this.quantityChangeInProgress = false;
            return;
        }
        index += dx;
        if (index >= 0) {
            this.selectedQuantity = this.quantityList[index][0];
        }

        if (index >= 0) {
            this.selectedQuantity = this.quantityList[index][0];
        } else {
            this.selectedQuantity = 0;
        }
        this.dataLayerService.eCommerceItem(
            {
                brand: this.product.brand,
                category: this.product.categorySEOName,
                productName: this.product.productName,
                productID: this.product.productID,
                price: this.product.price,
                quantity: this.selectedQuantity,
            },
            dx > 0 ? GA4EventType.add_to_cart : GA4EventType.remove_from_cart
        );

        this.quantityRefreshSubject.next(this.selectedQuantity);

        this.quantityChangeInProgress = false;
        this.setDiscountHideTimeout();
        this.calculatedBasketItem = this.calculateBasketItem(this.product);
    }

    onChangeQuantity(selectedQuantity?: number): void {
        if (this.inProgress) return;
        if (selectedQuantity === 0) return;
        const tmpQuantity = this.selectedShoppingListQuantity;
        if (selectedQuantity) this.selectedShoppingListQuantity = selectedQuantity;
        const outOfRange =
            this.quantityList.findIndex((quantity) => {
                return quantity[0] === this.selectedShoppingListQuantity;
            }) === -1;
        if (outOfRange) {
            this.selectedShoppingListQuantity = tmpQuantity;
            return;
        }

        this.inProgress = true;
        this.log.info('Change stated');
        this.userBasketService
            .updateQuantity(
                this.selectedBasketItemID,
                this.selectedShoppingListQuantity,
                this.masterBasketId
            )
            .pipe(take(1))
            .subscribe(
                (data) => {
                    this.log.info('Change done', data);
                    this.inProgress = false;
                },
                (err) => {
                    this.log.error('onChangeQuantity', err);
                    this.inProgress = false;
                }
            );
    }

    ngOnDestroy(): void {
        if (this.quantityRefreshSub) {
            this.quantityRefreshSub.unsubscribe();
        }
        if (this.authSub) {
            this.authSub.unsubscribe();
        }
        if (this.requestSub) {
            this.requestSub.unsubscribe();
        }

        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    private setQuantityRefreshDebounce(): Subscription {
        return this.quantityRefreshSubject
            .pipe(debounceTime(500))
            .subscribe((quantityIndex) => {
                if (quantityIndex <= 0) {
                    this.basketService
                        .deleteFromMasterBasket(this.selectedBasketItemID)
                        .pipe(takeUntil(this.destroyed$))
                        .subscribe(() => {
                            delete this.selectedQuantity;
                        });
                } else {
                    this.basketService
                        .updateQuantity(this.selectedBasketItemID, quantityIndex)
                        .pipe(
                            finalize(() => (this.quantityChangeInProgress = false)),
                            takeUntil(this.destroyed$)
                        )
                        .subscribe();
                }
            });
    }
}
