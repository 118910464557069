import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ArchieProviderDto, BasketDto } from '../../../index';
import { ServiceBaseService } from '../../../services/service-base.service';

@Component({
    selector: 'lib-archie-all-basket',
    templateUrl: './archie-all-basket.component.html',
    styleUrls: ['./archie-all-basket.component.scss'],
})
export class ArchieAllBasketComponent implements OnInit {
    @Input() selectedProviders: ArchieProviderDto[];
    @Input() selectedBaskets: BasketDto[];
    @Output() selectBasketEmitter = new EventEmitter<BasketDto>();

    siteImageUrl: string;

    constructor(baseService: ServiceBaseService) {
        this.siteImageUrl = baseService.siteImageUrl;
    }

    ngOnInit(): void {
        if (this.selectedBaskets?.length === 1) {
            this.selectBasketEmitter.next(this.selectedBaskets[0]);
        }
    }

    basketPrice(basket: BasketDto): number {
        return basket.basketItemList.reduce((sum, current) => {
            return sum + current.price;
        }, 0);
    }

    selectBasket(basket: BasketDto): void {
        this.selectBasketEmitter.next(basket);
    }
}
