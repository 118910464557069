<div
    [class.subCategory]="isSubCategory"
    class="main-categories pt-4"
    [ngClass]="{
        'category-floating-footer': isFooterFloating
    }"
>
    <div class="container">
        <span class="vissza"
            ><a (click)="goBackClick()">
                <img src="assets/images/left-arrow-categories.png" />
                {{ 'common.back' | translate }}</a
            >
        </span>
        <h2 class="desktop-element">{{ categoryParentName }}</h2>
        <!-- NEED CHANGE : FROM POST NEED PARENT CATEGORIES NAME-->
        <span class="mobile-element">{{ 'common.categories' | translate }}</span>
        <app-category-card
            [category]="category"
            [isSubCategory]="isSubCategory"
            *ngFor="let category of allCategories"
        ></app-category-card>
        <div class="fake-cat"></div>
        <div class="fake-cat"></div>
        <div class="fake-cat"></div>
        <div class="fake-cat"></div>
        <div class="fake-cat"></div>
    </div>
</div>
