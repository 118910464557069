import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    AuthenticationService,
    ServiceBaseService,
    SessionService,
    SharedModalService,
    UserShared,
} from 'projects/library-shared/src/public-api';
import { Subscription } from 'rxjs';
import { Event, NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-log-in',
    templateUrl: './log-in.coponent.html',
    styleUrls: ['./log-in.coponent.scss'],
})
export class LogInComponent implements OnInit, OnDestroy {
    @Input() isWhite = false;

    authStatusSub: Subscription;
    isAuthenticated = false;
    user: UserShared;
    isProviderSelectedForShopping = false;
    language: string;
    siteImageUrl: string;
    selectedProviderName: string;

    constructor(
        private authService: AuthenticationService,
        private sessionService: SessionService,
        private router: Router,
        private sharedModalService: SharedModalService,
        baseService: ServiceBaseService
    ) {
        this.siteImageUrl = baseService.getSiteImageUrl();
    }

    ngOnInit() {
        this.authStatusSub = this.authService
            .getAuthStatusListener()
            .subscribe((newAuthStatus) => {
                this.isAuthenticated = newAuthStatus;
                this.user = this.authService.getLoggedInUser();
            });

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                if (
                    event.url === '/' ||
                    event.urlAfterRedirects === '/404' ||
                    event.url.includes('/?') ||
                    event.url.includes('/#') ||
                    (event.urlAfterRedirects.includes('felhasznalo') &&
                        !event.url.includes('providerName') &&
                        !event.url.includes('providerId') &&
                        !event.url.includes('orderCode')) ||
                    (event.urlAfterRedirects.includes('benutzer') &&
                        !event.url.includes('providerName') &&
                        !event.url.includes('providerId') &&
                        !event.url.includes('orderCode'))
                ) {
                    this.sessionService.isProviderSelectedForShopping.next(false);
                    this.selectedProviderName = null;
                } else {
                    this.sessionService.sessionSubject.subscribe((session) => {
                        if (session && session.selectedShops) {
                            this.selectedProviderName = session.selectedShops[0];
                        }
                    });
                }
            }
        });

        this.sessionService.isProviderSelectedForShopping.subscribe((result) => {
            this.isProviderSelectedForShopping = result;
            if (this.isProviderSelectedForShopping && !this.selectedProviderName) {
                this.sessionService.sessionSubject.subscribe((session) => {
                    if (session && session.selectedShops) {
                        this.selectedProviderName = session.selectedShops[0];
                    }
                });
            }
        });
        this.language = ServiceBaseService.getCountryCode().toString();
    }

    onOpenDialog(): void {
        void this.sharedModalService.presentSignInModal();
    }

    onLogOut() {
        this.authService.logout().subscribe(
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {},
            (err) => console.error(err)
        );
    }

    ngOnDestroy() {
        if (this.authStatusSub) {
            this.authStatusSub.unsubscribe();
        }
    }
}
