import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-category-block-header',
    templateUrl: './category-block-header.component.html',
    styleUrls: ['./category-block-header.component.scss'],
})
export class CategoryBlockHeaderComponent {
    @Input() customImg: string;
    @Input() routerLink: string;
    @Input() backgroundColor: string;

    constructor(private router: Router) {}

    handleRoute(): void {
        if (this.routerLink) {
            this.router.navigate([this.routerLink]).then();
        }
    }
}
