<ng-container *ngIf="selectedProducts">
    <div class="position-relative h-100">
        <div
            class="d-none d-md-flex position-absolute bg-inherit top-0 start-0 pointer align-items-center p-3 pb-4 w-100"
            (click)="onBack()"
        >
            <fa-icon [icon]="faArrow"></fa-icon>
            <h6 class="p-0 m-0 ps-3 fw-bolder">{{ 'common.back' | translate }}</h6>
        </div>
        <div class="overflow-y-scroll p-md-3 h-100">
            <div class="d-flex flex-wrap justify-content-around pt-5">
                <lib-product-dto-card
                    *ngFor="let product of selectedProducts.productList"
                    [product]="product"
                    (cardClicked)="
                        clickProduct.emit({
                            product: product,
                            providerId: selectedProducts.providerId,
                            providerName: selectedProducts.providerName
                        })
                    "
                    [productProviderId]="selectedProducts.providerId"
                    [productProviderName]="selectedProducts.providerName"
                    [isArchie]="true"
                ></lib-product-dto-card>
            </div>
        </div>
    </div>
</ng-container>
