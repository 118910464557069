<div class="pt-5 d-flex flex-column align-items-center" [class.container]="!isMobile">
    <h3 class="pt-5 w-75 text-center">{{ '404.not-found' | translate }}</h3>
    <p class="d-block mt-3 mb-5">{{ '404.not-found-message' | translate }}</p>
    <button
        class="roksh-btn-primary our-shops-button gradient"
        [routerLink]="[routerUrl]"
    >
        <div class="d-flex flex-row justify-content-center">
            <span *ngIf="!isOwnWebShop" class="our-shops-text">{{
                'common.our-shops' | translate
            }}</span>
            <span *ngIf="isOwnWebShop" class="our-shops-text">{{
                'router.start-page' | translate
            }}</span>
        </div>
    </button>
</div>
