import { HtmlMarketingElementDto, ProductDto } from '../../index';
import { HtmlMarketingElementsExtendsDto } from '../../services/dto/html-marketing-elements/html-marketing-elements-extends.dto';

export class ProductBlockDto {
    CategoryBlockTypeID: number;
    Title: string;
    MobileApplicationTitle: string;
    CategoryProgId: string;
    // CategoryID: number;
    MediaGUID: string;
    MobileApplicationMediaGUID: string;
    BackGUID: string;
    BackColor: string;
    Description: string;
    // 0-RIGHT
    // 1-LEFT
    LayoutID: number;
    Position: number;
    TotalPages: number;
    Page: number;
    RelatedUrl: string;
    HasOwnCategory: boolean;
    OwnCategoryUrl: string;
    BackUrl: string;
    ProductList: ProductDto[];
    TitleTextColor: string;
    ProviderCodeToSelect: string;
    ProviderNameToSelect: string;
    BackColorWithOpacity: string;
    IsIncognitoRokshLanding: boolean;
    HtmlMarketingElementDto: HtmlMarketingElementDto[];
    HtmlMarketingElementsExtendsDto?: HtmlMarketingElementsExtendsDto;
}
