import { Injectable } from '@angular/core';
import { ISeoService } from './i-seo.service';

@Injectable({
    providedIn: 'root',
})
export class SeoService implements ISeoService {
    private maxTextLength = 70;

    titleWrap(title: string) {
        return (title.match(
            new RegExp('(\\S.{0,' + (this.maxTextLength - 1) + '})(?=\\s+|$)', 'g')
        ) || [])[0];
    }
}
