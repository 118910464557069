import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { BasketItemDTO } from '../../../index';
import { BasketService } from '../../../services/basket.service';
import { ServiceBaseService } from '../../../services/service-base.service';
import { TranslateService } from '@ngx-translate/core';
import { map, takeUntil } from 'rxjs/operators';
import {
    DataLayerGa4Service,
    GA4EventType,
} from '../../../services/data-layer/data-layer-ga4.service';
import { faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons';
import { CountryCodes } from '../../../types/country-codes';
import { ComponentType } from '@angular/cdk/overlay';
import { SessionService } from '../../../services/session/session.service';

@Component({
    selector: 'lib-basket-item',
    templateUrl: './basket-item.component.html',
    styleUrls: ['./basket-item.component.scss'],
})
export class BasketItemComponent implements OnInit, OnDestroy {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    @Input() basketItem: BasketItemDTO;
    @Input() isArchie = false;
    @Input() providerName: string;
    @Input() providerId: number;
    @Input() addressDialogComponent: ComponentType<unknown>;
    @Input() shopSelectDialogSeparatedComponent: ComponentType<unknown>;
    siteImageUrl = '';
    deleteInProgress = false;
    currentRokshDiscountLevelIndex: number;
    math = Math;

    faSpinner = faSpinner;
    faTrash = faTrash;
    CountryCodes = CountryCodes;
    countryCode = this.CountryCodes.hu;
    isMobile: boolean;
    public get isAtCountryCode(): boolean {
        return this.countryCode === CountryCodes.at;
    }

    constructor(
        private basketServices: BasketService,
        private DataLayerGa4Service: DataLayerGa4Service,
        private translateService: TranslateService,
        private sessionService: SessionService,
        baseService: ServiceBaseService
    ) {
        this.countryCode = ServiceBaseService.getCountryCode();
        this.siteImageUrl = baseService.siteImageUrl;
        this.isMobile = this.sessionService.isMobile;
    }

    ngOnInit() {
        this.basketServices.currentRokshDiscountLevelIndexSubject.subscribe(
            (currentRokshDiscountLevelIndex) => {
                this.currentRokshDiscountLevelIndex = currentRokshDiscountLevelIndex;
            }
        );

        if (!this.providerName) {
            this.sessionService.sessionSubject.subscribe((session) => {
                this.providerName = session.selectedShops[0];
            });
        }
    }

    get navigationUrl(): Observable<string> {
        if (this.isMobile) {
            return of(
                `${this.providerName.toLowerCase()}/termekek/${this.basketItem.sEOName.toLowerCase()}`
            );
        } else {
            return this.translateService.get('router.products').pipe(
                map((text: string) => {
                    return text.concat(
                        '\\',
                        this.basketItem.progID,
                        '\\',
                        this.basketItem.sEOName.toLowerCase()
                    );
                })
            );
        }
    }

    deleteFromBasket(): void {
        if (!this.deleteInProgress) {
            this.deleteInProgress = true;
            this.basketServices
                .deleteFromMasterBasket(this.basketItem.basketItemID)
                .pipe(takeUntil(this.destroyed$))
                .subscribe({ complete: () => (this.deleteInProgress = false) });
        }

        this.DataLayerGa4Service.eCommerceItem(
            this.basketItem,
            GA4EventType.remove_from_cart
        );
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
