import { Component } from '@angular/core';
import { register } from 'swiper/element/bundle';

declare global {
    interface Window {
        ReactNativeWebView: any;
        MSStream: string;
        HubSpotConversations: {
            widget: {
                remove: () => void;
                load: (data: any) => void;
                open: () => void;
            };
        };
        hsConversationsOnReady: any;
        hsConversationsSettings: any;
    }
}

register();

@Component({
    selector: 'lib-library-shared',
    template: ` <p>library-shared works!</p> `,
    styles: [],
})
export class LibrarySharedComponent {}
