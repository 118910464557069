<div *ngIf="!isLoading">
    <!--- If there is no image in the carousel, then we show the default provider cover image--->
    <div
        *ngIf="!filteredCoverImages || filteredCoverImages.length === 0"
        class="owl-carousel-container pt-4"
        [ngClass]="shopCoverImageUrl ? '' : 'skeleton'"
    >
        <div class="image-container">
            <img src="{{ shopCoverImageUrl }}" />
        </div>
    </div>

    <!--- If there is only one image in the carousel, then we show as a cover image--->
    <div
        *ngIf="filteredCoverImages?.length === 1"
        class="owl-carousel-container pt-4"
        [ngClass]="shopCoverImageUrl ? '' : 'skeleton'"
    >
        <div class="image-container">
            <a
                *ngIf="
                    !filteredCoverImages[0].isCTAButtonExternalURL &&
                    filteredCoverImages[0].cTAButtonUrl
                "
                [routerLink]="'/' + filteredCoverImages[0].cTAButtonUrl"
            >
                <img
                    src="{{ filteredCoverImages[0].imageUrl }}"
                    alt="{{ filteredCoverImages[0].cTAButtonText }}"
                />
            </a>
            <a
                *ngIf="
                    filteredCoverImages[0].isCTAButtonExternalURL &&
                    filteredCoverImages[0].cTAButtonUrl
                "
                href="{{ filteredCoverImages[0].cTAButtonUrl }}"
                target="_blank"
            >
                <img
                    src="{{ filteredCoverImages[0].imageUrl }}"
                    alt="{{ filteredCoverImages[0].cTAButtonText }}"
                />
            </a>
            <img
                *ngIf="!filteredCoverImages[0].cTAButtonUrl"
                src="{{ filteredCoverImages[0].imageUrl }}"
                alt="{{ filteredCoverImages[0].cTAButtonText }}"
            />
        </div>
    </div>

    <!--- If there is more than one image in the carousel, then we use the owl--->
    <div *ngIf="filteredCoverImages?.length > 1" class="owl-carousel-container pt-4">
        <a
            class="arrows prev-arrow mx-1 justify-content-around align-items-center d-none d-md-flex"
            (click)="carousel1.prev()"
        >
            <fa-icon [icon]="faChevronLeft" size="sm"></fa-icon>
        </a>
        <a
            class="arrows next-arrow mx-1 justify-content-around align-items-center d-none d-md-flex"
            (click)="carousel1.next()"
        >
            <fa-icon [icon]="faChevronRight" size="sm"></fa-icon>
        </a>
        <owl-carousel-o [options]="customOptions" #carousel1>
            <ng-template
                *ngFor="let providerCoverImage of filteredCoverImages"
                carouselSlide
                [width]="isMobileApp ? screenWidth : 0"
            >
                <div class="image-container">
                    <ng-container
                        *ngIf="providerCoverImage.cTAButtonUrl; else noCTAButton"
                    >
                        <a
                            *ngIf="!providerCoverImage.isCTAButtonExternalURL"
                            [routerLink]="'/' + providerCoverImage.cTAButtonUrl"
                        >
                            <img
                                src="{{ providerCoverImage.imageUrl }}"
                                alt="{{ providerCoverImage.cTAButtonText }}"
                            />
                        </a>
                        <a
                            *ngIf="providerCoverImage.isCTAButtonExternalURL"
                            href="{{ providerCoverImage.cTAButtonUrl }}"
                            target="_blank"
                        >
                            <img
                                src="{{ providerCoverImage.imageUrl }}"
                                alt="{{ providerCoverImage.cTAButtonText }}"
                            />
                        </a>
                    </ng-container>

                    <ng-template #noCTAButton>
                        <img
                            src="{{ providerCoverImage.imageUrl }}"
                            alt="{{ providerCoverImage.cTAButtonText }}"
                        />
                    </ng-template>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>
