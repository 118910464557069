<div class="text-end w-100 close-button">
    <span class="pointer" (click)="closeEmitter()">
        <img
            class="expansion-header-svg close-icon"
            src="{{ siteImageUrl }}images/shared-img/close.svg"
            alt="close"
        />
    </span>
</div>
<div
    class="d-flex py-3 px-4 flex-column align-items-center w-100"
    [class.no-padding-mobile]="!isMobile"
>
    <img
        src="{{ siteImageUrl }}images/shared-img/user-basket/new_list.svg"
        alt="new list"
        class="my-3"
    />
    <h6 class="text-center fw-bold pb-3">
        {{ 'user-basket.create-new-list' | translate }}
    </h6>
    <lib-provider-select
        class="w-100 pointer mt-5"
        [selectedProvider]="selectedProvider"
        (providerChanged)="onProviderChange($event)"
    ></lib-provider-select>
    <mat-form-field class="w-100">
        <mat-label>{{ 'user-basket.name-your-list' | translate }}</mat-label>
        <input matInput type="text" [(ngModel)]="basketName" />
    </mat-form-field>
    <button
        class="btn w-100 mt-4 mb-3 d-flex justify-content-center align-items-center"
        (click)="onCreateUserBasket()"
        [disabled]="!(selectedProvider && basketName)"
    >
        {{ 'user-basket.creating' | translate }}
    </button>
</div>
