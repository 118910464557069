import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { BaseLogService } from './base-log.service';

@Injectable({ providedIn: 'root' })
export class LogService {
    public mainClass = 'NotSetted';

    constructor(private baseLogService: BaseLogService) {}

    log(msg: string | number, ...optionalParams: any[]) {
        this.writeToLog(msg, optionalParams);
    }

    debug(msg: string | number, ...optionalParams: any[]) {
        this.writeToLog(msg, optionalParams);
    }

    info(msg: string | number, ...optionalParams: any[]) {
        this.writeToLog(msg, optionalParams);
    }

    warn(msg: string | number, ...optionalParams: any[]) {
        this.writeToLog(msg, optionalParams);
    }

    error(msg: string | number, ...optionalParams: any[]) {
        this.writeToLog(msg, optionalParams);
    }

    private writeToLog(msg: string | number, params: any[]) {
        if (this.shouldLog()) {
            let value = '';

            // Build log string
            if (this.baseLogService.logWithDate) {
                value = moment().tz('Europe/Budapest').format('HH:mm:ss');
                value += ' ';
            }
            if (this.baseLogService.logWithMainClass) {
                value += this.mainClass;
                value += ' ';
            }

            value += '- ' + msg;

            if (params.length) {
                value += ' - Extra Info: ' + this.formatParams(params);
            }
        }
    }

    private shouldLog(): boolean {
        return false;
    }

    private formatParams(params: any[]) {
        return params.reduce(
            (data, param) => {
                data.ind++;
                data.formatedParam = {
                    ...data.formatedParam,
                    [this.mainClass + '_' + data.ind]: param,
                };
                return data;
            },
            { formatedParam: {}, ind: 0 }
        ).formatedParam;
    }
}
