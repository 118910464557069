import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProviderSelectModule } from '../provider-select/provider-select.module';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { TipListComponent } from './tip-list/tip-list.component';

@NgModule({
    declarations: [TipListComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MatInputModule,
        FontAwesomeModule,
        ClipboardModule,
        ReactiveFormsModule,
        FormsModule,
        ProviderSelectModule,
        SharedPipesModule,
    ],
    exports: [TipListComponent],
})
export class TipListModule {}
