import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { TypeAheadResult } from '../models/DTO/typeaheadResult.model';
import {
    ProductListResult,
    ProductListResultNew,
} from '../models/product/product-list-result.model';
import { ServiceBaseService } from './service-base.service';

@Injectable({ providedIn: 'root' })
export class SearchService {
    private shopServiceUrl: string;
    private headers: HttpHeaders;
    searchResult: ProductListResult[];
    private searchSource = new BehaviorSubject('');
    public searchPhrase = this.searchSource.asObservable();

    constructor(
        private http: HttpClient,
        private serviceBaseService: ServiceBaseService
    ) {
        this.shopServiceUrl = this.serviceBaseService.getBaseUrl();
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    }

    getSearchResults(searchText: string, param) {
        return this.http.post<ProductListResult>(
            this.shopServiceUrl +
                'productlist/search/' +
                searchText.replace('%', '-').replace('/', '-').replace('+', '-'),
            param,
            { headers: this.headers }
        );
    }

    getSearchResultsForMobile(searchText: string, param) {
        return this.http.post<ProductListResultNew>(
            this.shopServiceUrl +
                'productlist/search/' +
                searchText.replace('%', '-').replace('/', '-').replace('+', '-'),
            param,
            { headers: this.headers }
        );
    }
    typeAhead(searchText: string, onlyInProducts = false, providerid: number = null) {
        return this.http
            .post<{ TagObject: TypeAheadResult[] }>(
                this.shopServiceUrl + 'productlist/typeahead/',
                {
                    query: searchText,
                    onlyInProducts: onlyInProducts,
                    providerid: providerid,
                },
                { headers: this.headers }
            )
            .pipe(map((data) => data.TagObject));
    }

    setSearchPhrase(newPhrase: string) {
        this.searchSource.next(newPhrase);
    }
}
