import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
    BasketDto,
    BasketService,
    SessionService,
} from 'projects/library-shared/src/public-api';
import { HomeService } from '../../../services/home/home.service';

@Component({
    selector: 'app-basket-provider-selector',
    templateUrl: './basket-provider-selector.component.html',
    styleUrls: ['./basket-provider-selector.component.scss'],
})
export class BasketProviderSelectorComponent implements OnInit {
    @Output() backToShopping = new EventEmitter<void>();

    zipCode: string;
    masterBasket: BasketDto[];

    constructor(
        private sessionService: SessionService,
        private homeService: HomeService,
        private basketService: BasketService
    ) {}

    ngOnInit(): void {
        this.homeService.isSessionSet.subscribe((isSet) => {
            if (isSet) {
                this.sessionService.sessionSubject.subscribe((session) => {
                    this.zipCode = session?.zipCode;
                    const shops = session?.availableShops;
                    if (!shops) return;
                    this.basketService.getMasterBasket().subscribe((masterBasket) => {
                        this.masterBasket = masterBasket.basketList.filter((basket) => {
                            const isAvailable =
                                shops.findIndex(
                                    (shop) => basket.providerName === shop
                                ) !== -1;
                            return basket.basketItemList?.length > 0 && isAvailable;
                        });
                    });
                });
            }
        });
    }
}
