import { Component } from '@angular/core';
import {
    ServiceBaseService,
    SessionService,
} from 'projects/library-shared/src/public-api';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/internal/operators/take';

@Component({
    selector: 'lib-page-not-found',
    templateUrl: './error404.component.html',
    styleUrls: ['./error404.component.scss'],
})
export class Error404Component {
    isOwnWebShop: boolean;
    routerUrl = '/';
    isMobile: boolean;

    constructor(
        private router: Router,
        private session: SessionService,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.isMobile = this.session.isMobile;
        this.setRouterLink();
    }

    private setRouterLink(): void {
        if (!this.session.isWrapper && this.isMobile) {
            this.isOwnWebShop = ServiceBaseService.isOwnWebShopMobile;
            if (this.isOwnWebShop) {
                this.session.sessionSubject.pipe(take(1)).subscribe((session) => {
                    const selectedShop =
                        session?.selectedShops && session.selectedShops[0];
                    if (selectedShop) {
                        this.routerUrl = `/shop/${selectedShop}/kezdooldal`;
                    } else {
                        this.routerUrl = '/landing';
                    }
                });
            } else {
                this.routerUrl = '/landing';
            }
        } else {
            this.isOwnWebShop = ServiceBaseService.isProviderOwnWebShop();
            this.translate.get('router.dashboard').subscribe((dashboardUrl) => {
                this.routerUrl = '/' + dashboardUrl;
            });
        }
    }
}
