import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ServiceBaseService } from './service-base.service';

@Injectable({
    providedIn: 'root',
})
export class SEOService {
    private static CANONICAL_HOST_NAME = 'https://roksh.com';

    private _headElement: HTMLHeadElement;

    private _canonicalLinkElement: HTMLLinkElement;

    private get headElement(): HTMLHeadElement {
        if (!this._headElement && this.document.getElementsByTagName('head').length > 0) {
            this._headElement = this.document.getElementsByTagName('head')[0];
        }
        return this._headElement;
    }

    private get canonicalLinkElement(): HTMLLinkElement {
        this._canonicalLinkElement =
            this.document.querySelector(`link[rel='canonical']`) || null;

        if (!this._canonicalLinkElement) {
            this._canonicalLinkElement = this.document.createElement(
                'link'
            ) as HTMLLinkElement;

            this.headElement.appendChild(this._canonicalLinkElement);
        }

        this._canonicalLinkElement.setAttribute('rel', 'canonical');

        return this._canonicalLinkElement;
    }

    private get necessaryProviderCode(): string {
        // eslint-disable-next-line no-prototype-builtins
        const isOwnDomain = ServiceBaseService.DOMAIN_PROVIDER_CODE_DICT.hasOwnProperty(
            ServiceBaseService.hostName
        );

        let providerCodeUrlInfix = '';

        if (isOwnDomain) {
            providerCodeUrlInfix = `/${
                ServiceBaseService.DOMAIN_PROVIDER_CODE_DICT[ServiceBaseService.hostName]
            }`;
        }

        return providerCodeUrlInfix;
    }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private serviceBaseService: ServiceBaseService
    ) {}

    public subscribeCanonicalUrlUpdateToNavigationEndEvent(): void {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                const route = this.getChild(this.activatedRoute);

                route.data.subscribe((data) => {
                    if (data.canonical) {
                        this.updateCanonicalUrl(
                            `${SEOService.CANONICAL_HOST_NAME}${this.necessaryProviderCode}${data.canonical}`
                        );
                    } else {
                        this.updateCanonicalUrl(
                            `${SEOService.CANONICAL_HOST_NAME}${this.necessaryProviderCode}${this.router.url}`
                        );
                    }
                });
            });
    }

    private updateCanonicalUrl(url: string): void {
        this.canonicalLinkElement.setAttribute('href', url);
    }

    private getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
        if (activatedRoute.firstChild) {
            return this.getChild(activatedRoute.firstChild);
        } else {
            return activatedRoute;
        }
    }
}
