import { AfterViewInit, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-cookies',
    templateUrl: './cookies.component.html',
    styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent implements AfterViewInit {
    constructor(private translate: TranslateService) {}

    ngAfterViewInit(): void {
        let url;
        this.translate
            .get('router.cookie-consent-link')
            .subscribe((text) => (url = text)); //'https://consent.cookiebot.com/95ce79ae-e3d2-4def-ba76-324466c173df/cd.js'

        const node = document.createElement('script');
        node.src = url;
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        document.getElementsByTagName('body')[0].appendChild(node);
    }
}
