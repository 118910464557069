<div
    *ngIf="basket"
    class="basket-container h-100 px-4 position-relative bg-white"
    [class.bg-gray]="isArchie"
>
    <div class="w-100 header-container row p-0 m-0 pt-3 d-flex align-items-center">
        <div class="col d-flex align-items-center p-0 m-0 border-end h-100">
            <div *ngIf="showProvider" class="h-100">
                <img
                    src="{{ siteImageUrl }}logos/{{ basket.providerCode }}-logo-s.png"
                    alt="logo"
                    class="w-100 h-100 object-fit-scale"
                />
            </div>
            <div *ngIf="!showProvider">
                <h1 class="fw-bold p-0 d-block d-md-none">
                    {{ 'common.basket' | translate }}
                </h1>
            </div>
        </div>
        <div class="col-7 d-flex align-items-center p-0 m-0 h-100">
            <div *ngIf="zipCode" class="text-center w-100">
                {{ 'shop-select-dialog.delivery-here' | translate }}:
                {{ zipCode }}
            </div>
        </div>
        <div
            class="col-1 d-flex align-items-center p-0 m-0 d-flex justify-content-end h-100"
        >
            <div [style.height]="'32px'" *ngIf="basket.basketItemList.length > 0">
                <img
                    [matMenuTriggerFor]="menu"
                    src="{{
                        siteImageUrl
                    }}images/shared-img/user-basket/ellipsis-menu.svg"
                    alt="hamburger"
                    class="pointer p-2 h-100"
                />
                <mat-menu #menu="matMenu" class="py-1 px-4">
                    <a
                        class="text-decoration-none d-flex align-items-center text-black-50 py-1"
                        (click)="clearBasket()"
                    >
                        <div
                            class="menu-icon d-flex justify-content-center align-items-center"
                        >
                            <img
                                class="h-100"
                                src="{{
                                    siteImageUrl
                                }}images/shared-img/user-basket/trash.svg"
                                alt="delete basket"
                            />
                        </div>
                        <span class="text">{{ 'basket.empty-basket' | translate }}</span>
                    </a>
                    <a
                        class="text-decoration-none d-flex align-items-center text-black-50 py-1"
                        (click)="onShareLinkByEmail()"
                    >
                        <div
                            class="menu-icon d-flex justify-content-center align-items-center"
                        >
                            <img
                                class="h-100"
                                src="{{
                                    siteImageUrl
                                }}images/shared-img/user-basket/share.svg"
                                alt="share basket"
                            />
                        </div>
                        <span class="text">{{ 'common.share' | translate }}</span>
                    </a>
                    <a
                        class="text-decoration-none d-flex align-items-center text-black-50 py-1"
                        (click)="saveBasket()"
                    >
                        <div
                            class="menu-icon d-flex justify-content-center align-items-center"
                        >
                            <img
                                class="h-100"
                                src="{{
                                    siteImageUrl
                                }}images/shared-img/user-basket/add-user-basket.svg"
                                alt="save basket"
                            />
                        </div>
                        <span class="text">{{ 'common.save-basket' | translate }}</span>
                    </a>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="content-separator"></div>
    <div *ngIf="!isDeliveryAvailable" class="min-max-value w-100 text-center">
        <span
            class="mx-4 my-4"
            [innerHTML]="'messages.we-dont-deliver-for-this-zip' | translate"
        ></span>
    </div>
    <lib-html-marketing containerId="basket"></lib-html-marketing>
    <hr [style.border-color]="'#e3e3e3'" class="mb-0" />
    <div *ngIf="isLessThanMinPrice" class="min-max-value w-100 text-center">
        <span
            class="mx-4 my-4"
            [innerHTML]="
                'messages.min-order-value' | translate : { minValue: minOrderPrice }
            "
        ></span>
    </div>
    <div *ngIf="isGreaterThanMaxWeight" class="min-max-value w-100 text-center">
        <span
            class="mx-4 my-4 lh-1"
            [innerHTML]="
                'messages.your-basket-now-above-limit-4'
                    | translate : { maxWeight: maxWeight }
            "
        ></span>
    </div>
    <div class="h-100 overflow-y-auto pt-2" *ngIf="basket.basketItemList?.length > 0">
        <div *ngFor="let basketItem of basket.basketItemList">
            <lib-basket-item
                [isArchie]="true"
                [basketItem]="basketItem"
                [providerName]="basket.providerName"
                [providerId]="basket.providerID"
            ></lib-basket-item>
        </div>
        <div class="row">
            <div class="col-12 mt-2 mb-5" *ngIf="basket.shippingPrice >= 0">
                <div
                    class="text-size-12 mx-auto text-end"
                    *ngIf="basket.basketItemList.length > 1"
                >
                    <fa-icon [icon]="faTruck" class="truck-icon me-2"></fa-icon>
                    ~{{ basket.shippingPrice | currencyFormatted }}&nbsp;
                    {{ 'common.currency' | translate }}
                </div>
            </div>
        </div>
        <div class="button-separator w-100"></div>
    </div>
    <div
        *ngIf="!basket.basketItemList || basket.basketItemList.length === 0"
        class="d-flex flex-column align-items-center h-100 w-100 top-0 start-0 pb-5 pt-5"
    >
        <div class="empty-basket-container">
            <img
                src="{{ siteImageUrl }}images/sad basket.svg"
                alt="basket-is-empty-image"
                class="my-auto sad-emoji"
            />
        </div>
        <h3 class="w-100 text-center text-black-50 mt-4">
            {{ 'basket.basket-is-empty-yet' | translate }}
        </h3>
        <h5 class="w-100 text-center roksh-text-orange" (click)="goToHome()">
            {{ 'basket.go-to-shopping' | translate }}
        </h5>
    </div>
    <div
        class="p-2 w-100 position-absolute order-button bottom-0 start-0"
        [class.bg-gray]="isArchie"
    >
        <button
            [disabled]="!isDeliveryAvailable || isDisabledShoppingCheckout"
            class="roksh-btn-primary w-100 h-100 gradient basket-button"
            (click)="navigateToShopCheckout()"
        >
            {{ 'basket.checkout-shopping' | translate }}
            {{ basket.totalPrice | currencyFormatted }}&nbsp;
            {{ 'common.currency' | translate }}
        </button>
    </div>
</div>
