import { Injectable } from '@angular/core';

//import { LogLevel } from '../models/logging/log-level.enum';

@Injectable({ providedIn: 'root' })
export class BaseLogService {
    //level: LogLevel = environment.logLevel;
    logWithDate = true;
    logWithMainClass = true;

    changeLogLeveOneUp() {
        //this.level = (this.level + 1) % 6;
    }
}
