import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../auth/auth.service';
import { BasketService } from './basket.service';
import {
    AddProductDto,
    AddUserBasketToMasterBasketDto,
    RemoveProductDto,
    RenameUserBasketDto,
    SendUserBasketInEmailDto,
    UpdateQuantityDto,
    UserBasketClient,
    UserBasketDto,
    UserBasketProductDto,
} from '../index';

@Injectable({ providedIn: 'root' })
export class UserBasketService {
    userBasketListSubject = new BehaviorSubject<UserBasketDto[]>(null);
    userBasketListObservable = this.userBasketListSubject.asObservable();
    selectedUserBasketProductListSubject = new BehaviorSubject<UserBasketProductDto[]>(
        null
    );

    constructor(
        private authService: AuthenticationService,
        private basketService: BasketService,
        private userBasketClient: UserBasketClient
    ) {
        this.authService.getAuthStatusListener().subscribe((loggedIn) => {
            if (!loggedIn) {
                this.cleanLists();
            }
        });
    }

    private cleanLists() {
        this.cleanUserBaskets();
    }

    addProductToUserBasket(
        masterBasketId: number,
        productId: number,
        unitValue: number
    ): Observable<UserBasketDto> {
        const addProductDto = new AddProductDto({
            masterBasketId: masterBasketId,
            productId: productId,
            unitValue: unitValue,
        });
        return this.userBasketClient.addProductToUserBasket(addProductDto).pipe(
            map((userBasket) => {
                const userBasketList = this.userBasketListSubject.getValue();
                if (userBasketList) {
                    const foundUserBasket = userBasketList.find(
                        (findUserBasket) =>
                            findUserBasket.masterBasketID === masterBasketId
                    );

                    const newProductList = userBasket.userBasketProductList.filter(
                        (productDto) => productDto.product.productID !== productId
                    );

                    foundUserBasket.userBasketProductList = newProductList;
                    this.userBasketListSubject.next(userBasketList);
                }

                return userBasket;
            })
        );
    }

    removeProductFromUserBasket(
        masterBasketId: number,
        productId: number,
        providerId: number
    ): Observable<UserBasketDto> {
        return this.userBasketClient.removeProductFromUserBasket(
            new RemoveProductDto({ masterBasketId, productId, providerId })
        );
    }

    addUserBasketToMasterBasket(
        userMasterBasketId: number,
        providerId: number
    ): Observable<void> {
        return this.userBasketClient
            .addUserBasketToMasterBasket(
                new AddUserBasketToMasterBasketDto({ userMasterBasketId, providerId })
            )
            .pipe(
                map((userBasket) => {
                    this.basketService.getMasterBasket().subscribe();
                    return userBasket;
                })
            );
    }

    createUserBasket(userBasketName: string, providerCode: string): Observable<number> {
        return this.userBasketClient
            .createUserMasterBasket(userBasketName, providerCode)
            .pipe(
                map((userBasketId) => {
                    const newUserBasket = this.createNewEmptyUserBasket(
                        userBasketId,
                        userBasketName
                    );
                    this.addNewUserBasketToSubject(newUserBasket);
                    return userBasketId;
                })
            );
    }

    renameUserBasket(
        userBasketId: number,
        newUserBasketName: string
    ): Observable<UserBasketDto> {
        return this.userBasketClient
            .renameUserBasket(
                new RenameUserBasketDto({ userBasketId, newUserBasketName })
            )
            .pipe(
                map((basket) => {
                    this.changeUserBasketName(userBasketId, newUserBasketName);
                    return basket;
                })
            );
    }

    private changeUserBasketName(userBasketId: number, newUserBasketName: string) {
        const userBasketList = this.userBasketListSubject.value;
        this.userBasketClient.renameUserBasket(
            new RenameUserBasketDto({ newUserBasketName, userBasketId })
        );
        const userBasketIdx = userBasketList.findIndex(
            (userBasket) => userBasket.masterBasketID === userBasketId
        );
        userBasketList[userBasketIdx].basketName = newUserBasketName;
        this.userBasketListSubject.next(userBasketList);
    }

    private addNewUserBasketToSubject(newUserBasket: UserBasketDto) {
        const userBasketList = this.userBasketListSubject.getValue();
        if (userBasketList) {
            userBasketList.push(newUserBasket);
            this.userBasketListSubject.next(userBasketList);
        }
    }

    private createNewEmptyUserBasket(
        userBasketId: number,
        userBasketName: string
    ): UserBasketDto {
        const newUserBasket = new UserBasketDto();
        newUserBasket.masterBasketID = userBasketId;
        newUserBasket.basketName = userBasketName;
        newUserBasket.userBasketProductList = [];

        return newUserBasket;
    }

    getUserBaskets(providerId?: number): Observable<UserBasketDto[]> {
        return this.userBasketClient.getUserBaskets(providerId).pipe(
            map((userBaskets) => {
                userBaskets = userBaskets.sort((a, b) => {
                    return b.masterBasketID - a.masterBasketID;
                });
                this.userBasketListSubject.next(userBaskets);
                return userBaskets;
            })
        );
    }

    getUserBasketDetails(
        providerId: number,
        userMasterBasketGUID: string
    ): Observable<UserBasketDto> {
        return this.userBasketClient
            .getUserBasketDetails(providerId, userMasterBasketGUID)
            .pipe(
                map((userBaskets) => {
                    return userBaskets;
                })
            );
    }

    private cleanUserBaskets() {
        this.userBasketListSubject.next(null);
    }

    deleteUserList(userMasterBasketId: number): Observable<void> {
        return this.userBasketClient.deleteUserList(userMasterBasketId).pipe(
            map(() => {
                this.deleteUserListFromService(userMasterBasketId);
            })
        );
    }

    private deleteUserListFromService(userMasterBasketId: number) {
        const userBasketList = this.userBasketListSubject.value.filter(
            (userBasket) => userBasket.masterBasketID !== userMasterBasketId
        );
        this.userBasketListSubject.next(userBasketList);
    }

    updateQuantity(
        basketItemId: number,
        quantity: number,
        masterBasketId: number
    ): Observable<UserBasketDto> {
        return this.userBasketClient
            .updateUserBasketQuantity(
                new UpdateQuantityDto({
                    masterBasketId,
                    basketItemId,
                    quantity,
                })
            )
            .pipe(
                map((userBaskets) => {
                    const userBasketDtoList = this.userBasketListSubject.getValue();
                    const userBasket = userBasketDtoList.find(
                        (userBasketDto) => userBasketDto.masterBasketID === masterBasketId
                    );
                    userBasket.totalPrice = userBaskets.totalPrice;

                    userBasket.userBasketProductList =
                        userBaskets.userBasketProductList.sort((a, b) => {
                            return a.product.productID - b.product.productID;
                        });

                    const userProductDto = userBasket.userBasketProductList.find(
                        (userProduct) => userProduct.basketItemId === basketItemId
                    );

                    userProductDto.unitValue = quantity;
                    this.userBasketListSubject.next(userBasketDtoList);
                    return userBaskets;
                })
            );
    }

    updateQuantityUser(
        basketItemId: number,
        quantity: number,
        userMasterBasketId: number,
        providerId: number
    ): Observable<UserBasketDto> {
        return this.userBasketClient
            .updateUserBasketQuantity(
                new UpdateQuantityDto({
                    basketItemId,
                    quantity,
                    masterBasketId: userMasterBasketId,
                    providerId,
                })
            )
            .pipe(
                map((userBasket) => {
                    let userBasketDtoList = this.userBasketListSubject.getValue();
                    userBasketDtoList = userBasketDtoList.filter(
                        (basket) => basket.masterBasketID !== userBasket.masterBasketID
                    );

                    userBasketDtoList.push(userBasket);

                    this.userBasketListSubject.next(userBasketDtoList);
                    return userBasket;
                })
            );
    }

    createUserBasketFromSession(
        userBasketName: string,
        providerCode: string
    ): Observable<UserBasketDto> {
        return this.userBasketClient
            .createUserBasketFromSession(userBasketName, providerCode)
            .pipe(
                map((userBasket) => {
                    this.addNewUserBasketToSubject(userBasket);
                    return userBasket;
                })
            );
    }

    duplicateUserMasterBasketByGuid(
        userBasketName: string,
        userMasterBasketGUID: string,
        providerCode: string
    ): Observable<UserBasketDto> {
        return this.userBasketClient
            .duplicateUserMasterBasket(userBasketName, userMasterBasketGUID, providerCode)
            .pipe(
                map((userBasket) => {
                    this.addNewUserBasketToSubject(userBasket);
                    return userBasket;
                })
            );
    }

    getUserMasterBasketByGuid(
        guid: string,
        providerId: number
    ): Observable<UserBasketDto> {
        return this.userBasketClient.getUserMasterBasketByGuid(guid, providerId);
    }

    getUserMasterBasketGuid(masterBasketID: number): Observable<string> {
        return this.userBasketClient.getOrGenerateUserMasterBasketGuid(masterBasketID);
    }

    sendUserBasketInEmail(
        masterBasketId: number,
        lastName: string,
        firstName: string,
        email: string
    ): Observable<void> {
        return this.userBasketClient.sendUserBasketInEmail(
            new SendUserBasketInEmailDto({
                masterBasketId: masterBasketId,
                email,
                firstName,
                lastName,
            })
        );
    }

    public setselectedUserBasketProductList(
        userBasketProductList: UserBasketProductDto[]
    ): void {
        this.selectedUserBasketProductListSubject.next(userBasketProductList);
    }
}
