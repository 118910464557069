import { Component, Input, OnInit } from '@angular/core';
import {
    CategoryService,
    CategoryShared,
    ProductBlockDto,
    ServiceBaseService,
} from 'projects/library-shared/src/public-api';

@Component({
    selector: 'app-custom-category-block',
    templateUrl: 'custom-category-block.html',
    styleUrls: ['custom-category-block.scss'],
})
export class CustomCategoryBlockComponent implements OnInit {
    @Input() customBlock: ProductBlockDto;

    isSubCategory = false;

    categories: CategoryShared[];
    baseBackUrl = '';

    constructor(
        private categoryService: CategoryService,
        public baseService: ServiceBaseService
    ) {
        this.baseBackUrl = this.baseService.getMediaServiceUrl();
        this.baseBackUrl = this.baseBackUrl + 'interface/medium';
    }

    ngOnInit() {
        if (this.customBlock) {
            this.customBlock.BackGUID = `url(${this.baseBackUrl}/${this.customBlock.BackGUID})`;
            this.isSubCategory = true;
            this.categoryService
                .getCatChildrenList(this.customBlock.CategoryProgId)
                .subscribe((result) => {
                    this.categories = result;
                });
        } else {
            this.customBlock.BackColor = '0';
            this.categoryService.categories.subscribe((result) => {
                this.categories = result;
            });
        }
    }
}
