import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ServiceBaseService } from './service-base.service';
import { PaymentClient } from '../index';

@Injectable({ providedIn: 'root' })
export class PaymentService {
    paymentProviders = new BehaviorSubject<string[]>([]);

    private shopServiceUrl: string;
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(
        private http: HttpClient,
        private serviceBaseService: ServiceBaseService,
        private paymentClient: PaymentClient
    ) {
        this.shopServiceUrl = this.serviceBaseService.getBaseUrl();

        this.getPaymentProviders().subscribe((providers) =>
            this.paymentProviders.next(providers)
        );
    }

    payResult(transactionID: string): Observable<any> {
        return this.http
            .post<{ TagObject: any }>(
                this.shopServiceUrl + 'payment/payresult',
                { TransactionID: transactionID },
                { headers: this.headers }
            )
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    validateTransactionByIPN(WeShopTransactionGUID: string): Observable<any> {
        return this.http
            .post<{ TagObject: any }>(
                this.shopServiceUrl + 'payment/getPaymentResult/',
                { WeShopTransactionGUID: WeShopTransactionGUID },
                { headers: this.headers }
            )
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    SendEmailsByTransactionGUID(WeShopTransactionGUID: string): Observable<any> {
        return this.http
            .post<{ TagObject: any }>(
                this.shopServiceUrl + 'order/SendEmailsByTransactionGUID/',
                { WeShopTransactionGUID: WeShopTransactionGUID },
                { headers: this.headers }
            )
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    getPaymentProviders(): Observable<string[]> {
        return this.paymentClient.getPaymentProviders();
    }
}
