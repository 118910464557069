import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faTimesCircle, faUser } from '@fortawesome/free-solid-svg-icons';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import {
    OrderConcatDetailsDTO,
    OrderService,
    ServiceBaseService,
} from 'projects/library-shared/src/public-api';

@Component({
    selector: 'app-order-contact-details-dialog',
    templateUrl: './order-contact-details-dialog.component.html',
    styleUrls: ['./order-contact-details-dialog.component.scss'],
})
export class OrderContactDetailsDialogComponent implements OnInit {
    faUser = faUser;
    faTimesCircle = faTimesCircle;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<OrderContactDetailsDialogComponent>,
        private formBuilder: FormBuilder,
        private orderService: OrderService
    ) {}

    contactDetailsForm = this.formBuilder.group({
        Email: [this.data.order.OrdererEmail, [Validators.required, Validators.email]],
        FirstName: [
            this.data.order.OrdererFirstName,
            [Validators.required, Validators.minLength(3)],
        ],
        LastName: [
            this.data.order.OrdererLastName,
            [Validators.required, Validators.minLength(3)],
        ],
        Phone: [
            this.data.order.OrdererPhone,
            [Validators.required, Validators.minLength(9)],
        ],
    });

    preferredCountries: CountryISO[] =
        ServiceBaseService.countrycode === 'hu'
            ? [CountryISO.Hungary, CountryISO.Austria]
            : [CountryISO.Austria, CountryISO.Hungary];
    SearchCountryField = SearchCountryField;
    PhoneNumberFormat = PhoneNumberFormat;
    phoneForm = new FormGroup({
        phone: new FormControl(undefined, [Validators.required, Validators.minLength(7)]),
    });

    ngOnInit(): void {
        if (this.contactDetailsForm)
            this.phoneForm.controls.phone.setValue(
                this.contactDetailsForm.get('Phone').value
            );
    }

    onPhoneChanges(): void {
        const phone = this.phoneForm.controls.phone.value;
        if (
            phone?.number &&
            phone.number !== this.contactDetailsForm.get('Phone').value
        ) {
            this.contactDetailsForm.get('Phone').setValue(phone.number);
            this.contactDetailsForm.markAsDirty();
        }
    }

    onSubmit() {
        const dto: OrderConcatDetailsDTO = {
            Phone: this.contactDetailsForm.get('Phone').value,
            FirstName: this.contactDetailsForm.get('FirstName').value,
            LastName: this.contactDetailsForm.get('LastName').value,
            Email: this.contactDetailsForm.get('Email').value,
            OrderCode: this.data.order.OrderCode,
            BirthDate: this.data.order.BirthDate,
        };
        if (this.contactDetailsForm.dirty && this.contactDetailsForm.valid) {
            this.orderService.modifyOrderDetails(dto).subscribe(
                (data) => {
                    this.dialogRef.close(data);
                },
                () => {
                    this.dialogRef.close();
                }
            );
        }
    }
}
