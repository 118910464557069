<h1 mat-dialog-title>
    {{ title }}
</h1>

<div mat-dialog-content>
    <p>{{ message }}</p>
</div>

<div mat-dialog-actions>
    <div class="row w-100">
        <div class="col-6">
            <button class="btn btn-success btn-rounded w-100" (click)="onConfirm()">
                {{ 'common.yes' | translate }}
            </button>
        </div>
        <div class="col-6">
            <button
                class="btn btn-outline-success btn-rounded w-100"
                (click)="onDismiss()"
            >
                {{ 'common.no' | translate }}
            </button>
        </div>
    </div>
</div>
