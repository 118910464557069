import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderSelectComponent } from './provider-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';

@NgModule({
    declarations: [ProviderSelectComponent],
    imports: [CommonModule, TranslateModule, IonicModule],
    exports: [ProviderSelectComponent],
})
export class ProviderSelectModule {}
