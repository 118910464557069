<h1 class="close-button-panel text-size-20" mat-dialog-title>
    <div class="text-end close-button">
        <span class="pointer" (click)="dialogRef.close()">
            <fa-icon [icon]="faTimesCircle"></fa-icon>
        </span>
    </div>
</h1>

<div class="row">
    <div class="col-8 px-0 mb-4 mt-4 font-weight-bold title">
        <fa-icon [icon]="faUser" class="me-1"></fa-icon>
        {{ 'common.contact-details' | translate }}
    </div>

    <form
        class="col-12 justify-content-center"
        [formGroup]="contactDetailsForm"
        (ngSubmit)="onSubmit()"
    >
        <mat-form-field appearance="outline" class="field py-0 col">
            <mat-label>{{ 'common.firstname' | translate }}</mat-label>
            <input
                matInput
                type="text"
                formControlName="FirstName"
                id="firstname"
                placeholder="{{ 'common.firstname' | translate }}"
                aria-label="FirstName"
                required
            />
            <mat-error
                *ngIf="
                    contactDetailsForm.get('FirstName').errors &&
                    contactDetailsForm.get('FirstName').touched
                "
            >
                {{ 'log-in.firstname-required' | translate }}</mat-error
            >
        </mat-form-field>
        <mat-form-field appearance="outline" class="field py-0 col">
            <mat-label color="red">{{ 'common.lastname' | translate }}</mat-label>
            <input
                matInput
                type="text"
                formControlName="LastName"
                id="lastname"
                placeholder="{{ 'common.lastname' | translate }}"
                aria-label="LastName"
                required
            />

            <mat-error
                *ngIf="
                    contactDetailsForm.get('LastName').errors &&
                    contactDetailsForm.get('LastName').touched
                "
                >{{ 'log-in.lastname-required' | translate }}</mat-error
            >
        </mat-form-field>
        <mat-form-field appearance="outline" class="field py-0 col">
            <mat-label>{{ 'common.e-mail' | translate }}</mat-label>
            <input
                matInput
                type="text"
                formControlName="Email"
                id="mail"
                placeholder="{{ 'common.e-mail' | translate }}"
                aria-label="Email"
                required
            />

            <mat-error
                *ngIf="
                    contactDetailsForm.get('Email').errors &&
                    contactDetailsForm.get('Email').touched
                "
            >
                {{ 'log-in.email-required' | translate }}
            </mat-error>
        </mat-form-field>
        <div
            class="col-12"
            style="display: inline-table; position: relative"
            [formGroup]="phoneForm"
        >
            <div class="wrapper">
                <mat-label class="custom-label">{{
                    'checkout.phone-number' | translate
                }}</mat-label>
                <ngx-intl-tel-input
                    [cssClass]="
                        'form-control tel-input ' +
                        (phoneForm.controls.phone.errors ? 'tel-input-invalid' : '')
                    "
                    id="tel"
                    [preferredCountries]="preferredCountries"
                    [enableAutoCountrySelect]="true"
                    [enablePlaceholder]="true"
                    [searchCountryFlag]="true"
                    [searchCountryField]="[
                        SearchCountryField.Iso2,
                        SearchCountryField.Name
                    ]"
                    [selectFirstCountry]="false"
                    [selectedCountryISO]="preferredCountries[0]"
                    [maxLength]="15"
                    [phoneValidation]="true"
                    [separateDialCode]="false"
                    [numberFormat]="PhoneNumberFormat.International"
                    name="Phone"
                    formControlName="phone"
                    (ngModelChange)="onPhoneChanges()"
                >
                </ngx-intl-tel-input>
                <small
                    class="text-danger mt-1 ps-2"
                    *ngIf="
                        phoneForm.controls.phone.errors &&
                        phoneForm.controls.phone.touched &&
                        phoneForm.controls.phone.errors?.validatePhoneNumber?.valid !==
                            false
                    "
                >
                    {{ 'common.phone-number-required' | translate }}
                </small>
                <small
                    class="text-danger mt-1 ps-2"
                    *ngIf="
                        phoneForm.controls.phone.errors?.validatePhoneNumber?.valid ===
                        false
                    "
                >
                    {{ 'application.invalid-phone-number' | translate }}
                </small>
            </div>
        </div>
        <div mat-dialog-actions class="d-flex justify-content-center w-100">
            <button
                mat-stroked-button
                class="save-btn btn-rounded mb-1 text-size-16 font-weight-600"
                mat-button
                type="submit"
            >
                {{ 'common.save' | translate }}
            </button>
        </div>
    </form>
</div>
