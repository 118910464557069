<div *ngIf="openRespMenu" class="overlayer-resp" (click)="closeRespMenu()"></div>

<!------ Mobil menu START ------>

<div class="resp-view">
    <div id="HamburgerMenuBackground" [ngClass]="{ open: openRespMenu }"></div>

    <nav
        id="drawer"
        class="navigation"
        [ngClass]="{ open: openRespMenu }"
        [ngStyle]="{ top: navPosition + 'px' }"
    >
        <div class="nav navigation-container-mobile level-{{ activeLevel }}">
            <ul class="navigation-panel ps-0" id="menu">
                <li class="topbar pt-2 pb-2 mb-3 pointer">
                    <div class="row text-start">
                        <div class="d-block d-md-none col-1 col-sm-1">
                            <div>
                                <fa-icon
                                    [icon]="faBars"
                                    class="pointer color-turquoise"
                                    size="lg"
                                    (click)="closeRespMenu()"
                                ></fa-icon>
                            </div>
                        </div>

                        <a
                            class="d-block d-lg-none text-start text-md-center col-9 col-sm-9 mt-sm-0 mt-md-3"
                            (click)="closeRespMenu()"
                        >
                            <a>
                                <img
                                    src="assets/images/roksh_logo.svg"
                                    alt="{{ 'info.alt-desktop-logo' | translate }}"
                                    class="sk-logo-resp"
                                />
                            </a>
                        </a>

                        <a
                            class="d-block d-lg-none float-end text-md-center col-1 mt-sm-0 mt-md-3"
                            (click)="closeRespMenu()"
                        >
                            <a (click)="closeRespMenu()">
                                <img
                                    src="assets/mobil-menu/close-menu.svg"
                                    alt="{{ 'info.alt-desktop-logo' | translate }}"
                                />
                            </a>
                        </a>
                    </div>
                </li>

                <ng-container *ngIf="!isAuthenticated">
                    <li (click)="onOpenDialog(); closeRespMenu()" class="pointer mt-2">
                        <div class="pt-2 pb-2 ms-4 mb-0">
                            <img
                                class="item-img me-2"
                                src="assets/mobil-menu/connect.svg"
                            />

                            <span class="item-color">
                                {{ 'common.login' | translate }}
                            </span>
                        </div>
                    </li>
                </ng-container>

                <ng-container *ngIf="isAuthenticated">
                    <li class="pointer mt-2 mb-2">
                        <div class="pt-2 pb-2 ms-4 mb-0">
                            <span class="username">{{ user?.FirstName }}</span>
                        </div>
                    </li>
                </ng-container>
                <li
                    (click)="onOpenPostcodeDialog(); closeRespMenu()"
                    class="pointer mt-2"
                >
                    <div class="pt-2 pb-2 ms-4 mb-0">
                        <app-postcode
                            [onlyTextShown]="true"
                            class="pointer d-md-inline-block mobil-postcode"
                        >
                        </app-postcode>
                    </div>
                </li>
                <hr class="mx-4 d-block mt-1" />
                <li
                    *ngIf="isCategoryMenuDisplayed"
                    (click)="closeRespMenu()"
                    class="pointer"
                    [ngClass]="{
                        'd-none': isOwnWebShop
                    }"
                    [routerLink]="['/']"
                >
                    <div class="pt-2 pb-2 ms-4 mb-0">
                        <img class="item-img me-2" src="assets/svg/boltok.svg" />

                        <span class="item-color">
                            {{ 'common.our-shops' | translate }}
                        </span>
                    </div>
                </li>

                <ng-container *ngIf="isAuthenticated">
                    <li
                        *ngIf="selectedProviderName"
                        [routerLink]="[
                            'router.user' | translate,
                            'router.shopping-list' | translate
                        ]"
                        [queryParams]="{ providerName: selectedProviderName }"
                        (click)="closeRespMenu()"
                        class="pointer"
                    >
                        <div class="pt-2 pb-2 ms-4 mb-0">
                            <img
                                class="item-img me-2"
                                src="assets/mobil-menu/listak.svg"
                            />

                            <span class="item-color">
                                {{ 'common.my-shopping-lists' | translate }}
                            </span>
                        </div>
                    </li>
                    <li
                        *ngIf="!selectedProviderName"
                        [routerLink]="[
                            'router.user' | translate,
                            'router.shopping-list' | translate
                        ]"
                        (click)="closeRespMenu()"
                        class="pointer"
                    >
                        <div class="pt-2 pb-2 ms-4 mb-0">
                            <img
                                class="item-img me-2"
                                src="assets/mobil-menu/listak.svg"
                            />

                            <span class="item-color">
                                {{ 'common.my-shopping-lists' | translate }}
                            </span>
                        </div>
                    </li>
                    <!--------------------------->
                    <li
                        *ngIf="selectedProviderName"
                        [routerLink]="[
                            'router.user' | translate,
                            'router.favourites' | translate
                        ]"
                        [queryParams]="{ providerName: selectedProviderName }"
                        (click)="closeRespMenu()"
                        class="pointer"
                    >
                        <div class="pt-2 pb-2 ms-4 mb-0">
                            <img
                                class="item-img me-2"
                                src="assets/mobil-menu/kedvencek.svg"
                            />

                            <span class="item-color">
                                {{ 'common.favourites' | translate }}
                            </span>
                        </div>
                    </li>
                    <li
                        *ngIf="!selectedProviderName"
                        [routerLink]="[
                            'router.user' | translate,
                            'router.favourites' | translate
                        ]"
                        (click)="closeRespMenu()"
                        class="pointer"
                    >
                        <div class="pt-2 pb-2 ms-4 mb-0">
                            <img
                                class="item-img me-2"
                                src="assets/mobil-menu/kedvencek.svg"
                            />

                            <span class="item-color">
                                {{ 'common.favourites' | translate }}
                            </span>
                        </div>
                    </li>
                    <!--------------------------->

                    <li
                        *ngIf="selectedProviderName"
                        [routerLink]="[
                            'router.user' | translate,
                            'router.settings' | translate
                        ]"
                        (click)="closeRespMenu()"
                        [queryParams]="{ providerName: selectedProviderName }"
                        class="pointer"
                    >
                        <div class="pt-2 pb-2 ms-4 mb-0">
                            <img
                                class="item-img me-2"
                                src="assets/mobil-menu/felhasznaloi.svg"
                            />

                            <span class="item-color">
                                {{ 'common.settings' | translate }}
                            </span>
                        </div>
                    </li>
                    <li
                        *ngIf="!selectedProviderName"
                        [routerLink]="[
                            'router.user' | translate,
                            'router.settings' | translate
                        ]"
                        (click)="closeRespMenu()"
                        class="pointer"
                    >
                        <div class="pt-2 pb-2 ms-4 mb-0">
                            <img
                                class="item-img me-2"
                                src="assets/mobil-menu/felhasznaloi.svg"
                            />

                            <span class="item-color">
                                {{ 'common.settings' | translate }}
                            </span>
                        </div>
                    </li>
                    <!--------------------------->

                    <li
                        *ngIf="selectedProviderName"
                        [routerLink]="[
                            'router.user' | translate,
                            'router.my-orders' | translate
                        ]"
                        [queryParams]="{ providerName: selectedProviderName }"
                        (click)="closeRespMenu()"
                        class="pointer"
                    >
                        <div class="pt-2 pb-2 ms-4 mb-0">
                            <img
                                class="item-img me-2"
                                src="assets/mobil-menu/megrendeleseim.svg"
                            />

                            <span class="item-color">
                                {{ 'common.my-orders' | translate }}
                            </span>
                        </div>
                    </li>
                    <li
                        *ngIf="!selectedProviderName"
                        [routerLink]="[
                            'router.user' | translate,
                            'router.my-orders' | translate
                        ]"
                        (click)="closeRespMenu()"
                        class="pointer"
                    >
                        <div class="pt-2 pb-2 ms-4 mb-0">
                            <img
                                class="item-img me-2"
                                src="assets/mobil-menu/megrendeleseim.svg"
                            />

                            <span class="item-color">
                                {{ 'common.my-orders' | translate }}
                            </span>
                        </div>
                    </li>
                    <!--------------------------->
                </ng-container>

                <li *ngIf="isCategoryMenuDisplayed">
                    <hr class="mx-4 d-block mt-1" />
                </li>

                <li *ngIf="isCategoryMenuDisplayed" (click)="closeRespMenu()">
                    <app-category-list></app-category-list>
                </li>
                <li
                    *ngIf="isAuthenticated"
                    (click)="onLogOut(); closeRespMenu()"
                    class="pointer"
                >
                    <div class="pt-2 pb-2 ms-4 mb-0">
                        <img
                            class="item-img me-2"
                            src="assets/mobil-menu/close-menu.svg"
                        />

                        <span class="item-color">
                            {{ 'common.logout' | translate }}
                        </span>
                    </div>
                </li>
                <li *ngIf="isCategoryMenuDisplayed">
                    <hr class="mx-4 d-block mt-1" />
                </li>
                <li (click)="closeRespMenu()" class="pointer">
                    <div class="pt-2 ms-4 mb-0">
                        <a
                            href="{{
                                'static-links.roksh-knowledge'
                                    | translate
                                    | providerTheme : 'static-links.roksh-knowledge'
                                    | async
                            }}"
                            target="_blank"
                        >
                            <span>{{ 'footer.faq' | translate }}</span>
                        </a>
                    </div>
                </li>

                <li (click)="closeRespMenu()" [routerLink]="['/cookiek']" class="pointer">
                    <div class="pt-2 ms-4 mb-0">
                        <span>{{ 'footer.cookie-consern' | translate }}</span>
                    </div>
                </li>

                <li (click)="closeRespMenu()" class="pointer">
                    <div class="pt-2 ms-4 mb-0">
                        <a
                            href="{{
                                'static-links.roksh-data-protect'
                                    | translate
                                    | providerTheme : 'static-links.roksh-data-protect'
                                    | async
                            }}"
                            target="_blank"
                        >
                            <span>{{ 'footer.data-protect' | translate }}</span>
                        </a>
                    </div>
                </li>

                <li (click)="closeRespMenu()" class="pointer">
                    <div class="pt-2 ms-4 mb-0">
                        <a
                            href="{{
                                'static-links.roksh-terms'
                                    | translate
                                    | providerTheme : 'static-links.roksh-terms'
                                    | async
                            }}"
                            target="_blank"
                        >
                            <span>{{ 'footer.terms' | translate }}</span>
                        </a>
                    </div>
                </li>

                <li (click)="closeRespMenu()" class="pointer">
                    <div class="pt-2 ms-4 mb-0">
                        <a
                            href="{{
                                'static-links.roksh-contact'
                                    | translate
                                    | providerTheme : 'static-links.roksh-contact'
                                    | async
                            }}"
                            target="_blank"
                        >
                            <span>{{ 'footer.contact' | translate }}</span>
                        </a>
                    </div>
                </li>

                <li class="pointer">
                    <div class="pt-2 ms-4 mb-0">
                        <a
                            target="_blank"
                            href="{{
                                'static-links.roksh-partner'
                                    | translate
                                    | providerTheme : 'static-links.roksh-partner'
                                    | async
                            }}"
                        >
                            {{ 'footer.partner' | translate }}
                        </a>
                    </div>
                </li>

                <li class="pointer">
                    <div class="pt-2 ms-4 mb-0">
                        <a
                            href="{{
                                'static-links.roksh-carrier'
                                    | translate
                                    | providerTheme : 'static-links.roksh-carrier'
                                    | async
                            }}"
                            target="_blank"
                        >
                            {{ 'footer.carrier' | translate }}
                        </a>
                    </div>
                </li>

                <li>
                    <div class="pt-2 pb-2 ms-4 mb-0">
                        <fa-icon
                            [icon]="faPhoneAlt"
                            class="pointer color-turquoise d-inline-block item-img me-2"
                            size="lg"
                        ></fa-icon>

                        <span>
                            <a href="tel:{{ 'common.contact-phone' | translate }}">
                                {{ 'common.contact-phone' | translate }}
                            </a>
                        </span>
                    </div>
                </li>

                <li class="pt-2 pb-2 ms-4 mb-0 text-size-14">
                    <a
                        href="{{
                            'static-links.roksh-facebook'
                                | translate
                                | providerTheme : 'static-links.roksh-facebook'
                                | async
                        }}"
                        target="_blank"
                    >
                        <fa-icon
                            [icon]="faFacebook"
                            [styles]="{ color: '#3B3B3B' }"
                            size="2x"
                            class="me-2"
                        ></fa-icon>
                    </a>

                    <a
                        href="{{
                            'static-links.roksh-instagram'
                                | translate
                                | providerTheme : 'static-links.roksh-instagram'
                                | async
                        }}"
                        target="_blank"
                    >
                        <fa-icon
                            [icon]="faInstagram"
                            [styles]="{ color: '#3B3B3B' }"
                            size="2x"
                            class="me-2"
                        ></fa-icon>
                    </a>
                </li>

                <li class="pt-2 pb-2 ms-4 mb-0 text-size-14">
                    <img
                        style="width: 285px !important; height: auto !important"
                        src="../../../assets/barion-card-strip-intl_1200px.png"
                        title=" Barion"
                        alt="Barion"
                        *ngIf="
                            defaultPaymentProviders &&
                            defaultPaymentProviders.indexOf('BarionCard') > -1
                        "
                    />
                </li>

                <li class="pt-2 pb-2 ms-4 mb-0 text-size-14">
                    <img
                        style="width: 270px !important; height: auto !important"
                        src="../../../assets/revolut.png"
                        title="Revolut"
                        alt="Revolut"
                        *ngIf="
                            defaultPaymentProviders &&
                            defaultPaymentProviders.indexOf('RevolutCard') > -1
                        "
                    />
                </li>
            </ul>

            <ng-container [ngSwitch]="mobileSubMenu">
                <!-- USER-BASKETS -->

                <ul
                    *ngSwitchCase="'USER-BASKETS'"
                    class="sec-categ-ul navigation-panel ps-0"
                >
                    <li
                        (click)="closeRespSubCategory()"
                        style="background-color: #f5f5f5"
                    >
                        <h5 class="pt-2 pb-2 ms-3 mb-0">
                            <fa-icon [icon]="faAngleLeft"></fa-icon>

                            <fa-icon [icon]="faUser" class="me-2"></fa-icon>
                            {{ user?.FirstName }}
                        </h5>
                    </li>
                </ul>

                <!-- SUB_CATEGORY -->

                <ul
                    *ngSwitchCase="'SUB_CATEGORY'"
                    class="sec-categ-ul navigation-panel"
                    [ngClass]="{
                        'open-resp-menu':
                            respActiveMenu === 'triggerResp' + activeCategory.categoryID
                    }"
                    id="targetResp{{ activeCategory.categoryID }}"
                >
                    <li class="pointer">
                        <h5
                            class="mt-3 ms-3 mb-3 sec-categ back-main-category"
                            (click)="closeRespSubCategory()"
                        >
                            <fa-icon [icon]="faAngleLeft"></fa-icon>
                            {{ 'common.categories' | translate }}
                        </h5>

                        <h5
                            [routerLink]="[activeCategory.url]"
                            (click)="closeRespMenu()"
                            class="mt-2 ms-3 mb-0 sec-categ font-weight-600"
                        >
                            <img
                                [src]="[
                                    'assets/images/category_icons/' +
                                        activeCategory.categoryIconFileName +
                                        '.png'
                                ]"
                                style="height: 20px; margin-right: 4px"
                            />
                            {{ activeCategory.categoryName }}
                        </h5>

                        <hr
                            style="margin-left: 10px; margin-right: 10px; display: block"
                        />
                    </li>

                    <ng-container *ngFor="let sc of activeCategory.childList">
                        <li class="pointer ps-3 pe-1 py-2">
                            <a
                                [routerLink]="[sc.url]"
                                class="text-start sec-categ"
                                (click)="closeRespMenu()"
                            >
                                {{ sc.categoryName }}
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </ng-container>
        </div>
    </nav>
</div>
