import { Injectable } from '@angular/core';
import { CategoryShared } from '../models/category/category.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { concatMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { SessionService } from './session/session.service';
import { ServiceBaseService } from './service-base.service';

@Injectable({ providedIn: 'root' })
export class CategoryService {
    catList: CategoryShared[];
    private shopServiceUrl: string;
    private headers: HttpHeaders;

    categories: BehaviorSubject<CategoryShared[]> = new BehaviorSubject<CategoryShared[]>(
        []
    );

    constructor(
        private http: HttpClient,
        private serviceBaseService: ServiceBaseService,
        private sessionService: SessionService
    ) {
        this.shopServiceUrl = this.serviceBaseService.getBaseUrl();
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        this.sessionService.sessionSubject
            .pipe(
                concatMap((session) => {
                    if (session) {
                        this.categories.next([]);
                        return this.getFullCategoryList();
                    } else {
                        return of([]);
                    }
                })
            )
            .subscribe((categories) => this.categories.next(categories));
        // .subscribe();
    }

    getFullCategoryList(): Observable<CategoryShared[]> {
        return this.http.post<CategoryShared[]>(
            this.shopServiceUrl + 'category/GetFullCategoryList/',
            {},
            { headers: this.headers }
        );
    }

    getCatChildrenList(progID: string): Observable<CategoryShared[]> {
        return this.http.get<CategoryShared[]>(
            `${this.shopServiceUrl}category/getChildrenCategories?ProgID=${progID}`,
            { headers: this.headers }
        );
    }
}
