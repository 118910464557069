<ng-container>
    <div *ngIf="!isForShoppingList; else shoppingList">
        <div *ngIf="isProviderSelectedForShopping || isArchie">
            <ng-container
                *ngIf="
                    !isForMassAdd &&
                        calculatedBasketItem &&
                        (calculatedBasketItem.unitValue || isOriginPlaceBasket);
                    else addToBasket
                "
            >
                <div class="d-flex flex-row align-items-end justify-content-between">
                    <div
                        [class]="'minus-container ' + plusMinusBtnClass"
                        (click)="stepQuantity(-1)"
                    >
                        <fa-icon
                            *ngIf="!quantityChangeInProgress; else qtySpinnerMinus"
                            [icon]="faMinus"
                        ></fa-icon>

                        <ng-template #qtySpinnerMinus>
                            <fa-icon [icon]="faSpinner" [spin]="true"> ></fa-icon>
                        </ng-template>
                    </div>
                    <div class="quantity-container mx-1" [style.width]="quantityWidth">
                        <select
                            class="text-center w-100 h-100 fw-bold p-0 m-0 pointer"
                            [(ngModel)]="selectedQuantity"
                            (change)="selectQuantity()"
                        >
                            <option *ngFor="let q of quantityList" [ngValue]="q[0]">
                                {{ q[1] }}
                            </option>
                        </select>
                    </div>
                    <div
                        [class]="'plus-container gradient ' + plusMinusBtnClass"
                        (click)="stepQuantity(1)"
                    >
                        <fa-icon
                            *ngIf="!quantityChangeInProgress; else qtySpinnerPlus"
                            [icon]="faPlus"
                            class="text-white"
                        ></fa-icon>

                        <ng-template #qtySpinnerPlus>
                            <fa-icon
                                [icon]="faSpinner"
                                [spin]="true"
                                class="text-white"
                            ></fa-icon>
                        </ng-template>
                    </div>
                </div>
            </ng-container>
            <ng-template #addToBasket>
                <div *ngIf="addToCartProhibited">
                    <button
                        class="add-to-cart-btn btn-rounded text-size-16 font-weight-600"
                        [ngStyle]="{
                            width: buttonWidth,
                            height: buttonHeight,
                            'padding-right': buttonPadding,
                            'padding-left': buttonPadding,
                            'padding-top': buttonPaddingY,
                            'padding-bottom': buttonPaddingY
                        }"
                    >
                        <fa-icon
                            *ngIf="inProgress"
                            [icon]="faSpinner"
                            [spin]="true"
                        ></fa-icon>
                    </button>
                </div>
                <div *ngIf="!addToCartProhibited">
                    <button
                        class="add-to-cart-btn btn-rounded text-size-16 font-weight-600"
                        (click)="addToCartOrReplace()"
                        [ngStyle]="{
                            width: buttonWidth,
                            height: buttonHeight,
                            'padding-right': buttonPadding,
                            'padding-left': buttonPadding,
                            'padding-top': buttonPaddingY,
                            'padding-bottom': buttonPaddingY
                        }"
                    >
                        <fa-icon
                            *ngIf="inProgress"
                            [icon]="faSpinner"
                            [spin]="true"
                        ></fa-icon>
                        <fa-icon
                            class="button-basket-icon"
                            *ngIf="!inProgress && cartIcon && !inOwnWebShop"
                            [icon]="faShoppingCart"
                        ></fa-icon>
                        <img
                            *ngIf="!inProgress && cartIcon && inOwnWebShop"
                            style="margin-right: 5px; margin-bottom: 5px; height: 20px"
                            src="https://weshopsiteimages.azureedge.net/images/aldi-basket.svg"
                            alt="basket"
                        />

                        {{ isForMassAdd ? massAddButtonText : buttonText }}
                    </button>
                </div>
            </ng-template>
        </div>
    </div>
    <ng-template #shoppingList>
        <ng-container>
            <div class="d-flex flex-row align-items-end justify-content-between">
                <div
                    class="minus-container blue-color blue-border"
                    (click)="onChangeQuantity(selectedShoppingListQuantity - 1)"
                >
                    <fa-icon
                        *ngIf="!quantityChangeInProgress; else qtySpinnerMinus"
                        [icon]="faMinus"
                    ></fa-icon>

                    <ng-template #qtySpinnerMinus>
                        <fa-icon [icon]="faSpinner" [spin]="true"> ></fa-icon>
                    </ng-template>
                </div>
                <div
                    class="quantity-container mx-1 blue-border"
                    [style.width]="quantityWidth"
                >
                    <select
                        class="text-center text-black w-100 h-100 fw-bold p-0 m-0 pointer"
                        [(ngModel)]="selectedShoppingListQuantity"
                        (change)="onChangeQuantity()"
                    >
                        <option *ngFor="let q of quantityList" [ngValue]="q[0]">
                            {{ q[1] }}
                        </option>
                    </select>
                </div>
                <div
                    class="plus-container bg-blue"
                    (click)="onChangeQuantity(selectedShoppingListQuantity + 1)"
                >
                    <fa-icon
                        *ngIf="!quantityChangeInProgress; else qtySpinnerPlus"
                        [icon]="faPlus"
                        class="text-white"
                    ></fa-icon>

                    <ng-template #qtySpinnerPlus>
                        <fa-icon
                            [icon]="faSpinner"
                            [spin]="true"
                            class="text-white"
                        ></fa-icon>
                    </ng-template>
                </div>
            </div>
        </ng-container>
        <div *ngIf="addToCartProhibited">
            <button
                class="add-to-cart-btn btn-rounded text-size-16 font-weight-600"
                [ngStyle]="{
                    width: buttonWidth,
                    height: buttonHeight,
                    'padding-right': buttonPadding,
                    'padding-left': buttonPadding,
                    'padding-top': buttonPaddingY,
                    'padding-bottom': buttonPaddingY
                }"
            >
                <fa-icon *ngIf="inProgress" [icon]="faSpinner" [spin]="true"></fa-icon>
            </button>
        </div>
        <div *ngIf="!addToCartProhibited && isProviderSelectedForShopping">
            <button
                [disabled]="
                    calculatedBasketItem &&
                    (calculatedBasketItem.unitValue || isOriginPlaceBasket)
                "
                class="add-to-cart-btn btn-rounded mb-1 mt-2 text-size-16 font-weight-600"
                (click)="addToCartOrReplace()"
                [ngStyle]="{
                    height: buttonHeight,
                    'padding-right': buttonPadding,
                    'padding-left': buttonPadding,
                    'padding-top': buttonPaddingY,
                    'padding-bottom': buttonPaddingY
                }"
            >
                <fa-icon *ngIf="inProgress" [icon]="faSpinner" [spin]="true"></fa-icon>
                <div
                    *ngIf="
                        !(
                            calculatedBasketItem &&
                            (calculatedBasketItem.unitValue || isOriginPlaceBasket)
                        );
                        else alreadyInBasket
                    "
                >
                    <fa-icon
                        class="button-basket-icon"
                        *ngIf="!inProgress && cartIcon && !inOwnWebShop"
                        [icon]="faShoppingCart"
                    ></fa-icon>
                    <img
                        *ngIf="!inProgress && cartIcon && inOwnWebShop"
                        style="margin-right: 5px; margin-bottom: 5px; height: 20px"
                        src="https://weshopsiteimages.azureedge.net/images/aldi-basket.svg"
                        alt="basket"
                    />

                    <span *ngIf="!inProgress" class="ps-1">{{
                        isForMassAdd ? massAddButtonText : buttonText
                    }}</span>
                </div>
                <ng-template #alreadyInBasket>
                    <fa-icon class="button-basket-icon" [icon]="faCheck"></fa-icon>
                </ng-template>
            </button>
        </div>
    </ng-template>
</ng-container>
