<div
    class="color-gray font-weight-600 pointer container header-container"
    (click)="showZipCodeDialog()"
>
    <div class="float-start">
        <img
            src="assets/images/roksh_logo.svg"
            class="d-block d-md-none roksh-logo-img-sm"
        />
        <img
            src="assets/svg/Logo_slogen.svg"
            class="d-none d-md-block roksh-logo-img-sm"
        />
    </div>
    <div class="float-end">
        {{ 'shop-select-dialog.delivery-here' | translate }}: {{ postcodeValue }}
        <fa-icon
            [icon]="faEdit"
            class="menu-nav-resp pointer color-turquoise"
            size="lg"
        ></fa-icon>
    </div>
</div>

<div class="w-100" style="background-color: #00b9ce; height: 200px">
    <div class="container">
        <h4
            class="text-white font-weight-600 float-start mt-1 mb-1 w-50 w-md-25 p-4 pb-4 full-header-text"
        >
            {{
                'dashboard.welcome-message'
                    | translate
                    | providerTheme : 'dashboard.welcome-message'
                    | async
            }}
        </h4>

        <img
            [src]="
                imageSrc('/assets/images/roksh-landing-kv-static.png', 'img-landing')
                    | async
            "
            class="d-sm-block header-img"
        />
    </div>
</div>

<div class="clearfix"></div>
<div class="container" style="font-size: inherit">
    <ng-container *ngIf="isShopChanging">
        <div class="text-center w-100">
            <img
                src="/assets/loading_kosar.gif"
                style="margin-top: 50px; margin: auto"
            /><br />
            <p>{{ 'shop-select-dialog.shop-selected' | translate }}</p>
        </div>
    </ng-container>

    <ng-container *ngIf="!isShopChanging">
        <div style="position: relative">
            <!----------------We dont deliver to this zipcode------->
            <ng-container *ngIf="isValidZip">
                <!----------------Available providers to shopping------------------>
                <ng-container *ngIf="availableShops?.length">
                    <div class="d-flex container-shop-box">
                        <form [formGroup]="ShopHandlerForm" class="w-100">
                            <div
                                style="width: 96%"
                                *ngIf="
                                    onlyOneShopSelected.length === 1 &&
                                    onlyOneShopSelected.indexOf(
                                        availableShopWBasketInfos[0].shopName
                                    ) > -1
                                "
                            >
                                <h5
                                    class="mt-4 font-weight-bolder"
                                    style="color: #00b9ce"
                                >
                                    {{ 'common.your-shopping-in-progress' | translate }}
                                </h5>
                                <div class="col-12 d-inline-block p-1">
                                    <div
                                        class="w-100 d-inline-block p-4 mx-1 mt-1 mb-2 selected-shop-box"
                                        (click)="
                                            selectThisShop(
                                                availableShopWBasketInfos[0].shopName
                                            )
                                        "
                                    >
                                        <div class="row">
                                            <div class="col-12 col-md-3 text-center">
                                                <img
                                                    class="d-block"
                                                    style="
                                                        max-width: 100%;
                                                        max-height: 60px;
                                                        margin: 10px auto;
                                                    "
                                                    src="{{ siteImageUrl }}logos/{{
                                                        availableShopWBasketInfos[0]
                                                            .shopName
                                                    }}-logo-s.png"
                                                />
                                            </div>
                                            <div
                                                class="col-12 col-md-5 text-center text-md-left pt-2"
                                            >
                                                <!--USER CAN SELECT PROVIDERS TO SHOP BEGIN-->
                                                <label
                                                    class="container m-0 pt-3 selected-label"
                                                >
                                                    <input
                                                        *ngIf="
                                                            userSelectedAvailableShops &&
                                                            onlyOneShopSelected.indexOf(
                                                                availableShopWBasketInfos[0]
                                                                    .shopName
                                                            ) > -1
                                                        "
                                                        checked
                                                        type="radio"
                                                        style="float: left"
                                                        name="shop-selector"
                                                        value="{{
                                                            availableShopWBasketInfos[0]
                                                                .shopName
                                                        }}"
                                                        (click)="dialogRef.close()"
                                                    />

                                                    <span class="checkmark"></span>
                                                </label>
                                                <!--END-->

                                                <strong>
                                                    {{
                                                        availableShopWBasketInfos[0]
                                                            .shopNameToShow
                                                    }}
                                                </strong>
                                            </div>
                                            <div
                                                class="col-12 col-md-4 text-center text-md-right pt-2"
                                            >
                                                <div
                                                    class="d-inline-flex selected-shop-name-container"
                                                >
                                                    <div style="width: 55px">
                                                        <div
                                                            class="position-absolute text-end text-size-24 font-weight-600"
                                                            style="
                                                                width: 30px;
                                                                color: darkorange;
                                                            "
                                                        >
                                                            {{
                                                                availableShopWBasketInfos[0]
                                                                    .basketTotalItems
                                                            }}
                                                        </div>
                                                        <img
                                                            src="assets/svg/basket_orange.svg"
                                                            alt="Kosár ikon"
                                                            class="basket-icon mt-3"
                                                            style="
                                                                width: 45px;
                                                                margin-left: -1px;
                                                            "
                                                        />
                                                    </div>
                                                    <span
                                                        class="ms-3 mt-3"
                                                        style="color: darkorange"
                                                    >
                                                        {{
                                                            'common.i-am-continue-shopping'
                                                                | translate
                                                        }}
                                                        <fa-icon
                                                            [icon]="faAngleRight"
                                                            class="menu-nav-resp pointer"
                                                            size="lg"
                                                        ></fa-icon>
                                                    </span>
                                                </div>
                                            </div>
                                            <!--<div class="chckbx-container col-12 text-center corrigate-img-on-mobile">
                </div>-->
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h5 class="text-black font-weight-bolder mt-2">
                                {{ 'common.choose-from-our-shops' | translate }}
                            </h5>
                            <ng-container *ngFor="let shop of availableShopWBasketInfos">
                                <div
                                    *ngIf="
                                        onlyOneShopSelected.indexOf(shop.shopName) === -1
                                    "
                                    class="shop-box-width d-inline-block p-1"
                                >
                                    <div
                                        class="shop-box w-100 d-inline-block py-2 px-1 mx-1 my-2"
                                        (click)="selectThisShop(shop.shopName)"
                                    >
                                        <div>
                                            <div
                                                class="chckbx-container text-center corrigate-img-on-mobile"
                                            >
                                                <!--USER CAN SELECT PROVIDERS TO SHOP BEGIN-->
                                                <label class="container m-0">
                                                    <input
                                                        *ngIf="
                                                            userSelectedAvailableShops &&
                                                            onlyOneShopSelected.indexOf(
                                                                shop.shopName
                                                            ) === -1
                                                        "
                                                        type="radio"
                                                        style="float: left"
                                                        name="shop-selector"
                                                        value="{{ shop.shopName }}"
                                                        (change)="
                                                            selectThisShop(shop.shopName)
                                                        "
                                                    />
                                                    <span class="checkmark"></span>
                                                </label>

                                                <!--END-->

                                                <img
                                                    class="d-block shop-img"
                                                    src="{{ siteImageUrl }}logos/{{
                                                        shop.shopName
                                                    }}-logo-s.png"
                                                />

                                                <strong>
                                                    {{ shop.shopNameToShow }}
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="shop-box-width d-inline-block p-1">
                                <div
                                    class="shop-box w-100 d-inline-block py-2 px-1 mx-1 my-2"
                                >
                                    <div style="height: 80px" class="text-center pt-2">
                                        <i>
                                            {{
                                                'common.more-shops-are-coming' | translate
                                            }}
                                        </i>
                                    </div>
                                    <div class="text-white">.</div>
                                </div>
                            </div>

                            <!-- Fake div, do not delete -->
                            <div
                                *ngIf="availableShops?.length % 1 === 0"
                                class="d-inline-block py-2 mx-1 my-1 shop-box-width"
                            ></div>
                        </form>
                    </div>
                </ng-container>
            </ng-container>
            <p *ngIf="!isValidZip && !isFirst" class="text-danger">
                {{ 'messages.not-valid-postcode' | translate }}
            </p>
            <br />
        </div>
    </ng-container>
    <!--</mat-tab> -->

    <ng-container *ngIf="!isShopChanging">
        <h5 class="text-black font-weight-bolder mt-2">
            {{ 'common.choose-from-our-brands' | translate }}
        </h5>
        <div style="position: relative">
            <ng-container *ngIf="isValidZip">
                <ng-container
                    *ngIf="!availableShops?.length && city !== 'Elérhető boltok'"
                >
                    <p class="text-danger text-center">
                        {{ 'messages.we-dont-deliver-for-this-zip-long' | translate }}
                    </p>
                    <a
                        class="btn btn-success w-100"
                        href="{{ 'router.i-subscribe-form' | translate }}"
                        (click)="dialogRef.close()"
                        id="goToKezdooldal"
                    >
                        {{ 'common.i-subscribe' | translate }}
                    </a>
                    <!--<p class="text-center mt-3">
                        <u>
                            <a
                                class="text-dark"
                                [routerLink]="['router.dashboard' | translate]"
                                (click)="dialogRef.close()"
                            >
                                {{ 'common.explore-products' | translate }}
                            </a>
                        </u>
                    </p>-->
                </ng-container>
            </ng-container>
            <p *ngIf="!isValidZip && !isFirst" class="text-danger">
                {{ 'messages.not-valid-postcode' | translate }}
            </p>

            <br />
        </div>
    </ng-container>
    <!--</mat-tab>
   </mat-tab-group>-->
</div>
