import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MobilMenuService {
    mobilMenuOpenerSubject = new Subject<boolean>();

    changeMenuOpenClose(newStatus: boolean) {
        this.mobilMenuOpenerSubject.next(newStatus);
    }
}
