<div *ngIf="basketItem.productID" class="basket-item d-block mb-2 py-2">
    <div class="d-flex flex-row">
        <div class="product-img pe-2">
            <a [href]="navigationUrl | async" class="w-100">
                <div
                    class="w-100 product-image d-flex justify-content-center align-items-center p-1"
                    *ngIf="basketItem.mediaUrlS !== siteImageUrl + 'interface/thumbnail/'"
                >
                    <img
                        [src]="basketItem.mediaUrlS"
                        [alt]="basketItem.sEOName"
                        (error)="image.style.display = 'none'"
                        #image
                    />
                </div>
            </a>
            <div
                *ngIf="
                    basketItem.isProductRokshDiscounted &&
                    basketItem.rokshDiscountPrice > 0
                "
                class="w-100 level-container d-flex flex-column justify-content-center align-items-center"
                [ngClass]="{
                    'disable-level-container':
                        basketItem.rokshDiscountLevel > currentRokshDiscountLevelIndex
                }"
            >
                <ng-container
                    *ngIf="basketItem.rokshDiscountLevel > currentRokshDiscountLevelIndex"
                >
                    <span>
                        {{ 'common.level' | translate }}
                        {{ basketItem.rokshDiscountLevel }}
                    </span>
                </ng-container>
                <span>
                    -{{ basketItem.rokshDiscountPrice | currencyFormatted }}
                    {{ 'common.currency' | translate }}
                </span>
            </div>
        </div>

        <div class="product-text w-100">
            <div
                *ngIf="!basketItem.isAvailable"
                class="text-danger missing-and-replace-product"
            >
                <strong class="text-danger">
                    {{ 'common.this-product-is-not-available-anymore' | translate }}
                </strong>
            </div>

            <div class="d-flex flex-row justify-content-between align-items-start">
                <a [href]="navigationUrl | async" class="product-name-text pe-3">
                    {{ basketItem.productName }}
                </a>

                <div class="basket-item-delete">
                    <fa-icon
                        *ngIf="deleteInProgress"
                        [icon]="faSpinner"
                        [spin]="true"
                    ></fa-icon>
                    <fa-icon
                        *ngIf="!deleteInProgress"
                        [icon]="faTrash"
                        class="trash-icon"
                        (click)="deleteFromBasket()"
                    ></fa-icon>
                </div>
            </div>

            <div
                *ngIf="basketItem.unit && basketItem.isAvailable"
                class="text-size-10 text-secondary pl-1 mt-1 mb-1"
            >
                <span *ngIf="basketItem.unitPrice && basketItem.unitPrice > 0">
                    {{ basketItem.unitPrice | currencyFormatted }}
                    {{ 'common.currency' | translate }}/{{ basketItem.unit }}
                </span>
            </div>
            <div
                *ngIf="
                    !basketItem.unit &&
                    !basketItem.isBulk &&
                    !basketItem.displayUnit &&
                    basketItem.isAvailable
                "
                class="text-size-10 text-secondary mt-1 pl-1"
            >
                {{ basketItem.price | currencyFormatted }}
                {{ 'common.currency' | translate }}/ {{ 'common.piece' | translate }}
            </div>

            <div class="d-flex flex-row justify-content-between align-items-center pt-2">
                <lib-add-to-cart-button
                    [isArchie]="isArchie"
                    [prod]="basketItem"
                    [providerId]="providerId"
                    [providerName]="providerName"
                    [basketItem]="basketItem"
                    [isOriginPlaceBasket]="true"
                    [showUserListIcon]="false"
                    [buttonWidth]="'145px'"
                    [addressDialogComponent]="addressDialogComponent"
                    [providerDialogComponent]="shopSelectDialogSeparatedComponent"
                >
                </lib-add-to-cart-button>

                <div *ngIf="basketItem.isAvailable" class="product-card-price">
                    {{ basketItem.price | currencyFormatted }}
                    {{ 'common.currency' | translate }}
                </div>
            </div>
        </div>
    </div>

    <div
        class="provider-deposit-container"
        *ngIf="
            basketItem.isAvailable && basketItem.basketDepositProductDtoList?.length > 0
        "
    >
        <ng-container
            *ngFor="let basketDepositProductDto of basketItem.basketDepositProductDtoList"
        >
            <div
                *ngIf="
                    !basketDepositProductDto.depositUnitTypeIsOptional && isAtCountryCode
                "
            >
                {{
                    basketDepositProductDto?.article == 0
                        ? ('deposit.mehrweg' | translate)
                        : ('deposit.einweg' | translate)
                }}
                {{ 'common.deposit-plp-before-text' | translate }}
                {{ basketDepositProductDto?.price | currencyFormatted }}
                /
                {{ 'common.not-optional-deposit-plp-after-text' | translate }}
                *
                {{ basketItem.unitValue }}

                <u class="float-end"
                    >{{
                        basketDepositProductDto?.price * basketItem?.unitValue
                            | currencyFormatted
                    }}
                    {{ 'common.currency' | translate }}</u
                >
            </div>
            <div
                *ngIf="
                    basketDepositProductDto.depositUnitTypeIsOptional && isAtCountryCode
                "
            >
                {{
                    basketDepositProductDto.article == 0
                        ? ('deposit.mehrweg' | translate)
                        : ('deposit.einweg' | translate)
                }}
                {{ 'common.deposit-plp-before-text' | translate }}
                {{ basketDepositProductDto.price | currencyFormatted }}/
                {{ 'common.crate' | translate }}
                *
                {{
                    math.floor(basketItem.unitValue / basketDepositProductDto.maxQuantity)
                }}

                <u class="float-end"
                    >{{
                        basketDepositProductDto.price *
                            math.floor(
                                basketItem.unitValue / basketDepositProductDto.maxQuantity
                            ) | currencyFormatted
                    }}
                    {{ 'common.currency' | translate }}</u
                >
            </div>
            <div *ngIf="!isAtCountryCode">
                {{ basketDepositProductDto.description }}
                /{{ 'common.piece' | translate }}
                <u class="float-end"
                    >{{
                        basketDepositProductDto.price * basketItem.unitValue
                            | currencyFormatted
                    }}
                    {{ 'common.currency' | translate }}</u
                >
            </div>
        </ng-container>
    </div>
</div>
