import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AvailableTimeSlotsDTO,
    InstantDeliveryAreaProvider,
    InstantTimeslotDTO,
    InstantWorktimeQueryDTO,
    Supplier,
    TimeSlotPriceDiscountDto,
} from '../../index';

export const TIMESLOT_SERVICE_IMPL = new InjectionToken<ITimeSlotService>(
    'timeSlotServiceImpl'
);
export interface ITimeSlotService {
    getAvailableTimeSlotList(
        providerID: number,
        typedZip: string
    ): Observable<AvailableTimeSlotsDTO>;

    getRealTimeInstantSuppliersByBoundary(
        providerID: number,
        zipCode: string,
        basketItemCount: number,
        instantIntervalHours: number
    ): Observable<Supplier[]>;

    getInstantDeliveryAreaProvider(
        providerID: number,
        zipCode: string
    ): Observable<InstantDeliveryAreaProvider>;

    getRealTimeInstantTimeSlotsByBoundary(
        worktimeQuery: InstantWorktimeQueryDTO
    ): Observable<InstantTimeslotDTO[]>;

    getConfiguredExpressWorktimesByProvider(
        providerID: number,
        postCode: string
    ): Observable<InstantTimeslotDTO[]>;

    getDiscountedDeliveryFee(
        deliveryFee: number,
        timeSlotPriceDiscountDtoList: TimeSlotPriceDiscountDto[],
        currentBasketValue: number
    ): number;

    setActiveEarliestTimeSlotId(activeEarliestTimeSlotId: string): void;

    setActiveTimeSlotId(activeTimeSlotId: string): void;
}
