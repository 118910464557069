import { Component, OnInit } from '@angular/core';
import { SessionService } from 'projects/library-shared/src/public-api';
import { CreateDialogService } from '../../../services/create-dialog.service';

@Component({
    selector: 'app-add-address-input',
    templateUrl: './add-address-input.component.html',
    styleUrls: ['./add-address-input.component.css'],
})
export class AddAddressInputComponent implements OnInit {
    zipCode = '';
    address = '';

    constructor(
        private sessionService: SessionService,
        private createDialogService: CreateDialogService
    ) {}

    ngOnInit(): void {
        this.sessionService.sessionSubject.subscribe((sessionSubject) => {
            if (sessionSubject.isValidZip && sessionSubject.zipCode) {
                this.zipCode = sessionSubject.zipCode;
                this.address = `${sessionSubject.city} ${sessionSubject.address}`;
            }
        });
    }

    onClickGiveAddress() {
        this.createDialogService.openAddressDialog().afterClosed().subscribe();
    }
}
