import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    CheckoutService,
    CheckoutStep,
    DATALAYER_SERVICE_IMPL,
    DataLayerGa4Service,
    SnackBarService,
    WishListProviderShopDTO,
} from 'projects/library-shared/src/public-api';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-checkout-wishlist',
    templateUrl: './checkout-wishlist.component.html',
    styleUrls: ['./checkout-wishlist.component.scss'],
})
export class CheckoutWishListComponent implements OnInit {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    checkoutForm: FormGroup;
    wishListProviderShops: WishListProviderShopDTO[];
    selectedIndex: number;
    wishListStepIndex = CheckoutStep.WishList;
    hasBeenActivated = false;
    isSubmitted = false;

    displayWishListProviderShops = false;
    wishListProviderShopsForm: FormArray;
    isExtraProductItem = true;
    maxNumberOfProduct = 0;
    activeProviderShopIds: Array<string> = [];
    mobile: boolean;

    public get wishListStepIsExpanded(): boolean {
        return this.selectedIndex === this.wishListStepIndex;
    }

    public get wishListStepIsActive(): boolean {
        return this.selectedIndex > this.wishListStepIndex;
    }

    public get wishListStepIsDisabled(): boolean {
        return !this.hasBeenActivated;
    }

    public isActiveShop(wishListProviderShopID: string): boolean {
        return this.activeProviderShopIds?.some((x) => x === wishListProviderShopID);
    }

    constructor(
        @Inject(DATALAYER_SERVICE_IMPL) private _DataLayerGa4Service: DataLayerGa4Service,
        private checkoutService: CheckoutService,
        private formBuilder: FormBuilder,
        private translate: TranslateService,
        private snackBarService: SnackBarService
    ) {}

    ngOnInit(): void {
        this.checkoutService.formGroup$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((form) => {
                this.checkoutForm = form;
            });

        this.checkoutService.selectedStep$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((index) => {
                if (index == this.wishListStepIndex) {
                    this.hasBeenActivated = true;
                }
                this.selectedIndex = index;
            });

        this.checkoutService.wishListProviderShops$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((wishListProviderShops) => {
                if (wishListProviderShops) {
                    this.wishListProviderShops = wishListProviderShops;
                    this.maxNumberOfProduct = Math.max(
                        ...this.wishListProviderShops.map(
                            (obj) => obj.maxNumberOfProductTypes
                        )
                    );
                }
            });

        this.checkoutService.isWishListAvailable$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((isWishListAvailable) => {
                this.displayWishListProviderShops = isWishListAvailable;
            });

        this.checkoutService.isSubmitted$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((isSubmitted) => (this.isSubmitted = isSubmitted));

        this.wishListProviderShopsForm = this.checkoutForm.controls.wishListForm.get(
            'wishListProviderShops'
        ) as FormArray;

        if (window.screen.width <= 450) {
            this.mobile = true;
        }
    }

    public getProductItems(form) {
        return form.controls.wishListProductItems.controls as FormGroup[];
    }

    addProviderShop(
        url: string,
        name: string,
        validSize: number,
        wishListProviderShopID: string
    ) {
        if (
            !this.isExtraProductItem ||
            this.activeProviderShopIds?.some((x) => x === wishListProviderShopID)
        ) {
            return;
        } else {
            this.wishListProviderShopsForm.push(
                this.formBuilder.group({
                    Shop: [url, []],
                    ShopName: [name, []],
                    WishListProviderShopID: [wishListProviderShopID, []],
                    wishListProductItems: this.formBuilder.array([
                        this.initProductItem(),
                    ]),
                })
            );

            this.activeProviderShopIds.push(wishListProviderShopID);
        }
    }

    initProductItem() {
        return this.formBuilder.group({
            ProductName: [null, Validators.required],
            QuantityText: [null, Validators.required],
        });
    }

    addProductItem(providerShopIndex: number) {
        (
            this.wishListProviderShopsForm.controls[providerShopIndex].get(
                'wishListProductItems'
            ) as FormArray
        ).push(this.initProductItem());
    }

    removeProductItem(providerShopIndex: number, productItemIndex: number) {
        const productItems = <FormArray>(
            this.wishListProviderShopsForm.controls[providerShopIndex].get(
                'wishListProductItems'
            )
        );

        productItems.removeAt(productItemIndex);
    }

    removeProviderShop(shopIndex: number, wishListProviderShopID: string) {
        this.wishListProviderShopsForm.removeAt(shopIndex);
        this.activeProviderShopIds = this.activeProviderShopIds.filter(
            (x) => x !== wishListProviderShopID
        );
    }

    onChangeExtraProductItemCheckBox(event: any): void {
        this.isExtraProductItem = !event.target.checked;

        if (!this.isExtraProductItem) {
            this.wishListProviderShopsForm.clear();
            this.activeProviderShopIds = [];
        }
    }

    validateSize(arr) {
        const productItemsSize = arr.get('wishListProductItems').value.length;
        const wishListProviderShopID = arr.get('WishListProviderShopID').value;
        const maxNumberOfProductTypes = this.wishListProviderShops.find(
            (x) => x.wishListProviderShopID === wishListProviderShopID
        );

        return productItemsSize < maxNumberOfProductTypes.maxNumberOfProductTypes;
    }

    nextStep(): void {
        if (
            this.isExtraProductItem &&
            (this.wishListProviderShopsForm.invalid ||
                this.wishListProviderShopsForm.length === 0)
        ) {
            this.translate.get('checkout.wishlist-required').subscribe((text) => {
                this.snackBarService.openErrorSnackBar(text);
                return;
            });
        }

        const scrWidth = window.innerWidth;
        if (scrWidth < 992) window.scroll(0, 0);

        const summaryPanel: HTMLElement = <HTMLElement>(
            document.getElementById('summaryPanel')
        );

        summaryPanel.classList.add('in-focus');

        if (this.displayWishListProviderShops) {
            this._DataLayerGa4Service.datalayerUniversalPush('Checkout', 'ToPreferences');
            this.setStep(CheckoutStep.Preferences);
        } else {
            this._DataLayerGa4Service.datalayerUniversalPush('Checkout', 'ToPayment');
            this.setStep(CheckoutStep.Payment);
        }
    }

    setStep(index: number): void {
        this.checkoutService.setSelectedStepSource(index);
        const scrWidth = window.innerWidth;

        if (scrWidth < 992) {
            window.scroll(0, 0);
        } else {
            if (index != 0) document.getElementById('summaryPanel').scrollIntoView();
        }
    }

    ngOnDestroy() {
        this.wishListProviderShopsForm.clear();
        this.activeProviderShopIds = [];
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
