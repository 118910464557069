import { InjectionToken } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ProviderSettingsRequestDTOShared } from '../../models/DTO/providersettings-request-dto';
import { AddOrUpdateOrderItemDTOShared } from '../../models/order/addorupdateorderitemDTO';
import { IProviderSettings } from '../../models/provider/i-provider-settings';
import { PaymentProvidersDto } from '../dto/payment-providers.dto';

export const ORDER_SERVICE_IMPL = new InjectionToken<IOrderService>('orderServiceImpl');

export interface IOrderService {
    getCheckoutDto(providercode: any): Observable<any>;
    validateCoupon(
        couponCode: string,
        differentBillingAddressSelector: string,
        providerId: number
    ): Observable<any>;
    finishOrder(
        checkoutForm: FormGroup,
        providerCode: string,
        basketID: number,
        providerID: number,
        isExpressDelivery: boolean
    ): Observable<any>;
    startPayment(orderCode: string): Observable<any>;
    startPaymentWidget(orderCode: string): Observable<any>;
    getProviderSettings(
        request: ProviderSettingsRequestDTOShared
    ): Observable<IProviderSettings[]>;
    getUserMarketingPreference(userID: number, providerID: number): Observable<boolean>;
    getProduct(productID: number, providerID: number);
    AddOrUpdateOrderItem(addOrUpdateOrderItemDTO: AddOrUpdateOrderItemDTOShared);
    DeleteOrderItem(addOrUpdateOrderItemDTO: AddOrUpdateOrderItemDTOShared);
    getPaymentMethods(providerID: number, zip: string): Observable<PaymentProvidersDto>;
    validateBillingAddressPostCode(postCode: string): Observable<boolean>;
    getCustomerCreditCardAuth(
        postCode: string,
        providerID: number,
        lat: number,
        long: number,
        paymentType: string
    ): Observable<string>;
}
