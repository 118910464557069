import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../../../services/session/session.service';
import { ServiceBaseService } from '../../../services/service-base.service';
import { EmptyBasketDialogComponent } from '../../../dialogs/empty-basket-dialog/empty-basket-dialog.component';
import { SharedModalService } from '../../../services/shared-modal.service';
import { UserBasketService } from '../../../services/user-basket.service';
import { SnackBarService } from '../../../services/snackbar/snackbar.service';
import { AuthenticationService } from '../../../auth/auth.service';
import { BasketService } from '../../../services/basket.service';
import { HtmlMarketingElementsExtendsDto } from '../../../services/dto/html-marketing-elements/html-marketing-elements-extends.dto';
import { TimeSlotService } from '../../../services/timeslot/time-slot.service';
import {
    DataLayerGa4Service,
    GA4EventType,
} from '../../../services/data-layer/data-layer-ga4.service';
import { MarketingService } from '../../../services/marketing/marketing.service';
import {
    BasketDto,
    BasketItemDTO,
    ConfigureSessionDto,
    ISessionDto,
    MasterBasketDto,
} from '../../../index';

@Component({
    selector: 'lib-basket',
    templateUrl: './basket.component.html',
    styleUrls: ['./basket.component.scss'],
})
export class BasketComponent implements OnInit {
    @Input() selectedBasket: BasketDto;
    basket: BasketDto;
    @Input() showProvider = true;
    @Input() isArchie = false;
    @Output() goToCheckoutEmitter = new EventEmitter<string>();

    private rangeListSet: boolean;
    private rangeList: number[] = [];

    faTruck = faTruck;
    zipCode: string;
    masterBasket: MasterBasketDto;
    sessionDto: ISessionDto;
    currentBasket: BasketItemDTO[] = [];
    disable: boolean;
    shippingPrice: number;
    sum: number;
    additionalProductDepositFees = 0;
    isLessThanMinPrice: boolean;
    isGreaterThanMaxWeight = true;
    isDisabledShoppingCheckout = true;
    isDeliveryAvailable = false;
    minOrderPrice: number;
    maxWeight = 0;
    totalWeightSub: Subscription;
    loaded = false;
    htmlMarketingElementDto$: Observable<HtmlMarketingElementsExtendsDto>;
    siteImageUrl: string;
    additionalDepositPriceSubjectSub: Subscription;
    selectedProvider: string;

    constructor(
        private sessionService: SessionService,
        private basketService: BasketService,
        private baseService: ServiceBaseService,
        private authService: AuthenticationService,
        private timeSlotService: TimeSlotService,
        private dataLayerGa4Service: DataLayerGa4Service,
        private dialog: MatDialog,
        private marketingService: MarketingService,
        private sharedModalService: SharedModalService,
        private userBasketService: UserBasketService,
        private snackBar: SnackBarService,
        private translateService: TranslateService,
        private router: Router
    ) {
        this.siteImageUrl = this.baseService.siteImageUrl;
    }

    ngOnInit(): void {
        this.sessionService.sessionSubject
            .pipe(filter((session) => !!session))
            .subscribe((sessionDto) => {
                if (!this.selectedBasket) {
                    this.basketService.masterBasketSubject.subscribe((masterBasket) => {
                        if (!masterBasket) {
                            return;
                        }

                        this.basket = masterBasket.basketList.find(
                            (basket) =>
                                basket.providerCode === sessionDto.selectedShopCodes[0]
                        );
                    });
                } else {
                    this.basket = this.selectedBasket;
                }
                this.zipCode = sessionDto.zipCode;
                this.selectedProvider = sessionDto.selectedShops[0];
                this.sessionDto = sessionDto;

                this.isDeliveryAvailable = sessionDto?.availableShops?.some(
                    (shop) =>
                        shop.toLowerCase() === sessionDto?.selectedShops[0]?.toLowerCase()
                );
                this.basketService.subscribeToLimitWarnings(sessionDto?.zipCode);

                this.rangeListSet = false;
                this.rangeList = [];
                if (sessionDto?.selectedShopIds?.length >= 1 && sessionDto?.zipCode) {
                    this.timeSlotService
                        .getAvailableTimeSlotList(
                            sessionDto.selectedShopIds[0],
                            sessionDto.zipCode
                        )
                        .subscribe(
                            (timeSlotDate) => {
                                if (timeSlotDate?.timeSlotRangeList?.length > 0) {
                                    const timeSlotDayes =
                                        timeSlotDate?.timeSlotRangeList[0]
                                            .timeSlotDayList;
                                    const minBasketValues = timeSlotDayes.map(
                                        (t) => t.minBasketValue
                                    );
                                    this.rangeList.push(...minBasketValues);
                                    this.rangeListSet = true;
                                    this.setMinOrderPrice();
                                    this.checkRestrictions();
                                } else {
                                    this.rangeListSet = true;
                                    this.minOrderPrice = 0;
                                    this.checkRestrictions();
                                }
                            },
                            () => {
                                this.rangeListSet = true;
                                this.minOrderPrice = 0;
                                this.checkRestrictions();
                            }
                        );
                }
            });
        this.basketService.masterBasketSubject.subscribe((masterBasketDto) => {
            this.masterBasket = masterBasketDto;
            const selectedBasket = masterBasketDto?.basketList.find(
                (item) => item.providerCode === this.sessionDto?.selectedShopCodes[0]
            );
            this.currentBasket = selectedBasket?.basketItemList;
            this.shippingPrice = selectedBasket?.shippingPrice;
            this.sum = selectedBasket?.totalPrice;
            this.loaded = true;
            if (this.currentBasket === undefined) this.currentBasket = [];
            if (masterBasketDto && this.sessionDto?.selectedShopCodes) {
                this.dataLayerGa4Service.eCommerceBasket(
                    this.currentBasket,
                    GA4EventType.view_cart
                );
                this.additionalDepositPriceSubjectSub =
                    this.basketService.currentDepositPriceSubject.subscribe(
                        (depositPrice) =>
                            (this.additionalProductDepositFees = depositPrice)
                    );

                this.checkRestrictions();
            }
        });
        this.totalWeightSub = this.createTotalWeightSubscription();
    }

    clearBasket(): void {
        this.dialog.open(EmptyBasketDialogComponent, {
            width: '330px',
            position: { right: '45px' },
            panelClass: ['animate__animated', 'animate__bounceIn'],
        });
    }

    private createTotalWeightSubscription(): Subscription {
        return this.basketService.totalWeigthSubject.subscribe(() => {
            this.maxWeight = this.basketService.getMaxBasketWeight() / 1000;
            this.isGreaterThanMaxWeight =
                this.basketService.isWeightGreatherThanMaxWeight();
            this.checkRestrictions();
        });
    }

    private isLessMinPriceAndGreaterThanMaxWeight(): boolean {
        return this.isLessThanMinPrice || this.isGreaterThanMaxWeight;
    }

    private isTotalPriceLessThanMinPrice(): boolean {
        if (this.currentBasket && this.sum) {
            return this.basketService.isTotalPriceLessThanMinPrice(
                this.sum,
                this.minOrderPrice,
                this.additionalProductDepositFees
            );
        }
        return true;
    }

    private setMinOrderPrice(): void {
        this.minOrderPrice = Math.min(...this.rangeList);
    }

    private checkRestrictions(): void {
        if (this.rangeListSet) {
            this.isLessThanMinPrice = this.isTotalPriceLessThanMinPrice();
            this.isDisabledShoppingCheckout =
                this.isLessMinPriceAndGreaterThanMaxWeight();
        }
    }

    onShareLinkByEmail(): void {
        void this.sharedModalService.openShareShoppingList(0, null, null, false);
    }

    saveBasket(): void {
        if (!this.authService.isAuth()) {
            void this.sharedModalService.presentSignInModal((isLoggedIn) => {
                if (isLoggedIn) this.createShoppingList();
            });

            return;
        }

        this.createShoppingList();
    }

    private createShoppingList(): void {
        void this.sharedModalService.createNewShoppingList((data) => {
            if (data) {
                this.userBasketService
                    .createUserBasketFromSession(
                        data.basketName,
                        this.sessionDto.selectedShopCodes[0]
                    )
                    .subscribe((res) => {
                        if (res) {
                            this.translateService
                                .get('messages.basket-saved')
                                .subscribe((text) => {
                                    this.snackBar.openSuccessSnackBar(text);
                                });
                        }
                    });
            }
        });
    }

    navigateToShopCheckout(): void {
        if (this.sessionDto.selectedShopCodes[0] !== this.basket.providerCode) {
            const configureSessionDto = new ConfigureSessionDto({
                shopsSelectedForRoot: this.basket.providerCode,
                userSelectedShops: [this.basket.providerName],
            });

            this.sessionService
                .configureUserSession(configureSessionDto)
                .pipe(take(1))
                .subscribe(() => {
                    this.sessionService.isProviderSelectedForShopping.next(true);
                    this.goToCheckout();
                });
        } else {
            this.goToCheckout();
        }
    }

    goToCheckout(): void {
        if (this.isArchie) {
            this.goToCheckoutEmitter.next(this.basket.providerCode);
        } else {
            this.basketService.setGoToCheckout(true);
            this.dataLayerGa4Service.datalayerUniversalPush(
                'Checkout',
                'To payment and delivery',
                this.basket.providerName
            );
            if (this.sessionService.isMobile) {
                void this.router.navigate([`checkout/${this.basket.providerCode}`]);
            } else {
                const isUser = this.authService.getLoggedInUser();

                if (isUser) {
                    this.basketService.openMenuSubject.next(false);
                    this.translateService.get('router.cassa').subscribe((text) => {
                        void this.router.navigate([
                            text,
                            this.basket?.providerCode.toLowerCase(),
                        ]);
                    });
                } else {
                    //void this.sharedModalService.presentSignInModal();
                }
            }
        }
    }

    goToHome(): void {
        if (this.sessionService.isMobile) {
            void this.router.navigate([`/shop/${this.basket.providerCode}/kezdooldal`]);
        } else {
            this.translateService.get('router.dashboard').subscribe((startPage) => {
                void this.router.navigate([`/${this.basket.providerCode}/${startPage}`]);
            });
        }
    }
}
