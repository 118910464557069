<div class="container">
    <div class="group">
        <div class="row">
            <div
                class="col-12 col-md-6 d-none d-md-block people mr-3 personal-shopper-img-container"
            >
                <img
                    *ngIf="countryCode === CountryCodes.hu"
                    class="people-img"
                    src="{{ siteImageUrl }}images/HU-delivery.svg"
                    alt="Roksh people"
                />
                <img
                    *ngIf="countryCode === CountryCodes.at"
                    class="people-img"
                    src="assets/svg/rokshImages/images/at_people.png"
                    alt="Roksh people"
                />
            </div>
            <div class="col-12 col-md-6 personal-shopper-container">
                <div class="personal-shopper">
                    <h1 class="text-left font-weight-bold mb-3">
                        {{ 'dashboard.become-personal-shopper' | translate }}
                    </h1>
                    <p class="text-left detail mt-3">
                        {{ 'dashboard.personal-shopper-detail' | translate }}
                    </p>
                    <div class="text-left">
                        <a
                            class="btn apply-btn roksh-btn-primary gradient"
                            href="{{
                                'static-links.roksh-carrier'
                                    | translate
                                    | providerTheme : 'static-links.roksh-carrier'
                                    | async
                            }}"
                            target="_blank"
                        >
                            {{ 'common.apply' | translate }}
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-12 d-md-none people mr-3 personal-shopper-img-container pt-5">
                <img
                    *ngIf="countryCode === CountryCodes.hu"
                    class="people-img"
                    src="{{ siteImageUrl }}images/HU-delivery.svg"
                    alt="Roksh people"
                />
                <img
                    *ngIf="countryCode === CountryCodes.at"
                    class="people-img"
                    src="assets/svg/rokshImages/images/at_people.png"
                    alt="Roksh people"
                />
            </div>
        </div>
    </div>
</div>
