import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { FullPostcode } from '../../models/postcode/postcode.model';

export const SESSION_SERVICE_IMPL = new InjectionToken<ISessionService>(
    'sessionServiceImpl'
);

export interface ISessionService {
    getCityBySession(): Observable<FullPostcode>;
}
