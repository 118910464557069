<div
    [ngClass]="{
        'not-availableshop': productProvider.shopAvailable === false && zipCode
    }"
>
    <span class="shop shop-img" [ngClass]="{ cheapest: cheapestShop }">
        <img
            *ngIf="productProvider.providerID"
            class="shop-image"
            [src]="siteImageUrl + 'logos/' + productProvider.providerID + '-logo-s.png'"
        />
    </span>
    <span class="pull-right price" [ngClass]="{ cheapest: cheapestShop }">
        <span *ngIf="productProvider.isBulk; then bulk; else notBulk"></span>
    </span>
    <ng-template #notBulk>
        <span
            [ngClass]="{
                'badge badge-danger  offer-price-container d-inline':
                    productProvider.isOffer === true
            }"
        >
            {{ productProvider.price - productProvider.depositFee | currencyFormatted }}
            {{ 'common.currency' | translate }}/<ng-container
                *ngIf="
                    productProvider.displayUnit !== null &&
                        productProvider.displayUnit !== '';
                    else dbContainer
                "
            >
                {{ productProvider.displayUnit }}
                <div
                    class="d-block"
                    style="line-height: 10px"
                    *ngIf="productProvider.depositFee && !hasAnyProviderDepositProduct"
                >
                    <span class="text-size-10 text-black-50">
                        {{ 'common.deposit-type-name' | translate }}
                        <br />
                        ({{ 'common.deposit-plp-before-text' | translate }}
                        {{ productProvider.depositFee | currencyFormatted }}
                        {{ 'common.deposit-plp-after-text' | translate }})
                    </span>
                </div>
            </ng-container>
            <ng-template #dbContainer>
                <span>{{ 'common.piece' | translate }} </span>
                <div
                    class="d-block"
                    style="line-height: 10px"
                    *ngIf="productProvider.depositFee && !hasAnyProviderDepositProduct"
                >
                    <span class="text-size-10 text-black-50">
                        {{ 'common.deposit-type-name' | translate }}
                        <br />
                        ({{ 'common.deposit-plp-before-text' | translate }}
                        {{ productProvider.depositFee | currencyFormatted }}
                        {{ 'common.deposit-plp-after-text' | translate }})
                    </span>
                </div>
            </ng-template>
        </span>
        <div
            *ngIf="
                productProvider.isOffer === true &&
                productProvider.originalPriceIfOffer != null
            "
            class="old-price-container position-relative"
            style="position: relative"
        >
            &nbsp;
            <span
                *ngIf="productProvider.originalPriceIfOffer > 0"
                style="margin-right: 2px"
            >
                {{ productProvider.originalPriceIfOffer | currencyFormatted }}
                {{ 'common.currency' | translate }}
            </span>

            <br />
            <small *ngIf="productProvider.isOffer" class="offer-time">
                {{ 'common.offer-ends-at' | translate }}:
                {{ productProvider.offerValidTo | date : 'yyyy/MM/dd' }}
                {{ 'common.offer-time-hour-constant' | translate }}
            </small>
        </div>
        <div
            class="d-block mt-2"
            style="line-height: 10px"
            *ngIf="
                hasAnyProviderDepositProduct &&
                productProvider.productProviderProviderDepositProducList
            "
        >
            <ng-container
                *ngFor="
                    let pdp of productProvider.productProviderProviderDepositProducList
                "
            >
                <div
                    class="text-size-10 text-black-50 mb-1"
                    *ngIf="!pdp.depositUnitTypeIsOptional && isAtCountryCode"
                >
                    ({{
                        pdp.article == 0
                            ? ('deposit.mehrweg' | translate)
                            : ('deposit.einweg' | translate)
                    }}
                    {{ 'common.deposit-plp-before-text' | translate }}
                    {{ pdp.price | currencyFormatted }}
                    {{ 'common.not-optional-deposit-plp-after-text' | translate }})
                </div>
                <div
                    class="text-size-10 text-black-50 mb-1"
                    *ngIf="pdp.depositUnitTypeIsOptional && isAtCountryCode"
                >
                    ({{
                        pdp.article == 0
                            ? ('deposit.mehrweg' | translate)
                            : ('deposit.einweg' | translate)
                    }}
                    {{ 'common.deposit-plp-before-text' | translate }}
                    {{ pdp.price | currencyFormatted }}
                    {{ 'common.optional-deposit-plp-after-text' | translate }}
                    {{ pdp.maxQuantity }}
                    {{ 'common.optional-deposit-plp-after-qty-text' | translate }}
                    )
                </div>
                <div class="text-size-10 text-black-50 mb-1" *ngIf="!isAtCountryCode">
                    {{ pdp.description }}
                </div>
            </ng-container>
        </div>
    </ng-template>
    <ng-template #bulk>
        <span
            [ngClass]="{
                'badge badge-danger  offer-price-container d-inline':
                    productProvider.isOffer === true
            }"
        >
            <span *ngIf="!productProvider.depositFee">
                {{ +productProvider.unitPrice | currencyFormatted }}
            </span>

            <span *ngIf="productProvider.depositFee">
                {{
                    +productProvider.unitPrice - productProvider.depositFee
                        | currencyFormatted
                }}
            </span>
            {{ 'common.currency' | translate }} /<ng-container
                *ngIf="
                    productProvider.displayUnit !== null &&
                        productProvider.displayUnit !== '';
                    else bulkUnit
                "
            >
                {{ productProvider.displayUnit }}
            </ng-container>
            <ng-template #bulkUnit>
                <span> {{ productProvider.unit }}</span>
            </ng-template>
        </span>
        <div
            *ngIf="
                productProvider.isOffer === true &&
                productProvider.originalUnitPriceIfOffer > 0
            "
            class="old-price-container position-relative"
            style="position: relative"
        >
            &nbsp;
            <span style="margin-right: 2px">
                {{ productProvider.originalUnitPriceIfOffer | currencyFormatted }}
                {{ 'common.currency' | translate }}
            </span>

            <br />
            <small *ngIf="productProvider.isOffer" class="offer-time">
                {{ 'common.offer-ends-at' | translate }}:
                {{ productProvider.offerValidTo | date : 'yyyy/MM/dd' }}
                {{ 'common.offer-time-hour-constant' | translate }}
            </small>
            <div class="d-block">
                <span
                    class="text-size-10 ms-2"
                    style="line-height: 10px"
                    *ngIf="productProvider.depositFee && !hasAnyProviderDepositProduct"
                >
                    {{ 'common.deposit-type-name' | translate }}
                    <br />
                    ({{ 'common.deposit-plp-before-text' | translate }}
                    {{ productProvider.depositFee | currencyFormatted }}
                    {{ 'common.deposit-plp-after-text' | translate }})
                </span>
            </div>

            <div
                class="d-block"
                style="line-height: 10px"
                *ngIf="
                    hasAnyProviderDepositProduct &&
                    productProvider.productProviderProviderDepositProducList
                "
            >
                <ng-container
                    *ngFor="
                        let pdp of productProvider.productProviderProviderDepositProducList
                    "
                >
                    <div
                        class="text-size-10 text-black-50 mb-1"
                        *ngIf="!pdp.depositUnitTypeIsOptional && isAtCountryCode"
                    >
                        ({{
                            pdp.article == 0
                                ? ('deposit.mehrweg' | translate)
                                : ('deposit.einweg' | translate)
                        }}
                        {{ 'common.deposit-plp-before-text' | translate }}
                        {{ pdp.price | currencyFormatted }}
                        {{ 'common.not-optional-deposit-plp-after-text' | translate }})
                    </div>
                    <div
                        class="text-size-10 text-black-50 mb-1"
                        *ngIf="pdp.depositUnitTypeIsOptional && isAtCountryCode"
                    >
                        ({{
                            pdp.article == 0
                                ? ('deposit.mehrweg' | translate)
                                : ('deposit.einweg' | translate)
                        }}
                        {{ 'common.deposit-plp-before-text' | translate }}
                        {{ pdp.price | currencyFormatted }}
                        {{ 'common.optional-deposit-plp-after-text' | translate }}
                        {{ pdp.maxQuantity }}
                        {{ 'common.optional-deposit-plp-after-qty-text' | translate }}
                        )
                    </div>
                    <div class="text-size-10 text-black-50 mb-1" *ngIf="!isAtCountryCode">
                        {{ pdp.description }}
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-template>
</div>
