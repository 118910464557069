/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    DateService,
    EarliestTimeSlot,
    SnackBarService,
    TimeSlotPriceDiscountDto,
    TimeSlotService,
} from 'projects/library-shared/src/public-api';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-earliest-timeslot',
    templateUrl: './earliest-timeslot.component.html',
    styleUrls: ['./earliest-timeslot.component.css'],
})
export class EarliestTimeSlotComponent implements OnInit {
    @Input() earliestTimeSlot: EarliestTimeSlot;
    @Input() currentBasketValue: number;
    @Input() currentBasketWeight: number;
    @Output() onSelectEarliestTimeSlot: EventEmitter<any> = new EventEmitter();

    dayText: string;
    dateText: string;
    deliveryFee: number;
    earliestTimeSlotId = '';
    activeEarliestTimeSlotId = '';
    disableEarliestTimeSlot: boolean | false;
    isOverWeightLimit: boolean | false;
    isUnderPriceLimit: boolean | false;

    constructor(
        private dateService: DateService,
        private timeSlotService: TimeSlotService,
        private translate: TranslateService,
        private snackBar: SnackBarService
    ) {}

    ngOnInit(): void {
        if (this.earliestTimeSlot.isInstant) {
            this.getDeliverFee(
                this.earliestTimeSlot.instantTimeSlot.deliveryFee,
                this.earliestTimeSlot.instantTimeSlot.timeSlotPriceDiscountDtoList
            );

            this.earliestTimeSlotId =
                this.earliestTimeSlot.instantTimeSlot.interval.toString();
        } else {
            this.isUnderPriceLimit =
                this.earliestTimeSlot.timeSlot.minBasketValueRestriction &&
                this.currentBasketValue <= this.earliestTimeSlot.timeSlot.minBasketValue;

            this.isOverWeightLimit =
                this.earliestTimeSlot.timeSlot.maxBasketWeightRestriction &&
                this.currentBasketWeight >=
                    this.earliestTimeSlot.timeSlot.maxBasketWeight;

            this.disableEarliestTimeSlot =
                this.isOverWeightLimit || this.isUnderPriceLimit;

            this.dayText = this.dateService.getDayString(
                this.earliestTimeSlot.timeSlot.date
            );

            this.dateText = this.dateService.getDateString(
                this.earliestTimeSlot.timeSlot.date
            );

            this.getDeliverFee(
                this.earliestTimeSlot.timeSlot.deliveryFee -
                    this.earliestTimeSlot.timeSlot.additionalFee,
                this.earliestTimeSlot.timeSlot.timeSlotPriceDiscountDtoList
            );

            this.earliestTimeSlotId =
                this.earliestTimeSlot.timeSlot.dateKey +
                this.earliestTimeSlot.timeSlot.hoursRange +
                this.earliestTimeSlot.timeSlot.startHour +
                this.earliestTimeSlot.timeSlot.endHour;
        }

        this.timeSlotService.activeEarliestTimeSlotId.subscribe(
            (activeEarliestTimeSlotId) => {
                this.activeEarliestTimeSlotId = activeEarliestTimeSlotId;
            }
        );
    }

    getDeliverFee(
        deliveryFee: number,
        timeSlotPriceDiscountDtoList: TimeSlotPriceDiscountDto[]
    ): void {
        this.deliveryFee = this.timeSlotService.getDeliverFee(
            deliveryFee,
            timeSlotPriceDiscountDtoList,
            this.currentBasketValue
        );
    }

    public selectEarliestTimeSlot(
        selectEarliestTimeSlot: any, //EarliestTimeSlot in reality, but the template trows error,
        earliestTimeSlotId: string,
        disable: boolean
    ): void {
        if (!disable) {
            this.timeSlotService.activeEarliestTimeSlotId.next(earliestTimeSlotId);
            this.onSelectEarliestTimeSlot.emit(selectEarliestTimeSlot);
        } else {
            this.translate
                .get('time-slot.no-select-info')
                .subscribe((notForSelectionInfo) => {
                    this.snackBar.openSnackBar(notForSelectionInfo, 'bottom');
                });
        }
    }
}
