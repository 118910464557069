import { CouponStatus } from '../../types/coupon-status';

export class Payment {
    instantDeliveryFee: number;
    deliveryFee = 0;
    totalDeliveryFee = 0;
    deliveryDiscountAmount = 0;
    priceDiscountAmount = 0;
    discountPercent: number;
    additionalDeliveryFee: number;
    couponStatus: CouponStatus = CouponStatus.NOCOUPON;
}
