<div
    *ngIf="!isAuthenticated"
    class="text-grey font-weight-500"
    [ngClass]="{'login-white': isWhite}"
>
    <span
        (click)="onOpenDialog()"
        [ngClass]="{'login-white': isWhite}"
        class="d-flex flex-nowrap pointer login-trigger-panel font-weight-600 provider-login justify-content-center align-items-center"
    >
        <img
            src="assets/svg/rokshImages/icons/login.svg"
            [ngClass]="{'login-white no-show': isWhite}"
            alt="Bejelentkezés ikon"
            class="user-svg provider-header-icon"
        />
    </span>
</div>
<button
    *ngIf="isAuthenticated"
    class="d-flex flex-nowrap card-menu font-weight-600 color-turquoise provider-login justify-content-center align-items-center"
    [ngClass]="{'login-white': isWhite}"
    mat-button
    [matMenuTriggerFor]="menu"
>
    <img
        src="assets/svg/Bejelentkezett_user.svg"
        alt="Bejelentkezett felhasználó"
        class="user-svg"
    />
</button>
<mat-menu #menu="matMenu" xPosition="before">
    <div *ngIf="user">
        <h2 class="fw-bold ps-3 pt-2 pe-5">{{ user.FirstName }} {{ user.LastName }}</h2>
        <p class="text-grey ps-3 pb-3">{{ user.Email }}</p>
    </div>
    <button
        *ngIf="selectedProviderName"
        [routerLink]="[
                                'router.user' | translate,
                                'router.my-orders' | translate
                            ]"
        [queryParams]="{providerName: selectedProviderName}"
        mat-menu-item
        class="menu-item"
    >
        <img
            src="{{ siteImageUrl }}images/user-menu/basket.svg"
            alt="orders"
            class="pe-3"
        />
        {{"common.my-orders" | translate}}
    </button>
    <button
        *ngIf="!selectedProviderName"
        [routerLink]="[
                                'router.user' | translate,
                                'router.my-orders' | translate
                            ]"
        mat-menu-item
        class="menu-item"
    >
        <img
            src="{{ siteImageUrl }}images/user-menu/basket.svg"
            alt="orders"
            class="pe-3"
        />
        {{"common.my-orders" | translate}}
    </button>
    <!------->
    <button
        *ngIf="selectedProviderName"
        [routerLink]="[
                                'router.user' | translate,
                                'router.shopping-list' | translate
                            ]"
        [queryParams]="{providerName: selectedProviderName}"
        mat-menu-item
        class="menu-item"
    >
        <img
            src="{{ siteImageUrl }}images/user-menu/list.svg"
            alt="orders"
            class="pe-3"
        />
        {{"common.my-shopping-lists" | translate}}
    </button>
    <button
        *ngIf="!selectedProviderName"
        [routerLink]="[
                                'router.user' | translate,
                                'router.shopping-list' | translate
                            ]"
        mat-menu-item
        class="menu-item"
    >
        <img
            src="{{ siteImageUrl }}images/user-menu/list.svg"
            alt="orders"
            class="pe-3"
        />
        {{"common.my-shopping-lists" | translate}}
    </button>
    <!------->
    <button
        *ngIf="selectedProviderName"
        [routerLink]="[
                                'router.user' | translate,
                                'router.favourites' | translate
                            ]"
        [queryParams]="{providerName: selectedProviderName}"
        mat-menu-item
        class="menu-item"
    >
        <img
            src="{{ siteImageUrl }}images/user-menu/heart.svg"
            alt="orders"
            class="pe-3"
        />
        {{"common.my-favourite-products" | translate}}
    </button>
    <button
        *ngIf="!selectedProviderName"
        [routerLink]="[
                                'router.user' | translate,
                                'router.favourites' | translate
                            ]"
        mat-menu-item
        class="menu-item"
    >
        <img
            src="{{ siteImageUrl }}images/user-menu/heart.svg"
            alt="orders"
            class="pe-3"
        />
        {{"common.my-favourite-products" | translate}}
    </button>
    <!------->
    <button
        *ngIf="selectedProviderName"
        [routerLink]="[
                                'router.user' | translate,
                                'router.settings' | translate
                            ]"
        [queryParams]="{providerName: selectedProviderName}"
        mat-menu-item
        class="menu-item"
    >
        <img
            src="{{ siteImageUrl }}images/user-menu/profile.svg"
            alt="orders"
            class="pe-3"
        />
        {{"common.settings" | translate}}
    </button>
    <button
        *ngIf="!selectedProviderName"
        [routerLink]="[
                                'router.user' | translate,
                                'router.settings' | translate
                            ]"
        mat-menu-item
        class="menu-item"
    >
        <img
            src="{{ siteImageUrl }}images/user-menu/profile.svg"
            alt="orders"
            class="pe-3"
        />
        {{"common.settings" | translate}}
    </button>
    <!------->
    <button mat-menu-item (click)="onLogOut()" class="menu-item">
        <img
            src="{{ siteImageUrl }}images/user-menu/exit.svg"
            alt="orders"
            class="pe-3"
        />
        {{"common.logout" | translate}}
    </button>
</mat-menu>

<img
    src="assets/svg/Bejelentkezett_user.svg"
    [ngClass]="{'login-white no-show': isWhite}"
    class="d-none"
/>
<img
    src="assets/svg/Bejelentkezes.svg"
    [ngClass]="{'login-white no-show': isWhite}"
    class="d-none"
/>
