import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
    BasketService,
    ConfigureSessionDto,
    FavoriteDto,
    FavoriteListService,
    ProviderDTO,
    ServiceBaseService,
    SessionService,
    SnackBarService,
} from 'projects/library-shared/src/public-api';
import {
    faAngleRight,
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-favorite-list',
    templateUrl: './favorite-list.component.html',
    styleUrls: ['./favorite-list.component.scss'],
})
export class FavoriteListComponent implements OnInit {
    faAngleRight = faAngleRight;
    favoritesByProvider: FavoriteDto[];
    latestProvider: ProviderDTO;
    isRokshDiscountAvailable: boolean;
    siteImageUrl: string;
    isLoading = true;
    faChevronLeft = faChevronLeft;
    faChevronRight = faChevronRight;
    @ViewChild('carousel') carousel: ElementRef;

    constructor(
        private basketService: BasketService,
        private sessionService: SessionService,
        private router: Router,
        private favoriteListService: FavoriteListService,
        private title: Title,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private snackBar: SnackBarService,
        baseService: ServiceBaseService
    ) {
        this.siteImageUrl = baseService.getSiteImageUrl();
    }

    ngOnInit() {
        this.basketService.isRokshDiscountAvailableSubject.subscribe(
            (isRokshDiscountAvailable) => {
                this.isRokshDiscountAvailable = isRokshDiscountAvailable;
            }
        );
        this.translate.get('common.favorite-list-title').subscribe((title) => {
            this.title.setTitle(title);
        });
    }

    loadFavoriteProducts(): void {
        this.isLoading = true;
        this.favoriteListService
            .getFavoriteProductList(this.latestProvider?.id)
            .subscribe((favoriteList) => {
                this.isLoading = false;
                this.favoritesByProvider = favoriteList;
            });
    }

    setLatestProvider(selectedProvider: ProviderDTO) {
        this.latestProvider = selectedProvider;
        this.loadFavoriteProducts();
    }

    removeFavoriteProduct(event: any, providerName: string) {
        this.favoritesByProvider.map((x) => {
            if (x.providerName === providerName) {
                x.favoriteItemDtos = x.favoriteItemDtos.filter(
                    (data) => data.originalProduct.productID !== +event
                );
            }
        });
    }

    goToProvider(item: FavoriteDto) {
        this.sessionService.sessionSubject.pipe(take(1)).subscribe((session) => {
            if (!session.availableShops.includes(item.providerName)) {
                this.translate
                    .get('messages.shop-not-available-postcode')
                    .subscribe((notAvailable) => {
                        this.snackBar.openErrorSnackBar(notAvailable);
                    });

                return;
            }

            const queryParams: Params = {
                providerName: item.providerName,
            };

            void this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams,
                queryParamsHandling: 'merge',
            });

            const configureSessionDto = new ConfigureSessionDto({
                userSelectedShops: [item.providerName],
                redirectToDashboardNeeded: false,
            });

            this.sessionService
                .configureUserSession(configureSessionDto)
                .pipe(take(1))
                .subscribe(() => {
                    this.sessionService.isProviderSelectedForShopping.next(true);
                });
        });
    }

    navigateCard(value: number, element: Element): void {
        const leftPosition = element.scrollLeft + value;
        element.scroll({ left: leftPosition, behavior: 'smooth' });
    }
}
