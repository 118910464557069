import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';

@NgModule({
    declarations: [],
    imports: [CommonModule, MatIconModule],
})
export class AppIconModule {
    private iconsPath = '/assets/svg/rokshImages/icons';
    private imagesPath = '/assets/svg/rokshImages/images';

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
        this.matIconRegistry
            .addSvgIcon('close', this.setPath(`${this.iconsPath}/close.svg`))
            .addSvgIcon('home', this.setPath(`${this.iconsPath}/house.svg`))
            .addSvgIcon('location', this.setPath(`${this.iconsPath}/location.svg`))
            .addSvgIcon('plus', this.setPath(`${this.iconsPath}/plus.svg`))
            .addSvgIcon('minus', this.setPath(`${this.iconsPath}/minus.svg`))
            .addSvgIcon('leadas-cta', this.setPath(`${this.iconsPath}/leadas cta.svg`));
    }
    private setPath(url: string): SafeResourceUrl {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
