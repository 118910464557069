import { InjectionToken } from '@angular/core';

export const SNACKBAR_SERVICE_IMPL = new InjectionToken<ISnackBarService>(
    'snackBarServiceImpl'
);

export interface ISnackBarService {
    openSnackBar(message: string): void;
    openErrorSnackBar(message: string): void;
}
