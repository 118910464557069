<div class="bg-white" [ngClass]="{ container: !isReduced }">
    <div class="row">
        <div class="col-12"></div>
        <div class="col-12">
            <!-------------stays-------------->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>
                            {{ 'faq.what-shopper-means' | translate }}
                        </h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="w-100">
                    <div class="row ml-2 w-100">
                        <p>
                            {{ 'faq.what-shopper-means-answer' | translate }}
                        </p>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>
                            {{ 'faq.what-if-no-product' | translate }}
                        </h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="w-100">
                    <div class="row ml-2 w-100">
                        <p>
                            {{ 'faq.what-if-no-product-answer' | translate }}
                        </p>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>
                            {{ 'faq.prices-are-real' | translate }}
                        </h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="w-100">
                    <div class="row ml-2 w-100">
                        <p>
                            {{ 'faq.prices-are-real-answer' | translate }}
                        </p>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>
                            {{ 'faq.how-delivery-works' | translate }}
                        </h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="w-100">
                    <div class="row ml-2 w-100">
                        <p>
                            {{ 'faq.how-delivery-works-answer' | translate }}
                        </p>
                    </div>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h5>
                            {{ 'faq.how-can-i-pay' | translate }}
                        </h5>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="w-100">
                    <div class="row ml-2 w-100">
                        <p>
                            {{ 'faq.how-can-i-pay-answer' | translate }}
                        </p>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
    </div>
</div>
