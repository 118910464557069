import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, ReplaySubject } from 'rxjs';
import {
    AvailableTimeSlotsDTO,
    InstantTimeslotDTO,
    ModifyOrderDeliveryDTOShared,
    OrderService,
    ServiceBaseService,
    TimeSlotDayDTO,
    TimeSlotRangeItemDTO,
    TimeSlotService,
} from 'projects/library-shared/src/public-api';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-order-timeslots-dialog',
    templateUrl: './order-timeslots-dialog.component.html',
    styleUrls: ['./order-timeslots-dialog.component.scss'],
})
export class OrderTimeslotsDialogComponent implements OnInit, OnDestroy {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    selectionAllowed = false;
    areaHasInstantProvider = false;
    faTimesCircle = faTimesCircle;
    deliveryTimeQueriesDone = false;
    configuredInstantWorktimes: InstantTimeslotDTO[];
    postCode: string;
    totalPrice: string;
    totalWeight: string;
    availableTimeSlotsDto: AvailableTimeSlotsDTO;
    selectedTimeSlotDTO: TimeSlotDayDTO;
    hoursRange: number;
    dialogTitle = '';
    selectedTimeSlotRangeItemDTO: TimeSlotRangeItemDTO;
    isShowSaveButton = false;

    constructor(
        private timeSlotService: TimeSlotService,
        public dialogRef: MatDialogRef<OrderTimeslotsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public baseService: ServiceBaseService,
        public translate: TranslateService,
        private orderService: OrderService
    ) {
        this.translate
            .get('time-slot.available-time-slots')
            .subscribe((timeSlots: string) => {
                this.dialogTitle = timeSlots;
            });
    }

    ngOnInit() {
        this.timeSlotService
            .getConfiguredExpressWorktimesByProvider(
                this.data.providerID,
                this.data.order.DeliveryAddressPostalCode
            )
            .pipe(
                takeUntil(this.destroyed$),
                tap((worktimes) => (this.configuredInstantWorktimes = worktimes)),
                switchMap(() => {
                    return forkJoin({
                        availableTimeSlotsData:
                            this.timeSlotService.getAvailableTimeSlotList(
                                this.data.providerID,
                                this.data.order.DeliveryAddressPostalCode,
                                this.data.order.OrderCode
                            ),
                    });
                })
            )
            .subscribe((responses) => {
                this.deliveryTimeQueriesDone = true;
                if (responses.availableTimeSlotsData) {
                    this.availableTimeSlotsDto = responses.availableTimeSlotsData;
                }
            });

        this.postCode = this.data.order.DeliveryAddressPostalCode;
        this.totalPrice = this.data.order.CurrentTotalPrice;
        this.totalWeight = this.data.order.TotalWeight;
    }

    public setDeliveryTimeSlot($event: any): void {
        this.selectedTimeSlotDTO = $event.timeSlotDay;
        this.hoursRange = $event.timeSlotRange.hoursRange;
        this.selectedTimeSlotRangeItemDTO = $event.timeSlotRangeItem;
        this.isShowSaveButton = true;
    }

    public showAvailableTimeSlots($event: any): void {
        this.selectedTimeSlotDTO = $event.selectedTimeSlotDayDto;
    }

    onSave() {
        const dto: ModifyOrderDeliveryDTOShared = {
            OrderCode: this.data.order.OrderCode,
            DeliveryTimeSlot: this.selectedTimeSlotDTO.date,
            TimeSlotEnd: this.selectedTimeSlotRangeItemDTO.endHour,
            TimeSlotStart: this.selectedTimeSlotRangeItemDTO.startHour,
            HoursRange: this.hoursRange,
            PostCode: this.postCode,
        };

        this.orderService.modifyOrderDeliveryTimeSlot(dto).subscribe(
            (data) => {
                this.dialogRef.close(data);
            },
            () => {
                this.dialogRef.close();
            }
        );
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
