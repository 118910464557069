import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';

import {
    AvailableTimeSlotsDTO,
    BasketService,
    DateService,
    EarliestTimeSlot,
    FormatterService,
    InstantTimeslotDTO,
    SessionService,
    SnackBarService,
    TimeSlotDayDTO,
    TimeSlotPriceDiscountDto,
    TimeSlotRangeDTO,
    TimeSlotRangeItemDTO,
    TimeSlotService,
} from 'projects/library-shared/src/public-api';
import { TranslateService } from '@ngx-translate/core';
import {
    faChevronLeft,
    faChevronRight,
    faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { MatExpansionPanel } from '@angular/material/expansion';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
    selector: 'app-provider-timeslots',
    templateUrl: './provider-timeslots.component.html',
    styleUrls: ['./provider-timeslots.component.scss'],
})
export class ProviderTimeslotsComponent implements OnInit {
    @ViewChildren(MatExpansionPanel) expansionPanel: QueryList<MatExpansionPanel>;

    @Input() customCurrentBasketValue: number;
    @Input() customCurrentBasketWeight: number;
    @Input() instantTimeSlots: InstantTimeslotDTO[];
    @Input() configuredInstantTimeSlots: InstantTimeslotDTO[];
    @Input() areaHasInstantProvider = false;
    @Input() instantDeliveryAvailable = false;
    @Input() selectionAllowed: boolean;
    @Input() isModifyDeliveryTime = false;
    @Input() postCode: string;
    @Input() availableTimeSlotDto: AvailableTimeSlotsDTO;

    timeSlotRangeList: TimeSlotRangeDTO[];
    isAvailableTimeSlots = true;

    @Output() onSelectInstantDelivery: EventEmitter<any> = new EventEmitter();
    @Output() onTimeSlotDaySelection: EventEmitter<any> = new EventEmitter();
    @Output() onSetDeliveryTimeSlot: EventEmitter<any> = new EventEmitter();
    @Output() onShowSelectionIsNotAllowed: EventEmitter<any> = new EventEmitter();

    notAvailableInfo = '';
    faChevronRight = faChevronRight;
    faChevronLeft = faChevronLeft;

    customOptions: OwlOptions = {
        autoWidth: true,
        dots: false,
    };

    get doDisplayTimeSlots(): boolean {
        return (
            this.postCode &&
            this.availableTimeSlotDto &&
            this.availableTimeSlotDto?.timeSlotRangeList?.length > 0
        );
    }
    get doDisplayTimeSlotRanges(): boolean {
        return (
            this.selectedTimeSlotDayDTO &&
            this.selectedTimeSlotDayDTO.timeSlotRangeItemList &&
            this.selectedTimeSlotDayDTO.timeSlotRangeItemList?.length > 0
        );
    }

    get noDeliveryTimeAvailable(): boolean {
        return (
            !this.instantDeliveryAvailable &&
            this.availableTimeSlotDto?.timeSlotRangeList?.length == 0
        );
    }

    selectedTimeSlotDayDTO: TimeSlotDayDTO;
    selectedTimeSlotRangeItemDTO: TimeSlotRangeItemDTO;
    instantDeliverySelected = false;
    selectedInstantWorktimeInterval: number;

    currentBasketValue: number;
    currentBasketWeight: number;
    selectedSlotDiscount = 0;
    faInfoCircle = faInfoCircle;
    earliestTimeSlots: EarliestTimeSlot[] = [];
    noSlot = false;

    constructor(
        private dateService: DateService,
        private sessionService: SessionService,
        private translate: TranslateService,
        private basketService: BasketService,
        private timeSlotService: TimeSlotService,
        public formatterService: FormatterService,
        private snackBar: SnackBarService
    ) {
        this.translate
            .get('time-slot.no-slots-for-slot')
            .subscribe((text) => (this.notAvailableInfo = text));
    }

    ngOnInit(): void {
        this.prepareTimeSlotList();

        if (this.customCurrentBasketValue) {
            this.currentBasketValue = this.customCurrentBasketValue;
        } else if (!this.isModifyDeliveryTime) {
            this.sessionService.sessionSubject.subscribe((userSelectedShops) => {
                if (userSelectedShops.selectedShops.length === 1) {
                    this.basketService.masterBasketSubject.subscribe((mb) => {
                        const currentBasket = mb.basketList.find(
                            (b) =>
                                b.providerName.toLocaleLowerCase() ===
                                userSelectedShops.selectedShops[0].toLocaleLowerCase()
                        );

                        this.currentBasketValue =
                            currentBasket.totalPrice + currentBasket.totalDepositPrice;
                    });
                }
            });
        }

        this.currentBasketWeight = this.timeSlotService.getCurrentBasketWeight(
            this.customCurrentBasketWeight,
            this.isModifyDeliveryTime
        );
        this.selectEarliestTimeSlots();

        this.noSlot =
            this.availableTimeSlotDto.timeSlotRangeList.findIndex(
                (slot) => slot.isAvailableForRange
            ) === -1;
    }

    public selectEarliestTimeSlots(): void {
        if (this.configuredInstantTimeSlots) {
            this.configuredInstantTimeSlots.forEach((instantTimeSlot) => {
                const correspondingTimeSlot = this.getCorrespondTimeSlot(instantTimeSlot);
                if (correspondingTimeSlot) {
                    const overweight =
                        this.currentBasketWeight >=
                            correspondingTimeSlot.maxBasketWeight &&
                        correspondingTimeSlot.maxBasketWeightRestriction;
                    const tooLowerPrice =
                        this.currentBasketValue <=
                            correspondingTimeSlot?.minBasketValue &&
                        true &&
                        correspondingTimeSlot.minBasketValueRestriction;

                    if (!(overweight || tooLowerPrice || !this.areaHasInstantProvider)) {
                        const timeline = new EarliestTimeSlot();
                        timeline.instantTimeSlot = instantTimeSlot;
                        timeline.isInstant = true;
                        this.earliestTimeSlots.push(timeline);
                    }
                }
            });
        }
        if (this.availableTimeSlotDto?.earliestTimeSlotRangeItemList) {
            this.availableTimeSlotDto?.earliestTimeSlotRangeItemList.forEach(
                (timeSlot) => {
                    const timeline = new EarliestTimeSlot();
                    timeline.timeSlot = timeSlot;
                    timeline.isInstant = false;
                    this.earliestTimeSlots.push(timeline);
                }
            );
        }

        if (this.earliestTimeSlots?.length === 0) {
            this.isAvailableTimeSlots = false;
        }
    }

    private closeAllExpansionPanel(): void {
        this.expansionPanel?.toArray().forEach((panel) => {
            if (panel.expanded) {
                panel.close();
            }
        });
    }

    selectEarliestTimeSlotDelivery(earliestTimeSlot: EarliestTimeSlot): void {
        this.setResetState();
        this.closeAllExpansionPanel();

        if (earliestTimeSlot.isInstant) {
            this.selectInstantDelivery(earliestTimeSlot.instantTimeSlot);
        } else {
            const selectedTimeSlotRange =
                this.availableTimeSlotDto?.timeSlotRangeList.find(
                    (range) => range.hoursRange == earliestTimeSlot.timeSlot.hoursRange
                );

            const selectedTimeSlotDayDto = selectedTimeSlotRange?.timeSlotDayList.find(
                (day) => day.dateKey === earliestTimeSlot.timeSlot.dateKey
            );

            this.onShowSelectionIsNotAllowed.emit(false);
            this.selectedTimeSlotDayDTO = selectedTimeSlotDayDto;

            if (selectedTimeSlotDayDto?.validForToday) {
                this.onTimeSlotDaySelection.emit({ selectedTimeSlotDayDto });
                this.selectedInstantWorktimeInterval = null;
            }

            const selectedTimeSlotRangeItem =
                selectedTimeSlotDayDto.timeSlotRangeItemList.find(
                    (rangeItem) =>
                        rangeItem.startHour == earliestTimeSlot.timeSlot.startHour &&
                        rangeItem.endHour == earliestTimeSlot.timeSlot.endHour
                );

            const discount = this.getDiscountPercentage(
                selectedTimeSlotDayDto.deliveryFee - selectedTimeSlotDayDto.additionalFee,
                selectedTimeSlotDayDto.timeSlotPriceDiscountDtoList
            );

            this.selectDeliveryTimeSlot(
                selectedTimeSlotRange,
                selectedTimeSlotRangeItem,
                discount
            );
        }
    }

    selectInstantDelivery(instantTimeSlot: InstantTimeslotDTO): void {
        if (this.selectionAllowed && this.instantDeliveryAvailable) {
            this.instantDeliverySelected = true;
            this.selectedTimeSlotRangeItemDTO = null;
            const correspondingTimeSlot = this.getCorrespondTimeSlot(instantTimeSlot);
            const discount = this.getDiscountPercentage(
                correspondingTimeSlot.deliveryFee,
                correspondingTimeSlot.timeSlotPriceDiscountDtoList
            );
            this.onSelectInstantDelivery.emit({
                instantTimeSlot: instantTimeSlot,
                discount: discount,
            });
            this.selectedInstantWorktimeInterval = instantTimeSlot.interval;
            this.selectedTimeSlotDayDTO = null;
        }
        if (!this.selectionAllowed) {
            this.onShowSelectionIsNotAllowed.emit(true);
        }
    }

    setResetState(): void {
        const elements = document.getElementsByClassName('delivery-days');
        const earliestElements = document.getElementsByClassName('card-container');
        this.timeSlotService.removeActiveClass(elements);
        this.timeSlotService.removeActiveClass(earliestElements);
        this.selectedTimeSlotDayDTO = null;
        this.selectedTimeSlotRangeItemDTO = null;
        this.instantDeliverySelected = false;
    }

    showAvailableTimeSlots($event: any, dateKey: string, timeSlotID: number): void {
        this.instantDeliverySelected = false;
        this.selectedTimeSlotRangeItemDTO = null;
        const elements = document.getElementsByClassName('delivery-days');
        for (let i = 0; i < elements.length; i++) elements[i].classList.remove('active');
        $event.target.classList.add('active');

        const timeSlotRangeIndex = this.availableTimeSlotDto?.timeSlotRangeList.findIndex(
            (range) => range.timeSlotID == timeSlotID
        );

        const selectedTimeSlotDayDto = this.availableTimeSlotDto?.timeSlotRangeList[
            timeSlotRangeIndex
        ]?.timeSlotDayList.find((day) => day.dateKey === dateKey);

        this.onShowSelectionIsNotAllowed.emit(false);
        this.selectedTimeSlotDayDTO = selectedTimeSlotDayDto;

        if (selectedTimeSlotDayDto?.validForToday) {
            this.onTimeSlotDaySelection.emit({ $event, selectedTimeSlotDayDto });
            this.selectedInstantWorktimeInterval = null;
        }
    }

    selectDeliveryTimeSlot(
        timeSlotRange: TimeSlotRangeDTO,
        timeSlotRangeItem: TimeSlotRangeItemDTO,
        discount: number
    ): void {
        this.selectedSlotDiscount = discount;
        this.instantDeliverySelected = false;
        this.selectedInstantWorktimeInterval = 0;
        if (this.selectionAllowed && timeSlotRangeItem.isAvailable) {
            this.selectedTimeSlotRangeItemDTO = timeSlotRangeItem;
            this.onSetDeliveryTimeSlot.emit({
                timeSlotRange,
                timeSlotRangeItem,
                timeSlotDay: this.selectedTimeSlotDayDTO,
                discountPercentage: this.selectedSlotDiscount,
            });
        } else {
            this.onShowSelectionIsNotAllowed.emit(true);
        }
    }

    prepareTimeSlotList() {
        this.translate.get('time-slot.full-slot').subscribe((text) => {
            this.availableTimeSlotDto?.timeSlotRangeList.forEach(
                (timeSlotRange, index) => {
                    timeSlotRange.timeSlotDayList.forEach((timeSlotDay, indexDay) => {
                        const dayText = this.dateService.getDayString(timeSlotDay.date);

                        const dateText = this.dateService.getDateString(timeSlotDay.date);
                        if (!timeSlotDay.isAvailableForToday) {
                            this.availableTimeSlotDto.timeSlotRangeList[
                                index
                            ].timeSlotDayList[indexDay].displayText =
                                '<strong>' +
                                text +
                                '</strong><span>' +
                                dayText +
                                '</span><span>' +
                                dateText +
                                '</span>';
                        } else {
                            this.availableTimeSlotDto.timeSlotRangeList[
                                index
                            ].timeSlotDayList[indexDay].displayText =
                                '<strong>' + dayText + '</strong>' + dateText;
                        }
                    });
                }
            );
        });
    }

    getCorrespondTimeSlot(timeSlot: InstantTimeslotDTO): InstantTimeslotDTO {
        return this.instantTimeSlots?.find((i) => i.interval === timeSlot.interval);
    }

    getDiscountPercentage(
        deliveryFee: number,
        timeSlotPriceDiscountDtoList: TimeSlotPriceDiscountDto[]
    ): number {
        return this.timeSlotService.getDiscountPercentage(
            deliveryFee,
            timeSlotPriceDiscountDtoList,
            this.currentBasketValue
        );
    }

    getDiscountedDeliveryFee(
        deliveryFee: number,
        timeSlotPriceDiscountDtoList: TimeSlotPriceDiscountDto[]
    ): number {
        return this.timeSlotService.getDiscountedDeliveryFee(
            deliveryFee,
            timeSlotPriceDiscountDtoList,
            this.currentBasketValue
        );
    }

    getTitleDeliveryFee(timeSlotDay: TimeSlotDayDTO) {
        if (
            this.getDiscountPercentage(
                timeSlotDay.deliveryFee - timeSlotDay.additionalFee,
                timeSlotDay.timeSlotPriceDiscountDtoList
            ) !== 0
        ) {
            const discountedDeliveryFee = this.getDiscountedDeliveryFee(
                timeSlotDay.deliveryFee - timeSlotDay.additionalFee,
                timeSlotDay.timeSlotPriceDiscountDtoList
            );

            return this.formatterService.formatCurrency(discountedDeliveryFee);
        } else {
            return this.formatterService.formatCurrency(
                timeSlotDay.deliveryFee - timeSlotDay.additionalFee
            );
        }
    }

    hasDiscount(timeSlotRange: TimeSlotRangeDTO): boolean {
        return (
            timeSlotRange.timeSlotDayList[0].timeSlotPriceDiscountDtoList[0]
                ?.discountPercentage > 0
        );
    }

    underThreshold(): boolean {
        return (
            (this.selectedTimeSlotDayDTO.minBasketValueRestriction &&
                this.currentBasketValue <= this.selectedTimeSlotDayDTO.minBasketValue) ||
            (this.selectedTimeSlotDayDTO.maxBasketWeightRestriction &&
                this.currentBasketWeight >= this.selectedTimeSlotDayDTO.maxBasketWeight)
        );
    }

    selectTimeSlot(): void {
        if (!this.selectionAllowed) {
            this.translate
                .get('time-slot.no-select-info')
                .subscribe((notForSelectionInfo) => {
                    this.snackBar.openSnackBar(notForSelectionInfo, 'bottom');
                });
        }
    }
}
