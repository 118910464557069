import { Injectable } from '@angular/core';
import { SessionService } from './session/session.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateShoppingListComponent } from '../components/shopping-list/create-shopping-list/create-shopping-list.component';
import { ModalController } from '@ionic/angular';
import { ShareBasketByEmailDialogComponent } from '../components/shopping-list/share-basket-by-email-dialog/share-basket-by-email-dialog.component';
import { PostMessageService } from './post-message/post-message.service';
import { ProviderDTO } from '../index';
import { LogInComponent } from '../components/log-in/log-in.component';

@Injectable({
    providedIn: 'root',
})
export class SharedModalService {
    private mobileModal: HTMLIonModalElement;
    private desktopModal: MatDialogRef<any>;

    constructor(
        private sessionService: SessionService,
        private dialog: MatDialog,
        private modalCtrl: ModalController,
        private postMessage: PostMessageService
    ) {}

    async createNewShoppingList(callback?: (data) => void): Promise<any> {
        if (this.sessionService.isMobile) {
            const modal = await this.modalCtrl.create({
                component: CreateShoppingListComponent,
                breakpoints: [0, 0.7, 1],
                initialBreakpoint: 0.7,
                cssClass: 'custom-modal',
                componentProps: {
                    closeEmitter: (data: ProviderDTO) => {
                        if (data && callback) {
                            callback(data);
                        }
                        void modal.dismiss();
                    },
                },
            });

            return modal.present();
        } else {
            const dialog = this.dialog.open(CreateShoppingListComponent, {
                width: '550px',
            });

            dialog.componentInstance.closeEmitter = (data?) => {
                dialog.close();
                if (data) callback(data);
            };

            return dialog;
        }
    }

    async openShareShoppingList(
        masterBasketId: number,
        masterBasketGUID: string,
        providerId: number,
        showCopyLink = true
    ): Promise<any> {
        if (this.sessionService.isMobile) {
            this.mobileModal = await this.modalCtrl.create({
                component: ShareBasketByEmailDialogComponent,
                breakpoints: [0.6, 1],
                initialBreakpoint: 0.6,
                cssClass: 'custom-modal',
                componentProps: {
                    masterBasketId: masterBasketId,
                    userMasterBasketGUID: masterBasketGUID,
                    providerId: providerId,
                    showLink: true,
                    showCopyLink: showCopyLink,
                    closeEmitter: () => {
                        this.mobileModal.dismiss();
                    },
                    copyMobile: (text: string) => {
                        this.postMessage.copyToClipboard(text);
                    },
                },
            });

            return await this.mobileModal.present();
        } else {
            this.desktopModal = this.dialog.open(ShareBasketByEmailDialogComponent, {
                maxWidth: '627px',
                minWidth: '300px',
                maxHeight: '90vh',
            });

            this.desktopModal.componentInstance.providerId = providerId;
            this.desktopModal.componentInstance.userMasterBasketGUID = masterBasketGUID;
            this.desktopModal.componentInstance.masterBasketId = masterBasketId;
            this.desktopModal.componentInstance.showLink = showCopyLink;
            this.desktopModal.componentInstance.closeEmitter = () => {
                this.desktopModal.close();
            };

            return this.desktopModal;
        }
    }

    async presentSignInModal(
        callback?: (signedId: boolean) => void,
        selectedTab: 0 | 1 = 0
    ): Promise<any> {
        if (this.sessionService.isMobile) {
            this.mobileModal = await this.modalCtrl.create({
                component: LogInComponent,
                breakpoints: [0, 1],
                initialBreakpoint: 1,
                componentProps: {
                    modal: this,
                    selectedTab,
                },
                cssClass: 'custom-modal',
            });

            this.mobileModal.onDidDismiss().then((resp) => {
                callback(resp.data);
            });

            return await this.mobileModal.present();
        } else {
            this.desktopModal = this.dialog.open(LogInComponent, {
                width: '420px',
                height: '620px',
                maxWidth: '95vw',
                maxHeight: '85vh',
                position: { top: '10vh' },
                panelClass: 'log-in-reg-panel',
            });

            this.desktopModal.afterClosed().subscribe((resp) => {
                callback(resp.loggedIn);
            });

            return this.desktopModal;
        }
    }

    closeModals(): void {
        if (this.mobileModal) {
            void this.mobileModal.dismiss();
            delete this.mobileModal;
        }
        if (this.desktopModal) {
            this.desktopModal.close();
            delete this.desktopModal;
        }
    }
}
