import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BasketService } from '../../../services/basket.service';
import { SessionService } from '../../../services/session/session.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-discount-next-level-dialog',
    templateUrl: './discount-next-level-dialog.component.html',
    styleUrls: ['./discount-next-level-dialog.component.scss'],
})
export class DiscountNextLevelDialogComponent {
    currentRokshDiscountLevelIndex: number;
    currentRokshDiscountBasketValue: number;
    nextRokshDiscountLevelIndex: number;
    currentTotalPrice: number;
    currentDepositTotalPrice: number;
    text: string;
    providerCode: string;
    url: string;
    @Input() closeEmitter: () => void;

    constructor(
        private router: Router,
        private translate: TranslateService,
        private basketService: BasketService,
        private sessionService: SessionService
    ) {
        this.providerCode =
            this.sessionService.sessionSubject.getValue().selectedShopCodes[0];
        this.basketService.currentRokshDiscountLevelIndexSubject.subscribe(
            (currentRokshDiscountLevelIndex) => {
                this.currentRokshDiscountLevelIndex = currentRokshDiscountLevelIndex;
            }
        );

        this.basketService.currentRokshDiscountLevelBasketValueSubject.subscribe(
            (currentRokshDiscountBasketValue) => {
                this.currentRokshDiscountBasketValue = currentRokshDiscountBasketValue;
            }
        );

        this.basketService.nextRokshDiscountLevelIndexSubject.subscribe(
            (nextRokshDiscountLevelIndex) => {
                this.nextRokshDiscountLevelIndex = nextRokshDiscountLevelIndex;
                this.translate
                    .get('common.roksh-discount-next-level-text', {
                        nextRokshDiscountLevelIndex: this.currentRokshDiscountLevelIndex,
                    })
                    .subscribe((text) => (this.text = text));
            }
        );

        this.basketService.currentTotalPriceSubject.subscribe((currentTotalPrice) => {
            this.currentTotalPrice = currentTotalPrice;
        });
        this.basketService.currentDepositPriceSubject.subscribe((depositPrice) => {
            this.currentDepositTotalPrice = depositPrice;
        });

        this.setDiscountProductListUrl();
    }

    setDiscountProductListUrl(): void {
        const offer = this.translate.instant('router.offer');
        const categoryBlock = this.translate.instant('router.category-block');

        this.url = `${this.sessionService.isMobile ? '/shop/' : '/'}${
            this.providerCode
        }/${offer}/${categoryBlock}/marketing-block`;
    }

    close(redirect: boolean): void {
        if (!redirect) {
            this.closeEmitter();
            return;
        }

        let url = '';
        if (!this.sessionService.isMobile) {
            const offer = this.translate.instant('router.offer');
            const categoryBlock = this.translate.instant('router.category-block');
            url = `/${this.providerCode}/${offer}/${categoryBlock}/marketing-block`;
        } else {
            url = `/shop/${this.providerCode}/kinalat/kategoria-blokk/marketing-block`;
        }

        this.closeEmitter();
        void this.router.navigate([url]);
    }
}
