import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, interval, ReplaySubject } from 'rxjs';
import {
    AvailableTimeSlotsDTO,
    BasketService,
    InstantTimeslotDTO,
    InstantWorktimeQueryDTO,
    ServiceBaseService,
    SessionService,
    SnackBarService,
    TimeSlotService,
} from 'projects/library-shared/src/public-api';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { switchMap, takeUntil, tap, throttle } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-provider-time-slots-dialog',
    templateUrl: './provider-time-slots-dialog.component.html',
    styleUrls: ['./provider-time-slots-dialog.component.scss'],
})
export class ProviderTimeSlotsDialogComponent implements OnInit, OnDestroy {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    selectionAllowed = false;
    instantDeliveryAvailable = false;
    instantTimeSlots: InstantTimeslotDTO[] = [];
    areaHasInstantProvider = false;
    postCode: string;
    faTimesCircle = faTimesCircle;
    showSelectionNotAllowedMessage = false;
    deliveryTimeQueriesDone = false;
    configuredInstantWorktimes: InstantTimeslotDTO[] = [];

    availableTimeSlotsDto: AvailableTimeSlotsDTO;
    siteImageUrl = '';
    dialogTitle = '';
    timeSlotInfo = '';
    notForSelectionInfo = '';
    selectedShopName = '';
    showProviderLgoo = false;

    constructor(
        private sessionService: SessionService,
        private snackBar: SnackBarService,
        private timeSlotService: TimeSlotService,
        private basketService: BasketService,
        public dialogRef: MatDialogRef<ProviderTimeSlotsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public baseService: ServiceBaseService,
        public translate: TranslateService
    ) {
        forkJoin([
            this.translate.get('time-slot.available-time-slots'),
            this.translate.get('time-slot.time-slot-info'),
            this.translate.get('time-slot.no-select-info'),
        ]).subscribe(
            ([timeSlots, timeSlotInfo, noSelectInfo]: [string, string, string]) => {
                this.dialogTitle = timeSlots;
                this.timeSlotInfo = timeSlotInfo;
                this.notForSelectionInfo = noSelectInfo;
            }
        );
    }

    ngOnInit() {
        this.selectedShopName = this.data.provider;
        this.showProviderLgoo = this.data.showProviderLogo;
        this.siteImageUrl = this.baseService.getSiteImageUrl();
        this.sessionService.sessionSubject
            .pipe(
                takeUntil(this.destroyed$),
                tap((cityData) => (this.postCode = cityData.zipCode)),
                switchMap(() =>
                    this.timeSlotService
                        .getConfiguredExpressWorktimesByProvider(
                            this.data.providerID,
                            this.postCode
                        )
                        .pipe(takeUntil(this.destroyed$))
                ),
                tap((worktimes) => (this.configuredInstantWorktimes = worktimes)),
                switchMap(() =>
                    this.basketService.selectedBasketSubject.asObservable().pipe(
                        throttle(() => interval(3000)),
                        takeUntil(this.destroyed$)
                    )
                ),
                switchMap((basket) => {
                    const instantWorktimeQueryDTO = new InstantWorktimeQueryDTO({
                        providerID: this.data.providerID,
                        zipCode: this.postCode,
                        itemCount: basket != null ? basket.basketItemList.length : 1,
                        intervals: this.configuredInstantWorktimes.map(
                            (worktime) => worktime.interval
                        ),
                    });

                    return forkJoin({
                        availableTimeSlotsDto:
                            this.timeSlotService.getAvailableTimeSlotList(
                                this.data.providerID,
                                this.postCode
                            ),
                        instantWorkTimes:
                            this.timeSlotService.getRealTimeInstantTimeSlotsByBoundary(
                                instantWorktimeQueryDTO
                            ),
                        instantProvider:
                            this.timeSlotService.getInstantDeliveryAreaProvider(
                                this.data.providerID,
                                this.postCode
                            ),
                    });
                })
            )
            .subscribe((responses) => {
                this.deliveryTimeQueriesDone = true;
                if (responses.availableTimeSlotsDto) {
                    this.availableTimeSlotsDto = responses.availableTimeSlotsDto;
                }
                if (responses.instantWorkTimes) {
                    this.instantDeliveryAvailable = true;
                    this.instantTimeSlots = responses.instantWorkTimes;
                }
                if (responses.instantProvider) {
                    this.areaHasInstantProvider = true;
                }
            });
    }

    onShowSelectionNotAllowed(show: boolean): void {
        if (show) {
            this.snackBar.openSnackBar(this.notForSelectionInfo, 'bottom');
        }
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    close(): void {
        this.dialogRef.close();
    }
}
