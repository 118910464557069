import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';
import {
    CategoryService,
    ConfigureSessionDto,
    ProductBlockDto,
    ProductDto,
    ServiceBaseService,
    SessionService,
} from 'projects/library-shared/src/public-api';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-commercial-block',
    templateUrl: './commercial-block.component.html',
    styleUrls: ['./commercial-block.component.scss'],
})
export class CommercialBlockComponent implements OnInit {
    @Input() commercialBlock: ProductBlockDto;
    @Output() onClickEmitter = new EventEmitter();
    private baseMediaUrl = '';
    coverImageUrl = '';
    mainImageUrl = '';
    nextUrl: string;
    productList: ProductDto[];

    constructor(
        public baseService: ServiceBaseService,
        public sessionService: SessionService,
        private router: Router,
        private categoryService: CategoryService
    ) {
        this.baseMediaUrl = this.baseService.getMediaServiceUrl();
        this.baseMediaUrl = this.baseMediaUrl + 'interface/original';
    }

    ngOnInit(): void {
        this.coverImageUrl = this.baseMediaUrl + '/' + this.commercialBlock.BackGUID;
        if (window.innerWidth <= 768) {
            this.mainImageUrl =
                this.baseMediaUrl + '/' + this.commercialBlock.MobileApplicationMediaGUID;
        } else {
            this.mainImageUrl = this.baseMediaUrl + '/' + this.commercialBlock.MediaGUID;
        }

        if (
            this.commercialBlock.HasOwnCategory &&
            this.commercialBlock.OwnCategoryUrl !== ''
        ) {
            this.nextUrl =
                this.commercialBlock.ProviderCodeToSelect +
                this.commercialBlock.OwnCategoryUrl;
        } else {
            this.nextUrl = '/' + this.commercialBlock.RelatedUrl;
        }

        this.onResize();
    }

    goToCustomBlockPage() {
        this.sessionService.isPostCodeSet.pipe(take(1)).subscribe((result) => {
            if (result) {
                const configureSessionDto = new ConfigureSessionDto({
                    userSelectedShops: [this.commercialBlock.ProviderNameToSelect],
                });
                this.categoryService.categories.next([]);
                this.sessionService
                    .configureUserSession(configureSessionDto)
                    .subscribe(() => {
                        void this.router.navigate([this.nextUrl]);
                    });
            }
        });
    }

    handleClick(): void {
        this.onClickEmitter.emit();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.productList = this.commercialBlock.ProductList.slice(0, 4);
        if (window.innerWidth >= 992 && window.innerWidth < 1200) {
            this.productList = this.commercialBlock.ProductList.slice(0, 3);
        } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
            this.productList = this.commercialBlock.ProductList.slice(0, 2);
        } else if (window.innerWidth >= 481 && window.innerWidth < 768) {
            this.productList = this.commercialBlock.ProductList.slice(0, 3);
        } else if (window.innerWidth < 481) {
            this.productList = this.commercialBlock.ProductList.slice(0, 2);
        }
    }
}
