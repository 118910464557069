import { Component, Input } from '@angular/core';
import { ProviderDTO } from '../../../index';
import { ServiceBaseService } from '../../../services/service-base.service';
import { SessionService } from '../../../services/session/session.service';
import { UserBasketService } from '../../../services/user-basket.service';

@Component({
    selector: 'lib-create-shopping-list',
    templateUrl: './create-shopping-list.component.html',
    styleUrls: ['./create-shopping-list.component.scss'],
})
export class CreateShoppingListComponent {
    basketName: string;
    isCreatingInProgress = false;
    selectedProvider: ProviderDTO;
    @Input() closeEmitter: (data?) => void;
    siteImageUrl: string;
    @Input() isFromBasket = false;
    isMobile: boolean;

    constructor(
        private userBasketService: UserBasketService,
        baseService: ServiceBaseService,
        sessionService: SessionService
    ) {
        this.isMobile = sessionService.isMobile;
        this.siteImageUrl = baseService.siteImageUrl;
    }

    onCreateUserBasket(): void {
        if (this.isFromBasket) {
            this.closeEmitter({ basketName: this.basketName });

            return;
        }
        this.isCreatingInProgress = true;
        this.userBasketService
            .createUserBasket(this.basketName, this.selectedProvider.providerCode)
            .subscribe(
                () => {
                    this.closeEmitter({ basketName: this.basketName });
                    this.isCreatingInProgress = false;
                },
                () => {
                    this.isCreatingInProgress = false;
                }
            );
    }

    onProviderChange(provider: ProviderDTO): void {
        this.selectedProvider = provider;
    }
}
