import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    ArchieClient,
    ArchieConversationThreadDto,
    ArchieConversationThreadMessageDto,
    ArchieProviderDto,
    ArchieSettingsClient,
    BasketDto,
    ProductDto,
} from '../..';
import { take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { faMicrophone, faPaperPlane, faStop } from '@fortawesome/free-solid-svg-icons';
import { ServiceBaseService } from '../../services/service-base.service';
import { SnackBarService } from '../../services/snackbar/snackbar.service';
import { Title } from '@angular/platform-browser';
import { animate, style, transition, trigger } from '@angular/animations';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { ArchieProductList } from './message-box/message-box.component';
import { SessionService } from '../../services/session/session.service';
import * as oboe from 'oboe';
import { BasketService } from '../../services/basket.service';

declare const webkitSpeechRecognition;
export enum ArchieNavigationLevel {
    PROVIDER_SELECT,
    CHAT,
    PRODUCTS_VIEW,
    ALL_BASKET,
    BASKET,
    ORDER,
    UPDATE_PROVIDER,
}

@Component({
    selector: 'lib-archie',
    templateUrl: './archie.component.html',
    styleUrls: ['./archie.component.scss'],
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [
                style({ transform: 'translateX(-30%)', opacity: 0 }),
                animate(
                    '150ms ease-in',
                    style({ transform: 'translateX(0%)', opacity: 1 })
                ),
            ]),
            transition(':leave', [
                style({ transform: 'translateX(0%)' }),
                animate(
                    '150ms ease-in',
                    style({ transform: 'translateX(-30%)', opacity: 0 })
                ),
            ]),
        ]),
    ],
})
export class ArchieComponent implements OnInit {
    @Input() navigationLevelEmitter = new EventEmitter<number>();
    @Output() providerSelectedEmitter = new EventEmitter<ArchieConversationThreadDto>();
    @Output() providerToShowEmitter = new EventEmitter<string>();
    @Input() readonly newThreadEmitter = new EventEmitter<boolean>();
    @Output() clickProduct: EventEmitter<{
        product: ProductDto;
        providerId: number;
        providerName: string;
    }> = new EventEmitter();
    @Output() goToCheckout: EventEmitter<string> = new EventEmitter();
    @Output() clickShowTimeSlot: EventEmitter<number> = new EventEmitter();
    @Output() sendMessageToArchieEmit: EventEmitter<ArchieConversationThreadMessageDto> =
        new EventEmitter();
    @ViewChild('messagePanel') messagePanel: ElementRef;
    navigationLevel = ArchieNavigationLevel.PROVIDER_SELECT;
    answerDoneEmitter: EventEmitter<boolean> = new EventEmitter();
    openProductListEmitter = new EventEmitter<ArchieProductList>();
    userMessage: string;
    messageSendingDisabled = false;
    archieConversationThreadID: string;
    isSendingInProgress = false;
    archieConversationThread: ArchieConversationThreadDto;
    faSend = faPaperPlane;
    faStop = faStop;
    faMic = faMicrophone;
    userStop$: Subject<boolean> = new Subject<boolean>();

    voiceSearch: any;
    isSpeaking = false;

    private newMessageInterval: any;
    private DEFAULT_TITLE: string;
    private NEW_MESSAGE: string;
    logMessage: string;
    selectedProducts: ArchieProductList;
    selectedProviders: ArchieProviderDto[];
    streamEnabled = false;
    private windowIsOnFocus = true;
    selectedBaskets: BasketDto[];
    selectedBasket: BasketDto;
    siteImageUrl: string;
    basketCount = 0;

    constructor(
        private route: ActivatedRoute,
        private archieClient: ArchieClient,
        private translate: TranslateService,
        private serviceBaseService: ServiceBaseService,
        private changeDetector: ChangeDetectorRef,
        private snackBar: SnackBarService,
        private archieSettingsClient: ArchieSettingsClient,
        private location: Location,
        private title: Title,
        private sessionService: SessionService,
        private basketService: BasketService,
        baseService: ServiceBaseService
    ) {
        this.siteImageUrl = baseService.siteImageUrl;
        if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
            this.voiceSearch = new webkitSpeechRecognition();
            this.setupVoiceRecognization();
        }
        this.logMessage = 'archie.waiting-for-question';
    }

    ngOnInit(): void {
        this.setTitle();
        this.route.params.subscribe((params) => {
            const inputConversationThreadID: string =
                params['ArchieConversationThreadID'];
            this.setThreadSubscriptions(inputConversationThreadID);
        });
        this.openProductListEmitter.subscribe((productArray) => {
            if (productArray && productArray.productList.length > 0) {
                this.selectedProducts = productArray;
                this.navigationLevelEmitter.next(ArchieNavigationLevel.PRODUCTS_VIEW);
                this.providerToShowEmitter.emit(productArray.providerName);
            } else {
                delete this.selectedProducts;
                this.navigationLevelEmitter.next(ArchieNavigationLevel.CHAT);
            }
        });
        this.navigationLevelEmitter.subscribe((level) => {
            if (this.sessionService.isMobile) {
                this.navigationLevel = level;
            } else {
                const navigationGroup = [
                    ArchieNavigationLevel.PROVIDER_SELECT,
                    ArchieNavigationLevel.CHAT,
                    ArchieNavigationLevel.PRODUCTS_VIEW,
                    ArchieNavigationLevel.UPDATE_PROVIDER,
                ];
                if (navigationGroup.includes(level)) {
                    this.navigationLevel = level;
                }
            }
        });
        this.providerSelectedEmitter.subscribe((providers) => {
            this.selectedProviders = providers.providerDto;
            if (providers) {
                this.basketService.masterBasketSubject.subscribe((masterBasket) => {
                    if (!masterBasket) {
                        return;
                    }

                    this.selectedBaskets = masterBasket.basketList.filter((basket) =>
                        this.selectedProviders.find(
                            (provider) => provider.providerId === basket.providerID
                        )
                    );

                    this.basketCount = 0;
                    this.selectedBaskets.forEach((basket) => {
                        this.basketCount += basket.basketItemList.length;
                    });
                });
            }
        });
    }

    private setThreadSubscriptions(ArchieConversationThreadID: string): void {
        if (ArchieConversationThreadID) {
            this.archieClient
                .getArchieThreadById(ArchieConversationThreadID)
                .subscribe((thread) => {
                    this.threadSelected(thread);

                    if (thread.providerDto.length >= 1) {
                        this.providerSelectedEmitter.next(thread);
                        this.navigationLevelEmitter.next(ArchieNavigationLevel.CHAT);
                    } else {
                        this.navigationLevelEmitter.next(
                            ArchieNavigationLevel.PROVIDER_SELECT
                        );
                    }
                });
        }
        this.archieClient.getArchieThread().subscribe((thread) => {
            this.threadSelected(thread);

            if (thread.providerDto.length >= 1) {
                this.providerSelectedEmitter.next(thread);
                this.navigationLevelEmitter.next(ArchieNavigationLevel.CHAT);
            } else {
                this.navigationLevelEmitter.next(ArchieNavigationLevel.PROVIDER_SELECT);
            }
        });
        this.newThreadEmitter.subscribe(() => {
            this.createNewArchieThread();
        });

        this.providerSelectedEmitter.subscribe((thread) => {
            this.threadSelected(thread);
        });
    }

    private threadSelected(thread: ArchieConversationThreadDto): void {
        this.archieConversationThread = thread;
        this.archieConversationThreadID = thread.archieConversationThreadID;

        let streamQueryParam = '?stream=true';
        this.route.queryParams.subscribe((params) => {
            const streamParam = params['stream']?.toString();

            if (streamParam?.toLowerCase() !== 'true') {
                streamQueryParam = '';
            }
        });

        this.location.go('archie/' + this.archieConversationThreadID, streamQueryParam);

        this.navigationLevelEmitter.next(ArchieNavigationLevel.CHAT);
    }

    private setupVoiceRecognization(): void {
        this.voiceSearch.continuous = false;
        this.voiceSearch.interimresults = true;
        if (ServiceBaseService.getCountryCode() === 'hu') {
            this.voiceSearch.lang = 'hu-HU';
        } else {
            this.voiceSearch.lang = 'de-DE';
        }

        this.voiceSearch.onresult = (res) => {
            if (res.results) {
                this.userMessage = res.results[0][0].transcript;
                this.sendMessageToArchie();
            }
            this.isSpeaking = false;
            this.changeDetector.detectChanges();
        };

        this.voiceSearch.onerror = () => {
            this.isSpeaking = false;
            this.changeDetector.detectChanges();
        };

        this.voiceSearch.onend = () => {
            this.isSpeaking = false;
            this.changeDetector.detectChanges();
        };
    }

    public sendMessageToArchie(automaticMessage?: string): void {
        if (automaticMessage) this.userMessage = automaticMessage;

        if (
            !this.userMessage ||
            this.userMessage?.trim() === '' ||
            this.messageSendingDisabled
        )
            return;
        const archieConversationThreadMessageDto = new ArchieConversationThreadMessageDto(
            {
                structuredMessageBody: this.userMessage,
                messageBody: this.userMessage,
                isArchieMessage: false,
                created: new Date(),
            }
        );

        this.archieConversationThread.archieConversationThreadMessageDtoList.push(
            archieConversationThreadMessageDto
        );

        const panel = this.messagePanel.nativeElement as HTMLDivElement;
        panel.scrollTop = panel.scrollHeight;

        this.sendMessageToArchieEmit.emit(archieConversationThreadMessageDto);
        this.messageSendingDisabled = true;
        this.isSendingInProgress = true;
        this.logMessage = 'archie.answer-is-in-progress';

        this.archieSettingsClient.getArchieSettings().subscribe((archieSettings) => {
            if (archieSettings?.streamingEnabled) {
                this.streamChatCompletions();
            } else {
                this.route.queryParams.subscribe((params) => {
                    const streamParam = params['stream']?.toString();
                    if (streamParam?.toLowerCase() === 'true') {
                        this.streamChatCompletions();
                    } else {
                        this.sendMessage();
                    }
                });
            }
        });
    }

    private streamChatCompletions(): void {
        this.streamEnabled = true;
        this.archieConversationThread.archieConversationThreadMessageDtoList.push(
            new ArchieConversationThreadMessageDto({
                structuredMessageBody: '',
                messageBody: '',
                isArchieMessage: true,
                created: new Date(),
            })
        );

        const messageListLength =
            this.archieConversationThread.archieConversationThreadMessageDtoList?.length;

        const baseUrl = this.serviceBaseService.getBaseUrl();
        const endpoint = `archie-stream/stream-chat-completions/${this.archieConversationThreadID}/${this.userMessage}`;
        const url = new URL(endpoint, baseUrl);

        const config = {
            method: 'GET',
            url: url.toString(),
        };

        const oboeService = oboe(config);

        oboeService
            .node('!.*', (chunk: string) => {
                if (chunk !== null && chunk !== undefined) {
                    this.archieConversationThread.archieConversationThreadMessageDtoList[
                        messageListLength - 1
                    ].messageBody += chunk;
                    this.archieConversationThread.archieConversationThreadMessageDtoList[
                        messageListLength - 1
                    ].structuredMessageBody += chunk;
                }
            })
            .done(() => {
                this.newMessage();
                this.answerDoneEmitter.emit(true);
                this.streamEnabled = false;
            })
            .fail((error) => {
                this.streamEnabled = false;
                console.log('StreamChatCompletions error', error);
            });
    }

    private newMessage(): void {
        this.setOptionToSendNewMessage();

        if (!this.windowIsOnFocus) {
            let defaultSet = true;
            this.newMessageInterval = setInterval(() => {
                if (defaultSet) {
                    this.title.setTitle(this.DEFAULT_TITLE + ' ' + this.NEW_MESSAGE);
                } else {
                    this.title.setTitle(this.DEFAULT_TITLE);
                }
                defaultSet = !defaultSet;
            }, 1500);
        }

        this.changeDetector.detectChanges();
    }

    private sendMessage(): void {
        this.archieClient
            .sendMessageToArchie(this.archieConversationThreadID, this.userMessage)
            .pipe(takeUntil(this.userStop$))
            .subscribe(
                (existingThread) => {
                    this.checkEmptyMessage(existingThread);

                    this.archieConversationThread.archieConversationThreadMessageDtoList.push(
                        existingThread.archieConversationThreadMessageDtoList[
                            existingThread.archieConversationThreadMessageDtoList.length -
                                1
                        ]
                    );
                    this.newMessage();
                },
                () => {
                    this.archieConversationThread.archieConversationThreadMessageDtoList.push(
                        new ArchieConversationThreadMessageDto({
                            structuredMessageBody: this.getErrorMessage(),
                            messageBody: this.getErrorMessage(),
                            isArchieMessage: true,
                            created: new Date(),
                        })
                    );
                    this.setOptionToSendNewMessage();
                    this.changeDetector.detectChanges();
                }
            );
    }

    private setOptionToSendNewMessage(): void {
        this.messageSendingDisabled = false;
        this.isSendingInProgress = false;
        this.logMessage = 'archie.waiting-for-question';
        this.userMessage = '';
    }

    cancelRequest(): void {
        this.userStop$.next(true);
        this.setOptionToSendNewMessage();
    }

    private checkEmptyMessage(existingThread: ArchieConversationThreadDto): void {
        const lastMessage =
            existingThread.archieConversationThreadMessageDtoList[
                existingThread.archieConversationThreadMessageDtoList.length - 1
            ];

        if (lastMessage.isArchieMessage && lastMessage.messageBody === '') {
            existingThread.archieConversationThreadMessageDtoList[
                existingThread.archieConversationThreadMessageDtoList.length - 1
            ].messageBody = this.getErrorMessage();

            existingThread.archieConversationThreadMessageDtoList[
                existingThread.archieConversationThreadMessageDtoList.length - 1
            ].structuredMessageBody = this.getErrorMessage();
        }
    }

    private getErrorMessage(): string {
        let errorMessage = '';
        this.translate.get('archie.error-message').subscribe((message) => {
            return (errorMessage = message);
        });

        return errorMessage;
    }

    private setTitle(): void {
        this.translate
            .get(['archie.default-title', 'archie.new-message'])
            .subscribe((translation) => {
                this.DEFAULT_TITLE = translation['archie.default-title'];
                this.NEW_MESSAGE = translation['archie.new-message'];

                this.title.setTitle(this.DEFAULT_TITLE);
            });
    }

    createNewArchieThread(): void {
        this.translate.get('archie.sure-new-thread').subscribe((text) => {
            if (confirm(text)) {
                this.messageSendingDisabled = true;
                this.logMessage = 'archie.create-new-thread';
                this.archieClient
                    .newArchieThread()
                    .pipe(take(1))
                    .subscribe((newThread) => {
                        this.threadSelected(newThread);
                        this.messageSendingDisabled = false;
                        this.logMessage = 'archie.waiting-for-question';
                        this.navigationLevelEmitter.next(
                            ArchieNavigationLevel.PROVIDER_SELECT
                        );
                    });
            }
        });
    }

    handleClickProduct(event: {
        product: ProductDto;
        providerId: number;
        providerName: string;
    }): void {
        this.clickProduct.emit(event);
    }

    handleClickShowTimeSlot(event: number) {
        this.clickShowTimeSlot.emit(event);
    }

    startListening(): void {
        if (this.isSpeaking) {
            this.isSpeaking = false;
            this.changeDetector.detectChanges();
            this.voiceSearch.end();
        }
        if ('webkitSpeechRecognition' in window) {
            this.isSpeaking = true;
            this.changeDetector.detectChanges();
            this.voiceSearch.start();
        } else {
            this.snackBar.openErrorSnackBar(
                'Your browser does not support voice recognition!'
            );
        }
    }

    clearNewMessageStatus(): void {
        this.title.setTitle(this.DEFAULT_TITLE);

        if (this.newMessageInterval) clearInterval(this.newMessageInterval);
    }

    onBasketSelected(basket: BasketDto): void {
        this.selectedBasket = basket;
        this.navigationLevelEmitter.next(ArchieNavigationLevel.BASKET);
        this.providerToShowEmitter.emit(basket.providerName);
    }

    @HostListener('window:focus', ['$event'])
    onFocus(): void {
        this.clearNewMessageStatus();
        this.windowIsOnFocus = true;
    }

    @HostListener('window:blur', ['$event'])
    onBlur(): void {
        this.windowIsOnFocus = false;
    }
}
