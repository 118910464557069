import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderLandingCarouselComponent } from './provider-landing-carousel.component';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CarouselModule } from 'ngx-owl-carousel-o';

@NgModule({
    declarations: [ProviderLandingCarouselComponent],
    imports: [CommonModule, RouterModule, FontAwesomeModule, CarouselModule],
    exports: [ProviderLandingCarouselComponent],
})
export class ProviderLandingCarouselModule {}
