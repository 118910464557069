import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    ProductDto,
    SessionService,
    SnackBarService,
} from 'projects/library-shared/src/public-api';
import { ProductDetailsDialogComponent } from '../../containers/product-details/product-details-dialog/product-details-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { AddressDialogComponent } from '../address/address-dialog.component';
import { ShopSelectDialogSeparatedComponent } from '../shop-select-dialog-separated/shop-select-dialog-separated.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { CreateDialogService } from '../../services/create-dialog.service';

@Component({
    selector: 'app-product-card-wrapper',
    templateUrl: './product-card-wrapper.component.html',
    styleUrls: ['./product-card-wrapper.component.css'],
})
export class ProductCardWrapperComponent {
    @Input() masterBasketId: number;
    @Input() basketItemId: number;
    @Input() isShoppingList = false;
    @Input() isFavoriteList = false;
    @Input() commercialBlock = false;
    @Input() product: ProductDto;
    @Input() providerName: string;
    @Input() providerId: number;
    @Input() selectedShoppingListQuantity: number;
    @Input() preferredWidth: string;
    @Output() deleteEmitter: EventEmitter<any> = new EventEmitter();
    @Output() removeEmitter: EventEmitter<any> = new EventEmitter();
    AddressDialogComponent = AddressDialogComponent;
    ShopSelectDialogSeparatedComponent = ShopSelectDialogSeparatedComponent;

    constructor(
        private dialog: MatDialog,
        private location: Location,
        private title: Title,
        private translate: TranslateService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private snackBar: SnackBarService,
        private sessionService: SessionService,
        private createDialog: CreateDialogService
    ) {}

    onClick(): void {
        if (
            this.providerName &&
            (this.isFavoriteList || this.isShoppingList) &&
            !this.sessionService.isProviderSelectedForShopping.getValue()
        ) {
            this.sessionService.sessionSubject.pipe(take(1)).subscribe((session) => {
                if (!session.availableShops.includes(this.providerName)) {
                    this.translate
                        .get('messages.shop-not-available-postcode')
                        .subscribe((notAvailable) => {
                            this.snackBar.openErrorSnackBar(notAvailable);
                        });

                    return;
                }

                const queryParams: Params = {
                    providerId: this.providerName,
                };

                void this.router.navigate([], {
                    relativeTo: this.activatedRoute,
                    queryParams,
                    queryParamsHandling: 'merge',
                });
            });
        }

        const dialogRef = this.dialog.open(ProductDetailsDialogComponent, {
            maxWidth: '1155px',
            minHeight: '620px',
            width: '90vw',
            data: { seoName: this.product.sEOName },
            panelClass: 'position-relative',
        });

        this.setURLAndTitle(dialogRef);
    }

    private setURLAndTitle(dialogRef: any): void {
        this.translate.get('router.products').subscribe((text) => {
            const routerUrl = text;

            const currentUrl = this.location.path();
            this.location.go(
                routerUrl +
                    '/' +
                    this.product?.categorySEOName?.toLocaleLowerCase() +
                    '/' +
                    this.product?.sEOName?.toLocaleLowerCase()
            );

            const currentTitle = this.title.getTitle();
            this.title.setTitle('' + this.product?.productName + '!');

            dialogRef.afterClosed().subscribe(() => {
                this.location.go(currentUrl);
                this.title.setTitle(currentTitle);
            });

            event.preventDefault();
        });
    }

    onAddToBasket(isSuccess: boolean): void {
        if (!isSuccess) {
            this.createDialog.openAddressDialog(true);
        }
    }
}
