<div
    class="card-container w-100 d-flex"
    (click)="selectBasket.emit(userBasket)"
    *ngIf="userBasket"
>
    <div
        class="z-1 provider-container h-100 bg-turquoise d-flex justify-content-center align-content-center position-relative"
    >
        <img
            *ngIf="userBasket.totalPrice > 0; else emptyBasket"
            src="{{ siteImageUrl }}images/shared-img/user-basket/basket_full.svg"
            alt="full basket"
            [class.opacity-down]="!providerSelected"
        />
        <ng-template #emptyBasket>
            <img
                src="{{ siteImageUrl }}images/shared-img/user-basket/basket_empty.svg"
                alt="empty basket"
                [class.opacity-down]="!providerSelected"
            />
        </ng-template>
        <div
            *ngIf="!providerSelected && userBasket.providerName"
            class="position-absolute d-flex justify-content-center align-items-center provider-logo p-1 m-auto start-0 top-0 end-0 bottom-0"
        >
            <img
                [src]="
                    siteImageUrl +
                    'logos/' +
                    userBasket.providerName.toLowerCase() +
                    '-logo-s.png'
                "
                alt="logo"
                class="max-h-100"
            />
        </div>
    </div>
    <div
        class="w-100 h-100 d-flex justify-content-between flex-column p-3 position-relative"
    >
        <div
            class="z-0 w-100 h-100 bg-turquoise position-absolute top-0 start-0"
            *ngIf="isSelected"
            @selectAnimation
        ></div>
        <h4 class="z-1">{{ userBasket.basketName }}</h4>
        <h4 class="z-1 w-100 text-end text-grey">
            {{ userBasket.totalPrice | currencyFormatted }}
            &nbsp;{{ 'common.currency' | translate }}
        </h4>
    </div>
</div>
