<h4 class="close-button-panel position-relative" mat-dialog-title>
    <div class="float-start font-weight-bold"></div>
    <div class="float-end text-end close-button ms-2">
        <span class="pointer" (click)="dialogRef.close()">
            <fa-icon [icon]="faTimesCircle"></fa-icon>
        </span>
    </div>
    <div class="clearfix"></div>
</h4>
<br />
<div mat-dialog-content class="mb-5">
    <div
        *ngIf="showTotalPriceWarningMessage"
        class="alert-total-price d-flex align-items-center ms-3 justify-content-center mb-2"
    >
        <img
            src="assets/svg/rokshImages/icons/exclamation.svg"
            class="pe-2"
            alt="exclamation"
        />
        {{ 'messages.add-product-max' | translate }}
    </div>
    <div class="row">
        <div class="col">
            <img
                src="{{ product?.mediaUrlS }}"
                title="{{ product?.productName }}"
                onerror="this.src='assets/images/roksh_logo.svg';"
                class="ms-lg-1 order-1 order-lg-1 productImg"
            />
        </div>
        <div class="col">
            <h5
                class="mt-0 text-size-14 mb-2"
                itemprop="name"
                attr.content="{{ product?.productName }}"
            >
                {{ product?.productName }}
            </h5>
            <div class="row">
                <div class="col-9">
                    <h6 class="font-weight-bold my-2 text-size-12">
                        <span class="text-black-50 font-weight-light">
                            {{ productProvider?.packageQuantity }}
                            {{ productProvider?.packageBaseUnit }}
                        </span>
                    </h6>
                </div>
            </div>
        </div>
        <div class="col-item">
            <div class="mb-0 text-end text-size-12">
                {{ 'common.price' | translate }}:
            </div>
            <div class="mb-0 text-end text-size-12">
                {{ 'common.final-price' | translate }}:
            </div>
        </div>
        <div class="col">
            <span class="text-size-12">
                <div class="row mobile-screen-price">
                    {{ productProvider?.price | currencyFormatted }}&nbsp;{{
                        'common.currency' | translate
                    }}
                </div>
                <div class="row mobile-screen-price mobile-screen-final-price">
                    {{
                        productProvider?.price * addOrUpdateOrderItemDTO.Quantity
                            | currencyFormatted
                    }}&nbsp;{{ 'common.currency' | translate }}
                </div>
            </span>
        </div>
    </div>
    <div class="row mobile-screen">
        <div class="d-flex align-items-center justify-content-between ms-3 rearrange">
            <div
                *ngIf="
                    product?.minPrice === 0 || product?.available === false;
                    else elseBlock
                "
            >
                <span class="text-danger">
                    {{ 'messages.not-available-product' | translate }}</span
                >
                <link itemprop="availability" href="http://schema.org/NotInStock" />
            </div>
            <ng-template #elseBlock>
                <div class="pb-1 w-100">
                    <div class="add-to-order-panel" style="width: 145px">
                        <a
                            class="quantity-btn float-start"
                            (click)="stepQuantity(addOrUpdateOrderItemDTO.Quantity - 1)"
                        >
                            <fa-icon
                                *ngIf="!quantityChangeInProgress; else qtySpinner"
                                [icon]="faMinus"
                                class="text-orange"
                                [styles]="{ color: '#E67300' }"
                            ></fa-icon>
                            <ng-template #qtySpinner>
                                <fa-icon
                                    [icon]="faSpinner"
                                    [spin]="true"
                                    [styles]="{ color: '#E67300' }"
                                ></fa-icon>
                            </ng-template>
                        </a>
                        <a
                            class="quantity-btn float-end"
                            (click)="stepQuantity(addOrUpdateOrderItemDTO.Quantity + 1)"
                        >
                            <fa-icon
                                *ngIf="!quantityChangeInProgress; else qtySpinner"
                                [icon]="faPlus"
                                class="text-orange"
                                [styles]="{ color: '#E67300' }"
                            ></fa-icon>
                            <ng-template #qtySpinner>
                                <fa-icon
                                    [icon]="faSpinner"
                                    [spin]="true"
                                    [styles]="{ color: '#E67300' }"
                                ></fa-icon>
                            </ng-template>
                        </a>
                        <div *ngIf="!product?.isBulk; else bulkPrice">
                            <select
                                class="product-quantity d-block mx-auto text-size-18"
                                [(ngModel)]="addOrUpdateOrderItemDTO.Quantity"
                                (change)="stepQuantity(addOrUpdateOrderItemDTO.Quantity)"
                            >
                                <option *ngFor="let q of quantityList" [ngValue]="q[0]">
                                    {{ q[1] }}
                                </option>
                            </select>
                        </div>

                        <ng-template #bulkPrice>
                            <select
                                class="product-quantity d-block mx-auto text-size-18"
                                [(ngModel)]="addOrUpdateOrderItemDTO.Quantity"
                                (change)="stepQuantity(addOrUpdateOrderItemDTO.Quantity)"
                            >
                                <option *ngFor="let q of quantityList" [ngValue]="q[0]">
                                    {{ q[1] }}
                                </option>
                            </select>
                        </ng-template>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
    <div *ngIf="errorMessage !== null && errorMessage.length > 0; else justHr">
        <br />
        <div class="error-message d-flex justify-content-center">
            {{ errorMessage }}
        </div>
        <br />
    </div>
    <ng-template #justHr>
        <hr />
    </ng-template>
    <br />
    <div *ngIf="isAddInProgress; else modifyBlock">
        <div class="d-flex justify-content-center">
            <button
                class="add-update-btn btn-rounded mb-1 text-size-16 font-weight-600"
                (click)="onAdd()"
                [ngClass]="{ disabled: btnModify() }"
            >
                {{ 'common.add' | translate }}
            </button>
        </div>
    </div>
    <ng-template #modifyBlock>
        <div class="d-flex justify-content-center">
            <button
                class="add-update-btn btn-rounded mb-1 text-size-16 font-weight-600"
                (click)="onModify()"
                [ngClass]="{ disabled: btnModify() }"
            >
                {{ 'common.modify' | translate }}
            </button>
            <div
                *ngIf="
                    orderItem?.OrderRemovedItemID === null ||
                    orderItem?.OrderRemovedItemID === ''
                "
                class="pt-2"
            >
                <div *ngIf="btnDelete; else clickable">
                    <a class="ms-3 text-size-14 font-weight-600" (click)="deleteError()">
                        {{ 'common.delete' | translate }}
                    </a>
                </div>
                <ng-template #clickable>
                    <a class="ms-3 text-size-14 font-weight-600" (click)="onDelete()">
                        {{ 'common.delete' | translate }}
                    </a>
                </ng-template>
            </div>
        </div>
    </ng-template>
</div>
