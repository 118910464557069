<div class="modal-container">
    <div class="dialog-header mt-1">
        <div class="text-end w-100 close-button">
            <span class="pointer" (click)="close(false)">
                <img
                    class="expansion-header-svg close-icon"
                    src="assets/images/discount/icons/close.svg"
                />
            </span>
        </div>
        <h4 class="title fw-bold d-flex justify-content-left pb-3 pt-5">
            {{ 'common.roksh-discount-info-title' | translate }}
        </h4>
    </div>

    <div class="wrapper">
        <div class="box-text mb-2">
            <span class="current-roksh-discount fw-bold">
                {{ currentRokshDiscount | currencyFormatted }}
                {{ 'common.currency' | translate }}
            </span>

            <p>
                {{ 'common.roksh-discount-info-box-text' | translate }}
            </p>
        </div>

        <div>
            {{ 'common.roksh-discount-info-text' | translate }}
        </div>
        <div class="w-100 mt-4">
            <a class="marketing-block-btn" (click)="close(true)">
                {{ 'common.go-to-marketing-block' | translate }}
            </a>
        </div>
    </div>
</div>
