import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
    AuthenticationService,
    BasketService,
    DataLayerGa4Service,
    LoadingService,
    MasterBasketDto,
    ServiceBaseService,
    SessionService,
    UserShared,
} from 'projects/library-shared/src/public-api';
import { Subscription } from 'rxjs';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { MobilMenuService } from './mobil-menu/mobil-menu.service';
import { CreateDialogService } from '../../services/create-dialog.service';
import { HomeService } from '../../services/home/home.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    isHeaderDisplayed = true;
    isAuthenticated = false;
    user: UserShared;
    isLoading = false;
    isPostCodeBtn = true;
    userSelectedShops: string[];
    masterBasket: MasterBasketDto;
    prodCount = 0;
    faChevronLeft = faChevronLeft;

    scrollSub: Subscription;
    logoutSub: Subscription;
    authStatusSub: Subscription;
    isLoadingSub: Subscription;
    navigationStartSub: Subscription;
    navigationEndSub: Subscription;
    isPostCodeSet = false;
    isOwnWebShop = ServiceBaseService.isProviderOwnWebShop();
    isInformativeLandingScreen = true;
    isAddAddressInputDisplayed = false;
    isRokshDiscountAvailable: boolean;
    isMobileView = false;
    isAddAddressButtonDisplayed = false;

    constructor(
        private authService: AuthenticationService,
        private loadingService: LoadingService,
        private homeService: HomeService,
        private sessionService: SessionService,
        private DataLayerGa4Service: DataLayerGa4Service,
        private mobilMenuService: MobilMenuService,
        private createDialogService: CreateDialogService,
        private basketService: BasketService
    ) {
        if (window.innerWidth < 768) this.isMobileView = true;
        this.setRokshDiscountsSubscriptions();
    }

    ngOnInit() {
        this.basketService.isRokshDiscountAvailableSubject.subscribe(
            (isRokshDiscountAvailable) => {
                this.isRokshDiscountAvailable = isRokshDiscountAvailable;
            }
        );
        this.homeService.isHeaderDisplayed.subscribe((value) => {
            this.isHeaderDisplayed = value;
        });

        this.homeService.isSessionSet.subscribe((isSetted) => {
            if (isSetted) {
                this.homeService.isCategoryMenuDisplayed.subscribe((value) => {
                    this.isInformativeLandingScreen = !value;
                    if (
                        window.location.href.includes('providerId') ||
                        window.location.href.includes('providerName') ||
                        window.location.href.includes('orderCode')
                    )
                        this.isInformativeLandingScreen = false;

                    if (typeof this.isPostCodeSet !== 'undefined') {
                        this.setIsInformativeLandingScreen();
                    }

                    this.calculateProductCount();
                });

                this.sessionService.sessionSubject.subscribe((session) => {
                    this.userSelectedShops = session?.selectedShops;

                    if (session?.isValidZip) {
                        if (this.userSelectedShops?.length === 1)
                            this.isPostCodeBtn = false;
                    }

                    this.basketService.masterBasketSubject.subscribe((masterBasket) => {
                        this.masterBasket = masterBasket;
                        this.calculateProductCount();
                    });
                });

                this.authStatusSub = this.createAuthSub();
                this.configureHeaderLodingIndicator();
                this.basketService.productCountChangeSubject.subscribe((prodCount) => {
                    this.prodCount = prodCount;
                });

                this.sessionService.isPostCodeSet.subscribe((result) => {
                    this.isPostCodeSet = result;
                    this.setIsInformativeLandingScreen();
                });
            }
        });
    }

    showRokshDiscountInfo() {
        this.createDialogService.openRokshDiscountInfoDialog();
    }

    private createAuthSub(): Subscription {
        return this.authService.getAuthStatusListener().subscribe((newAuthStatus) => {
            this.isAuthenticated = newAuthStatus;
            this.user = this.authService.getLoggedInUser();
        });
    }

    private configureHeaderLodingIndicator() {
        this.isLoadingSub = this.loadingService.getIsLoading().subscribe((loading) => {
            this.isLoading = loading;
        });
    }

    private calculateProductCount(): void {
        if (this.userSelectedShops && this.masterBasket?.basketList) {
            if (this.isInformativeLandingScreen) {
                this.prodCount = this.masterBasket.basketList.reduce(
                    (productCount, basket) =>
                        productCount + basket.basketItemList?.length ?? 0,
                    0
                );
            } else {
                const selectedShop = this.masterBasket.basketList.find(
                    (x) =>
                        x.providerName.toLowerCase() ===
                        this.userSelectedShops[0].toLowerCase()
                );
                this.prodCount = selectedShop?.basketItemList.length ?? 0;
            }
        }
    }

    private setIsInformativeLandingScreen(): void {
        this.isAddAddressInputDisplayed =
            this.isInformativeLandingScreen && this.isPostCodeSet;
    }

    private setRokshDiscountsSubscriptions(): void {
        this.basketService.showRokshDiscountNextLevelDialogSubject.subscribe(
            (showDialog) => {
                if (showDialog) {
                    this.showRokshDiscountNextLevelDialog();
                }
            }
        );
    }

    private showRokshDiscountNextLevelDialog(): void {
        this.createDialogService.openRokshDiscountNextLevelDialog();
    }

    showResponsiveMenu() {
        this.mobilMenuService.changeMenuOpenClose(true);
    }

    onOpenBasket() {
        this.basketService.openMenuSubject.next(true);
        if (this.prodCount > 0) {
            this.DataLayerGa4Service.datalayerUniversalPush(
                'Checkout',
                'Basket open',
                this.prodCount.toString()
            );
        }
    }

    ngOnDestroy() {
        if (this.authStatusSub) {
            this.authStatusSub.unsubscribe();
        }
        if (this.isLoadingSub) {
            this.isLoadingSub.unsubscribe();
        }
        if (this.navigationStartSub) {
            this.navigationStartSub.unsubscribe();
        }
        if (this.navigationEndSub) {
            this.navigationEndSub.unsubscribe();
        }
        if (this.scrollSub) {
            this.scrollSub.unsubscribe();
        }
        if (this.logoutSub) {
            this.logoutSub.unsubscribe();
        }
        if (this.homeService.isHeaderDisplayed) {
            this.homeService.isHeaderDisplayed.unsubscribe();
        }
        if (this.homeService.isSessionSet) {
            this.homeService.isSessionSet.unsubscribe();
        }
        if (this.sessionService.sessionSubject) {
            this.sessionService.sessionSubject.unsubscribe();
        }
        if (this.isLoadingSub) {
            this.isLoadingSub.unsubscribe();
        }
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        this.adjustHeaderElements();
    }

    adjustHeaderElements() {
        const minScroll = 300;

        let currentScroll = 0;
        currentScroll = window.pageYOffset;

        this.isAddAddressButtonDisplayed =
            currentScroll > minScroll &&
            this.isInformativeLandingScreen &&
            !this.isPostCodeSet;
    }
}
