import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProviderClient, ProviderDTO } from '../index';

@Injectable({ providedIn: 'root' })
export class ProvidersService {
    constructor(private providerClient: ProviderClient) {}

    public getAllProviders(): Observable<ProviderDTO[]> {
        return this.providerClient.getAllProviders();
    }
    public getProvidersByOrder(): Observable<ProviderDTO[]> {
        return this.providerClient.getProvidersByOrder();
    }
    public getProvidersByUserBasket(): Observable<ProviderDTO[]> {
        return this.providerClient.getProvidersByBasket();
    }
    public getProvidersByFavouriteProduct(): Observable<ProviderDTO[]> {
        return this.providerClient.getProvidersByFavouriteProduct();
    }
}
