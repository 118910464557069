import { Component, Input, OnInit } from '@angular/core';
import { faCheck, faMinus, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ProductDto, UserBasketDto, UserBasketProductDto } from '../../../../index';
import { UserBasketService } from '../../../../services/user-basket.service';
import { DataLayerGa4Service } from '../../../../services/data-layer/data-layer-ga4.service';

@Component({
    selector: 'lib-add-product-to-list-row',
    templateUrl: './add-to-list-row.component.html',
    styleUrls: ['./add-to-list-row.component.scss'],
    animations: [
        trigger('inOutText', [
            state('open', style({ transform: 'translateX(0)' })),
            state('closed', style({ transform: 'translateX(-30px)' })),
            transition('open => closed', [animate('200ms ease-in')]),
            transition('closed => open', [animate('150ms ease-in')]),
        ]),
    ],
})
export class AddToListRowComponent implements OnInit {
    @Input() userBasket: UserBasketDto;
    @Input() selectedProduct: ProductDto;
    @Input() quantityList: [number, string][];
    isProductContained = false;
    inProgress = false;
    selectedQuantity: number;
    basketItemIdForChangeQuantity: number;

    faMinusCircle = faMinus;
    faPlusCircle = faPlus;
    faSpinner = faSpinner;
    faChecked = faCheck;
    removeInProgress = false;

    constructor(
        private userBasketService: UserBasketService,
        private dataLayerGa4Service: DataLayerGa4Service
    ) {}

    ngOnInit(): void {
        this.getUserBasketDetails();
    }

    private getUserBasketDetails() {
        this.inProgress = true;
        this.userBasketService
            .getUserBasketDetails(
                this.userBasket.providerID,
                this.userBasket.userMasterBasketGUID
            )
            .subscribe(
                (userBasket) => {
                    this.userBasket.userBasketProductList =
                        userBasket.userBasketProductList;

                    const productDto = this.calculateContainedProduct();
                    if (productDto) {
                        this.isProductContained = true;
                        this.selectedQuantity = productDto.unitValue;
                        this.basketItemIdForChangeQuantity = productDto.basketItemId;
                    }

                    this.inProgress = false;
                },
                () => {
                    this.inProgress = false;
                }
            );
    }

    calculateContainedProduct(): UserBasketProductDto {
        return this.userBasket.userBasketProductList.find((productDto) => {
            return productDto.product.productID === this.selectedProduct.productID;
        });
    }

    onAddProduct(): void {
        if (this.inProgress) return;

        if (this.isProductContained) {
            this.onChangeQuantity(this.selectedQuantity + 1);
            return;
        }

        this.inProgress = true;
        const productId = this.selectedProduct?.productID
            ? this.selectedProduct.productID
            : this.selectedProduct['Product'].ProductID;

        this.userBasketService
            .addProductToUserBasket(
                this.userBasket.masterBasketID,
                productId,
                this.quantityList[0][0]
            )
            .subscribe(
                (newUserBasket) => {
                    this.userBasket = newUserBasket;
                    this.getUserBasketDetails();

                    this.dataLayerGa4Service.datalayerUniversalPush(
                        'Functions',
                        'Add to list',
                        this.selectedProduct.productName
                    );
                    this.inProgress = false;
                },
                () => {
                    this.inProgress = false;
                }
            );
    }

    onRemoveProduct(): void {
        if (this.inProgress) return;

        if (this.selectedQuantity - 1 > 0) {
            this.onChangeQuantity(this.selectedQuantity - 1);
            return;
        }

        this.inProgress = true;
        this.removeInProgress = true;
        this.userBasketService
            .removeProductFromUserBasket(
                this.userBasket.masterBasketID,
                this.selectedProduct.productID,
                null
            )
            .subscribe(
                (newUserBasket) => {
                    setTimeout(() => {
                        this.removeInProgress = false;
                    }, 500);
                    this.inProgress = false;
                    this.isProductContained = false;
                    this.userBasket = newUserBasket;
                },
                () => {
                    this.inProgress = false;
                }
            );
    }

    onChangeQuantity(selectedQuantity?: number): void {
        if (this.inProgress) return;
        const tmpQuantity = this.selectedQuantity;
        if (selectedQuantity) this.selectedQuantity = selectedQuantity;
        const outOfRange =
            this.quantityList.findIndex(
                (quantity) => quantity[0] === this.selectedQuantity
            ) === -1;
        if (outOfRange) {
            this.selectedQuantity = tmpQuantity;

            return;
        }

        this.inProgress = true;
        this.userBasketService
            .updateQuantity(
                this.basketItemIdForChangeQuantity,
                this.selectedQuantity,
                this.userBasket.masterBasketID
            )
            .subscribe(
                () => {
                    this.inProgress = false;
                },
                () => {
                    this.inProgress = false;
                }
            );
    }
}
