export enum AppEnvironment {
    Localhost,
    Master,
    Staging,
    Production,
    ProviderTest,
    DeliveryTest,
    AtProduction,
    AtStaging,
    AtMaster,
    HuGoJajaProd,
}
