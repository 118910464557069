<a
    class="w-100 ps-4 text-decoration-none d-flex align-items-center h-100 icon"
    (click)="onShareLinkByEmail()"
>
    <img
        class="pe-2"
        src="{{ siteImageUrl }}images/shared-img/user-basket/share.svg"
        alt="Save basket"
    />
    <span class="text">{{ 'common.share' | translate }}</span>
</a>
