import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductNutritionComponent } from './product-nutrition.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
    declarations: [ProductNutritionComponent],
    imports: [CommonModule, FontAwesomeModule],
    exports: [ProductNutritionComponent],
})
export class ProductNutritionModule {}
