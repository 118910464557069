<div class="discount-margin">
    <div class="col-12 pt-3 mb-3 ps-0">
        <a [routerLink]="['/', 'router.dashboard' | translate]">{{
            'router.start-page' | translate
        }}</a>
        <fa-icon [icon]="faAngleRight" class="mt-1 mx-2 ms-0"></fa-icon>
        <a
            [queryParams]="{ providerName: selectedProviderName }"
            [routerLink]="[
                '/',
                'router.user' | translate,
                'router.my-orders' | translate
            ]"
            >{{ 'common.my-orders' | translate }}</a
        >
        <fa-icon [icon]="faAngleRight" class="mt-1 mx-2 ms-0"></fa-icon> {{ orderCode }}
    </div>
    <div class="mb-2">
        <span class="vissza">
            <fa-icon [icon]="faArrowLeft" class="color-turquoise mt-1 ms-0"></fa-icon>
            <a
                class="color-turquoise ms-2"
                [routerLink]="[
                    '/',
                    'router.user' | translate,
                    'router.my-orders' | translate
                ]"
                [queryParams]="{ providerName: selectedProviderName }"
                >{{ 'common.back' | translate }}</a
            >
        </span>
    </div>

    <div class="form-container">
        <h4 class="color-turquoise font-weight-600 pull-left my-orders-header">
            {{ 'common.my-order' | translate }}
        </h4>
        <a
            href="{{
                'static-links.roksh-knowledge'
                    | translate
                    | providerTheme : 'static-links.roksh-knowledge'
                    | async
            }}"
            target="_blank"
            class="pull-right"
            ><u>{{ 'common.i-have-question' | translate }}</u></a
        >
    </div>
    <div class="clearfix"></div>
    <br />
    <div *ngIf="isLoading">
        <app-order-card [order]="order" [isOneOrderView]="true"></app-order-card>
    </div>
</div>
