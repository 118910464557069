import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArchieComponent } from './archie.component';
import { MessageBoxComponent } from './message-box/message-box.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ArchieProductListComponent } from './product-list/archie-product-list.component';
import { ArchieProviderSelectComponent } from './archie-provider-select/archie-provider-select.component';
import { ArchieAllBasketComponent } from './archie-all-basket/archie-all-basket.component';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { ProductModule } from '../product/product.module';

@NgModule({
    declarations: [
        ArchieComponent,
        MessageBoxComponent,
        ArchieProductListComponent,
        ArchieProviderSelectComponent,
        ArchieAllBasketComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        SharedPipesModule,
        ProductModule,
    ],
    exports: [ArchieComponent, ArchieAllBasketComponent],
})
export class ArchieModule {}
