import { Component, OnInit } from '@angular/core';
import { faAngleRight, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
    BasketService,
    ConfigureSessionDto,
    DataLayerGa4Service,
    LogService,
    ProviderThemePipe,
    ServiceBaseService,
    SessionService,
    ShopAvailableDto,
} from 'projects/library-shared/src/public-api';
import { CreateDialogService } from '../../services/create-dialog.service';
import { HomeService } from '../../services/home/home.service';
import { Observable } from 'rxjs';

@Component({
    templateUrl: './shop-select-dialog-separated.component.html',
    styleUrls: ['./shop-select-dialog-separated.component.scss'],
    providers: [LogService],
})
export class ShopSelectDialogSeparatedComponent implements OnInit {
    ShopHandlerForm: FormGroup;

    get imageSrc(): (src: string, key: string) => Observable<string> {
        return (src, key) => this.calculateImageRoute(src, key);
    }

    private calculateImageRoute(src: string, key: string): Observable<string> {
        return this.providerThemePipe.transform(src, key);
    }

    faEdit = faEdit;
    faAngleRight = faAngleRight;
    TotalPrice = 169000;

    availableShops: string[];
    availableShopWBasketInfos: ShopAvailableDto[] = [];

    userSelectedAvailableShops: string[] = [];
    onlyOneShopSelected: string[] = [];
    city: string;
    isValidZip: boolean;
    checked = true;
    isFirst = true;
    isDeliveryAvailableByPostCode: boolean;
    availableProviderNames: string[] = [];

    isPostCodeSetted = false;
    isShopChanging = false;
    isProviderSelectedForShopping = false;
    postcodeValue: string;

    siteImageUrl = '';

    constructor(
        public dialogRef: MatDialogRef<ShopSelectDialogSeparatedComponent>,
        private sessionService: SessionService,
        private createDialogService: CreateDialogService,
        private basketService: BasketService,
        private formBuilder: FormBuilder,
        private homeService: HomeService,
        private DataLayerGa4Service: DataLayerGa4Service,
        private log: LogService,
        public baseService: ServiceBaseService,
        private providerThemePipe: ProviderThemePipe
    ) {
        if (ServiceBaseService.isProviderOwnWebShop()) {
            const configureSessionDto = new ConfigureSessionDto({
                userSelectedShops: [ServiceBaseService.getOwnWebShopProviderCode()],
            });

            this.sessionService.configureUserSession(configureSessionDto).subscribe();
            this.dialogRef.close();
        }
    }

    ngOnInit() {
        this.siteImageUrl = this.baseService.getSiteImageUrl();
        this.ShopHandlerForm = this.formBuilder.group({
            ShopId: [],
        });

        this.homeService.isSessionSet.subscribe(() => {
            this.availableProviderNames =
                this.homeService.dataBaseAvailableProviderNames.value;
        });

        this.log.mainClass = ShopSelectDialogSeparatedComponent.name;

        this.sessionService.sessionSubject.subscribe((session) => {
            this.city = session.city;
            this.isDeliveryAvailableByPostCode = session.isDeliveryAvailableByPostCode;
            this.isValidZip = session.isValidZip;
            this.postcodeValue = session.zipCode;
            this.isDeliveryAvailableByPostCode = session.isDeliveryAvailableByPostCode;

            if (session.shopsAvailable) {
                this.availableShops = session.shopsAvailable.map((shop) => shop.shopName);
                this.userSelectedAvailableShops = session.selectedShops;

                if (this.userSelectedAvailableShops?.length === 1)
                    this.onlyOneShopSelected[0] = this.userSelectedAvailableShops[0];

                this.createavailableShopWBasketInfos();
            } else {
                this.availableShops = [];
                this.availableShopWBasketInfos = [];
            }
            this.log.log('sessionSubject', this.availableShops, session.shopsAvailable);
        });

        this.sessionService.isPostCodeSet.subscribe((result) => {
            this.isPostCodeSetted = result;
        });
        this.sessionService.isProviderSelectedForShopping.subscribe((result) => {
            this.isProviderSelectedForShopping = result;
        });

        this.DataLayerGa4Service.datalayerUniversalPush('ZIP', 'Open');
    }

    selectThisShop(shop: string) {
        this.isShopChanging = true;
        this.isProviderSelectedForShopping = true;

        const configureSessionDto = new ConfigureSessionDto({
            userSelectedShops: [shop],
            redirectToDashboardNeeded: true,
        });

        this.sessionService.configureUserSession(configureSessionDto).subscribe();

        setTimeout(() => {
            this.isShopChanging = false;
            this.dialogRef.close();
        }, 3000);
    }

    showZipCodeDialog() {
        this.createDialogService.openAddressDialog();
        this.dialogRef.close();
    }

    private createavailableShopWBasketInfos() {
        this.basketService.masterBasketSubject.subscribe((masterBasket) => {
            this.availableShopWBasketInfos = [];
            for (const i in this.availableShops) {
                if (
                    masterBasket &&
                    masterBasket.basketList &&
                    masterBasket.basketList.find(
                        (basket) =>
                            basket.providerName.toLocaleLowerCase() ===
                            this.availableShops[i].toLocaleLowerCase()
                    ) &&
                    masterBasket.basketList.find(
                        (basket) =>
                            basket.providerName.toLocaleLowerCase() ===
                            this.availableShops[i].toLocaleLowerCase()
                    ).basketItemList
                ) {
                    this.availableShopWBasketInfos.push({
                        shopName: this.availableShops[i],
                        shopNameToShow: this.availableShops[i],
                        basketTotalItems: masterBasket.basketList.find(
                            (b) =>
                                b.providerName.toLocaleLowerCase() ===
                                this.availableShops[i].toLocaleLowerCase()
                        ).basketItemList.length,
                        basketTotalReplacementProduct: masterBasket.basketList.find(
                            (b) =>
                                b.providerName.toLocaleLowerCase() ===
                                this.availableShops[i].toLocaleLowerCase()
                        ).basketItemList.length,
                        basketTotalPrice: masterBasket.basketList.find(
                            (b) =>
                                b.providerName.toLocaleLowerCase() ===
                                this.availableShops[i].toLocaleLowerCase()
                        ).totalPrice,
                    });
                } else {
                    this.availableShopWBasketInfos.push({
                        shopName: this.availableShops[i],
                        shopNameToShow: this.availableShops[i],
                        basketTotalItems: -1,
                        basketTotalReplacementProduct: -1,
                        basketTotalPrice: -1,
                    });
                }
            }

            this.availableShopWBasketInfos = this.availableShopWBasketInfos.sort(
                (n1, n2) =>
                    this.onlyOneShopSelected.indexOf(n2.shopName) -
                    this.onlyOneShopSelected.indexOf(n1.shopName)
            );
        });
    }
}
