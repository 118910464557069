<div class="w-100 p-1 p-md-3 header-checkout">
    <div
        class="container text-center d-flex flex-column flex-md-row h-100 align-items-start align-items-md-center"
    >
        <a
            *ngIf="!isArchie"
            class="text-white order-3 order-md-0 text-start"
            style="min-width: 100px"
            [routerLink]="['/', 'router.dashboard' | translate]"
        >
            <fa-icon [icon]="faArrowLeft"></fa-icon> {{ 'common.back' | translate }}
        </a>
        <div class="flex-grow-1">
            <img
                src="assets/svg/Logo_slogen.svg"
                alt="{{ 'info.alt-desktop-logo' | translate }}"
                class="pull-left sk-logo-resp"
            />
        </div>
        <h1 class="m-0 flex-grow-1 align-self-center text-end">
            {{ 'checkout.finish-checkout' | translate }}
        </h1>
    </div>
</div>

<div class="container">
    <div *ngIf="(orderStatus$ | async) === orderStatus.SUBMITTED">
        <p
            class="text-success m-4 font-weight-600"
            [innerHTML]="'checkout.submit-message' | translate"
        ></p>
        <a class="btn btn-success" href="/{{ 'router.dashboard' | translate }}">
            {{ 'checkout.continue-shopping' | translate }}
        </a>
    </div>

    <div *ngIf="(orderStatus$ | async) === orderStatus.FAILED">
        <div class="text-info m-4 font-weight-600">
            <h2 class="text-warning">{{ 'checkout.error-at-checkout' | translate }}</h2>
            <div [innerHTML]="'checkout.error-at-checkout-message' | translate"></div>
            <button class="btn btn-success" onClick="window.location.reload();">
                <fa-icon [icon]="faTruck"></fa-icon>
                {{ 'checkout.checkout-again' | translate }}</button
            >&nbsp;&nbsp;&nbsp;
            <a
                class="btn btn-info"
                href=" {{
                    'static-links.roksh-contact'
                        | translate
                        | providerTheme : 'static-links.roksh-contact'
                        | async
                }}
                                    "
            >
                <fa-icon [icon]="faInfo"></fa-icon>
                {{ 'checkout.checkout-contact' | translate }}
            </a>
        </div>
        <br />
    </div>

    <div
        *ngIf="(orderStatus$ | async) === orderStatus.STARTCHECKOUTPROGRESS"
        class="w-100 text-center"
    >
        <p class="text-info m-4 font-weight-600">
            {{ 'checkout.collect-checkout-data' | translate }}
            <br />
            <img src="/assets/loading_kosar.gif" style="margin: auto; margin-top: 30px" />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </p>
    </div>

    <div
        *ngIf="(orderStatus$ | async) === orderStatus.INPROGRESS"
        class="w-100 text-center"
    >
        <p class="text-info m-4 font-weight-600">
            {{ 'checkout.validate-checkout-progress' | translate }}
            <br />
            <img src="/assets/loading_kosar.gif" style="margin: auto; margin-top: 30px" />
        </p>
    </div>

    <div class="w-100 px-0" *ngIf="(orderStatus$ | async) === orderStatus.FORM">
        <div class="row m-0 w-100">
            <div class="col-12 col-lg-8 ps-0 pe-0" style="padding-top: 25px">
                <app-checkout-address></app-checkout-address>
                <app-checkout-delivery-time></app-checkout-delivery-time>
                <div *ngIf="displayWishListProviderShops">
                    <app-checkout-wishlist></app-checkout-wishlist>
                </div>
                <div *ngIf="displayOrderPreferences">
                    <app-checkout-preferences></app-checkout-preferences>
                </div>
                <app-checkout-payment></app-checkout-payment>
            </div>
            <div class="col-12 col-lg-4" style="padding-top: 25px" id="summaryPanel">
                <app-checkout-summary></app-checkout-summary>
            </div>
        </div>
        <br />
        <br />
        <br />
        <!--<i style="font-size:14px;color:#888;">
        *{{ 'checkout.what-is-roksh' | translate }}
        <br />
    </i>-->
    </div>
</div>
<div class="container" *ngIf="order && order.SumDeposit && order.SumDeposit > 0">
    <div class="col-12 mt-2 pb-2">
        <p class="text-size-14">
            <ng-container>
                <strong>
                    <fa-icon [icon]="faWineBottle"></fa-icon>
                    {{ 'product-details.deposit-info' | translate }}
                </strong>
            </ng-container>
        </p>
    </div>
</div>
