import { Directive, HostListener, Inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductDetailsDialogComponent } from '../containers/product-details/product-details-dialog/product-details-dialog.component';
import { DOCUMENT, Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Overlay } from '@angular/cdk/overlay';

@Directive({
    selector: 'a[appProductCardLink]',
})
export class ProductCardLinkDirective {
    @Input() navigateUrl: string[];
    @Input() productId: string;
    @Input() productName: string;

    constructor(
        private dialog: MatDialog,
        private location: Location,
        private title: Title,
        @Inject(DOCUMENT) private dom: Document,
        private overlay: Overlay
    ) {}

    @HostListener('click', ['$event'])
    clicked(event: any) {
        const dialogRef = this.dialog.open(ProductDetailsDialogComponent, {
            maxWidth: '1100px',
            minHeight: '50vh',
            width: '90vw',
            data: this.productId,
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
        });
        const currentUrl = this.location.path();
        this.location.go(this.navigateUrl.join('/'));
        const currentTitle = this.title.getTitle();
        this.title.setTitle('' + this.productName + '!');

        dialogRef.afterClosed().subscribe(() => {
            this.location.go(currentUrl);
            this.title.setTitle(currentTitle);
        });

        event.preventDefault();
    }
}
