import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
    AddProductToListComponent,
    CreateShoppingListComponent,
    DiscountInfoDialogComponent,
    DiscountNextLevelDialogComponent,
    EmptyBasketDialogComponent,
    IMyOrder,
    ProductDto,
    SharedModalService,
    UserBasketDto,
} from 'projects/library-shared/src/public-api';
import { ShopSelectDialogSeparatedComponent } from '../components/shop-select-dialog-separated/shop-select-dialog-separated.component';
import { Observable } from 'rxjs';
import { SingleUserBasketDialogComponent } from '../containers/user-basket-list/single-user-basket-dialog/single-user-basket-dialog.component';
import { GenerateUserBasketGuidDialogComponent } from '../containers/user-basket-list/generate-user-basket-guid-dialog/generate-user-basket-guid-dialog.component';
import { ProviderTimeSlotsDialogComponent } from '../containers/dashboard/provider-time-slots-dialog/provider-time-slots-dialog.component';
import { ConfirmCancellationDialogComponent } from '../components/confirm-cancellation-dialog/confirm-cancellation-dialog.component';
import { OrderContactDetailsDialogComponent } from '../containers/order-list/order-contact-details-dialog.component';
import { OrderCommentDialogComponent } from '../containers/order-list/order-comment-dialog.component';
import { OrderTimeslotsDialogComponent } from '../containers/order-list/order-timeslots-dialog/order-timeslots-dialog.component';
import { DeliveryAddressDialogComponent } from '../containers/order-list/delivery-address/delivery-address-dialog.component';
import { AddressDialogComponent } from '../components/address/address-dialog.component';

@Injectable({ providedIn: 'root' })
export class CreateDialogService {
    constructor(
        private dialog: MatDialog,
        private sharedModalService: SharedModalService
    ) {}

    basketName: string;
    openedDialog: MatDialogRef<any>;

    closeOpenedDialog(): void {
        if (this.openedDialog)
            this.openedDialog.afterClosed().subscribe(() => {
                delete this.openedDialog;
            });
        this.openedDialog.close();
    }

    openShopSelectDialogSeparatedComponent(): MatDialogRef<ShopSelectDialogSeparatedComponent> {
        return this.dialog.open(ShopSelectDialogSeparatedComponent, {
            maxWidth: '480px',
            minWidth: '300px',
            maxHeight: '85vh',
            panelClass: 'my-custom-panel',
            position: { top: '10vh' },
        });
    }

    openSaveUserMbDialog(): Observable<CreateShoppingListComponent> {
        const dialogRef = this.dialog.open(CreateShoppingListComponent, {
            maxWidth: '95vw',
            width: '600px',
            minWidth: '100vw',
        });

        dialogRef.componentInstance.closeEmitter = (data?) => {
            dialogRef.close(data);
        };

        dialogRef.componentInstance.isFromBasket = true;

        return dialogRef.afterClosed();
    }

    openUserListAdd(product: ProductDto): MatDialogRef<AddProductToListComponent> {
        const dialog = this.dialog.open(AddProductToListComponent, {
            maxWidth: '95vw',
            minWidth: '300px',
            width: '500px',
            height: '75vh',
            maxHeight: '85vh',
        });

        dialog.componentInstance.newListEmitter = () => {
            void this.sharedModalService.createNewShoppingList(() => {
                this.openUserListAdd(product);
            });
        };

        dialog.componentInstance.closeEmitter = () => {
            dialog.close();
        };

        dialog.componentInstance.product = product;

        return dialog;
    }

    openSingleUserBasketDialog(
        shoppingList: UserBasketDto
    ): Observable<SingleUserBasketDialogComponent> {
        const dialog = this.dialog.open(SingleUserBasketDialogComponent, {
            autoFocus: false,
            maxHeight: '90vh',
            minWidth: '360px',
            maxWidth: '100vw',
            panelClass: 'custom-shared-list',
        });

        dialog.componentInstance.shoppingList = shoppingList;

        return dialog.afterClosed();
    }

    openGenerateUserMbGuidDialog(
        guid: string,
        providerId: number
    ): MatDialogRef<GenerateUserBasketGuidDialogComponent> {
        const dialogRef = this.dialog.open(GenerateUserBasketGuidDialogComponent, {
            maxWidth: '85vw',
            minWidth: '300px',
            maxHeight: '90vh',
            data: { guid, providerId },
        });
        return dialogRef;
    }

    showAvailableTimeSlotsDialog(
        provider: string,
        providerID: number,
        showProviderLogo = false
    ): MatDialogRef<ProviderTimeSlotsDialogComponent> {
        const dialogRef = this.dialog.open(ProviderTimeSlotsDialogComponent, {
            maxWidth: '768px',
            width: '96vw',
            maxHeight: '96vh',
            panelClass: 'custom-time-slot-modal',
            data: {
                provider: provider,
                providerID: providerID,
                showProviderLogo: showProviderLogo,
            },
        });
        dialogRef.disableClose = false;
        return dialogRef;
    }

    openConfirmCancellationDialog(
        title: string
    ): MatDialogRef<ConfirmCancellationDialogComponent> {
        return this.dialog.open(ConfirmCancellationDialogComponent, {
            width: '400px',
            maxWidth: '95vw',
            maxHeight: '90vh',
            position: { top: '10vh' },
            data: { title: title },
        });
    }

    openOrderContactDetailsDialog(
        order: IMyOrder
    ): MatDialogRef<OrderContactDetailsDialogComponent> {
        return this.dialog.open(OrderContactDetailsDialogComponent, {
            width: '400px',
            maxWidth: '95vw',
            maxHeight: '85vh',
            position: { top: '10vh' },
            panelClass: 'my-dialog',
            data: { order: order },
        });
    }

    openOrderCommentDialog(
        orderCode: string,
        comment: string
    ): MatDialogRef<OrderCommentDialogComponent> {
        return this.dialog.open(OrderCommentDialogComponent, {
            width: '400px',
            maxWidth: '95vw',
            maxHeight: '85vh',
            position: { top: '10vh' },
            panelClass: 'my-dialog',
            data: { orderCode: orderCode, comment: comment },
        });
    }

    openOrderTimeSlotsDialog(
        providerID: number,
        order: IMyOrder
    ): MatDialogRef<OrderTimeslotsDialogComponent> {
        const dialogRef = this.dialog.open(OrderTimeslotsDialogComponent, {
            maxWidth: '1100px',
            width: '90vw',
            maxHeight: '90vh',
            data: { providerID: providerID, order: order },
        });
        return dialogRef;
    }

    openOrderDeliveryAddressDialog(
        providerID: number,
        order: IMyOrder
    ): MatDialogRef<DeliveryAddressDialogComponent> {
        const dialogRef = this.dialog.open(DeliveryAddressDialogComponent, {
            maxWidth: '400px',
            width: '90vw',
            maxHeight: '85vh',
            data: { providerID: providerID, order: order },
        });
        return dialogRef;
    }

    openAddressDialog(
        stayHere = false,
        alertMessage = ''
    ): MatDialogRef<AddressDialogComponent> {
        return this.dialog.open(AddressDialogComponent, {
            data: { stayHere: stayHere, alertMessage: alertMessage },
        });
    }

    openRokshDiscountInfoDialog(): MatDialogRef<DiscountInfoDialogComponent> {
        const modal = this.dialog.open(DiscountInfoDialogComponent, {
            width: '395px',
            maxWidth: '95vw',
            maxHeight: '85vh',
            position: { top: '10vh' },
            panelClass: 'my-dialog',
        });

        modal.componentInstance.closeEmitter = () => {
            this.dialog.closeAll();
        };

        return modal;
    }

    openRokshDiscountNextLevelDialog(): MatDialogRef<DiscountNextLevelDialogComponent> {
        const modal = this.dialog.open(DiscountNextLevelDialogComponent, {
            width: '495px',
            maxWidth: '95vw',
            maxHeight: '85vh',
            position: { top: '10vh' },
            panelClass: 'my-dialog',
        });

        modal.componentInstance.closeEmitter = () => {
            this.dialog.closeAll();
        };

        return modal;
    }

    openEmptyBasketDialog(
        masterBasketId: string
    ): MatDialogRef<EmptyBasketDialogComponent> {
        return this.dialog.open(EmptyBasketDialogComponent, {
            data: { masterBasketId: masterBasketId },
            width: '330px',
            position: { right: '45px' },
            panelClass: ['animate__animated', 'animate__bounceIn'],
        });
    }
}
