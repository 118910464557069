import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProviderSettingsService } from '../provider-settings.service';
import { ServiceBaseService } from '../service-base.service';

@Pipe({
    name: 'providerTheme',
})
export class ProviderThemePipe implements PipeTransform {
    constructor(private providerSettingService: ProviderSettingsService) {}

    transform(text: string, key: string): Observable<string> {
        if (ServiceBaseService.isProviderOwnWebShop()) {
            return this.providerSettingService.settings.pipe(
                map((settings) => settings.find((x) => x.Key === key)?.Value)
            );
        }

        return of(text);
    }
}
