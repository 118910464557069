<div class="modal-container">
    <div class="dialog-header">
        <div class="text-end w-100 close-button">
            <span class="pointer" (click)="close(false)">
                <img
                    class="expansion-header-svg close-icon"
                    src="assets/images/discount/icons/close.svg"
                />
            </span>
        </div>
    </div>

    <div class="roksh-discount-next-level-dialog">
        <div class="box">
            <div style="margin: auto; width: 200px">
                <img
                    class="eu-blokk rocket-img"
                    src="assets/images/discount/rocket.png"
                />
            </div>
            <div class="progress-bar-container-desktop">
                <div class="progress">
                    <div
                        class="progress-bar"
                        role="progressbar"
                        style="width: 100%"
                        aria-valuenow="25"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    >
                        <span class="progress-completed">
                            {{ 'common.roksh-discount-progress-bar-level' | translate }}
                            {{ currentRokshDiscountLevelIndex }}
                        </span>
                    </div>
                </div>
                <div class="progress-bar-footer">
                    <div>
                        {{
                            currentTotalPrice + currentDepositTotalPrice
                                | currencyFormatted
                        }}
                        {{ 'common.currency' | translate }}
                    </div>
                    <div class="ms-auto">
                        {{ currentRokshDiscountBasketValue | currencyFormatted }}
                        {{ 'common.currency' | translate }}
                    </div>
                </div>
            </div>
            <br />
            <div class="mt-2 text-container">
                {{ text }}
            </div>
        </div>
    </div>
    <div class="w-100 mt-4">
        <a class="marketing-block-btn" [routerLink]="url" (click)="close(true)">
            {{
                'common.roksh-discount-next-level-button-text'
                    | translate
                        : { nextRokshDiscountLevelIndex: nextRokshDiscountLevelIndex }
            }}
        </a>
    </div>
</div>
