import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'projects/library-shared/src/public-api';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss'],
})
export class PasswordComponent {
    passwordForm = this.formBuilder.group(
        {
            Password: [
                '',
                [
                    Validators.required,
                    Validators.minLength(7),
                    Validators.pattern(
                        /^(?=.*\d)(?=.*[a-záéúőóüöí.])(?=.*[A-ZÁÉÚŐÓÜÖÍ])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*)(+=._-ÁáÉéÍíÓóÚúÖöÜüŐőŰű].{7,}$/
                    ),
                ],
            ],
            ConfirmPassword: ['', [Validators.required]],
        },
        { validator: this.checkPasswords }
    );

    isOK: boolean;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private authService: AuthenticationService,
        private route: ActivatedRoute,
        private snackBar: MatSnackBar
    ) {}

    checkPasswords(group: FormGroup) {
        const pass = group.controls.Password.value;
        const confirmPass = group.controls.ConfirmPassword.value;

        if (
            !new RegExp(
                /^(?=.*\d)(?=.*[a-záéúőóüöí.])(?=.*[A-ZÁÉÚŐÓÜÖÍ])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*)(+=._-ÁáÉéÍíÓóÚúÖöÜüŐőŰű].{7,}$/
            ).test(pass)
        ) {
            group.controls.Password.setErrors({ errorSpecialCharacter: true });
            return null;
        }

        if (pass === confirmPass ? null : { notSame: true }) {
            group.controls.ConfirmPassword.setErrors({ notSame: true });
            return { notSame: true };
        } else {
            group.controls.ConfirmPassword.setErrors(null);
            return null;
        }
    }

    onFormSubmit() {
        if (this.checkPasswords(this.passwordForm) === null && this.passwordForm.valid) {
            this.authService
                .savePassword({
                    OldPassword: null,
                    Guid: this.route.snapshot.queryParamMap.get('guid'),
                    Token: this.route.snapshot.queryParamMap.get('token'),
                    Password: this.passwordForm.controls['Password'].value,
                    ConfirmPassword: this.passwordForm.controls['ConfirmPassword'].value,
                })
                .subscribe(
                    (passwordOK) => {
                        this.isOK = passwordOK;
                    },
                    (passwordError) => {
                        this.snackBar.open(passwordError.error.ErrorTxt, null, {
                            verticalPosition: 'top',
                            horizontalPosition: 'center',
                            duration: 3000,
                        });
                    }
                );
        }
    }
}
