import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CategoryService, CategoryShared } from 'projects/library-shared/src/public-api';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'projects/roksh-desktop/src/app/services/home/home.service';

@Component({
    selector: 'app-category-list',
    templateUrl: './category-list.component.html',
    styleUrls: ['./category-list.component.scss'],
})
export class CategoryListComponent implements OnInit {
    constructor(
        private categoryService: CategoryService,
        private router: Router,
        private translateService: TranslateService,
        private homeService: HomeService,
        private cdr: ChangeDetectorRef
    ) {}

    categoryList: CategoryShared[] = [];
    activeCategory = '';
    activeChildCategory = '';

    viewInited = false;
    dummyArray = new Array(12);

    ngOnInit(): void {
        this.getCategoryList();

        this.router.events
            .pipe(filter((e: Event): e is RouterEvent => e instanceof RouterEvent))
            .subscribe((e: RouterEvent) => {
                this.translateService.get('router.offer').subscribe((text) => {
                    if (e instanceof NavigationEnd) {
                        const categoryFullUrl = e.url.split(text + '/').pop();
                        if (categoryFullUrl) {
                            this.activeCategory = categoryFullUrl.split('/')[0];
                        }
                        if (categoryFullUrl && categoryFullUrl.split('/')[1])
                            this.activeChildCategory = categoryFullUrl.split('/')[1];
                    }
                });
            });

        this.homeService.isCategoryMenuDisplayed.subscribe((isCategoryMenuDisplayed) => {
            if (isCategoryMenuDisplayed) {
                this.activeCategory = '';
            }
        });
    }

    scroll(id: string) {
        const scrollTo = document.getElementById(id);
        const active = document.getElementById('active-li');
        const container = document
            .getElementsByClassName('category-page-sidebar')
            .item(0) as HTMLElement;

        if (!this.viewInited) {
            this.viewInited = true;
            this.cdr.detectChanges(); // needed to avoid ElementChangedAfterExpressionChecked error
            if (container && scrollTo) {
                container.scrollTop = scrollTo.offsetTop - 220;
            }
        } else if (scrollTo.offsetTop > active.offsetTop) {
            container.scrollTop = scrollTo.offsetTop - active.offsetHeight - 110; // 155 a boltválasztó, 45 egy sor magassága
        } else {
            container.scrollTop = scrollTo.offsetTop - 155; // 155 a boltválasztó + zip magassága
        }
    }

    private getCategoryList(): void {
        this.categoryService.categories.subscribe((categoryList) => {
            this.categoryList = categoryList;
        });
    }
}
