import { Component, OnInit } from '@angular/core';
import {
    faAngleRight,
    faChevronDown,
    faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
    BasketService,
    CategoryService,
    ConfigureSessionDto,
    CountryCodes,
    DataLayerGa4Service,
    LogService,
    ServiceBaseService,
    SessionService,
    ShopAvailableDto,
} from 'projects/library-shared/src/public-api';
import { CreateDialogService } from 'projects/roksh-desktop/src/app/services/create-dialog.service';
import { HomeService } from 'projects/roksh-desktop/src/app/services/home/home.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-shop-select',
    templateUrl: './shop-select.component.html',
    styleUrls: ['./shop-select.component.scss'],
    providers: [LogService],
})
export class ShopSelectComponent implements OnInit {
    ShopHandlerForm: FormGroup;
    faChevronDown = faChevronDown;
    faChevronUp = faChevronUp;
    faAngleRight = faAngleRight;
    TotalPrice = 169000;

    availableShops: string[];
    availableShopWBasketInfos: ShopAvailableDto[] = [];

    userSelectedAvailableShops: string[] = [];
    onlyOneShopSelected: string[] = [];
    city: string;
    isValidZip: boolean;
    checked = true;
    isFirst = true;
    isDeliveryAvailableByPostCode: boolean;
    availableProviderNames: string[] = [];

    isPostCodeSetted = false;
    isShopChanging = false;
    isProviderSelectedForShopping = false;
    postcodeValue: string;

    siteImageUrl = '';

    CountryCodes = CountryCodes;
    countryCode = this.CountryCodes.hu;

    showText = '';
    showState = true;
    showAllText = '';
    showLessText = '';

    public get isAtCountryCode(): boolean {
        return this.countryCode === CountryCodes.at;
    }

    constructor(
        private sessionService: SessionService,
        private categoryService: CategoryService,
        private createDialogService: CreateDialogService,
        private basketService: BasketService,
        private formBuilder: FormBuilder,
        private homeService: HomeService,
        private DataLayerGa4Service: DataLayerGa4Service,
        private log: LogService,
        public baseService: ServiceBaseService,
        private translate: TranslateService
    ) {
        if (ServiceBaseService.isProviderOwnWebShop()) {
            const configureSessionDto = new ConfigureSessionDto({
                userSelectedShops: [ServiceBaseService.getOwnWebShopProviderCode()],
            });

            this.sessionService.configureUserSession(configureSessionDto).subscribe();
        }

        forkJoin([
            this.translate.get('dashboard.show-all'),
            this.translate.get('dashboard.show-less'),
        ]).subscribe(([all, less]: [string, string]) => {
            this.showText = all;
            this.showAllText = all;
            this.showLessText = less;
        });

        this.countryCode = ServiceBaseService.getCountryCode();
    }

    ngOnInit() {
        this.siteImageUrl = this.baseService.getSiteImageUrl();
        this.ShopHandlerForm = this.formBuilder.group({
            ShopId: [],
        });

        this.homeService.isSessionSet.subscribe((isSet) => {
            if (isSet) {
                this.availableProviderNames =
                    this.homeService.dataBaseAvailableProviderNames.value;

                this.sessionService.sessionSubject.subscribe((session) => {
                    this.city = session.city;
                    this.isDeliveryAvailableByPostCode =
                        session.isDeliveryAvailableByPostCode;
                    this.isValidZip = session.isValidZip;
                    this.postcodeValue = session.zipCode;
                    this.isDeliveryAvailableByPostCode =
                        session.isDeliveryAvailableByPostCode;

                    if (session.shopsAvailable && this.isValidZip) {
                        this.availableShops = session.shopsAvailable.map(
                            (shop) => shop.shopName
                        );
                        this.userSelectedAvailableShops = session.selectedShops;

                        if (this.userSelectedAvailableShops?.length === 1)
                            this.onlyOneShopSelected[0] =
                                this.userSelectedAvailableShops[0];

                        this.createavailableShopWBasketInfos();
                    } else {
                        this.availableShops = session.dataBaseAvailableProviderNames;
                    }
                    this.log.log(
                        'sessionSubject',
                        this.availableShops,
                        session.shopsAvailable
                    );
                });

                this.sessionService.isPostCodeSet.subscribe((result) => {
                    this.isPostCodeSetted = result;
                });
                this.sessionService.isProviderSelectedForShopping.subscribe((result) => {
                    this.isProviderSelectedForShopping = result;
                });
            }
        });

        this.DataLayerGa4Service.datalayerUniversalPush('ZIP', 'Open');
    }

    setShowMore(showState: boolean) {
        this.showState = showState;
        this.showText = showState ? this.showAllText : this.showLessText;
    }

    selectThisShop(shop: string) {
        if (!this.isValidZip) {
            this.showZipCodeDialog();

            return;
        }

        this.isShopChanging = true;
        this.isProviderSelectedForShopping = true;

        const configureSessionDto = new ConfigureSessionDto({
            userSelectedShops: [shop],
            redirectToDashboardNeeded: true,
        });

        this.categoryService.categories.next([]);
        this.sessionService.configureUserSession(configureSessionDto).subscribe();
        this.sessionService.isProviderSelectedForShopping.next(true);
        setTimeout(() => {
            this.isShopChanging = false;
        }, 3000);
    }

    showZipCodeDialog() {
        this.createDialogService.openAddressDialog();
    }

    private createavailableShopWBasketInfos() {
        this.basketService.masterBasketSubject.subscribe((masterBasket) => {
            this.availableShopWBasketInfos = [];

            for (const i in this.availableShops) {
                if (
                    masterBasket &&
                    masterBasket.basketList &&
                    masterBasket.basketList.find(
                        (basket) =>
                            basket.providerName.toLocaleLowerCase() ===
                            this.availableShops[i].toLocaleLowerCase()
                    ) &&
                    masterBasket.basketList.find(
                        (basket) =>
                            basket.providerName.toLocaleLowerCase() ===
                            this.availableShops[i].toLocaleLowerCase()
                    ).basketItemList
                ) {
                    this.availableShopWBasketInfos.push({
                        shopName: this.availableShops[i],
                        shopNameToShow: this.availableShops[i],
                        basketTotalItems: masterBasket.basketList.find(
                            (b) =>
                                b.providerName.toLocaleLowerCase() ===
                                this.availableShops[i].toLocaleLowerCase()
                        ).basketItemList.length,
                        basketTotalReplacementProduct: masterBasket.basketList.find(
                            (b) =>
                                b.providerName.toLocaleLowerCase() ===
                                this.availableShops[i].toLocaleLowerCase()
                        ).basketItemList.length,
                        basketTotalPrice: masterBasket.basketList.find(
                            (b) =>
                                b.providerName.toLocaleLowerCase() ===
                                this.availableShops[i].toLocaleLowerCase()
                        ).totalPrice,
                    });
                } else {
                    this.availableShopWBasketInfos.push({
                        shopName: this.availableShops[i],
                        shopNameToShow: this.availableShops[i],
                        basketTotalItems: -1,
                        basketTotalReplacementProduct: -1,
                        basketTotalPrice: -1,
                    });
                }
            }

            this.availableShopWBasketInfos = this.availableShopWBasketInfos.sort(
                (n1, n2) =>
                    this.onlyOneShopSelected.indexOf(n2.shopName) -
                    this.onlyOneShopSelected.indexOf(n1.shopName)
            );
        });
    }
}
