import { Component, Inject, OnInit } from '@angular/core';
import {
    BasketService,
    BillingAddressType,
    CheckoutOrderDto,
    CheckoutService,
    CouponStatus,
    CurrencyService,
    DATALAYER_SERVICE_IMPL,
    DataLayerGa4Service,
    FormatterService,
    Payment,
    ProviderSettingsRequestDTOShared,
    ServiceBaseService,
    SnackBarService,
    CheckoutStep,
    DiscountForUser,
} from 'projects/library-shared/src/public-api';
import { forkJoin, ReplaySubject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AbstractControl, FormGroup } from '@angular/forms';
import { faSpinner, faWineBottle } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-checkout-summary',
    templateUrl: './checkout-summary.component.html',
    styleUrls: ['./checkout-summary.component.scss'],
})
export class CheckoutSummaryComponent implements OnInit {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    checkoutForm: FormGroup;
    faSpinner = faSpinner;
    faWineBottle = faWineBottle;

    order: CheckoutOrderDto;
    paymentData: Payment;
    isCouponChecked = false;
    couponStatus = CouponStatus;
    couponMessage = '';
    couponName = '';
    couponDisplayName = '';
    providerGDPRText = '';
    providerGDPRLink = '';
    providerMarketingTextHeader = '';
    providerMarketingText = '';
    marketingTextExpanded = false;
    siteImageUrl = '';
    isCheckoutInProgress: boolean;
    currentRokshDiscount = 0;
    totalProviderDepositProducts = 0;
    selectedDiscount: DiscountForUser;
    discounts: DiscountForUser[];
    isLoadingFinished = false;
    selectedStep: number;

    get couponCodeControl(): AbstractControl {
        return this.checkoutForm.get('paymentForm.CouponCode');
    }

    get discountControl(): AbstractControl {
        return this.checkoutForm.get('discountForm.DiscountID');
    }

    get postCodeControl(): AbstractControl {
        return this.checkoutForm.get('addressForm.PostCode');
    }

    get providerMarketingControl(): AbstractControl {
        return this.checkoutForm.get('paymentForm.ProviderMarketingAccepted');
    }

    get tipControl(): number {
        return this.checkoutForm.get('tipForm.TipID')?.value
            ? this.getTipIdAmount(this.checkoutForm.get('tipForm.TipID').value)
            : +this.checkoutForm.get('tipForm.TipAmount').value;
    }

    get isDisableNextPayButton(): boolean {
        return this.selectedStep !== CheckoutStep.Summary;
    }

    get totalPriceFormat(): number | string {
        if (
            this.paymentData.totalDeliveryFee == this.paymentData.deliveryDiscountAmount
        ) {
            return this.formatterService.formatCurrency(
                this.order.TotalPrice +
                    this.paymentData.totalDeliveryFee -
                    this.paymentData.deliveryDiscountAmount -
                    this.paymentData.priceDiscountAmount -
                    this.order.RokshDiscountTotalPrice +
                    this.totalProviderDepositProducts +
                    this.order.SystemUsageFee +
                    this.tipControl
            );
        } else {
            return this.formatterService.formatCurrency(
                this.order.TotalPrice +
                    this.paymentData.deliveryFee -
                    this.paymentData.priceDiscountAmount -
                    this.order.RokshDiscountTotalPrice +
                    this.totalProviderDepositProducts +
                    this.order.SystemUsageFee +
                    this.tipControl
            );
        }
    }

    constructor(
        public checkoutService: CheckoutService,
        private snackBarService: SnackBarService,
        public baseService: ServiceBaseService,
        private formatterService: FormatterService,
        public currencyService: CurrencyService,
        private basketService: BasketService,
        private translate: TranslateService,
        @Inject(DATALAYER_SERVICE_IMPL) private _DataLayerGa4Service: DataLayerGa4Service
    ) {}

    ngOnInit(): void {
        this.siteImageUrl = this.baseService.getSiteImageUrl();

        this.checkoutService.formGroup$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((form) => (this.checkoutForm = form));

        this.checkoutService.paymentData$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((paymentData) => (this.paymentData = paymentData));

        this.checkoutService._billingAddressTypeSubject.subscribe(() => {
            if (this.isLoadingFinished && this.couponCodeControl.value) {
                this.isCouponChecked = true;
                this.checkCoupon();
            }
        });

        this.checkoutService.selectedStep$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((index) => {
                this.selectedStep = index;
            });

        this.basketService.currentRokshDiscountSubject.subscribe(
            (currentRokshDiscount) => {
                this.currentRokshDiscount = currentRokshDiscount;
            }
        );

        this.checkoutService.order$
            .pipe(
                takeUntil(this.destroyed$),
                switchMap((order) => {
                    if (order) {
                        this.order = order;

                        this.getTotalDepositAmount(this.order);
                        this.removeDiscount();
                        this.getDiscountsForUser(this.order);

                        if (this.paymentData.discountPercent) {
                            this.paymentData.priceDiscountAmount =
                                (this.order.TotalPrice *
                                    this.paymentData.discountPercent) /
                                100;
                        }

                        const gdprRequest: ProviderSettingsRequestDTOShared = {
                            providerID: order.providerID,
                            section: 'GDPR',
                            keys: ['Text', 'Link'],
                        };
                        const marketingRequest: ProviderSettingsRequestDTOShared = {
                            providerID: order.providerID,
                            section: 'Marketing',
                            keys: ['Text', 'FullText'],
                        };
                        return forkJoin({
                            gdprSettings:
                                this.checkoutService.getProviderSettings(gdprRequest),
                            marketingSettings:
                                this.checkoutService.getProviderSettings(
                                    marketingRequest
                                ),
                        });
                    }
                })
            )
            .subscribe((settings) => {
                if (settings.gdprSettings) {
                    this.providerGDPRText = settings.gdprSettings.filter(
                        (x) => x.Section == 'GDPR' && x.Key == 'Text'
                    )[0]?.Value;
                    this.providerGDPRLink = settings.gdprSettings.filter(
                        (x) => x.Section == 'GDPR' && x.Key == 'Link'
                    )[0]?.Value;
                }
                if (settings.marketingSettings) {
                    this.providerMarketingTextHeader = settings.marketingSettings.filter(
                        (x) => x.Section == 'Marketing' && x.Key == 'Text'
                    )[0]?.Value;
                    this.providerMarketingText = settings.marketingSettings.filter(
                        (x) => x.Section == 'Marketing' && x.Key == 'FullText'
                    )[0]?.Value;
                    if (this.providerMarketingTextHeader && this.order?.UserDto) {
                        this.checkoutService
                            .getUserMarketingPreference(
                                this.order?.UserDto?.UserID,
                                this.order?.providerID
                            )
                            .pipe(takeUntil(this.destroyed$))
                            .subscribe((consent) => {
                                this.providerMarketingControl.setValue(consent);
                            });
                    }
                }
            });

        this.checkoutService.isCheckoutInProgress$.subscribe(
            (checkoutInProgress) => (this.isCheckoutInProgress = checkoutInProgress)
        );
        this.isLoadingFinished = true;
    }

    getTotalDepositAmount(order: CheckoutOrderDto) {
        this.totalProviderDepositProducts = 0;

        order.BasketItemList.forEach((basketItem) => {
            if (basketItem.ProductProvider?.ProductProviderProviderDepositProductList) {
                basketItem.ProductProvider?.ProductProviderProviderDepositProductList.forEach(
                    (pppdp) => {
                        if (!pppdp?.ProviderDepositProduct?.DepositUnitType.IsOptional) {
                            this.totalProviderDepositProducts +=
                                basketItem.UnitValue * pppdp.ProviderDepositProduct.Price;
                        } else {
                            this.totalProviderDepositProducts +=
                                Math.floor(
                                    basketItem.UnitValue /
                                        pppdp.ProviderDepositProduct.MaxQuantity
                                ) * pppdp.ProviderDepositProduct.Price;
                        }
                    }
                );
            }
        });
    }

    getDiscountsForUser(order: CheckoutOrderDto): void {
        forkJoin([
            this.translate.get('common.delivery-free'),
            this.translate.get('common.currency'),
            this.translate.get('common.discount-text'),
        ]).subscribe(
            ([deliveryFee, currency, discountText]: [string, string, string]) => {
                this.discounts = order.Discounts.map((discount) => {
                    if (discount.DiscountTypeID === CouponStatus.TOTALPRICEDISCOUNT) {
                        if (discount.DiscountAmount) {
                            discount.DiscountTitle = [
                                discount.DiscountAmount,
                                currency,
                                discountText,
                            ]
                                .filter(Boolean)
                                .join(' ');
                        }
                        if (discount.DiscountPercent) {
                            discount.DiscountTitle =
                                discount.DiscountPercent + '%' + ' ' + discountText;
                        }
                    }
                    if (discount.DiscountTypeID === CouponStatus.FREEDELIVERY) {
                        discount.DiscountTitle = deliveryFee;
                    }
                    return discount;
                });
            }
        );
    }

    removeDiscount(): void {
        this.setDiscountForm();
        this.setCouponForm();
        this.couponMessage = '';
        this.paymentData.discountPercent = undefined;
        this.paymentData.deliveryDiscountAmount = 0;
        this.paymentData.priceDiscountAmount = 0;
        this.paymentData.couponStatus = CouponStatus.NOCOUPON;
        this.checkoutService.setPaymentDataSource(this.paymentData);
    }

    private setCouponForm(): void {
        if (this.couponCodeControl.value) {
            this.checkoutForm.get('paymentForm.CouponCode').setValue(null);
            this.isCouponChecked = false;
        }
    }

    private setDiscountForm(): void {
        if (this.discountControl.value)
            this.checkoutForm.get('discountForm.DiscountID').setValue(null);
        this.selectedDiscount = new DiscountForUser();
    }

    onDiscountChanged(): void {
        this.setCouponForm();
        this.checkoutForm
            .get('discountForm.DiscountID')
            .setValue(this.selectedDiscount.DiscountID);
        this.checkCoupon();
    }

    checkCoupon() {
        if (this.couponCodeControl.value || this.discountControl.value) {
            const diffBillingAddressSelector = this.checkoutForm.get(
                'addressForm.DifferentBillingAddressSelector'
            );

            if (this.couponCodeControl.value) {
                this.isCouponChecked = true;
                this.setDiscountForm();
            }

            const couponCode = this.couponCodeControl.value
                ? this.couponCodeControl.value
                : this.discountControl.value;
            this.checkoutService
                .validateCoupon(couponCode, diffBillingAddressSelector.value)
                .subscribe((discount) => {
                    if (!discount) {
                        this.translate
                            .get('checkout.invalid-coupon')

                            .subscribe((text) => {
                                this.snackBarService.openSnackBar(text);
                                this.paymentData.couponStatus = CouponStatus.BADCOUPON;
                                this.couponMessage = text;
                                this.paymentData.discountPercent = undefined;
                                this.paymentData.deliveryDiscountAmount = 0;
                                this.paymentData.priceDiscountAmount = 0;
                                this.checkoutService.setPaymentDataSource(
                                    this.paymentData
                                );
                                return;
                            });
                    }

                    if (
                        discount.MinimumBasketValue >
                        this.order.TotalPrice -
                            this.paymentData.deliveryDiscountAmount -
                            this.paymentData.priceDiscountAmount -
                            this.order.RokshDiscountTotalPrice
                    ) {
                        this.translate
                            .get('checkout.coupon-minimum-value-error', {
                                minimumValue: this.formatterService
                                    .formatCurrency(discount.MinimumBasketValue)
                                    .toString(),
                            })
                            .subscribe((text) => {
                                this.snackBarService.openSnackBar(text);
                                this.paymentData.couponStatus = CouponStatus.BADCOUPON;
                                this.couponMessage = text;
                                this.paymentData.discountPercent = undefined;
                                this.paymentData.deliveryDiscountAmount = 0;
                                this.paymentData.priceDiscountAmount = 0;
                                this.checkoutService.setPaymentDataSource(
                                    this.paymentData
                                );
                            });
                        return;
                    }

                    if (discount.DiscountTypeID == CouponStatus.FREEDELIVERY) {
                        this.translate.get('checkout.free-delivery').subscribe((text) => {
                            this.paymentData.couponStatus = CouponStatus.FREEDELIVERY;
                            this.paymentData.discountPercent = undefined;
                            this.paymentData.priceDiscountAmount = 0;
                            this.paymentData.deliveryDiscountAmount =
                                this.paymentData.totalDeliveryFee;
                            this.couponName = discount.DiscountName;
                            this.couponDisplayName = discount.DiscountDisplayName;
                            this.couponMessage = text;
                            this.checkoutService.setPaymentDataSource(this.paymentData);
                            return;
                        });
                    }

                    if (discount.DiscountTypeID == CouponStatus.TOTALPRICEDISCOUNT) {
                        if (
                            diffBillingAddressSelector.value ==
                            BillingAddressType.DifferentBillingAddress
                        ) {
                            this.translate
                                .get('user-message.no-coupon-for-company')
                                .subscribe((text) => {
                                    this.snackBarService.openErrorSnackBar(text);
                                    this.paymentData.couponStatus =
                                        CouponStatus.BADCOUPON;
                                    this.couponMessage = text;
                                    this.paymentData.discountPercent = undefined;
                                    this.paymentData.deliveryDiscountAmount = 0;
                                    this.paymentData.priceDiscountAmount = 0;
                                    this.checkoutService.setPaymentDataSource(
                                        this.paymentData
                                    );
                                    return;
                                });
                        } else if (discount.DiscountPercent || discount.DiscountAmount) {
                            this.translate
                                .get('checkout.total-price-discount')
                                .subscribe((text) => {
                                    this.paymentData.couponStatus =
                                        CouponStatus.TOTALPRICEDISCOUNT;

                                    let discountAmount = 0;

                                    if (discount.DiscountPercent) {
                                        this.paymentData.discountPercent =
                                            discount.DiscountPercent;
                                        discountAmount =
                                            (this.order.TotalPrice *
                                                discount.DiscountPercent) /
                                            100;
                                    } else if (discount.DiscountAmount) {
                                        discountAmount = discount.DiscountAmount;
                                    }

                                    discountAmount =
                                        this.currencyService.roundAmountByCurrentCountryCode(
                                            discountAmount
                                        );

                                    this.paymentData.deliveryDiscountAmount = 0;
                                    this.paymentData.priceDiscountAmount =
                                        discountAmount < this.order.TotalPrice
                                            ? discountAmount
                                            : this.order.TotalPrice;
                                    this.couponName = discount.DiscountName;
                                    this.couponDisplayName = discount.DiscountDisplayName;
                                    this.couponMessage = text;
                                    this.checkoutService.setPaymentDataSource(
                                        this.paymentData
                                    );
                                    return;
                                });
                        }
                    }
                });
        } else {
            this.translate
                .get('checkout.add-coupon-to-validate')
                .subscribe((text) => this.snackBarService.openSnackBar(text));
        }
    }

    validateAddressAndGoToPayment(): void {
        const postCode = this.postCodeControl;
        const city = this.checkoutForm.get('addressForm.City');
        const streetName = this.checkoutForm.get('addressForm.StreetName');
        const houseNumber = this.checkoutForm.get('addressForm.HouseNumber');
        if (
            postCode.invalid ||
            streetName.invalid ||
            houseNumber.invalid ||
            city.invalid
        ) {
            this.translate
                .get('checkout.address-required')
                .subscribe((text) => this.snackBarService.openSnackBar(text));
            this.checkoutService.setCheckoutInProgress(false);
        } else {
            this.validateAddressAndCheckout(
                postCode.value,
                city.value,
                streetName.value,
                houseNumber.value
            );
        }
    }

    getTipIdAmount(tipID: string): number {
        return this.order.Tips.find((tip) => tip.RokshTipID === tipID)?.Amount;
    }

    validateAddressAndCheckout(
        postCode: string,
        city: string,
        streetName: string,
        houseNumber: string
    ): void {
        this.checkoutService
            .validateAddress(postCode, city, streetName, houseNumber)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((addressIsValid) => {
                if (addressIsValid) {
                    this.checkoutService.checkout();
                } else {
                    this.checkoutService.setCheckoutInProgress(false);
                }
            });
    }

    checkPaymentType(): void {
        const paymentType = this.checkoutForm.get('paymentForm.PaymentType').value;
        this.checkoutService
            .getPaymentProvidersByPostCode(this.postCodeControl.value)
            .subscribe((value) => {
                if (!value.PaymentProviders.includes(paymentType)) {
                    this.translate
                        .get('checkout.invalid-payment-type')
                        .subscribe((text) => {
                            this.snackBarService.openSnackBar(text);
                            this.checkoutService.setCheckoutInProgress(false);
                        });
                }
            });
    }

    checkout(): void {
        if (!this.isCheckoutInProgress) {
            this.checkoutService.setCheckoutInProgress(true);
            this.checkPaymentType();
            this.validateAddressAndGoToPayment();
        }
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
