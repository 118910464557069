import { AppEnvironment } from '../../types/app-environment';

export class AppSettings {
    public static settings = {
        default: {
            mediaServiceUrl: 'https://productimagesmaster.azureedge.net/',
            baseUrl: 'https://shopservice.roksh.com/',
            signalr: 'https://roksh-backend.roksh.com',
        },
        localhost: {
            baseUrl: 'https://localhost:5001/',
            signalr: 'http://localhost:5009',
        },
        master: {
            baseUrl: 'https://shopservice-master.roksh.com/',
            signalr: 'https://roksh-backend-master.roksh.com',
        },
        staging: {
            baseUrl: 'https://shopservice-staging.roksh.com/',
            signalr: 'https://roksh-backend-staging.roksh.com',
        },
        providertest: {
            mediaServiceUrl:
                'https://weshopapp-roksh-media-master.azurewebsites.net/interface',
            baseUrl: 'https://provider-roksh-shopservice.azurewebsites.net/',
        },
        deliverytest: {
            baseUrl: 'https://roksh-delivery-test-shopservice.azurewebsites.net/',
        },
        atmaster: {
            baseUrl: 'https://shopservice-master.roksh.at/',
        },
        atstaging: {
            baseUrl: 'https://shopservice-staging.roksh.at/',
            signalr: 'https://roksh-backend-staging.roksh.com',
        },
        atproduction: {
            baseUrl: 'https://shopservice-master.roksh.at/',
            signalr: 'https://roksh-backend.roksh.com',
        },
        hugojajaprod: {
            mediaServiceUrl: 'https://productimagesmaster.azureedge.net/',
            baseUrl: 'https://shopservice.gojaja.com/',
            signalr: 'https://roksh-backend.roksh.com',
        },
    };

    public static get(key: string): string {
        const envSettings =
            AppSettings.settings[
                AppEnvironment[AppSettings.getEnvironment()].toLowerCase()
            ];
        if (envSettings !== undefined && envSettings[key] !== undefined) {
            return envSettings[key];
        } else if (AppSettings.settings.default[key] !== undefined) {
            return AppSettings.settings.default[key];
        }
        return null;
    }

    public static getEnvironment(): AppEnvironment {
        let env;
        switch (true) {
            case window.location.href.includes('localhost'):
                env = AppEnvironment.Localhost;
                break;
            case window.location.href.includes('at-') &&
                window.location.href.includes('master'):
                env = AppEnvironment.AtMaster;
                break;
            case window.location.href.includes('at-') &&
                window.location.href.includes('staging'):
                env = AppEnvironment.AtStaging;
                break;
            case window.location.href.includes('roksh.at'):
                env = AppEnvironment.AtProduction;
                break;
            case window.location.href.includes('master'):
                env = AppEnvironment.Master;
                break;
            case window.location.href.includes('staging'):
                env = AppEnvironment.Staging;
                break;
            case window.location.href.includes('provider-roksh'):
                env = AppEnvironment.ProviderTest;
                break;
            case window.location.href.includes('delivery-test'):
                env = AppEnvironment.DeliveryTest;
                break;
            case window.location.href.includes('gojaja'):
                env = AppEnvironment.HuGoJajaProd;
                console.log('Hugo Jaja Prod');
                break;
            default:
                env = AppEnvironment.Production;
        }
        console.log('environment' + env);
        return env;
    }
}
