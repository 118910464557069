import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    AuthenticationService,
    BillingAddressType,
    CheckoutService,
    CheckoutStep,
    DATALAYER_SERVICE_IMPL,
    DataLayerGa4Service,
    GA4EventType,
    IOrderService,
    ORDER_SERVICE_IMPL,
    ServiceBaseService,
    SnackBarService,
    UserShared,
} from 'projects/library-shared/src/public-api';
import { TranslateService } from '@ngx-translate/core';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-checkout-address',
    templateUrl: './checkout-address.component.html',
    styleUrls: ['./checkout-address.component.scss'],
})
export class CheckoutAddressComponent implements OnInit {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    checkoutForm: FormGroup;
    selectedIndex: number;
    isSubmitted = false;
    addressStepIndex = CheckoutStep.Address;
    addressValidated = false;
    sameBillingAddress = true;
    differentBillingAddress = false;
    VATInvoiceAvailable = true;
    deliveryAvailablePostcodes: string[] = [];

    preferredCountries: CountryISO[] =
        ServiceBaseService.countrycode === 'hu'
            ? [CountryISO.Hungary, CountryISO.Austria]
            : [CountryISO.Austria, CountryISO.Hungary];
    phoneForm = new FormGroup({
        phone: new FormControl(undefined, [Validators.required, Validators.minLength(7)]),
    });
    SearchCountryField = SearchCountryField;
    PhoneNumberFormat = PhoneNumberFormat;

    vatInvoiceAvailable = false;
    maxBirthdate: Date = new Date();

    zipIsValid$: Observable<boolean>;

    public get fullNameProvided(): boolean {
        return (
            (this.firstNameControl.value !== '' && !this.firstNameControl.invalid) ||
            (this.lastNameControl.value !== '' && !this.lastNameControl.invalid)
        );
    }

    public get emailProvided(): boolean {
        return this.emailControl.value !== '' && !this.emailControl.invalid;
    }

    public get phoneNumberProvided(): boolean {
        return this.phoneNumberControl.value !== '' && !this.phoneNumberControl.invalid;
    }

    public get birthdateProvided(): boolean {
        return this.birthdateControl.valid;
    }

    get emailControl(): AbstractControl {
        return this.checkoutForm.get('addressForm.Email');
    }

    get firstNameControl(): AbstractControl {
        return this.checkoutForm.get('addressForm.FirstName');
    }

    get lastNameControl(): AbstractControl {
        return this.checkoutForm.get('addressForm.LastName');
    }

    get taxNumberControl(): AbstractControl {
        return this.checkoutForm.get('addressForm.TaxNumber');
    }

    get fullNameControl(): AbstractControl {
        return this.checkoutForm.get('addressForm.FullName');
    }

    get billingNameControl(): AbstractControl {
        return this.checkoutForm.get('addressForm.BillingName');
    }

    get phoneNumberControl(): AbstractControl {
        return this.checkoutForm.get('addressForm.Phone');
    }

    get birthdateControl(): AbstractControl {
        return this.checkoutForm.get('addressForm.Birthdate');
    }

    public get addressStepIsExpanded(): boolean {
        return this.selectedIndex === this.addressStepIndex;
    }

    public get addressStepIsActive(): boolean {
        return this.selectedIndex > this.addressStepIndex;
    }

    public get postCodeProvided(): boolean {
        return (
            this.postCodeControl != null &&
            this.postCodeControl.value !== '' &&
            !this.postCodeControl.invalid
        );
    }

    public get addressProvided(): boolean {
        const city = this.checkoutForm.get('addressForm.City');
        const streetName = this.checkoutForm.get('addressForm.StreetName');
        const houseNumber = this.checkoutForm.get('addressForm.HouseNumber');
        return (
            city != null &&
            streetName != null &&
            houseNumber != null &&
            city.value !== '' &&
            streetName.value !== '' &&
            houseNumber.value !== '' &&
            !city.invalid &&
            !streetName.invalid &&
            !houseNumber.invalid
        );
    }

    get postCodeControl(): AbstractControl {
        return this.checkoutForm.get('addressForm.PostCode');
    }

    get billingAddressPostCodeControl(): AbstractControl {
        return this.checkoutForm.get('addressForm.BillingAddressPostCode');
    }

    get billingAddressControl(): AbstractControl {
        return this.checkoutForm.get('addressForm.BillingAddress');
    }

    get differentBillingAddressSelector(): AbstractControl {
        return this.checkoutForm.get('addressForm.DifferentBillingAddressSelector');
    }

    get longitudeControl(): AbstractControl {
        return this.checkoutForm.get('paymentForm.Longitude');
    }

    get latitudeControl(): AbstractControl {
        return this.checkoutForm.get('paymentForm.Latitude');
    }

    constructor(
        private checkoutService: CheckoutService,
        private snackBarService: SnackBarService,
        @Inject(DATALAYER_SERVICE_IMPL) private _DataLayerGa4Service: DataLayerGa4Service,
        @Inject(ORDER_SERVICE_IMPL) private _orderService: IOrderService,
        private datePipe: DatePipe,
        private authService: AuthenticationService,
        private snackBar: MatSnackBar,

        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.checkoutService.formGroup$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((form) => (this.checkoutForm = form));

        this.checkoutService.selectedStep$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((index) => (this.selectedIndex = index));

        this.checkoutService.isSubmitted$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((isSubmitted) => (this.isSubmitted = isSubmitted));

        this.checkoutService.address$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((address) => {
                if (address) {
                    this.postCodeControl.setValue(address.postCode);
                    this.checkoutForm.get('addressForm.City').setValue(address.city);
                    this.checkoutForm
                        .get('addressForm.StreetName')
                        .setValue(address.streetName);
                    this.checkoutForm
                        .get('addressForm.HouseNumber')
                        .setValue(address.houseNumber);
                }
            });

        this.checkoutService.order$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((order) => {
                if (order) {
                    this.deliveryAvailablePostcodes = order.deliveryAvailablePostcodes;
                    this.vatInvoiceAvailable = order.vatInvoiceAvailable;
                }
            });

        this.zipIsValid$ = this.checkoutService.zipIsValid$;

        this.checkoutService.user$.subscribe((userDto) => {
            if (userDto) {
                this.emailControl.setValue(userDto?.Email);
                this.firstNameControl.setValue(userDto?.FirstName);
                this.lastNameControl.setValue(userDto?.LastName);
                this.fullNameControl.setValue(
                    userDto?.FirstName + ' ' + userDto?.LastName
                );
                this.fullNameControl.setErrors(null);
                this.birthdateControl.setValue(userDto?.BirthDate);
                this.phoneNumberControl.setValue(userDto?.MobilePhone);
                this.checkoutService.setFormGroupSource(this.checkoutForm);
            }
        });

        this.maxBirthdate.setFullYear(this.maxBirthdate.getFullYear() - 18);

        if (this.checkoutForm)
            this.phoneForm.controls.phone.setValue(this.phoneNumberControl.value);
    }

    onSaveProfile(): void {
        const newUser: UserShared = new UserShared();
        const user = this.authService.getLoggedInUser();
        newUser.UserID = user.UserID;
        newUser.BirthDate = new Date(
            this.datePipe.transform(this.birthdateControl.value, 'yyyy-MM-dd')
        );
        newUser.MobilePhone = this.phoneNumberControl.value;
        newUser.FirstName = this.firstNameControl.value;
        newUser.LastName = this.lastNameControl.value;
        newUser.Email = user.Email;

        this.authService.saveProfile(newUser).subscribe(() => {
            this.translate.get('profile.modified-data').subscribe((text) =>
                this.snackBar.open(text, null, {
                    verticalPosition: 'top',
                    horizontalPosition: 'center',
                    duration: 3000,
                })
            );
        });
    }

    onChangeName(event: any) {
        const str = event.target.value;
        this.firstNameControl.setValue(str.substr(0, str.indexOf(' ')));
        this.lastNameControl.setValue(str.substr(str.indexOf(' ') + 1));
        if (this.firstNameControl.value == '' || this.lastNameControl.value == '') {
            this.fullNameControl.setErrors({ incorrect: true });
        } else {
            this.fullNameControl.setErrors(null);
        }
        this.checkoutService.setFormGroupSource(this.checkoutForm);
    }

    setStep(index: number): void {
        this.checkoutService.setSelectedStepSource(index);
        const scrWidth = window.innerWidth;

        const summaryPanel: HTMLElement = <HTMLElement>(
            document.getElementById('summaryPanel')
        );
        summaryPanel.classList.remove('in-focus');
        if (scrWidth < 992) {
            window.scroll(0, 0);
        } else {
            if (index != 0) document.getElementById('summaryPanel').scrollIntoView();
        }
    }

    setZipCode(event: any): void {
        const typedZip = event.target.value;
        this.checkoutService.resetAddress(typedZip, null, null, null);
        if (typedZip.length === 4) {
            this.checkoutService.queryDeliveryTimesAndPaymentMethods(typedZip);
        }
    }

    invalidateAddress(): void {
        this.addressValidated = false;
        this.resetLatLong();
    }

    nextStep(): void {
        const postCode = this.postCodeControl;
        const city = this.checkoutForm.get('addressForm.City');
        const streetName = this.checkoutForm.get('addressForm.StreetName');
        const houseNumber = this.checkoutForm.get('addressForm.HouseNumber');
        if (
            postCode.invalid ||
            city.invalid ||
            streetName.invalid ||
            this.phoneForm.controls.phone.errors ||
            houseNumber.invalid
        ) {
            this.translate
                .get('common.value-required')
                .subscribe((text) => this.snackBarService.openErrorSnackBar(text));

            return;
        } else {
            this.getGoogleResult(
                postCode.value,
                city.value,
                streetName.value,
                houseNumber.value
            );
        }

        if (this.firstNameControl.invalid || this.lastNameControl.invalid) {
            const inputField: HTMLElement = <HTMLElement>document.getElementById('fname');
            inputField.focus();
            return;
        }

        if (this.emailControl.invalid) {
            const inputField: HTMLElement = <HTMLElement>document.getElementById('mail');
            inputField.focus();
            return;
        }

        if (this.phoneNumberControl.invalid) {
            const inputField: HTMLElement = <HTMLElement>document.getElementById('tel');
            inputField.focus();
            return;
        }

        if (this.birthdateControl.invalid) {
            const inputField: HTMLElement = <HTMLElement>(
                document.getElementById('birthdate')
            );
            inputField.focus();
            return;
        }

        if (this.differentBillingAddress) {
            if (this.billingNameControl.invalid) {
                const inputField: HTMLElement = <HTMLElement>(
                    document.getElementById('billing-name')
                );
                inputField.focus();
                return;
            }

            if (this.billingAddressPostCodeControl.invalid) {
                const inputField: HTMLElement = <HTMLElement>(
                    document.getElementById('billing-address-post-code')
                );
                inputField.focus();
                return;
            }

            if (this.billingAddressControl.invalid) {
                const inputField: HTMLElement = <HTMLElement>(
                    document.getElementById('billing-address')
                );
                inputField.focus();
                return;
            }

            if (this.taxNumberControl.invalid) {
                const inputField: HTMLElement = <HTMLElement>(
                    document.getElementById('tax-number')
                );
                inputField.focus();
                return;
            }
        }

        this._DataLayerGa4Service.addAddress({
            postCode: postCode.value,
            streetName: streetName.value,
            city: city.value,
            houseNumber: houseNumber.value,
        });
        if (this.checkoutForm.get('addressForm.Comment')) {
            this._DataLayerGa4Service.datalayerUniversalPush(
                GA4EventType.personal_info,
                GA4EventType.personal_info
            );
        }

        this._DataLayerGa4Service.datalayerUniversalPush('Checkout', 'ToDeliveryTime');

        if (
            this.fullNameControl.touched ||
            this.phoneNumberControl.touched ||
            this.birthdateControl.touched
        ) {
            this.onSaveProfile();
        }
    }

    onChangeBillingAddressCheckBox(event: any): void {
        const checkboxValue = event.target.value;

        this.sameBillingAddress = checkboxValue === BillingAddressType.SameBillingAddress;
        this.differentBillingAddress =
            checkboxValue === BillingAddressType.DifferentBillingAddress;
        this.checkoutService.setBillingAddressTypeSubject(checkboxValue);

        if (this.sameBillingAddress) {
            this.checkoutForm.get('addressForm.BillingName').clearValidators();
            this.checkoutForm.get('addressForm.BillingName').updateValueAndValidity();

            this.checkoutForm.get('addressForm.BillingAddressPostCode').clearValidators();
            this.checkoutForm
                .get('addressForm.BillingAddressPostCode')
                .updateValueAndValidity();

            this.checkoutForm.get('addressForm.BillingAddress').clearValidators();
            this.checkoutForm.get('addressForm.BillingAddress').updateValueAndValidity();

            this.checkoutForm.get('addressForm.TaxNumber').clearValidators();
            this.checkoutForm.get('addressForm.TaxNumber').updateValueAndValidity();
        }

        if (this.differentBillingAddress) {
            this.checkoutForm
                .get('addressForm.BillingName')
                .setValidators([Validators.required]);
            this.checkoutForm.get('addressForm.BillingName').updateValueAndValidity();

            this.checkoutForm
                .get('addressForm.BillingAddressPostCode')
                .setValidators([
                    Validators.required,
                    Validators.minLength(4),
                    Validators.maxLength(4),
                    Validators.pattern(/^[0-9]*$/),
                ]);
            this.checkoutForm
                .get('addressForm.BillingAddressPostCode')
                .updateValueAndValidity();

            this.checkoutForm
                .get('addressForm.BillingAddress')
                .setValidators([Validators.required, Validators.minLength(2)]);
            this.checkoutForm.get('addressForm.BillingAddress').updateValueAndValidity();

            this.checkoutForm
                .get('addressForm.TaxNumber')
                .setValidators([
                    Validators.required,
                    Validators.pattern(/^(\d{8}-\d{1}-\d{2})$/),
                ]);
            this.checkoutForm.get('addressForm.TaxNumber').updateValueAndValidity();
        }
    }

    onChangeBillingAddressPostCode(event: any): void {
        const postCode = event.target.value;
        this._orderService
            .validateBillingAddressPostCode(postCode)
            .subscribe((isValid) => {
                if (!isValid) {
                    this.translate
                        .get('messages.invalid-billing-address-post-code')
                        .subscribe((text) => this.snackBarService.openSnackBar(text));

                    const inputField: HTMLElement = <HTMLElement>(
                        document.getElementById('billing-address-post-code')
                    );
                    inputField.focus();
                    this.billingAddressPostCodeControl.setErrors({ incorrect: true });
                } else {
                    this.billingAddressPostCodeControl.setErrors(null);
                }
            });
    }

    getGoogleResult(
        postCode: string,
        city: string,
        streetName: string,
        houseNumber: string
    ): void {
        if (
            postCode.length === 4 &&
            this.deliveryAvailablePostcodes.indexOf(postCode) == -1
        ) {
            this.checkoutService.setAvailableTimeSlotsDtoSource(null);
            this.translate
                .get('messages.we-dont-deliver-for-this-zip')
                .subscribe((text) => this.snackBarService.openSnackBar(text));

            this.checkoutService.setZipIsValidSource(false);
            this.addressValidated = false;
        } else {
            this.checkoutService
                .validateAddress(postCode, city, streetName, houseNumber)
                .subscribe((addressIsvalid) => {
                    if (addressIsvalid) {
                        this.addressValidated = true;
                        this.checkoutService.setAddressSource({
                            postCode: postCode,
                            city: city,
                            streetName: streetName,
                            houseNumber: houseNumber,
                        });
                        this.forwardToNextStep();
                    } else {
                        this.addressValidated = false;
                    }
                });
        }
    }

    resetLatLong() {
        this.checkoutService.setLatLong();
    }

    forwardToNextStep(): void {
        if (this.postCodeControl.invalid) {
            const inputField: HTMLElement = <HTMLElement>document.getElementById('irsz');
            inputField.focus();
            return;
        }
        const streetName = this.checkoutForm.get('addressForm.StreetName');
        const houseNumber = this.checkoutForm.get('addressForm.HouseNumber');
        if (
            streetName.invalid ||
            houseNumber.invalid ||
            this.longitudeControl.invalid ||
            this.latitudeControl.invalid
        ) {
            const inputField: HTMLElement = <HTMLElement>document.getElementById('addr');
            inputField.focus();
            return;
        }

        if (this.firstNameControl.invalid || this.lastNameControl.invalid) {
            const inputField: HTMLElement = <HTMLElement>document.getElementById('fname');
            inputField.focus();
            return;
        }

        if (this.emailControl.invalid) {
            const inputField: HTMLElement = <HTMLElement>document.getElementById('mail');
            inputField.focus();
            return;
        }

        if (this.phoneNumberControl.invalid) {
            const inputField: HTMLElement = <HTMLElement>document.getElementById('tel');
            inputField.focus();
            return;
        }

        if (this.birthdateControl.invalid) {
            const inputField: HTMLElement = <HTMLElement>(
                document.getElementById('birthdate')
            );
            inputField.focus();
            return;
        }

        if (this.differentBillingAddress) {
            if (this.billingNameControl.invalid) {
                const inputField: HTMLElement = <HTMLElement>(
                    document.getElementById('billing-name')
                );
                inputField.focus();
                return;
            }

            this._orderService
                .validateBillingAddressPostCode(this.billingAddressPostCodeControl.value)
                .subscribe((isValid) => {
                    if (!isValid) {
                        this.translate
                            .get('messages.invalid-billing-address-post-code')
                            .subscribe((text) => this.snackBarService.openSnackBar(text));

                        const inputField: HTMLElement = <HTMLElement>(
                            document.getElementById('billing-address-post-code')
                        );
                        inputField.focus();
                        return;
                    }
                });

            if (this.billingAddressPostCodeControl.invalid) {
                const inputField: HTMLElement = <HTMLElement>(
                    document.getElementById('billing-address-post-code')
                );
                inputField.focus();
                return;
            }

            if (this.billingAddressControl.invalid) {
                const inputField: HTMLElement = <HTMLElement>(
                    document.getElementById('billing-address')
                );
                inputField.focus();
                return;
            }

            if (this.taxNumberControl.invalid) {
                const inputField: HTMLElement = <HTMLElement>(
                    document.getElementById('tax-number')
                );
                inputField.focus();
                return;
            }
        }

        this.setStep(CheckoutStep.DeliveryTime);
    }

    public addressData(): string {
        const city = this.checkoutForm.get('addressForm.City').value;
        const streetName = this.checkoutForm.get('addressForm.StreetName').value;
        const houseNumber = this.checkoutForm.get('addressForm.HouseNumber').value;

        return [city, streetName, houseNumber].filter(Boolean).join(' ');
    }

    onPhoneChanges(): void {
        const phone = this.phoneForm.controls.phone.value;
        if (phone?.number && phone.number !== this.phoneNumberControl.value) {
            this.phoneNumberControl.setValue(phone.number);
            this.checkoutForm.markAsDirty();
            this.phoneNumberControl.markAsTouched();
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
