import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
    FormatterService,
    InstantTimeslotDTO,
    NotAvailableTextOptions,
    TimeSlotService,
} from 'projects/library-shared/src/public-api';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';

@Component({
    selector: 'app-express-timeslot',
    templateUrl: './express-timeslot.component.html',
    styleUrls: ['./express-timeslot.component.scss'],
})
export class ExpressTimeslotComponent implements OnChanges {
    @Input() instantWorkTime: InstantTimeslotDTO;
    @Input() selectionAllowed: boolean;
    @Input() selectedExpressTimeSlotInterval: number;
    @Input() configuredInterval: number;
    @Input() discountPercentage: number;
    @Input() discountedDeliveryFee: number;
    @Input() isOverWeightLimit: boolean;
    @Input() isUnderPriceLimit: boolean;
    @Input() areaHasInstantProvider = true;

    @Output() onInstantDeliverySelected: EventEmitter<number> = new EventEmitter();
    text$: Observable<string>;

    constructor(
        private translate: TranslateService,
        public formatterService: FormatterService,
        private timeSlotService: TimeSlotService
    ) {}

    get selectable(): boolean {
        return this.selectionAllowed && this.available;
    }

    get available(): boolean {
        return this.instantWorkTime != null;
    }

    get expressPriceText(): string {
        let expressPriceText = '';
        forkJoin([
            this.translate.get('common.currency'),
            this.translate.get('time-slot.full-slot'),
        ]).subscribe(([currency, close]: [string, string]) => {
            expressPriceText = this.instantWorkTime
                ? this.formatterService.formatCurrency(this.instantWorkTime.deliveryFee) +
                  ' ' +
                  currency
                : this.areaHasInstantProvider
                ? close
                : '';
        });

        return expressPriceText;
    }

    get selected(): boolean {
        return this.instantWorkTime
            ? this.selectedExpressTimeSlotInterval === this.instantWorkTime?.interval
            : false;
    }

    get overWeight(): boolean {
        return this.isOverWeightLimit && this.instantWorkTime?.maxBasketWeightRestriction;
    }

    get tooLowPrice(): boolean {
        return (
            this.isUnderPriceLimit &&
            this.available &&
            this.instantWorkTime?.minBasketValueRestriction
        );
    }

    get selectionBlocked(): boolean {
        return this.overWeight || this.tooLowPrice || !this.selectionAllowed;
    }

    notAvailableText(): void {
        const options: NotAvailableTextOptions = {
            instantWorkTime: this.instantWorkTime,
            selectionAllowed: this.selectionAllowed,
            selectedExpressTimeSlotInterval: this.selectedExpressTimeSlotInterval,
            configuredInterval: this.configuredInterval,
            discountPercentage: this.discountPercentage,
            discountedDeliveryFee: this.discountedDeliveryFee,
            isOverWeightLimit: this.isOverWeightLimit,
            isUnderPriceLimit: this.isUnderPriceLimit,
            areaHasInstantProvider: this.areaHasInstantProvider,
        };

        this.text$ = this.timeSlotService.getNotAvailableText(options);
    }

    public selectInstantDelivery(): void {
        if (!this.overWeight && !this.tooLowPrice) {
            this.onInstantDeliverySelected.emit(this.instantWorkTime?.interval);
        }
    }

    ngOnChanges(): void {
        this.notAvailableText();
    }
}
