import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import {
    BasketService,
    ContainerIds,
    HtmlMarketingElementsExtendsDto,
    ISeoService,
    LogService,
    MarketingService,
    ProductDto,
    ProductListService,
    SEO_SERVICE_IMPL,
    ServiceBaseService,
    SessionService,
} from 'projects/library-shared/src/public-api';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from '../../services/home/home.service';

@Component({
    selector: 'app-product-details',
    templateUrl: './product-details.component.html',
    styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
    faAngleRight = faAngleRight;
    htmlMarketingElementDto$: Observable<HtmlMarketingElementsExtendsDto>;
    categ1?: string;
    seoName?: string;
    prod: ProductDto;
    minPrice: any;
    menuOpen = false;
    openMenuSubjectSub: Subscription;
    availableShops: any;
    siteImageUrl = '';
    shop?: string;

    protected readonly ContainerIds = ContainerIds;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private title: Title,
        private meta: Meta,
        private basketService: BasketService,
        private productListService: ProductListService,
        private log: LogService,
        @Inject(DOCUMENT) private dom: Document,
        public baseService: ServiceBaseService,
        private homeService: HomeService,
        private translate: TranslateService,
        private sessionService: SessionService,
        @Inject(SEO_SERVICE_IMPL) private _seoService: ISeoService,
        private marketingService: MarketingService
    ) {
        this.log.mainClass = 'ProductDetailsComponent';

        this.route.params.subscribe((params) => {
            this.categ1 = params['categ1'];
            this.seoName = params['seoname'];
            this.shop = params['providercode'];

            this.homeService.isSessionSet.subscribe((isSet) => {
                if (isSet) {
                    this.loadProductAndSEO(this.seoName, this.shop);
                }
            });
        });
    }

    ngOnInit(): void {
        this.sessionService.isProviderSelectedForShopping.next(true);
        this.siteImageUrl = this.baseService.getSiteImageUrl();
        this.openMenuSubjectSub = this.basketService.openMenuSubject.subscribe((data) => {
            this.menuOpen = data;
        });
    }

    private loadProductAndSEO(seoName: string, shop: string): void {
        this.productListService.getProductDetails(seoName).subscribe(
            (product) => {
                this.prod = product;

                this.htmlMarketingElementDto$ =
                    this.marketingService.getHtmlMarketingElements(
                        ContainerIds.productDetails
                    );

                if (!product || this.prod.productDetails === null) {
                    this.router.navigate(['404'], {});
                    this.log.error('Hibás termék nincs details');
                    return;
                }

                let proddesc = '';
                if (this.prod.description) {
                    proddesc += this.prod.description;
                }

                forkJoin([
                    this.translate.get('meta.category-keywords'),
                    this.translate.get('info.category-title'),
                    this.translate.get('meta.category-description-first'),
                ]).subscribe(
                    ([keywords, title, description]: [string, string, string]) => {
                        const metaDescription = [
                            this.prod.category.categoryName,
                            this.prod.productName,
                            shop?.toUpperCase(),
                            description,
                            proddesc,
                            this.prod.sEOMetaDescripton,
                        ]
                            .filter(Boolean)
                            .join(' ');

                        const metatitle = this._seoService.titleWrap(
                            [
                                'ROKSH',
                                this.prod.category.categoryName,
                                this.prod.productName,
                                shop?.toUpperCase(),
                                title,
                                this.prod.sEOTitle,
                            ]
                                .filter(Boolean)
                                .join(' ')
                        );

                        this.meta.updateTag({
                            name: 'keywords',
                            content:
                                this.prod.category.categoryName +
                                ' ' +
                                this.prod.productName +
                                ',' +
                                keywords +
                                this.prod.sEOMetaKeyWords,
                        });
                        this.meta.updateTag({
                            name: 'description',
                            content: metaDescription,
                        });

                        this.title.setTitle(metatitle);

                        // social media preview
                        this.meta.updateTag({
                            property: 'og:url',
                            content: this.dom.URL,
                        });
                        this.meta.updateTag({
                            property: 'og:type',
                            content: 'product',
                        });
                        this.meta.updateTag({
                            property: 'og:title',
                            content: metatitle,
                        });
                        this.meta.updateTag({
                            property: 'og:description',
                            content: metaDescription,
                        });

                        this.meta.updateTag({
                            property: 'og:image',
                            content: this.prod.mediaUrlL,
                        });
                    }
                );
            },
            () => {
                void this.router.navigate(['/404']);
            }
        );
    }

    ngOnDestroy() {
        this.openMenuSubjectSub.unsubscribe();
    }
}
