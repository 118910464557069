<div class="row d-none" [class.scrolled]="scrolled" *ngIf="prod.productName">
    <div class="offset-md-4 col-md-8">
        <div class="row">
            <div class="col-8">
                <p class="ml-3 text-justify" [innerHTML]="prod.productName"></p>
            </div>
            <div class="col-4">
                <lib-add-to-cart-button
                    [isArchie]="isArchie"
                    [providerId]="providerId"
                    [providerName]="providerName"
                    [quantityList]="quantityList"
                    [prod]="prod"
                    [buttonText]="'common.add-to-basket-long' | translate"
                    [buttonWidth]="'100%'"
                    [quantityWidth]="'50%'"
                    plusMinusBtnClass="adjust-width"
                    [addressDialogComponent]="AddressDialogComponent"
                    [providerDialogComponent]="ShopSelectDialogSeparatedComponent"
                    (onAdd)="onAddToBasket($event)"
                >
                </lib-add-to-cart-button>
            </div>
        </div>
    </div>
</div>
<div class="row" itemscope itemtype="http://schema.org/Product" *ngIf="prod">
    <div class="mobile-zoom-modal d-sm-none overflow-auto" *ngIf="mobileZoomedIn">
        <fa-icon
            class="mobile-zoom pointer text-center"
            [icon]="faSearchMinus"
            (click)="zoomIOMobile()"
        ></fa-icon>
        <img src="{{ mobileZoomedImgSrc }}" /><br />
    </div>
    <span class="d-none" itemprop="name">{{ prod.productName }}</span>
    <div
        class="col-12 order-2 custom-order-md-1 position-relative"
        [ngClass]="isZoomedIn ? ' col-md-12 ps-3 pe-5' : ' col-md-4'"
    >
        <div
            class="badge-container-desktop m-auto"
            *ngIf="
                (productProviderArray[0]?.rokshDiscountPrice &&
                    productProviderArray[0]?.rokshDiscountPrice > 0) ||
                productProviderArray[0]?.isOneProductForFree
            "
        >
            <span class="badge badge-info">
                {{ 'common.roksh-discount-tooltip-1' | translate }}
                <span *ngIf="!productProviderArray[0]?.isOneProductForFree"
                    >{{
                        productProviderArray[0]?.rokshDiscountPrice | currencyFormatted
                    }}
                    {{ 'common.currency' | translate }}</span
                >
                <span *ngIf="productProviderArray[0]?.isOneProductForFree"
                    >{{ productProviderArray[0]?.price | currencyFormatted }}
                    {{ 'common.currency' | translate }}</span
                >
                <br />
                {{ 'common.roksh-discount-tooltip-2' | translate }}
            </span>
        </div>

        <div class="clearfix"></div>
        <div class="product-image">
            <ng-container *ngIf="prod.mediaUrlL === mediaServiceUrl + 'interface/large/'">
                <img
                    src="assets/images/missing.jpg"
                    class="mt-3"
                    style="height: auto; margin-top: 5px"
                />
            </ng-container>

            <ng-container *ngIf="prod.mediaUrlL !== mediaServiceUrl + 'interface/large/'">
                <ng-container *ngIf="!isZoomedIn">
                    <div class="mt-4 mb-4 basket-replace-container" *ngIf="!inProgress">
                        <a
                            *ngIf="hasGallery"
                            class="arrows prev-arrow"
                            (click)="carousel1.prev()"
                        >
                            <img
                                src="assets/images/left-arrow.png"
                                alt="Lapozó balra nyíl"
                            />
                        </a>

                        <owl-carousel-o [options]="customOptions" #carousel1>
                            <ng-template
                                *ngFor="let galleryImg of galleryImgArray"
                                class="productcard-container"
                                carouselSlide
                            >
                                <img
                                    class="d-block d-sm-none pointer mobile-zoom"
                                    src="assets/svg/plus-icon.svg"
                                    alt="search"
                                    (click)="zoomIOMobile(galleryImg)"
                                />

                                <img
                                    itemprop="image"
                                    alt="{{ imgAlt }}"
                                    class="w-100 mt-3"
                                    src="{{ galleryImg }}"
                                    onError="this.src='assets/images/roksh_logo.svg'"
                                    [ngClass]="isZoomedIn ? 'product-img-corrigated' : ''"
                                />
                            </ng-template>
                        </owl-carousel-o>

                        <a
                            *ngIf="hasGallery"
                            class="arrows next-arrow"
                            (click)="carousel1.next()"
                        >
                            <img
                                src="assets/images/right-arrow.png"
                                alt="Lapozó jobbra nyíl"
                            />
                        </a>
                        <div
                            *ngIf="!isZoomedIn"
                            (click)="zoomIO()"
                            class="pull-right zoomer d-none d-sm-block"
                        >
                            <img src="assets/svg/plus-icon.svg" alt="search" />
                        </div>
                        <div
                            *ngIf="isZoomedIn"
                            (click)="zoomIO()"
                            class="pull-right zoomer d-none d-sm-block"
                        >
                            <fa-icon [icon]="faSearchMinus"></fa-icon>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="isZoomedIn">
                    <div class="mt-4 mb-4 basket-replace-container" *ngIf="!inProgress">
                        <a
                            class="arrows prev-arrow"
                            (click)="carousel1.prev()"
                            *ngIf="hasGallery"
                            ><img
                                src="assets/images/left-arrow.png"
                                alt="Lapozó balra nyíl"
                        /></a>

                        <owl-carousel-o [options]="customOptions" #carousel1>
                            <ng-template
                                *ngFor="let galleryImg of galleryImgArray"
                                class="productcard-container"
                                carouselSlide
                            >
                                <img
                                    itemprop="image"
                                    alt="{{ imgAlt }}"
                                    class="w-100 mt-3"
                                    src="{{ galleryImg }}"
                                    onError="this.src='assets/images/roksh_logo.svg'"
                                    [ngClass]="isZoomedIn ? 'product-img-corrigated' : ''"
                                />
                            </ng-template>
                        </owl-carousel-o>
                        <a
                            class="arrows next-arrow"
                            (click)="carousel1.next()"
                            *ngIf="hasGallery"
                            ><img
                                src="assets/images/right-arrow.png"
                                alt="Lapozó jobbra nyíl"
                        /></a>

                        <div
                            *ngIf="!isZoomedIn"
                            (click)="zoomIO()"
                            class="pull-right zoomer d-none d-sm-block"
                        >
                            <fa-icon [icon]="faSearchPlus"></fa-icon>
                        </div>
                        <div
                            *ngIf="isZoomedIn"
                            (click)="zoomIO()"
                            class="pull-right zoomer d-none d-sm-block"
                        >
                            <fa-icon [icon]="faSearchMinus"></fa-icon>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <div
                *ngIf="
                    product.isOffer &&
                    productProviderArray &&
                    productProviderArray.length > 0 &&
                    productProviderArray[0]['originalPriceIfOffer'] > 0
                "
                class="mt-2 mb-4 text-center"
            >
                <span class="badge percentage"> - {{ percentageOfDiscount() }}%</span>
            </div>
            <img
                *ngIf="product?.spinViewerUrl"
                src="{{ siteImageUrl }}/images/SpinViewerIcon.svg"
                alt="360"
                class="spin-viewer"
                (click)="showExternalSpinViewer(product?.spinViewerUrl)"
            />
        </div>
    </div>

    <div
        class="col-12 order-3"
        [ngClass]="isZoomedIn ? ' col-md-12' : 'col-md-8'"
        *ngIf="!mobileZoomedIn"
    >
        <div
            class="d-md-block"
            itemprop="offers"
            itemscope
            itemtype="http://schema.org/Offer"
        >
            <div *ngIf="prod.minPrice !== 0 && prod.available === true">
                <div class="row">
                    <div class="col-sm-12 col-xl-7">
                        <h1
                            class="mb-1 product-name"
                            itemprop="name"
                            attr.content="{{ prod.productName }}"
                        >
                            {{ prod.productName }}
                        </h1>
                        <ng-container *ngIf="prodDetails.description">
                            <div class="d-lg-block d-md-block d-none mt-4">
                                <strong>{{
                                    'product-details.description' | translate
                                }}</strong>
                                <div #detailsContainer id="detailsContainer">
                                    <p
                                        itemprop="description"
                                        class="ml-3 text-justify details-container"
                                        [innerHTML]="prodDetails.description"
                                    ></p>
                                </div>
                                <div
                                    #detailsContainerOpener
                                    id="detailsContainerOpener"
                                    class="d-none"
                                    (click)="openDetails()"
                                >
                                    <h2 class="opener-h2">
                                        <span class="opener-span">
                                            {{
                                                'common.more-details-information'
                                                    | translate
                                            }}
                                            »
                                        </span>
                                    </h2>
                                </div>
                            </div>
                        </ng-container>
                        <div
                            *ngIf="hasMoreShop && !enableMoreShop && !isOwnWebShop"
                            class="more-shops pointer my-2 d-lg-block d-md-block d-none"
                            (click)="enableMoreShop = true"
                        >
                            {{ 'common.more-offer' | translate }}
                            <fa-icon [icon]="faAngleDown"></fa-icon>
                        </div>
                        <ng-container *ngIf="enableMoreShop && !isOwnWebShop">
                            <div
                                class="mb-4 mx-2 mx-s-0 position-relative d-lg-block d-md-block d-none"
                                *ngFor="let pp of productProviderArray; let i = index"
                            >
                                <br />
                                <app-product-price-row
                                    *ngIf="i >= 1 && pp.available"
                                    [productProvider]="pp"
                                    [minPrice]="
                                        prod.isBulk ? prod.minUnitPrice : prod.minPrice
                                    "
                                    [zipCode]="zipCode"
                                >
                                </app-product-price-row>
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-sm-12 col-xl-5 mb-2">
                        <div class="card card-helper p-md-2 position-relative">
                            <div
                                class="price-container"
                                *ngIf="isOrderCOmpleted && productProviderArray"
                                [class.mb-5]="
                                    !productProviderArray[0]?.unitPrice &&
                                    !hasMoreCheapest
                                "
                            >
                                <div
                                    *ngFor="let pp of productProviderArray; let i = index"
                                    class="mt-2 mb-1 mx-2 mx-s-0"
                                    [ngClass]="
                                        (!prod.isBulk && prod.minPrice === pp.price) ||
                                        (prod.isBulk &&
                                            prod.minUnitPrice === +pp.unitPrice)
                                            ? 'cheapest-price-line'
                                            : 'price-line'
                                    "
                                >
                                    <app-product-price-row
                                        *ngIf="i < 1 && pp.available"
                                        [productProvider]="pp"
                                        [minPrice]="
                                            prod.isBulk
                                                ? prod.minUnitPrice
                                                : prod.minPrice
                                        "
                                        [zipCode]="zipCode"
                                        [hasMoreCheapest]="hasMoreCheapest"
                                    >
                                    </app-product-price-row>
                                </div>
                            </div>
                            <span class="opacity-60 price-tags mt-1 mb-4">
                                <ng-container
                                    *ngIf="prod.isBulk && productProviderArray[0]"
                                >
                                    <span
                                        class="priceunit"
                                        itemprop="price"
                                        attr.content="{{
                                            productProviderArray[0]?.unitPrice
                                        }}"
                                    >
                                        {{
                                            +productProviderArray[0]?.unitPrice
                                                | currencyFormatted
                                        }}
                                    </span>
                                    <span itemprop="priceCurrency" [attr.content]="'HUF'">
                                        {{ 'common.currency' | translate }}
                                    </span>
                                    <span>
                                        <ng-container *ngIf="!prodDetails.displayUnit"
                                            >/kg</ng-container
                                        >
                                        <ng-container *ngIf="prodDetails.displayUnit"
                                            >/{{ prodDetails.displayUnit }}</ng-container
                                        >
                                        <span
                                            *ngIf="
                                                !isProviderSelected &&
                                                productProviderArray.length > 1
                                            "
                                            >-tól
                                        </span>
                                    </span>
                                    <span
                                        *ngIf="
                                            isOnePrice &&
                                            prod.productProvider[0].secondaryUnitPrice
                                        "
                                    >
                                        ,
                                        <span>
                                            {{
                                                prod.productProvider[0].secondaryUnitPrice
                                                    | currencyFormatted
                                            }}
                                        </span>

                                        {{ 'common.currency' | translate }}/
                                        {{ prod.productProvider[0].secondaryUnit }}
                                    </span>
                                    <link
                                        itemprop="availability"
                                        href="http://schema.org/InStock"
                                    />
                                </ng-container>

                                <ng-container
                                    *ngIf="
                                        !prod.isBulk &&
                                        productProviderArray &&
                                        productProviderArray[0] &&
                                        productProviderArray[0]?.unitPrice &&
                                        +productProviderArray[0]?.unitPrice > 0
                                    "
                                >
                                    <span
                                        class="priceunit"
                                        itemprop="price"
                                        attr.content="{{
                                            productProviderArray[0]?.unitPrice
                                        }}"
                                    >
                                        {{
                                            +productProviderArray[0]?.unitPrice
                                                | currencyFormatted
                                        }}
                                    </span>
                                    <span itemprop="priceCurrency" [attr.content]="'HUF'">
                                        {{ 'common.currency' | translate }}/{{
                                            prod.priceUnitType
                                        }}
                                        <span
                                            *ngIf="
                                                isOnePrice &&
                                                prod.productProvider[0].secondaryUnitPrice
                                            "
                                        >
                                            ,
                                            {{
                                                prod.productProvider[0].secondaryUnitPrice
                                                    | currencyFormatted
                                            }}
                                            {{ 'common.currency' | translate }}/
                                            {{ prod.productProvider[0].secondaryUnit }}
                                        </span>
                                    </span>
                                    <link
                                        itemprop="availability"
                                        href="http://schema.org/InStock"
                                    />
                                </ng-container>
                            </span>

                            <div
                                *ngIf="
                                    prod.minPrice > 0 &&
                                    prod.available === true &&
                                    !shoppingProhibited
                                "
                                id="add-button-intersect"
                                class="text-start mt-3"
                                [ngClass]="{ 'd-none d-md-block': isDialog }"
                            >
                                <lib-add-to-cart-button
                                    [isArchie]="isArchie"
                                    [providerId]="providerId"
                                    [providerName]="providerName"
                                    [quantityList]="quantityList"
                                    [prod]="prod"
                                    [buttonText]="'common.add-to-basket-long' | translate"
                                    [buttonWidth]="'100%'"
                                    [quantityWidth]="'50%'"
                                    plusMinusBtnClass="adjust-width"
                                    [addressDialogComponent]="AddressDialogComponent"
                                    [providerDialogComponent]="
                                        ShopSelectDialogSeparatedComponent
                                    "
                                    (onAdd)="onAddToBasket($event)"
                                >
                                </lib-add-to-cart-button>
                            </div>
                        </div>
                        <div class="col-12 mt-2">
                            <div class="d-flex">
                                <div class="w-50 pe-1">
                                    <div class="card py-2 px-1">
                                        <div
                                            class="mx-auto text-center pointer"
                                            (click)="onUserListAdd()"
                                            style="display: flex; justify-content: center"
                                        >
                                            <img
                                                src="{{
                                                    siteImageUrl
                                                }}images/shared-img/user-basket/add-user-basket.svg"
                                                class="me-2 add-user-basket"
                                                alt=""
                                            />
                                            <small class="add-to-list small-text">{{
                                                'product-card.add-to-list' | translate
                                            }}</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-50 ps-1">
                                    <div
                                        class="card py-2 px-1"
                                        (click)="onProductFavoriteChange()"
                                    >
                                        <div
                                            class="mx-auto text-center pointer"
                                            style="display: flex; justify-content: center"
                                        >
                                            <img
                                                src="{{
                                                    !isFavoriteProduct
                                                        ? siteImageUrl +
                                                          'images/heart.svg'
                                                        : siteImageUrl +
                                                          'images/heart-fill.svg'
                                                }}"
                                                class="me-2 favourites"
                                                alt=""
                                            />
                                            <small class="small-text">{{
                                                (this.isFavoriteProduct
                                                    ? 'common.my-favourite'
                                                    : 'common.favourites'
                                                ) | translate
                                            }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="prodDetails.description">
            <div class="d-block d-lg-none d-md-none mt-3">
                <strong>{{ 'product-details.description' | translate }}</strong>
                <p
                    itemprop="description"
                    class="ml-3 text-justify description"
                    id="_details-container"
                    [innerHTML]="prodDetails.description"
                ></p>
            </div>
        </ng-container>
        <div
            *ngIf="hasMoreShop && !enableMoreShop && !isOwnWebShop"
            class="more-shops pointer my-2 d-block d-lg-none d-md-none"
            (click)="enableMoreShop = true"
        >
            {{ 'common.more-offer' | translate }}
            <fa-icon [icon]="faAngleDown"></fa-icon>
        </div>
        <ng-container *ngIf="enableMoreShop && !isOwnWebShop">
            <div
                class="mb-4 mx-2 mx-s-0 position-relative d-block d-lg-none d-md-none"
                *ngFor="let pp of productProviderArray; let i = index"
            >
                <br />
                <app-product-price-row
                    *ngIf="i >= 1 && pp.available"
                    [productProvider]="pp"
                    [minPrice]="prod.isBulk ? prod.minUnitPrice : prod.minPrice"
                    [zipCode]="zipCode"
                >
                </app-product-price-row>
            </div>
        </ng-container>

        <div *ngIf="prod.minPrice === 0 || prod.available === false">
            <h1
                class="mb-1 text-size-28 px-1"
                itemprop="name"
                attr.content="{{ prod.productName }}"
            >
                {{ prod.productName }}
            </h1>
            <span class="text-danger">
                {{ 'product-details.not-in-stock' | translate }}</span
            >
            <br />
            <hr />
            <br />
            <div class="w-75">
                <h5 class="text-success">
                    {{ 'product-details.but-there-are-more' | translate }}
                </h5>

                <button
                    class="btn btn-warning w-100"
                    (click)="goToCategoryOnMissingProd()"
                >
                    {{ 'product-details.other' | translate }}
                    {{ prod.category.categoryName }}
                    {{ 'product-details.see' | translate }} >>
                </button>
            </div>

            <link itemprop="availability" href="http://schema.org/NotInStock" />
        </div>

        <a
            *ngIf="prodDetails?.promotion?.promotionURL"
            href="{{ prodDetails.promotion.promotionURL }}"
        >
            <img
                class="w-100 mt-3"
                style="display: block; margin: auto"
                src="data:image/png;base64,{{ prodDetails.promotion.promotionPicture }}"
                alt="Promóciós kép..."
            />
        </a>

        <img
            *ngIf="prodDetails?.promotion?.promotionPicture"
            class="w-100 mt-3"
            style="display: block; margin: auto"
            src="data:image/png;base64,{{ prodDetails.promotion.promotionPicture }}"
            alt="Promóciós kép..."
        />

        <hr />

        <div class="row product-informations">
            <ng-container *ngIf="prod.icons">
                <div class="d-flex flex-wrap icons-container">
                    <img
                        *ngFor="let icon of prod.icons"
                        [src]="icon.blobURL"
                        style="height: 66px; max-width: 230px; margin: 5px"
                    />
                </div>
            </ng-container>
            <ng-container *ngIf="prodDetails.consumption">
                <div class="col-11">
                    <h3 class="text-size-20">
                        {{ 'product-details.usage' | translate }}:
                    </h3>
                    <p
                        class="ml-3 opacity-60 text-justify text-size-14"
                        [innerHTML]="prodDetails.consumption"
                    ></p>
                </div>
                <hr />
            </ng-container>

            <ng-container *ngIf="prodDetails.storing">
                <div class="col-11">
                    <h3 class="text-size-20">
                        {{ 'product-details.storing' | translate }}:
                    </h3>
                    <p
                        class="ml-3 opacity-60 text-size-14"
                        [innerHTML]="prodDetails.storing"
                    ></p>
                </div>
                <hr />
            </ng-container>

            <ng-container *ngIf="prodDetails.allergenic">
                <div class="col-11">
                    <h3 class="text-size-20">
                        {{ 'product-details.allergens' | translate }}:
                    </h3>
                    <p
                        class="ml-3 mb-0 opacity-60 text-justify text-size-14"
                        *ngIf="
                            !isTextHtml(prodDetails.allergenic);
                            else allergensStringHtml
                        "
                    >
                        {{ allergensString }}
                    </p>

                    <ng-template #allergensStringHtml>
                        <p
                            class="ml-3 mb-0 opacity-60 text-justify text-size-14"
                            [innerHTML]="prodDetails.allergenic"
                        ></p>
                    </ng-template>
                </div>
                <hr />
            </ng-container>

            <ng-container *ngIf="prodDetails.ingredients">
                <div class="col-11 ingred mb-4">
                    <h3 class="text-size-20">
                        {{ 'product-details.ingredients' | translate }}:
                    </h3>
                    <p
                        class="ml-3 mb-0 opacity-60 text-justify text-size-14"
                        *ngIf="
                            !isTextHtml(prodDetails.ingredients);
                            else ingredientsStringHtml
                        "
                    >
                        {{ ingredientsString }}
                    </p>
                    <ng-template #ingredientsStringHtml>
                        <p
                            class="ml-3 mb-0 opacity-60 text-justify text-size-14"
                            [innerHTML]="prodDetails.ingredients"
                        ></p>
                    </ng-template>
                </div>
                <hr />
            </ng-container>
            <!-- Meg kell formázni és csak utáűna megjeleníteni -->
            <ng-container *ngIf="prodDetails.textualNutrition">
                <div class="col-11 mb-4">
                    <h3 class="text-size-20">
                        {{ 'product-details.nutritions' | translate }}:
                    </h3>
                    <div
                        *ngIf="
                            !isTextHtml(prodDetails.textualNutrition);
                            else textualNutritionHtml
                        "
                    >
                        <lib-product-nutrition
                            [textNutrition]="prodDetails.textualNutrition"
                        ></lib-product-nutrition>
                    </div>
                    <ng-template #textualNutritionHtml>
                        <p
                            class="ml-3 mb-0 opacity-60 text-justify text-size-14"
                            [innerHTML]="prodDetails.textualNutrition"
                        ></p>
                    </ng-template>
                </div>
                <hr />
            </ng-container>

            <ng-container *ngIf="prodDetails.packageType">
                <div class="col-11">
                    <h3 class="text-size-20">
                        {{ 'product-details.package-type' | translate }}:
                    </h3>
                    <p
                        class="ml-3 opacity-60 text-size-14"
                        [innerHTML]="prodDetails.packageType"
                    ></p>
                </div>
                <hr />
            </ng-container>

            <ng-container *ngIf="prodDetails.serviceDepartment">
                <div class="col-11">
                    <h3 class="text-size-20">
                        {{ 'product-details.service-department' | translate }}:
                    </h3>
                    <p
                        class="ml-3 opacity-60 text-size-14"
                        [innerHTML]="prodDetails.serviceDepartment"
                    ></p>
                </div>
                <hr />
            </ng-container>

            <ng-container *ngIf="prodDetails.producerAddress">
                <div class="col-11">
                    <h3 class="text-size-20">
                        {{ 'product-details.producer-address' | translate }}:
                    </h3>
                    <p
                        class="ml-3 opacity-60 text-size-14"
                        [innerHTML]="prodDetails.producerAddress"
                    ></p>
                </div>
                <hr />
            </ng-container>

            <ng-container *ngIf="prodDetails.supplierAddress">
                <div class="col-11">
                    <h3 class="text-size-20">
                        {{ 'product-details.supplier-data' | translate }}:
                    </h3>
                    <p class="ml-3 opacity-60 text-size-14">
                        {{ 'product-details.supplier-address' | translate }}:
                        {{ prodDetails.supplierAddress }}
                    </p>
                    <p
                        class="ml-3 opacity-60 text-size-14"
                        *ngIf="prodDetails.supplierPhone"
                    >
                        {{ 'product-details.supplier-phone' | translate }}:
                        {{ prodDetails.supplierPhone }}
                    </p>
                    <p
                        class="ml-3 opacity-60 text-size-14"
                        *ngIf="prodDetails.supplierWeb"
                    >
                        {{ 'product-details.supplier-web' | translate }}:
                        {{ prodDetails.supplierWeb }}
                    </p>
                </div>
                <hr />
            </ng-container>

            <ng-container *ngIf="prodDetails.manufacturer">
                <div class="col-11">
                    <h3 class="text-size-20">
                        {{ 'product-details.supplier-data' | translate }}:
                    </h3>
                    <p
                        class="ml-3 opacity-60 text-size-14"
                        [innerHTML]="prodDetails.manufacturer"
                    ></p>
                </div>
                <hr />
            </ng-container>

            <ng-container *ngIf="prodDetails.originCountry">
                <div class="col-11">
                    <h3 class="text-size-20">
                        {{ 'product-details.origin-country' | translate }}:
                    </h3>
                    <p
                        class="ml-3 opacity-60 text-size-14"
                        [innerHTML]="prodDetails.originCountry"
                    ></p>
                </div>
                <hr />
            </ng-container>

            <ng-container *ngIf="prodDetails.alcoholStr">
                <div class="col-11">
                    <h3 class="text-size-20">
                        {{ 'product-details.alcohol-percentage' | translate }}:
                    </h3>
                    <p
                        class="ml-3 opacity-60 text-size-14"
                        [innerHTML]="prodDetails.alcoholStr"
                    ></p>
                </div>
                <hr />
            </ng-container>

            <!--Így is ismerhetik-->
            <div
                class="col-11"
                *ngIf="
                    (prodviderProductNameUniqueArr.length > 0 && prod.minPrice === 0) ||
                    prod.available === false
                "
            >
                <h6>{{ 'product-details.similar-names' | translate }}:</h6>
                <p class="ml-3 opacity-60 text-size-14">
                    <ng-container *ngFor="let pp of prodviderProductNameUniqueArr">
                        <span *ngIf="pp"> {{ pp }}, </span>
                    </ng-container>
                </p>
            </div>

            <!--<div class="col-11">
      <p class="ml-3 opacity-60 text-size-14">
          <ng-container>
              <i> * {{ 'product-details.prices-info' | translate }} </i>
          </ng-container>
      </p>
  </div>-->

            <div class="col-11" *ngIf="prod.isBulk">
                <p class="ml-3 opacity-60 text-size-14">
                    <ng-container>
                        <i> * {{ 'product-details.bulk-info' | translate }} </i>
                    </ng-container>
                </p>
            </div>

            <div
                class="col-11"
                *ngIf="
                    productProviderArray[0] &&
                    productProviderArray[0]?.depositFee &&
                    !hasAnyProviderDepositProduct
                "
            >
                <p class="text-size-14">
                    <ng-container>
                        <strong>
                            {{ 'product-details.deposit-info' | translate }}
                        </strong>
                    </ng-container>
                </p>
            </div>
            <!-- <div class="col-11" *ngIf="hasAnyProviderDepositProduct">
                <p class="text-size-14">
                    <ng-container>
                        <strong>
                            {{ 'product-details.provider-deposit-info' | translate }}
                        </strong>
                    </ng-container>
                </p>
            </div> -->
        </div>
    </div>
</div>
