import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ProvidersService } from '../../../services/provider.service';
import {
    ArchieClient,
    ArchieConversationThreadDto,
    ProviderDTO,
    UpdateArchieConversationThreadProvidersDto,
} from '../../../index';
import { ServiceBaseService } from '../../../services/service-base.service';
import { FormControl, FormGroup } from '@angular/forms';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { take } from 'rxjs/operators';
import { SessionService } from '../../../services/session/session.service';

@Component({
    selector: 'lib-archie-provider-select',
    templateUrl: './archie-provider-select.component.html',
    styleUrls: ['./archie-provider-select.component.scss'],
})
export class ArchieProviderSelectComponent implements OnInit {
    @Input() providerSelectedEmitter: EventEmitter<ArchieConversationThreadDto>;
    @Input() threadId: string;
    @Input() isNewChat = true;
    readonly MAXIMUM_PROVIDERS_AMOUNT = 3;
    providers: ProviderDTO[];
    formGroup = new FormGroup({
        provider: new FormControl(false),
    });
    selectedProviders: ProviderDTO[] = [];
    siteImageUrl: string;
    faCheck = faCheck;
    isLoading = false;

    constructor(
        private providerService: ProvidersService,
        private sessionService: SessionService,
        private archieClient: ArchieClient,
        baseService: ServiceBaseService
    ) {
        this.siteImageUrl = baseService.getSiteImageUrl();
    }

    ngOnInit(): void {
        this.sessionService.sessionSubject.subscribe((session) => {
            this.providerService.getAllProviders().subscribe((providers) => {
                if (session?.availableShops) {
                    this.providers = providers.filter((current) =>
                        session.availableShops.find(
                            (shop) => shop.toLowerCase() === current.name.toLowerCase()
                        )
                    );
                }
            });
        });
    }

    onSelect(event: Event, provider: ProviderDTO) {
        const input = event.target as HTMLInputElement;
        if (input.checked) {
            if (this.selectedProviders.length === this.MAXIMUM_PROVIDERS_AMOUNT) {
                input.checked = false;

                return;
            }

            this.selectedProviders.push(provider);
        } else {
            const index = this.selectedProviders.findIndex(
                (current) => current.name === provider.name
            );
            this.selectedProviders.splice(index, 1);
        }
    }

    onProvidersSelected(): void {
        this.isLoading = true;
        const providerIdList: number[] = this.selectedProviders.map(
            (provider) => provider.id
        );
        const updateArchieConversation = {
            archieConversationThreadId: null,
            providerIds: providerIdList,
        } as UpdateArchieConversationThreadProvidersDto;

        if (this.threadId) {
            updateArchieConversation.archieConversationThreadId = this.threadId;
            this.updateThread(updateArchieConversation);
        } else {
            this.archieClient
                .newArchieThread()
                .pipe(take(1))
                .subscribe((newThread) => {
                    updateArchieConversation.archieConversationThreadId =
                        newThread.archieConversationThreadID;
                    this.updateThread(updateArchieConversation);
                });
        }
    }

    private updateThread(
        updateArchieConversation: UpdateArchieConversationThreadProvidersDto
    ) {
        this.archieClient
            .updateArchieConversationThreadProviders(updateArchieConversation)
            .subscribe((res) => {
                this.providerSelectedEmitter.emit(res);
                this.isLoading = false;
            });
    }

    onProvidersUpdate(): void {
        this.isLoading = true;
        const providerIdList: number[] = this.selectedProviders.map(
            (provider) => provider.id
        );
        const updateArchieConversation = {
            archieConversationThreadId: this.threadId,
            providerIds: providerIdList,
        } as UpdateArchieConversationThreadProvidersDto;
        this.updateThread(updateArchieConversation);
    }
}
