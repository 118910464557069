<div id="addressPanel" [formGroup]="checkoutForm">
    <div formGroupName="addressForm">
        <mat-expansion-panel
            class="row"
            style="margin-bottom: 5px"
            [expanded]="addressStepIsExpanded"
            [class.activated-step]="addressStepIsActive"
            (opened)="setStep(addressStepIndex)"
            hideToggle
        >
            <mat-expansion-panel-header
                class="checkout-panel-header"
                style="height: 90px !important; width: calc(100% - 10px)"
            >
                <div class="checkout-header-img">
                    <img
                        src="assets/svg/rokshImages/icons/delivery.svg"
                        class="expansion-header-svg"
                    />
                </div>
                <div class="column w-100">
                    <mat-panel-title>
                        <div class="fw-semibold">
                            {{ 'checkout.delivery-data' | translate }}
                        </div>
                    </mat-panel-title>
                    <mat-panel-description class="col-12">
                        <ng-container *ngIf="postCodeProvided">
                            {{ postCodeControl.value + ', ' }}
                        </ng-container>

                        <ng-container *ngIf="addressProvided">
                            {{ addressData() }}
                        </ng-container>
                    </mat-panel-description>
                </div>
                <img
                    src="assets/svg/valid.svg"
                    class="d-none"
                    style="margin: auto 10px; height: 1rem"
                />
            </mat-expansion-panel-header>
            <div class="row mt-4">
                <div class="clearfix"></div>
                <mat-form-field class="col-12 col-md-6" appearance="outline">
                    <mat-label>{{ 'common.post-code' | translate }}</mat-label>
                    <input
                        formControlName="PostCode"
                        class="checkout-form-input"
                        (input)="setZipCode($event)"
                        matInput
                        maxlength="4"
                        placeholder="{{ 'common.post-code' | translate }}"
                        id="irsz"
                    />
                    <img
                        *ngIf="(zipIsValid$ | async) === true"
                        src="assets/svg/valid.svg"
                        class="valid-icon"
                    />
                    <mat-error *ngIf="!checkoutForm.get('addressForm.PostCode').valid">
                        {{ 'common.postal-code-required' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-12 col-md-6" appearance="outline">
                    <mat-label>{{ 'common.city-name' | translate }}</mat-label>
                    <input
                        formControlName="City"
                        matInput
                        class="checkout-form-input"
                        placeholder="{{ 'common.city-name' | translate }}*"
                        maxlength="255"
                        id="addr"
                        (input)="invalidateAddress()"
                    />
                    <img
                        *ngIf="addressProvided"
                        src="assets/svg/valid.svg"
                        class="valid-icon"
                    />
                    <mat-error *ngIf="!checkoutForm.get('addressForm.City').valid">
                        {{ 'checkout.city-name-required' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-12 col-md-6" appearance="outline">
                    <mat-label>{{ 'common.street' | translate }}</mat-label>
                    <input
                        formControlName="StreetName"
                        matInput
                        class="checkout-form-input"
                        placeholder="{{ 'common.street' | translate }}*"
                        maxlength="255"
                        (input)="invalidateAddress()"
                    />
                    <img
                        *ngIf="addressProvided"
                        src="assets/svg/valid.svg"
                        class="valid-icon"
                    />
                    <mat-error *ngIf="!checkoutForm.get('addressForm.StreetName').valid">
                        {{ 'common.street-required' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-12 col-md-6" appearance="outline">
                    <mat-label>{{ 'common.housenumber' | translate }}</mat-label>
                    <input
                        formControlName="HouseNumber"
                        matInput
                        class="checkout-form-input"
                        placeholder="{{ 'common.housenumber' | translate }}*"
                        maxlength="255"
                        (input)="invalidateAddress()"
                    />
                    <img
                        *ngIf="addressProvided"
                        src="assets/svg/valid.svg"
                        class="valid-icon"
                    />
                    <mat-error *ngIf="!checkoutForm.get('addressForm.HouseNumber').valid">
                        {{ 'common.housenumber-required' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="col-12 col-md-6" appearance="outline">
                    <mat-label> {{ 'common.fullname' | translate }}</mat-label>
                    <input
                        formControlName="FullName"
                        (change)="onChangeName($event)"
                        class="checkout-form-input"
                        matInput
                        placeholder=" {{ 'common.fullname' | translate }}*"
                        id="fname"
                    />
                    <img
                        *ngIf="fullNameProvided"
                        src="assets/svg/valid.svg"
                        class="valid-icon"
                    />
                    <mat-error *ngIf="!fullNameProvided">
                        {{ 'checkout.fullname-required' | translate }}
                    </mat-error>
                </mat-form-field>
                <div
                    class="col-12 col-md-6"
                    style="display: inline-table; position: relative"
                    [formGroup]="phoneForm"
                >
                    <div class="wrapper">
                        <mat-label class="custom-label"
                            >{{ 'checkout.phone-number' | translate }} *</mat-label
                        >
                        <ngx-intl-tel-input
                            [cssClass]="
                                'form-control tel-input ' +
                                (phoneForm.controls.phone.errors
                                    ? 'tel-input-invalid'
                                    : '')
                            "
                            id="tel"
                            [preferredCountries]="preferredCountries"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [searchCountryField]="[
                                SearchCountryField.Iso2,
                                SearchCountryField.Name
                            ]"
                            [selectFirstCountry]="false"
                            [selectedCountryISO]="preferredCountries[0]"
                            [maxLength]="15"
                            [phoneValidation]="true"
                            [separateDialCode]="false"
                            [numberFormat]="PhoneNumberFormat.International"
                            formControlName="phone"
                            (ngModelChange)="onPhoneChanges()"
                        >
                        </ngx-intl-tel-input>
                        <small
                            class="text-danger mt-1 ps-2"
                            *ngIf="
                                phoneForm.controls.phone.errors &&
                                phoneForm.controls.phone.touched &&
                                phoneForm.controls.phone.errors?.validatePhoneNumber
                                    ?.valid !== false
                            "
                        >
                            {{ 'common.phone-number-required' | translate }}
                        </small>
                        <small
                            class="text-danger mt-1 ps-2"
                            *ngIf="
                                phoneForm.controls.phone.errors?.validatePhoneNumber
                                    ?.valid === false
                            "
                        >
                            {{ 'application.invalid-phone-number' | translate }}
                        </small>
                        <img
                            *ngIf="phoneForm.controls.phone.status === 'VALID'"
                            src="assets/svg/valid.svg"
                            class="valid-icon phone-icon"
                        />
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>{{ 'common.birthdate' | translate }}</mat-label>
                        <input
                            id="birthdate"
                            class="checkout-form-input"
                            formControlName="Birthdate"
                            placeholder="{{ 'common.birthdate' | translate }}"
                            matInput
                            [matDatepicker]="picker"
                            readonly
                            (focus)="picker.open()"
                            (click)="picker.open()"
                            [max]="maxBirthdate"
                        />
                        <mat-datepicker #picker></mat-datepicker>
                        <img
                            *ngIf="birthdateProvided"
                            src="assets/svg/valid.svg"
                            class="valid-icon"
                        />
                        <mat-error *ngIf="!birthdateProvided">
                            {{ 'log-in.birthdate-required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-12 col-md-6">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>{{ 'common.email' | translate }}</mat-label>
                        <input
                            formControlName="Email"
                            class="checkout-form-input"
                            matInput
                            placeholder="email@email.com"
                            id="mail"
                        />
                        <img
                            *ngIf="emailProvided"
                            src="assets/svg/valid.svg"
                            class="valid-icon"
                        />
                        <mat-error *ngIf="!checkoutForm.get('addressForm.Email').valid">{{
                            'checkout.email-required' | translate
                        }}</mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="vatInvoiceAvailable" class="pay">
                    <div class="col-12 mb-3">
                        <div class="float-start pe-2" style="padding-top: 2px">
                            <input
                                id="SameBillingAddress"
                                name="DifferentBillingAddressSelector"
                                type="radio"
                                value="SameBillingAddress"
                                formControlName="DifferentBillingAddressSelector"
                                (change)="onChangeBillingAddressCheckBox($event)"
                                checked
                            />
                        </div>
                        <label for="SameBillingAddress">
                            {{ 'checkout.same-billing-address-label' | translate }}
                        </label>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-12">
                        <div class="float-start pe-2" style="padding-top: 2px">
                            <input
                                id="DifferentBillingAddress"
                                name="DifferentBillingAddressSelector"
                                type="radio"
                                value="DifferentBillingAddress"
                                formControlName="DifferentBillingAddressSelector"
                                (change)="onChangeBillingAddressCheckBox($event)"
                            />
                        </div>
                        <label for="DifferentBillingAddress">
                            {{ 'checkout.different-billing-address-label' | translate }}
                        </label>
                    </div>
                    <div class="clearfix"></div>
                    <div *ngIf="differentBillingAddress" class="row">
                        <mat-form-field class="col-12 col-md-6" appearance="outline">
                            <mat-label>
                                {{ 'checkout.billing-name' | translate }}*
                            </mat-label>
                            <input
                                formControlName="BillingName"
                                class="checkout-form-input"
                                matInput
                                placeholder=" {{ 'checkout.billing-name' | translate }}*"
                                id="billing-name"
                            />

                            <mat-error
                                *ngIf="!checkoutForm.get('addressForm.BillingName').valid"
                            >
                                {{ 'checkout.billing-name-required' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-12 col-md-6" appearance="outline">
                            <mat-label>{{ 'common.post-code' | translate }}</mat-label>
                            <input
                                formControlName="BillingAddressPostCode"
                                class="checkout-form-input"
                                matInput
                                maxlength="4"
                                placeholder="{{ 'common.post-code' | translate }}"
                                (change)="onChangeBillingAddressPostCode($event)"
                                id="billing-address-post-code"
                            />
                            <mat-error
                                *ngIf="
                                    !checkoutForm.get(
                                        'addressForm.BillingAddressPostCode'
                                    ).valid
                                "
                            >
                                {{
                                    'checkout.billing-address-post-code-required'
                                        | translate
                                }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-12 col-md-6" appearance="outline">
                            <mat-label
                                >{{
                                    'common.street-and-housenumber' | translate
                                }}*</mat-label
                            >
                            <input
                                formControlName="BillingAddress"
                                matInput
                                class="checkout-form-input"
                                placeholder="{{
                                    'common.street-and-housenumber' | translate
                                }}*"
                                maxlength="255"
                                id="billing-address"
                            />
                            <mat-error
                                *ngIf="
                                    !checkoutForm.get('addressForm.BillingAddress').valid
                                "
                            >
                                {{ 'checkout.billing-address-required' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-12 col-md-6" appearance="outline">
                            <mat-label>{{ 'checkout.tax-number' | translate }}</mat-label>
                            <input
                                formControlName="TaxNumber"
                                matInput
                                class="checkout-form-input"
                                placeholder="{{ 'checkout.tax-number' | translate }}"
                                minlength="0 | 6"
                                id="tax-number"
                            />
                            <mat-error
                                *ngIf="!checkoutForm.get('addressForm.TaxNumber').valid"
                            >
                                {{ 'checkout.tax-number-format-error' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <mat-form-field class="col-12 comment" appearance="outline">
                    <mat-label>{{
                        'checkout.comment-for-deliverer' | translate
                    }}</mat-label>
                    <textarea
                        formControlName="Comment"
                        matInput
                        class="checkout-form-input w-100"
                        placeholder="{{
                            'checkout.comment-for-deliverer-body' | translate
                        }}"
                    >
                    </textarea>
                </mat-form-field>
                <div>
                    <small> * {{ 'common.required' | translate }} </small>
                </div>
            </div>
            <mat-action-row class="text-center px-4 py-3">
                <span
                    mat-button
                    class="col-12 col-md-6 m-auto m-md-0 btn btn-tovabb btn-rounded mb-2"
                    (click)="nextStep()"
                    >{{ 'common.next' | translate }}</span
                >
            </mat-action-row>
        </mat-expansion-panel>
    </div>
</div>
