import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
    AuthenticationService,
    BasketService,
    ConfigureSessionDto,
    FormatterService,
    IMyOrder,
    LogService,
    MyOrderItemProductDto,
    OrderService,
    ServiceBaseService,
    SessionService,
    SharedModalService,
    UserShared,
} from 'projects/library-shared/src/public-api';
import { faAngleRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from '../../services/home/home.service';

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss'],
    providers: [LogService],
})
export class OrderComponent implements OnInit {
    faArrowLeft = faArrowLeft;
    faAngleRight = faAngleRight;
    siteImageUrl = '';

    massAddButtonText = '';
    isAuthenticated = false;
    @Output() onaddorreplace: EventEmitter<any> = new EventEmitter();
    public order: IMyOrder;
    public isLoading = false;
    public orderCode: '';
    mobile: boolean;
    user: UserShared = new UserShared();
    products: MyOrderItemProductDto[];
    now = new Date();

    isDeliveryAvailableByPostCode = true;
    isPostCodeSetted = false;
    isProviderSelectedForShopping = false;
    selectedProviderName: string;

    @Output() onadd: EventEmitter<any> = new EventEmitter();
    destroyed$: any;

    constructor(
        private authService: AuthenticationService,
        private sessionService: SessionService,
        public basketService: BasketService,
        public formatterService: FormatterService,
        public baseService: ServiceBaseService,
        public translate: TranslateService,
        private route: ActivatedRoute,
        private orderService: OrderService,
        private homeService: HomeService,
        private sharedModalService: SharedModalService
    ) {
        this.translate
            .get('common.add-all-to-basket')
            .subscribe((translation) => (this.massAddButtonText = translation));
    }

    ngOnInit(): void {
        this.user = this.authService.getLoggedInUser();

        const orderCode = this.route.snapshot.queryParams['orderCode'];
        this.orderCode = orderCode;

        this.siteImageUrl = this.baseService.getSiteImageUrl();

        this.homeService.isSessionSet.subscribe((sessionSet) => {
            if (sessionSet) {
                this.authService.checkServerLoggedInUser().subscribe((checkUser) => {
                    if (!checkUser) {
                        void this.sharedModalService.presentSignInModal();
                    }
                });
            }
        });

        if (window.screen.width <= 360) {
            this.mobile = true;
        }

        this.authService.getAuthStatusListener().subscribe((newAuthStatus) => {
            this.isAuthenticated = newAuthStatus;
            this.user = this.authService.getLoggedInUser();

            if (this.isAuthenticated && this.user) {
                this.orderService.getOrderForUser(orderCode).subscribe((data) => {
                    if (data) {
                        const orderData = data as IMyOrder;
                        this.order = orderData;
                        if (
                            this.sessionService.sessionSubject?.value.selectedShops
                                ?.length !== 1
                        ) {
                            const configureSessionDto = new ConfigureSessionDto({
                                userSelectedShops: [],
                                redirectToDashboardNeeded: false,
                                setUserSelectedShopsOnFirstSiteLoad: true,
                                shopsSelectedForRoot: this.order.ProviderName,
                                ownWebshopProviderCode: this.order.ProviderName,
                            });
                            this.sessionService
                                .configureUserSession(configureSessionDto)
                                .subscribe();

                            this.sessionService.sessionSubject.value.selectedShops;
                        }

                        this.isLoading = true;
                    }
                });
            }
        });

        this.route.queryParams.subscribe((params) => {
            this.selectedProviderName = params.providerName as string;
        });
    }
}
