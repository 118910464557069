import { RegOrigin } from '../../auth/reg-origin.enum';

export class UserShared {
    UserID: number;
    Email: string;
    PostalAddr?: string;
    Sex?: string;
    BirthDate?: Date;
    LastName: string;
    FirstName: string;
    IsRecentRegistered: boolean;
    Origin?: RegOrigin;
    HasPassword?: boolean;
    HasPin?: boolean;
    MobilePhone: string;
    ToSAccepted?: boolean;
    PrivacyAccepted?: boolean;
    MarketingEmail?: boolean;
    MarketingSMS?: boolean;
}
