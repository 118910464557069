<div class="pt-5 position-relative">
    <div
        *ngIf="showProviderLgoo"
        class="provider-logo position-absolute category-page-sidebar-header-current-shop-image"
        [ngStyle]="{
            padding: selectedShopName ? '5px' : '0'
        }"
    >
        <ng-container>
            <img
                *ngIf="selectedShopName; else loading"
                class="provider-image"
                src="{{ siteImageUrl }}logos/{{ selectedShopName }}-logo-s.png"
                alt="provider logo"
            />
            <ng-template #loading>
                <div class="skeleton"></div>
            </ng-template>
        </ng-container>
    </div>
    <div class="position-absolute close-button" (click)="close()">
        <img src="assets/svg/rokshImages/icons/close.svg" alt="close icon" />
    </div>
    <h1 class="w-100 text-center">{{ dialogTitle }}</h1>

    <div class="infobox">
        <span class="info timeslot"> {{ timeSlotInfo }}</span>

        <div *ngIf="showSelectionNotAllowedMessage" class="selectionInfo">
            <hr class="divider" />
            <span>{{ notForSelectionInfo }}</span>
        </div>
    </div>

    <app-provider-timeslots
        *ngIf="deliveryTimeQueriesDone"
        [selectionAllowed]="false"
        [availableTimeSlotDto]="availableTimeSlotsDto"
        [instantDeliveryAvailable]="instantDeliveryAvailable"
        [instantTimeSlots]="instantTimeSlots"
        [configuredInstantTimeSlots]="configuredInstantWorktimes"
        [areaHasInstantProvider]="areaHasInstantProvider"
        [postCode]="postCode"
        [isModifyDeliveryTime]="false"
        (onShowSelectionIsNotAllowed)="onShowSelectionNotAllowed($event)"
    >
    </app-provider-timeslots>
</div>
