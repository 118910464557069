import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { IOrderService } from './i-order.service';
import { ICheckoutData } from '../../models/DTO/i-checkout-data';
import { FormGroup } from '@angular/forms';
import { IProviderSettings } from '../../models/provider/i-provider-settings';
import { ProviderSettingsRequestDTOShared } from '../../models/DTO/providersettings-request-dto';
import { AddOrUpdateOrderItemDTOShared } from '../../models/order/addorupdateorderitemDTO';
import { TranslateService } from '@ngx-translate/core';
import { ISnackBarService, SNACKBAR_SERVICE_IMPL } from '../snackbar/i-snackbar.service';
import { OrderConcatDetailsDTO } from '../../models/DTO/order-contact-details-dto';
import { OrderCommentDTO } from '../../models/DTO/order-comment-dto';
import { ModifyOrderDeliveryDTOShared } from '../../models/DTO/order-delivery-dto';
import { OrderDeliveryAddressDTO } from '../../models/DTO/order-delivery-address-dto';
import { PaymentProvidersDto } from '../dto/payment-providers.dto';
import { ServiceBaseService } from '../service-base.service';
import {
    CreditCardAuthDto,
    OrderClient,
    TimeSlotClient,
    TimeSlotListRequestDTO,
} from '../..';
import { IMyOrder } from '../../models/order/myorder';

@Injectable({ providedIn: 'root' })
export class OrderService implements IOrderService {
    _canceledOrderSubject: BehaviorSubject<string>;
    totalPriceValidSubject: BehaviorSubject<boolean>;
    private shopServiceUrl: string;
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    calculatedTotalPriceThresholdPercentage = 200;
    calculatedTotalPriceThresholdAmount = 10000;

    constructor(
        @Inject(SNACKBAR_SERVICE_IMPL) private _snackBarService: ISnackBarService,
        private http: HttpClient,
        private serviceBaseService: ServiceBaseService,
        private translate: TranslateService,
        private orderClient: OrderClient,
        private timeSlotClient: TimeSlotClient
    ) {
        this.shopServiceUrl = this.serviceBaseService.getBaseUrl();
        this._canceledOrderSubject = new BehaviorSubject(null);
        this.totalPriceValidSubject = new BehaviorSubject(true);
    }

    setCanceledOrderSource(orderCode: string): void {
        this._canceledOrderSubject.next(orderCode);
    }

    setTotalPriceValidSource(valid: boolean): void {
        this.totalPriceValidSubject.next(valid);
    }

    getCheckoutDto(providercode: any): Observable<any> {
        return this.http
            .post<{ TagObject: any }>(
                this.shopServiceUrl + 'order/GetCheckoutDto',
                { ProviderCode: providercode },
                { headers: this.headers }
            )
            .pipe(
                map((orderDto) => {
                    return orderDto;
                })
            );
    }

    finishOrder(
        checkoutForm: FormGroup,
        providerCode: string,
        basketID: number,
        providerID: number,
        isExpressDelivery: boolean
    ): Observable<any> {
        const formObj = checkoutForm.getRawValue();

        const checkoutData: ICheckoutData = {
            BasketID: basketID,
            ProviderCode: providerCode,
            ProviderID: providerID,
            CheckoutFormDto: formObj,
            IsExpressDelivery: isExpressDelivery,
            OriginBaseUrl: location.origin,
        };

        return this.http
            .post<{ TagObject: any }>(
                this.shopServiceUrl + 'order/AddOrder',
                checkoutData,
                { headers: this.headers }
            )
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    startPayment(orderCode: string): Observable<any> {
        return this.http
            .post<{ TagObject: any }>(
                this.shopServiceUrl + 'order/StartPay',
                { OrderCode: orderCode },
                { headers: this.headers }
            )
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    startPaymentWidget(orderCode: string): Observable<any> {
        return this.http
            .get<{ TagObject: any }>(
                this.shopServiceUrl + 'order/StartPayWidget/' + orderCode,
                { headers: this.headers }
            )
            .pipe(
                map((data) => {
                    return data;
                })
            );
    }

    getCounties() {
        return this.http.get<string[]>(this.shopServiceUrl + `auth/Counties`, {
            headers: this.headers,
        });
    }

    getLocation(address: string): Observable<string> {
        return this.http.get<string>(this.shopServiceUrl + `order/GetLocation`, {
            headers: this.headers,
            params: { address: address },
        });
    }

    getCities(parentId: string, providerCode: string) {
        return this.http.get<string[]>(this.shopServiceUrl + `auth/Cities`, {
            headers: this.headers,
            params: { city: parentId, providerCode: providerCode },
        });
    }

    getPostCodes(parentId: string, providerCode: string) {
        return this.http.get<string[]>(this.shopServiceUrl + `auth/PostCodes`, {
            headers: this.headers,
            params: { zip: parentId, providerCode: providerCode },
        });
    }

    getAvailableTimeSlotList(providerID: string, postCode: string) {
        const timeSlotListRequestDTO = new TimeSlotListRequestDTO({
            providerID: Number(providerID),
            postCode: postCode,
        });
        return this.timeSlotClient.getAvailableTimeSlotList(timeSlotListRequestDTO);
    }

    getOrderList() {
        return this.http
            .post<Array<any>>(this.shopServiceUrl + 'order/GetOrderList', {
                headers: this.headers,
            })
            .pipe(
                map((orderDto) => {
                    return orderDto;
                })
            );
    }

    getOrdersForUser(providerId: number | null): Observable<IMyOrder[]> {
        return this.http.get<IMyOrder[]>(this.shopServiceUrl + 'order/GetOrdersForUser', {
            headers: this.headers,
            params: { providerId: providerId },
        });
    }

    getOrderItemDTO(orderItemID: number) {
        return this.http.get(this.shopServiceUrl + 'order/GetOrderItemDTO', {
            headers: this.headers,
            params: { orderItemId: orderItemID },
        });
    }

    getTimeLeftToModifyOrder(orderCode: string) {
        return this.orderClient.getTimeLeftToModifyOrder(orderCode);
    }

    getOrderTotalPrice(orderId: number, value = 0) {
        return this.http.get(this.shopServiceUrl + 'order/GetOrderTotalPrice', {
            headers: this.headers,
            params: { orderId: orderId, value: value },
        });
    }

    getOrderCustomerPaidTotalPrice(orderId: number, value = 0) {
        return this.http.get(
            this.shopServiceUrl + 'order/GetOrderCustomerPaidTotalPrice',
            {
                headers: this.headers,
                params: { orderId: orderId, value: value },
            }
        );
    }

    getOrderForUser(ordercode: string): Observable<any> {
        const res = this.http
            .get(this.shopServiceUrl + 'order/OrderForUser/' + ordercode, {
                headers: this.headers,
            })
            .pipe(
                map((orderDto) => {
                    return orderDto;
                }),
                catchError((error: HttpErrorResponse) => {
                    if (error.status == 400) {
                        this._snackBarService.openSnackBar(error.error.detail);
                    } else {
                        this.translate
                            .get('error.common-error')
                            .subscribe((text) =>
                                this._snackBarService.openSnackBar(text)
                            );
                    }
                    return throwError(error);
                })
            );
        return res;
    }

    validateCoupon(
        couponCode: string,
        differentBillingAddressSelector: string,
        providerId: number
    ) {
        return this.http
            .post<any>(
                this.shopServiceUrl + 'order/ValidateDiscount',
                {
                    couponCode: couponCode,
                    providerId: providerId,
                    differentBillingAddressSelector: differentBillingAddressSelector,
                },
                { headers: this.headers }
            )
            .pipe(
                map((result) => {
                    return result;
                })
            );
    }

    getInstantDeliveryAreaProvider(providerID: number, zipCode: string) {
        return this.http.get<any[]>(
            this.shopServiceUrl + `api/timeslot/GetInstantDeliveryAreaProvider`,
            {
                headers: this.headers,
                params: { providerID: providerID.toString(), zipCode: zipCode },
            }
        );
    }

    getProduct(productID: number, providerID: number) {
        return this.http.get<any>(this.shopServiceUrl + `order/GetProduct`, {
            headers: this.headers,
            params: { productId: productID, providerID: providerID },
        });
    }

    OnClickReplacementAccept(orderItemID: number) {
        return this.http.post<any>(this.shopServiceUrl + `order/OnReplacementAccept`, {
            orderItemId: orderItemID,
            headers: this.headers,
        });
    }

    OnClickReplacementDecline(orderItemID: number) {
        return this.http.post<any>(this.shopServiceUrl + `order/OnReplacementDecline`, {
            OrderItemID: orderItemID,
            headers: this.headers,
        });
    }

    AddOrUpdateOrderItem(addOrUpdateOrderItemDTO: AddOrUpdateOrderItemDTOShared) {
        return this.http.post<any>(this.shopServiceUrl + `order/AddOrUpdateOrderItem`, {
            OrderCode: addOrUpdateOrderItemDTO.OrderCode,
            ProviderId: addOrUpdateOrderItemDTO.ProviderID,
            ProductId: addOrUpdateOrderItemDTO.ProductID,
            Quantity: addOrUpdateOrderItemDTO.Quantity,
            headers: this.headers,
        });
    }

    DeleteOrderItem(addOrUpdateOrderItemDTO: AddOrUpdateOrderItemDTOShared) {
        return this.http.post<any>(this.shopServiceUrl + `order/DeleteOrderItem`, {
            headers: this.headers,
            OrderCode: addOrUpdateOrderItemDTO.OrderCode,
            ProviderId: addOrUpdateOrderItemDTO.ProviderID,
            ProductId: addOrUpdateOrderItemDTO.ProductID,
            Quantity: addOrUpdateOrderItemDTO.Quantity,
        });
    }

    getProviderSettings(
        request: ProviderSettingsRequestDTOShared
    ): Observable<IProviderSettings[]> {
        return this.http
            .post<IProviderSettings[]>(
                this.shopServiceUrl + 'order/GetProviderSettings',
                request,
                {
                    headers: this.headers,
                }
            )
            .pipe(
                map((settings) => {
                    return settings;
                })
            );
    }

    getPaymentMethods(providerID: number, zip: string): Observable<PaymentProvidersDto> {
        return this.http.get<PaymentProvidersDto>(
            this.shopServiceUrl + 'order/get-payment-methods',
            {
                headers: this.headers,
                params: { providerID: providerID, postcode: zip },
            }
        );
    }

    getUserMarketingPreference(userID: number, providerID: number): Observable<boolean> {
        return this.http.get<boolean>(
            this.shopServiceUrl + `order/GetUserMarketingPreferenceForProvider`,
            {
                headers: this.headers,
                params: {
                    userID: userID.toString(),
                    providerID: providerID.toString(),
                },
            }
        );
    }

    getOrderInvoicesUrls(orderCode: string) {
        return this.http.get<string[]>(this.shopServiceUrl + 'order/order-invoices-url', {
            headers: this.headers,
            params: { orderCode: orderCode },
        });
    }

    cancelOrder(orderCode: string): Observable<any> {
        const res = this.http
            .post<any>(
                this.shopServiceUrl + 'order/cancel-order',
                { OrderCode: orderCode },
                { headers: this.headers }
            )
            .pipe(
                map((data) => {
                    return data;
                }),
                catchError((error: HttpErrorResponse) => {
                    if (error.status == 400) {
                        this._snackBarService.openSnackBar(error.error.detail);
                    } else {
                        this.translate
                            .get('error.cancel-order')
                            .subscribe((text) =>
                                this._snackBarService.openSnackBar(text)
                            );
                        return throwError(error);
                    }
                })
            );
        return res;
    }

    modifyOrderDetails(request: OrderConcatDetailsDTO): Observable<any> {
        const res = this.http
            .post<any>(this.shopServiceUrl + 'order/contact-details', request, {
                headers: this.headers,
            })
            .pipe(
                map((result) => {
                    return result;
                }),
                catchError((error: HttpErrorResponse) => {
                    if (error.status == 400) {
                        this._snackBarService.openSnackBar(error.error.detail);
                    } else {
                        this.translate
                            .get('error.common-error')
                            .subscribe((text) =>
                                this._snackBarService.openSnackBar(text)
                            );
                        return throwError(error);
                    }
                })
            );
        return res;
    }

    modifyOrderComment(request: OrderCommentDTO): Observable<any> {
        const res = this.http
            .post<any>(this.shopServiceUrl + 'order/comment', request, {
                headers: this.headers,
            })
            .pipe(
                map((result) => {
                    return result;
                }),
                catchError((error: HttpErrorResponse) => {
                    if (error.status == 400) {
                        this._snackBarService.openSnackBar(error.error.detail);
                    } else {
                        this.translate
                            .get('error.common-error')
                            .subscribe((text) =>
                                this._snackBarService.openSnackBar(text)
                            );
                        return throwError(error);
                    }
                })
            );
        return res;
    }

    modifyOrderDeliveryTimeSlot(request: ModifyOrderDeliveryDTOShared): Observable<any> {
        const res = this.http
            .post<any>(this.shopServiceUrl + 'order/delivery-timeslot', request, {
                headers: this.headers,
            })
            .pipe(
                map((result) => {
                    return result;
                }),
                catchError((error: HttpErrorResponse) => {
                    if (error.status == 400) {
                        this._snackBarService.openSnackBar(error.error.detail);
                    } else {
                        this.translate
                            .get('error.common-error')
                            .subscribe((text) =>
                                this._snackBarService.openSnackBar(text)
                            );
                    }
                    return throwError(error);
                })
            );
        return res;
    }

    getDeliveryAvailablePostcodes(providerID: number) {
        return this.http.get<string[]>(
            this.shopServiceUrl + `order/delivery-available-postcodes`,
            {
                headers: this.headers,
                params: { providerID: providerID },
            }
        );
    }

    validateBillingAddressPostCode(postCode: string): Observable<boolean> {
        return this.http.get<boolean>(
            this.shopServiceUrl + `order/validate-billing-address-post-code`,
            {
                headers: this.headers,
                params: { postCode: postCode },
            }
        );
    }

    modifyOrderDeliveryAddress(request: OrderDeliveryAddressDTO): Observable<any> {
        const res = this.http
            .post<any>(this.shopServiceUrl + 'order/delivery-address', request, {
                headers: this.headers,
            })
            .pipe(
                map((result) => {
                    return result;
                }),
                catchError((error: HttpErrorResponse) => {
                    if (error.status == 400) {
                        this._snackBarService.openSnackBar(error.error.detail);
                    } else {
                        this.translate
                            .get('error.common-error')
                            .subscribe((text) =>
                                this._snackBarService.openSnackBar(text)
                            );
                    }
                    return throwError(error);
                })
            );
        return res;
    }

    getCustomerCreditCardAuth(
        postCode: string,
        providerID: number,
        lat: number,
        long: number,
        paymentType: string
    ): Observable<any> {
        const dto = new CreditCardAuthDto({
            providerID: providerID,
            postCode: postCode,
            lat: lat,
            long: long,
            paymentType: paymentType,
        });

        return this.http.post<{ TagObject: any }>(
            this.shopServiceUrl + 'order/order-credit-card-auth',
            dto,
            { headers: this.headers }
        );
    }

    public totalPriceValidator(
        finalPrice: number,
        deliveryFee: number,
        totalPrice: number
    ): boolean {
        const caculatedTotalPrice = finalPrice - deliveryFee;

        const isExceedingAmountThreshold =
            caculatedTotalPrice > totalPrice + this.calculatedTotalPriceThresholdAmount;
        const isExceedingPercentageThreshold =
            caculatedTotalPrice >
            (totalPrice * this.calculatedTotalPriceThresholdPercentage) / 100;

        return isExceedingAmountThreshold && isExceedingPercentageThreshold
            ? false
            : true;
    }
}
