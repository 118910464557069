import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-generate-user-basket-guid-dialog',
    templateUrl: './generate-user-basket-guid-dialog.component.html',
    styleUrls: ['./generate-user-basket-guid-dialog.component.scss'],
})
export class GenerateUserBasketGuidDialogComponent implements OnInit {
    guidRouterLink: string;
    guidFullLink: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { guid: string; providerId: number },
        public dialogRef: MatDialogRef<GenerateUserBasketGuidDialogComponent>,
        private snackBar: MatSnackBar,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        forkJoin([
            this.translate.get('router.user'),
            this.translate.get('router.shopping-list'),
        ]).subscribe(([user, shoppingList]: [string, string]) => {
            this.guidRouterLink =
                user + '/' + shoppingList + '/' + this.data.guid + this.data.providerId;
            this.guidFullLink =
                window.location.origin +
                '/' +
                user +
                '/' +
                shoppingList +
                '/' +
                this.data.guid +
                '?providerId=' +
                this.data.providerId.toString();
        });
    }

    onCopy(): void {
        this.translate.get('common.link-copied').subscribe((text) =>
            this.snackBar.open(text, null, {
                verticalPosition: 'top',
                horizontalPosition: 'center',
                duration: 1500,
            })
        );
    }
}
