import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UserShared } from '../models/auth/user.model';
import {
    FacebookLoginProvider,
    GoogleLoginProvider,
    SocialAuthService,
    SocialUser,
} from '@abacritt/angularx-social-login';
import { LogService } from '../services/log.service';
import { BaseLogService } from '../services/base-log.service';
import { RegistrationUserDto } from '../models/auth/registration-user.dto';
import { RegOrigin } from './reg-origin.enum';
import { UserPasswordDto } from '../models/auth/password-user';
import { CookieHandlerService } from '../services/cookie-handler.service';
import { SessionService } from '../services/session/session.service';
import { ServiceBaseService } from '../services/service-base.service';
import * as Sentry from '@sentry/angular-ivy';
import { AcceptTermsDTO } from '../index';
import { DATALAYER_SERVICE_IMPL } from '../services/data-layer/i-data-layer.service';
import {
    DataLayerGa4Service,
    GA4Category,
    GA4EventType,
} from '../services/data-layer/data-layer-ga4.service';

@Injectable({ providedIn: 'root' })
@Sentry.TraceClassDecorator()
export class AuthenticationService {
    private isAuthenticated = false;
    private authStatusListener = new BehaviorSubject<boolean>(false);
    private headers: HttpHeaders;
    private loggedInUser: UserShared;
    shopServiceUrl: string;

    logService: LogService;

    constructor(
        private http: HttpClient,
        private serviceBaseService: ServiceBaseService,
        baseLog: BaseLogService,
        private socialService: SocialAuthService,
        private _cookieHanlderService: CookieHandlerService,
        private sessionService: SessionService,
        private socialAuthService: SocialAuthService,
        @Inject(DATALAYER_SERVICE_IMPL) private dataLayerGa4Service: DataLayerGa4Service
    ) {
        this.logService = new LogService(baseLog);
        this.logService.mainClass = 'AuthService';
        this.shopServiceUrl = this.serviceBaseService.getBaseUrl();
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json' });

        this.sessionService.sessionSubject.subscribe((session) => {
            if (session) {
                this.getServerLoggedInUser();
            }
        });
    }

    isAuth(): boolean {
        return this.isAuthenticated;
    }

    getLoggedInUser(): UserShared {
        return this.isAuthenticated ? this.loggedInUser : null;
    }

    getServerLoggedInUser(): void {
        this.http.get<UserShared>(this.shopServiceUrl + 'auth/user').subscribe(
            (userDto) => {
                this.logService.info('loggedInUser', userDto);

                this.dataLayerGa4Service.datalayerUniversalPush(
                    GA4EventType.login,
                    GA4EventType.login,
                    'user_email',
                    userDto?.Email
                );

                if (userDto) {
                    this.loggedInUser = userDto;
                    this._cookieHanlderService.setCookie('user_email', userDto?.Email);
                    this.checkJwtHasPin().subscribe();
                    this.changeUserAuthStatus(true);
                    this.isAuthenticated = true;
                }
            },
            (error) => {
                this.logService.error('getServerLoggedInUser error:', error);
            }
        );
    }

    public getLoggedInUserForMobile(): Observable<UserShared> {
        return this.http.get<UserShared>(this.shopServiceUrl + 'auth/user');
    }

    public checkServerLoggedInUser(): Observable<UserShared> {
        return this.http.get<UserShared>(this.shopServiceUrl + 'auth/user').pipe(
            map((userDto) => {
                if (userDto) {
                    this.dataLayerGa4Service.datalayerUniversalPush(
                        GA4EventType.login,
                        GA4EventType.login,
                        'user_email',
                        userDto?.Email
                    );
                    return userDto;
                } else {
                    return null;
                }
            })
        );
    }

    getAuthStatusListener(): Observable<boolean> {
        return this.authStatusListener.asObservable();
    }

    async loginWithGoogle(): Promise<Observable<UserShared>> {
        const socialUser: SocialUser = await this.socialService.signIn(
            GoogleLoginProvider.PROVIDER_ID
        );
        const accessToken: string = socialUser.authToken;
        return this.http
            .post<UserShared>(
                this.shopServiceUrl + 'auth/LoginOrRegisterGoogle',
                JSON.stringify(accessToken),
                { headers: this.headers }
            )
            .pipe(
                map((user) => {
                    this.loggedInUser = user;
                    this.loggedInUser.HasPin = false;
                    this.changeUserAuthStatus(true);
                    this._cookieHanlderService.setCookie('user_email', user.Email);
                    this.dataLayerGa4Service.datalayerUniversalPush(
                        GA4EventType.login,
                        GA4EventType.login,
                        'user_email',
                        user.Email
                    );
                    return user;
                })
            );
    }

    async loginWithGoogleForMobile(accessToken: string): Promise<Observable<UserShared>> {
        return this.http
            .post<UserShared>(
                this.shopServiceUrl + 'auth/LoginOrRegisterGoogle',
                JSON.stringify(accessToken),
                { headers: this.headers }
            )
            .pipe(
                map((user) => {
                    this.logService.info('Google login', user);
                    this.loggedInUser = user;
                    this.loggedInUser.HasPin = false;
                    this.changeUserAuthStatus(true);
                    this._cookieHanlderService.setCookie('user_email', user.Email);
                    this.dataLayerGa4Service.datalayerUniversalPush(
                        GA4EventType.login,
                        GA4EventType.login,
                        'user_email',
                        user.Email
                    );
                    return user;
                })
            );
    }

    async loginWithFacebook(): Promise<Observable<UserShared>> {
        const socialUser: SocialUser = await this.socialService.signIn(
            FacebookLoginProvider.PROVIDER_ID
        );
        const accessToken: string = socialUser.authToken;
        return this.http
            .post<UserShared>(
                this.shopServiceUrl + 'auth/LoginOrRegisterFaceBook',
                JSON.stringify(accessToken),
                { headers: this.headers }
            )
            .pipe(
                map((user) => {
                    this.loggedInUser = user;
                    this.loggedInUser.HasPin = false;
                    this.changeUserAuthStatus(true);
                    this._cookieHanlderService.setCookie('user_email', user.Email);
                    this.dataLayerGa4Service.datalayerUniversalPush(
                        GA4EventType.login,
                        GA4EventType.login,
                        'user_email',
                        user.Email
                    );
                    return user;
                })
            );
    }

    async loginWithFacebookForMobile(
        accessToken: string
    ): Promise<Observable<UserShared>> {
        return this.http
            .post<UserShared>(
                this.shopServiceUrl + 'auth/LoginOrRegisterFaceBook',
                JSON.stringify(accessToken),
                { headers: this.headers }
            )
            .pipe(
                map((user) => {
                    this.loggedInUser = user;
                    this.loggedInUser.HasPin = false;
                    this.changeUserAuthStatus(true);
                    this._cookieHanlderService.setCookie('user_email', user.Email);
                    this.dataLayerGa4Service.datalayerUniversalPush(
                        GA4EventType.login,
                        GA4EventType.login,
                        'user_email',
                        user.Email
                    );
                    return user;
                })
            );
    }
    async loginWithAppleForMobile(
        identityToken: string
    ): Promise<Observable<UserShared>> {
        return this.http
            .post<UserShared>(
                this.shopServiceUrl + 'auth/LoginOrRegisterApple',
                JSON.stringify(identityToken),
                { headers: this.headers }
            )
            .pipe(
                map((user) => {
                    this.loggedInUser = user;
                    this.loggedInUser.HasPin = false;
                    this.changeUserAuthStatus(true);
                    this._cookieHanlderService.setCookie('user_email', user.Email);
                    this.dataLayerGa4Service.datalayerUniversalPush(
                        GA4EventType.login,
                        GA4EventType.login,
                        'user_email',
                        user.Email
                    );
                    return user;
                })
            );
    }

    login(email: string, password: string): Observable<UserShared> {
        return this.http
            .post<UserShared>(this.shopServiceUrl + 'auth/login', {
                Email: email,
                Password: password,
            })
            .pipe(
                map((user) => {
                    if (user) {
                        this.loggedInUser = user;
                        this.loggedInUser.HasPin = true;
                        this.changeUserAuthStatus(true);
                        this._cookieHanlderService.setCookie('user_email', user.Email);
                        this.dataLayerGa4Service.datalayerUniversalPush(
                            GA4EventType.login,
                            GA4EventType.login,
                            'user_email',
                            user.Email
                        );
                        this.isAuthenticated = true;
                        this.sessionService.refreshSession().subscribe();
                    }
                    return user;
                })
            );
    }

    acceptTerms(
        aszf: boolean,
        privacy: boolean,
        marketingEmail: boolean,
        marketingSMS: boolean
    ): Observable<UserShared> {
        const acceptTermsDto: AcceptTermsDTO = new AcceptTermsDTO();

        acceptTermsDto.aszf = aszf;
        acceptTermsDto.privacy = privacy;
        acceptTermsDto.marketingEmail = marketingEmail;
        acceptTermsDto.marketingSMS = marketingSMS;

        return this.http
            .post<UserShared>(this.shopServiceUrl + 'auth/accept-terms', acceptTermsDto)
            .pipe(
                map((user) => {
                    if (user) {
                        this.loggedInUser = user;
                        this.loggedInUser.HasPin = true;
                        this.changeUserAuthStatus(true);
                        this._cookieHanlderService.setCookie('user_email', user.Email);
                        this.isAuthenticated = true;
                        this.sessionService.refreshSession().subscribe();
                    }
                    return user;
                })
            );
    }

    resendPassword(email: string): Observable<boolean> {
        return this.http
            .post<boolean>(this.shopServiceUrl + 'auth/resendpassword', {
                Email: email,
            })
            .pipe(
                map((ok) => {
                    return ok;
                })
            );
    }

    logout(): Observable<string> {
        return this.http
            .get<{ Tag: string }>(this.shopServiceUrl + 'auth/logout', {
                headers: this.headers,
            })
            .pipe(
                map((data) => {
                    if (data.Tag === 'OK') {
                        this.changeUserAuthStatus(false);
                    }

                    void this.socialService.signOut();
                    return data.Tag;
                })
            );
    }

    registration(reg: RegistrationUserDto): Observable<UserShared> {
        return this.http
            .post<UserShared>(this.shopServiceUrl + 'auth/register', reg)
            .pipe(
                map((user) => {
                    this.loggedInUser = user;
                    this.loggedInUser.HasPin = true;
                    this.changeUserAuthStatus(true);
                    this._cookieHanlderService.setCookie('user_email', user.Email);
                    this.dataLayerGa4Service.datalayerUniversalPush(
                        GA4EventType.login,
                        GA4EventType.login,
                        'user_email',
                        user.Email
                    );
                    return user;
                })
            );
    }

    saveSocialPwd(socialPswd: string): Observable<any> {
        return this.http
            .post(
                this.shopServiceUrl + 'auth/saveSocialPassword',
                JSON.stringify(socialPswd),
                { headers: this.headers }
            )
            .pipe(
                map((x) => {
                    this.socialUserSetPwd();
                    return x;
                })
            );
    }

    private changeUserAuthStatus(newStatus: boolean) {
        this.isAuthenticated = newStatus;
        if (!this.isAuthenticated) {
            this.loggedInUser = null;
        }
        this.authStatusListener.next(this.isAuthenticated);
    }

    isSocialUser(): boolean {
        return this.loggedInUser.Origin !== RegOrigin.SITE;
    }

    hasSocialPwd(): Observable<boolean> {
        return this.http.get<boolean>(this.shopServiceUrl + 'auth/CheckDbPassword');
    }

    checkJwtHasPin(): Observable<boolean> {
        return this.http.get<boolean>(this.shopServiceUrl + 'auth/CheckJWTPassword').pipe(
            map((hasPin) => {
                this.loggedInUser.HasPin = hasPin;
                return hasPin;
            })
        );
    }

    loginSocialPassword(socialPswd: string): Observable<void> {
        return this.http.post<void>(
            this.shopServiceUrl + 'auth/LoginSocialPassword',
            JSON.stringify(socialPswd),
            { headers: this.headers }
        );
    }

    socialUserSetPwd(): void {
        this.loggedInUser.HasPassword = true;
        this.loggedInUser.HasPin = true;
    }

    socialUserSetHasPin(): void {
        this.loggedInUser.HasPin = true;
    }

    savePassword(reg: UserPasswordDto): Observable<boolean> {
        return this.http
            .post<boolean>(this.shopServiceUrl + 'auth/savepassword', reg)
            .pipe(
                map((isOk) => {
                    return isOk;
                })
            );
    }

    saveProfile(u: UserShared): Observable<UserShared> {
        return this.http
            .post<UserShared>(this.shopServiceUrl + 'auth/saveprofile', u)
            .pipe(
                map((ux) => {
                    this.loggedInUser.Email = ux.Email;
                    this.loggedInUser.FirstName = ux.FirstName;
                    this.loggedInUser.LastName = ux.LastName;
                    this.loggedInUser.BirthDate = ux.BirthDate;
                    this.loggedInUser.MobilePhone = ux.MobilePhone;
                    return ux;
                })
            );
    }

    deleteProfile(): Observable<boolean> {
        return this.http
            .post<null>(this.shopServiceUrl + 'auth/deleteprofile', null)
            .pipe(
                map((ux) => {
                    return ux;
                })
            );
    }

    subscribeForGoogleLogin(callback: (user: UserShared) => void): void {
        this.socialAuthService.authState.subscribe((user) => {
            if (user != null) {
                this.socialAuthService
                    .getAccessToken(GoogleLoginProvider.PROVIDER_ID)
                    .then((accessToken) => {
                        this.loginWithGoogleForMobile(accessToken).then(
                            (loginObservable) => {
                                loginObservable.subscribe(
                                    (user) => {
                                        this.dataLayerGa4Service.datalayerUniversalPush(
                                            GA4Category.authentication,
                                            'login with Google'
                                        );
                                        callback(user);
                                        this.sessionService
                                            .refreshSession()
                                            .pipe(take(1))
                                            .subscribe();
                                    },
                                    (error) => {
                                        console.error(error);
                                    }
                                );
                            }
                        );
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        });
    }
}
