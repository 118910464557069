import { Component, OnInit } from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    UntypedFormControl,
    Validators,
} from '@angular/forms';
import {
    AuthenticationService,
    ServiceBaseService,
    UserShared,
} from 'projects/library-shared/src/public-api';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DialogService } from '../../services/dialog.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
    faAngleRight = faAngleRight;
    isSubmitted: boolean;

    preferredCountries: CountryISO[] =
        ServiceBaseService.countrycode === 'hu'
            ? [CountryISO.Hungary, CountryISO.Austria]
            : [CountryISO.Austria, CountryISO.Hungary];
    SearchCountryField = SearchCountryField;
    PhoneNumberFormat = PhoneNumberFormat;

    profileForm = this.formBuilder.group({
        FirstName: new UntypedFormControl('', [
            Validators.required,
            Validators.minLength(3),
        ]),
        LastName: new UntypedFormControl('', [
            Validators.required,
            Validators.minLength(3),
        ]),
        Email: new UntypedFormControl('', [Validators.required, Validators.email]),
        Birthdate: new UntypedFormControl('', Validators.required),
        MobilePhone: new UntypedFormControl('', [Validators.minLength(7)]),
    });

    phoneForm = new FormGroup({
        phone: new FormControl(undefined, [Validators.required, Validators.minLength(7)]),
    });

    passwordForm = this.formBuilder.group(
        {
            OldPassword: ['', [Validators.required]],

            Password: [
                '',
                [
                    Validators.required,
                    Validators.minLength(7),
                    Validators.pattern(
                        /^(?=.*[a-záéíóúöüőű])(?=.*[A-ZÁÉÍÓÚÖÜŐŰ])(?=.*\d)[a-zA-ZÁáÉéÍíÓóÚúÖöÜüŐőŰű\d]{7,}[\w~@#$%^&*+-=>€§¨´£'_\\|`{}:,;!.?"()[\]-]*$/
                    ),
                ],
            ],
            ConfirmPassword: ['', [Validators.required]],
        },
        { validator: this.checkPasswords }
    );

    step = 0;
    user: UserShared = new UserShared();
    maxBirthdate: Date = new Date();

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthenticationService,
        private snackBar: MatSnackBar,
        private dialogService: DialogService,
        private translate: TranslateService,
        private title: Title,
        private datePipe: DatePipe
    ) {}

    ngOnInit(): void {
        this.translate.get('common.settings-title').subscribe((title) => {
            this.title.setTitle(title);
        });
        this.user = this.authService.getLoggedInUser();

        if (this.user === null) {
            setTimeout(() => {
                this.user = this.authService.getLoggedInUser();
                if (this.user !== null) {
                    this.profileForm.setValue({
                        Email: this.user.Email,
                        FirstName: this.user.FirstName,
                        LastName: this.user.LastName,
                        Birthdate: this.user.BirthDate,
                        MobilePhone: this.user.MobilePhone,
                    });
                }
            }, 1300);
        } else
            this.profileForm.setValue({
                Email: this.user.Email,
                FirstName: this.user.FirstName,
                LastName: this.user.LastName,
                Birthdate: this.user.BirthDate,
                MobilePhone: this.user.MobilePhone,
            });

        if (this.profileForm)
            this.phoneForm.controls.phone.setValue(
                this.profileForm.get('MobilePhone').value
            );

        this.maxBirthdate.setFullYear(this.maxBirthdate.getFullYear() - 18);
    }

    checkPasswords(group: FormGroup) {
        const pass = group.controls.Password.value;
        const confirmPass = group.controls.ConfirmPassword.value;
        if (
            !new RegExp(
                /^[\w~@#$%^&*+-=>€§¨´'£_\\|`{}ZÁáÉéÍíÓóÚúÖöÜüŐőŰű:,;!.?"()[\]-]*$/
            ).test(pass)
        ) {
            group.controls.Password.setErrors({ errorSpecialCharacter: true });
            return null;
        }

        if (pass === confirmPass ? null : { notSame: true }) {
            group.controls.ConfirmPassword.setErrors({ notSame: true });
            return { notSame: true };
        } else {
            group.controls.ConfirmPassword.setErrors(null);
            return null;
        }
    }

    onSaveProfile() {
        const u: UserShared = new UserShared();
        const profileForm = this.profileForm.value;
        u.UserID = this.user.UserID;
        u.BirthDate = new Date(
            this.datePipe.transform(profileForm.Birthdate, 'yyyy-MM-dd')
        );
        u.MobilePhone = profileForm.MobilePhone;
        u.FirstName = profileForm.FirstName;
        u.LastName = profileForm.LastName;
        u.Email = profileForm.Email;

        this.authService.saveProfile(u).subscribe(() => {
            this.translate.get('profile.modified-data').subscribe((text) =>
                this.snackBar.open(text, null, {
                    verticalPosition: 'top',
                    horizontalPosition: 'center',
                    duration: 3000,
                })
            );
        });
    }

    onSavePassword() {
        this.authService
            .savePassword({
                OldPassword: this.passwordForm.controls['OldPassword'].value,
                Password: this.passwordForm.controls['Password'].value,
                ConfirmPassword: this.passwordForm.controls['ConfirmPassword'].value,
                Guid: null,
                Token: null,
            })
            .subscribe(
                () => {
                    this.translate.get('profile.modified-password').subscribe((text) =>
                        this.snackBar.open(text, null, {
                            verticalPosition: 'top',
                            horizontalPosition: 'center',
                            duration: 3000,
                        })
                    );
                },
                (passwordError) => {
                    this.snackBar.open(passwordError.error.ErrorTxt, null, {
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                        duration: 3000,
                    });
                }
            );
    }

    onDeleteProfile() {
        forkJoin([
            this.translate.get('profile.confirm-delete'),
            this.translate.get('profile.deleted-account'),
        ]).subscribe(([confirm, deleted]: [string, string]) => {
            this.dialogService.confirmDialog(confirm).then((isok) => {
                if (isok) {
                    this.authService.deleteProfile().subscribe(() => {
                        const ref = this.snackBar.open(deleted, null, {
                            verticalPosition: 'top',
                            horizontalPosition: 'center',
                            duration: 2000,
                        });
                        ref.afterDismissed().subscribe(() => {
                            document.location.href = '/';
                        });
                    });
                }
            });
        });
    }

    onPhoneChanges(): void {
        const phone = this.phoneForm.controls.phone.value;
        if (phone?.number && phone.number !== this.profileForm.get('MobilePhone').value) {
            this.profileForm.get('MobilePhone').setValue(phone.number);
            this.profileForm.markAsDirty();
        }
    }
}
