import { CategoryShared } from '../category/category.model';
import {
    ProductListResult,
    ProductListResultNew,
} from '../product/product-list-result.model';
import { ProductBlockDto } from './product-block-dto';

export class DashboardProductListDto {
    SubCategories: CategoryShared[];
    ProductListResults: ProductListResult[];
    ProductBlockList: ProductBlockDto[];
}

export class DashboardProductListDtoForMobile {
    SubCategories: CategoryShared[];
    ProductListResults: ProductListResultNew[];
    ProductBlockList: ProductBlockDto[];
}
