import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    ServiceBaseService,
    SharedModalService,
} from 'projects/library-shared/src/public-api';

@Component({
    selector: 'app-share-basket-button',
    templateUrl: './share-basket-button.component.html',
    styleUrls: ['./share-basket-button.component.scss'],
})
export class ShareBasketButtonComponent {
    @Input() userMasterBasketGUID;
    @Input() masterBasketID;
    @Input() providerId;
    @Input() showLink;
    @Input() customStyle = 'medium';

    buttonText = '';
    siteImageUrl: string;

    constructor(
        private sharedModalService: SharedModalService,
        private translate: TranslateService,
        baseService: ServiceBaseService
    ) {
        this.siteImageUrl = baseService.siteImageUrl;
        this.translate
            .get('user-basket.send-in-mail-to-myself')
            .subscribe((text) => (this.buttonText = text));
    }

    onShareLinkByEmail(): void {
        void this.sharedModalService.openShareShoppingList(
            this.masterBasketID,
            this.userMasterBasketGUID,
            this.providerId,
            this.showLink
        );
    }
}
