<div class="w-100 h-100 p-3">
    <div class="d-flex flex-row align-items-center">
        <img class="provider-logo pe-3" [src]="providerLogo" />
        <div class="d-flex flex-row w-100 justify-content-between align-items-center">
            <div class="d-flex flex-column w-100">
                <span class="provider-name-text">{{ basket.providerName }}</span>
                <span class="quantity-text"
                    >{{ 'common.quantity' | translate }}:
                    {{ basket.basketItemList.length }}
                    {{ 'common.piece' | translate }}</span
                >
            </div>
            <span class="price-text">
                {{ basketPrice | currencyFormatted }}&nbsp;{{
                    'common.currency' | translate
                }}
            </span>
        </div>
    </div>

    <button
        class="roksh-btn-primary continue-shopping-btn my-2"
        (click)="selectShop(basket.providerName.toLowerCase())"
    >
        {{ 'common.i-am-continue-shopping' | translate }}
    </button>
    <div class="vl"></div>
</div>
