/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';
import { BasketHeaderComponent } from './components/basket/basket-header/basket-header.component';
import { RouterModule } from '@angular/router';
import { ProductReplaceDialogComponent } from './containers/product-replace/product-replace.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { UserBasketListComponent } from './containers/user-basket-list/user-basket-list.component';
import { UserBasketDetailsComponent } from './containers/user-basket-list/user-basket-details/user-basket-details.component';
import { FavoriteListComponent } from './containers/favorite-list/favorite-list.component';
import { EmailActivationComponent } from './containers/landing/email-activation/email-activation.component';
import { BasketEmptyComponent } from './components/basket/basket-empty/basket-empty.component';
import { MustMatchDirective } from './directive/must-match.directive';
import { SocialPwdWrapperComponent } from './components/header/log-in/social-pwd/social-pwd-wrapper/social-pwd-wrapper.component';
import { SocialPwdComponent } from './components/header/log-in/social-pwd/social-pwd.component';
import { PostcodeComponent } from './components/header/postcode/postcode.component';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { LogInComponent } from './components/header/log-in/log-in.coponent';
import { MobilMenuComponent } from './components/header/mobil-menu/mobil-menu.component';
import { CategoryPageComponent } from './containers/category-page/category-page.component';
import { BasketNewWrapperComponent } from './components/basket/basket-new-wrapper/basket-new-wrapper.component';
import { ProductGroupComponent } from './components/product-group/product-group.component';
import { LandingStaticFirstComponent } from './containers/dashboard/landing-static-first/landing-static-first.component';
import { LandingStaticSecondComponent } from './containers/dashboard/landing-static-second/landing-static-second.component';
import { CategoryCardComponent } from './components/category-card/category-card.component';
import { PopularProductsNewComponent } from './containers/dashboard/popular-products-new/popular-products-new.component';
import { ContestComponent } from './containers/contest/contest.component';
import { CustomCategoryBlockComponent } from './components/custom-category-block/custom-category-block.component';
import { ShopSelectDialogSeparatedComponent } from './components/shop-select-dialog-separated/shop-select-dialog-separated.component';
import { SingleUserBasketDialogComponent } from './containers/user-basket-list/single-user-basket-dialog/single-user-basket-dialog.component';
import { SaveUserBasketBtnComponent } from './components/save-user-basket-btn/save-user-basket-btn.component';
import { GenerateUserBasketGuidDialogComponent } from './containers/user-basket-list/generate-user-basket-guid-dialog/generate-user-basket-guid-dialog.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ShareBasketButtonComponent } from './containers/user-basket-list/share-basket-button/share-basket-button.component';
import { OrderItemCard } from './components/orderitem-card/orderitem-card.component';
import { ProviderTimeslotsComponent } from './components/provider-timeslots/provider-timeslots.component';
import { TranslateModule } from '@ngx-translate/core';
import { ExpressTimeslotComponent } from './components/provider-timeslots/express-timeslot/express-timeslot.component';
import { ProviderSelectorComponent } from './components/provider-selector/provider-selector.component';
import { AddAddressButtonComponent } from './components/address/add-address-button/add-address-button.component';
import { AddAddressInputComponent } from './components/address/add-address-input/add-address-input.component';
import { CategoryMenuComponent } from './components/category-menu/category-menu.component';
import { BasketProviderSelectorComponent } from './components/basket/basket-provider-selector/basket-provider-selector.component';
import { BasketProviderComponent } from './components/basket/basket-provider-selector/basket-provider/basket-provider.component';

import { AppDownloadComponent } from './components/app-download/app-download.component';
import { PersonalShopperComponent } from './components/personal-shopper/personal-shopper.component';
import { ShopSelectComponent } from './components/shop-select/shop-select.component';
import { CategoryListComponent } from './components/category-list/category-list.component';
import { EarliestTimeSlotComponent } from './components/provider-timeslots/earliest-timeslot/earliest-timeslot.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { CategoryBlockHeaderComponent } from './components/category-block-header/category-block-header.component';
import { ProductCardLinkDirective } from './directive/product-card-link.directive';
import { ProductCardWrapperComponent } from './components/product-card-wrapper/product-card-wrapper.component';
import {
    ArchieModule,
    DialogsModule,
    HtmlMarketingModule,
    LibrarySharedModule,
    ProductModule,
    ProviderSelectModule,
    RokshDiscountModule,
    SafePipe,
    SharedPipesModule,
    ShoppingListModule,
    ThemePipeModule,
    TipListModule,
} from 'projects/library-shared/src/public-api';
import { InformationBlockComponent } from './containers/dashboard/information-block/information-block.component';

@NgModule({
    declarations: [
        BasketHeaderComponent,
        ProductReplaceDialogComponent,
        UserBasketListComponent,
        UserBasketDetailsComponent,
        FavoriteListComponent,
        EmailActivationComponent,
        BasketEmptyComponent,
        MustMatchDirective,
        SocialPwdWrapperComponent,
        SocialPwdComponent,
        PostcodeComponent,
        ShopSelectDialogSeparatedComponent,
        LogInComponent,
        AddAddressButtonComponent,
        AddAddressInputComponent,
        MobilMenuComponent,
        CategoryPageComponent,
        BasketNewWrapperComponent,
        ProductGroupComponent,
        LandingStaticFirstComponent,
        LandingStaticSecondComponent,
        CategoryCardComponent,
        CategoryMenuComponent,
        PopularProductsNewComponent,
        ContestComponent,
        CustomCategoryBlockComponent,
        SingleUserBasketDialogComponent,
        SaveUserBasketBtnComponent,
        GenerateUserBasketGuidDialogComponent,
        ShareBasketButtonComponent,
        OrderItemCard,
        ProviderTimeslotsComponent,
        ExpressTimeslotComponent,
        ProviderSelectorComponent,
        PersonalShopperComponent,
        AppDownloadComponent,
        ShopSelectComponent,
        SafePipe,
        BasketProviderSelectorComponent,
        BasketProviderComponent,
        CategoryListComponent,
        EarliestTimeSlotComponent,
        CategoryBlockHeaderComponent,
        ProductCardLinkDirective,
        ProductCardWrapperComponent,
        InformationBlockComponent,
    ],
    entryComponents: [
        ProductReplaceDialogComponent,
        EmailActivationComponent,
        SocialPwdWrapperComponent,
        ShopSelectDialogSeparatedComponent,
        SingleUserBasketDialogComponent,
        GenerateUserBasketGuidDialogComponent,
        ShopSelectComponent,
    ],
    imports: [
        TranslateModule,
        CommonModule,
        FormsModule,
        FontAwesomeModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatSelectModule,
        MatTabsModule,
        MatGridListModule,
        CarouselModule,
        MatInputModule,
        MatTooltipModule,
        MatMenuModule,
        RouterModule,
        LazyLoadImageModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        DigitOnlyModule,
        ClipboardModule,
        ThemePipeModule,
        LibrarySharedModule,
        ProductModule,
        MatButtonModule,
        MatDialogModule,
        RokshDiscountModule,
        ProviderSelectModule,
        ShoppingListModule,
        TipListModule,
        SharedPipesModule,
        DialogsModule,
        HtmlMarketingModule,
        ArchieModule,
    ],
    exports: [
        FontAwesomeModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTabsModule,
        MatGridListModule,
        CarouselModule,
        MatTooltipModule,
        MatInputModule,
        BasketHeaderComponent,
        ProductReplaceDialogComponent,
        LazyLoadImageModule,
        MatMenuModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MustMatchDirective,
        SocialPwdWrapperComponent,
        SocialPwdComponent,
        PostcodeComponent,
        ShopSelectDialogSeparatedComponent,
        LogInComponent,
        AddAddressButtonComponent,
        AddAddressInputComponent,
        MobilMenuComponent,
        BasketNewWrapperComponent,
        ProductGroupComponent,
        LandingStaticFirstComponent,
        LandingStaticSecondComponent,
        CategoryCardComponent,
        CategoryMenuComponent,
        PopularProductsNewComponent,
        CustomCategoryBlockComponent,
        OrderItemCard,
        ProviderTimeslotsComponent,
        ProviderSelectorComponent,
        PersonalShopperComponent,
        AppDownloadComponent,
        ShopSelectComponent,
        SafePipe,
        ProductModule,
        MatButtonModule,
        MatDialogModule,
        RokshDiscountModule,
        ProviderSelectModule,
        ShoppingListModule,
        TipListModule,
        SharedPipesModule,
        ProductCardWrapperComponent,
        DialogsModule,
        HtmlMarketingModule,
        ArchieModule,
    ],
    providers: [SafePipe, MatDatepickerModule, DatePipe],
})
export class SharedModule {}
