import { EventEmitter, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { IRealtimeClientService } from './irealtime-client.service';

@Injectable()
export class RealtimeClientService implements IRealtimeClientService {
    private _isConnectionEstablished = false;
    private _hubConnection: HubConnection;
    private _intentionallyStopped = false;

    public connectionEstablished = new EventEmitter<boolean>();
    public connectionId: string;

    public initHubConnection(hubName: string, baseUrl = '') {
        this._hubConnection = new HubConnectionBuilder()
            .withUrl(baseUrl + '/' + hubName)
            .withAutomaticReconnect()
            .build();

        this._hubConnection.serverTimeoutInMilliseconds = 900000;
        this._hubConnection.onclose(() => {
            this._isConnectionEstablished = false;
            if (!this._intentionallyStopped) {
                setTimeout(() => this.connect(), 1000);
            }
        });
    }

    public connect(): Promise<void> {
        if (!this._isConnectionEstablished) {
            return this._hubConnection
                .start()
                .then(() => {
                    this._isConnectionEstablished = true;
                    this._intentionallyStopped = false;
                    this.connectionEstablished.emit(this._isConnectionEstablished);
                })
                .catch(() => {
                    this._isConnectionEstablished = false;
                    setTimeout(() => this.connect(), 1000);
                });
        } else {
            return new Promise<void>(null);
        }
    }

    public getConnectionId = () => {
        this._hubConnection.invoke('getconnectionid').then((data) => {
            this.connectionId = data;
        });
    };

    public disconnect(): Promise<void> {
        this._intentionallyStopped = true;
        return this._hubConnection?.stop();
    }

    public invoke<T>(methodName: string, ...args: any): Promise<T> {
        return this._hubConnection.invoke(methodName, ...args);
    }

    public on(methodName: string, callback): void {
        this._hubConnection.on(methodName, (...args: any) => callback(args));
    }
}
