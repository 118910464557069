<div
    *ngIf="earliestTimeSlot.isInstant"
    class="delivery-days"
    (click)="
        selectEarliestTimeSlot(
            earliestTimeSlot.instantTimeSlot,
            earliestTimeSlotId,
            false
        )
    "
>
    <a>
        <div
            class="card-container"
            [ngClass]="{
                active: earliestTimeSlotId === activeEarliestTimeSlotId
            }"
        >
            <div class="text-center p-2">
                <span class="fw-bold py-2 time-slot.express-delivery">{{
                    'time-slot.express-delivery'
                        | translate
                            : { interval: earliestTimeSlot.instantTimeSlot.interval }
                }}</span>
                <hr />
                <span class="text-success"
                    >{{ deliveryFee | currencyFormatted }}
                    {{ 'common.currency' | translate }}</span
                >
            </div>
        </div>
    </a>
</div>

<div
    *ngIf="!earliestTimeSlot.isInstant"
    class="p-2 delivery-days card-container"
    [ngClass]="{
        active: earliestTimeSlotId === activeEarliestTimeSlotId,
        disabled: disableEarliestTimeSlot,
        'not-available': disableEarliestTimeSlot,
        'container-opacity': !disableEarliestTimeSlot
    }"
    (click)="
        selectEarliestTimeSlot(
            earliestTimeSlot.timeSlot,
            earliestTimeSlotId,
            disableEarliestTimeSlot
        )
    "
>
    <a class="h-100 text-decoration-none">
        <div class="h-100">
            <div class="h-100 text-center d-flex flex-column justify-content-between">
                <div class="d-flex flex-column justify-content-center align-items-center">
                    <div class="fw-semibold">{{ dayText }}</div>
                    <div class="text-gray">{{ dateText }}</div>
                </div>
                <hr class="m-0" />
                <div class="d-flex flex-column justify-content-center align-items-center">
                    <div class="text-size-14 fw-semibold">
                        {{ earliestTimeSlot.timeSlot.startHour }}.00 -
                        {{ earliestTimeSlot.timeSlot.endHour }}.00
                    </div>
                    <div class="text-size-13 text-success">
                        {{ deliveryFee | currencyFormatted }}
                        {{ 'common.currency' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </a>
</div>
