import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import {
    CheckoutService,
    CheckoutStep,
    CountryCodes,
    DATALAYER_SERVICE_IMPL,
    DataLayerGa4Service,
    GA4EventType,
    PaymentType,
    ServiceBaseService,
    SnackBarService,
} from 'projects/library-shared/src/public-api';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-checkout-payment',
    templateUrl: './checkout-payment.component.html',
    styleUrls: ['./checkout-payment.component.scss'],
})
export class CheckoutPaymentComponent implements OnInit {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    faInfoCircle = faInfoCircle;
    CountryCodes = CountryCodes;
    countryCode = this.CountryCodes.hu;

    checkoutForm: FormGroup;
    isSubmitted = false;
    selectedIndex: number;
    paymentStepIndex = CheckoutStep.Payment;
    hasBeenActivated = false;
    defaultPaymentProviders: string[];
    paymentProviders: string[];
    paymentProviderInfoImgSrc: string;
    customerCreditCardAuth = false;

    siteImageUrl = '';

    public get paymentStepIsExpanded(): boolean {
        return this.selectedIndex === this.paymentStepIndex;
    }

    public get isAtCountryCode(): boolean {
        return this.countryCode === CountryCodes.at;
    }

    public get isHuCountryCode(): boolean {
        return this.countryCode === CountryCodes.hu;
    }

    public get paymentStepIsActive(): boolean {
        return this.selectedIndex > this.paymentStepIndex;
    }

    public get paymentStepIsDisabled(): boolean {
        return !this.hasBeenActivated;
    }

    get paymentControl(): AbstractControl {
        return this.checkoutForm.get('paymentForm.PaymentType');
    }

    get toSControl(): AbstractControl {
        return this.checkoutForm.get('paymentForm.ToSAccepted');
    }

    get privacyControl(): AbstractControl {
        return this.checkoutForm.get('paymentForm.PrivacyAccepted');
    }

    get postCodeControl(): AbstractControl {
        return this.checkoutForm.get('addressForm.PostCode');
    }

    constructor(
        private checkoutService: CheckoutService,
        private snackBarService: SnackBarService,
        @Inject(DATALAYER_SERVICE_IMPL) private _DataLayerGa4Service: DataLayerGa4Service,
        private translate: TranslateService,
        public baseService: ServiceBaseService
    ) {
        this.countryCode = ServiceBaseService.getCountryCode();
        this.siteImageUrl = this.baseService.getSiteImageUrl();
    }

    ngOnInit(): void {
        this.checkoutService.formGroup$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((form) => (this.checkoutForm = form));

        this.checkoutService.selectedStep$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((index) => {
                if (index === this.paymentStepIndex) {
                    this.hasBeenActivated = true;
                }
                this.selectedIndex = index;
            });

        this.checkoutService.isSubmitted$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((isSubmitted) => (this.isSubmitted = isSubmitted));

        this.checkoutService.defaultPaymentProviders$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((paymentProviders) => {
                this.defaultPaymentProviders = paymentProviders;
                this.paymentProviders = paymentProviders;
                this.setDefaultPaymentType();
            });

        this.checkoutForm
            .get('paymentForm.PaymentType')
            .valueChanges.subscribe((value) => {
                this.setPaymentProviderInfoImg(value);
            });

        this.checkoutForm
            .get('wishListForm')
            .valueChanges.subscribe((wishListProviderShops) => {
                if (wishListProviderShops.wishListProviderShops.length > 0) {
                    this.defaultPaymentProviders = this.defaultPaymentProviders.filter(
                        (item) => item === 'RevolutCard'
                    );
                } else {
                    this.defaultPaymentProviders = this.paymentProviders;
                }
            });

        this.checkoutService.user$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
            this.checkoutService.setFormGroupSource(this.checkoutForm);
        });

        this.checkoutService.customerCreditCardAuth$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((available) => (this.customerCreditCardAuth = available));

        this.paymentControl.valueChanges.subscribe((value) => {
            this.checkPaymentType(value);
        });
    }

    checkPaymentType(paymentType: string): void {
        this.checkoutService
            .getPaymentProvidersByPostCode(this.postCodeControl.value)
            .subscribe((value) => {
                if (!value.PaymentProviders.includes(paymentType)) {
                    this.translate
                        .get('messages.not-allowed-payment-provider')
                        .subscribe((text) => {
                            this.snackBarService.openSnackBar(text);
                            this.paymentControl.setValue(null);
                        });
                }
            });
    }

    setStep(index: number): void {
        this.checkoutService.setSelectedStepSource(index);
        const scrWidth = window.innerWidth;

        if (scrWidth < 992) {
            window.scroll(0, 0);
        } else {
            if (index != 0) document.getElementById('summaryPanel').scrollIntoView();
        }
    }

    nextStep(): void {
        if (!this.paymentControl.value) {
            this.translate.get('checkout.payment-provider-required').subscribe((text) => {
                this.snackBarService.openErrorSnackBar(text);
            });

            return;
        }

        if (this.privacyControl.value === false) {
            this.translate.get('checkout.data-protection-required').subscribe((text) => {
                this.snackBarService.openErrorSnackBar(text);
            });

            return;
        } else if (this.toSControl.value === false) {
            this.translate.get('checkout.terms-required').subscribe((text) => {
                this.snackBarService.openErrorSnackBar(text);
            });

            return;
        }

        const scrWidth = window.innerWidth;
        if (scrWidth < 992) window.scroll(0, 0);

        const summaryPanel: HTMLElement = <HTMLElement>(
            document.getElementById('summaryPanel')
        );

        summaryPanel.classList.add('in-focus');

        this._DataLayerGa4Service.datalayerUniversalPush('Checkout', 'ToSummary');

        if (this.paymentControl.value) {
            this.checkoutService.getCustomerCreditCardAuth();
        }

        this._DataLayerGa4Service.datalayerUniversalPush(
            GA4EventType.payment_info,
            GA4EventType.payment_info
        );

        this.setStep(CheckoutStep.Summary);
    }

    private setPaymentProviderInfoImg(paymentProvider: string): void {
        const windowSize = window.innerWidth < 768 ? 's' : 'l';
        const hasCustomerCreditCardAuth = this.customerCreditCardAuth ? '-cardauth' : '';

        this.paymentProviderInfoImgSrc =
            'https://weshopsiteimages.azureedge.net/payment-info-images/' +
            this.countryCode +
            '-' +
            paymentProvider +
            '-' +
            windowSize +
            hasCustomerCreditCardAuth +
            '.jpg';
    }

    private setDefaultPaymentType() {
        // const payOnDeliveryCard = this.defaultPaymentProviders.find(
        //     (item) => item === PaymentType.PayOnDeliveryCard
        // );

        // const paymentType = payOnDeliveryCard
        //     ? PaymentType.PayOnDeliveryCard
        //     : this.isHuCountryCode
        //     ? PaymentType.BarionCard
        //     : PaymentType.RevolutCard;

        const paymentType = PaymentType.RevolutCard;

        this.checkoutForm.get('paymentForm.PaymentType').setValue(paymentType);
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
