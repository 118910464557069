<div class="row m-0 w-100 border-bottom py-3 px-0 position-relative">
    <div
        class="col-7 d-flex align-items-center ps-0 position-relative overflow-hidden"
        [@inOutText]="isProductContained && !inProgress ? 'open' : 'closed'"
    >
        <div class="checked-container">
            <fa-icon [icon]="faChecked" class="text-success"></fa-icon>
        </div>
        <div class="text-wrap">{{ userBasket.basketName }}</div>
    </div>
    <div class="col-5 px-0 d-flex justify-content-end">
        <div class="col-3 z-1">
            <fa-icon
                *ngIf="isProductContained"
                class="pointer button animate__faster animate__animated animate__backInRight"
                [class.animate__backOutRight]="removeInProgress"
                [icon]="faMinusCircle"
                (click)="onRemoveProduct()"
            ></fa-icon>
        </div>
        <div class="col-6 px-1 z-1">
            <select
                *ngIf="isProductContained"
                [disabled]="inProgress"
                [(ngModel)]="selectedQuantity"
                [class.animate__backOutRight]="removeInProgress"
                (change)="onChangeQuantity()"
                class="w-100 animate__animated animate__faster animate__backInRight"
            >
                <option *ngFor="let q of quantityList" [ngValue]="q[0]">
                    {{ q[1] }}
                </option>
            </select>
        </div>
        <div class="col-3 z-2">
            <fa-icon
                class="pointer button button-plus"
                [icon]="faPlusCircle"
                (click)="onAddProduct()"
            ></fa-icon>
        </div>
    </div>
    <ng-template [ngIf]="inProgress">
        <div
            class="w-100 h-100 d-flex justify-content-center align-items-center position-absolute me-auto ms-auto start-0 end-0 top-0 bottom-0"
        >
            <fa-icon *ngIf="inProgress" [icon]="faSpinner" [spin]="true"></fa-icon>
        </div>
    </ng-template>
</div>
