import { Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { UserBasketDto } from '../../../index';
import { FormatterService } from '../../../services/formatter.service';
import { SessionService } from '../../../services/session/session.service';
import { ServiceBaseService } from '../../../services/service-base.service';

@Component({
    selector: 'lib-shopping-list-card',
    templateUrl: './shopping-list-card.component.html',
    styleUrls: ['./shopping-list-card.component.scss'],
    animations: [
        trigger('selectAnimation', [
            transition(':enter', [
                style({ transform: 'translateX(-100%)' }),
                animate('150ms ease-in', style({ transform: 'translateX(0%)' })),
            ]),
            transition(':leave', [
                animate('150ms ease-out', style({ transform: 'translateX(-100%)' })),
            ]),
        ]),
    ],
})
export class ShoppingListCardComponent {
    @Input() userBasket: UserBasketDto;
    @Input() isSelected: boolean;
    @Output() selectBasket: EventEmitter<UserBasketDto> = new EventEmitter();

    siteImageUrl: string;
    providerSelected: boolean;

    constructor(
        public formatterService: FormatterService,
        sessionService: SessionService,
        baseService: ServiceBaseService
    ) {
        this.siteImageUrl = baseService.getSiteImageUrl();
        this.providerSelected = sessionService.isProviderSelectedForShopping.getValue();
    }
}
