<div class="p-3" [class.pt-5]="isMobile">
    <div class="d-flex" *ngIf="!isMobile && showLink">
        <mat-form-field class="w-100">
            <input [value]="link" matInput type="text" disabled />
        </mat-form-field>
        <button
            class="link-button ms-3 px-3"
            [cdkCopyToClipboard]="link"
            (click)="successfullyCopyMessage()"
        >
            {{ 'common.copy-link' | translate }}
        </button>
    </div>
    <h5 class="pb-3 fw-bold">
        {{ 'user-basket.which-mail-to-send' | translate }}
    </h5>

    <form [formGroup]="sendBasketForm" (ngSubmit)="onShare()">
        <div class="row">
            <div class="col">
                <mat-form-field class="w-100">
                    <mat-label>{{ 'common.firstname' | translate }}</mat-label>
                    <input matInput type="text" formControlName="FirstName" />

                    <mat-error
                        *ngIf="
                            sendBasketForm.get('FirstName').errors &&
                            sendBasketForm.get('FirstName').touched
                        "
                    >
                        {{ 'log-in.firstname-required' | translate }}</mat-error
                    >
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field class="w-100">
                    <mat-label>{{ 'common.lastname' | translate }}</mat-label>
                    <input matInput type="text" formControlName="LastName" />

                    <mat-error
                        *ngIf="
                            sendBasketForm.get('LastName').errors &&
                            sendBasketForm.get('LastName').touched
                        "
                        >{{ 'log-in.lastname-required' | translate }}</mat-error
                    >
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <mat-form-field>
                <mat-label>{{ 'common.e-mail' | translate }}</mat-label>
                <input matInput type="text" formControlName="Email" />

                <mat-error
                    *ngIf="
                        sendBasketForm.get('Email').errors &&
                        sendBasketForm.get('Email').touched
                    "
                >
                    {{ 'log-in.email-required' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="d-flex w-100 justify-content-center" [class.flex-column]="isMobile">
            <button class="btn w-75" [class.w-100]="isMobile" type="submit">
                <fa-icon *ngIf="isLoading" [icon]="faSpinner" [spin]="true"></fa-icon>
                <fa-icon *ngIf="!isLoading" [icon]="faMail"></fa-icon>
                {{ 'user-basket.send-in-mail-to-myself' | translate }}
            </button>
            <button
                *ngIf="isMobile && showCopyLink"
                class="link-button w-100 px-3 my-3"
                (click)="copyOnMobile()"
            >
                {{ 'common.copy-link' | translate }}
            </button>
        </div>
    </form>
</div>
