import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CategoryProviderSEOSettingShared } from '../models/provider/category-provider-seo-setting.model';
import { IProviderSettings } from '../models/provider/i-provider-settings';
import { ServiceBaseService } from './service-base.service';
import {
    ProviderClient,
    ProviderCoverImageResponseDto,
    ProviderFooterLegalInfoDTO,
} from '../index';

@Injectable({
    providedIn: 'root',
})
export class ProviderSettingsService {
    private shopServiceUrl: string;
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    settings = new BehaviorSubject<IProviderSettings[]>([]);

    constructor(
        private http: HttpClient,
        private serviceBaseService: ServiceBaseService,
        private providerClient: ProviderClient
    ) {
        this.shopServiceUrl = this.serviceBaseService.getBaseUrl();
    }

    getProviderThemes(providerCode: string): Observable<IProviderSettings[]> {
        return this.http.get<any>(
            this.shopServiceUrl + 'api/ProviderTheme/Get/' + providerCode,
            {
                headers: this.headers,
                params: { providerid_or_code: providerCode },
            }
        );
    }

    getProviderCoverImages(
        providerCode: string
    ): Observable<ProviderCoverImageResponseDto[]> {
        return this.http.get<any>(
            this.shopServiceUrl + 'api/ProviderTheme/get-cover-images/' + providerCode,
            {
                headers: this.headers,
                params: { providerid_or_code: providerCode },
            }
        );
    }

    getSEODetails(categoryId: number): Observable<CategoryProviderSEOSettingShared[]> {
        return this.http.get<any>(this.shopServiceUrl + 'api/ProviderTheme/seo-setting', {
            headers: this.headers,
            params: {
                categoryID: categoryId,
            },
        });
    }

    getProviderDesignForSEO(providerCode: string): Observable<IProviderSettings[]> {
        return this.http.get<any>(
            this.shopServiceUrl + 'api/ProviderTheme/landing-seo-setting/' + providerCode,
            {
                headers: this.headers,
                params: {
                    providerid_or_code: providerCode,
                },
            }
        );
    }

    getProviderLegalInfos(providerID: number): Observable<ProviderFooterLegalInfoDTO[]> {
        return this.providerClient.getProviderLegalInfos(providerID);
    }
}
