<div class="px-3 pt-2 w-100">
    <div class="d-flex align-items-end">
        <ng-container
            *ngIf="archieConversationThreadMessageDto.isArchieMessage; else userAvatar"
        >
            <div
                class="archie-profile-img me-2 d-flex justify-content-center align-items-center"
            >
                <img
                    class="h-100 w-100"
                    src="https://weshopsiteimages.blob.core.windows.net/images/archieimages/archie.jpg"
                    alt="avatar"
                />
            </div>
            <h5 class="fw-bold m-0">Archie</h5>
        </ng-container>
        <ng-template #userAvatar>
            <div
                class="archie-profile-img me-2 d-flex justify-content-center align-items-center"
            >
                <fa-icon [icon]="faUser"></fa-icon>
            </div>
            <h5 class="fw-bold m-0">{{ 'archie.you' | translate }}</h5>
        </ng-template>
    </div>
    <div class="w-100 pt-3">
        <div
            [innerHTML]="
                archieConversationThreadMessageDto.structuredMessageBody
                    .replace('```html', '')
                    .replace('```', '')
                    .replace('&nbsp;', '')
                    .replace('\n', '<br />')
            "
        ></div>

        <div
            class="w-100 d-flex justify-content-center align-items-center py-3"
            *ngIf="productLoadingIsInProgress"
        >
            <div class="loader d-flex justify-content-center align-items-center"></div>
        </div>
    </div>
</div>
<div *ngFor="let archieProduct of productDtoArr" class="w-100 pt-3">
    <div class="d-flex justify-content-between w-100 px-3">
        <div class="d-flex align-items-center">
            <div
                class="provider-img p-2 me-2 d-flex justify-content-center align-items-center"
            >
                <img
                    class="h-100 w-10"
                    src="{{ siteImageUrl }}logos/{{
                        archieProduct.providerName
                    }}-logo-s.png"
                    alt="avatar"
                />
            </div>
            <h5 class="fw-bold m-0 p-0">{{ archieProduct.providerName }}</h5>
        </div>
        <div>
            <p
                class="text-nowrap text-blue text-size-14 m-0 mt-2"
                (click)="showAllProducts(archieProduct)"
            >
                {{ 'dashboard.show-all' | translate }}
                <fa-icon [icon]="faChevronRight"></fa-icon>
            </p>
        </div>
    </div>
    <div class="product-suggestions-container w-100 d-flex ps-2">
        <ng-container *ngFor="let product of archieProduct.productList">
            <div class="p-2 m-2">
                <lib-product-dto-card
                    [product]="product"
                    (cardClicked)="
                        handleClickProduct(
                            product,
                            archieProduct.providerId,
                            archieProduct.providerName
                        )
                    "
                    [productProviderId]="archieProduct.providerId"
                    [productProviderName]="archieProduct.providerName"
                    [isArchie]="true"
                ></lib-product-dto-card>
            </div>
        </ng-container>
    </div>
</div>

<div *ngIf="providerIdArr" class="w-100">
    <div class="w-100 d-flex ps-2">
        <ng-container *ngFor="let providerId of providerIdArr">
            <div class="p-2 m-2">
                <div class="row mr-2">
                    <div
                        class="bg-white p-2 text-center pointer show-timeslot"
                        (click)="handleClickShowTimeSlot(providerId)"
                    >
                        <div class="p-2 h-100">Időpontok megtekintése  </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
<div class="w-100 px-3 pb-5">
    <i class="text-datetime">
        {{ archieConversationThreadMessageDto.created | date : 'yyyy.MM.dd.hh:mm' }}
    </i>
</div>
