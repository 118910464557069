<ng-container *ngIf="orderItem && orderItem.Product && orderItem.IsShown">
    <div class="card card-orderitem my-2">
        <div class="row mt-2">
            <div class="col-2 image" *ngIf="!replaced; else replacedPict">
                <a
                    appProductCardLink
                    [href]="[
                        'router.products' | translate,
                        orderItem.Product?.categorySEOName | lowercase,
                        orderItem.Product?.sEOName | lowercase
                    ]"
                    [navigateUrl]="[
                        'router.products' | translate,
                        orderItem.Product?.categorySEOName | lowercase,
                        orderItem.Product?.sEOName | lowercase
                    ]"
                    [productId]="orderItem?.Product.sEOName | lowercase"
                    [productName]="orderItem?.Product.productName"
                    class="product-card-image-link"
                >
                    <div *ngIf="hasBadge; else nobadge">
                        <img
                            src="{{ orderItem.MediaUrlS }}"
                            onerror="this.src='assets/images/roksh_logo.svg';"
                            title="{{ orderItem.ProductName }}"
                            class="ms-lg-5 order-1 order-lg-1 productImg-small"
                        />
                    </div>
                    <ng-template #nobadge>
                        <img
                            src="{{ orderItem.MediaUrlS }}"
                            onerror="this.src='assets/images/roksh_logo.svg';"
                            title="{{ orderItem.ProductName }}"
                            class="ms-lg-5 order-1 order-lg-1 productImg"
                        />
                    </ng-template>
                </a>
                <div
                    *ngIf="newItem && !removed && !modified && !missing"
                    class="badge badge-warning p-2 rounded text-white"
                >
                    {{ 'common.new' | translate }}
                </div>
                <div *ngIf="removed" class="badge badge-danger p-2 rounded">
                    {{ 'order-list.ordercustomerstatus-deleted' | translate }}
                </div>
                <div
                    *ngIf="modified && !removed && !missing"
                    class="badge badge-primary p-2 rounded"
                >
                    {{ 'common.modify' | translate }}
                </div>
                <div *ngIf="missing" class="badge badge-danger p-2 rounded">
                    {{ 'common.missing' | translate }}
                </div>
                <div
                    *ngIf="replaced"
                    class="badge badge-secondary p-2 rounded replaced-item"
                >
                    {{ 'common.replacement' | translate }}
                </div>
            </div>
            <ng-template #replacedPict>
                <div class="col-2 image">
                    <a
                        appProductCardLink
                        [href]="[
                            'router.products' | translate,
                            orderItem?.OrderReplacementItem?.ProductProvider?.Product
                                ?.categorySEOName | lowercase,
                            orderItem?.OrderReplacementItem?.ProductProvider?.Product
                                ?.sEOName | lowercase
                        ]"
                        [navigateUrl]="[
                            'router.products' | translate,
                            orderItem?.OrderReplacementItem?.ProductProvider?.Product
                                ?.categorySEOName | lowercase,
                            orderItem?.OrderReplacementItem?.ProductProvider?.Product
                                ?.sEOName | lowercase
                        ]"
                        [productId]="
                            orderItem?.OrderReplacementItem?.ProductProvider?.Product
                                ?.sEOName | lowercase
                        "
                        [productName]="orderItem.OrderReplacementItem?.ItemName"
                        class="product-card-image-link"
                    >
                        <img
                            src="{{
                                orderItem?.OrderReplacementItem?.ProductProvider
                                    ?.ProviderProductImageUrl
                            }}"
                            onerror="this.src='assets/images/roksh_logo.svg';"
                            title="{{ orderItem.OrderReplacementItem?.ItemName }}"
                            class="ms-lg-5 order-1 order-lg-1 productImg-small"
                        />
                    </a>
                    <div
                        *ngIf="replaced"
                        class="badge badge-secondary p-2 rounded replaced-item"
                    >
                        {{ 'common.replacement' | translate }}
                    </div>
                </div>
            </ng-template>
            <div class="col-3">
                <h5
                    class="product-name mt-2 font-weight-bold"
                    itemprop="name"
                    attr.content="{{ productName }}"
                >
                    {{ productName }}
                </h5>
                <div class="row">
                    <div class="col">
                        <h6 class="font-weight-bold text-size-12">
                            <span
                                class="text-black-50 font-weight-light"
                                *ngIf="
                                    productProvider?.packageQuantity &&
                                    productProvider?.packageBaseUnit
                                "
                            >
                                {{ productPackageQuantity }}
                            </span>
                        </h6>
                    </div>
                </div>
                <div
                    class="row w-100 m-auto desposit-box"
                    *ngIf="
                        orderItem.OrderReplacementItem
                            ?.OrderReplacementItemDepositProductList?.length > 0
                    "
                >
                    <div
                        class="col-12 font-dynamic-10 d-block border deposit-container"
                        *ngFor="
                            let orderItemDepositProduct of orderItem.OrderReplacementItem
                                .OrderReplacementItemDepositProductList
                        "
                    >
                        <fa-icon
                            class="deposit-icon mx-2"
                            [icon]="faWineBottle"
                        ></fa-icon>
                        <strong
                            >{{
                                'collecting.optional-deposit-quantity-title' | translate
                            }}:</strong
                        ><br />
                        {{ orderItemDepositProduct.DepositUnitTypeName }}
                        {{ orderItemDepositProduct.Price | currencyFormatted }}
                        {{ 'common.currency' | translate }}
                        {{ orderItemDepositProduct.UnitName }}
                        <br />
                        <div>
                            <strong>{{ 'collecting.quantity' | translate }}</strong
                            ><br />
                            {{
                                orderItemDepositProduct.UnitValue ??
                                    orderItemDepositProduct.UnitValue
                            }}
                            {{ 'collecting.piece(s)' | translate }}*
                            {{ orderItemDepositProduct.Price | currencyFormatted }}
                            {{ 'common.currency' | translate }} =
                            <strong>
                                {{
                                    (orderItemDepositProduct.UnitValue ??
                                        orderItemDepositProduct.UnitValue) *
                                        orderItemDepositProduct.Price | currencyFormatted
                                }}
                                {{ 'common.currency' | translate }}</strong
                            >
                        </div>
                        <div
                            *ngIf="
                                orderItemDepositProduct.CollectedQuantity &&
                                orderItemDepositProduct.CollectedQuantity > 0
                            "
                        >
                            <strong>{{
                                'collecting.already-added-to-cart' | translate
                            }}</strong
                            ><br />
                            {{ orderItemDepositProduct.CollectedQuantity }}
                            {{ 'collecting.piece(s)' | translate }}*
                            {{ orderItemDepositProduct.Price | currencyFormatted }}
                            {{ 'common.currency' | translate }} =
                            <strong>
                                <u>
                                    {{
                                        orderItemDepositProduct.CollectedQuantity *
                                            orderItemDepositProduct.Price
                                            | currencyFormatted
                                    }}
                                    {{ 'common.currency' | translate }}</u
                                ></strong
                            >
                        </div>
                    </div>
                </div>
                <div
                    class="row mt-5 pt-1"
                    *ngIf="
                        replaced &&
                        orderItem.OrderReplacementItem?.ProductProvider === null
                    "
                >
                    <div class="col">
                        <h6 class="font-weight-bold font-dynamic-10 my-2">
                            <span
                                class="text-black-50 font-weight-light text-decoration-underline"
                            >
                                {{ 'order-list.original-order-item' | translate }}
                            </span>
                        </h6>
                    </div>
                </div>
                <div
                    class="row mt-3"
                    *ngIf="
                        replaced &&
                        orderItem.OrderReplacementItem?.ProductProvider !== null
                    "
                >
                    <div class="col">
                        <h6 class="font-weight-bold font-dynamic-10 my-2">
                            <span class="text-black-50 font-weight-light">
                                {{ 'order-list.original-order-item' | translate }}
                            </span>
                        </h6>
                    </div>
                </div>
            </div>
            <div class="col-3 ps-0 align-right d-block">
                <h6 class="font-weight-bold font-dynamic-10 font-dynamic-14 my-2">
                    <span class="text-black-50 font-weight-light">
                        {{ 'common.price' | translate }}:
                    </span>
                    <span class="price-quantity">
                        {{ unitPrice }}
                    </span>
                </h6>
                <div class="row">
                    <h6 class="font-weight-bold font-dynamic-10 mt-5">
                        <span class="text-black-50 font-weight-light">
                            {{ actionTranslateBasedOnStatus }}:
                        </span>
                        <span class="price-quantity">
                            &nbsp;{{ collectedQuantity }}&nbsp;
                        </span>
                        <span class="price-quantity">{{ quantityUnit }} </span>
                    </h6>
                </div>
            </div>
            <div class="col-3 ps-0 align-right">
                <h6
                    class="font-weight-bold font-dynamic-10 font-dynamic-14 my-2"
                    *ngIf="!replaced"
                >
                    <span class="text-black-50 font-weight-light">
                        {{ 'order-list.ordered-quantity' | translate }}:
                    </span>
                    <span class="price-quantity">
                        &nbsp;{{ orderItem.OrderedQuantity }}&nbsp;
                    </span>
                    <span class="price-quantity">{{ quantityUnit }}</span>
                </h6>
            </div>
        </div>
        <div class="row" *ngIf="replaced">
            <div class="col-3 image-replaced ms-4 align-items-end">
                <img
                    src="{{ orderItem.MediaUrlS }}"
                    onerror="this.src='assets/images/roksh_logo.svg';"
                    title="{{ orderItem.ProductName }}"
                    class="ms-0 mt-2 order-1 order-lg-1 productImg-smallest"
                />
            </div>
            <div class="col">
                <div class="row">
                    <span
                        class="font-dynamic-8 mt-2 font-weight-bold"
                        itemprop="name"
                        attr.content="{{ orderItem.Product.productName }}"
                    >
                        {{ orderItem.Product.productName }}
                    </span>
                </div>
                <div class="row">
                    <h6 class="font-dynamic-8 font-weight-bold">
                        <span
                            class="text-black-50 font-weight-light"
                            *ngIf="
                                productProvider?.packageQuantity &&
                                productProvider?.packageBaseUnit
                            "
                        >
                            {{ productProvider.packageQuantity }}
                            {{ productProvider.packageBaseUnit }}
                        </span>
                    </h6>
                </div>
            </div>
            <div class="col-2 ps-0 align-right">
                <div class="row">
                    <h6 class="font-weight-bold font-dynamic-10 font-dynamic-14 my-2">
                        <span
                            class="text-black-50 font-weight-light font-dynamic-8 font-dynamic-14"
                        >
                            {{ 'common.price' | translate }}:
                        </span>
                        <span class="text-black-50 font-weight-bold">
                            {{ productProvider?.price | currencyFormatted }}&nbsp;
                            {{ 'common.currency' | translate }}
                        </span>
                    </h6>
                </div>
            </div>
            <div class="col-3 ps-0 align-right">
                <h6 class="font-weight-bold font-dynamic-10 font-dynamic-14 my-2 pe-1">
                    <span
                        class="text-black-50 font-weight-light font-dynamic-8 font-dynamic-14"
                    >
                        {{ 'order-list.ordered-quantity' | translate }}:
                    </span>
                    <span class="text-black-50 font-weight-bold">
                        &nbsp;{{ orderItem.OrderedQuantity }}&nbsp;
                    </span>
                    <span class="text-black-50 font-weight-bold">{{ quantityUnit }}</span>
                </h6>
            </div>
        </div>

        <div
            class="row w-75 m-auto"
            *ngIf="orderItem.OrderItemDepositProductList?.length > 0"
        >
            <div
                class="col-6 p-2 font-dynamic-10 d-block border"
                *ngFor="
                    let orderItemDepositProduct of orderItem.OrderItemDepositProductList
                "
            >
                <fa-icon class="deposit-icon mx-2" [icon]="faWineBottle"></fa-icon>
                <strong
                    >{{
                        'collecting.optional-deposit-quantity-title' | translate
                    }}:</strong
                ><br />
                {{ orderItemDepositProduct.DepositUnitTypeName }}
                {{ orderItemDepositProduct.Price | currencyFormatted }}
                {{ 'common.currency' | translate }}
                {{ orderItemDepositProduct.UnitName }}
                <br />
                <div>
                    <strong>{{ 'collecting.quantity' | translate }}</strong
                    ><br />
                    {{
                        orderItemDepositProduct.UnitValue ??
                            orderItemDepositProduct.UnitValue
                    }}
                    {{ 'collecting.piece(s)' | translate }}*
                    {{ orderItemDepositProduct.Price | currencyFormatted }}
                    {{ 'common.currency' | translate }} =
                    <strong>
                        {{
                            (orderItemDepositProduct.UnitValue ??
                                orderItemDepositProduct.UnitValue) *
                                orderItemDepositProduct.Price | currencyFormatted
                        }}
                        {{ 'common.currency' | translate }}</strong
                    >
                </div>
                <div
                    *ngIf="
                        orderItemDepositProduct.CollectedQuantity &&
                        orderItemDepositProduct.CollectedQuantity > 0
                    "
                >
                    <strong>{{ 'collecting.already-added-to-cart' | translate }}</strong
                    ><br />
                    {{ orderItemDepositProduct.CollectedQuantity }}
                    {{ 'collecting.piece(s)' | translate }}*
                    {{ orderItemDepositProduct.Price | currencyFormatted }}
                    {{ 'common.currency' | translate }} =
                    <strong>
                        <u>
                            {{
                                orderItemDepositProduct.CollectedQuantity *
                                    orderItemDepositProduct.Price | currencyFormatted
                            }}
                            {{ 'common.currency' | translate }}</u
                        ></strong
                    >
                </div>
            </div>
        </div>

        <hr
            style="
                margin-top: 2em;
                width: 90%;
                align-self: center;
                margin-bottom: 0 !important;
            "
        />
        <div class="row col-12 justify-content-end">
            <div class="col ps-0 align-right">
                <h6 class="font-weight-bold font-dynamic-10 font-dynamic-16 mt-2 d-flex">
                    <span class="text-black-50 font-weight-light">
                        {{ 'common.final-price' | translate }}:&nbsp;
                    </span>

                    <div *ngIf="!replaced; else replacedFinalPrice">
                        <span class="price-quantity">
                            {{ formattedPrice }}&nbsp;
                            {{ 'common.currency' | translate }}
                        </span>
                    </div>
                    <ng-template #replacedFinalPrice>
                        <span class="price-quantity">
                            {{ formattedReplacementItemPrice }}&nbsp;
                            {{ 'common.currency' | translate }}
                        </span>
                    </ng-template>
                    <span
                        *ngIf="
                            totalProviderDepositProducts &&
                            totalProviderDepositProducts > 0
                        "
                        class="text-black-50 font-weight-light"
                    >
                        , {{ 'checkout.deposit-fee' | translate }} &nbsp;
                        {{ formattedTotalProviderDepositProductPrice }}&nbsp;
                        {{ 'common.currency' | translate }}
                    </span>
                </h6>
            </div>
            <div class="vertical-line p-0" style="height: 20px"></div>
            <div
                *ngIf="!btnReplace; else btnReplaceSection"
                class="col-mg-auto mx-3 col-add-to-cart p-0"
            >
                <div class="col-12 mx-3 col-add-to-cart">
                    <div *ngIf="isOpen && !order.PayOk; else notModifyable">
                        <a
                            class="font-dynamic-15 font-weight-bold me-2 color-turquoise text-decoration-underline"
                            (click)="onClickModify()"
                        >
                            {{ 'common.modify' | translate }}
                        </a>
                    </div>
                    <ng-template #notModifyable>
                        <div
                            class="d-flex align-items-center justify-content-between mt-1 rearrange"
                        >
                            <div
                                *ngIf="
                                    orderItem.Product.minPrice === 0 ||
                                        orderItem.Product.available === false;
                                    else elseBlock
                                "
                            >
                                <span
                                    class="text-danger font-dynamic-8 font-dynamic-14"
                                    *ngIf="showUnavailable"
                                >
                                    {{
                                        'messages.not-available-product' | translate
                                    }}</span
                                >
                                <link
                                    itemprop="availability"
                                    href="http://schema.org/NotInStock"
                                />
                            </div>
                            <ng-template #elseBlock>
                                <ng-container
                                    *ngIf="!orderItem.Product.isBulk; else bulkPrice"
                                >
                                    <div
                                        class="pb-1 add-to-cart-container"
                                        *ngIf="isCollectingInProgress"
                                    >
                                        <lib-add-to-cart-button
                                            [addressDialogComponent]="
                                                AddressDialogComponent
                                            "
                                            [providerDialogComponent]="
                                                ShopSelectDialogSeparatedComponent
                                            "
                                            [prod]="orderItem.Product"
                                            [cartIcon]="false"
                                            [buttonText]="buttonText"
                                            [buttonWidth]="'130px'"
                                            [selectFontSize]="'16px'"
                                            [buttonPadding]="'2px'"
                                            (onadd)="onAddOrReplace($event)"
                                        >
                                        </lib-add-to-cart-button>
                                    </div>
                                </ng-container>
                                <ng-template #bulkPrice>
                                    <div
                                        class="pb-1 add-to-cart-container"
                                        *ngIf="isCollectingInProgress"
                                    >
                                        <lib-add-to-cart-button
                                            [prod]="orderItem.Product"
                                            [cartIcon]="false"
                                            [buttonText]="buttonText"
                                            [buttonWidth]="'130px'"
                                            [selectFontSize]="'16px'"
                                            [buttonPadding]="'2px'"
                                            (onadd)="onAddOrReplace($event)"
                                        >
                                        </lib-add-to-cart-button>
                                    </div>
                                </ng-template>
                            </ng-template>
                        </div>
                    </ng-template>
                </div>
            </div>
            <ng-template #btnReplaceSection>
                <div
                    *ngIf="
                        order.OnlineConsultation &&
                        orderItem.OrderReplacementItem.QuantityRequestedByUser === null
                    "
                    class="col-mg-auto"
                >
                    <button
                        class="btn-danger btn-wide btn-rounded mx-2 my-2"
                        (click)="onClickReplacementDecline()"
                    >
                        {{ 'common.decline' | translate }}
                    </button>
                    <button
                        class="btn-success btn-wide btn-rounded me-4 my-2"
                        (click)="onClickReplacementAccept()"
                    >
                        {{ 'common.accept' | translate }}
                    </button>
                </div>
            </ng-template>
        </div>
    </div>
</ng-container>
