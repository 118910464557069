<div class="px-0 px-md-4 archie-container pt-5">
    <div class="row p-0 m-0 h-100 justify-content-between">
        <div
            class="col h-100 p-0 m-0 basket-container"
            [class.col-md-12]="hideBasketPages.includes(navigationLevel)"
        >
            <lib-archie
                [navigationLevelEmitter]="navigationLevelEmitter"
                (providerSelectedEmitter)="onProvidersSelected($event)"
                (clickProduct)="handleClickProduct($event)"
                (clickShowTimeSlot)="showAvailableTimeSlots($event)"
            ></lib-archie>
        </div>
        <div
            class="col basket-width p-0 ps-2 m-0 h-100"
            *ngIf="!hideBasketPages.includes(navigationLevel)"
        >
            <div class="h-100 basket-container">
                <div class="info-panel-header p-4 py-3">
                    <div
                        class="w-100 d-flex justify-content-between align-items-center"
                        *ngIf="basketNavigationLevel.valueOf() === 3"
                    >
                        <h4 class="fw-bold p-0 m-0">
                            {{ 'basket.your-baskets' | translate }}
                        </h4>
                        <div
                            class="d-flex align-items-center"
                            (click)="onModifyProviders()"
                        >
                            <div
                                class="change-provider d-flex justify-content-center align-items-center"
                            >
                                <fa-icon [icon]="faSwap"></fa-icon>
                            </div>
                            <p class="pointer p-0 m-0 ps-2 font-size-14">
                                {{ 'common.modify' | translate }}
                            </p>
                        </div>
                    </div>
                    <div
                        *ngIf="basketNavigationLevel.valueOf() === 4"
                        class="d-flex justify-content-between align-items-center"
                    >
                        <div class="d-flex align-items-center">
                            <fa-icon
                                *ngIf="selectedProviders.length > 1"
                                class="me-3"
                                [icon]="faArrow"
                                (click)="goBackToAllBaskets()"
                            ></fa-icon>
                            <h4 class="fw-bold p-0 m-0">
                                {{ 'common.basket' | translate }}
                            </h4>
                        </div>
                        <div
                            class="d-flex align-items-center"
                            (click)="onModifyProviders()"
                        >
                            <div
                                class="change-provider d-flex justify-content-center align-items-center"
                            >
                                <fa-icon [icon]="faSwap"></fa-icon>
                            </div>
                            <p class="pointer p-0 m-0 ps-2 font-size-14">
                                {{ 'common.modify' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="h-100">
                    <div *ngIf="basketNavigationLevel.valueOf() === 3">
                        <lib-archie-all-basket
                            [selectedBaskets]="selectedBaskets"
                            [selectedProviders]="selectedProviders"
                            [selectedBaskets]="selectedBaskets"
                            (selectBasketEmitter)="onBasketSelected($event)"
                        ></lib-archie-all-basket>
                    </div>
                    <div
                        *ngIf="basketNavigationLevel.valueOf() === 4"
                        class="h-100 position-relative"
                    >
                        <div class="overflow-y-scroll h-90 px-3">
                            <lib-basket
                                [isArchie]="true"
                                [selectedBasket]="selectedBasket"
                                (goToCheckoutEmitter)="navigateToShopCheckout()"
                            ></lib-basket>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
