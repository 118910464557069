<div [formGroup]="checkoutForm">
    <div formGroupName="paymentForm">
        <mat-card style="border: 1px solid #aaa" *ngIf="order">
            <div class="coupon-title mb-4">
                <span> {{ 'checkout.redeem-your-coupon' | translate }}</span>
            </div>

            <div class="coupon-container mb-4">
                <mat-form-field appearance="outline" class="d-block pb-0">
                    <mat-select
                        formControlName="DiscountID"
                        (selectionChange)="onDiscountChanged()"
                        [(value)]="selectedDiscount"
                        placeholder="{{ 'checkout.select-coupon' | translate }}"
                    >
                        <mat-option *ngIf="discounts.length === 0">
                            <div class="container">
                                <div class="row w-100 opacity-50">
                                    <div
                                        class="col-10 panel-discount-name d-flex align-items-center justify-content-between text-center pe-0"
                                    >
                                        <span class="text-wrap lh-base">
                                            {{ 'checkout.no-coupon' | translate }}
                                        </span>
                                    </div>
                                    <div
                                        class="col-2 d-flex align-items-center justify-content-end text-end"
                                    >
                                        <img
                                            alt="coupon"
                                            src="assets/checkout/discount-image.svg"
                                        />
                                    </div>
                                </div>
                            </div>
                        </mat-option>
                        <mat-option *ngFor="let discount of discounts" [value]="discount">
                            <div class="container">
                                <div class="row pt-4 w-100">
                                    <div
                                        class="col-6 panel-discount-name d-flex align-items-center justify-content-between text-center pe-0"
                                    >
                                        <span class="text-wrap lh-base">
                                            {{ discount.DiscountDisplayName }}:
                                        </span>
                                    </div>
                                    <div
                                        class="col-6 d-flex align-items-center justify-content-between text-end"
                                    >
                                        <div class="row lh-base">
                                            <span class="panel-discount">{{
                                                discount.DiscountTitle
                                            }}</span>
                                            <div
                                                class="d-flex align-items-center justify-content-end"
                                            >
                                                <span class="panel-discount-valid">
                                                    {{
                                                        'checkout.usable' | translate
                                                    }}:</span
                                                >
                                                <span class="ms-2 panel-discount-valid">{{
                                                    discount.ValidTo | date : 'dd/MM/yyyy'
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <hr />
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="coupon-container row text-center">
                <mat-form-field
                    class="col-12 mt-sm-2 col-sm-8 mt-lg-0 col-lg-8 pb-0 mt-0"
                >
                    <input
                        formControlName="CouponCode"
                        type="text"
                        class="coupon-input"
                        matInput
                        placeholder=" {{ 'checkout.add-cupon' | translate }}"
                    />
                </mat-form-field>
                <div class="col-12 col-sm-4 col-lg-4 text-center ps-0 pe-1">
                    <div
                        class="btn btn-use-coupon d-flex align-items-center justify-content-between text-center mt-2 mt-0 mt-sm-2 mt-lg-0"
                    >
                        <span
                            class="text-center w-100 w-md-75 w-lg-100 text-size-16"
                            (click)="checkCoupon()"
                            >{{ 'checkout.use-coupon' | translate }}</span
                        >
                    </div>
                </div>
                <div class="clearfix"></div>
            </div>
            <div class="container">
                <div class="row">
                    <div
                        *ngIf="selectedDiscount?.DiscountID"
                        class="d-flex align-items-center justify-content-between text-center p-2 selected-user-coupon text-uppercase discount-name mt-3 mb-3"
                    >
                        <img alt="coupon" src="assets/checkout/discount-image.svg" />
                        {{ selectedDiscount.DiscountDisplayName }}

                        <img
                            class="close-img"
                            (click)="removeDiscount()"
                            alt="close"
                            src="assets/checkout/discount-close.svg"
                        />
                    </div>
                    <div
                        *ngIf="couponCodeControl.value && isCouponChecked"
                        class="d-flex align-items-center justify-content-between text-center p-2 selected-user-coupon text-uppercase discount-name mt-3 mb-3"
                    >
                        <img alt="coupon" src="assets/checkout/discount-image.svg" />
                        {{ couponDisplayName }}
                        <img
                            class="close-img"
                            (click)="removeDiscount()"
                            alt="close"
                            src="assets/checkout/discount-close.svg"
                        />
                    </div>
                </div>
            </div>
            <hr />
            <div class="summary-container">
                <h3 class="pull-left font-weight-600">
                    {{ 'checkout.summary' | translate }}
                </h3>
                <img
                    class="pull-right"
                    src="{{ siteImageUrl }}logos/{{ order.providerCode }}-logo-s.png"
                    style="display: inline; max-height: 30px; color: #fff"
                />
            </div>
            <div class="clearfix"></div>
            <br />
            <div class="clearfix"></div>
            <div
                class="prices d-flex align-items-center justify-content-between text-center"
            >
                <span class="pull-left text-size-18 font-weight-600">
                    {{ 'checkout.products-sum-price' | translate }}:
                </span>
                <span class="pull-right text-size-18 font-weight-600">
                    {{ order.TotalPrice - order.SumDeposit | currencyFormatted }}
                    {{ 'common.currency' | translate }}
                </span>
            </div>
            <div class="clearfix"></div>
            <div
                *ngIf="
                    paymentData.couponStatus === couponStatus.TOTALPRICEDISCOUNT &&
                    ((couponCodeControl.value && isCouponChecked) ||
                        selectedDiscount?.DiscountID)
                "
                class="discount d-flex align-items-center justify-content-between text-center"
            >
                <span class="pull-left text-size-16 discount-title">
                    {{ 'checkout.product-discount' | translate }}:
                </span>
                <span class="pull-right text-size-16 font-weight-600 discount-amount">
                    <span
                        class="pull-right text-size-16 font-weight-600"
                        *ngIf="paymentData.priceDiscountAmount > 1"
                    >
                        - {{ paymentData.priceDiscountAmount }}
                        {{ 'common.currency' | translate }}
                    </span>
                </span>
            </div>

            <div class="clearfix"></div>
            <div
                *ngIf="order.RokshDiscountTotalPrice && order.RokshDiscountTotalPrice > 0"
                class="gamification prices d-flex align-items-center justify-content-between text-center"
            >
                <span class="pull-left text-size-16 roksh-discount-gamification">
                    {{ 'common.roksh-discount' | translate }}:
                </span>
                <span
                    class="pull-right text-size-16 font-weight-600 roksh-discount-gamification-amount"
                >
                    - {{ order.RokshDiscountTotalPrice | currencyFormatted }}
                    {{ 'common.currency' | translate }}
                </span>
            </div>

            <div class="clearfix"></div>
            <div
                class="prices d-flex align-items-center justify-content-between text-center"
            >
                <span class="pull-left text-size-16 font-weight-600">
                    {{ 'checkout.delivery-fee' | translate }}
                </span>
                <span
                    class="pull-right text-size-16 font-weight-600"
                    *ngIf="paymentData.totalDeliveryFee"
                >
                    {{ paymentData.totalDeliveryFee | currencyFormatted }}
                    {{ 'common.currency' | translate }}
                </span>
            </div>

            <div class="clearfix"></div>
            <div
                *ngIf="
                    paymentData.couponStatus === couponStatus.FREEDELIVERY &&
                    ((couponCodeControl.value && isCouponChecked) ||
                        selectedDiscount?.DiscountID)
                "
                class="discount d-flex align-items-center justify-content-between text-center"
            >
                <span class="pull-left text-size-16 discount-title">
                    {{ 'common.delivery-free' | translate }}
                </span>
                <span class="pull-right text-size-16 font-weight-600 discount-amount">
                    <span
                        class="pull-right text-size-16 font-weight-600"
                        *ngIf="paymentData.deliveryDiscountAmount > 1"
                    >
                        - {{ paymentData.deliveryDiscountAmount }}
                        {{ 'common.currency' | translate }}
                    </span>
                </span>
            </div>

            <div class="clearfix"></div>
            <div
                *ngIf="
                    paymentData.totalDeliveryFee &&
                    paymentData.deliveryDiscountAmount === 0 &&
                    paymentData.totalDeliveryFee - paymentData.deliveryFee !== 0
                "
                class="discount d-flex align-items-center justify-content-between text-center"
            >
                <span class="pull-left text-size-16 discount-title">
                    {{ 'checkout.delivery-discount' | translate }}
                </span>
                <span class="pull-right text-size-16 font-weight-600 discount-amount">
                    <span class="pull-right text-size-16 font-weight-600"
                        >-
                        {{ paymentData.totalDeliveryFee - paymentData.deliveryFee }}
                        {{ 'common.currency' | translate }}
                    </span>
                </span>
            </div>

            <ng-container
                *ngIf="
                    (order.SumDeposit && order.SumDeposit > 0) ||
                    (totalProviderDepositProducts && totalProviderDepositProducts > 0)
                "
            >
                <span class="pull-left text-size-16">
                    <fa-icon [icon]="faWineBottle"></fa-icon>
                    {{ 'checkout.deposit-fee' | translate }}
                </span>
                <span
                    class="pull-right text-size-16 font-weight-600"
                    *ngIf="
                        totalProviderDepositProducts && totalProviderDepositProducts > 0
                    "
                >
                    {{ totalProviderDepositProducts | currencyFormatted }}
                    {{ 'common.currency' | translate }}
                </span>
                <span
                    class="pull-right text-size-16 font-weight-600"
                    *ngIf="order.SumDeposit && order.SumDeposit > 0"
                >
                    {{ order.SumDeposit | currencyFormatted }}
                    {{ 'common.currency' | translate }}
                </span>
                <div class="clearfix"></div>
            </ng-container>

            <div class="clearfix"></div>

            <div
                *ngIf="
                    paymentData.couponStatus &&
                    paymentData?.couponStatus === couponStatus.BADCOUPON
                "
                class="text-12"
            >
                <span
                    *ngIf="paymentData.couponStatus === couponStatus.BADCOUPON"
                    class="w-100 text-danger"
                    >{{ couponMessage }}</span
                >
            </div>

            <div
                *ngIf="tipControl && tipControl > 0"
                class="d-flex flex-row justify-content-between align-items-center prices"
            >
                <span class="text-size-16"> {{ 'common.tip' | translate }}: </span>
                <span class="text-size-16 font-weight-600">
                    {{ tipControl | currencyFormatted }}
                    {{ 'common.currency' | translate }}
                </span>
            </div>

            <div
                *ngIf="order.SystemUsageFee && order.SystemUsageFee > 0"
                class="d-flex flex-row justify-content-between align-items-center prices"
            >
                <span class="text-size-16">
                    {{ 'common.system-usage-fee' | translate }}:
                </span>
                <span class="text-size-16 font-weight-600">
                    {{ order.SystemUsageFee | currencyFormatted }}
                    {{ 'common.currency' | translate }}
                </span>
            </div>

            <div class="clearfix"></div>
            <hr />
            <div class="clearfix"></div>

            <span class="pull-left text-size-16 font-weight-600">
                {{ 'checkout.sum-price' | translate }}:</span
            >
            <span
                class="pull-right text-size-16 font-weight-600"
                *ngIf="order.TotalPrice && paymentData.totalDeliveryFee"
            >
                {{ totalPriceFormat }}
                {{ 'common.currency' | translate }}
            </span>
            <span
                class="pull-right text-size-18 font-weight-600"
                *ngIf="order.TotalPrice && !paymentData.totalDeliveryFee"
            >
                {{
                    order.TotalPrice -
                        paymentData.deliveryDiscountAmount -
                        paymentData.priceDiscountAmount -
                        order.RokshDiscountTotalPrice +
                        this.order.SystemUsageFee +
                        this.tipControl +
                        totalProviderDepositProducts | currencyFormatted
                }}
                {{ 'common.currency' | translate }}
            </span>
            <div class="clearfix"></div>
            <div
                *ngIf="
                    (checkoutService.creditCardAuthTotalAmountSource$ | async) !== null
                "
            >
                <span class="pull-left text-size-16">
                    {{ 'checkout.card-auth-amount' | translate }}:
                </span>
                <span
                    class="pull-right text-size-16 font-weight-600"
                    *ngIf="
                        checkoutService.creditCardAuthTotalAmountSource$ | async as amount
                    "
                >
                    {{ +amount | currencyFormatted }}
                    {{ 'common.currency' | translate }}
                </span>
            </div>
            <div class="clearfix"></div>
            <div
                class="w-100 text-end"
                *ngIf="('common.vat-included' | translate).length > 3"
            >
                <small style="font-size: 10px">
                    {{ 'common.vat-included' | translate }} </small
                ><br />
                <small style="font-size: 10px">
                    {{ 'common.paper-bag-not-included' | translate }}
                </small>
            </div>
            <div class="clearfix"></div>
            <div class="clearfix"></div>

            <hr />
            <div *ngIf="providerGDPRText && providerGDPRLink">
                <small>
                    <a
                        class="text-muted"
                        href="{{ providerGDPRLink }}"
                        target="_blank"
                        rel="noopener noreferrer"
                        >{{ providerGDPRText }}</a
                    >
                </small>
                <hr />
            </div>

            <div *ngIf="providerMarketingTextHeader" class="form-check">
                <input
                    class="form-check-input"
                    type="checkbox"
                    formControlName="ProviderMarketingAccepted"
                    id="flexCheckDefault"
                />
                <label class="form-check-label">
                    <div [ngClass]="{ collapse: !marketingTextExpanded }">
                        <small>{{ providerMarketingText }} </small>
                    </div>

                    <div [ngClass]="{ collapse: marketingTextExpanded }">
                        <small>{{ providerMarketingTextHeader }}</small>
                    </div>
                </label>
                <small
                    *ngIf="!marketingTextExpanded; else collapsed"
                    (click)="marketingTextExpanded = !marketingTextExpanded"
                    style="color: #00b9ce; cursor: pointer"
                >
                    {{ 'common.more' | translate }}...
                </small>
                <ng-template #collapsed>
                    <small
                        style="color: #00b9ce; cursor: pointer"
                        (click)="marketingTextExpanded = !marketingTextExpanded"
                    >
                        {{ 'common.less' | translate }}...
                    </small>
                </ng-template>

                <hr />
            </div>

            <button
                (click)="checkout()"
                *ngIf="
                    checkoutForm.get('paymentForm.PaymentType').value !==
                        'PayOnDeliveryCard' &&
                    checkoutForm.get('paymentForm.PaymentType').value !==
                        'PayOnDeliveryCash'
                "
                class="btn pull-right btn-warning w-100"
                [disabled]="checkoutForm.invalid && isDisableNextPayButton"
            >
                <fa-icon
                    *ngIf="isCheckoutInProgress; else checkoutButtonText"
                    [icon]="faSpinner"
                    [spin]="true"
                ></fa-icon>
                <ng-template #checkoutButtonText>
                    {{ 'checkout.go-to-pay' | translate }}
                </ng-template>
            </button>

            <button
                (click)="checkout()"
                *ngIf="
                    checkoutForm.get('paymentForm.PaymentType').value ===
                        'PayOnDeliveryCard' ||
                    checkoutForm.get('paymentForm.PaymentType').value ===
                        'PayOnDeliveryCash'
                "
                class="btn pull-right btn-warning w-100"
                [disabled]="checkoutForm.invalid && isDisableNextPayButton"
            >
                <fa-icon
                    *ngIf="isCheckoutInProgress; else checkoutButtonTextOnDelivery"
                    [icon]="faSpinner"
                    [spin]="true"
                >
                </fa-icon>
                <ng-template #checkoutButtonTextOnDelivery>
                    {{ 'checkout.add-pay-on-delivery-order' | translate }}
                </ng-template>
            </button>

            <div class="clearfix"></div>
        </mat-card>
    </div>
</div>
