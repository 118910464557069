import { NgModule } from '@angular/core';
import { ProviderThemePipe } from '../services/pipes/provider-theme.pipe';

@NgModule({
    declarations: [ProviderThemePipe],
    imports: [],
    exports: [ProviderThemePipe],
    providers: [ProviderThemePipe],
})
export class ThemePipeModule {}
