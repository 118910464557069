import { Injectable } from '@angular/core';
import { CountryCodes } from '../types/country-codes';
import { FormatterService } from './formatter.service';
import { ServiceBaseService } from './service-base.service';

@Injectable({ providedIn: 'root' })
export class CurrencyService {
    currentCountryCode: CountryCodes;
    countryCodes = CountryCodes;

    constructor(public formatterService: FormatterService) {
        this.currentCountryCode = ServiceBaseService.getCountryCode();
    }

    roundAmountByCurrentCountryCode(amount: number) {
        switch (this.currentCountryCode) {
            case this.countryCodes.hu:
                return Math.ceil(amount);
            case this.countryCodes.at:
                return this.formatterService.round(amount, 2).toFixed(2);
            default:
                return amount;
        }
    }
}
