import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    AuthenticationService,
    BasketService,
    DataLayerGa4Service,
    FavoriteListService,
    GA4EventType,
    ISeoService,
    IShopAvailable,
    ProductDetailsSharedDto,
    ProductDto,
    ProductProviderDto,
    SEO_SERVICE_IMPL,
    ServiceBaseService,
    SessionService,
    SharedModalService,
} from 'projects/library-shared/src/public-api';
import { fromEvent, Subscription } from 'rxjs';
import {
    faAngleDown,
    faSearchMinus,
    faSearchPlus,
    faSpinner,
    faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { CreateDialogService } from '../../../services/create-dialog.service';
import { HomeService } from '../../../services/home/home.service';
import { AddressDialogComponent } from '../../../components/address/address-dialog.component';
import { ShopSelectDialogSeparatedComponent } from '../../../components/shop-select-dialog-separated/shop-select-dialog-separated.component';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-product-details-content',
    templateUrl: './product-details-content.component.html',
    styleUrls: ['./product-details-content.component.scss'],
})
export class ProductDetailsContentComponent
    implements OnInit, AfterViewInit, OnDestroy, AfterViewInit
{
    @Input() isDialog = false;
    @Input() isArchie = false;
    @Input() shoppingProhibited: boolean;
    @Input() providerId: number;
    @Input() providerName: string;
    @Output() onRemove: EventEmitter<any> = new EventEmitter();
    @ViewChild('detailsContainer') detailsContainer: ElementRef;

    hasAnyProviderDepositProduct = false;

    @Input() set prod(prod: ProductDto) {
        this.product = prod;
        if (this.product?.providerDepositProductDtoList) {
            this.hasAnyProviderDepositProduct = true;
        }

        this.prodDetails = prod.productDetails;
        this.ingredientsString = this.formatIngredients(this.prodDetails.ingredients);
        this.allergensString = this.formatAllergens(this.prodDetails.allergenic);
        this.productProviderArray = this.customProductOrder(true);
        this.galleryImgArray = prod.galleryImageUrlList;
        this.ngOnInit();
    }
    get prod() {
        return this.product;
    }
    product: ProductDto;
    prodDetails: ProductDetailsSharedDto;
    faTimesCircle = faTimesCircle;
    faSearchPlus = faSearchPlus;
    faSearchMinus = faSearchMinus;
    isOwnWebShop = ServiceBaseService.isProviderOwnWebShop();
    isAuth = false;
    isFavoriteProgress = false;
    isFavoriteProduct = false;
    faSpinner = faSpinner;
    favoriteBasketItemId: number;
    favoritItemSub: Subscription;
    authSub: Subscription;

    customOptions: any = {
        responsive: {
            autoWidth: true,
            0: {
                items: 1,
            },
        },
    };

    inProgress: boolean;
    faAngleDown = faAngleDown;
    productProviderArray: ProductProviderDto[];
    galleryImgArray: string[];
    prodviderProductNameUniqueArr: string[] = [''];
    availableShops: IShopAvailable[];
    zipCode: any = '0000';
    ingredientsString: string;
    allergensString: string;

    enableMoreShop = false;
    hasMoreShop = false;
    hasMoreCheapest = false;

    isOrderCOmpleted = false;
    addButtonInterscept = null;
    isDeliveryAvailableByPostCode: boolean;
    isPostCodeSetted = false;
    isProviderSelectedForShopping = false;
    isProviderSelected = false;

    isAuthenticated = false;
    authStatusSub: Subscription;
    userSelectedShops: string[];
    userSelectedShopIds: number[];
    quantityList: [number, string][];

    hasGallery = false;
    isZoomedIn = false;
    isOnePrice = false;
    mediaServiceUrl = '';

    mobileZoomedIn = false;
    mobileZoomedImgSrc = '';
    imgAlt: string;
    hasDescriptionParsed = false;

    AddressDialogComponent = AddressDialogComponent;
    ShopSelectDialogSeparatedComponent = ShopSelectDialogSeparatedComponent;
    siteImageUrl: string;
    scrolled: boolean;

    constructor(
        private favoriteListService: FavoriteListService,
        private sessionService: SessionService,
        private DataLayerGa4Service: DataLayerGa4Service,
        private createDialogService: CreateDialogService,
        private sharedModalService: SharedModalService,
        private snackBar: MatSnackBar,
        private location: Location,
        private router: Router,
        private authService: AuthenticationService,
        private homeService: HomeService,
        public basketService: BasketService,
        public baseService: ServiceBaseService,
        public translate: TranslateService,
        @Inject(SEO_SERVICE_IMPL) private _seoService: ISeoService
    ) {
        this.siteImageUrl = this.baseService.siteImageUrl;
    }

    ngOnInit() {
        this.authSub = this.authService.getAuthStatusListener().subscribe((isAuth) => {
            this.isAuth = isAuth;
        });
        this.favoritItemSub =
            this.favoriteListService.favoriteProductListObservable.subscribe(
                (favoriteList) => {
                    if (favoriteList != null) {
                        this.isFavoriteProduct = favoriteList.some((favoritItem) => {
                            if (
                                this.product.productID ===
                                favoritItem.originalProduct.productID
                            ) {
                                this.favoriteBasketItemId = favoritItem.basketItemId;
                                return true;
                            }
                            return false;
                        });
                    } else {
                        this.isFavoriteProduct = false;
                    }
                }
            );

        this.mediaServiceUrl = this.baseService.getMediaServiceUrl();
        this.sessionService.sessionSubject.subscribe((sessionSubject) => {
            this.isDeliveryAvailableByPostCode =
                sessionSubject.isDeliveryAvailableByPostCode;

            if (sessionSubject.isValidZip) {
                this.userSelectedShopIds = sessionSubject.selectedShopIds;
                const userSelectedShopIds = this.userSelectedShopIds;

                this.prod.productProvider.forEach(function (pp) {
                    pp.shopAvailable = userSelectedShopIds.indexOf(pp.providerID) > -1;
                });
            }

            if (!this.providerId) {
                this.productProviderArray = this.customProductOrder(true);
            } else {
                this.productProviderArray = [
                    this.prod.productProvider.find(
                        (provider) => provider.providerID === this.providerId
                    ),
                ];
                this.productProviderArray[0].shopAvailable = true;
            }
            if (this.productProviderArray) {
                for (let i = 0; i < this.productProviderArray.length; i++) {
                    if (
                        this.prodviderProductNameUniqueArr.indexOf(
                            this.productProviderArray[i]?.providerProductName
                        ) === -1
                    ) {
                        this.prodviderProductNameUniqueArr.push(
                            this.productProviderArray[i]?.providerProductName
                        );
                    }
                }
            } else {
                this.prod.available = false;
            }

            this.setThisQuantityList();
        });
        this.prod.galleryImageUrlList = [];
        this.prod.galleryImageUrlList.push(this.prod.mediaUrlL);

        this.updateURLwithShop();

        this.imgAlt = this._seoService.titleWrap(
            [
                'ROKSH',
                this.prod.category.categoryName,
                this.prod.productName,
                this.prod.selectedProviderCode,
            ]
                .filter(Boolean)
                .join(' ')
        );

        const a = this.prod.productMediaList.map((media) => media.mediaUrlL);
        for (let i = 0; i < a.length; i++) this.prod.galleryImageUrlList.push(a[i]);
        this.galleryImgArray = this.prod.galleryImageUrlList;
        if (this.galleryImgArray && this.galleryImgArray.length > 1)
            this.hasGallery = true;

        this.authStatusSub = this.createAuthSub();

        if (this.prod && this.prod.productProvider.length === 1) this.isOnePrice = true;
    }

    ngAfterContentInit() {
        this.DataLayerGa4Service.eCommerceItem(this.prod, GA4EventType.view_item);
    }

    private setThisQuantityList() {
        this.quantityList = this.basketService.createQuantityList(
            this.prod.minWeightStep,
            this.prod.maxWeightStep,
            this.prod.isBulk,
            this.prod.priceUnitType
        );
    }

    onProductFavoriteChange(): void {
        if (this.isAuthenticated) {
            this.changeFavorite();
        } else {
            void this.sharedModalService.presentSignInModal((loggedIn) => {
                if (loggedIn) this.changeFavorite();
            });
        }
    }

    private changeFavorite(): void {
        if (!this.isFavoriteProgress) {
            this.isFavoriteProgress = true;
            if (this.isFavoriteProduct) {
                this.removeProductFromFavorite(this.product.productID);
            } else {
                this.addProductToFavorite(this.product.productID);
                this.DataLayerGa4Service.datalayerUniversalPush(
                    'Functions',
                    'Add to favorite',
                    this.product.productName
                );
            }
        }
    }

    private removeProductFromFavorite(productId: number) {
        this.favoriteListService.removeProductFromFavorite(productId).subscribe(
            () => {
                this.isFavoriteProgress = false;
                this.isFavoriteProduct = false;
                this.onRemove.emit();
            },
            (err) => {
                this.isFavoriteProgress = false;
                console.error(err);
            }
        );
    }

    private addProductToFavorite(productId: number) {
        this.favoriteListService.addProductToFavorite(productId).subscribe(
            () => {
                this.isFavoriteProgress = false;
                this.isFavoriteProduct = true;
            },
            (err) => {
                this.isFavoriteProgress = false;
                console.error(err);
            }
        );
    }

    private createAuthSub(): Subscription {
        return this.authService.getAuthStatusListener().subscribe((newAuthStatus) => {
            this.isAuthenticated = newAuthStatus;
        });
    }

    openDetails() {
        const detailsContainer = document.querySelector('#detailsContainer');
        const detailsContainerOpener = document.querySelector('#detailsContainerOpener');

        detailsContainer.classList.remove('details-closed');
        detailsContainerOpener.classList.add('d-none');
    }

    ngAfterViewInit(): void {
        const height = this.detailsContainer?.nativeElement.offsetHeight;
        if (height > 150) {
            const detailsContainer = document.querySelector('#detailsContainer');
            const detailsContainerOpener = document.querySelector(
                '#detailsContainerOpener'
            );

            detailsContainer.classList.add('details-closed');
            detailsContainerOpener.classList.remove('d-none');
        }

        const onIntersection = (entries: IntersectionObserverEntry[]) => {
            for (const entry of entries) {
                const floatingProceTopElement =
                    document.querySelector('#floating-price-top');
                if (floatingProceTopElement) {
                    if (entry.intersectionRatio > 0) {
                        floatingProceTopElement.classList.remove('d-md-inline-block');
                        floatingProceTopElement.classList.add('d-md-none');
                    } else {
                        floatingProceTopElement.classList.remove('d-md-none');
                        floatingProceTopElement.classList.add('d-md-inline-block');
                    }
                }
            }
        };

        const options = {
            threshold: [0, 1],
        };

        this.addButtonInterscept = new IntersectionObserver(onIntersection, options);

        const testIntersceptElement = document.querySelector('#add-button-intersect');

        if (testIntersceptElement) {
            this.addButtonInterscept.observe(testIntersceptElement);
        }

        const content = document.querySelector('.scroll-bottleneck-fix');

        if (content) {
            const scroll$ = fromEvent(content, 'scroll').pipe(map(() => content));
            scroll$.subscribe((element) => {
                this.scrolled = element.scrollTop > 100;
            });
        }
    }

    onUserListAdd() {
        if (this.isAuthenticated) {
            this.createDialogService.openUserListAdd(this.product);
        } else {
            void this.sharedModalService.presentSignInModal((loggedIn) => {
                if (loggedIn) this.createDialogService.openUserListAdd(this.product);
            });
        }
    }

    zoomIOMobile(galleryImg = '') {
        this.mobileZoomedIn = !this.mobileZoomedIn;

        this.mobileZoomedImgSrc = galleryImg;
    }

    zoomIO() {
        this.isZoomedIn = !this.isZoomedIn;

        if (this.hasGallery) {
            this.customOptions = {
                responsive: {
                    autoWidth: true,
                    0: {
                        items: 1,
                    },
                },
            };
        }
    }

    formatIngredients(ingredientsXml: string) {
        const ingredientsArray = [];
        if (ingredientsXml) {
            ingredientsXml = ingredientsXml.trim();
            if (!ingredientsXml.startsWith('<'))
                return this.replaceSpecialCharsInStr(ingredientsXml);
            const parser = new DOMParser();
            const xmlDoc = <XMLDocument>(
                parser.parseFromString(ingredientsXml, 'text/xml')
            );
            const ingredientNodes = xmlDoc.documentElement.childNodes;
            for (let i = 0; i < ingredientNodes.length; i++) {
                if (ingredientNodes[i].nodeType === Node.ELEMENT_NODE) {
                    ingredientNodes[i].textContent = this.replaceSpecialCharsInStr(
                        ingredientNodes[i].textContent
                    );
                    ingredientsArray.push(ingredientNodes[i].textContent);
                }
            }

            return ingredientsArray.join(', ');
        } else {
            return null;
        }
    }

    formatAllergens(allergensXml: string) {
        const allergensArray = [];
        if (allergensXml) {
            allergensXml = allergensXml.trim();
            if (!allergensXml.startsWith('<'))
                return this.replaceSpecialCharsInStr(allergensXml);
            const parser = new DOMParser();
            const xmlDoc = <XMLDocument>parser.parseFromString(allergensXml, 'text/xml');
            const allergenNodes = xmlDoc.documentElement.childNodes;
            for (let i = 0; i < allergenNodes.length; i++) {
                if (allergenNodes[i].nodeType === Node.ELEMENT_NODE) {
                    allergenNodes[i].textContent = this.replaceSpecialCharsInStr(
                        allergenNodes[i].textContent
                    );
                    allergensArray.push(allergenNodes[i].textContent);
                }
            }

            return allergensArray.join(', ');
        } else {
            return null;
        }
    }

    customProductOrder(isShopFilter = false): ProductProviderDto[] {
        const minPriceArray = [];
        const othersArray = [];
        let fullArray: any[];

        for (let i = 0; i < this.prod.productProvider.length; i++) {
            if (
                (!this.prod.isBulk &&
                    this.prod.minPrice === this.prod.productProvider[i].price) ||
                (this.prod.isBulk &&
                    this.prod.minUnitPrice ===
                        Number(this.prod.productProvider[i].unitPrice))
            ) {
                minPriceArray.push(this.prod.productProvider[i]);
            } else {
                othersArray.push(this.prod.productProvider[i]);
            }
        }

        othersArray.sort((a: ProductProviderDto, b: ProductProviderDto) => {
            const aPrice = a.isBulk ? Number(a.unitPrice) : a.price;
            const bPrice = b.isBulk ? Number(b.unitPrice) : b.price;
            return aPrice - bPrice;
        });

        this.hasMoreCheapest = minPriceArray.length > 1;
        this.hasMoreShop =
            othersArray.length > 0 && minPriceArray.length + othersArray.length > 1;

        fullArray = minPriceArray.concat(othersArray);
        const tmpArr = [];

        if (this.availableShops && isShopFilter) {
            for (let i = 0; i < fullArray.length; i++) {
                if (fullArray[i].Available === true) {
                    tmpArr.push(fullArray[i]);
                }
            }
            for (let i = 0; i < fullArray.length; i++) {
                if (fullArray[i].Available === false) {
                    tmpArr.push(fullArray[i]);
                }
            }
            this.isOrderCOmpleted = true;
            fullArray = tmpArr;
        } else if (isShopFilter) {
            this.isOrderCOmpleted = true;
        }

        fullArray.sort((a: ProductProviderDto, b: ProductProviderDto) => {
            this.sessionService.sessionSubject.subscribe((session) => {
                if (session) {
                    this.userSelectedShopIds = session.selectedShopIds;
                    this.isProviderSelected = this.userSelectedShopIds.length > 0;
                } else {
                    this.isProviderSelected = false;
                }
            });

            if (this.userSelectedShopIds) {
                const aProviderAvailability = this.userSelectedShopIds.includes(
                    a.providerID
                );
                const bProviderAvailability = this.userSelectedShopIds.includes(
                    b.providerID
                );

                return aProviderAvailability === bProviderAvailability
                    ? 0
                    : aProviderAvailability
                    ? -1
                    : 1;
            }
        });
        return fullArray;
    }

    goToCategoryOnMissingProd() {
        let canGoToCategoryByPostCode = false;
        let canGoToCatgoryByShopSelected = false;

        if (
            (this.isDeliveryAvailableByPostCode == null ||
                this.isDeliveryAvailableByPostCode) &&
            (!this.sessionService.sessionSubject.value.zipCode ||
                !this.sessionService.sessionSubject.value.isValidZip ||
                this.sessionService.sessionSubject.value.zipCode.trim() == '')
        ) {
            this.createDialogService
                .openAddressDialog()
                .afterClosed()
                .subscribe(() => {
                    if (!this.isPostCodeSetted) {
                        this.translate
                            .get('product-details.add-your-postcode-to-explore')
                            .subscribe((text) => {
                                this.snackBar.open(text, null, {
                                    verticalPosition: 'top',
                                    horizontalPosition: 'center',
                                    duration: 3000,
                                });
                                return;
                            });
                    } else {
                        canGoToCategoryByPostCode = true;
                    }
                });
        } else {
            canGoToCategoryByPostCode = true;
        }
        if (!canGoToCategoryByPostCode) {
            return;
        }

        if (
            (typeof this.isDeliveryAvailableByPostCode == null ||
                this.isDeliveryAvailableByPostCode) &&
            this.isPostCodeSetted &&
            !this.isProviderSelectedForShopping
        ) {
            this.createDialogService
                .openShopSelectDialogSeparatedComponent()
                .afterClosed()
                .subscribe(() => {
                    if (!this.isProviderSelectedForShopping) {
                        this.translate
                            .get('product-details.select-shop-to-explore')
                            .subscribe((text) => {
                                this.snackBar.open(text, null, {
                                    verticalPosition: 'top',
                                    horizontalPosition: 'center',
                                    duration: 3000,
                                });
                                return;
                            });
                    } else {
                        canGoToCatgoryByShopSelected = true;
                        this.router.navigate([this.prod.category.url]);
                    }
                });
        } else {
            canGoToCatgoryByShopSelected = true;
            this.router.navigate([this.prod.category.url]);
        }
        if (!canGoToCatgoryByShopSelected) {
            return;
        }
        if (canGoToCatgoryByShopSelected && canGoToCategoryByPostCode) {
            this.router.navigate([this.prod.category.url]);
        }
    }

    percentageOfDiscount(): string {
        let percentage = 0;
        if (this.productProviderArray.length > 0) {
            if (!this.productProviderArray[0]?.isBulk) {
                percentage = !this.productProviderArray[0]?.depositFee
                    ? this.productProviderArray[0]?.price
                    : this.productProviderArray[0]?.price -
                      this.productProviderArray[0]?.depositFee;
            } else {
                percentage = !this.productProviderArray[0]?.depositFee
                    ? Number(this.productProviderArray[0]?.unitPrice)
                    : Number(this.productProviderArray[0]?.unitPrice) -
                      this.productProviderArray[0]?.depositFee;
            }

            const discount =
                ((this.productProviderArray[0]['originalPriceIfOffer'] - percentage) /
                    this.productProviderArray[0]['originalPriceIfOffer']) *
                100;

            return Math.floor(discount).toString();
        }
        return '0';
    }

    private updateURLwithShop() {
        if (
            this.prod.selectedProviderCode &&
            !window.location.pathname.startsWith('/' + this.prod.selectedProviderCode) &&
            !ServiceBaseService.isProviderOwnWebShop()
        )
            this.location.replaceState(
                this.prod.selectedProviderCode + window.location.pathname
            );
    }

    ngOnDestroy() {
        if (this.addButtonInterscept) {
            this.addButtonInterscept.disconnect();
        }
        if (this.authStatusSub) {
            this.authStatusSub.unsubscribe();
        }

        if (this.favoritItemSub) {
            this.favoritItemSub.unsubscribe();
        }
        if (this.authSub) {
            this.authSub.unsubscribe();
        }

        this.hasDescriptionParsed = false;
    }

    replaceSpecialCharsInStr(startStr: string) {
        startStr = startStr
            .replace('&amp;', '&')
            .replace('&amp', '&')
            .replace('&lt;', '<')
            .replace('&lt', '<')
            .replace('&gt;', '>')
            .replace('&gt', '>')
            .replace('&quot;', '"')
            .replace('&quot', '"')
            .replace('&apos;', "'")
            .replace('&apos', "'");
        return startStr;
    }

    isTextHtml(textToParse: string) {
        if (
            textToParse.indexOf('</p>') > -1 ||
            textToParse.indexOf('</span>') > -1 ||
            textToParse.indexOf('</strong>') > -1 ||
            textToParse.indexOf('</div>') > -1 ||
            textToParse.indexOf('</h1>') > -1 ||
            textToParse.indexOf('</h2>') > -1 ||
            textToParse.indexOf('</h3>') > -1 ||
            textToParse.indexOf('</h4>') > -1 ||
            textToParse.indexOf('</h5>') > -1 ||
            textToParse.indexOf('</h6>') > -1 ||
            textToParse.indexOf('<br>') > -1
        ) {
            return true;
        }
        return false;
    }

    showExternalSpinViewer(spinViewerUrl: string) {
        window.open(spinViewerUrl, '_blank');
    }

    onAddToBasket(isSuccess: boolean): void {
        if (!isSuccess) {
            this.createDialogService.openAddressDialog(true);
        }
    }
}
