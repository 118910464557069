import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    FormatterService,
    IMyOrder,
    IMyOrderItem,
    IRealtimeClientService,
    OrderService,
    ProductProviderDto,
    REALTIME_CLIENT_IMPL,
} from 'projects/library-shared/src/public-api';
import { AddOrUpdateOrderItemDialogComponent } from '../../containers/order-list/add-or-update-order-item/add-or-update-order-item.component';
import { MatDialog } from '@angular/material/dialog';
import { OrderState } from '../../../types/order-state';
import { OrderCustomerStatus } from '../../../types/ordercustomerstatus';
import { ShopSelectDialogSeparatedComponent } from '../shop-select-dialog-separated/shop-select-dialog-separated.component';
import { AddressDialogComponent } from '../address/address-dialog.component';
import { faWineBottle } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-orderitem-card',
    templateUrl: './orderitem-card.component.html',
    styleUrls: ['./orderitem-card.component.scss'],
})
export class OrderItemCard implements OnInit {
    @Input() public orderItem: IMyOrderItem;
    buttonText = '';
    @Output() onaddorreplace: EventEmitter<any> = new EventEmitter();

    isOpen = false;

    @Input() public order: IMyOrder;
    @Input() public btnReplace: boolean;

    productProvider: ProductProviderDto;

    totalProviderDepositProducts = 0;
    formattedTotalProviderDepositProductPrice = '';
    hasBadge = false;

    newItem = false;
    replaced = false;
    modified = false;
    removed = false;
    missing = false;
    faWineBottle = faWineBottle;

    AddressDialogComponent = AddressDialogComponent;
    ShopSelectDialogSeparatedComponent = ShopSelectDialogSeparatedComponent;

    constructor(
        public formatterService: FormatterService,
        private translate: TranslateService,
        private dialog: MatDialog,
        private orderService: OrderService,
        @Inject(REALTIME_CLIENT_IMPL) private _realtimeClient: IRealtimeClientService
    ) {
        this.translate
            .get('common.add-to-basket')
            .subscribe((text) => (this.buttonText = text));
    }

    ngOnInit() {
        this.isOpen = this.order.OrderCustomerStatus == OrderCustomerStatus.STORED;
        this.newItem = this.orderItem.IsNew;
        this.replaced = this.orderItem.OrderReplacementItem != null;
        this.modified = this.orderItem.IsModified == true;
        this.missing = this.orderItem.IsMissing;
        this.removed = this.orderItem.IsRemovedByCustomer;
        this.getTotalDepositAmount(this.orderItem);

        this.hasBadge =
            this.newItem ||
            this.removed ||
            this.modified ||
            this.replaced ||
            this.missing;

        this.orderItem.Product.productProvider?.forEach((pp) => {
            if (pp.providerID == this.order.ProviderID) this.productProvider = pp;
        });
    }

    get showUnavailable(): boolean {
        return this.order.OrderStateID !== +OrderState.CollectingInProgress;
    }

    get isCollectingInProgress(): boolean {
        return this.order.OrderStateID !== OrderState.CollectingInProgress;
    }

    get productName(): string {
        return !this.replaced
            ? this.orderItem.Product.productName
            : this.orderItem.OrderReplacementItem?.ItemName;
    }

    get collectedQuantity(): number {
        return !this.replaced
            ? this.orderItem.CollectedQuantity
            : this.orderItem?.OrderReplacementItem?.CollectedQuantity;
    }

    get productPackageQuantity(): string {
        if (!this.replaced)
            return (
                this.productProvider?.packageQuantity +
                ' ' +
                this.productProvider?.packageBaseUnit
            );
        else
            this.orderItem.OrderReplacementItem?.ProductProvider?.PackageQuantity +
                ' ' +
                this.orderItem.OrderReplacementItem?.ProductProvider?.PackageBaseUnit;
    }

    get unitPrice(): string {
        let resultText: string;
        const price = !this.replaced
            ? this.orderItem.UnitPrice
            : this.orderItem?.OrderReplacementItem?.UnitPrice;
        resultText = this.formatterService.formatCurrency(price).toString();
        this.translate
            .get('common.currency')
            .subscribe((text) => (resultText += ' ' + text));
        return resultText;
    }

    get quantityUnit(): string {
        if (!this.replaced) {
            if (this.orderItem.SellUnit) {
                return this.orderItem.SellUnit;
            } else if (
                this.orderItem.Product?.isBulk &&
                this.orderItem.Product?.priceUnitType
            ) {
                return this.orderItem.Product.priceUnitType;
            }
        } else {
            if (this.orderItem?.OrderReplacementItem?.Unit !== 'piece(s)') {
                let result: string;
                this.translate.get('common.kg').subscribe((text) => (result = text));
                return result;
            }
        }

        let result: string;
        this.translate.get('common.piece').subscribe((text) => (result = text));
        return result;
    }

    get actionTranslateBasedOnStatus(): string {
        let result: string;

        if (
            this.order.OrderCustomerStatus === OrderCustomerStatus.COLLECTING ||
            this.order.OrderCustomerStatus === OrderCustomerStatus.STORED
        )
            this.translate.get('orders.collected').subscribe((text) => (result = text));
        else
            this.translate
                .get('common.delivered-quantity')
                .subscribe((text) => (result = text));

        return result;
    }

    onAddOrReplace(stat: any) {
        this.onaddorreplace.emit(stat);
    }

    get formattedPrice(): string {
        const price = this.orderItem.UnitPrice;
        const quantity =
            this.orderItem.CollectedQuantity > 0 ||
            this.order.OrderStateID === +OrderState.CollectingInProgress
                ? this.orderItem.CollectedQuantity
                : this.orderItem.OrderedQuantity;

        return this.formatterService.formatCurrency(price * quantity).toString();
    }

    get formattedReplacementItemPrice(): string {
        let price = this.orderItem?.OrderReplacementItem?.Price;

        if (this.orderItem?.OrderReplacementItem?.QuantityRequestedByUser === 0) {
            price = 0;
        }
        return this.formatterService.formatCurrency(price).toString();
    }

    getTotalDepositAmount(orderItem: IMyOrderItem) {
        this.totalProviderDepositProducts = 0;

        if (!this.missing && !this.removed && !this.replaced) {
            orderItem.OrderItemDepositProductList?.forEach((orderItemDepositProduct) => {
                if (orderItemDepositProduct.CollectedQuantity) {
                    this.totalProviderDepositProducts +=
                        orderItemDepositProduct.CollectedQuantity *
                        orderItemDepositProduct.Price;
                } else {
                    this.totalProviderDepositProducts +=
                        orderItemDepositProduct.UnitValue * orderItemDepositProduct.Price;
                }
            });
        }

        if (
            orderItem.OrderReplacementItem?.OrderReplacementItemDepositProductList
                ?.length > 0
        ) {
            this.totalProviderDepositProducts = 0;
            orderItem.OrderReplacementItem.OrderReplacementItemDepositProductList.forEach(
                (orderReplacementItemDepositProduct: any) => {
                    if (orderReplacementItemDepositProduct.CollectedQuantity) {
                        this.totalProviderDepositProducts +=
                            orderReplacementItemDepositProduct.CollectedQuantity *
                            orderReplacementItemDepositProduct.Price;
                    } else {
                        this.totalProviderDepositProducts +=
                            orderReplacementItemDepositProduct.UnitValue *
                            orderReplacementItemDepositProduct.Price;
                    }
                }
            );
        }

        this.formattedTotalProviderDepositProductPrice = this.formatterService
            .formatCurrency(this.totalProviderDepositProducts)
            .toString();
    }

    // TODO: Later currentPrice will be removed, need to use the ordered price!
    onClickModify() {
        this.dialog
            .open(AddOrUpdateOrderItemDialogComponent, {
                maxWidth: '100vw',
                minWidth: '150px',
                maxHeight: '100vh',
                position: { top: '10vh' },
                data: { orderItem: this.orderItem, order: this.order },
            })
            .afterClosed()
            .subscribe((res) => {
                this.order.OrderedItems.forEach((itm) => {
                    if (itm?.ProductID == res?.ProductID) {
                        if (
                            res.OrderRemovedItemID == null ||
                            res.OrderRemovedItemID == ''
                        ) {
                            itm.Quantity = res.Quantity;
                            itm.OrderedQuantity = res.Quantity;
                            itm.Price = res.Price;
                            this.removed = false;
                            this.modified = true;

                            if (
                                itm.OrderItemDepositProductList &&
                                itm.OrderItemDepositProductList.length > 0
                            ) {
                                if (
                                    res.OrderItemDepositProductList.find(
                                        (item) => !item.depositUnitTypeIsOptional
                                    )
                                ) {
                                    itm.OrderItemDepositProductList.find(
                                        (item) => !item.depositUnitTypeIsOptional
                                    ).unitValue = res.OrderItemDepositProductList.find(
                                        (item) => !item.depositUnitTypeIsOptional
                                    ).UnitValue;
                                }

                                if (
                                    itm.OrderItemDepositProductList.find(
                                        (item) => item.depositUnitTypeIsOptional
                                    )
                                ) {
                                    itm.OrderItemDepositProductList.find(
                                        (item) => item.depositUnitTypeIsOptional
                                    ).unitValue = res.OrderItemDepositProductList.find(
                                        (item) => item.depositUnitTypeIsOptional
                                    ).UnitValue;
                                }
                            }
                        } else {
                            itm.OrderRemovedItemID = res.OrderRemovedItemID;
                            this.removed = true;
                        }
                    }
                });

                this.recalcFinalPriceIfStored();

                this.onaddorreplace.emit();
            });
    }

    recalcFinalPriceIfStored() {
        this.order.FinalPriceValueIfStored = 0;
        if (this.order.OrderCustomerStatus === OrderCustomerStatus.STORED) {
            let difference = 0;
            this.order.OrderedItems.forEach((item) => {
                if (item.IsNew || item.IsRemovedByCustomer) {
                    const priceAct =
                        item.OrderReplacementItem != null
                            ? item.OrderReplacementItem.Price
                            : item.Price;
                    if (
                        item.IsRemovedByCustomer ||
                        item.IsMissing ||
                        (item.OrderRemovedItemID != null &&
                            item.OrderReplacementItem == null)
                    ) {
                        difference -= priceAct;
                    } else {
                        difference += priceAct;
                    }
                }
            });

            this.orderService
                .getOrderTotalPrice(this.order.OrderId, difference)
                .subscribe((res) => (this.order.FinalPriceValueIfStored = res as number));
        }
    }

    onClickReplacementAccept() {
        this.orderService
            .OnClickReplacementAccept(this.orderItem.OrderItemID)
            .subscribe(() => {
                this.orderItem.OrderReplacementItem.QuantityRequestedByUser =
                    this.orderItem.OrderReplacementItem.Quantity;
                let indexOut = -1;
                this.order.ReplacementItems.forEach((item, index) => {
                    if (item.OrderItemID === this.orderItem.OrderItemID) {
                        this.order.CollectedItems =
                            this.order.CollectedItems.concat(item);
                        indexOut = index;
                    }
                });

                if (indexOut != -1) this.order.ReplacementItems.splice(indexOut, 1);

                this._realtimeClient.invoke(
                    'replacementmessagesendroksh',
                    'UserID',
                    this.orderItem.OrderItemID,
                    this.orderItem.OrderReplacementItem.CollectedQuantity
                );

                this.checkIfEveryReplacementHandled();
            });
    }

    onClickReplacementDecline() {
        this.orderService
            .OnClickReplacementDecline(this.orderItem.OrderItemID)
            .subscribe(() => {
                this.orderItem.OrderReplacementItem.QuantityRequestedByUser = 0;

                this.order.ReplacementItems.forEach((item) => {
                    if (item.OrderItemID === this.orderItem.OrderItemID) {
                        this.order.MissingItems = this.order.MissingItems.concat(item);

                        this.order.ReplacementItems = this.order.ReplacementItems.filter(
                            (i) => i.OrderItemID !== item.OrderItemID
                        );
                    }
                });

                this._realtimeClient.invoke(
                    'replacementmessagesendroksh',
                    'UserID',
                    this.orderItem.OrderItemID,
                    0
                );

                this.checkIfEveryReplacementHandled();
            });
    }

    checkIfEveryReplacementHandled() {
        if (this.order.ReplacementItems.length == 0) {
            this._realtimeClient.invoke(
                'allreplacementokmessagesendroksh',
                this.order.OrderId
            );
        }
    }
}
