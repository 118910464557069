<div class="container" style="width: 200px">
    <div *ngIf="inProgress">
        <p class="text-center">Aktivierung der E-Mail-Adresse läuft</p>
        <fa-icon
            [icon]="faUndo"
            flip="horizontal"
            class="mx-auto d-block"
            style="font-size: 40px; color: #4486f4; width: 41px"
        ></fa-icon>
    </div>

    <div *ngIf="!inProgress && !isError">
        <p class="text-center">E-Mail-Adresse aktiviert</p>
        <fa-icon
            [icon]="faCheck"
            class="mx-auto d-block"
            style="font-size: 40px; color: #4caf50; width: 41px"
        ></fa-icon>
        <button class="btn btn-primary d-block m-auto" (click)="onOpenLoginDialog()">
            Anmeldung
        </button>
    </div>
    <div *ngIf="!inProgress && isError">
        <p class="text-center">Etwas ist schief gelaufen. Bitte versuche es erneut!</p>
        <fa-icon
            [icon]="faTimes"
            class="mx-auto d-block"
            style="font-size: 40px; color: red; width: 41px"
        ></fa-icon>
    </div>
</div>
