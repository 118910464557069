import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
    CategoryBlockType,
    CategoryService,
    CategoryShared,
    ContainerIds,
    DashboardProductListDto,
    DashboardService,
    FavoriteListService,
    HtmlMarketingElementsExtendsDto,
    ISeoService,
    MarketingService,
    ProductBlockDto,
    ProductListService,
    ProviderSettingsService,
    ProviderSettingType,
    SEO_SERVICE_IMPL,
    ServiceBaseService,
    SessionService,
} from 'projects/library-shared/src/public-api';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { EmailActivationComponent } from '../landing/email-activation/email-activation.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { take } from 'rxjs/operators';
import { CreateDialogService } from '../../services/create-dialog.service';
import { HomeService } from '../../services/home/home.service';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
    dummyArray = new Array(12);
    dashboardArr: DashboardProductListDto;
    isPostCodeSetted = false;
    isProviderSelectedForShopping = false;
    shop: string;
    shopID: number;
    shopCoverImageUrl: string;
    userSelectedShops: string[];

    isDashboardCatLoading = false;
    actualMainCategoryInd = 0;
    dividedMainCategorys = [];

    hasMoreList = true;

    OFFSET_LOAD_MORE = 1500;

    progId = 'dashboard';
    allCustomBlocks: ProductBlockDto[];
    routeLink: string;
    allCategories: CategoryShared[] = [];
    minDeliveryFee = 990;

    siteImageUrl = '';
    seoTitle: string;
    seoMetaDescription: string;
    seoMetaKeywords: string;

    homeServiceSubscription = new Subscription();
    providerSettingsServiceSubscription = new Subscription();
    productListServiceSubscription = new Subscription();
    sessionSubjectSubscription = new Subscription();
    eventSubscription = new Subscription();
    categorySubscription = new Subscription();
    loadingMoreSubscription = new Subscription();
    windowScrollSubscription = new Subscription();
    categoryBlockTypes = CategoryBlockType;

    protected readonly ContainerIds = ContainerIds;
    htmlMarketingElementDto$: Observable<HtmlMarketingElementsExtendsDto>;

    constructor(
        private homeService: HomeService,
        private favoriteListService: FavoriteListService,
        private categoryService: CategoryService,
        private createDialogService: CreateDialogService,
        private dashboardService: DashboardService,
        private productListService: ProductListService,
        private title: Title,
        private meta: Meta,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private location: Location,
        private sessionService: SessionService,
        public baseService: ServiceBaseService,
        private _router: Router,
        private translate: TranslateService,
        private providerSettingsService: ProviderSettingsService,
        private marketingService: MarketingService,
        @Inject(SEO_SERVICE_IMPL) private _seoService: ISeoService
    ) {
        this.setMarketingElement();
    }

    private setMarketingElement(): void {
        this.htmlMarketingElementDto$ = this.marketingService.getHtmlMarketingElements(
            ContainerIds.landingTileDesktop
        );
    }

    ngOnInit() {
        this.sessionService.isProviderSelectedForShopping.next(true);
        this.route.paramMap.subscribe(() => {
            const activateCode = this.route.snapshot.queryParams['activateCode'];
            if (activateCode) {
                this.dialog.open(EmailActivationComponent, {
                    maxWidth: '95vw',
                    maxHeight: '85vh',
                    position: { top: '10vh' },
                    data: activateCode,
                });
            }
        });

        this.siteImageUrl = this.baseService.getSiteImageUrl();

        this.translate.get('router.offer').subscribe((text) => (this.routeLink = text));

        this.homeServiceSubscription = this.homeService.isSessionSet.subscribe(
            (isSet) => {
                if (isSet) {
                    this.actualMainCategoryInd = 0;
                    this.dividedMainCategorys = [];
                    this.allCategories = [];
                    window.addEventListener('scroll', this.onWindowScroll, true);

                    this.sessionSubjectSubscription = this.sessionService.sessionSubject
                        .pipe(take(1))
                        .subscribe((session) => {
                            this.favoriteListService
                                .getFavoriteProductList(session.selectedShopIds[0])
                                .subscribe();
                            // const selectedShopNotAvailable =
                            //     session.availableShops.length > 0 &&
                            //     !session.availableShops.includes(session.selectedShops[0]);

                            //Eliminated for now, because the shopselector panel pops up on dashboard
                            // if (selectedShopNotAvailable) {
                            //     this.createDialogService.openShopSelectDialogSeparatedComponent();
                            // }

                            this.actualMainCategoryInd = 0;
                            this.isDashboardCatLoading = false;
                            this.dashboardArr = new DashboardProductListDto();
                            this.dashboardArr.ProductListResults = [];
                            this.hasMoreList = true;

                            if (session) {
                                this.userSelectedShops = session.selectedShops;

                                this.dashboardService
                                    .getProductBlockList(this.progId, 1)
                                    .subscribe((result) => {
                                        this.allCustomBlocks = result;
                                    });
                            }

                            if (this.exactlyOneShopSelected()) {
                                const shop = session?.selectedShopCodes[0]?.toLowerCase();
                                this.shop = this.userSelectedShops[0];
                                this.shopID = session.selectedShopIds[0];

                                this.homeService
                                    .getProviderDataByShopName(session.selectedShops[0])
                                    .subscribe((res) => {
                                        if (res && res != '') {
                                            this.shopCoverImageUrl =
                                                this.siteImageUrl +
                                                'covers/' +
                                                res.CoverImage;
                                            if (res.CoverImage == null)
                                                this.shopCoverImageUrl =
                                                    'assets/dashboard-background.jpg';
                                            this.minDeliveryFee = res.MinDeliveryFee;
                                        } else {
                                            this.shopCoverImageUrl =
                                                'assets/dashboard-background.jpg';
                                        }
                                    });

                                this.translate
                                    .get('router.dashboard')
                                    .subscribe((page) => {
                                        /***change the selected shopname on providerchange ***/
                                        if (!ServiceBaseService.isProviderOwnWebShop()) {
                                            page = shop?.concat('/', page) ?? page;
                                        }
                                        const queryParams = window.location
                                            .toString()
                                            .split('?');
                                        if (queryParams.length > 1) {
                                            this.location.replaceState(
                                                page,
                                                queryParams[1]
                                            );
                                        } else {
                                            this.location.replaceState(page);
                                        }
                                        /***/
                                    });

                                this.updateSEOTags(shop);
                            } else {
                                this.shop = null;
                            }

                            this.categorySubscription =
                                this.categoryService.categories.subscribe(
                                    (allCategories) => {
                                        this.allCategories = allCategories;
                                        const newdividedMainCatList = [];
                                        this.actualMainCategoryInd = 0;
                                        this.dashboardArr.ProductListResults = [];
                                        const categorysplit = Math.ceil(
                                            allCategories.length / 4
                                        );

                                        for (let i = 0; i < categorysplit; i++) {
                                            newdividedMainCatList[i] = allCategories
                                                .slice(i * 4, (i + 1) * 4)
                                                .map((x) => x.ProgID);
                                        }

                                        this.dividedMainCategorys = newdividedMainCatList;

                                        if (
                                            this.dividedMainCategorys &&
                                            this.dividedMainCategorys.length > 0
                                        )
                                            this.loadDashboard();
                                    }
                                );
                        });

                    this.sessionService.isPostCodeSet.subscribe((result) => {
                        this.isPostCodeSetted = result;
                    });
                    this.sessionService.isProviderSelectedForShopping.subscribe(
                        (result) => {
                            this.isProviderSelectedForShopping = result;
                        }
                    );
                }
            }
        );
    }

    showShopSelectDialog() {
        if (!this.isPostCodeSetted) {
            this.createDialogService.openAddressDialog(true);
        } else {
            this.createDialogService.openShopSelectDialogSeparatedComponent();
        }
    }

    onWindowScroll = () => {
        this.windowScrollSubscription = this.homeService.isSessionSet.subscribe(
            (isSetted) => {
                if (isSetted) {
                    const d = document.documentElement;
                    const offset = d.scrollTop + window.innerHeight;
                    const height = d.offsetHeight;

                    if (
                        offset + this.OFFSET_LOAD_MORE >= height &&
                        this.dividedMainCategorys &&
                        this.dividedMainCategorys.length > 0
                    ) {
                        this.loadDashboard();
                    }
                }
            }
        );
    };

    showAvailableTimeSlots() {
        this.createDialogService.showAvailableTimeSlotsDialog(this.shop, this.shopID);
    }

    private exactlyOneShopSelected(): boolean {
        return this.userSelectedShops && this.userSelectedShops.length === 1;
    }

    private loadDashboard() {
        if (!this.hasMoreList) {
            window.removeEventListener('scroll', this.onWindowScroll, true);
        } else if (!this.isDashboardCatLoading) {
            this.isDashboardCatLoading = true;
            this.productListServiceSubscription = this.productListService
                .getAdditionalCategoryProductList(
                    this.dividedMainCategorys[this.actualMainCategoryInd]
                )
                .subscribe((dashboardArr) => {
                    this.isDashboardCatLoading = false;
                    if (!this.dashboardArr) {
                        this.dashboardArr = dashboardArr;
                    } else {
                        const dashboardArrElement =
                            this.dashboardArr.ProductListResults.find(
                                (ProductListResult) =>
                                    ProductListResult.CategoryHeader.CategoryName ===
                                    dashboardArr.ProductListResults[0]?.CategoryHeader
                                        .CategoryName
                            );

                        if (!dashboardArrElement) {
                            this.dashboardArr.ProductListResults = [
                                ...this.dashboardArr.ProductListResults,
                                ...dashboardArr.ProductListResults,
                            ];
                        }
                    }
                });

            this.actualMainCategoryInd++;
            if (this.dividedMainCategorys.length === this.actualMainCategoryInd) {
                this.hasMoreList = false;
            }
        }
    }

    private updateSEOTags(shop = '') {
        if (shop) {
            this.providerSettingsServiceSubscription = this.providerSettingsService
                .getProviderDesignForSEO(shop)
                .pipe(take(1))
                .subscribe((settings) => {
                    if (settings) {
                        const newSettings = this.providerSettingsService.settings
                            .getValue()
                            .concat(
                                settings.filter(
                                    (x) =>
                                        x.ProviderSettingType === ProviderSettingType.Text
                                )
                            );
                        this.providerSettingsService.settings.next(newSettings);

                        this.seoTitle =
                            settings.find((x) => x.Key === 'seo-title')?.Value ?? '';
                        this.seoMetaDescription =
                            settings.find((x) => x.Key === 'seo-meta-description')
                                ?.Value ?? '';
                        this.seoMetaKeywords =
                            settings.find((x) => x.Key === 'seo-meta-keywords')?.Value ??
                            '';
                    }
                });
        }

        this.eventSubscription = this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (event.url.includes(this.translate.instant('router.dashboard'))) {
                    this.updateSEOTags(this.shopID?.toString() ?? '');
                }
            }
        });

        this.translate.get('seo.landing-title').subscribe((text) => {
            if (text !== 'seo.landing-title') {
                const metaTitle = this._seoService.titleWrap(
                    ['ROKSH ', shop.toUpperCase(), text, this.seoTitle]
                        .filter(Boolean)
                        .join(' ')
                );

                this.title.setTitle(metaTitle);
            }
        });

        this.translate.get('seo.landing-meta-description').subscribe((text) =>
            this.meta.updateTag({
                name: 'description',
                content: [shop.toUpperCase(), text, this.seoMetaDescription]
                    .filter(Boolean)
                    .join(' '),
            })
        );

        this.translate.get('seo.landing-meta-keywords').subscribe((text) =>
            this.meta.updateTag({
                name: 'keywords',
                content: [shop.toUpperCase(), text, this.seoMetaKeywords]
                    .filter(Boolean)
                    .join(' '),
            })
        );
    }

    ngOnDestroy() {
        window.removeEventListener('scroll', this.onWindowScroll, true);
        this.dashboardArr = new DashboardProductListDto();
        this.dashboardArr.ProductListResults = [];
        this.homeServiceSubscription.unsubscribe();
        this.providerSettingsServiceSubscription.unsubscribe();
        this.productListServiceSubscription.unsubscribe();
        this.sessionSubjectSubscription.unsubscribe();
        this.eventSubscription.unsubscribe();
        this.categorySubscription.unsubscribe();
        this.loadingMoreSubscription.unsubscribe();
        this.windowScrollSubscription.unsubscribe();
    }
}
