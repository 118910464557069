<div id="preferencesPanel" [formGroup]="checkoutForm">
    <div formGroupName="preferencesForm">
        <mat-expansion-panel
            [expanded]="preferencesStepIsExpanded"
            [disabled]="preferencesStepIsDisabled"
            [class.activated-step]="preferencesStepIsActive"
            style="margin-bottom: 5px"
            (opened)="setStep(preferencesStepIndex)"
            hideToggle
        >
            <mat-expansion-panel-header
                class="checkout-panel-header"
                style="height: 90px !important; width: calc(100% - 10px)"
            >
                <div class="checkout-header-img">
                    <img
                        src="assets/svg/rokshImages/images/preference.svg"
                        class="expansion-header"
                    />
                </div>
                <div class="column w-100">
                    <mat-panel-title>
                        <strong class="w-100">
                            <div class="row ms-0">
                                <div class="col ps-0">
                                    {{ 'common.preferences' | translate }}
                                </div>
                            </div>
                        </strong>
                    </mat-panel-title>
                </div>
                <img
                    src="assets/svg/valid.svg"
                    class="d-none"
                    style="margin: auto 10px; height: 1rem"
                />
            </mat-expansion-panel-header>

            <div
                *ngFor="
                    let preferenceSettings of orderPreferenceSettings;
                    let idx = index
                "
                class="ms-2 md-2 mt-3 preference"
            >
                <div class="setting-title text-size-14 font-weight-bold">
                    {{ preferenceSettings.settingText }}
                </div>
                <div
                    *ngFor="
                        let choice of preferenceSettings.preferenceSettingOptionList;
                        let i = index
                    "
                    class="ms-3 mb-3 inner-block"
                >
                    <input
                        class="radio-custom"
                        type="radio"
                        name="choice-{{ idx }}"
                        [checked]="choice.defaultSelected"
                        [value]="choice.orderPreferenceSettingOptionId"
                        (change)="
                            selectPreferenceSetting(
                                choice.orderPreferenceSettingOptionId,
                                preferenceSettings.settingText
                            )
                        "
                        [id]="'choiceNr' + idx + '#' + i"
                    />

                    <label class="ms-3 label-text" [for]="'choiceNr' + idx + '#' + i">
                        {{ choice.optionText }}
                    </label>
                </div>
            </div>

            <mat-action-row
                class="paperinfo mb-3"
                *ngIf="
                    isPaperbagInfoTextVisible &&
                    ('checkout.paper-bag-info-header' | translate).length > 1
                "
            >
                <div class="row">
                    <div class="info-title">
                        <img
                            class="expansion-header-svg"
                            src="assets/svg/rokshImages/icons/info.svg"
                        />
                        <span class="ms-3"
                            >{{ 'checkout.paper-bag-info-header' | translate }}
                        </span>
                    </div>
                    <div class="info-text">
                        <label class="form-check-label">
                            <div [ngClass]="{ collapse: !paperBagInfoTextExpanded }">
                                {{ 'checkout.paper-bag-info-text' | translate }}
                                >
                            </div>

                            <div [ngClass]="{ collapse: paperBagInfoTextExpanded }">
                                {{ 'checkout.paper-bag-info-full-text' | translate }}
                            </div>
                            <small
                                *ngIf="!paperBagInfoTextExpanded; else collapsed"
                                class="less-more"
                                data-toggle="collapse"
                                data-target="#demo"
                                (click)="
                                    paperBagInfoTextExpanded = !paperBagInfoTextExpanded
                                "
                            >
                                {{ 'common.more' | translate }}...
                            </small>
                            <ng-template #collapsed>
                                <small
                                    class="less-more"
                                    data-toggle="collapse"
                                    data-target="#demo"
                                    (click)="
                                        paperBagInfoTextExpanded =
                                            !paperBagInfoTextExpanded
                                    "
                                >
                                    {{ 'common.less' | translate }}...
                                </small>
                            </ng-template>
                        </label>
                    </div>
                </div>
            </mat-action-row>

            <br />
            <mat-action-row class="text-center">
                <span
                    mat-button
                    class="col-12 col-md-6 m-auto m-md-0 btn btn-tovabb btn-rounded mb-2"
                    (click)="nextStep()"
                    >{{ 'common.next' | translate }}</span
                >
            </mat-action-row>
        </mat-expansion-panel>
    </div>
</div>
