import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LoadingService {
    isLoading = new BehaviorSubject<boolean>(false);

    getIsLoading() {
        return this.isLoading.asObservable();
    }

    setIsLoading(value: boolean) {
        this.isLoading.next(value);
    }
}
