<div class="container ps-5 pe-5 position-relative">
    <div class="container position-relative">
        <app-landing-static-first
            (tryItBtn)="showShopSelectDialog()"
        ></app-landing-static-first>
    </div>
</div>
<div id="shopSelector"></div>
<app-shop-select></app-shop-select>

<lib-html-marketing containerId="landingPageDesktop"></lib-html-marketing>

<div *ngIf="!isPostCodeSetted" class="container infos">
    <div class="row three-columns-info">
        <div class="col-12 mb-3 text-center">
            <h2 class="w-100 w-md-50 mx-auto mt-3">
                {{ 'dashboard.we-have-everything' | translate }}
            </h2>
        </div>

        <div class="col-12 col-md-4">
            <img
                src="/assets/svg/rokshImages/images/introduction_1.png"
                class="mb-2 mb-md-5"
            />

            <h3 class="mb-2 mb-md-4">
                {{ 'dashboard.shop-from-favourite-shops' | translate }}
            </h3>

            <p class="mb-4">
                {{ 'dashboard.select-favourite-shop-message' | translate }}
            </p>
        </div>

        <div class="col-12 col-md-4">
            <img
                src="/assets/svg/rokshImages/images/introduction_2.png"
                class="mb-2 mb-md-5"
            />

            <h3 class="mb-2 mb-md-4">{{ 'dashboard.we-pick-up' | translate }}</h3>

            <p class="mb-4">
                {{ 'dashboard.find-favourite-products-message' | translate }}
            </p>
        </div>

        <div class="col-12 col-md-4">
            <img
                src="/assets/svg/rokshImages/images/introduction_3.png"
                class="mb-2 mb-md-5"
            />

            <h3 class="mb-2 mb-md-4">
                {{ 'dashboard.deliver-on-specified-date' | translate }}
            </h3>

            <p class="mb-4">{{ 'dashboard.get-your-order-message' | translate }}</p>
        </div>
    </div>

    <div class="text-center">
        <button
            class="btn start-shopping-btn"
            *ngIf="!isPostCodeSetted || !isProviderSelectedForShopping"
            type="button"
            (click)="showShopSelectDialog()"
        >
            {{ 'dashboard.let-shopping-start' | translate }}
        </button>
    </div>
</div>

<div *ngFor="let block of commercialBlocks">
    <app-commercial-block
        (onClickEmitter)="handleClick()"
        [commercialBlock]="block"
    ></app-commercial-block>
</div>

<div class="container position-relative gyik-group">
    <div class="position-relative">
        <h2 class="mb-2 gyik-title">
            {{ 'info.frequently-asked-questions' | translate }}
        </h2>

        <app-aboutus [isReduced]="true"></app-aboutus>

        <br />

        <div class="w-100 text-center">
            <a
                href="{{
                    'static-links.roksh-knowledge'
                        | translate
                        | providerTheme : 'static-links.roksh-knowledge'
                        | async
                }}"
                target="_blank"
            >
                <u>{{ 'common.more-questions' | translate }} </u>
            </a>
        </div>

        <div class="btn-margin-bottom"></div>
    </div>
</div>

<div class="personal-shopper">
    <app-personal-shopper></app-personal-shopper>
</div>

<br />

<div class="container d-md-none">
    <div class="row">
        <div class="col-12 text-center">
            <img
                style="width: 285px !important"
                class="eu-blokk"
                src="../../../assets/barion-card-strip-intl_1200px.png"
                title="Barion"
                alt="Barion"
                *ngIf="
                    defaultPaymentProviders &&
                    defaultPaymentProviders.indexOf('BarionCard') > -1
                "
            />

            <img
                style="width: 270px !important"
                class="eu-blokk"
                src="../../../assets/revolut.png"
                title="Revolut"
                alt="Revolut"
                *ngIf="
                    defaultPaymentProviders &&
                    defaultPaymentProviders.indexOf('RevolutCard') > -1
                "
            />
        </div>
    </div>
</div>
