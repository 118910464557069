import { Component, HostListener, OnInit } from '@angular/core';
import { take } from 'rxjs/internal/operators/take';
import { ProviderSettingsService } from '../../services/provider-settings.service';
import { ServiceBaseService } from '../../services/service-base.service';
import { SessionService } from '../../services/session/session.service';
import { ImageSizeType, ProviderCoverImageResponseDto } from '../../index';
import { SharedHomeService } from '../../services/home/shared-home.service';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-provider-landing-carousel',
    templateUrl: './provider-landing-carousel.component.html',
    styleUrls: ['provider-landing-carousel.component.scss'],
})
export class ProviderLandingCarouselComponent implements OnInit {
    providerCoverImageResponseDtos: ProviderCoverImageResponseDto[];
    filteredCoverImages: ProviderCoverImageResponseDto[];

    faChevronRight = faChevronRight;
    faChevronLeft = faChevronLeft;

    screenHeight: number;
    currentImageSizeType: ImageSizeType;
    screenWidth: number;
    siteImageUrl = '';
    shopCoverImageUrl: string;

    isMobileApp: boolean;
    isLoading = true;

    customOptions: OwlOptions = {
        dots: true,
        loop: true,
        items: 1,
        slideBy: 1,
        startPosition: 0,
        autoplaySpeed: 300,
        autoplay: true,
        autoWidth: true,
        autoplayTimeout: 8000,
    };

    private selectedProvider: string;

    constructor(
        public baseService: ServiceBaseService,
        private sessionService: SessionService,
        private homeService: SharedHomeService,
        private providerSettingsService: ProviderSettingsService,
        private translate: TranslateService
    ) {
        this.siteImageUrl = this.baseService.getSiteImageUrl();
        this.isMobileApp = this.sessionService.isMobile;
        this.getScreenSize();
    }

    ngOnInit(): void {
        this.siteImageUrl = this.baseService.getSiteImageUrl();
        this.sessionService.sessionSubject.subscribe((sessionSubject) => {
            if (sessionSubject && sessionSubject.selectedShops?.length > 0) {
                this.providerSettingsService
                    .getProviderCoverImages(sessionSubject.selectedShopCodes[0])
                    .pipe(take(1))
                    .subscribe((providerCoverImageResponseDtos) => {
                        this.providerCoverImageResponseDtos =
                            providerCoverImageResponseDtos;
                        this.filterCoverImages();
                    });

                this.homeService
                    .getProviderDataByShopName(sessionSubject.selectedShops[0])
                    .subscribe((res) => {
                        if (res && res != '') {
                            this.shopCoverImageUrl =
                                this.siteImageUrl + 'covers/' + res.CoverImage;
                            if (res.CoverImage == null)
                                this.shopCoverImageUrl =
                                    'assets/dashboard-background.jpg';
                        } else {
                            this.shopCoverImageUrl = 'assets/dashboard-background.jpg';
                        }
                    });

                this.selectedProvider = sessionSubject.selectedShops[0];
            }
        });
    }

    private filterCoverImages(): void {
        if (this.providerCoverImageResponseDtos) {
            this.filteredCoverImages = this.providerCoverImageResponseDtos.filter(
                (coverImages) => {
                    return coverImages.imageSizeTypeID === this.currentImageSizeType;
                }
            );
            if (this.isMobileApp) {
                this.translate
                    .get(['router.search', 'router.category-block'])
                    .subscribe((translation) => {
                        this.convertToApplicationUrl(translation);

                        this.isLoading = false;
                    });
            } else {
                this.isLoading = false;
            }
        }
    }

    private convertToApplicationUrl(translation: any): void {
        this.filteredCoverImages.map((coverImage) => {
            if (!coverImage.cTAButtonUrl || coverImage.isCTAButtonExternalURL)
                return coverImage;

            const categoryProgId = coverImage.cTAButtonUrl?.split('/').pop();
            const isSearch = coverImage.cTAButtonUrl.includes(
                translation['router.search']
            );
            const isBlock = coverImage.cTAButtonUrl.includes(
                translation['router.category-block']
            );
            coverImage.cTAButtonUrl =
                'shop/' +
                this.selectedProvider +
                (isBlock || isSearch ? '/kinalat' : '/kategoriak') +
                (isSearch ? '/kereses/' : isBlock ? '/kategoria-blokk/' : '/') +
                categoryProgId;

            return coverImage;
        });
    }

    private setCurrentImageSizeType(): void {
        if (this.screenWidth < 576) {
            this.currentImageSizeType = ImageSizeType.Xs;
        } else if (this.screenWidth > 576 && this.screenWidth <= 768) {
            this.currentImageSizeType = ImageSizeType.Sm;
        } else if (this.screenWidth > 768 && this.screenWidth <= 992) {
            this.currentImageSizeType = ImageSizeType.Md;
        } else if (this.screenWidth > 992 && this.screenWidth <= 1200) {
            this.currentImageSizeType = ImageSizeType.Lg;
        } else if (this.screenWidth > 1200 && this.screenWidth <= 1400) {
            this.currentImageSizeType = ImageSizeType.Xl;
        } else if (this.screenWidth > 1400 && this.screenWidth <= 1600) {
            this.currentImageSizeType = ImageSizeType.Xxl;
        } else {
            this.currentImageSizeType = ImageSizeType.Xxxl;
        }
    }

    @HostListener('window:resize', ['$event'])
    getScreenSize(): void {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
        this.setCurrentImageSizeType();
        this.filterCoverImages();
    }
}
