import { ServiceBaseService } from '../services/service-base.service';

export const environmentProd = {
    production: true,
    apiUrl: 'https://localhost:5001/',
    facebookClientId: '516711728807278',
    googleClientId:
        '569587751630-i8lk28u43e277h7dittrqovf72q8k78q.apps.googleusercontent.com',
    digimarketerid: '94744893-3ade-4cb5-9fd8-579e03f9fd1e',
    loggedEnvironments: ['localhost', 'master', 'staging', 'production'],
    API_BASE_URL: ServiceBaseService.getStaticBaseUrl(),
    GOOGLE_API_KEY: 'AIzaSyBcIFCMxEax202tsFH5wJ-g51JpqNUdYhc',
};
