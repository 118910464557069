<app-header trace="header"></app-header>
<app-category-menu
    trace="category-menu"
    *ngIf="isCategoryMenuDisplayed"
></app-category-menu>
<app-user-menu *ngIf="isUserMenuOpened"></app-user-menu>
<div
    class="pb-5 router-container"
    [ngClass]="{
        'has-category-menu': isCategoryMenuDisplayed || isUserMenuOpened,
        'floating-footer': isFooterFloating
    }"
>
    <div
        id="side-basket-container"
        class="float-end position-absolute basket-place"
        [ngClass]="{ 'open-basket': openMenu }"
    >
        <div class="position-relative root-basket-container">
            <app-basket-new-wrapper trace="new-basket-wrapper"></app-basket-new-wrapper>
        </div>
    </div>
    <router-outlet></router-outlet>
</div>
<app-footer *ngIf="isFooterDisplayed" trace="footer"></app-footer>

<!--~~~~~~~~APP COMPONENT~~~~~~~~~~~~-->
