import { Injectable } from '@angular/core';
import {
    ProviderClient,
    ProviderFooterLegalInfoDTO,
} from 'projects/library-shared/src/public-api';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FooterService {
    private footerStatus = new BehaviorSubject<boolean>(false);

    constructor(private providerClient: ProviderClient) {}

    getFooterStatusListener() {
        return this.footerStatus.asObservable();
    }

    changeFooterStatus() {
        this.footerStatus.next(!this.footerStatus.value);
    }

    getLegalInfos(providerID: number): Observable<ProviderFooterLegalInfoDTO[]> {
        return this.providerClient.getProviderLegalInfos(providerID);
    }
}
