<div class="d-flex flex-column align-items-center">
    <div class="my-4 delete-img d-flex justify-content-center align-items-center">
        <img
            *ngIf="!deleteInProgress"
            class="h-100 w-100"
            src="{{ siteImageUrl }}images/shared-img/user-basket/delete_sign.svg"
            alt="delete icon"
        />
        <fa-icon
            size="2x"
            *ngIf="deleteInProgress"
            [icon]="faSpinner"
            [spin]="true"
        ></fa-icon>
    </div>
    <span class="title">{{ 'basket.empty-basket' | translate }}</span>
    <span class="my-4 description">{{
        'basket.are-you-sure-empty-basket' | translate
    }}</span>

    <div class="d-flex flex-row justify-content-between w-100">
        <button class="no-button me-2 w-50" (click)="noClicked()">
            {{ 'common.no' | translate }}
        </button>
        <button class="yes-button w-50" (click)="yesClicked()">
            {{ 'common.yes' | translate }}
        </button>
    </div>
</div>
