<lib-product-dto-card
    [isShoppingListCard]="isShoppingList"
    [isFavoriteListCard]="isFavoriteList"
    [commercialBlock]="commercialBlock"
    [product]="product"
    [basketItemId]="basketItemId"
    (cardClicked)="onClick()"
    (deleteEmitter)="deleteEmitter.emit($event)"
    (onRemove)="removeEmitter.emit($event)"
    [masterBasketId]="masterBasketId"
    [selectedShoppingListQuantity]="selectedShoppingListQuantity"
    [addressDialogComponent]="AddressDialogComponent"
    [providerDialogComponent]="ShopSelectDialogSeparatedComponent"
    [preferredWidth]="preferredWidth"
    (addOrReplaceEmitter)="onAddToBasket($event)"
    [productProviderName]="providerName"
    [productProviderId]="providerId"
></lib-product-dto-card>
