<div class="container">
    <br /><br />

    <mat-card class="card-psw" *ngIf="isOK">
        <mat-card-header>
            <h2>{{ 'password.new-password-success' | translate }}</h2>
        </mat-card-header>
        <mat-card-actions>
            <a
                [routerLink]="['/']"
                class="btn btn-outline-success btn-rounded w-100 mt-2"
            >
                {{ 'common.go-to-shop' | translate }}
            </a>
        </mat-card-actions>
    </mat-card>

    <form
        class="w-100 container mt-2 ms-0"
        [formGroup]="passwordForm"
        (ngSubmit)="onFormSubmit()"
        mat-dialog-content
    >
        <mat-card class="card-psw" *ngIf="!isOK">
            <mat-card-header>
                <h2>{{ 'password.set-new-password' | translate }}</h2>
            </mat-card-header>

            <mat-card-content>
                <mat-form-field class="w-100">
                    <input
                        formControlName="Password"
                        type="password"
                        matInput
                        placeholder="{{ 'password.new-password' | translate }}"
                    />
                    <mat-error
                        *ngIf="
                            passwordForm.get('Password').errors?.errorSpecialCharacter &&
                            passwordForm.get('Password').touched
                        "
                        >{{ 'password.invalid-special-character' | translate }}</mat-error
                    >
                    <mat-error
                        *ngIf="
                            passwordForm.get('Password').errors?.pattern &&
                            passwordForm.get('Password').touched
                        "
                        >{{ 'log-in.password-policiy-message' | translate }}</mat-error
                    >
                    <mat-error
                        *ngIf="
                            passwordForm.get('Password').errors?.required &&
                            passwordForm.get('Password').touched
                        "
                        >{{ 'log-in.password-required' | translate }}</mat-error
                    >
                </mat-form-field>
                <br />

                <mat-form-field class="w-100">
                    <input
                        formControlName="ConfirmPassword"
                        type="password"
                        matInput
                        placeholder="{{ 'password.new-password-again' | translate }}"
                    />
                    <mat-error
                        *ngIf="
                            passwordForm.get('ConfirmPassword').errors?.notSame &&
                            passwordForm.get('ConfirmPassword').touched
                        "
                        >{{ 'log-in.password-does-not-match' | translate }}</mat-error
                    >
                    <mat-error
                        *ngIf="
                            passwordForm.get('ConfirmPassword').errors?.required &&
                            passwordForm.get('ConfirmPassword').touched
                        "
                        >{{ 'log-in.password-required' | translate }}</mat-error
                    >
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions>
                <button
                    id="btnLDoSave"
                    type="submit"
                    class="btn btn-outline-success btn-rounded w-100 mt-2"
                >
                    {{ 'password.save-password' | translate }}
                </button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>
