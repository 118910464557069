import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Error404Component } from './error404/error404.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';

@NgModule({
    declarations: [Error404Component],
    imports: [CommonModule, TranslateModule, RouterLink],
    exports: [Error404Component],
})
export class PageNotFoundModule {}
