import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faEnvelope, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { ServiceBaseService } from '../../../services/service-base.service';
import { SessionService } from '../../../services/session/session.service';
import { UserBasketService } from '../../../services/user-basket.service';
import { PostMessageService } from '../../../services/post-message/post-message.service';

@Component({
    selector: 'lib-share-basket-by-email-dialog',
    templateUrl: './share-basket-by-email-dialog.component.html',
    styleUrls: ['./share-basket-by-email-dialog.component.scss'],
})
export class ShareBasketByEmailDialogComponent implements OnInit {
    isLoading = false;
    faSpinner = faSpinner;
    faMail = faEnvelope;
    link: string;
    isMobile: boolean;
    private isWrapper: boolean;
    @Input() userMasterBasketGUID: string;
    @Input() masterBasketId: number;
    @Input() providerId: number;
    @Input() closeEmitter: () => void;
    @Input() copyMobile: (link: string) => void;
    @Input() showLink: boolean;
    @Input() showCopyLink: boolean;

    sendBasketForm = this.formBuilder.group({
        Email: ['', [Validators.required, Validators.email]],
        LastName: ['', [Validators.required]],
        FirstName: ['', [Validators.required]],
    });

    constructor(
        private userBasketService: UserBasketService,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private postMessage: PostMessageService,
        sessionService: SessionService
    ) {
        this.isMobile = sessionService.isMobile;
        this.isWrapper = sessionService.isWrapper;
    }

    ngOnInit(): void {
        if (!this.isMobile) {
            const originalHost = window.location.href.split('?')[0];
            this.link = `${originalHost}?providerId=${this.providerId}&userMasterBasketGUID=${this.userMasterBasketGUID}`;
        } else {
            const originalHost = window.location.host;
            const domainSuffix =
                ServiceBaseService.languagecode === 'hu' ? '.com' : '.at';
            const host =
                originalHost.includes('staging') ||
                originalHost.includes('localhost') ||
                originalHost.includes('roksh-application-draft')
                    ? `https://staging.roksh${domainSuffix}`
                    : `https://www.roksh${domainSuffix}`;

            this.link = `${host}/felhasznalo/bevasarlolista?providerId=${this.providerId}&userMasterBasketGUID=${this.userMasterBasketGUID}`;
        }
    }

    onShare(): void {
        if (this.sendBasketForm.valid) {
            this.isLoading = true;
            this.userBasketService
                .sendUserBasketInEmail(
                    this.masterBasketId,
                    this.sendBasketForm.get('LastName').value,
                    this.sendBasketForm.get('FirstName').value,
                    this.sendBasketForm.get('Email').value
                )
                .subscribe(() => {
                    this.isLoading = false;
                    this.closeEmitter();
                    this.translate.get('user-basket.sent-in-mail').subscribe((text) =>
                        this.snackBar.open(text, null, {
                            verticalPosition: 'top',
                            horizontalPosition: 'center',
                            duration: 5000,
                        })
                    );
                });
        }
    }

    async copyOnMobile() {
        if (this.isWrapper) {
            this.postMessage.copyToClipboard(this.link);
        } else {
            await navigator.clipboard.writeText(this.link);
        }

        this.successfullyCopyMessage();
    }

    successfullyCopyMessage() {
        this.translate.get('user-basket.link-copied').subscribe((text) =>
            this.snackBar.open(text, null, {
                verticalPosition: 'top',
                horizontalPosition: 'center',
                duration: 5000,
            })
        );
    }
}
