// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { LogLevel } from '../models/logging/log-level.enum';
import { ServiceBaseService } from '../services/service-base.service';

export const environment = {
    production: false,
    apiUrl: 'https://localhost:5001/',
    logLevel: LogLevel.Info,
    facebookClientId: '516711728807278',
    googleClientId:
        '569587751630-i8lk28u43e277h7dittrqovf72q8k78q.apps.googleusercontent.com',
    digimarketerid: '94744893-3ade-4cb5-9fd8-579e03f9fd1e',
    loggedEnvironments: ['localhost', 'master', 'staging', 'production'],
    API_BASE_URL: ServiceBaseService.getStaticBaseUrl(),
    GOOGLE_API_KEY: 'AIzaSyBcIFCMxEax202tsFH5wJ-g51JpqNUdYhc',
    GOOGLE_PAY_FOR_HU: 'pk_o1MuiI7KyVR25mXb6j2aRAQa7ekm1TqlLesQcsnpUpHOD0jT',
    GOOGLE_PAY_FOR_AT: '',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
