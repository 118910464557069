import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    DataLayerGa4Service,
    GA4EventType,
    OrderCommentDTO,
    OrderService,
} from 'projects/library-shared/src/public-api';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-order-comment-dialog',
    templateUrl: './order-comment-dialog.component.html',
    styleUrls: ['./order-comment-dialog.component.scss'],
})
export class OrderCommentDialogComponent {
    faTimesCircle = faTimesCircle;

    now = new Date();
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<OrderCommentDialogComponent>,
        private formBuilder: FormBuilder,
        private orderService: OrderService,
        private dataLayerGa4Service: DataLayerGa4Service
    ) {}

    orderCommentForm = this.formBuilder.group({
        Comment: [this.data.comment, [Validators.minLength(1)]],
    });

    onSubmit() {
        const dto: OrderCommentDTO = {
            OrderCode: this.data.orderCode,
            Comment: this.orderCommentForm.get('Comment').value,
        };
        if (this.orderCommentForm.dirty && this.orderCommentForm.valid) {
            this.orderService.modifyOrderComment(dto).subscribe(
                (data) => {
                    this.dataLayerGa4Service.datalayerUniversalPush(
                        GA4EventType.personal_info,
                        GA4EventType.personal_info
                    );

                    this.dialogRef.close(data);
                },
                () => {
                    this.dialogRef.close();
                }
            );
        }
    }
}
