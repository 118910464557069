import { Provider } from '../..';

export class CategoryShared {
    CategoryName: string;
    ProgID: string;
    Level: number;
    Url: string;
    CategoryID: number;
    ParentCategory: CategoryShared;
    ChildList: CategoryShared[];
    CategoryIconFileName: string;
    CategoryImageFileName = '';
    IsRoot: boolean;
    CategoryProviderImage?: any;
    AlternativeCategoryProviderID: number;
    Provider: Provider;
}
