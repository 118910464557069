import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
    APPLE_SIGN_IN,
    CLOSE_APP,
    CLOSE_SENDBIRD_CHAT,
    COPY_TO_CLIPBOARD,
    CURRENT_URL,
    FACEBOOK_AUTH,
    FOOTER_COLOR,
    GEOLOCATION,
    GET_BUILD_NUMBER,
    GET_FCM_APNS_TOKEN,
    GET_FCM_TOKEN,
    GET_PLATFORM,
    GET_SYSTEM_NAME,
    GET_VERSION,
    GOOGLE_AUTH,
    GOOGLE_PAY,
    HARDWARE_BACK_PRESS,
    HASH_NOTCH,
    HEADER_COLOR,
    KEYBOARD_STATUS,
    ONE_SIGNAL_SET_EMAIL,
    ONE_SIGNAL_SET_TAG,
    OPEN_SENDBIRD_CHAT,
    OPEN_URL,
    ORIGIN,
    REQUEST_NOTIFICATION_USER_PERMISSION,
    SYSTEM_VERSION,
} from './function-name.constans';
import { TranslateService } from '@ngx-translate/core';
import {
    AllowedCardAuthMethodsType,
    AllowedCardNetworkType,
} from '../dto/google-pay/google-pay-request';
import { PostMessageResponse } from '../dto/post-message-response/post-message-response';
import { AlertInformationDto } from '../dto/alert-information/alert-information.dto';

@Injectable({
    providedIn: 'root',
})
export class PostMessageService {
    constructor(private translateService: TranslateService) {}
    currentPostMessage = new BehaviorSubject<PostMessageResponse>(
        {} as PostMessageResponse
    );

    public setCurrentPostMessage(postMessageResponse: string): void {
        try {
            this.currentPostMessage.next(
                JSON.parse(postMessageResponse) as PostMessageResponse
            );
        } catch {
            return;
        }
    }

    public getCurrentPostMessage(): BehaviorSubject<PostMessageResponse> {
        return this.currentPostMessage;
    }

    public googleAuth(): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(GOOGLE_AUTH);
    }

    public facebookAuth(): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(FACEBOOK_AUTH);
    }

    public appleSignIn(): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(APPLE_SIGN_IN);
    }

    public buildNumber(): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(GET_BUILD_NUMBER);
    }

    public systemName(): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(GET_SYSTEM_NAME);
    }

    public versionNumber(): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(GET_VERSION);
    }

    public systemVersion(): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(SYSTEM_VERSION);
    }

    public deviceNotch(): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(HASH_NOTCH);
    }

    public platformName(): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(GET_PLATFORM);
    }

    public keyboardStatus(): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(KEYBOARD_STATUS, false);
    }

    public setHeaderColor(rgbaColor = 'rbga(0,0,0,1)'): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(`${HEADER_COLOR}@${rgbaColor}`);
    }

    public setFooterColor(rgbaColor = 'rbga(0,0,0,1)'): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(`${FOOTER_COLOR}@${rgbaColor}`);
    }

    public setCurrentUrl(currentUrl: string): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(`${CURRENT_URL}@${currentUrl}`);
    }

    public setOrigin(origin: string): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(`${ORIGIN}@${origin}`);
    }

    public exitApplication(): void {
        if (window.ReactNativeWebView) {
            forkJoin([
                this.translateService.get('application.exit-application-title'),
                this.translateService.get('application.exit-application-message'),
                this.translateService.get('application.exit-application-cancel-button'),
                this.translateService.get('application.exit-application-confirm-button'),
            ]).subscribe(
                ([
                    exitApplicationTitle,
                    exitApplicationMessage,
                    exitApplicationCancelButton,
                    exitApplicationConfirmButton,
                ]: [string, string, string, string]) => {
                    const alertInformation = {
                        title: exitApplicationTitle,
                        message: exitApplicationMessage,
                        buttons: [
                            { text: exitApplicationCancelButton },
                            { text: exitApplicationConfirmButton },
                        ],
                    } as AlertInformationDto;
                    window.ReactNativeWebView.postMessage(
                        CLOSE_APP + '@' + JSON.stringify(alertInformation)
                    );
                }
            );
        } else {
            console.info(
                `The window.ReactNativeWebView object is only available from the device or android/iOS simulator! functionName: ${CLOSE_APP}`
            );
        }
    }

    private sendToPostMessageBridge(
        functionName: string,
        needPost = true
    ): Observable<PostMessageResponse> {
        if (window.ReactNativeWebView) {
            if (needPost) {
                window.ReactNativeWebView.postMessage(functionName);
            }

            return this.currentPostMessage.pipe(
                filter(
                    (postMessageResponse: PostMessageResponse) =>
                        postMessageResponse.functionName === functionName
                )
            );
        } else {
            console.info(
                `The window.ReactNativeWebView object is only available from the device or android/iOS simulator! functionName: ${functionName}`
            );
            return of({
                functionName: functionName,
                result: '-1',
                isSuccess: false,
            } as PostMessageResponse);
        }
    }

    public openUrl(event: Event, href = ''): void {
        if (window.ReactNativeWebView) {
            event.preventDefault();
        }

        const hrefString = href !== '' ? href : (event.target as HTMLAnchorElement).href;

        this.sendToPostMessageBridge(`${OPEN_URL}@${hrefString}`);
    }

    public getHardwareBackPress(): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(HARDWARE_BACK_PRESS);
    }

    public requestNotificationUserPermission(): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(REQUEST_NOTIFICATION_USER_PERMISSION);
    }

    public getFCMAPNSToken(): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(GET_FCM_APNS_TOKEN);
    }

    public getFCMToken(): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(GET_FCM_TOKEN);
    }

    public getLocation(): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(GEOLOCATION);
    }

    public copyToClipboard(text: string): Observable<PostMessageResponse> {
        const data = {
            message: COPY_TO_CLIPBOARD,
            data: text,
        };

        return this.sendToPostMessageBridge(JSON.stringify(data));
    }

    public googlePayRequest(
        gatewayMerchantId: string,
        totalPrice: string,
        currencyCode: string
    ): Observable<PostMessageResponse> {
        const allowedCardNetworks = ['VISA', 'MASTERCARD'] as AllowedCardNetworkType[];
        const allowedCardAuthMethods = [
            'PAN_ONLY',
            'CRYPTOGRAM_3DS',
        ] as AllowedCardAuthMethodsType[];
        const requestData = {
            message: GOOGLE_PAY,
            data: {
                cardPaymentMethod: {
                    tokenizationSpecification: {
                        type: 'PAYMENT_GATEWAY',
                        gateway: 'revolut',
                        gatewayMerchantId: gatewayMerchantId,
                    },
                    allowedCardNetworks,
                    allowedCardAuthMethods,
                },
                transaction: {
                    totalPrice: totalPrice,
                    totalPriceStatus: 'FINAL',
                    currencyCode: currencyCode,
                },
                merchantName: 'Weshop Kft.',
            },
        };
        return this.sendToPostMessageBridge(JSON.stringify(requestData));
    }
    public setOneSignalEmail(email: string): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(`${ONE_SIGNAL_SET_EMAIL}@${email}`);
    }

    public setOneSignalTag(key: string, value: string): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(`${ONE_SIGNAL_SET_TAG}@${key}@${value}`);
    }

    public openSendBirdChat(
        sendBirdChannelUrl?: string,
        SendBirdUserId?: string,
        sendBirdNickName?: string
    ): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(
            `${OPEN_SENDBIRD_CHAT}#${sendBirdNickName}#${SendBirdUserId}#${sendBirdChannelUrl}`
        );
    }
    public closeSendBirdChat(): Observable<PostMessageResponse> {
        return this.sendToPostMessageBridge(`${CLOSE_SENDBIRD_CHAT}`);
    }
}
