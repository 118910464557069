<div
    *ngIf="(tips && tips.length > 0) || isRokshTipAmountEnabled"
    id="tipPanel"
    [formGroup]="checkoutForm"
>
    <div formGroupName="tipForm">
        <hr />
        <div class="headline">{{ 'checkout.tip-headline' | translate }}</div>
        <div class="title">{{ 'checkout.tip-title' | translate }}</div>
        <div *ngIf="tips && tips.length > 0" class="mt-4">
            <div class="d-inline-flex">
                <div *ngFor="let tip of tips; let idx = index" class="">
                    <div
                        class="p-2 m-1 ms-0 me-2 tip d-flex justify-content-center text-size-12 pointer"
                        (click)="selectedTip(tip.RokshTipID)"
                        [ngClass]="{
                            'active-tip': isActiveTip(tip.RokshTipID)
                        }"
                    >
                        {{ tip.Amount | currencyFormatted }}
                        {{ 'common.currency' | translate }}
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="isRokshTipAmountEnabled" class="row mt-1">
            <div class="clearfix"></div>
            <mat-form-field class="col-12 col-md-6" appearance="outline">
                <input
                    formControlName="TipAmount"
                    class="checkout-form-input"
                    (input)="setTipAmount($event)"
                    type="number"
                    matInput
                    maxlength="4"
                    min="0"
                    placeholder="{{ 0 | currencyFormatted }} {{
                        'common.currency' | translate
                    }}"
                    id="amount"
                    step="{{ isAtCountryCode ? '0.5' : '1' }}"
                />
            </mat-form-field>
        </div>
        <hr />
    </div>
</div>
