import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { faChevronRight, faUser } from '@fortawesome/free-solid-svg-icons';
import {
    ArchieConversationThreadDto,
    ArchieConversationThreadMessageDto,
    ArchieProviderDto,
    ProductDto,
    ProductListClient,
} from '../../..';
import { ServiceBaseService } from '../../../services/service-base.service';

export type ArchieProductList = {
    providerName: string;
    providerId: number;
    productList: ProductDto[];
};

@Component({
    selector: 'lib-message-box',
    templateUrl: './message-box.component.html',
    styleUrls: ['./message-box.component.scss'],
})
export class MessageBoxComponent implements OnInit {
    @Input() archieConversationThreadMessageDto: ArchieConversationThreadMessageDto;
    @Input() openProductListEmitter: EventEmitter<ArchieProductList>;
    @Input() selectedProviders: ArchieProviderDto[];
    @Input() streamEnabled: boolean;
    @Input() answerDoneEmitter: EventEmitter<boolean>;
    @Input() archieConversationThread: ArchieConversationThreadDto;
    @Output() clickProduct: EventEmitter<{
        product: ProductDto;
        providerId: number;
        providerName: string;
    }> = new EventEmitter();
    @Output() clickShowTimeSlot: EventEmitter<number> = new EventEmitter();
    @Output() autoMessage: EventEmitter<string> = new EventEmitter();
    private isPredefinedFunctionsInitialized = false;
    private initDone = false;
    productLoadingIsInProgress = false;
    productDtoArr: ArchieProductList[] = [];
    providerIdArr: number[];
    faUser = faUser;
    faChevronRight = faChevronRight;
    siteImageUrl: string;

    constructor(
        private elementRef: ElementRef,
        private productListClient: ProductListClient,
        baseService: ServiceBaseService
    ) {
        this.siteImageUrl = baseService.siteImageUrl;
    }

    ngOnInit(): void {
        if (this.streamEnabled) {
            this.answerDoneEmitter.subscribe((answerDone) => {
                if (answerDone) {
                    this.initPredefinedFunctions();
                }
            });
        } else {
            setTimeout(() => {
                this.initPredefinedFunctions();
            }, 1500);
        }
    }

    ///https://stackblitz.com/edit/angular-innerhtml-examples-bt4mjh?file=src%2Fapp%2Fapp.component.ts,src%2Fapp%2Fapp.component.html,src%2Fapp%2Ftrust-html.pipe.ts
    replyToArchie(): void {
        alert('You clicked on a parsed content');
    }

    userSelectedThisAnswer(event: Event): void {
        const text = (event.target as HTMLDivElement).innerText;
        this.autoMessage.emit(text);
    }

    initPredefinedFunctions(): void {
        if (this.initDone) return;

        this.isPredefinedFunctionsInitialized = false;
        const element = this.elementRef.nativeElement as HTMLDivElement;
        if (this.isPredefinedFunctionsInitialized) {
            return;
        }

        const isClickable = element.querySelector('#helloBtnh5');
        if (isClickable) {
            element
                .querySelector('#helloBtnh5')
                .addEventListener('click', this.replyToArchie.bind(this));
        }

        const isSelectable = element.querySelectorAll('.selectable-answer');
        if (isSelectable) {
            const clickableContents = element.querySelectorAll('.selectable-answer');

            for (let i = 0; i < clickableContents.length; i++) {
                clickableContents[i].addEventListener(
                    'click',
                    this.userSelectedThisAnswer.bind(this)
                );
            }
        }

        const isProductSuggest = element.querySelectorAll('.archie-suggestion-products');
        if (isProductSuggest && isProductSuggest.length > 0) {
            this.productLoadingIsInProgress = true;
            const newElement = document.createElement('div');
            newElement.innerHTML =
                this.archieConversationThreadMessageDto.structuredMessageBody;
            const suggestionProducts = newElement.getElementsByClassName(
                'archie-suggestion-products'
            );
            for (let i = 0; i < suggestionProducts.length; i++) {
                const productContent = suggestionProducts[i].innerHTML;
                const id = suggestionProducts[i].id;
                let provider: ArchieProviderDto;
                if (+id) {
                    provider = this.selectedProviders.find(
                        (current) => current.providerId === +id
                    );
                } else {
                    provider = this.selectedProviders.find(
                        (current) =>
                            current.providerName.toLowerCase() === id.toLowerCase()
                    );
                }
                if (!provider) continue;

                const productIdArr: number[] = productContent
                    .replace('[', '')
                    .replace(']', '')
                    .split(',')
                    .map((value) => +value);

                this.productListClient
                    .getProductListByProductIds(provider.providerId, productIdArr)
                    .subscribe((productList) => {
                        this.productDtoArr.push({
                            providerName: provider.providerName,
                            providerId: provider.providerId,
                            productList,
                        });
                    });
                this.productLoadingIsInProgress = false;
            }
        }

        //initProviderIds if there is any
        if (
            this.elementRef.nativeElement.querySelectorAll('.time-slot-search') &&
            this.elementRef.nativeElement.querySelectorAll('.time-slot-search').length > 0
        ) {
            this.providerIdArr = this.archieConversationThread.providerDto.map(
                (provider) => provider.providerId
            );
        }

        this.isPredefinedFunctionsInitialized = true;
        this.initDone = true;
    }

    handleClickProduct(
        product: ProductDto,
        providerId: number,
        providerName: string
    ): void {
        this.clickProduct.emit({ product, providerId, providerName });
    }

    handleClickShowTimeSlot(providerID: number) {
        this.clickShowTimeSlot.emit(providerID);
    }

    showAllProducts(productArray: ArchieProductList): void {
        this.openProductListEmitter.next(productArray);
    }
}
