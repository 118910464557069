import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
    AuthenticationService,
    BasketService,
    DataLayerGa4Service,
    ProviderDTO,
    ServiceBaseService,
    SharedModalService,
    UserBasketDto,
    UserBasketProductDto,
    UserBasketService,
    UserShared,
} from 'projects/library-shared/src/public-api';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-user-basket-list',
    templateUrl: './user-basket-list.component.html',
    styleUrls: ['./user-basket-list.component.scss'],
})
export class UserBasketListComponent implements OnInit, OnDestroy {
    userBasketList: UserBasketDto[];
    selectedUserBasket: UserBasketDto;
    userBasketSubscription: Subscription;
    user: UserShared = new UserShared();
    isUserBasketInProgress = true;
    menuLevel = 1;

    latestProvider: ProviderDTO;

    selectedProvider: ProviderDTO;
    isDiscountAvailable: boolean;
    siteImageUrl: string;
    private currentProductList: UserBasketProductDto[];

    constructor(
        private authService: AuthenticationService,
        private DataLayerGa4Service: DataLayerGa4Service,
        private basketService: BasketService,
        private userBasketService: UserBasketService,
        private sharedModalService: SharedModalService,
        private title: Title,
        private translate: TranslateService,
        baseService: ServiceBaseService
    ) {
        this.siteImageUrl = baseService.siteImageUrl;
    }

    ngOnInit(): void {
        this.basketService.isRokshDiscountAvailableSubject.subscribe((available) => {
            this.isDiscountAvailable = available;
        });
        this.translate.get('common.shopping-list-title').subscribe((title) => {
            this.title.setTitle(title);
        });

        this.authService.getAuthStatusListener().subscribe((newAuthStatus) => {
            if (newAuthStatus) {
                this.user = this.authService.getLoggedInUser();
            }
        });

        this.DataLayerGa4Service.datalayerUniversalPush(
            'Functions',
            'Open Shopping lists'
        );

        this.userBasketService.userBasketListSubject.subscribe((userbasketList) => {
            this.userBasketList = userbasketList;
        });
    }

    loadUserBaskets(selectedProvider?: ProviderDTO): void {
        this.userBasketList = [];
        if (selectedProvider) this.latestProvider = selectedProvider;

        this.isUserBasketInProgress = true;
        this.userBasketService.getUserBaskets(this.latestProvider?.id).subscribe(
            (userBasketList) => {
                this.userBasketList = userBasketList;
                this.isUserBasketInProgress = false;
            },
            () => {
                this.isUserBasketInProgress = false;
            }
        );
    }

    onUserBasketSelect(userBasket: UserBasketDto): void {
        this.selectedUserBasket = userBasket;

        this.userBasketService
            .getUserBasketDetails(userBasket.providerID, userBasket.userMasterBasketGUID)
            .subscribe((userBasket) => {
                const updatedBasketIndex = this.userBasketList.findIndex(
                    (x) => x.userMasterBasketGUID === userBasket.userMasterBasketGUID
                );
                this.userBasketList[updatedBasketIndex].totalPrice =
                    userBasket.totalPrice;

                this.currentProductList = userBasket.userBasketProductList.sort(
                    (a, b) => {
                        return a.product.productID - b.product.productID;
                    }
                );
                this.selectedUserBasket.userBasketProductList = [
                    ...this.currentProductList,
                ];

                this.userBasketService.setselectedUserBasketProductList(
                    userBasket.userBasketProductList
                );
            });
    }

    onCreateNewUserBasket(): void {
        this.sharedModalService.createNewShoppingList((data) => {
            if (data) this.loadUserBaskets();
        });
    }

    onBasketDeleted(productId: number): void {
        if (productId) {
            this.currentProductList = this.currentProductList.filter(
                (item) => item.product.productID !== productId
            );
            this.selectedUserBasket.userBasketProductList = [...this.currentProductList];

            const updatedBasketIndex = this.userBasketList.findIndex(
                (basket) =>
                    basket.userMasterBasketGUID ===
                    this.selectedUserBasket.userMasterBasketGUID
            );
            this.userBasketList[updatedBasketIndex].totalPrice =
                this.selectedUserBasket.userBasketProductList.reduce(
                    (partialSum, basketProduct) =>
                        partialSum +
                        basketProduct.product.price * basketProduct.unitValue,
                    0
                );
        } else {
            this.selectedUserBasket = null;
            this.menuLevel = 1;
            this.loadUserBaskets(this.latestProvider);
        }
    }

    onProviderChange(provider: ProviderDTO): void {
        this.loadUserBaskets(provider);
        this.selectedProvider = provider;

        if (this.selectedUserBasket?.providerName !== this.selectedProvider?.name)
            delete this.selectedUserBasket;
    }

    ngOnDestroy(): void {
        if (this.userBasketSubscription) {
            this.userBasketSubscription.unsubscribe();
        }
    }
}
