<div class="popular-component position-relative ontop-ofbg mt-5">
    <h2
        *ngIf="customBlock"
        class="float-start text-white float-md-left text-size-42"
        style="z-index: 2"
    >
        {{ customBlock.Title }}
    </h2>
    <p
        *ngIf="customBlock"
        class="float-start float-md-end text-size-16"
        style="z-index: 2"
    >
        <a
            *ngIf="customBlock.RelatedUrl"
            class="no-hover text-white font-weight-600"
            href="{{ customBlock.RelatedUrl }}"
            style="text-decoration: underline"
        >
            {{ 'common.go-to-category' | translate }}
            <fa-icon [icon]="faChevronRight"></fa-icon>
        </a>
    </p>
    <p
        *ngIf="customBlock"
        class="ps-0 float-start text-white float-md-left text-size-16 col-12"
    >
        {{ customBlock.Description }}
    </p>
    <div class="popular-products" *ngIf="popularProducts">
        <div mat-tab-nav-bar class="pp-container">
            <div class="ghost-first"></div>
            <ng-container *ngFor="let product of popularProducts; let ind = index">
                <div class="product-img" [ngClass]="{ 'd-md-none': ind > 3 }">
                    <a
                        [href]="[
                            'router.products' | translate,
                            product.CategorySEOName | lowercase,
                            product.SEOName | lowercase
                        ]"
                        [navigateUrl]="[
                            'router.products' | translate,
                            product.CategorySEOName | lowercase,
                            product.SEOName | lowercase
                        ]"
                        [productId]="product.ProductName | lowercase"
                        [productName]="product.SEOName"
                        class="product-card-image-link"
                        appProductCardLink
                    >
                        <ng-container
                            *ngIf="
                                product['MediaUrlS'] ===
                                mediaServiceUrl + 'interface/thumbnail/'
                            "
                        >
                            <img
                                src="assets/images/roksh_logo.svg"
                                style="height: auto; padding: 20px"
                            />
                        </ng-container>
                        <ng-container
                            *ngIf="
                                product['MediaUrlS'] !==
                                mediaServiceUrl + 'interface/thumbnail/'
                            "
                        >
                            <ng-container
                                *ngIf="lazyImg; then lazyImg; else notLazyImg"
                            ></ng-container>
                            <ng-template #lazyImg
                                ><img
                                    itemprop="image"
                                    alt="{{ product.CategorySEOName }} {{
                                        product.ProductName
                                    }}"
                                    [lazyLoad]="product['MediaUrlS']"
                                    [defaultImage]="'assets/images/roksh_logo.svg'"
                            /></ng-template>
                            <ng-template #notLazyImg
                                ><img
                                    itemprop="image"
                                    alt="{{ product.CategorySEOName }} {{
                                        product.ProductName
                                    }}"
                                    [src]="product['MediaUrlS']"
                            /></ng-template>
                        </ng-container>
                    </a>
                    <img src="assets/popular_product_roksh_logo.png" class="pp_logo" />
                    <!--<span class="tooltip-text">{{ product.ProductName }}</span>-->
                </div>
            </ng-container>
            <div class="ghost-last"></div>
        </div>
        <div class="clearfix"></div>
    </div>
    <div
        *ngIf="customBlock"
        class="position-absolute popular-background"
        [style.background-color]="customBlock.BackColor"
        [style.background-image]="BackGUID"
    ></div>
</div>
