<button
    class="w-100 h-100 address-btn d-flex justify-content-center align-items-center"
    type="button"
    (click)="onClickGiveAddress()"
>
    <img
        src="assets/svg/rokshImages/icons/location_white.svg"
        class="location-icon-img me-2"
    />
    <span>{{ zipCode }}, {{ address }}</span>
</button>
