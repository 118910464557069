import { BrowserModule } from '@angular/platform-browser';
import {
    APP_INITIALIZER,
    CUSTOM_ELEMENTS_SCHEMA,
    ErrorHandler,
    NgModule,
} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { ProductDetailsComponent } from './containers/product-details/product-details.component';
import { AboutUsComponent } from './containers/aboutus/aboutus.component';
import { LogInDialogComponent } from './components/header/log-in/log-in-dialog/log-in-dialog.component';
import { SearchComponent } from './components/search/search.component';
import { ProductDetailsDialogComponent } from './containers/product-details/product-details-dialog/product-details-dialog.component';
import { ProductDetailsContentComponent } from './containers/product-details/product-details-content/product-details-content.component';
import { CommonModule, DecimalPipe, registerLocaleData } from '@angular/common';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import {
    FacebookLoginProvider,
    GoogleLoginProvider,
    GoogleSigninButtonModule,
    SocialAuthServiceConfig,
    SocialLoginModule,
} from '@abacritt/angularx-social-login';

import {
    API_BASE_URL,
    AuthInterceptor,
    DATALAYER_SERVICE_IMPL,
    DataLayerGa4Service,
    ErrorHandlerService,
    LoaderInterceptor,
    LOCATION_SERVICE_IMPL,
    LocationService,
    MyMonitoringService,
    ORDER_SERVICE_IMPL,
    OrderService,
    PageNotFoundModule,
    ProductNutritionModule,
    ProviderLandingCarouselModule,
    REALTIME_CLIENT_IMPL,
    RealtimeClientService,
    RESTRICTION_CHECKER_SERVICE_IMPL,
    RestrictionCheckerService,
    SEO_SERVICE_IMPL,
    SeoService,
    ServiceBaseService,
    SESSION_SERVICE_IMPL,
    SessionService,
    SNACKBAR_SERVICE_IMPL,
    SnackBarService,
    ThemePipeModule,
    TIMESLOT_SERVICE_IMPL,
    TimeSlotService,
} from 'projects/library-shared/src/public-api';
import { SharedModule } from './shared.module';
import { ProductAvailablePipe } from './containers/product-details/product-details-content/product-available.pipe';
import { ProductPriceRowComponent } from './containers/product-details/product-details-content/product-price-row/product-price-row.component';
import { RegistrationComponent } from './components/header/log-in/reg/registration.component';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { FooterComponent } from './components/footer/footer.component';
import { PasswordComponent } from './containers/password/password.component';
import { InformativeDashboardComponent } from './containers/informative-dashboard/informative-dashboard.component';
import { ProfileComponent } from './containers/profile/profile.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { OrderListComponent } from './containers/order-list/order-list.component';
import { Router, RouterModule } from '@angular/router';
import { CookiesComponent } from './containers/cookies/cookies.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { PaymentComponent } from './containers/payment/payment.component';
import { NewCheckoutComponent } from './containers/checkout/new-checkout.component';
import { CheckoutAddressComponent } from './containers/checkout/checkout-address/checkout-address.component';
import { CheckoutDeliveryTimeComponent } from './containers/checkout/checkout-deliverytime/checkout-delivery-time.component';
import { CheckoutPaymentComponent } from './containers/checkout/checkout-payment/checkout-payment.component';
import { CheckoutWishListComponent } from './containers/checkout/checkout-wishlist/checkout-wishlist.component';
import { CheckoutSummaryComponent } from './containers/checkout/checkout-summary/checkout-summary.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import localeHu from '@angular/common/locales/hu';
import { CheckoutPreferencesComponent } from './containers/checkout/checkout-preferences/checkout-preferences.component';
import { AddItemToOrderDialogComponent } from './containers/order-list/add-item-to-order/add-item-to-order.component';
import { ConfirmCancellationDialogComponent } from './components/confirm-cancellation-dialog/confirm-cancellation-dialog.component';
import { OrderContactDetailsDialogComponent } from './containers/order-list/order-contact-details-dialog.component';
import { OrderCommentDialogComponent } from './containers/order-list/order-comment-dialog.component';
import { OrderTimeslotsDialogComponent } from './containers/order-list/order-timeslots-dialog/order-timeslots-dialog.component';
import { DeliveryAddressDialogComponent } from './containers/order-list/delivery-address/delivery-address-dialog.component';
import { AddOrUpdateOrderItemDialogComponent } from './containers/order-list/add-or-update-order-item/add-or-update-order-item.component';
import { OrderComponent } from './containers/order-list/order.component';
import { OrderCardComponent } from './containers/order-list/order-card.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AddressDialogComponent } from './components/address/address-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { AppIconModule } from './app-icon.module';
import { HOME_SERVICE_IMPL } from './services/home/i-home-service';
import { HomeService } from './services/home/home.service';
import { environment } from '../environments/environment';
import { CommercialBlockComponent } from './components/commercial-block/commercial-block.component';
import { ProviderTimeSlotsDialogComponent } from './containers/dashboard/provider-time-slots-dialog/provider-time-slots-dialog.component';
import * as Sentry from '@sentry/angular-ivy';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { ArchieDesktopComponent } from './containers/archie/archie-desktop.component';

registerLocaleData(localeHu, 'hu-HU');

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        AppComponent,
        HeaderComponent,
        ProductDetailsComponent,
        AboutUsComponent,
        CookiesComponent,
        LogInDialogComponent,
        ProductDetailsDialogComponent,
        ProductDetailsContentComponent,
        SearchComponent,
        ProductAvailablePipe,
        ProductPriceRowComponent,
        RegistrationComponent,
        ProfileComponent,
        DashboardComponent,
        InformativeDashboardComponent,
        FooterComponent,
        PasswordComponent,
        ConfirmDialogComponent,
        OrderListComponent,
        ProviderTimeSlotsDialogComponent,
        NewCheckoutComponent,
        CheckoutAddressComponent,
        CheckoutDeliveryTimeComponent,
        CheckoutPaymentComponent,
        CheckoutWishListComponent,
        CheckoutSummaryComponent,
        CheckoutPreferencesComponent,
        AddOrUpdateOrderItemDialogComponent,
        AddItemToOrderDialogComponent,
        ConfirmCancellationDialogComponent,
        OrderContactDetailsDialogComponent,
        OrderCommentDialogComponent,
        OrderTimeslotsDialogComponent,
        DeliveryAddressDialogComponent,
        OrderComponent,
        OrderCardComponent,
        PaymentComponent,
        AddressDialogComponent,
        CommercialBlockComponent,
        UserMenuComponent,
        ArchieDesktopComponent,
    ],
    entryComponents: [
        LogInDialogComponent,
        ProductDetailsDialogComponent,
        ProviderTimeSlotsDialogComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        TranslateModule.forRoot({
            defaultLanguage: ServiceBaseService.getLanguageCode(),
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [HttpClient, ServiceBaseService],
            },
        }),
        HttpClientModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        CommonModule,
        BsDropdownModule.forRoot(),
        AccordionModule.forRoot(), // You could also split this up if you don't want the Entire Module imported
        BrowserAnimationsModule,
        MatDialogModule,
        MatButtonModule,
        MatPaginatorModule,
        BrowserAnimationsModule,
        MatSnackBarModule,
        MatCardModule,
        MatRadioModule,
        MatProgressBarModule,
        NgxIntlTelInputModule,
        TypeaheadModule.forRoot(),
        MatProgressSpinnerModule,
        SharedModule,
        MatIconModule,
        AppIconModule,
        ThemePipeModule,
        ProviderLandingCarouselModule,
        ProductNutritionModule,
        Sentry.TraceModule,
        PageNotFoundModule,
        SocialLoginModule,
        GoogleSigninButtonModule,
    ],
    providers: [
        { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '200ms' } },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(environment.googleClientId, {
                            oneTapEnabled: false,
                            prompt: 'none',
                            scopes: [
                                'https://www.googleapis.com/auth/userinfo.email',
                                'https://www.googleapis.com/auth/userinfo.profile',
                            ],
                        }),
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(environment.facebookClientId),
                    },
                ],
                onError: (err) => {
                    console.error(err);
                },
            } as SocialAuthServiceConfig,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true,
        },
        {
            provide: ORDER_SERVICE_IMPL,
            useClass: OrderService,
        },
        {
            provide: SNACKBAR_SERVICE_IMPL,
            useClass: SnackBarService,
        },
        {
            provide: SESSION_SERVICE_IMPL,
            useClass: SessionService,
        },
        {
            provide: REALTIME_CLIENT_IMPL,
            useClass: RealtimeClientService,
        },
        {
            provide: TIMESLOT_SERVICE_IMPL,
            useClass: TimeSlotService,
        },
        {
            provide: LOCATION_SERVICE_IMPL,
            useClass: LocationService,
        },
        {
            provide: DATALAYER_SERVICE_IMPL,
            useClass: DataLayerGa4Service,
        },
        {
            provide: HOME_SERVICE_IMPL,
            useClass: HomeService,
        },
        {
            provide: RESTRICTION_CHECKER_SERVICE_IMPL,
            useClass: RestrictionCheckerService,
        },
        {
            provide: MyMonitoringService,
        },
        {
            provide: SEO_SERVICE_IMPL,
            useClass: SeoService,
        },
        { provide: API_BASE_URL, useValue: environment.API_BASE_URL },
        { provide: MAT_DATE_LOCALE, useValue: ServiceBaseService.getDateCode() },
        { provide: DecimalPipe },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function translateLoaderFactory(http: HttpClient): MultiTranslateHttpLoader {
    return new MultiTranslateHttpLoader(http, [
        {
            prefix:
                ServiceBaseService.getTranslationBaseUrl() + 'api/translations/get/0/',
            suffix: '',
        },
    ]);
}
