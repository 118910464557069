import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProductNutritionElement } from '../../models/product/product-nutrition/product-nutrition-element.model';
import { ProductNutritionHeader } from '../../models/product/product-nutrition/product-nutrition-header.model';
import { ProductNutritionShared } from '../../models/product/product-nutrition/product-nutrition.model';

@Component({
    selector: 'lib-product-nutrition',
    templateUrl: './product-nutrition.component.html',
    styleUrls: ['./product-nutrition.component.scss'],
})
export class ProductNutritionComponent implements OnInit {
    @Input() textNutrition: string;

    productNutritionText = '';
    isProductNutritionXml = true;
    productNutrition: ProductNutritionShared;
    headerLength: number;

    constructor(public translate: TranslateService) {}

    ngOnInit(): void {
        this.productNutrition = this.formatNutrition(this.textNutrition);
        if (this.productNutrition) {
            this.headerLength = this.calculateHeaderLength(this.productNutrition.header);
        }
    }

    formatNutrition(nutritionXml: string) {
        if (nutritionXml) {
            if (Array.from(nutritionXml.trim())[0] != '<') {
                this.isProductNutritionXml = false;
                this.productNutritionText = nutritionXml;
                return null;
            }

            const newProductNutrition = new ProductNutritionShared();
            this.translate
                .get('product-details.nutritions')
                .subscribe((text) => (newProductNutrition.header.nameValue = text));

            const parser = new DOMParser();
            const xmlDoc = <XMLDocument>parser.parseFromString(nutritionXml, 'text/xml');

            const children = xmlDoc.documentElement.childNodes;
            for (let i = 0; i < children.length; i++) {
                const child = children[i];
                if (child.nodeType === Node.ELEMENT_NODE) {
                    if (child.nodeName === 'Nutrient') {
                        newProductNutrition.nutritionArray.push(
                            this.createProductNutritionElementFromXml(child.childNodes)
                        );
                    } else if (child.nodeName === 'Headings') {
                        const headerValues = [];
                        for (let j = 0; j < child.childNodes.length; j++) {
                            const headerElement = child.childNodes[j];
                            if (headerElement.nodeType === Node.ELEMENT_NODE) {
                                headerValues.push(headerElement.textContent);
                            }
                        }
                        newProductNutrition.header.firstValue = headerValues[0];
                        newProductNutrition.header.secondValue = headerValues[1];
                        newProductNutrition.header.thirdValue = headerValues[2];
                    }
                }
            }

            return newProductNutrition;
        } else {
            return null;
        }
    }

    createProductNutritionElementFromXml(
        nutrientNode: NodeListOf<ChildNode>
    ): ProductNutritionElement {
        const values = [];
        const productNutritionElement = new ProductNutritionElement();

        for (let i = 0; i < nutrientNode.length; i++) {
            const child = nutrientNode[i];
            if (child.nodeType === Node.ELEMENT_NODE) {
                if (child.nodeName.toLowerCase() === 'name') {
                    productNutritionElement.name = child.textContent;
                } else if (child.nodeName.toLowerCase() === 'values') {
                    for (let j = 0; j < child.childNodes.length; j++) {
                        const nutrientValue = child.childNodes[j];
                        if (
                            nutrientValue.nodeType === Node.ELEMENT_NODE &&
                            nutrientValue.nodeName.toLowerCase() === 'value'
                        ) {
                            values.push(nutrientValue.textContent);
                        }
                    }
                }
            }
        }

        productNutritionElement.firstValue = values[0];
        productNutritionElement.secondValue = values[1];
        productNutritionElement.thirdValue = values[2];

        return productNutritionElement;
    }

    calculateHeaderLength(header: ProductNutritionHeader): number {
        if (header.thirdValue) {
            return 4;
        }
        if (header.secondValue) {
            return 3;
        }
        if (header.firstValue) {
            return 2;
        }
        return 1;
    }

    calculateColspanForNutrient(nutritionElement: ProductNutritionElement) {
        return !nutritionElement.firstValue &&
            !nutritionElement.secondValue &&
            !nutritionElement.thirdValue
            ? this.headerLength
            : 1;
    }
}
