import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import RevolutCheckout from '@revolut/checkout';
import { Location } from '@angular/common';
import { BillingAddressType } from '../../types/billing-address-type';
import { OrderStatus } from '../../types/order-status';
import { CheckoutOrderDto } from '../../models/order/checkoutOrderDto';
import { UserDtoShared } from '../../models/user/userDto';
import { AddressShared } from '../../models/address/address';
import {
    AvailableTimeSlotsDTO,
    DayOfWeek,
    InstantTimeslotDTO,
    InstantWorktimeQueryDTO,
    PreferenceSettingDTO,
    ProviderClient,
    TimeSlotDayDTO,
    TimeSlotRangeItemDTO,
    WishListClient,
    WishListProviderShopDTO,
} from '../../index';
import { Payment } from '../../models/payment/payment';
import { SelectedOrderPreferenceDTO } from '../../models/DTO/selected-order-preference-dto';
import { GeoLocation } from '../../models/geolocation/geolocation';
import { CountryCodes } from '../../types/country-codes';
import { RedirectProductModel } from '../../models/data-layer/redirect-product.model';
import { IOrderService, ORDER_SERVICE_IMPL } from '../order/i-order.service';
import { ISnackBarService, SNACKBAR_SERVICE_IMPL } from '../snackbar/i-snackbar.service';
import { SessionService } from '../session/session.service';
import { ITimeSlotService, TIMESLOT_SERVICE_IMPL } from '../timeslot/i-time-slot.service';
import { ILocationService, LOCATION_SERVICE_IMPL } from '../location/i-location.service';
import {
    DATALAYER_SERVICE_IMPL,
    IDataLayerService,
} from '../data-layer/i-data-layer.service';
import { BasketService } from '../basket.service';
import { ServiceBaseService } from '../service-base.service';
import { IProviderSettings } from '../../models/provider/i-provider-settings';
import { ProviderSettingsRequestDTOShared } from '../../models/DTO/providersettings-request-dto';
import { CheckoutStep } from '../../models/checkout-step/checkout-step';
import { CouponStatus } from '../../types/coupon-status';
import { IDiscount } from '../../models/DTO/i-discount';
import { PaymentProvidersDto } from '../dto/payment-providers.dto';
import { TimeSlotService } from '../timeslot/time-slot.service';
import { Locale } from '@revolut/checkout/types/types';
import { environment } from '../../environments/environment';
import { PostMessageService } from '../post-message/post-message.service';
import { DataLayerGa4Service, GA4EventType } from '../data-layer/data-layer-ga4.service';
import { FormatterService } from '../formatter.service';

@Injectable({
    providedIn: 'root',
})
export class CheckoutService {
    private _orderStatusSource: BehaviorSubject<OrderStatus>;
    public readonly orderStatus$: Observable<OrderStatus>;

    private _formGroupSource: BehaviorSubject<FormGroup>;
    public readonly formGroup$: Observable<FormGroup>;

    private _selectedStepSource: BehaviorSubject<number>;
    public readonly selectedStep$: Observable<number>;

    private _orderDtoSource: BehaviorSubject<CheckoutOrderDto>;
    public readonly order$: Observable<CheckoutOrderDto>;

    private _userDtoSource: BehaviorSubject<UserDtoShared>;
    public readonly user$: Observable<UserDtoShared>;

    private _addressSource: BehaviorSubject<AddressShared>;
    public readonly address$: Observable<AddressShared>;

    private _zipIsValidSource: BehaviorSubject<boolean>;
    public readonly zipIsValid$: Observable<boolean>;

    private _instantDeliverySelectedSource: BehaviorSubject<boolean>;
    public readonly instantDeliverySelected$: Observable<boolean>;

    private _instantDeliveryApprovedSource: BehaviorSubject<boolean>;
    public readonly instantDeliveryApproved$: Observable<boolean>;

    private _areaHasInstantProviderSource: BehaviorSubject<boolean>;
    public readonly areaHasInstantProvider$: Observable<boolean>;

    private _customerCreditCardAuthSource: BehaviorSubject<boolean>;
    public readonly customerCreditCardAuth$: Observable<boolean>;

    private _availableTimeSlotsSource: BehaviorSubject<AvailableTimeSlotsDTO>;
    public readonly availableTimeSlots$: Observable<AvailableTimeSlotsDTO>;

    private _selectedTimeSlotSource: BehaviorSubject<TimeSlotDayDTO>;
    public readonly selectedTimeSlot$: Observable<TimeSlotDayDTO>;

    private _selectedTimeSlotRangeItemSource: BehaviorSubject<TimeSlotRangeItemDTO>;
    public readonly selectedTimeSlotRangeItem$: Observable<TimeSlotRangeItemDTO>;

    private _paymentDataSource: BehaviorSubject<Payment>;
    public readonly paymentData$: Observable<Payment>;

    private _isSubmittedSource: BehaviorSubject<boolean>;
    public readonly isSubmitted$: Observable<boolean>;

    private _orderPreferencesSource: BehaviorSubject<PreferenceSettingDTO[]>;
    public readonly orderPreferences$: Observable<PreferenceSettingDTO[]>;

    private _wishListProviderShopsSource: BehaviorSubject<WishListProviderShopDTO[]>;
    public readonly wishListProviderShops$: Observable<WishListProviderShopDTO[]>;

    private _isWishListAvailableSource: BehaviorSubject<boolean>;
    public readonly isWishListAvailable$: Observable<boolean>;

    private _defaultPaymentProvidersSource: BehaviorSubject<string[]>;
    public readonly defaultPaymentProviders$: Observable<string[]>;

    private _instantWorktimeSource: BehaviorSubject<InstantTimeslotDTO[]>;
    public readonly instantWorktime$: Observable<InstantTimeslotDTO[]>;

    private _configuredInstantWorktimeSource: BehaviorSubject<InstantTimeslotDTO[]>;
    public readonly configuredInstantWorktime$: Observable<InstantTimeslotDTO[]>;

    private _selectedInstantDeliveryIntervalSource: BehaviorSubject<number>;
    public readonly selectedInstantDeliveryIntervalSource$: Observable<number>;

    private _selectedOrderPreferenceSource: BehaviorSubject<SelectedOrderPreferenceDTO[]>;
    public readonly selectedOrderPreferenceSource$: Observable<
        SelectedOrderPreferenceDTO[]
    >;

    private _creditCardAuthTotalAmountSource: BehaviorSubject<string>;
    public readonly creditCardAuthTotalAmountSource$: Observable<string>;

    public _billingAddressTypeSubject: BehaviorSubject<string>;

    _completedSummarySource: BehaviorSubject<boolean>;
    public readonly completedSummarySource$: Observable<boolean>;

    defaultLocation: GeoLocation = new GeoLocation(19.040236, 47.497913);
    currentCountryCode: CountryCodes;
    countryCodes = CountryCodes;
    redirectProductModel: RedirectProductModel = new RedirectProductModel();
    currentProviderCode: string;
    providerID: number;

    private _isCheckoutInProgress: BehaviorSubject<boolean>;
    public readonly isCheckoutInProgress$: Observable<boolean>;

    constructor(
        @Inject(ORDER_SERVICE_IMPL) private _orderService: IOrderService,
        @Inject(SNACKBAR_SERVICE_IMPL) private _snackBarService: ISnackBarService,
        private _sessionService: SessionService,
        @Inject(TIMESLOT_SERVICE_IMPL) private _timeSlotService: ITimeSlotService,
        @Inject(LOCATION_SERVICE_IMPL) private _locationService: ILocationService,
        @Inject(DATALAYER_SERVICE_IMPL) private _dataLayerService: IDataLayerService,
        private _basketService: BasketService,
        private router: Router,
        private translate: TranslateService,
        private location: Location,
        public baseService: ServiceBaseService,
        private providerClient: ProviderClient,
        private wishListClient: WishListClient,
        private formBuilder: FormBuilder,
        private timeSlotService: TimeSlotService,
        private postMessageService: PostMessageService,
        private formatterService: FormatterService,
        @Inject(DATALAYER_SERVICE_IMPL) private dataLayerGa4Service: DataLayerGa4Service
    ) {
        this._orderStatusSource = new BehaviorSubject(OrderStatus.STARTCHECKOUTPROGRESS);
        this.orderStatus$ = this._orderStatusSource.asObservable();
        this._formGroupSource = new BehaviorSubject(null);
        this.formGroup$ = this._formGroupSource.asObservable();
        this._selectedStepSource = new BehaviorSubject(0);
        this.selectedStep$ = this._selectedStepSource.asObservable();
        this._orderDtoSource = new BehaviorSubject(null);
        this.order$ = this._orderDtoSource.asObservable();
        this._userDtoSource = new BehaviorSubject(null);
        this.user$ = this._userDtoSource.asObservable();
        this._addressSource = new BehaviorSubject(null);
        this.address$ = this._addressSource.asObservable();
        this._zipIsValidSource = new BehaviorSubject(false);
        this.zipIsValid$ = this._zipIsValidSource.asObservable();
        this._instantDeliverySelectedSource = new BehaviorSubject(false);
        this.instantDeliverySelected$ =
            this._instantDeliverySelectedSource.asObservable();
        this._instantDeliveryApprovedSource = new BehaviorSubject(false);
        this.instantDeliveryApproved$ =
            this._instantDeliveryApprovedSource.asObservable();
        this._areaHasInstantProviderSource = new BehaviorSubject(false);
        this.areaHasInstantProvider$ = this._areaHasInstantProviderSource.asObservable();
        this._customerCreditCardAuthSource = new BehaviorSubject(false);
        this.customerCreditCardAuth$ = this._customerCreditCardAuthSource.asObservable();
        this._availableTimeSlotsSource = new BehaviorSubject(null);
        this.availableTimeSlots$ = this._availableTimeSlotsSource.asObservable();
        this._selectedTimeSlotSource = new BehaviorSubject(null);
        this.selectedTimeSlot$ = this._selectedTimeSlotSource.asObservable();
        this._selectedTimeSlotRangeItemSource = new BehaviorSubject(null);
        this.selectedTimeSlotRangeItem$ =
            this._selectedTimeSlotRangeItemSource.asObservable();
        this._isSubmittedSource = new BehaviorSubject(false);
        this.isSubmitted$ = this._isSubmittedSource.asObservable();
        this._paymentDataSource = new BehaviorSubject(new Payment());
        this.paymentData$ = this._paymentDataSource.asObservable();
        this.currentCountryCode = ServiceBaseService.getCountryCode();
        this._orderPreferencesSource = new BehaviorSubject(null);
        this.orderPreferences$ = this._orderPreferencesSource.asObservable();
        this._wishListProviderShopsSource = new BehaviorSubject(null);
        this.wishListProviderShops$ = this._wishListProviderShopsSource.asObservable();
        this._isWishListAvailableSource = new BehaviorSubject(false);
        this.isWishListAvailable$ = this._isWishListAvailableSource.asObservable();
        this._defaultPaymentProvidersSource = new BehaviorSubject(null);
        this.defaultPaymentProviders$ =
            this._defaultPaymentProvidersSource.asObservable();
        this._instantWorktimeSource = new BehaviorSubject(null);
        this.instantWorktime$ = this._instantWorktimeSource.asObservable();
        this._configuredInstantWorktimeSource = new BehaviorSubject(null);
        this.configuredInstantWorktime$ =
            this._configuredInstantWorktimeSource.asObservable();
        this._selectedInstantDeliveryIntervalSource = new BehaviorSubject(null);
        this.selectedInstantDeliveryIntervalSource$ =
            this._selectedInstantDeliveryIntervalSource.asObservable();
        this._selectedOrderPreferenceSource = new BehaviorSubject(null);
        this.selectedOrderPreferenceSource$ =
            this._selectedOrderPreferenceSource.asObservable();
        this._creditCardAuthTotalAmountSource = new BehaviorSubject(null);
        this.creditCardAuthTotalAmountSource$ =
            this._creditCardAuthTotalAmountSource.asObservable();
        this._billingAddressTypeSubject = new BehaviorSubject(
            BillingAddressType.SameBillingAddress
        );

        this._completedSummarySource = new BehaviorSubject(false);
        this.completedSummarySource$ = this._completedSummarySource.asObservable();

        this._isCheckoutInProgress = new BehaviorSubject(false);
        this.isCheckoutInProgress$ = this._isCheckoutInProgress.asObservable();
    }

    setSelectedStepSource(index: number): void {
        this._selectedStepSource.next(index);
    }

    setOrderStatusSource(status: OrderStatus): void {
        this._orderStatusSource.next(status);
    }

    setFormGroupSource(formGroup: FormGroup): void {
        this._formGroupSource.next(formGroup);
    }

    setUserDataSource(userDto: UserDtoShared): void {
        this._userDtoSource.next(userDto);
    }

    setOrderSource(order: CheckoutOrderDto): void {
        this._orderDtoSource.next(order);
    }

    setAddressSource(address: AddressShared): void {
        this._addressSource.next(address);
    }

    setZipIsValidSource(isValid: boolean): void {
        this._zipIsValidSource.next(isValid);
    }

    setAvailableTimeSlotsDtoSource(availableTimeSlotsDto: AvailableTimeSlotsDTO): void {
        this._availableTimeSlotsSource.next(availableTimeSlotsDto);
    }

    setSelectedTimeSlotSource(timeSlotDto: TimeSlotDayDTO): void {
        this._selectedTimeSlotSource.next(timeSlotDto);
    }

    setSelectedTimeSlotRangeItemSource(timeSlotRangeItem: TimeSlotRangeItemDTO): void {
        this._selectedTimeSlotRangeItemSource.next(timeSlotRangeItem);
    }

    setInstantDeliverySelectedSource(selected: boolean): void {
        this._instantDeliverySelectedSource.next(selected);
    }

    setInstantDeliveryApprovedSource(approved: boolean): void {
        this._instantDeliveryApprovedSource.next(approved);
    }

    setAreaHasInstantProviderSource(exists: boolean): void {
        this._areaHasInstantProviderSource.next(exists);
    }

    setCustomerCreditCardAuthSource(available: boolean): void {
        this._customerCreditCardAuthSource.next(available);
    }

    setIsSubmittedSource(isSubmitted: boolean): void {
        this._isSubmittedSource.next(isSubmitted);
    }

    setPaymentDataSource(paymentData: Payment): void {
        this._paymentDataSource.next(paymentData);
    }

    setOrderPreferencesSource(settings: PreferenceSettingDTO[]): void {
        this._orderPreferencesSource.next(settings);
    }

    setWishListProviderShopsSource(
        wishListProviderShops: WishListProviderShopDTO[]
    ): void {
        this._wishListProviderShopsSource.next(wishListProviderShops);
    }

    setSelectedOrderPreferenceSource(prefSetting: SelectedOrderPreferenceDTO[]): void {
        this._selectedOrderPreferenceSource.next(prefSetting);
    }

    setDefaultPaymentProvidersSource(settings: string[]): void {
        this._defaultPaymentProvidersSource.next(settings);
    }

    setInstantWorktimeSource(worktimes: InstantTimeslotDTO[]): void {
        this._instantWorktimeSource.next(worktimes);
    }

    setConfiguredInstantWorktimeSource(worktimes: InstantTimeslotDTO[]): void {
        this._configuredInstantWorktimeSource.next(worktimes);
    }

    setInstantDeliverySelectedIntervalSource(interval: number): void {
        this._selectedInstantDeliveryIntervalSource.next(interval);
    }

    setCreditCardAuthTotalAmountSource(amount: string): void {
        this._creditCardAuthTotalAmountSource.next(amount);
    }

    setBillingAddressTypeSubject(billingAddress: string): void {
        this._billingAddressTypeSubject.next(billingAddress);
    }

    setCheckoutInProgress(checkoutInProgress: boolean): void {
        this._isCheckoutInProgress.next(checkoutInProgress);
    }

    setCompletedSummary(completed: boolean): void {
        this._completedSummarySource.next(completed);
    }

    getCheckoutDto(): void {
        this._orderService
            .getCheckoutDto(
                window.location.href
                    .substring(window.location.href.lastIndexOf('/') + 1)
                    .split('?')[0]
                    .toLowerCase()
            )
            .subscribe((data) => {
                if (!data.isUserLoggedIn) {
                    this.translate
                        .get('checkout.log-in-to-checkout')
                        .subscribe((text) => this._snackBarService.openSnackBar(text));

                    this.router.navigate(['/']);
                } else {
                    this.dataLayerGa4Service.eCommerceBasket(
                        data.BasketItemList,
                        GA4EventType.begin_checkout
                    );

                    this.setOrderStatusSource(OrderStatus.FORM);

                    if (this.currentCountryCode == this.countryCodes.hu)
                        data.totalPrice = Math.ceil(data.TotalPrice);

                    this.setDefaultPaymentProvidersSource(data.paymentProviders);
                    this.setCustomerCreditCardAuthSource(data.CustomerCreditCardAuth);

                    this._sessionService.getCityBySession().subscribe((session) => {
                        this.currentProviderCode = data.providerCode;
                        this.providerID = data.providerID;
                        if (session.zipCode && session.address) {
                            this.resetAddress(
                                session.zipCode,
                                session.city,
                                session.streetName,
                                session.houseNumber
                            );
                            this.queryDeliveryTimesAndPaymentMethods(session.zipCode);

                            const date = new Date().getDay();

                            this.wishListClient
                                .getAllowedWishListProviderShops(
                                    data.providerID,
                                    session.zipCode,
                                    date,
                                    null,
                                    null
                                )
                                .subscribe((wishListProviderShops) => {
                                    if (wishListProviderShops) {
                                        this._isWishListAvailableSource.next(
                                            wishListProviderShops.isWishListAllowed
                                        );

                                        this._wishListProviderShopsSource.next(
                                            wishListProviderShops.wishListProviderShopDTOList
                                        );
                                    } else {
                                        this._isWishListAvailableSource.next(false);
                                        this._wishListProviderShopsSource.next([]);
                                    }
                                });
                        }
                    });

                    this.setUserDataSource(data.UserDto);
                    this.setOrderSource(data);

                    this.providerClient
                        .getProviderPreferenceSettings(data.providerID)
                        .subscribe((settings) => {
                            if (settings) {
                                this._orderPreferencesSource.next(settings);
                            }
                        });

                    this.addProductsToDataLayer(data);
                }
            });
    }

    getProviderSettings(
        request: ProviderSettingsRequestDTOShared
    ): Observable<IProviderSettings[]> {
        return this._orderService.getProviderSettings(request);
    }

    getUserMarketingPreference(userID: number, providerID: number): Observable<boolean> {
        return this._orderService.getUserMarketingPreference(userID, providerID);
    }

    getAllowedWishListProviderShops(
        zipCode: string,
        day: DayOfWeek,
        startHour: number,
        endHour: number
    ) {
        this.wishListClient
            .getAllowedWishListProviderShops(
                this.providerID,
                zipCode,
                day,
                startHour,
                endHour
            )
            .subscribe((wishListProviderShops) => {
                if (wishListProviderShops) {
                    this._isWishListAvailableSource.next(
                        wishListProviderShops.isWishListAllowed
                    );
                    this._wishListProviderShopsSource.next(
                        wishListProviderShops.wishListProviderShopDTOList
                    );
                }
            });
    }

    resetAddress(
        zip: string,
        city: string,
        streetName: string,
        houseNumber: string
    ): void {
        this._zipIsValidSource.next(false);
        this.setAddressSource({
            postCode: zip,
            city: city,
            streetName: streetName,
            houseNumber: houseNumber,
        });
    }

    getPaymentProvidersByPostCode(postCode: string): Observable<PaymentProvidersDto> {
        const order = this._orderDtoSource.getValue();
        return this._orderService.getPaymentMethods(order.providerID, postCode);
    }

    queryDeliveryTimesAndPaymentMethods(zip: string): void {
        this.setSelectedStepSource(CheckoutStep.Address);
        this.resetAllDeliverySelection();
        const order = this._orderDtoSource.getValue();
        const checkoutForm = this._formGroupSource.getValue();
        const longitude = checkoutForm.get('paymentForm.Longitude').value;
        const latitude = checkoutForm.get('paymentForm.Latitude').value;

        if (zip && order?.deliveryAvailablePostcodes.indexOf(zip) > -1) {
            this.getTimeSlot(
                order.BasketItemList.length,
                order.providerID,
                zip,
                latitude,
                longitude
            );
        } else {
            this.translate
                .get('messages.we-dont-deliver-for-this-zip-from-this-shop')
                .subscribe((text) => this._snackBarService.openSnackBar(text));
        }
    }

    getTimeSlot(
        basketItemCount: number,
        providerID: number,
        zipCode: string,
        latitude?: number,
        longitude?: number
    ): void {
        this._timeSlotService
            .getConfiguredExpressWorktimesByProvider(providerID, zipCode)
            .pipe(
                tap((confWorktimes) => {
                    this.setConfiguredInstantWorktimeSource(confWorktimes);
                }),

                switchMap(() => {
                    const intervals = this._configuredInstantWorktimeSource
                        .getValue()
                        .map((w) => w.interval);
                    const instantWorkTimeWorkQueryDTO = new InstantWorktimeQueryDTO({
                        providerID: providerID,
                        zipCode: zipCode,
                        itemCount: basketItemCount,
                        intervals: intervals,
                        orderID: -1, // itt még ne mtudjuk a kosár tartalmát
                        deliveryAddressLat: latitude,
                        deliveryAddressLong: longitude,
                    });

                    return forkJoin({
                        availableTimeSlotsDto:
                            this._timeSlotService.getAvailableTimeSlotList(
                                providerID,
                                zipCode
                            ),
                        instantSupplierWorktime:
                            this._timeSlotService.getRealTimeInstantTimeSlotsByBoundary(
                                instantWorkTimeWorkQueryDTO
                            ),
                        instantProvider:
                            this._timeSlotService.getInstantDeliveryAreaProvider(
                                providerID,
                                zipCode
                            ),
                        paymentMethods: this._orderService.getPaymentMethods(
                            providerID,
                            zipCode
                        ),
                    });
                })
            )
            .subscribe((results) => {
                this.setZipIsValidSource(true);
                this.setAvailableTimeSlotsDtoSource(results.availableTimeSlotsDto);
                if (results.instantSupplierWorktime) {
                    this.setInstantWorktimeSource(results.instantSupplierWorktime);
                    this.setInstantDeliveryApproval();
                } else {
                    this.setInstantDeliveryApprovedSource(false);
                    this.setInstantDeliverySelectedSource(false);
                }
                if (results.instantProvider) {
                    this.setAreaHasInstantProviderSource(true);
                }
                if (results.paymentMethods) {
                    this.setCustomerCreditCardAuthSource(
                        results.paymentMethods.CustomerCreditCardAuth
                    );
                    this.setDefaultPaymentProvidersSource(
                        results.paymentMethods.PaymentProviders
                    );
                }
            });
    }

    resetAllDeliverySelection(): void {
        this.setAvailableTimeSlotsDtoSource(null);
        const checkoutForm = this._formGroupSource.getValue();
        checkoutForm.get('deliveryTimeForm.DeliveryDays').setValue(null);
        checkoutForm.get('deliveryTimeForm.DeliveryTimeSlots').setValue(null);
        this.setFormGroupSource(checkoutForm);
        this.setSelectedTimeSlotSource(null);
        this.setInstantDeliveryApprovedSource(false);
        this.setInstantDeliverySelectedSource(false);
        this.setAreaHasInstantProviderSource(false);
        this.timeSlotService.activeEarliestTimeSlotId.next(null);
        this.timeSlotService.activeTimeSlotId.next(null);
    }

    getOptimalInstantSupplierWorktime(): void {
        const order = this._orderDtoSource.getValue();
        const postCode = this._addressSource.getValue().postCode;
        const instantIntervalHours =
            this._selectedInstantDeliveryIntervalSource.getValue();
        if (order && postCode && instantIntervalHours) {
            this._timeSlotService
                .getRealTimeInstantSuppliersByBoundary(
                    order.providerID,
                    postCode,
                    order.BasketItemList.length,
                    instantIntervalHours
                )
                .subscribe((res) => {
                    if (res) {
                        this.setInstantDeliveryApproval();
                    } else {
                        this._instantDeliverySelectedSource.next(false);
                        this._instantDeliveryApprovedSource.next(false);
                    }
                });
        }
    }

    getCustomerCreditCardAuth() {
        const address = this._addressSource.getValue();
        const order = this._orderDtoSource.getValue();
        const checkoutForm = this._formGroupSource.getValue();
        const longitude = checkoutForm.get('paymentForm.Longitude').value;
        const latitude = checkoutForm.get('paymentForm.Latitude').value;
        const paymentType = checkoutForm.get('paymentForm.PaymentType').value?.trim();

        this._orderService
            .getCustomerCreditCardAuth(
                address.postCode,
                order.providerID,
                latitude,
                longitude,
                paymentType
            )
            .subscribe((amount) => {
                this.setCreditCardAuthTotalAmountSource(amount);
            });
    }

    getRealTimeInstantTimeSlotsByBoundary(interval: number) {
        const order = this._orderDtoSource.getValue();
        const postCode = this._addressSource.getValue().postCode;
        const checkoutForm = this._formGroupSource.getValue();
        const longitude = checkoutForm.get('paymentForm.Longitude').value;
        const latitude = checkoutForm.get('paymentForm.Latitude').value;
        const instantWorkTimeWorkQueryDTO = new InstantWorktimeQueryDTO({
            providerID: order.providerID,
            zipCode: postCode,
            itemCount: order.BasketItemList.length,
            intervals: [interval],
            orderID: -1,
            deliveryAddressLat: latitude,
            deliveryAddressLong: longitude,
        });

        if (order && postCode) {
            this._timeSlotService
                .getRealTimeInstantTimeSlotsByBoundary(instantWorkTimeWorkQueryDTO)
                .subscribe((res) => {
                    if (res) {
                        this.setInstantDeliveryApproval();
                    } else {
                        this._instantDeliverySelectedSource.next(false);
                        this._instantDeliveryApprovedSource.next(false);
                    }
                });
        }
    }

    setInstantDeliveryApproval(): void {
        const basketWeight = this._basketService.getCurrentTotalWeigth();
        if (basketWeight <= this._basketService.getInstantWeightLimit()) {
            this._instantDeliveryApprovedSource.next(true);
        } else {
            this._instantDeliveryApprovedSource.next(false);

            const basketWeightFormatted = this.formatterService.weightFormat(
                basketWeight / 1000
            );
            this.translate.get('messages.your-basket-now').subscribe((text) => {
                this._snackBarService.openSnackBar(
                    text +
                        basketWeightFormatted +
                        ' kg. ' +
                        this._basketService.getInstantDelWeightLimitMsg()
                );
            });
        }
    }

    calculateDeliveryFee(
        instantDelivery: boolean,
        timeSlotFee = 0,
        additionalFee = 0,
        discountPercentage = 0
    ): void {
        const paymentData = this._paymentDataSource.getValue();
        const originalDeliveryFee = timeSlotFee;

        timeSlotFee = (1 - discountPercentage / 100) * timeSlotFee;

        if (paymentData.couponStatus == CouponStatus.FREEDELIVERY) {
            paymentData.deliveryDiscountAmount = originalDeliveryFee;
        }
        if (instantDelivery) {
            paymentData.instantDeliveryFee = timeSlotFee;
            paymentData.deliveryFee = paymentData.instantDeliveryFee;
            paymentData.totalDeliveryFee = originalDeliveryFee;
        } else {
            paymentData.totalDeliveryFee = originalDeliveryFee;
            paymentData.additionalDeliveryFee = additionalFee;
            paymentData.deliveryFee = timeSlotFee - paymentData.additionalDeliveryFee;
        }
        this._paymentDataSource.next(paymentData);
    }

    validateCoupon(
        couponCode: string,
        differentBillingAddressSelector: string
    ): Observable<IDiscount> {
        return this._orderService
            .validateCoupon(couponCode, differentBillingAddressSelector, this.providerID)
            .pipe(
                map((resp: any) => {
                    if (resp) {
                        return <IDiscount>{
                            DiscountName: resp.DiscountName,
                            DiscountTypeID: resp.DiscountTypeID,
                            DiscountAmount: resp.DiscountAmount,
                            DiscountPercent: resp.DiscountPercent,
                            DiscountDisplayName: resp.DiscountDisplayName,
                            MinimumBasketValue: resp.MinimumBasketValue,
                        };
                    }
                })
            );
    }

    validateAddress(
        postCode: string,
        city: string,
        streetName: string,
        houseNumber: string
    ): Observable<boolean> {
        return this._locationService
            .validateAddress(postCode, city, streetName, houseNumber)
            .pipe(
                map((validatedAddress) => {
                    this.setLatLong(validatedAddress.location);
                    return validatedAddress.isValid;
                })
            );
    }

    setLatLong(location = this.defaultLocation): void {
        const checkoutForm = this._formGroupSource.getValue();
        checkoutForm.get('paymentForm.Longitude').setValue(location.longitude);
        checkoutForm.get('paymentForm.Latitude').setValue(location.latitude);
        this.setFormGroupSource(checkoutForm);
    }

    validateOrderPreferences(showMessage?: boolean): boolean {
        const preferences = this._orderPreferencesSource.getValue();
        if (!preferences) {
            return false;
        }

        const selectedPreferences = this._selectedOrderPreferenceSource.getValue();
        const result = [];
        preferences.map((items) => {
            if (
                !selectedPreferences.find(
                    (setting) => setting.settingText == items.settingText
                )
            ) {
                result.push(false);
            } else {
                result.push(true);
            }
        });

        if (result.some((item) => !item)) {
            if (showMessage) {
                this.translate
                    .get('checkout.select-information-required')
                    .subscribe((text) => {
                        this._snackBarService.openErrorSnackBar(text);
                    });
                this._selectedStepSource.next(CheckoutStep.Preferences);
            }

            if (!this._sessionService.isMobile) {
                document.getElementById('preferencesPanel').scrollIntoView();
            }

            this.setCheckoutInProgress(false);
        }

        return !result.some((item) => !item);
    }

    checkout(isMobile = false, router = null, modal: Promise<any> = null): void {
        const checkoutForm = this._formGroupSource.getValue();
        const longitude = checkoutForm.get('paymentForm.Longitude').value;
        const latitude = checkoutForm.get('paymentForm.Latitude').value;
        const interval = this._selectedInstantDeliveryIntervalSource.getValue();
        /****Check if instant delivery is still available if selected****/
        if (checkoutForm.valid) {
            const order = this._orderDtoSource.getValue();
            const instantWorkTimeWorkQueryDTO = new InstantWorktimeQueryDTO({
                providerID: order.providerID,
                zipCode: checkoutForm.get('addressForm.PostCode').value,
                itemCount: order.BasketItemList.length,
                intervals: [interval],
                orderID: -1,
                deliveryAddressLat: latitude,
                deliveryAddressLong: longitude,
            });
            if (this.validateOrderPreferences()) {
                if (this._instantDeliverySelectedSource.getValue()) {
                    this._timeSlotService
                        .getRealTimeInstantTimeSlotsByBoundary(
                            instantWorkTimeWorkQueryDTO
                        )
                        .subscribe((res) => {
                            if (!res) {
                                this.setInstantDeliveryApprovedSource(false);
                                this.setInstantDeliverySelectedSource(false);
                                this.loadTimeslotListAfterFailedCheckout(true);
                                this.setInstantDeliverySelectedIntervalSource(null);
                            } else {
                                this.continueCheckout();
                            }
                        });
                } else {
                    /****We check that if the selected timeslot still available*****/
                    const selectedtimeSlotDayDTO =
                        this._selectedTimeSlotSource.getValue();
                    const selectedtimeSlotRangeItemDTO =
                        this._selectedTimeSlotRangeItemSource.getValue();
                    this._timeSlotService
                        .getAvailableTimeSlotList(
                            order.providerID,
                            checkoutForm.get('addressForm.PostCode').value
                        )
                        .subscribe((availableTimeSlotsDto) => {
                            let timeSlotRangeItemExists = false;

                            if (availableTimeSlotsDto?.timeSlotRangeList !== null) {
                                for (const range of availableTimeSlotsDto.timeSlotRangeList) {
                                    const day = range.timeSlotDayList.find(
                                        (day) =>
                                            day.dateKey == selectedtimeSlotDayDTO.dateKey
                                    );

                                    if (!day) continue;
                                    if (
                                        day.timeSlotRangeItemList.find(
                                            (y) =>
                                                y.startHour ==
                                                    selectedtimeSlotRangeItemDTO.startHour &&
                                                y.endHour ==
                                                    selectedtimeSlotRangeItemDTO.endHour &&
                                                y.isAvailable
                                        )
                                    ) {
                                        timeSlotRangeItemExists = true;
                                        break;
                                    }
                                }
                            }

                            if (!timeSlotRangeItemExists) {
                                this.loadTimeslotListAfterFailedCheckout(false);
                            } else {
                                this.continueCheckout(isMobile, router, modal);
                            }
                        });
                }
            } else {
                this.setSelectedStepSource(CheckoutStep.Preferences);
                let inputField: HTMLElement;

                if (inputField && !this._sessionService.isMobile)
                    inputField?.scrollIntoView();
            }
        } else {
            this.setCheckoutInProgress(false);
            this.scrollToFirstInvalidGroup(checkoutForm);
        }
    }

    scrollToFirstInvalidGroup(checkoutForm: FormGroup): void {
        let inputField: HTMLElement;

        if (
            checkoutForm.get('addressForm').invalid ||
            checkoutForm.get('paymentForm.Longitude').invalid ||
            checkoutForm.get('paymentForm.Latitude').invalid
        ) {
            this.setSelectedStepSource(CheckoutStep.Address);
            inputField = <HTMLElement>document.getElementById('addressPanel');
        } else if (checkoutForm.get('deliveryTimeForm').invalid) {
            this.setSelectedStepSource(CheckoutStep.DeliveryTime);
            inputField = <HTMLElement>document.getElementById('deliveryTimePanel');
        } else if (checkoutForm.get('wishListForm')?.invalid) {
            this.setSelectedStepSource(CheckoutStep.WishList);
            inputField = <HTMLElement>document.getElementById('wishListPanel');
        } else if (this.validateOrderPreferences()) {
            this.setSelectedStepSource(CheckoutStep.Preferences);
            inputField = <HTMLElement>document.getElementById('preferencesPanel');
        } else {
            this.setSelectedStepSource(CheckoutStep.Payment);
            inputField = <HTMLElement>document.getElementById('paymentDataPanel');
        }

        if (inputField && !this._sessionService.isMobile) inputField?.scrollIntoView();
    }

    continueCheckout(isMobile = false, router = null, modal: Promise<any> = null): void {
        const order = this._orderDtoSource.getValue();
        this._dataLayerService.datalayerUniversalPush(
            'Checkout',
            'StartOrder',
            order.providerCode
        );

        //TODO important to call it by url
        //this._homeService.setFooterDisplayed(false);

        this._orderStatusSource.next(OrderStatus.INPROGRESS);
        this._isSubmittedSource.next(true);

        const checkoutForm = this._formGroupSource.getValue();

        checkoutForm
            .get('paymentForm.CouponCode')
            .setValue(checkoutForm.get('paymentForm.CouponCode').value?.trim());

        const phoneNumber =
            checkoutForm.get('addressForm.Phone').value.internationalNumber;
        if (phoneNumber) {
            checkoutForm
                .get('addressForm.Phone')
                .setValue(
                    checkoutForm.get('addressForm.Phone').value.internationalNumber
                );
        }

        if (this._instantDeliverySelectedSource.getValue()) {
            const currentDate = new Date();
            const currentHour = currentDate.getHours();
            const instantDeliveryEnd =
                currentHour + this._selectedInstantDeliveryIntervalSource.getValue();
            const currentMinutes =
                currentDate.getMinutes() < 10
                    ? '0' + currentDate.getMinutes()
                    : currentDate.getMinutes();

            checkoutForm
                .get('deliveryTimeForm.DeliveryTimeSlots')
                .setValue(
                    currentHour +
                        '.' +
                        currentMinutes +
                        ' - ' +
                        instantDeliveryEnd +
                        '.' +
                        currentMinutes
                );
        }

        this._formGroupSource.next(checkoutForm);

        this._orderService
            .finishOrder(
                checkoutForm,
                this.currentProviderCode,
                order.BasketItemList[0].BasketID,
                order.providerID,
                this._instantDeliverySelectedSource.getValue()
            )
            .subscribe(
                (data) => {
                    this.dataLayerGa4Service.datalayerUniversalPush(
                        GA4EventType.start_order,
                        GA4EventType.start_order
                    );

                    if (data.ResultCode.split('#')[0] === 'ok') {
                        this._orderStatusSource.next(OrderStatus.SUBMITTED);
                    } else if (data.ResultCode === 'waitingforpayment') {
                        const paymentType = checkoutForm
                            .get('paymentForm.PaymentType')
                            .value?.trim();

                        if (paymentType === 'RevolutCard') {
                            this._orderService
                                .startPaymentWidget(data.OrderCode)
                                .subscribe((startPayObject) => {
                                    this.doRevolutWidget(
                                        startPayObject,
                                        data.OrderCode,
                                        isMobile,
                                        router
                                    );
                                });
                        }
                        if (paymentType === 'ApplePay') {
                            this._orderService
                                .startPaymentWidget(data.OrderCode)
                                .subscribe((startPayObject) => {
                                    this.doPaymentRequest(
                                        startPayObject,
                                        data.OrderCode,
                                        isMobile,
                                        router,
                                        modal
                                    );
                                });
                        } else if (paymentType === 'GooglePay') {
                            this._orderService
                                .startPaymentWidget(data.OrderCode)
                                .subscribe((startPayObject) => {
                                    this.doPaymentRequestForAndroid(
                                        startPayObject,
                                        data.OrderCode,
                                        router,
                                        modal
                                    );
                                });
                        } else if (paymentType === 'BarionCard') {
                            this._orderService
                                .startPayment(data.OrderCode)
                                .subscribe((redirectSimpleUrl) => {
                                    window.location = redirectSimpleUrl;
                                });
                        } else if (
                            paymentType === 'PayOnDeliveryCard' ||
                            paymentType === 'PayOnDeliveryCash'
                        ) {
                            this._orderService
                                .startPayment(data.OrderCode)
                                .subscribe((transactionGuid) => {
                                    this.router.navigate(['payment/spresult'], {
                                        queryParams: { paymentId: transactionGuid },
                                    });
                                });
                        }
                    } else {
                        this._orderStatusSource.next(OrderStatus.FAILED);
                        window.alert(data.ResultMessage);

                        this._dataLayerService.datalayerUniversalPush(
                            'Checkout',
                            'Finish order',
                            'Error'
                        );
                    }
                },
                () => {
                    this._orderStatusSource.next(OrderStatus.FAILED);
                },
                () => this.setCheckoutInProgress(false)
            );
    }

    doRevolutWidget(
        startPayObject: any,
        ordercode: string,
        isMobile = false,
        router = null
    ) {
        const revolutMode = this.baseService.getRevolutMode();

        RevolutCheckout(
            startPayObject.BankTransactionGUID,
            revolutMode === 'sandbox' ? 'sandbox' : 'prod'
        ).then(function (instance) {
            instance.payWithPopup({
                locale: ServiceBaseService.languagecode as Locale,
                name: startPayObject.CustomerName,
                email: startPayObject.CustomerEmail,
                savePaymentMethodFor: 'merchant', //'customer',
                onSuccess: () => {
                    if (isMobile && router !== null) {
                        router.navigate(['payment/spresult'], {
                            queryParams: { paymentId: startPayObject.TransactionGUID },
                        });
                    } else {
                        window.location.href =
                            '/payment/spresult?paymentId=' +
                            startPayObject.TransactionGUID +
                            '&ordercode=' +
                            ordercode;
                    }
                },
                onError() {
                    window.location.href =
                        '/payment/spresult?status=failed' + '&ordercode=' + ordercode;
                },
                onCancel() {
                    this._orderStatusSource.next(OrderStatus.FORM);
                },
            });
        });
    }

    doPaymentRequest(
        startPayObject: any,
        ordercode: string,
        isMobile = false,
        router = null,
        modal: Promise<any> = null
    ) {
        RevolutCheckout(startPayObject.BankTransactionGUID, 'prod').then(function (
            instance
        ) {
            modal.then((ionModal: any) => {
                ionModal.present().then(() => {
                    const order = this._orderDtoSource.getValue();
                    const shippingOptions = [
                        {
                            id: ordercode,
                            label: ordercode,
                            amount: order.TotalPrice,
                        },
                    ];
                    const paymentRequest = instance.paymentRequest({
                        target: document.getElementById(
                            'revolut-payment-request-container'
                        ),
                        requestShipping: false,
                        locale: ServiceBaseService.languagecode as Locale,
                        onSuccess() {
                            if (isMobile && router !== null) {
                                router.navigate(['payment/spresult'], {
                                    queryParams: {
                                        paymentId: startPayObject.TransactionGUID,
                                    },
                                });
                            } else {
                                window.location.href =
                                    '/payment/spresult?paymentId=' +
                                    startPayObject.TransactionGUID +
                                    '&ordercode=' +
                                    ordercode;
                            }
                            ionModal.dismiss(true).then();
                        },
                        onError(error) {
                            window.alert(error.message);
                            window.location.href =
                                '/payment/spresult?status=failed' +
                                '&ordercode=' +
                                ordercode;
                            ionModal.dismiss(true).then();
                        },
                        buttonStyle: { size: 'large', variant: 'light-outlined' },
                    });

                    paymentRequest['shippingOptions'] = shippingOptions;
                    paymentRequest['savePaymentMethodForMerchant'] = true;

                    paymentRequest.canMakePayment().then((method) => {
                        if (method) {
                            paymentRequest.render().then();
                        } else {
                            window.alert('Not supported');
                            paymentRequest.destroy();
                        }
                    });
                });
            });
        });
    }

    doPaymentRequestForAndroid(
        startPayObject: any,
        ordercode: string,
        router = null,
        modal: Promise<any> = null
    ) {
        const merchantID =
            ServiceBaseService.languagecode == 'hu'
                ? environment.GOOGLE_PAY_FOR_HU
                : environment.GOOGLE_PAY_FOR_AT;
        const currency = ServiceBaseService.languagecode == 'hu' ? 'HUF' : 'EUR';
        const order = this._orderDtoSource.getValue();

        this.postMessageService
            .googlePayRequest(merchantID, order.TotalPrice.toString(), currency)
            .subscribe((response) => {
                document
                    .getElementById('revolut-payment-request-container')
                    .append(`<a>${response.result}</a>`);
            });
    }

    loadTimeslotListAfterFailedCheckout(afterInstant: boolean): void {
        forkJoin([
            this.translate.get('checkout.no-timeslot-further-message'),
            this.translate.get('checkout.no-express-message'),
        ]).subscribe(([timeslotMessage, instantMessage]: [string, string]) => {
            const message = afterInstant ? instantMessage : timeslotMessage;
            this._snackBarService.openSnackBar(message);
        });

        const order = this._orderDtoSource.getValue();
        const checkoutForm = this._formGroupSource.getValue();

        this._timeSlotService
            .getAvailableTimeSlotList(
                order.providerID,
                checkoutForm.get('addressForm.PostCode').value
            )
            .subscribe((availableTimeSlotsDto) => {
                this._availableTimeSlotsSource.next(availableTimeSlotsDto);
            });

        this._timeSlotService.setActiveEarliestTimeSlotId(null);
        this._timeSlotService.setActiveTimeSlotId(null);
        this.setCheckoutInProgress(true);
        this._selectedStepSource.next(CheckoutStep.DeliveryTime);
        document.getElementById('deliveryTimesPanel').scrollIntoView();
    }

    private addProductsToDataLayer(data: any): void {
        this.redirectProductModel.isConnected = true;
        this.redirectProductModel.redirectionJobId = '1';
        this.redirectProductModel.providerCode = data.providerCode;

        // this._dataLayerService.initiateCheckout(this.redirectProductModel);
    }

    createCheckoutForm(): FormGroup {
        return this.formBuilder.group({
            addressForm: this.formBuilder.group({
                PostCode: [
                    null,
                    [
                        Validators.required,
                        Validators.minLength(4),
                        Validators.maxLength(4),
                        Validators.pattern(/^[0-9]*$/),
                    ],
                ],
                City: [
                    null,
                    [
                        Validators.required,
                        Validators.minLength(2),
                        Validators.maxLength(50),
                    ],
                ],
                StreetName: [null, [Validators.required, Validators.minLength(2)]],
                HouseNumber: [null, [Validators.required, Validators.minLength(1)]],
                Comment: [null],
                FirstName: [null, [Validators.required, Validators.minLength(1)]],
                LastName: [null, [Validators.required, Validators.minLength(1)]],
                FullName: [null, [Validators.required, Validators.minLength(1)]],
                Email: [null, [Validators.required, Validators.email]],
                Phone: [null, [Validators.required, Validators.minLength(7)]],
                BirthDate: [null, [Validators.required]],
                BillingName: [null, []],
                BillingAddressPostCode: [null, []],
                BillingAddress: [null, []],
                TaxNumber: [null, [Validators.pattern(/^(\d{8}-\d{1}-\d{2})$/)]],
                DifferentBillingAddressSelector: [BillingAddressType.SameBillingAddress],
            }),
            deliveryTimeForm: this.formBuilder.group({
                DeliveryDays: [null, [Validators.required, Validators.minLength(2)]],
                DeliveryTimeSlots: [null, [Validators.required, Validators.minLength(2)]],
                DeliveryDate: [null],
                DeliveryStartHour: [null, Validators.required],
                DeliveryEndHour: [null, Validators.required],
                TimeSlotID: [0, Validators.required],
            }),
            preferencesForm: this.formBuilder.group({
                OrderPreferenceSettingOptionsIds: [null, []],
            }),
            paymentForm: this.formBuilder.group({
                PaymentType: [null, Validators.required],
                Latitude: [null],
                Longitude: [null],
                CouponCode: [null, []],
                ToSAccepted: [false, [Validators.requiredTrue]],
                PrivacyAccepted: [false, [Validators.requiredTrue]],
                ProviderMarketingAccepted: [true],
            }),
            discountForm: this.formBuilder.group({
                DiscountID: [null, []],
            }),
            tipForm: this.formBuilder.group({
                TipID: [null, []],
                TipAmount: [null, []],
            }),
        });
    }
}
