<div class="w-100 p-5">
    <br /><br /><br /><br /><br />

    <div *ngIf="!isIPNBEResponsed" class="text-info w-100 text-center">
        <h2>{{ 'payment.payment-in-progress' | translate }}</h2>
        <img src="assets/loading_kosar.gif" style="width: auto; height: 150px" />
    </div>

    <!--Ha a backendről visszajött, hogy sikeres a tranzakció, vagy ha 5 ms után semmi nem jön vissza-->
    <div *ngIf="status == 'RESERVED'" class="text-success text-center">
        <h3 *ngIf="!this.isPayOnDelivery && !this.isCashOnDelivery">
            <fa-icon
                [icon]="faCheckCircle"
                class="menu-nav-resp pointer color-turquoise"
                size="lg"
            ></fa-icon>
            {{ 'payment.succesful-reservation' | translate }}
        </h3>
        <h3 *ngIf="this.isPayOnDelivery || this.isCashOnDelivery">
            <fa-icon
                [icon]="faCheckCircle"
                class="menu-nav-resp pointer color-turquoise"
                size="lg"
            ></fa-icon>
            {{ 'payment.succesful-pay-on-delivery' | translate }}
        </h3>
        <p *ngIf="orderCode">
            {{ 'order-list.order-number' | translate }}: {{ orderCode }}
        </p>
        <br />
        <p class="text-success m-4 font-weight-600">
            {{ 'payment.email-soon-message' | translate }}
            <br />
            {{ 'payment.you-have-nothing-to-do' | translate }} <br />
            {{ 'payment.thanks-for-order' | translate }}
        </p>
        <div *ngIf="currentOrder" class="clearfix">
            <br />
            <app-order-card
                [order]="currentOrder"
                [isOneOrderView]="true"
            ></app-order-card>
        </div>
    </div>

    <div
        *ngIf="isIPNBEResponsed && status != 'RESERVED'"
        class="text-center"
        style="color: #666"
    >
        <ng-container *ngIf="status == 'FAILED'">
            <h3 class="text-danger">{{ 'payment.failed-payment' | translate }}</h3>
            <p *ngIf="parsedResult">
                {{ 'payment.transaction-id' | translate }}: {{ parsedResult }}
            </p>
            <br />
            <p *ngIf="orderCode">
                {{ 'order-list.order-number' | translate }}: {{ orderCode }}
            </p>
            <br />

            <p>
                {{ 'payment.please-try-again' | translate }}
            </p>

            <p>
                {{ 'payment.contact-us' | translate }}
            </p>
            <br />
        </ng-container>

        <ng-container *ngIf="status == 'CANCELED'">
            <h3 class="text-info">{{ 'payment.interrupted-payment' | translate }}</h3>
            <p>{{ 'payment.want-to-order-again' | translate }}</p>
            <button (click)="startPay(orderCode)" class="btn btn-success w-75 m-auto">
                {{ 'payment.barion-pay-again' | translate }}
            </button>
        </ng-container>

        <ng-container *ngIf="status == 'EXPIRED'">
            <h3 class="text-info">
                {{ 'payment.expired-payment' | translate }}
            </h3>
            <p>{{ 'payment.want-to-order-again' | translate }}</p>
            <button (click)="startPay(orderCode)" class="btn btn-success w-75 m-auto">
                {{ 'payment.barion-pay-again' | translate }}
            </button>
        </ng-container>

        <ng-container
            *ngIf="status != 'FAILED' && status != 'CANCELED' && status != 'EXPIRED'"
        >
            {{ 'payment.other-error' | translate }}
        </ng-container>
    </div>
</div>
