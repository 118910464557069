import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    CategoryBlockType,
    DashboardService,
    PaymentService,
    ProductBlockDto,
    ServiceBaseService,
    SessionService,
} from 'projects/library-shared/src/public-api';
import { MatExpansionModule } from '@angular/material/expansion';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { CreateDialogService } from '../../services/create-dialog.service';
import { HomeService } from '../../services/home/home.service';
import { MatDialog } from '@angular/material/dialog';
import { AddressDialogComponent } from '../../components/address/address-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-informative-dashboard',
    templateUrl: './informative-dashboard.component.html',
    styleUrls: ['./informative-dashboard.component.scss'],
})
export class InformativeDashboardComponent implements OnInit, OnDestroy {
    isPostCodeSetted = false;
    isProviderSelectedForShopping = false;
    availableShops: string[];

    siteImageUrl = '';

    defaultPaymentProviders: string[];

    commercialBlocks: ProductBlockDto[];
    addressDialogComponent = AddressDialogComponent;
    constructor(
        private sessionService: SessionService,
        private meta: Meta,
        private createDialogService: CreateDialogService,
        private homeService: HomeService,
        public mat: MatExpansionModule,
        public baseService: ServiceBaseService,
        private translate: TranslateService,
        private paymentService: PaymentService,
        private dashboardService: DashboardService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
        this.sessionService.isProviderSelectedForShopping.next(false);
        this.siteImageUrl = this.baseService.getSiteImageUrl();

        forkJoin([
            this.translate.get('meta.dashboard-og-title'),
            this.translate.get('meta.dashboard-description'),
            this.translate.get('meta.dashboard-keywords'),
        ]).subscribe(([title, description, keywords]: [string, string, string]) => {
            this.meta.updateTag({
                property: 'og:title',
                content: title,
            });
            this.meta.updateTag({
                property: 'og:description',
                content: description,
            });
            this.meta.updateTag({
                property: 'og:image',
                content:
                    'https://www.roksh.com/assets/images/roksh-landing-kv-static.png',
            });
            this.meta.updateTag({
                name: 'keywords',
                content: keywords,
            });

            this.meta.updateTag({
                name: 'description',
                content: description,
            });
        });

        this.sessionService.isPostCodeSet.subscribe((result) => {
            this.isPostCodeSetted = result;

            this.dashboardService
                .getProductBlockList(
                    'dashboard',
                    1,
                    CategoryBlockType.commercial,
                    true,
                    !result
                )
                .subscribe((productBlocks: ProductBlockDto[]) => {
                    this.commercialBlocks = productBlocks;
                    if (!result) {
                        this.commercialBlocks = this.commercialBlocks.filter(
                            (item) => item.IsIncognitoRokshLanding == true
                        );
                    } else {
                        this.sessionService.sessionSubject.subscribe((session) => {
                            this.commercialBlocks = this.commercialBlocks.filter((item) =>
                                session.shopsAvailable.some((shopAvailable) =>
                                    shopAvailable.shopName.includes(
                                        item.ProviderNameToSelect
                                    )
                                )
                            );
                        });
                    }
                });
        });
        this.sessionService.isProviderSelectedForShopping.subscribe((result) => {
            this.isProviderSelectedForShopping = result;
        });

        this.homeService.dataBaseAvailableProviderNames.subscribe((data) => {
            this.availableShops = data;
        });

        this.paymentService.paymentProviders.subscribe((paymentProviders) => {
            this.defaultPaymentProviders = paymentProviders;
        });
    }

    ngOnDestroy(): void {
        this.homeService.isHeaderDisplayed.next(true);
    }

    showShopSelectDialog(): void {
        if (!this.isPostCodeSetted) {
            this.createDialogService.openAddressDialog();
        } else if (!this.isProviderSelectedForShopping) {
            this.createDialogService.openShopSelectDialogSeparatedComponent();
        }
    }

    handleClick(): void {
        this.sessionService.isPostCodeSet.subscribe((result) => {
            if (!result) {
                this.dialog
                    .open(this.addressDialogComponent, {
                        data: { stayHere: true },
                    })
                    .afterClosed()
                    .subscribe(() => {
                        if (!this.isPostCodeSetted) {
                            this.translate
                                .get('messages.add-your-postcode-b4-add-to-basket')
                                .subscribe((text) => {
                                    this.snackBar.open(text, null, {
                                        verticalPosition: 'top',
                                        horizontalPosition: 'center',
                                        duration: 3000,
                                    });
                                });
                        } else {
                            this.isPostCodeSetted = true;
                            //TODO productdto-t ezzel kiegészíteni product.providerName
                            this.sessionService.sessionSubject.subscribe((session) => {
                                this.commercialBlocks = this.commercialBlocks.filter(
                                    (block) =>
                                        block.ProductList.some((product) =>
                                            session.selectedShops.includes(
                                                block.ProviderNameToSelect
                                            )
                                        )
                                );
                            });
                        }
                    });
            }
        });
    }
}
