import { Component, Input, OnInit } from '@angular/core';
import {
    ContainerIds,
    HtmlMarketingElementsExtendsDto,
    LogService,
    MarketingService,
    ProductBlockDto,
    ProductListResult,
    ServiceBaseService,
    SessionService,
} from 'projects/library-shared/src/public-api';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-product-group',
    templateUrl: './product-group.component.html',
    styleUrls: ['./product-group.component.scss'],
    providers: [LogService],
})
export class ProductGroupComponent implements OnInit {
    @Input() productListResult: ProductListResult;
    @Input() isMainCategory = false;
    @Input() customBlock: ProductBlockDto;
    @Input() shoppingProhibited: boolean;
    categoryImgSrc: string;
    coverImgSrc: string;
    baseImgUrl: string;
    title: string;
    nextUrl: string;
    productListResultUnordered: ProductListResult;
    siteImageUrl = '';
    mediaServiceUrl = '';
    isCustomBlock = false;
    faChevronRight = faChevronRight;
    faChevronLeft = faChevronLeft;
    carouselView = false;
    isMobileView = false;
    htmlMarketingElementDto: HtmlMarketingElementsExtendsDto;
    customOptions: any = {
        dots: false,
        autoWidth: true,
        responsive: {
            autoWidth: true,
            0: {
                items: 2,
                slideBy: 2,
            },
            360: {
                items: 2,
                slideBy: 2,
            },
            480: {
                items: 2,
                slideBy: 2,
            },
            605: {
                items: 3,
                slideBy: 3,
            },
            825: {
                items: 4,
                slideBy: 4,
            },
            990: {
                autowidth: true,
            },
        },
    };
    customBlockOptions: any = {
        dots: false,
        autoWidth: true,
        responsive: {
            autoWidth: true,
            0: {
                items: 2,
                slideBy: 2,
            },
            360: {
                items: 2,
                slideBy: 2,
            },
            480: {
                items: 1,
                slideBy: 1,
            },
            605: {
                items: 3,
                slideBy: 2,
            },
            825: {
                items: 4,
                slideBy: 3,
            },
            990: {
                autowidth: true,
            },
        },
    };

    constructor(
        private log: LogService,
        private route: ActivatedRoute,
        public baseService: ServiceBaseService,
        private sessionService: SessionService,
        private marketingService: MarketingService
    ) {
        if (window.innerWidth < 768) this.isMobileView = true;
    }

    ngOnInit() {
        this.siteImageUrl = this.baseService.getSiteImageUrl();
        this.mediaServiceUrl = this.baseService.getMediaServiceUrl();
        const provider = this.sessionService.sessionSubject
            .getValue()
            .selectedShopCodes[0]?.toLowerCase();

        const cat1 = this.route.snapshot.params['cat1'];
        if (typeof cat1 !== 'undefined') this.carouselView = true;

        if (this.customBlock !== undefined) {
            this.isCustomBlock = true;
            this.productListResultUnordered = this.productListResult;
            this.title = this.customBlock.Title;
            this.baseImgUrl = '';
            this.categoryImgSrc =
                this.mediaServiceUrl + `interface/original/${this.customBlock.MediaGUID}`;

            this.coverImgSrc =
                this.mediaServiceUrl + `interface/medium/${this.customBlock.MediaGUID}`;

            if (
                this.customBlock.HasOwnCategory &&
                this.customBlock.OwnCategoryUrl !== ''
            ) {
                this.nextUrl = '/' + provider + '/' + this.customBlock.OwnCategoryUrl;
            } else {
                this.nextUrl = '/' + provider + '/' + this.customBlock.RelatedUrl;
            }
        } else {
            this.isCustomBlock = false;
            this.loadUncostum();
            this.nextUrl = '/' + this.productListResult.CategoryHeader.Url;
            if (provider !== undefined && !this.nextUrl.includes(provider)) {
                this.nextUrl = `/${provider}`.concat(this.nextUrl);
            }
        }
        this.htmlMarketingElementDto =
            this.marketingService.filteredHtmlMarketingElements(
                this.customBlock.HtmlMarketingElementDto
            );
    }

    loadUncostum() {
        this.log.mainClass = ProductGroupComponent.name;
        this.customBlock = new ProductBlockDto();

        this.productListResultUnordered = this.productListResult;

        this.customBlock.ProductList = this.productListResult.ProductList;

        if (
            this.productListResult &&
            this.productListResult.CategoryHeader &&
            this.productListResult.CategoryHeader.CategoryProviderImage &&
            this.productListResult.CategoryHeader.CategoryProviderImage
                .MainCategoryImageBlueFileName &&
            this.productListResult.CategoryHeader.CategoryProviderImage
                .MainCategoryImageWhiteFileName
        ) {
            this.baseImgUrl =
                this.siteImageUrl + 'category-images/category-provider-images/';
            this.categoryImgSrc =
                this.productListResult.CategoryHeader.CategoryProviderImage.MainCategoryImageBlueFileName;
        } else if (
            this.productListResult.CategoryHeader &&
            this.productListResult.CategoryHeader.CategoryProviderImage &&
            this.productListResult.CategoryHeader.CategoryProviderImage
                .CategoryImageFileName
        ) {
            this.baseImgUrl =
                this.siteImageUrl + 'category-images/category-provider-images/';
            this.categoryImgSrc =
                this.productListResult.CategoryHeader.CategoryProviderImage.CategoryImageFileName;
        } else {
            if (
                this.productListResult &&
                this.productListResult.CategoryHeader &&
                this.productListResult.CategoryHeader.CategoryImageFileName &&
                this.productListResult.CategoryHeader.CategoryImageFileName.indexOf(
                    'own-categories'
                ) > -1
            ) {
                this.baseImgUrl = this.siteImageUrl + 'category-images/';
                this.categoryImgSrc =
                    this.productListResult.CategoryHeader.CategoryImageFileName;
            } else {
                if (this.isMainCategory) {
                    this.baseImgUrl =
                        this.siteImageUrl + 'category-images/main-categories/';
                    this.categoryImgSrc =
                        this.productListResult.CategoryHeader.ProgID + '.png';
                } else {
                    this.baseImgUrl = this.siteImageUrl + 'category-images/categories/';
                    this.categoryImgSrc =
                        this.productListResult.CategoryHeader.ProgID + '.png';
                }
            }
        }
    }

    protected readonly ContainerIds = ContainerIds;
}
