import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ServiceBaseService } from '../../../services/service-base.service';
import { UserBasketService } from '../../../services/user-basket.service';

@Component({
    templateUrl: './delete-shopping-list-dialog.component.html',
    styleUrls: ['./delete-shopping-list-dialog.component.scss'],
})
export class DeleteShoppingListDialogComponent {
    removeIsProgress = false;
    siteImageUrl: string;

    constructor(
        public dialogRef: MatDialogRef<DeleteShoppingListDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public userBasket: { userBasketId: number; basketName: string },
        public userBasketService: UserBasketService,
        baseService: ServiceBaseService
    ) {
        this.siteImageUrl = baseService.siteImageUrl;
    }

    onRemoveUserBasket() {
        this.removeIsProgress = true;
        this.userBasketService.deleteUserList(this.userBasket.userBasketId).subscribe(
            () => {
                this.removeIsProgress = false;
                this.dialogRef.close('Delete');
            },
            () => {
                this.removeIsProgress = false;
                this.dialogRef.close('Error');
            }
        );
    }
}
