import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import * as moment from 'moment-timezone';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    AvailableTimeSlotsDTO,
    CheckoutService,
    CheckoutStep,
    DATALAYER_SERVICE_IMPL,
    DataLayerGa4Service,
    DateService,
    GA4EventType,
    InstantTimeslotDTO,
    SnackBarService,
    TimeSlotDayDTO,
    TimeSlotRangeItemDTO,
} from 'projects/library-shared/src/public-api';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-checkout-delivery-time',
    templateUrl: './checkout-delivery-time.component.html',
    styleUrls: ['./checkout-delivery-time.component.scss'],
})
export class CheckoutDeliveryTimeComponent implements OnInit, OnDestroy {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    checkoutForm: FormGroup;
    selectedIndex: number;
    deliveryStepIndex = CheckoutStep.DeliveryTime;
    hasBeenActivated = false;

    availableTimeSlotsDto: AvailableTimeSlotsDTO;
    selectedTimeSlotDTO: TimeSlotDayDTO;
    selectedTimeSlotRangeItemDTO: TimeSlotRangeItemDTO;

    zipIsValid: boolean;
    postCode: string;
    instantDeliveryApproved = false;
    instantDeliverySelected = false;
    areaHasInstantProvider$: Observable<boolean>;
    instantWorktimes: InstantTimeslotDTO[];
    configuredInstantWorktimes: InstantTimeslotDTO[];
    displayOrderPreferences = false;

    displayWishListProviderShops = false;

    public get deliveryTimeStepIsExpanded(): boolean {
        return this.selectedIndex === this.deliveryStepIndex;
    }

    public get deliveryTimeStepIsActive(): boolean {
        return this.selectedIndex > this.deliveryStepIndex;
    }

    public get deliveryTimeStepIsDisabled(): boolean {
        return !this.hasBeenActivated;
    }

    public get deliveryDaysAvailable(): boolean {
        return this.deliveryDaysControl.value !== '' && !this.deliveryDaysControl.invalid;
    }

    public get deliveryTimeSlotsAvailable(): boolean {
        return (
            this.deliveryTimeSlotsControl.value !== '' &&
            !this.deliveryTimeSlotsControl.invalid
        );
    }

    get deliveryDaysControl(): AbstractControl {
        return this.checkoutForm.get('deliveryTimeForm.DeliveryDays');
    }

    get deliveryTimeSlotsControl(): AbstractControl {
        return this.checkoutForm.get('deliveryTimeForm.DeliveryTimeSlots');
    }

    get deliveryDateControl(): AbstractControl {
        return this.checkoutForm.get('deliveryTimeForm.DeliveryDate');
    }

    get deliveryStartHourControl(): AbstractControl {
        return this.checkoutForm.get('deliveryTimeForm.DeliveryStartHour');
    }

    get deliveryEndHourControl(): AbstractControl {
        return this.checkoutForm.get('deliveryTimeForm.DeliveryEndHour');
    }

    get timeSlotIDControl(): AbstractControl {
        return this.checkoutForm.get('deliveryTimeForm.TimeSlotID');
    }

    constructor(
        private checkoutService: CheckoutService,
        private snackBarService: SnackBarService,
        private dateSerivce: DateService,
        @Inject(DATALAYER_SERVICE_IMPL) private _DataLayerGa4Service: DataLayerGa4Service,
        private translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.checkoutService.formGroup$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((form) => (this.checkoutForm = form));

        this.checkoutService.orderPreferences$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((settings) => {
                this.displayOrderPreferences =
                    settings && settings.length !== 0 ? true : false;
            });

        this.checkoutService.selectedStep$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((index) => {
                if (index == this.deliveryStepIndex) {
                    this.hasBeenActivated = true;
                }
                this.selectedIndex = index;
            });

        this.checkoutService.availableTimeSlots$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((availableTimeSlots) => {
                if (availableTimeSlots) {
                    this.availableTimeSlotsDto = availableTimeSlots;
                    this.deliveryTimeSlotsControl.setValue(null);
                    this.deliveryDaysControl.setValue(null);
                }
            });

        this.checkoutService.selectedTimeSlot$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((selectedTimeSlot) => {
                this.selectedTimeSlotDTO = selectedTimeSlot;
            });

        this.checkoutService.instantDeliveryApproved$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((isApproved) => (this.instantDeliveryApproved = isApproved));

        this.checkoutService.instantWorktime$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((worktimes) => (this.instantWorktimes = worktimes));

        this.checkoutService.configuredInstantWorktime$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((worktimes) => (this.configuredInstantWorktimes = worktimes));

        this.checkoutService.instantDeliverySelected$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((isSelected) => (this.instantDeliverySelected = isSelected));

        this.areaHasInstantProvider$ = this.checkoutService.areaHasInstantProvider$;

        this.checkoutService.zipIsValid$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((zipIsValid) => (this.zipIsValid = zipIsValid));

        this.checkoutService.address$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((address) => {
                if (address) {
                    this.postCode = address.postCode;
                }
            });

        this.checkoutService.isWishListAvailable$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((isWishListAvailable) => {
                this.displayWishListProviderShops = isWishListAvailable;
            });
    }

    public showAvailableTimeSlots($event: any): void {
        this.checkoutService.setInstantDeliverySelectedSource(false);
        this.checkoutService.setSelectedTimeSlotRangeItemSource(null);
        this.selectedTimeSlotRangeItemDTO = null;

        this.selectedTimeSlotDTO = $event.selectedTimeSlotDayDto;

        this.deliveryTimeSlotsControl.setValue(null);
        this.deliveryDaysControl.setValue(
            this.dateSerivce.getReadableDate(this.selectedTimeSlotDTO.date.toString())
        );

        this.checkoutService.setSelectedTimeSlotSource(this.selectedTimeSlotDTO);
        return;
    }

    getReadableDate(date: Date): string {
        return this.dateSerivce.getReadableDate(date.toString());
    }

    public selectInstantDelivery($event: any) {
        this.selectedTimeSlotDTO = null;
        const elements = document.getElementsByClassName('delivery-days');
        for (let i = 0; i < elements.length; i++) elements[i].classList.remove('active');

        //weight check
        this.checkoutService.setInstantDeliveryApproval();
        if (this.instantDeliveryApproved) {
            //worktime check
            this.checkoutService.getRealTimeInstantTimeSlotsByBoundary(
                $event.instantTimeSlot.interval
            );
            if (this.instantDeliveryApproved) {
                this.checkoutService.setInstantDeliverySelectedSource(true);
                this.checkoutService.setInstantDeliverySelectedIntervalSource(
                    $event.instantTimeSlot.interval
                );
                this.checkoutService.calculateDeliveryFee(
                    true,
                    $event.instantTimeSlot.deliveryFee,
                    0,
                    $event.discount
                );

                // Temporary prototype workaround
                this.timeSlotIDControl.setValue($event.instantTimeSlot.timeslotID);

                const currentDate = new Date();
                const currentHour = currentDate.getHours();
                const instantDeliveryEnd = currentHour + $event.instantTimeSlot.interval;
                const currentMinutes =
                    currentDate.getMinutes() < 10
                        ? '0' + currentDate.getMinutes()
                        : currentDate.getMinutes();
                this.deliveryTimeSlotsControl.setValue(
                    currentHour +
                        '.' +
                        currentMinutes +
                        ' - ' +
                        instantDeliveryEnd +
                        '.' +
                        currentMinutes
                );

                let deliveryDateToSend = moment(currentDate).format();
                deliveryDateToSend = deliveryDateToSend.split('+')[0];

                this.deliveryDateControl.setValue(deliveryDateToSend);
                this.deliveryStartHourControl.setValue(currentHour);
                this.deliveryEndHourControl.setValue(instantDeliveryEnd);

                this.deliveryDaysControl.setValue(
                    this.dateSerivce.getReadableDateString(currentDate)
                );

                this.checkoutService.setFormGroupSource(this.checkoutForm);

                const postCode = this.checkoutForm.get('addressForm.PostCode');

                this.checkoutService.getAllowedWishListProviderShops(
                    postCode?.value,
                    currentDate.getDay(),
                    currentHour,
                    instantDeliveryEnd
                );
            } else {
                this.checkoutService.setInstantDeliveryApprovedSource(false);
                this.checkoutService.setInstantDeliverySelectedSource(false);
                this.translate.get('checkout.no-express-message').subscribe((text) => {
                    this.snackBarService.openSnackBar(text);
                });
            }
        }
    }

    public setDeliveryTimeSlot($event: any): void {
        this.checkoutService.setInstantDeliverySelectedSource(false);
        this.checkoutService.setSelectedTimeSlotRangeItemSource($event.timeSlotRangeItem);
        this.selectedTimeSlotDTO = $event.timeSlotDay;
        this.checkoutService.setSelectedTimeSlotSource(this.selectedTimeSlotDTO);
        this.checkoutService.calculateDeliveryFee(
            false,
            $event.timeSlotDay.deliveryFee,
            $event.timeSlotDay.additionalFee,
            $event.discountPercentage
        );

        this.selectedTimeSlotRangeItemDTO = $event.timeSlotRangeItem;
        this.timeSlotIDControl.setValue($event.timeSlotRange.timeSlotID);
        this.deliveryTimeSlotsControl.setValue(
            this.selectedTimeSlotRangeItemDTO.startHour +
                '.00 - ' +
                this.selectedTimeSlotRangeItemDTO.endHour +
                '.00'
        );

        let deliveryDateToSend = moment(this.selectedTimeSlotDTO.date).format();
        deliveryDateToSend = deliveryDateToSend.split('+')[0].split('T')[0];

        this.deliveryDateControl.setValue(deliveryDateToSend);
        this.deliveryStartHourControl.setValue(
            this.selectedTimeSlotRangeItemDTO.startHour
        );
        this.deliveryEndHourControl.setValue(this.selectedTimeSlotRangeItemDTO.endHour);
        this.deliveryDaysControl.setValue(
            this.dateSerivce.getReadableDateString(this.selectedTimeSlotDTO.date)
        );

        this.checkoutService.setFormGroupSource(this.checkoutForm);
        const postCode = this.checkoutForm.get('addressForm.PostCode');

        this.checkoutService.getAllowedWishListProviderShops(
            postCode?.value,
            this.selectedTimeSlotDTO.date.getDay(),
            this.selectedTimeSlotRangeItemDTO.startHour,
            this.selectedTimeSlotRangeItemDTO.endHour
        );

        this._DataLayerGa4Service.datalayerUniversalPush(
            GA4EventType.delivery_time_picked,
            GA4EventType.delivery_time_picked
        );
    }

    setStep(index: number): void {
        this.checkoutService.setSelectedStepSource(index);

        const scrWidth = window.innerWidth;

        const summaryPanel: HTMLElement = <HTMLElement>(
            document.getElementById('summaryPanel')
        );
        summaryPanel.classList.remove('in-focus');
        if (scrWidth < 992) {
            window.scroll(0, 0);
        } else {
            if (index != 0) document.getElementById('summaryPanel').scrollIntoView();
        }
    }

    nextStep(): void {
        if (
            (this.deliveryDaysControl.invalid || this.deliveryTimeSlotsControl.invalid) &&
            !this.instantDeliverySelected
        ) {
            this.translate.get('checkout.slot-required').subscribe((text) => {
                this.snackBarService.openErrorSnackBar(text);
            });

            return;
        }
        this._DataLayerGa4Service.datalayerUniversalPush(
            GA4EventType.delivery_time_finished,
            GA4EventType.delivery_time_finished
        );
        if (this.displayWishListProviderShops) {
            this._DataLayerGa4Service.datalayerUniversalPush('Checkout', 'ToWishList');
            this.setStep(CheckoutStep.WishList);
        } else if (this.displayOrderPreferences) {
            this._DataLayerGa4Service.datalayerUniversalPush('Checkout', 'ToPreferences');
            this.setStep(CheckoutStep.Preferences);
        } else {
            this._DataLayerGa4Service.datalayerUniversalPush('Checkout', 'ToPayment');
            this.setStep(CheckoutStep.Payment);
        }
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
        delete this.selectedIndex;
    }
}
