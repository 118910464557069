import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FacebookAuthResponseDto } from './dtos/facebook-auth-response.dto';
import { take } from 'rxjs/operators';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Router } from '@angular/router';
import { PostMessageResponse } from '../../services/dto/post-message-response/post-message-response';
import { UserShared } from '../../models/auth/user.model';
import { ServiceBaseService } from '../../services/service-base.service';
import { AuthenticationService } from '../../auth/auth.service';
import { LogService } from '../../services/log.service';
import { SessionService } from '../../services/session/session.service';
import { PostMessageService } from '../../services/post-message/post-message.service';
import { BasketService } from '../../services/basket.service';
import { SnackBarService } from '../../services/snackbar/snackbar.service';
import { SharedModalService } from '../../services/shared-modal.service';
import {
    DataLayerGa4Service,
    GA4Category,
} from '../../services/data-layer/data-layer-ga4.service';
import { RegistrationUserDto } from '../../models/auth/registration-user.dto';
import { ISessionDto } from '../../index';

@Component({
    selector: 'app-sign-page',
    templateUrl: 'log-in.component.html',
    styleUrls: ['log-in.component.scss'],
})
export class LogInComponent implements OnInit, AfterViewInit {
    registrationForm = new FormGroup({
        firstName: new FormControl('', [Validators.required, Validators.minLength(3)]),
        lastName: new FormControl('', [Validators.required, Validators.minLength(3)]),
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [
            Validators.required,
            Validators.minLength(7),
            Validators.pattern(
                /^(?=.*\d)(?=.*[a-záéúőóüöí.])(?=.*[A-ZÁÉÚŐÓÜÖÍ])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*)(+=._-ÁáÉéÍíÓóÚúÖöÜüŐőŰű].{7,}$/
            ),
        ]),
        confirmPassword: new FormControl('', [
            Validators.compose([this.validateNotEqual.bind(this)]),
        ]),
        birthDate: new FormControl('', Validators.required),
        aszf: new FormControl(false, [Validators.requiredTrue]),
        privacy: new FormControl(false, [Validators.requiredTrue]),
        marketingEmail: new FormControl(false),
        marketingSMS: new FormControl(false),
    });

    loginForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required]),
    });

    termsAcceptationForm = new FormGroup({
        Aszf: new FormControl(false, [Validators.requiredTrue]),
        Privacy: new FormControl(false, [Validators.requiredTrue]),
        MarketingEmail: new FormControl(false),
        MarketingSMS: new FormControl(false),
    });

    @Input() modal: HTMLIonModalElement;
    currentRegistrationPage = 1;
    platform$: Observable<PostMessageResponse>;
    maxBirthdate: Date = new Date();
    submitRegisterForm = false;
    submitTermsAcceptationForm = false;
    rememberMe = true;
    registerWithEmail = false;
    @ViewChild('tabsComponent') tabsComponent: TabsetComponent;
    @Input() selectedTab = 0;
    loggedInUser: UserShared;
    isWrapper: boolean;
    locale = ServiceBaseService.getCountryCode();
    isPopupBlocked: boolean;

    constructor(
        private authService: AuthenticationService,
        private socialAuthService: SocialAuthService,
        private logService: LogService,
        private translateService: TranslateService,
        private router: Router,
        private sessionService: SessionService,
        private datePipe: DatePipe,
        private postMessageService: PostMessageService,
        private basketService: BasketService,
        private snackBar: SnackBarService,
        private sharedModalService: SharedModalService,
        _adapter: DateAdapter<any>,
        @Inject(MAT_DATE_LOCALE) readonly _locale: string,
        private dataLayerGa4Service: DataLayerGa4Service
    ) {
        this._locale = ServiceBaseService.countrycode;
        _adapter.setLocale(this._locale);
        this.isWrapper = this.sessionService.isWrapper;
    }

    ngOnInit(): void {
        this.platform$ = this.postMessageService.platformName();
        this.maxBirthdate.setFullYear(this.maxBirthdate.getFullYear() - 18);

        if (localStorage.getItem('remember')) {
            const { email, password } = JSON.parse(
                atob(localStorage.getItem('remember'))
            );
            this.loginForm.get('email').setValue(email);
            this.loginForm.get('password').setValue(password);
        }

        this.authService.getAuthStatusListener().subscribe((isAuth) => {
            if (isAuth) {
                this.loggedInUser = this.authService.getLoggedInUser();
                this.postMessageService.setOneSignalEmail(this.loggedInUser.Email);
                this.postMessageService.setOneSignalTag(
                    'FirstName',
                    this.loggedInUser?.FirstName
                );
                this.postMessageService.setOneSignalTag(
                    'LastName',
                    this.loggedInUser?.LastName
                );
            }
        });
    }

    showRegistrationForm(): void {
        this.registerWithEmail = true;
    }

    closeRegistrationForm(): void {
        this.registerWithEmail = false;
    }

    async onGoogleLoginOrRegistration(): Promise<void> {
        this.postMessageService
            .googleAuth()
            .pipe(take(1))
            .subscribe((response) => {
                this.onGoogleLoginOrRegistrationResult(
                    response.result.accessToken
                ).then();
            });
    }

    async onGoogleLoginOrRegistrationResult(accessToken: string): Promise<void> {
        (await this.authService.loginWithGoogleForMobile(accessToken)).subscribe(
            (loggedInUser) => {
                this.loggedInUser = loggedInUser;
                this.postMessageService.setOneSignalEmail(this.loggedInUser.Email);
                this.postMessageService.setOneSignalTag(
                    'FirstName',
                    this.loggedInUser?.FirstName
                );
                this.postMessageService.setOneSignalTag(
                    'LastName',
                    this.loggedInUser?.LastName
                );

                this.sessionService.refreshSession().subscribe(() => {
                    this.dataLayerGa4Service.datalayerUniversalPush(
                        GA4Category.authentication,
                        'login with Google'
                    );

                    if (
                        this.loggedInUser.PrivacyAccepted &&
                        this.loggedInUser.ToSAccepted
                    )
                        this.modal.dismiss(true);
                });
            },
            (error) => {
                this.logService.error('Google login', error);
            }
        );
    }

    async onFacebookLoginOrRegistration(): Promise<void> {
        const userAgent = navigator.userAgent || navigator.vendor;
        if (this.isWrapper) {
            this.postMessageService
                .facebookAuth()
                .pipe(take(1))
                .subscribe((response) => {
                    this.onFacebookLoginOrRegistrationResult(
                        response.result,
                        /android/i.test(userAgent) ? 'android' : 'ios'
                    ).then();
                    this.sessionService.refreshSession().subscribe((sessionDto) => {
                        this.afterLoginNavigationTo(sessionDto);
                    });
                });
        } else {
            (await this.authService.loginWithFacebook()).subscribe(
                (facebookReGsponse) => {
                    this.loggedInUser = facebookReGsponse;

                    this.postMessageService.setOneSignalEmail(this.loggedInUser.Email);
                    this.postMessageService.setOneSignalTag(
                        'FirstName',
                        this.loggedInUser?.FirstName
                    );
                    this.postMessageService.setOneSignalTag(
                        'LastName',
                        this.loggedInUser?.LastName
                    );

                    this.sessionService.refreshSession().subscribe((sessionDto) => {
                        this.afterLoginNavigationTo(sessionDto);
                        this.presentRegistrationSuccessfully();
                        this.dataLayerGa4Service.datalayerUniversalPush(
                            GA4Category.authentication,
                            'login with Facebook'
                        );
                    });
                },
                (error) => {
                    this.logService.error('Facebook login', error);
                }
            );
        }
    }

    async onFacebookLoginOrRegistrationResult(
        facebookAuthResponseDto: FacebookAuthResponseDto,
        platform: string
    ): Promise<void> {
        const accessOrAuthentucationToken =
            platform == 'ios'
                ? 'bearer' + facebookAuthResponseDto
                : facebookAuthResponseDto?.accessToken;
        (
            await this.authService.loginWithFacebookForMobile(accessOrAuthentucationToken)
        ).subscribe(
            (loggedInUser) => {
                this.loggedInUser = loggedInUser;

                this.postMessageService.setOneSignalEmail(this.loggedInUser.Email);
                this.postMessageService.setOneSignalTag(
                    'FirstName',
                    this.loggedInUser?.FirstName
                );
                this.postMessageService.setOneSignalTag(
                    'LastName',
                    this.loggedInUser?.LastName
                );

                this.sessionService.refreshSession().subscribe(
                    (sessionDto) => {
                        this.afterLoginNavigationTo(sessionDto);
                        this.presentRegistrationSuccessfully();
                        this.dataLayerGa4Service.datalayerUniversalPush(
                            GA4Category.authentication,
                            'login with Facebook'
                        );
                    },
                    (error) => {
                        console.log('error', error);
                    }
                );
            },
            (error) => {
                this.logService.error('Facebook login', error);
            }
        );
    }

    async onAppleLoginOrRegistration(): Promise<void> {
        this.postMessageService
            .appleSignIn()
            .pipe(take(1))
            .subscribe((response) => {
                this.onAppleLoginOrRegistrationResult(response.result).then();
            });
    }

    async onAppleLoginOrRegistrationResult(identityToken: string): Promise<void> {
        (await this.authService.loginWithAppleForMobile(identityToken)).subscribe(
            (loggedInUser) => {
                this.loggedInUser = loggedInUser;
                this.postMessageService.setOneSignalEmail(this.loggedInUser.Email);
                this.postMessageService.setOneSignalTag(
                    'FirstName',
                    this.loggedInUser?.FirstName
                );
                this.postMessageService.setOneSignalTag(
                    'LastName',
                    this.loggedInUser?.LastName
                );

                this.sessionService.refreshSession().subscribe((sessionDto) => {
                    this.afterLoginNavigationTo(sessionDto);
                    this.presentRegistrationSuccessfully();
                    this.dataLayerGa4Service.datalayerUniversalPush(
                        GA4Category.authentication,
                        'login with Apple SignIn'
                    );
                });
                if (loggedInUser.ToSAccepted && loggedInUser.PrivacyAccepted)
                    void this.modal.dismiss(true);
            },
            (error) => {
                this.logService.error('Apple login', error);
            }
        );
    }

    submit(): void {
        this.submitRegisterForm = true;
        if (this.registrationForm.valid) {
            const {
                email,
                password,
                firstName,
                lastName,
                birthDate,
                aszf,
                privacy,
                marketingEmail,
                marketingSMS,
            } = this.registrationForm.value;

            const user: RegistrationUserDto = {
                Email: email,
                Password: password,
                FirstName: firstName,
                LastName: lastName,
                Birthdate: this.datePipe.transform(birthDate, 'yyyy-MM-dd'),
                Aszf: aszf,
                MarketingEmail: marketingEmail,
                MarketingSMS: marketingSMS,
                Privacy: privacy,
            };

            this.authService.registration(user).subscribe(
                (loggedInUser) => {
                    this.loggedInUser = loggedInUser;
                    this.postMessageService.setOneSignalEmail(this.loggedInUser.Email);
                    this.postMessageService.setOneSignalTag(
                        'FirstName',
                        this.loggedInUser?.FirstName
                    );
                    this.postMessageService.setOneSignalTag(
                        'LastName',
                        this.loggedInUser?.LastName
                    );

                    this.presentRegistrationSuccessfully();
                    if (loggedInUser.ToSAccepted && loggedInUser.PrivacyAccepted)
                        void this.modal.dismiss(true);

                    this.dataLayerGa4Service.datalayerUniversalPush(
                        GA4Category.registration,
                        'register with username and password',
                        'email',
                        email
                    );
                },
                (error) => {
                    this.translateService.get(error.error.ErrorTxt).subscribe((text) => {
                        this.snackBar.openErrorSnackBar(text);
                    });
                }
            );
        }
    }

    submitLoginForm(): void {
        this.loginForm.updateValueAndValidity();
        if (this.loginForm.valid) {
            const { email, password } = this.loginForm.value;

            this.rememberMe
                ? localStorage.setItem(
                      'remember',
                      btoa(JSON.stringify(this.loginForm.value))
                  )
                : localStorage.removeItem('remember');

            this.authService.login(email, password).subscribe(
                (loggedInUser) => {
                    this.loggedInUser = loggedInUser;

                    this.postMessageService.setOneSignalEmail(this.loggedInUser.Email);
                    this.postMessageService.setOneSignalTag(
                        'FirstName',
                        this.loggedInUser?.FirstName
                    );
                    this.postMessageService.setOneSignalTag(
                        'LastName',
                        this.loggedInUser?.LastName
                    );

                    this.sessionService.refreshSession().subscribe((sessionDto) => {
                        this.afterLoginNavigationTo(sessionDto);
                    });

                    if (loggedInUser.ToSAccepted && loggedInUser.PrivacyAccepted)
                        void this.modal.dismiss(true);

                    this.dataLayerGa4Service.datalayerUniversalPush(
                        GA4Category.authentication,
                        'login with username and password',
                        'email',
                        email
                    );
                },
                (loginError) => {
                    this.translateService
                        .get(loginError.error.ErrorTxt)
                        .subscribe((text) => {
                            this.snackBar.openErrorSnackBar(text);
                        });
                }
            );
        }
    }

    resendPassword(): void {
        if (!this.loginForm.get('email')?.value) {
            this.translateService
                .get('log-in.for-new-password-we-need-you-email')
                .subscribe((text) => {
                    this.snackBar.openErrorSnackBar(text);
                });

            return;
        }

        this.authService.resendPassword(this.loginForm.get('email')?.value).subscribe(
            (loginOK) => {
                if (loginOK) {
                    this.translateService
                        .get('log-in.new-password-todo')
                        .subscribe((text) => {
                            this.snackBar.openSuccessSnackBar(text);
                        });
                }
            },
            (loginError) => {
                this.translateService.get(loginError.error.ErrorTxt).subscribe((text) => {
                    this.snackBar.openErrorSnackBar(text);
                });
            }
        );
    }

    back(): void {
        void this.modal.dismiss(false);
    }

    afterLoginNavigationTo(sessionDto: ISessionDto): void {
        this.basketService
            .getGoToCheckout()
            .pipe(take(1))
            .subscribe((doIt: boolean) => {
                if (doIt) {
                    if (this.sessionService.isMobile) {
                        void this.router.navigate([
                            `checkout/${sessionDto.selectedShopCodes[0] ?? 'unknown'}`,
                        ]);
                    }
                } else {
                    if (this.sessionService.isMobile) {
                        void this.router.navigate(['/profile-options']);
                    }
                }

                if (this.loggedInUser.ToSAccepted && this.loggedInUser.PrivacyAccepted) {
                    this.sharedModalService.closeModals();
                }
            });
    }

    onTermsAccept(): void {
        this.submitTermsAcceptationForm = true;

        if (this.termsAcceptationForm.valid) {
            this.authService
                .acceptTerms(
                    this.termsAcceptationForm.controls.Aszf.value,
                    this.termsAcceptationForm.controls.Privacy.value,
                    this.termsAcceptationForm.controls.MarketingEmail.value,
                    this.termsAcceptationForm.controls.MarketingSMS.value
                )
                .subscribe(
                    (loggedInUser) => {
                        this.loggedInUser = loggedInUser;

                        if (loggedInUser.ToSAccepted && loggedInUser.PrivacyAccepted) {
                            this.modal.dismiss(true);
                        }
                    },
                    (loginError) => {
                        this.translateService
                            .get(loginError.error.ErrorTxt)
                            .subscribe((text) => {
                                this.snackBar.openErrorSnackBar(text);
                            });
                    }
                );
        }
    }

    togglePasswordVisibility(element: HTMLInputElement): void {
        element.type === 'text' ? (element.type = 'password') : (element.type = 'text');
    }

    private validateNotEqual(fieldControl: FormControl): any {
        return fieldControl.value !== this.registrationForm?.get('password')?.value
            ? { validateNotEqual: true }
            : null;
    }

    handleChangeRememberMe(element: EventTarget): void {
        this.rememberMe = (element as HTMLInputElement).checked;
        console.log(this.rememberMe);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.selectedTab === 1 && this.tabsComponent) {
                this.tabsComponent.tabs.map((item) => (item.active = !item.active));
            }
        }, 50);
    }

    handlePostMessageOpenUrl(event: Event): void {
        this.postMessageService.openUrl(event);
    }

    checkPopup(): void {
        this.isPopupBlocked = this.sessionService.isPopUpsBlocked();
    }

    private presentRegistrationSuccessfully(): void {
        this.translateService.get('common.succesful-login').subscribe(async (message) => {
            this.snackBar.openSuccessSnackBar(message);
        });
    }
}
