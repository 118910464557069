import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyFormattedPipe } from './currency-formatted.pipe';

@NgModule({
    declarations: [CurrencyFormattedPipe],
    imports: [CommonModule],
    exports: [CurrencyFormattedPipe],
})
export class SharedPipesModule {}
