import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import {
    AuthenticationService,
    BasketService,
    DataLayerGa4Service,
    FormatterService,
    GA4EventType,
    IMyOrder,
    IMyOrderItem,
    InvoiceType,
    IRealtimeClientService,
    ISnackBarService,
    LogService,
    MyOrderItemProductDto,
    OrderCommentDTO,
    OrderService,
    REALTIME_CLIENT_IMPL,
    ServiceBaseService,
    SessionService,
    SNACKBAR_SERVICE_IMPL,
    UserShared,
} from 'projects/library-shared/src/public-api';
import {
    faAngleRight,
    faEllipsisV,
    faShoppingCart,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';
import { tap } from 'rxjs/operators';
import { forkJoin, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { AddItemToOrderDialogComponent } from './add-item-to-order/add-item-to-order.component';
import { OrderState } from '../../../types/order-state';
import { OrderCustomerStatus } from '../../../types/ordercustomerstatus';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { AppSettings } from '../../helpers/appsettings';
import { DecimalPipe } from '@angular/common';
import { CreateDialogService } from '../../services/create-dialog.service';

@Component({
    selector: 'app-order-card',
    templateUrl: './order-card.component.html',
    styleUrls: ['./order-card.component.scss'],
    providers: [LogService],
})
export class OrderCardComponent implements OnInit {
    faAngleRight = faAngleRight;
    faSpinner = faSpinner;
    faCalendar = faCalendar;
    faShoppingCart = faShoppingCart;
    isSubmitted: boolean;
    siteImageUrl = '';
    faEllipsisV = faEllipsisV;

    massAddButtonText = '';
    @Output() onaddorreplace: EventEmitter<any> = new EventEmitter();
    @Input() public order: IMyOrder;
    @Input() public isOneOrderView = false;

    mobile: boolean;
    user: UserShared = new UserShared();
    myOrder: IMyOrder;
    products: MyOrderItemProductDto[];
    now = new Date();
    timeLeft: number;
    isOpen: boolean;

    formattedTimer: string;
    private timerEndDate: Date;
    private interval: NodeJS.Timeout;

    isDeliveryAvailableByPostCode = true;
    isPostCodeSetted = false;
    isProviderSelectedForShopping = false;

    private requestSub = new Subscription();

    @Output() onadd: EventEmitter<any> = new EventEmitter();

    constructor(
        @Inject(SNACKBAR_SERVICE_IMPL) private _snackBarService: ISnackBarService,
        private authService: AuthenticationService,
        private orderService: OrderService,
        private sessionService: SessionService,
        private createDialogService: CreateDialogService,
        private snackBar: MatSnackBar,
        private DataLayerGa4Service: DataLayerGa4Service,
        public basketService: BasketService,
        private log: LogService,
        private formatterService: FormatterService,
        public baseService: ServiceBaseService,
        private dialog: MatDialog,
        public translate: TranslateService,
        private numberPipe: DecimalPipe,
        @Inject(REALTIME_CLIENT_IMPL) private _realtimeClient: IRealtimeClientService
    ) {
        this.translate
            .get('common.order-basket-again')
            .subscribe((translation) => (this.massAddButtonText = translation));
    }

    ngOnInit(): void {
        this.siteImageUrl = this.baseService.getSiteImageUrl();
        this.sessionService.sessionSubject.subscribe((sessionSubject) => {
            this.isDeliveryAvailableByPostCode =
                sessionSubject?.isDeliveryAvailableByPostCode;
        });

        if (window.screen.width <= 360) {
            this.mobile = true;
        }
        this.user = this.authService.getLoggedInUser();

        this.myOrder = this.order;
        this.processOrder();
        this.updateTimerState();

        if (this.isOneOrderView) {
            this._realtimeClient.initHubConnection(
                'collectinghub',
                AppSettings.get('signalr')
            );
            this.establishRealTimeConnections();
        }
        this.recalcFinalPriceIfStored();
    }

    ngOnDestroy() {
        this._realtimeClient.disconnect();
    }

    get OrderCustomerStatusStr(): string {
        return OrderCustomerStatus[this.myOrder.OrderCustomerStatus];
    }

    get deliveryFinalPrice(): string {
        return this.formatterService.formatCurrency(this.myOrder.TotalPrice).toString();
    }

    get finalPrice(): number {
        let value = 0;

        if (this.myOrder.OrderCustomerStatus === OrderCustomerStatus.STORED) {
            value = this.myOrder.FinalPriceValueIfStored;
        } else if (this.myOrder.OrderCustomerStatus === OrderCustomerStatus.COLLECTING) {
            this.myOrder.CollectedItems.forEach((itm) => {
                if (itm.OrderReplacementItem != null)
                    value += itm.OrderReplacementItem.Price;
                else value += itm.Price;
            });
            this.myOrder.UnCollectedItems.forEach((itm) => {
                if (!itm.IsRemovedByCustomer) value += itm.Price;
            });
            this.myOrder.ReplacementItems.forEach((itm) => {
                if (itm.OrderReplacementItem != null)
                    value += itm.OrderReplacementItem.Price;
                else value += itm.Price;
            });
        } else {
            value = this.myOrder.CustomerPaidTotalPrice;
        }

        if (value == 0) {
            return value;
        }

        return value + this.myOrder.DeliveryFee;
    }

    recalcFinalPriceIfStored() {
        this.myOrder.FinalPriceValueIfStored = 0;
        if (this.myOrder.OrderCustomerStatus === OrderCustomerStatus.STORED) {
            let difference = 0;
            this.myOrder.OrderedItems.forEach((item) => {
                if (item.IsNew || item.IsRemovedByCustomer) {
                    const priceAct =
                        item.OrderReplacementItem != null
                            ? item.OrderReplacementItem.Price
                            : item.Price;
                    if (
                        item.IsRemovedByCustomer ||
                        item.IsMissing ||
                        (item.OrderRemovedItemID != null &&
                            item.OrderReplacementItem == null)
                    ) {
                        difference -= priceAct;
                    } else {
                        difference += priceAct;
                    }
                }
            });

            this.orderService
                .getOrderTotalPrice(this.myOrder.OrderId, difference)
                .subscribe(
                    (res) => (this.myOrder.FinalPriceValueIfStored = res as number)
                );
        }
    }

    startTimer() {
        this.interval ??= setInterval(() => {
            if (this.timeLeft > 0) {
                this.timeLeft = Math.round(
                    (this.timerEndDate?.valueOf() - Date.now()) / 1000
                );
                this.formattedTimer = this.transformToMMSSFormat(this.timeLeft);
            } else {
                clearInterval(this.interval);
                this.timeLeft = 0;
                this.timeoutFinished();
            }
        }, 1000);
    }

    timeoutFinished() {
        if (this.myOrder.OnlineConsultation) {
            this._realtimeClient.invoke(
                'timeoutfinished',
                this.myOrder.OrderId.toString()
            );

            this.myOrder.ReplacementItems.forEach((replaceitem) => {
                if (replaceitem.OrderReplacementItem?.QuantityRequestedByUser === null) {
                    this.orderService
                        .getOrderItemDTO(replaceitem.OrderItemID)
                        .subscribe((res) => {
                            if (res) {
                                const item = res as IMyOrderItem;
                                item.IsRemovedByCustomer = true;
                                item.CollectedQuantity = 0;
                                item.OrderReplacementItem.QuantityRequestedByUser = 0;
                                item.IsMissing = true;

                                this.myOrder.MissingItems =
                                    this.myOrder.MissingItems.concat(replaceitem);
                                this.myOrder.ReplacementItems =
                                    this.myOrder.ReplacementItems.filter(
                                        (i) => i.OrderItemID !== item.OrderItemID
                                    );
                            }
                        });
                }
            });
        }
    }

    updateTimerState() {
        this.orderService
            .getTimeLeftToModifyOrder(this.myOrder.OrderCode)
            .subscribe((endDate) => {
                if (endDate) {
                    this.timerEndDate = new Date(endDate);
                }
                if (this.timeLeft === undefined && this.timerEndDate) {
                    this.timeLeft = Math.round(
                        (this.timerEndDate?.valueOf() - Date.now()) / 1000
                    );
                }

                if (this.timerEndDate > new Date()) {
                    this.startTimer();
                }
            });
    }

    transformToMMSSFormat(value: number): string {
        const minutes: number = Math.floor(value / 60);
        const seconds = value - minutes * 60;

        const formattedMinutes = this.numberPipe.transform(minutes, '2.0');
        const formattedSeconds = this.numberPipe.transform(seconds, '2.0');
        return `${formattedMinutes}:${formattedSeconds}`.concat(
            '.'.repeat((seconds % 3) + 1)
        );
    }

    get realTimeIsOn(): boolean {
        return (
            (this.myOrder.OrderCustomerStatus === OrderCustomerStatus.COLLECTING &&
                Date.now() < this.timerEndDate?.valueOf()) ||
            (this.myOrder.OrderCustomerStatus === OrderCustomerStatus.COLLECTING &&
                !this.timerEndDate)
        );
    }

    private establishRealTimeConnections(): void {
        this._realtimeClient.connect().then(() => {
            this._realtimeClient.on('RefreshOrder', () => {
                this.orderService
                    .getOrderForUser(this.order.OrderCode)
                    .subscribe((order) => {
                        this.myOrder = order;
                        this.processOrder();
                    });
            });

            this._realtimeClient.invoke('AddToGroup', this.myOrder.OrderId.toString());

            this._realtimeClient.on('orderitemcollected', (response) => {
                if (this.myOrder.OrderId.toString() === response[0]) {
                    const element = this.myOrder.UnCollectedItems.find(
                        (item) => item.OrderItemID === response[1]
                    );

                    this.orderService
                        .getOrderItemDTO(response[1])
                        .subscribe((orderItem) => {
                            if (orderItem) {
                                const item = orderItem as IMyOrderItem;
                                item.IsShown = true;
                                item.IsNew = element?.IsNew ?? true;
                                this.myOrder.CollectedItems.push(item);
                                this.myOrder.UnCollectedItems =
                                    this.myOrder.UnCollectedItems.filter(
                                        (i) => i.OrderItemID !== item.OrderItemID
                                    );
                            }
                        });
                }
            });

            this._realtimeClient.on('allreplacementok', (response) => {
                if (response[0] === this.myOrder.OrderId) {
                    this.orderService
                        .getOrderCustomerPaidTotalPrice(this.myOrder.OrderId)
                        .subscribe((res) => {
                            this.myOrder.CustomerPaidTotalPrice = res as number;
                            this.timeLeft = 0;
                        });
                }
            });

            this._realtimeClient.on('orderitemmissing', (response) => {
                if (this.myOrder.OrderId.toString() === response[0]) {
                    let targetIndex = -1;

                    this.myOrder.UnCollectedItems.forEach((element, index) => {
                        if (element.OrderItemID === response[1]) {
                            element.IsMissing = true;
                            this.myOrder.MissingItems =
                                this.myOrder.MissingItems.concat(element);
                            targetIndex = index;
                        }
                    });

                    this.myOrder.UnCollectedItems.splice(targetIndex, 1);
                }
            });

            this._realtimeClient.on('orderitemreplacement', (response) => {
                if (this.myOrder.OrderId.toString() === response[0]) {
                    this.myOrder.UnCollectedItems = this.myOrder.UnCollectedItems.filter(
                        (item) => item.OrderItemID !== response[1]
                    );

                    this.orderService.getOrderItemDTO(response[1]).subscribe((res) => {
                        if (res) {
                            const item = res as IMyOrderItem;
                            item.IsShown = true;
                            item.IsNew = true;
                            this.myOrder.ReplacementItems =
                                this.myOrder.ReplacementItems.concat(item);
                        }
                    });
                }
            });

            this._realtimeClient.on('remorderitemcollected', (response) => {
                if (this.myOrder.OrderId.toString() === response[0]) {
                    this.myOrder.CollectedItems.forEach((element) => {
                        if (element.OrderItemID === response[1]) {
                            this.orderService
                                .getOrderItemDTO(element.OrderItemID)
                                .subscribe((res) => {
                                    if (res) {
                                        const item = res as IMyOrderItem;
                                        item.IsShown = true;
                                        item.IsNew = element.IsNew;
                                        item.OrderReplacementItem = null;
                                        item.CollectedQuantity = 0;
                                        this.myOrder.CollectedItems =
                                            this.myOrder.CollectedItems.filter(
                                                (i) => i.OrderItemID !== item.OrderItemID
                                            );
                                        this.myOrder.UnCollectedItems =
                                            this.myOrder.UnCollectedItems.concat(item);
                                    }
                                });
                        }
                    });
                }
            });

            this._realtimeClient.on('remorderitemmissing', (response) => {
                if (this.myOrder.OrderId.toString() === response[0]) {
                    let targetIndex = -1;

                    this.myOrder.MissingItems.forEach((element, index) => {
                        if (element.OrderItemID === response[1]) {
                            element.IsMissing = false;
                            targetIndex = index;

                            this.myOrder.MissingItems.splice(targetIndex, 1);

                            this.orderService
                                .getOrderItemDTO(response[1])
                                .subscribe((res) => {
                                    if (res) {
                                        const item = res as IMyOrderItem;
                                        item.IsShown = true;
                                        item.IsNew = element.IsNew;
                                        this.myOrder.UnCollectedItems =
                                            this.myOrder.UnCollectedItems.concat(item);
                                    }
                                });
                        }
                    });
                }
            });

            this._realtimeClient.on('remorderitemreplacement', (response) => {
                if (this.myOrder.OrderId.toString() === response[0]) {
                    let targetIndex = -1;

                    this.myOrder.ReplacementItems.forEach((element, index) => {
                        if (element.OrderItemID === response[1]) {
                            this.orderService
                                .getOrderItemDTO(response[1])
                                .subscribe((res) => {
                                    if (res) {
                                        const item = res as IMyOrderItem;
                                        item.IsShown = true;
                                        item.IsNew = element.IsNew;
                                        this.myOrder.UnCollectedItems =
                                            this.myOrder.UnCollectedItems.concat(item);
                                        targetIndex = index;
                                    }
                                });
                        }
                    });

                    this.myOrder.ReplacementItems.splice(targetIndex, 1);
                }
            });

            this._realtimeClient.on('checkoutclicked', () => {
                this.updateTimerState();
            });
        });

        this._realtimeClient.on('newitemadded', (response) => {
            if (this.myOrder.OrderId.toString() === response[2]) {
                this.orderService.getOrderItemDTO(response[0]).subscribe((res) => {
                    if (res) {
                        const item = res as IMyOrderItem;
                        item.IsShown = true;
                        item.IsNew = true;

                        if (!response[1]) {
                            this.myOrder.UnCollectedItems =
                                this.myOrder.UnCollectedItems.concat(item);
                        } else {
                            this.myOrder.CollectedItems =
                                this.myOrder.CollectedItems.concat(item);
                        }
                    }
                });
            }
        });
    }

    private createInvocieLists(order: IMyOrder) {
        if (Array.isArray(order.ProviderInvoices) && order.ProviderInvoices.length) {
            order.ModifyInvoices = order.ProviderInvoices.map((invoice) => {
                if (
                    invoice.InvoiceType === InvoiceType.Modifier &&
                    Boolean(invoice.Url)
                ) {
                    return invoice.Url;
                }
            });
            order.ProductInvoices = order.ProviderInvoices.map((invoice) => {
                if (invoice.InvoiceType === InvoiceType.Final && Boolean(invoice.Url)) {
                    return invoice.Url;
                }
            });
            order.DeliveryInvoices = order.ProviderInvoices.map((invoice) => {
                if (
                    invoice.InvoiceType === InvoiceType.DeliveryFinal &&
                    Boolean(invoice.Url)
                ) {
                    return invoice.Url;
                }
            });
        }
    }

    private calcOrderItems(order: IMyOrder) {
        if (order.OrderCustomerStatus === OrderCustomerStatus.COLLECTING) {
            order.MissingItems = [];
            order.ReplacementItems = [];
            order.CollectedItems = [];
            order.UnCollectedItems = [];

            order.OrderedItems.forEach((item) => {
                if (
                    item.IsMissing ||
                    (item.OrderReplacementItem !== null &&
                        item.OrderReplacementItem.QuantityRequestedByUser === 0)
                ) {
                    order.MissingItems.push(item);
                } else if (
                    item.OrderReplacementItem !== null &&
                    item.OrderReplacementItem.QuantityRequestedByUser === null
                ) {
                    order.ReplacementItems.push(item);
                } else if (
                    (item.OrderReplacementItem !== null &&
                        item.OrderReplacementItem.QuantityRequestedByUser > 0) ||
                    (item.OrderReplacementItem === null && item.CollectedQuantity > 0)
                ) {
                    order.CollectedItems.push(item);
                } else order.UnCollectedItems.push(item);
            });
        }
    }

    onAddAll(order: IMyOrder) {
        let canAddToCartByPostCode = false;
        let canAddToCartByShopSelected = false;

        if (
            this.isDeliveryAvailableByPostCode &&
            (!this.sessionService.sessionSubject.value.zipCode ||
                !this.sessionService.sessionSubject.value.isValidZip ||
                this.sessionService.sessionSubject.value.zipCode.trim() == '')
        ) {
            this.createDialogService
                .openAddressDialog()
                .afterClosed()
                .subscribe(() => {
                    if (!this.isPostCodeSetted) {
                        this.translate
                            .get('messages.add-your-postcode-b4-add-to-basket')
                            .subscribe((text) => {
                                this.snackBar.open(text, null, {
                                    verticalPosition: 'top',
                                    horizontalPosition: 'center',
                                    duration: 3000,
                                });
                                return;
                            });
                    } else {
                        canAddToCartByPostCode = true;
                    }
                });
        } else {
            canAddToCartByPostCode = true;
        }
        if (!canAddToCartByPostCode) {
            return;
        }

        if (
            this.isDeliveryAvailableByPostCode &&
            this.isPostCodeSetted &&
            !this.isProviderSelectedForShopping
        ) {
            this.createDialogService
                .openShopSelectDialogSeparatedComponent()
                .afterClosed()
                .subscribe(() => {
                    if (!this.isProviderSelectedForShopping) {
                        this.translate
                            .get('messages.select-shop-b4-add-to-basket')
                            .subscribe((text) => {
                                this.snackBar.open(text, null, {
                                    verticalPosition: 'top',
                                    horizontalPosition: 'center',
                                    duration: 3000,
                                });
                                return;
                            });
                    } else {
                        canAddToCartByShopSelected = true;
                    }
                });
        } else {
            canAddToCartByShopSelected = true;
        }
        if (!canAddToCartByShopSelected) {
            return;
        }

        if (!order.AddIsInProgress) {
            order.AddIsInProgress = true;

            const requests = [];
            for (const orderItem of order.OrderedItems) {
                let price: number;
                if (orderItem.Product.isBulk) {
                    price = orderItem.Product.minUnitPrice;
                } else {
                    price = orderItem.Product.price;
                }

                if (typeof price === 'undefined') {
                    price = orderItem.Product.minPrice;
                }
                if (orderItem.Product) {
                    this.DataLayerGa4Service.eCommerceItem(
                        {
                            isBulk: orderItem.Product.isBulk,
                            productName: orderItem.ProductName,
                            productID: orderItem.ProductID,
                            quantity: orderItem.Quantity,
                            unitPrice: orderItem.UnitPrice,
                            price: orderItem.Price,
                            item_category:
                                orderItem.Product.category?.categoryName ?? null,
                            item_brand: orderItem.Product.brand,
                        },
                        GA4EventType.view_item
                    );

                    requests.push(
                        this.basketService
                            .addToMasterBasket(
                                orderItem.Product.productID,
                                orderItem.Quantity
                            )
                            .pipe(
                                tap(
                                    () => {
                                        this.onadd.emit();
                                    },
                                    (err) => {
                                        this.log.error('Adding Product to basket', err);
                                    }
                                )
                            )
                    );
                }
            }

            if (requests?.length < 1) {
                order.AddIsInProgress = false;
                this.translate.get('messages.all-products-in-basket').subscribe((text) =>
                    this.snackBar.open(text, null, {
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                        duration: 3000,
                    })
                );
            }

            const requestsObs = forkJoin(requests);

            this.requestSub = requestsObs.subscribe(() => {
                order.AddIsInProgress = false;
                this.translate.get('messages.all-products-in-basket').subscribe((text) =>
                    this.snackBar.open(text, null, {
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                        duration: 3000,
                    })
                );
            });
        }
    }

    get addBtnAvailable(): boolean {
        const totalPriceValid = this.orderService.totalPriceValidator(
            this.finalPrice,
            this.myOrder.DeliveryFee,
            this.myOrder.TotalPrice
        );
        if (totalPriceValid) {
            if (this.myOrder.PayOk) return false;
            if (
                this.myOrder.PhoneConsultation &&
                this.myOrder.OrderStateID === OrderState.CollectingInProgress
            )
                return false;

            if (this.myOrder.OrderCustomerStatus === OrderCustomerStatus.STORED)
                return true;

            if (this.myOrder.OrderStateID !== OrderState.CollectingInProgress)
                return false;

            if (
                !this.myOrder.OnlineConsultation &&
                this.myOrder.OrderStateID === OrderState.CollectingInProgress
            )
                return false;

            if (this.timeLeft || this.timeLeft === 0) return false;

            const missingItemsCount = this.myOrder.OrderedItems.filter(
                (item) => item.IsMissing === true
            ).length;

            const newItemCount = this.myOrder.OrderedItems.filter(
                (item) => item.IsNew === true
            ).length;

            return newItemCount < missingItemsCount + 2;
        } else {
            return false;
        }
    }

    onAddItem() {
        if (this.addBtnAvailable == false) return;

        this.dialog
            .open(AddItemToOrderDialogComponent, {
                maxWidth: '95vw',
                minWidth: '300px',
                maxHeight: '85vh',
                position: { top: '10vh' },
                data: { order: this.myOrder },
            })
            .afterClosed()
            .subscribe(
                (res) => {
                    if (res) {
                        res as IMyOrderItem;
                        res.IsNew = true;
                        res.IsShown = true;
                        this.myOrder.OrderedItems.push(res);
                        if (this.myOrder.UnCollectedItems != null)
                            this.myOrder.UnCollectedItems.push(res);

                        this.onaddorreplace.emit();

                        this.recalcFinalPriceIfStored();

                        this._realtimeClient.invoke(
                            'newitemmessagesendroksh',
                            'UserID',
                            res.OrderItemID,
                            this.myOrder.OrderId
                        );
                    }
                },
                (error) => console.log(error)
            );
    }

    orderCancellation(order: IMyOrder) {
        if (
            order.LastPossibleCancellationDate > new Date() ||
            order.OrderStateID === OrderState.CollectingInProgress
        ) {
            this.translate.get('common.confirm-cancel-order').subscribe((title) => {
                this.createDialogService
                    .openConfirmCancellationDialog(title)
                    .afterClosed()
                    .subscribe((data) => {
                        if (data) {
                            this.orderService
                                .cancelOrder(order.OrderCode)
                                .subscribe(() => {
                                    if (this.myOrder.OrderCode == order.OrderCode) {
                                        this.myOrder.IsModifyDeliveryAndAddress = false;
                                        this.myOrder.AvailableForCancellation = false;
                                        this.myOrder.IsModifyCommentAndContact = false;
                                        this.myOrder.IsShowModifyDeliveryTimeSlotButton =
                                            false;
                                        this.myOrder.IsShowCancelOrderButton = false;
                                        this.myOrder.OrderCustomerStatus =
                                            OrderCustomerStatus.DELETED;
                                        this.orderService.setCanceledOrderSource(
                                            this.myOrder.OrderCode
                                        );

                                        this._realtimeClient.invoke(
                                            'ordercancelled',
                                            this.myOrder.OrderId
                                        );
                                    }
                                });
                        }
                    });
            });
        } else {
            this.translate
                .get('error.cancel-order-last-time')
                .subscribe((text) => this._snackBarService.openSnackBar(text));

            if (this.myOrder.OrderCode == order.OrderCode)
                order.IsShowCancelOrderButton = false;
        }
    }

    onMenuClickEditContactDetails(order: IMyOrder) {
        this.createDialogService
            .openOrderContactDetailsDialog(order)
            .afterClosed()
            .subscribe((data) => {
                if (data) {
                    data as IMyOrder;
                    if (this.myOrder.OrderCode === order.OrderCode) {
                        this.myOrder.OrdererEmail = data.OrdererEmail;
                        this.myOrder.OrdererName = data.OrdererName;
                        this.myOrder.OrdererPhone = data.OrdererPhone;
                        this.translate.get('common.success-save').subscribe((text) =>
                            this.snackBar.open(text, null, {
                                verticalPosition: 'top',
                                horizontalPosition: 'center',
                                duration: 5000,
                                panelClass: ['success-snackbar'],
                            })
                        );
                    }
                }
            });
    }

    onMenuClickDeleteComment(orderCode: string) {
        this.translate.get('common.cancel-comment').subscribe((title) => {
            this.createDialogService
                .openConfirmCancellationDialog(title)
                .afterClosed()
                .subscribe((data) => {
                    if (data) {
                        const dto: OrderCommentDTO = {
                            OrderCode: orderCode,
                            Comment: '',
                        };
                        this.orderService.modifyOrderComment(dto).subscribe((data) => {
                            if (data) {
                                data as IMyOrder;
                                if (this.myOrder.OrderCode === orderCode) {
                                    this.myOrder.Comment = data.Comment;
                                    this.translate
                                        .get('common.success-save')
                                        .subscribe((text) =>
                                            this.snackBar.open(text, null, {
                                                verticalPosition: 'top',
                                                horizontalPosition: 'center',
                                                duration: 5000,
                                                panelClass: ['success-snackbar'],
                                            })
                                        );
                                }
                            }
                        });
                    }
                });
        });
    }

    onMenuClickEditComment(orderCode: string, comment: string) {
        this.createDialogService
            .openOrderCommentDialog(orderCode, comment)
            .afterClosed()
            .subscribe((data) => {
                if (data) {
                    data as IMyOrder;
                    if (this.myOrder.OrderCode === orderCode) {
                        this.myOrder.Comment = data.Comment;
                        this.translate.get('common.success-save').subscribe((text) =>
                            this.snackBar.open(text, null, {
                                verticalPosition: 'top',
                                horizontalPosition: 'center',
                                duration: 5000,
                                panelClass: ['success-snackbar'],
                            })
                        );
                    }
                }
            });
    }

    onClickModifyTimeSlot(order: IMyOrder) {
        this.createDialogService
            .openOrderTimeSlotsDialog(order.ProviderID, order)
            .afterClosed()
            .subscribe((data) => {
                if (data) {
                    data as IMyOrder;
                    if (this.myOrder.OrderCode === order.OrderCode) {
                        this.myOrder.DeliveryDay = data.DeliveryDay;
                        this.myOrder.DeliveryTimeSlot = data.DeliveryTimeSlot;
                        this.myOrder.DeliveryFee = data.DeliveryFee;
                        this.translate.get('common.success-save').subscribe((text) =>
                            this.snackBar.open(text, null, {
                                verticalPosition: 'top',
                                horizontalPosition: 'center',
                                duration: 5000,
                                panelClass: ['success-snackbar'],
                            })
                        );
                    }
                }
            });
    }

    onClickModifyDelveryAddressAndTimeSlot(order: IMyOrder) {
        this.createDialogService
            .openOrderDeliveryAddressDialog(order.ProviderID, order)
            .afterClosed()
            .subscribe((data) => {
                if (data) {
                    data as IMyOrder;
                    if (this.myOrder.OrderCode === order.OrderCode) {
                        this.myOrder.DeliveryDay = data.DeliveryDay;
                        this.myOrder.DeliveryTimeSlot = data.DeliveryTimeSlot;
                        this.myOrder.DeliveryAddressPostalCode =
                            data.DeliveryAddressPostalCode;
                        this.myOrder.DeliveryAddressSummary = data.DeliveryAddressSummary;
                        this.myOrder.DeliveryFee = data.DeliveryFee;

                        this.translate.get('common.success-save').subscribe((text) =>
                            this.snackBar.open(text, null, {
                                verticalPosition: 'top',
                                horizontalPosition: 'center',
                                duration: 5000,
                                panelClass: ['success-snackbar'],
                            })
                        );
                    }
                }
            });
    }

    approveOrder() {
        this.timeLeft = 0;
        this._realtimeClient.invoke(
            'AllReplacementOkMessageSendRoksh',
            this.myOrder.OrderId
        );
    }

    getDiscountPrice() {
        return this.myOrder.TotalPriceDiscount + this.myOrder.RokshDiscountTotalPrice;
    }

    private processOrder() {
        this.myOrder.Products = this.myOrder.OrderedItems.map((oItem) => {
            if (oItem.Product) {
                return oItem.Product;
            }
        });

        this.calcOrderItems(this.myOrder);
        this.createInvocieLists(this.myOrder);

        this.myOrder.OrderedItems = this.myOrder.OrderedItems.filter(function (item) {
            return item.Product != null;
        });

        this.myOrder.Products = this.myOrder.Products.filter(function (prod) {
            return prod != null;
        });

        this.myOrder.ProductInvoices = this.myOrder.ProductInvoices?.filter(function (
            prod
        ) {
            return prod != null;
        });

        this.myOrder.DeliveryInvoices = this.myOrder.DeliveryInvoices?.filter(function (
            prod
        ) {
            return prod != null;
        });

        this.myOrder.ModifyInvoices = this.myOrder.ModifyInvoices?.filter(function (
            prod
        ) {
            return prod != null;
        });

        this.myOrder.LastPossibleCancellationDate = new Date(this.myOrder.DeliveryDate);
        this.myOrder.LastPossibleCancellationDate.setDate(
            this.myOrder.LastPossibleCancellationDate.getDate() - 1
        );
        this.myOrder.LastPossibleCancellationDate.setHours(20);
        this.myOrder.LastPossibleCancellationDate.setMinutes(0);
        this.myOrder.LastPossibleCancellationDate.setMilliseconds(0);

        this.myOrder.IsShowCancelOrderButton =
            this.myOrder.LastPossibleCancellationDate > this.now &&
            this.myOrder.AvailableForCancellation;

        this.myOrder.IsShowModifyDeliveryTimeSlotButton =
            this.myOrder.OrderStateID == OrderState.CollectingIsNotAssigned ||
            this.myOrder.OrderStateID == OrderState.CollectingIsWaitingForAccept;
    }
}
