<div class="info-container px-3 px-md-5">
    <div class="form-container pt-4">
        <h3 class="pb-3 pt-3">
            {{ 'profile.personal-data' | translate }}
        </h3>
        <form class="w-100" [formGroup]="profileForm" (ngSubmit)="onSaveProfile()">
            <mat-form-field class="w-100">
                <input
                    formControlName="FirstName"
                    matInput
                    placeholder=" {{ 'common.firstname' | translate }}"
                />
                <mat-error
                    *ngIf="
                        profileForm.get('FirstName').errors?.required &&
                        (profileForm.get('FirstName').touched || isSubmitted)
                    "
                    >{{ 'log-in.firstname-required' | translate }}</mat-error
                >
            </mat-form-field>

            <br />

            <mat-form-field class="w-100">
                <input
                    formControlName="LastName"
                    matInput
                    placeholder=" {{ 'common.lastname' | translate }}"
                />
                <mat-error
                    *ngIf="
                        profileForm.get('LastName').errors?.required &&
                        (profileForm.get('LastName').touched || isSubmitted)
                    "
                    >{{ 'log-in.lastname-required' | translate }}</mat-error
                >
            </mat-form-field>

            <br />

            <mat-form-field class="w-100">
                <input
                    formControlName="Birthdate"
                    placeholder="{{ 'common.birthdate' | translate }}"
                    matInput
                    [matDatepicker]="picker"
                    readonly
                    (focus)="picker.open()"
                    (click)="picker.open()"
                    [max]="maxBirthdate"
                />
                <mat-datepicker #picker></mat-datepicker>
                <mat-error
                    *ngIf="
                        profileForm.get('Birthdate').errors?.required &&
                        (profileForm.get('Birthdate').touched || isSubmitted)
                    "
                >
                    {{ 'log-in.birthdate-required' | translate }}
                </mat-error>
            </mat-form-field>

            <br />

            <div class="col-12 phone-container" [formGroup]="phoneForm">
                <div class="wrapper">
                    <mat-label class="custom-label phone-label">{{
                        'checkout.phone-number' | translate
                    }}</mat-label>
                    <ngx-intl-tel-input
                        [cssClass]="
                            'form-control tel-input ' +
                            (phoneForm.controls.phone.errors ? 'tel-input-invalid' : '')
                        "
                        id="tel"
                        [enableAutoCountrySelect]="true"
                        [preferredCountries]="preferredCountries"
                        [enablePlaceholder]="true"
                        [searchCountryFlag]="true"
                        [searchCountryField]="[
                            SearchCountryField.Iso2,
                            SearchCountryField.Name
                        ]"
                        [selectFirstCountry]="false"
                        [selectedCountryISO]="preferredCountries[0]"
                        [maxLength]="15"
                        [phoneValidation]="true"
                        [separateDialCode]="false"
                        [numberFormat]="PhoneNumberFormat.International"
                        formControlName="phone"
                        (ngModelChange)="onPhoneChanges()"
                    >
                    </ngx-intl-tel-input>
                </div>
                <small
                    class="text-danger mt-1 ps-2"
                    *ngIf="
                        phoneForm.controls.phone.errors &&
                        phoneForm.controls.phone.touched &&
                        phoneForm.controls.phone.errors?.validatePhoneNumber?.valid !==
                            false
                    "
                >
                    {{ 'common.phone-number-required' | translate }}
                </small>
                <small
                    class="text-danger mt-1 ps-2"
                    *ngIf="
                        phoneForm.controls.phone.errors?.validatePhoneNumber?.valid ===
                        false
                    "
                >
                    {{ 'application.invalid-phone-number' | translate }}
                </small>
            </div>
            <div>
                <small> * {{ 'common.required' | translate }} </small>
            </div>
            <div class="row">
                <div class="col-6">
                    <button
                        id="btnLDoSave1"
                        [disabled]="!profileForm.valid"
                        type="submit"
                        class="btn btn-success w-100 mt-2"
                    >
                        {{ 'common.save' | translate }}
                    </button>
                </div>

                <div class="col-6">
                    <a
                        id="btnLDoCancel1"
                        [routerLink]="'/'"
                        class="btn btn-outline-success w-100 mt-2"
                    >
                        {{ 'common.cancel' | translate }}
                    </a>
                </div>
            </div>
        </form>
        <br />
        <br />
        <h3 class="pb-3">
            {{ 'password.modify-password' | translate }}
        </h3>
        <form class="w-100" [formGroup]="passwordForm" (ngSubmit)="onSavePassword()">
            <mat-form-field class="w-100">
                <input
                    formControlName="OldPassword"
                    type="password"
                    matInput
                    placeholder="{{ 'password.old-password' | translate }}"
                />
                <mat-error
                    *ngIf="
                        passwordForm.get('OldPassword').errors?.required &&
                        (passwordForm.get('OldPassword').touched || isSubmitted)
                    "
                    >{{ 'log-in.password-required' | translate }}</mat-error
                >
            </mat-form-field>
            <br />

            <mat-form-field class="w-100">
                <input
                    formControlName="Password"
                    type="password"
                    matInput
                    placeholder="{{ 'password.new-password' | translate }}"
                />
                <mat-error
                    *ngIf="
                        passwordForm.get('Password').errors?.errorSpecialCharacter &&
                        (passwordForm.get('Password').touched || isSubmitted)
                    "
                    >{{ 'password.fobidden-special-character' | translate }}</mat-error
                >
                <mat-error
                    *ngIf="
                        passwordForm.get('Password').errors?.pattern &&
                        (passwordForm.get('Password').touched || isSubmitted)
                    "
                    >{{ 'log-in.password-policiy-message' | translate }}</mat-error
                >
                <mat-error
                    *ngIf="
                        passwordForm.get('Password').errors?.required &&
                        (passwordForm.get('Password').touched || isSubmitted)
                    "
                    >{{ 'log-in.password-required' | translate }}</mat-error
                >
            </mat-form-field>
            <br />

            <mat-form-field class="w-100">
                <input
                    formControlName="ConfirmPassword"
                    type="password"
                    matInput
                    placeholder="{{ 'password.new-password-again' | translate }}"
                />
                <mat-error
                    *ngIf="
                        passwordForm.get('ConfirmPassword').errors?.notSame &&
                        passwordForm.get('ConfirmPassword').touched
                    "
                    >{{ 'log-in.password-does-not-match' | translate }}</mat-error
                >
                <mat-error
                    *ngIf="
                        passwordForm.get('ConfirmPassword').errors?.required &&
                        (passwordForm.get('ConfirmPassword').touched || isSubmitted)
                    "
                    >{{ 'log-in.password-required' | translate }}</mat-error
                >
            </mat-form-field>
            <div>
                <small> * {{ 'common.required' | translate }} </small>
            </div>
            <div class="row">
                <div class="col-6">
                    <button
                        id="btnLDoSave"
                        [disabled]="!passwordForm.valid"
                        type="submit"
                        class="btn btn-success w-100 mt-2"
                    >
                        {{ 'common.save' | translate }}
                    </button>
                </div>

                <div class="col-6">
                    <a
                        id="btnLDoCancel"
                        [routerLink]="'/'"
                        class="btn btn-outline-success w-100 mt-2"
                    >
                        {{ 'common.cancel' | translate }}
                    </a>
                </div>
            </div>
        </form>

        <br />
        <br />
        <br />
        <div>
            {{ 'profile.delete-account-message' | translate }}
        </div>

        <div class="row">
            <div class="col-12 col-md-6 col-lg-5" style="margin: 0 auto">
                <button
                    id="btnDelprof"
                    class="btn btn-danger w-100 mt-2"
                    type="button"
                    (click)="onDeleteProfile()"
                >
                    {{ 'profile.delete-account' | translate }}
                </button>
            </div>
        </div>

        <br />
        <br />
        <br />
    </div>
</div>
