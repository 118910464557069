import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { tap } from 'rxjs/operators';
import { LogService } from '../services/log.service';
import { BaseLogService } from '../services/base-log.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    logService: LogService;

    constructor(baseLog: BaseLogService) {
        this.logService = new LogService(baseLog);
        this.logService.mainClass = 'AuthInterceptor';
    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authToken = this.getToken();
        let authRequest = req;

        if (authToken) {
            authRequest = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + authToken),
            });
        }

        return next.handle(authRequest).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    const ev = event as HttpResponse<any>;
                    const token = ev.headers.get('JWT-Auth');
                    if (token) {
                        this.setToken(token);
                    }
                }
                return event;
            })
        );
    }

    private getToken() {
        const jwtToken = localStorage.getItem('jwt-token');
        if (jwtToken) {
            return jwtToken;
        }

        return null;
    }

    private setToken(t: string) {
        this.logService.info('set JWT:', t);
        localStorage.setItem('jwt-token', t);
        window.location.reload();
    }
}
