<div class="d-none d-md-block btn-float-container pointer position-relative">
    <div
        class="btn pointer btn-float"
        [ngClass]="{
            'has-category-menu-floater': isCategoryMenuDisplayed || isUserMenuDisplayed
        }"
        (click)="onClickFloat()"
    >
        {{ 'footer.contact-faq-other' | translate }}
        <fa-icon
            [icon]="faInfoCircle"
            [styles]="{ color: '#3B3B3B' }"
            size="1x"
            class="me-2"
        ></fa-icon>
    </div>
</div>

<div
    class="footer-bg d-none d-md-block"
    [ngClass]="{
        'has-category-menu': isCategoryMenuDisplayed || isUserMenuDisplayed
    }"
    [class]="footerClassName"
>
    <div class="footer position-relative">
        <div class="row">
            <div [class]="!isFooterFloating ? 'd-none' : ''" class="close-footer pointer">
                <p (click)="onClickFloat()">
                    <fa-icon
                        [icon]="faMinusSquare"
                        [styles]="{ color: '#3B3B3B' }"
                        size="1x"
                        class="me-2"
                    ></fa-icon>

                    <!-- Bezár -->
                </p>
            </div>

            <div class="col-2">
                <img
                    alt="footer roksh logo"
                    class="logo pointer"
                    src="../../../assets/svg/footer-logo.svg"
                    [routerLink]="['router.dashboard' | translate]"
                />
            </div>

            <div class="footer-links col-8">
                <ul>
                    <li class="second-link">
                        <a
                            href="{{
                                'static-links.roksh-knowledge'
                                    | translate
                                    | providerTheme : 'static-links.roksh-knowledge'
                                    | async
                            }}"
                            target="_blank"
                        >
                            {{ 'footer.faq' | translate }}
                        </a>
                    </li>

                    <li>
                        <a
                            href="{{
                                'static-links.roksh-data-protect'
                                    | translate
                                    | providerTheme : 'static-links.roksh-data-protect'
                                    | async
                            }}"
                            target="_blank"
                        >
                            {{ 'footer.data-protect' | translate }}
                        </a>
                    </li>

                    <li>
                        <a
                            href="{{
                                'static-links.roksh-terms'
                                    | translate
                                    | providerTheme : 'static-links.roksh-terms'
                                    | async
                            }}"
                            target="_blank"
                        >
                            {{ 'footer.terms' | translate }}
                        </a>
                    </li>

                    <li class="first-link">
                        <a
                            href="{{
                                'static-links.roksh-contact'
                                    | translate
                                    | providerTheme : 'static-links.roksh-contact'
                                    | async
                            }}"
                            target="_blank"
                        >
                            {{ 'footer.contact' | translate }}
                        </a>
                    </li>

                    <li class="third-link">
                        <a
                            target="_blank"
                            href="{{
                                'static-links.roksh-partner'
                                    | translate
                                    | providerTheme : 'static-links.roksh-partner'
                                    | async
                            }}"
                        >
                            {{ 'footer.partner' | translate }}
                        </a>
                    </li>

                    <li class="third-link">
                        <a
                            href="{{
                                'static-links.roksh-carrier'
                                    | translate
                                    | providerTheme : 'static-links.roksh-carrier'
                                    | async
                            }}"
                            target="_blank"
                        >
                            {{ 'footer.carrier' | translate }}
                        </a>
                    </li>

                    <li [routerLink]="['/cookiek']">
                        {{ 'footer.cookie-consern' | translate }}
                    </li>
                </ul>
            </div>

            <div class="social col-2">
                <ul>
                    <li>
                        <a
                            href="{{
                                'static-links.roksh-facebook'
                                    | translate
                                    | providerTheme : 'static-links.roksh-facebook'
                                    | async
                            }}"
                            target="_blank"
                        >
                            <fa-icon
                                [icon]="faFacebook"
                                [styles]="{ color: '#3B3B3B' }"
                                size="2x"
                                class="me-2"
                            >
                            </fa-icon>
                        </a>
                    </li>

                    <li>
                        <a
                            href="{{
                                'static-links.roksh-instagram'
                                    | translate
                                    | providerTheme : 'static-links.roksh-instagram'
                                    | async
                            }}"
                            target="_blank"
                        >
                            <fa-icon
                                [icon]="faInstagram"
                                [styles]="{ color: '#3B3B3B' }"
                                size="2x"
                                class="me-2"
                            >
                            </fa-icon>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="row credit-cards-row">
            <div class="col-12 credit-cards-column">
                <img
                    style="width: 285px !important"
                    class="eu-blokk"
                    src="../../../assets/barion-card-strip-intl_1200px.png"
                    title="Barion"
                    alt="Barion"
                    *ngIf="
                        defaultPaymentProviders &&
                        defaultPaymentProviders.indexOf('BarionCard') > -1
                    "
                />

                <img
                    style="width: 270px !important"
                    class="eu-blokk"
                    src="../../../assets/revolut.png"
                    title="Revolut"
                    alt="Revolut"
                    *ngIf="
                        defaultPaymentProviders &&
                        defaultPaymentProviders.indexOf('RevolutCard') > -1
                    "
                />

                <div *ngFor="let legalInfo of providerLegalInfos">
                    <a
                        *ngIf="legalInfo.link; else noLink"
                        [href]="legalInfo.link"
                        target="_blank"
                        class="product-card-image-link"
                    >
                        <ng-container>
                            <img
                                [src]="legalInfo.image"
                                itemprop="image"
                                class="legal-info"
                            />
                        </ng-container>
                    </a>
                    <ng-template #noLink>
                        <div *ngIf="legalInfo.image">
                            <img
                                [src]="legalInfo.image"
                                itemprop="image"
                                class="legal-info"
                            />
                        </div>
                        <div *ngIf="legalInfo.text" class="col-6">
                            {{ legalInfo.text }}
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <span
            *ngIf="this.frontendVersion$ | async as frontendVersion"
            [attr.data-date]="frontendVersion.VersionNumberDate"
            class="position-absolute version-number"
            >{{ frontendVersion.VersionNumber }}</span
        >
    </div>
</div>

<div
    class="footer-bg mobile-landing d-md-block position-relative"
    [class]="footerClassName"
>
    <div class="footer row pt-0">
        <div [class]="!isFooterFloating ? 'd-none' : ''" class="close-footer pointer">
            <p (click)="onClickFloat()">
                <fa-icon
                    [icon]="faMinusSquare"
                    [styles]="{ color: '#3B3B3B' }"
                    size="1x"
                    class="me-2"
                ></fa-icon>

                <!-- Bezár -->
            </p>
        </div>

        <div class="footer-links col-12 position-relative">
            <ul class="mb-0">
                <li>
                    <a
                        href="{{
                            'static-links.roksh-data-protect'
                                | translate
                                | providerTheme : 'static-links.roksh-data-protect'
                                | async
                        }}"
                        target="_blank"
                    >
                        {{ 'footer.data-protect' | translate }}
                    </a>
                </li>

                <li>
                    <a
                        href="{{
                            'static-links.roksh-terms'
                                | translate
                                | providerTheme : 'static-links.roksh-terms'
                                | async
                        }}"
                        target="_blank"
                    >
                        {{ 'footer.terms' | translate }}
                    </a>
                </li>

                <li [routerLink]="['/cookiek']">
                    {{ 'footer.cookie-consern' | translate }}
                </li>
            </ul>
            <span
                *ngIf="this.frontendVersion$ | async as frontendVersion"
                class="position-absolute version-number"
                >{{ frontendVersion.VersionNumber }}</span
            >
        </div>
    </div>
    <div *ngIf="providerLegalInfos" class="container text-center">
        <div *ngFor="let legalInfo of providerLegalInfos" class="row mt-2">
            <a
                *ngIf="legalInfo.link; else noLink"
                [href]="legalInfo.link"
                target="_blank"
                class="product-card-image-link"
            >
                <ng-container>
                    <img [src]="legalInfo.image" itemprop="image" class="legal-info" />
                </ng-container>
            </a>
            <ng-template #noLink>
                <div *ngIf="legalInfo.image">
                    <img [src]="legalInfo.image" itemprop="image" class="legal-info" />
                </div>
                <div *ngIf="legalInfo.text" class="col-12 align-self-center">
                    {{ legalInfo.text }}
                </div>
            </ng-template>
        </div>
    </div>
</div>
