import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { StepLimit } from '../models/product/product-select-steps/step-limit.model';
import { LogService } from './log.service';
import { BaseLogService } from './base-log.service';
import { OrderService } from './order/order.service';
import { TranslateService } from '@ngx-translate/core';
import { RestrictionCheckerService } from './restriction-checker/restriction-checker.service';
import { SessionService } from './session/session.service';
import {
    AddToMasterBasketDTO,
    BasketDto,
    BasketItemDTO,
    MarketingClient,
    MasterBasketClient,
    MasterBasketDto,
    ProductDto,
    RokshDiscountDTO,
} from '../index';
import { ServiceBaseService } from './service-base.service';

@Injectable({ providedIn: 'root' })
export class BasketService {
    // Roksh discount
    hideDiscountMobile = new BehaviorSubject<boolean>(false);
    private rokshDiscounts: RokshDiscountDTO;
    private isRokshDiscountAvailable: boolean;
    private previousRokshDiscountLevelIndex: number;
    private previousRokshDiscountLevelBasketValue: number;
    private previousRokshDiscountLevelExists: boolean;
    private currentRokshDiscountLevelIndex: number;
    private currentRokshDiscountLevelBasketValue: number;
    private nextRokshDiscountLevelIndex: number;
    private nextRokshDiscountLevelBasketValue: number;
    private nextRokshDiscountLevelExists: boolean;
    private currentRokshDiscount: number;
    private currentTotalPrice: number;
    private currentDepositTotalPrice: number;
    private showRokshDiscountNextLevelDialog: boolean;

    rokshDiscountsSubject = new BehaviorSubject<RokshDiscountDTO>(null);
    isRokshDiscountAvailableSubject = new BehaviorSubject<boolean>(false);
    previousRokshDiscountLevelIndexSubject = new BehaviorSubject<number>(0);
    previousRokshDiscountLevelBasketValueSubject = new BehaviorSubject<number>(0);
    previousRokshDiscountLevelExistsSubject = new BehaviorSubject<boolean>(false);
    currentRokshDiscountLevelIndexSubject = new BehaviorSubject<number>(0);
    currentRokshDiscountLevelBasketValueSubject = new BehaviorSubject<number>(0);
    nextRokshDiscountLevelIndexSubject = new BehaviorSubject<number>(null);
    nextRokshDiscountLevelBasketValueSubject = new BehaviorSubject<number>(null);
    nextRokshDiscountLevelExistsSubject = new BehaviorSubject<boolean>(false);
    currentRokshDiscountSubject = new BehaviorSubject<number>(0);
    currentTotalPriceSubject = new BehaviorSubject<number>(0);
    currentDepositPriceSubject = new BehaviorSubject<number>(0);
    showRokshDiscountNextLevelDialogSubject = new BehaviorSubject<boolean>(false);

    lastRokshDiscountLevelIndex = 0;

    private masterBasket: MasterBasketDto;
    userSelectedBasketID: number;
    selectedBasketProviderID: number;

    instantDeliveryWarningDisplayed: boolean;
    instantWeigthLimit = 50000;
    weightLimit = 50000;
    instantDelWeightLimitMsg = '';
    delWeightLimitMsg = '';

    shopServiceUrl: string;
    productCountChangeSubject = new BehaviorSubject<number>(0);
    openMenuSubject = new BehaviorSubject<boolean>(false);
    masterBasketSubject = new BehaviorSubject<MasterBasketDto>(null);
    refreshCalculatedBasketItemSubject = new BehaviorSubject<void>(null);
    totalWeigthSubject = new BehaviorSubject<number>(0);
    selectedBasketSubject = new BehaviorSubject<BasketDto>(null);
    selectedBasket: BasketDto;

    log: LogService;

    private basketCount = new BehaviorSubject<number>(0);
    private goToCheckout = new BehaviorSubject<boolean>(false);

    constructor(
        private serviceBaseService: ServiceBaseService,
        private baseLog: BaseLogService,
        private orderService: OrderService,
        private translate: TranslateService,
        private restrictionService: RestrictionCheckerService,
        private sessionService: SessionService,
        private masterBasketClient: MasterBasketClient,
        private marketingClient: MarketingClient
    ) {
        this.translate.get('messages.your-basket-now-above-limit-2').subscribe((text) => {
            this.instantDelWeightLimitMsg = text;
            this.delWeightLimitMsg = text;
        });

        this.log = new LogService(baseLog);
        this.log.mainClass = 'BasketService';
        this.shopServiceUrl = this.serviceBaseService.getBaseUrl();

        this.sessionService.sessionSubject.subscribe((session) => {
            if (session && session.selectedShops) {
                this.getMasterBasket().subscribe((basket) => {
                    this.setSelectedBasketByShop(session?.selectedShops[0]);
                    this.refreshMasterBasket(basket);
                    this.getAvailableRokshDiscounts().subscribe((rokshDiscounts) => {
                        this.setAvailableRokshDiscounts(rokshDiscounts);
                        this.selectedBasketSubject.subscribe(() => {
                            this.initializeRokshDiscountData();
                        });
                    });
                });
            }
        });
    }

    public setBasketCount(count: number): void {
        this.basketCount.next(count);
    }

    public getBasketCount(): BehaviorSubject<number> {
        return this.basketCount;
    }

    public setGoToCheckout(state: boolean): void {
        this.goToCheckout.next(state);
    }

    public getGoToCheckout(): BehaviorSubject<boolean> {
        return this.goToCheckout;
    }

    public getInstantWeightLimit(): number {
        return this.instantWeigthLimit;
    }

    public getCurrentTotalWeigth(): number {
        return this.totalWeigthSubject.getValue();
    }

    public getInstantDelWeightLimitMsg(): string {
        return this.instantDelWeightLimitMsg;
    }

    public setUserSelectedBasketID(selectedBasketId: number): void {
        this.userSelectedBasketID = selectedBasketId;
    }

    public setOpenMenuSubject(newMenuStatus: boolean): void {
        this.openMenuSubject.next(newMenuStatus);
    }

    private setSelectedBasketSubject(): void {
        if (this.masterBasket && this.userSelectedBasketID > 0) {
            this.selectedBasket = this.masterBasket.basketList.find(
                (basket) => basket.basketID === this.userSelectedBasketID
            );
            this.selectedBasketSubject.next(this.selectedBasket);
        }
    }

    public isTotalPriceLessThanMinPrice(
        totalPrice: number,
        minPrice: number,
        depositPrice?: number
    ): boolean {
        return (
            minPrice > totalPrice + (depositPrice ?? this.currentDepositTotalPrice ?? 0)
        );
    }

    public getMaxBasketWeight(): number {
        return this.restrictionService.getMaxBasketWeight();
    }

    public getMasterBasket(): Observable<MasterBasketDto> {
        return this.masterBasketClient.getMasterBasket().pipe(
            map((masterBasket) => {
                this.log.log('get-master-basket/', masterBasket);
                this.refreshMasterBasket(masterBasket);
                return masterBasket;
            })
        );
    }

    public addToMasterBasket(
        productID: number,
        quantity: number,
        providerID?: number
    ): Observable<MasterBasketDto> {
        const isAlreadyInBasket = this.selectedBasket.basketItemList.findIndex(
            (product) => product.productID === productID
        );
        if (isAlreadyInBasket !== -1) return of({} as MasterBasketDto);

        this.log.info('addToCart', productID, quantity);
        const dto = new AddToMasterBasketDTO({
            productID,
            quantity,
            providerID,
        });

        return this.masterBasketClient.addToMasterBasket(dto).pipe(
            map((masterBasket) => {
                this.log.log('basket/Add/');
                const lastRokshDiscountLevelIndex = this.currentRokshDiscountLevelIndex;
                this.refreshMasterBasket(masterBasket);
                this.showRokshDiscountNextLevelDialog =
                    lastRokshDiscountLevelIndex < this.currentRokshDiscountLevelIndex;
                this.showRokshDiscountNextLevelDialogSubject.next(
                    this.showRokshDiscountNextLevelDialog
                );
                this.showRokshDiscountNextLevelDialogSubject.next(false);
                return masterBasket;
            })
        );
    }

    public deleteFromMasterBasket(basketItemID: number): Observable<MasterBasketDto> {
        return this.masterBasketClient.deleteFromMasterBasket(basketItemID).pipe(
            map((masterBasket) => {
                this.refreshMasterBasket(masterBasket);
                return masterBasket;
            })
        );
    }

    public updateQuantity(
        basketItemID: number,
        quantity: number
    ): Observable<MasterBasketDto> {
        return this.masterBasketClient.updateQuantity(basketItemID, quantity).pipe(
            map((masterBasket) => {
                const lastRokshDiscountLevelIndex = this.currentRokshDiscountLevelIndex;
                this.refreshMasterBasket(masterBasket);
                this.showRokshDiscountNextLevelDialog =
                    lastRokshDiscountLevelIndex < this.currentRokshDiscountLevelIndex;
                this.showRokshDiscountNextLevelDialogSubject.next(
                    this.showRokshDiscountNextLevelDialog
                );
                this.showRokshDiscountNextLevelDialogSubject.next(false);
                return masterBasket;
            })
        );
    }

    public getFirstBasketItem(productId: number): BasketItemDTO {
        if (!this.masterBasket || !this.masterBasket.basketList) {
            return null;
        }

        for (const basket of this.masterBasket.basketList) {
            const basketItem = basket.basketItemList.find(
                (basketItem) => basketItem.productID === productId
            );
            if (basketItem) {
                return basketItem;
            }
        }
        return null;
    }

    public getSelectedBasketItemOriginal(productId: number): BasketItemDTO {
        if (
            !this.masterBasket ||
            !this.userSelectedBasketID ||
            !this.masterBasket.basketList
        ) {
            return null;
        }
        const basket = this.masterBasket.basketList.find(
            (x) => x.basketID === this.userSelectedBasketID
        );
        if (basket) {
            return basket.basketItemList.find(
                (basketItem) => basketItem.productID === productId
            );
        }
        return null;
    }

    public getSelectedBasketItem(productId: number, providerId?: number): BasketItemDTO {
        if (
            !this.masterBasket ||
            !this.userSelectedBasketID ||
            !this.masterBasket.basketList
        ) {
            return null;
        }

        let basket: BasketDto;
        if (providerId) {
            basket = this.masterBasket.basketList.find(
                (x) => x.providerID === providerId
            );
        } else {
            basket = this.masterBasket.basketList.find(
                (x) => x.basketID === this.userSelectedBasketID
            );
        }

        if (basket) {
            return basket.basketItemList.find(
                (basketItem) => basketItem.productID === productId
            );
        }
        return null;
    }

    public getBasketItem(basketItemID: number): BasketItemDTO {
        if (!this.masterBasket || !this.masterBasket.basketList) {
            return null;
        }
        for (const basket of this.masterBasket.basketList) {
            const basketItem = basket.basketItemList.find(
                (basketItem) => basketItem.basketItemID === basketItemID
            );
            if (basketItem) {
                return basketItem;
            }
        }
        return null;
    }

    public emptyMasterBasket(): Observable<MasterBasketDto> {
        return this.masterBasketClient.emptyMasterBasket().pipe(
            map((masterBasket) => {
                this.refreshMasterBasket(masterBasket);
                return masterBasket;
            })
        );
    }

    public emptyBasket(providerID: number): Observable<MasterBasketDto> {
        return this.masterBasketClient.emptyBasket(providerID).pipe(
            map((masterBasket) => {
                this.refreshMasterBasket(masterBasket);
                return masterBasket;
            })
        );
    }

    // dinamikus értéklista választott basketitem szerint
    public createQuantityList(
        minWeightStep: number,
        maxWeightStep: number,
        isBulk: boolean,
        priceUnitType: string,
        alternatePriceUnitType = ''
    ): Array<[number, string]> {
        let quantityList: Array<[number, string]>;

        if (!isBulk || !minWeightStep) {
            quantityList = this.NotBulkQuantityList(minWeightStep, maxWeightStep);
        } else {
            const stepLimit = new StepLimit();
            stepLimit.maxWStep = maxWeightStep;
            stepLimit.minWStep = minWeightStep;

            quantityList = this.BulkQuantityList(stepLimit, priceUnitType);
        }

        let localPiece: string;
        this.translate.get('common.piece').subscribe((text) => (localPiece = text));

        if (alternatePriceUnitType !== '') {
            for (let i = 0; i < quantityList.length; i++) {
                if (alternatePriceUnitType === localPiece) {
                    quantityList[i][1] = quantityList[i][1].replace(' kg', localPiece);
                } else {
                    quantityList[i][1] = quantityList[i][1].replace(localPiece, ' kg');
                }
            }
        }
        return quantityList;
    }

    private NotBulkQuantityList(
        minWeightStep: number = null,
        maxWeightStep: number = null
    ): Array<[number, string]> {
        let quantityListStart = 1;
        let quantityListEnd = 24;
        let quantityListStep = 1;

        if (minWeightStep != null && minWeightStep != 0)
            quantityListStart = minWeightStep;
        if (maxWeightStep != null && maxWeightStep != 0) quantityListEnd = maxWeightStep;
        if (minWeightStep != null && minWeightStep != 0) quantityListStep = minWeightStep;

        const quantityList = new Array<[number, string]>();

        for (let i = quantityListStart; i <= quantityListEnd; i = i + quantityListStep) {
            quantityList.push([
                i,
                i.toString() + ' ' + this.translate.instant('common.piece'),
            ]);
        }
        return quantityList;
    }

    private BulkQuantityList(
        stepLimit: StepLimit,
        priceUnitType: string
    ): Array<[number, string]> {
        const quantityList = new Array<[number, string]>();

        const stepnumber = this.calculateStepNumber(stepLimit);

        let w = stepLimit.minWStep;

        let i = 0;
        while (i <= stepnumber && w - stepLimit.maxWStep <= 0.001) {
            quantityList.push(this.createQuantityValue(w, priceUnitType));
            w += stepLimit.minWStep;
            i++;
        }

        return quantityList;
    }

    private createQuantityValue(w: number, priceUnitType: string): [number, string] {
        // 2 tizedes kerekítés
        const value = Math.round(w * 100) / 100;

        //Ha szerepel szám a mértékegységben, akkor megjelenítési okok miatt beszorozzuk a léptékkel e.g.: 0.5 100g => 50g
        if (/\d/.test(priceUnitType)) {
            let unitnumber = '';
            let unitstr = '';
            for (const c of priceUnitType)
                /^-?[\d.]+(?:e-?\d+)?$/.test(c) ? (unitnumber += c) : (unitstr += c);
            priceUnitType = Math.round(parseInt(unitnumber) * value) + ' ' + unitstr;
            return [value, priceUnitType];
        }

        return [value, value + ' ' + priceUnitType];
    }

    private calculateStepNumber(stepLimit: StepLimit): number {
        if (
            (stepLimit.maxWStep - stepLimit.minWStep) / stepLimit.minWStep <
            stepLimit.MAXWITEMS
        ) {
            return Math.ceil(
                (stepLimit.maxWStep - stepLimit.minWStep) / stepLimit.minWStep
            );
        } else {
            return stepLimit.MAXWITEMS;
        }
    }

    private refreshMasterBasket(masterBasket: MasterBasketDto): void {
        this.masterBasket = masterBasket;

        const selectedShops =
            this.sessionService.sessionSubject.getValue()?.selectedShops;
        if (selectedShops.length === 1) {
            this.setSelectedBasketByShop(selectedShops[0]);
        }

        if (this.selectedBasket) {
            this.productCountChangeSubject.next(
                this.selectedBasket.basketItemList?.length
            );
        }

        this.refreshCalculatedBasketItemSubject.next();

        this.setSelectedBasketSubject();
        this.masterBasketSubject.next(masterBasket);
    }

    private setSelectedBasketByShop(shop: string): void {
        if (this.masterBasket) {
            const newBasket = this.masterBasket.basketList.find(
                (basket) => basket.providerName.toLowerCase() === shop?.toLowerCase()
            );
            this.selectedBasket = newBasket;
            this.userSelectedBasketID = this.selectedBasket?.basketID;
            this.setTotalWeight();
        }
    }

    public getTotalPrice(prod: ProductDto, unitVal: number): number {
        return (prod.isBulk ? prod.minUnitPrice : prod.minPrice) * unitVal;
    }

    public isWeightGreatherThanMaxWeight(): boolean {
        let isMaxWeight = false;
        this.totalWeigthSubject.subscribe((weigth) => {
            isMaxWeight = this.restrictionService.isMaxBasketWeightExceeded(weigth);
        });
        return isMaxWeight;
    }

    private setTotalWeight(): void {
        if (this.masterBasket && this.userSelectedBasketID > 0) {
            this.selectedBasket = this.masterBasket.basketList.find(
                (b) => b.basketID === this.userSelectedBasketID
            );
        } else {
            return;
        }

        const basket = this.selectedBasketSubject.getValue();

        let TotalWeight = 0;
        basket?.basketItemList.forEach(function (basketItem) {
            if (basketItem)
                TotalWeight += basketItem.unitValue * basketItem.packageQuantity;
        });

        if (this.totalWeigthSubject.getValue() != TotalWeight) {
            this.totalWeigthSubject.next(TotalWeight);
        }
    }

    public subscribeToLimitWarnings(zipCode: string): void {
        this.totalWeigthSubject.subscribe((weigth) => {
            this.restrictionService.checkRestrictions(weigth);
            const basket = this.selectedBasketSubject.getValue();
            if (zipCode && basket && !this.instantDeliveryWarningDisplayed) {
                this.orderService
                    .getInstantDeliveryAreaProvider(basket.providerID, zipCode)
                    .pipe(take(1))
                    .subscribe();
            }
        });
    }

    // Roksh discount

    private getAvailableRokshDiscounts(): Observable<RokshDiscountDTO> {
        return this.marketingClient.getRokshDiscounts().pipe(
            map((rokshDiscounts) => {
                return rokshDiscounts;
            })
        );
    }

    private setAvailableRokshDiscounts(rokshDiscounts: RokshDiscountDTO): void {
        this.rokshDiscounts = rokshDiscounts;
        this.rokshDiscountsSubject.next(rokshDiscounts);
    }

    private initializeRokshDiscountData(): void {
        this.setRokshDiscountAvailability(this.selectedBasket);
        this.setCurrentTotalPrice(this.selectedBasket);

        if (this.isRokshDiscountAvailable) {
            this.setRokshDiscountLevels(this.selectedBasket);
            this.setCurrentRokshDiscount(this.selectedBasket);
        }
    }

    private setRokshDiscountAvailability(basket: BasketDto): void {
        if (basket) {
            this.isRokshDiscountAvailable = !!basket?.isRokshDiscountAvailable;
            this.isRokshDiscountAvailableSubject.next(this.isRokshDiscountAvailable);
        }
    }

    private setCurrentTotalPrice(basket: BasketDto): void {
        if (basket) {
            this.currentDepositTotalPrice = basket.totalDepositPrice;
            this.currentTotalPrice = basket.totalPrice;
            this.currentTotalPriceSubject.next(this.currentTotalPrice);
            this.currentDepositPriceSubject.next(this.currentDepositTotalPrice);
        }
    }

    public calculateTotalPrice(basket: BasketDto): number {
        if (basket) {
            return (basket?.totalPrice ?? 0) + (basket?.totalDepositPrice ?? 0);
        }
    }

    private setRokshDiscountLevels(basket: BasketDto): void {
        if (
            basket &&
            this.rokshDiscounts &&
            this.rokshDiscounts.isRokshDiscountAvailable &&
            this.rokshDiscounts.rokshDiscountLevels &&
            0 < this.rokshDiscounts.rokshDiscountLevels.length
        ) {
            const sortedRokshDiscountLevels = [
                ...this.rokshDiscounts.rokshDiscountLevels,
            ].sort((rokshDiscountLevel) => rokshDiscountLevel.level);

            let currentRokshDiscountLevelArrayIndex = 0;

            sortedRokshDiscountLevels.forEach((rokshDiscountLevel, index) => {
                if (
                    rokshDiscountLevel.basketValue <=
                    this.currentTotalPrice + this.currentDepositTotalPrice
                ) {
                    currentRokshDiscountLevelArrayIndex = index;
                }
            });

            const currentRokshDiscountLevel =
                sortedRokshDiscountLevels[currentRokshDiscountLevelArrayIndex];

            if (currentRokshDiscountLevel) {
                this.currentRokshDiscountLevelIndex = currentRokshDiscountLevel.level;
                this.currentRokshDiscountLevelBasketValue =
                    currentRokshDiscountLevel.basketValue;

                this.currentRokshDiscountLevelIndexSubject.next(
                    this.currentRokshDiscountLevelIndex
                );
                this.currentRokshDiscountLevelBasketValueSubject.next(
                    this.currentRokshDiscountLevelBasketValue
                );
            }

            this.previousRokshDiscountLevelExists =
                currentRokshDiscountLevelArrayIndex !== 0;
            this.previousRokshDiscountLevelExistsSubject.next(
                this.previousRokshDiscountLevelExists
            );

            if (this.previousRokshDiscountLevelExists) {
                const previousRokshDiscountLevel =
                    sortedRokshDiscountLevels[currentRokshDiscountLevelArrayIndex - 1];
                this.previousRokshDiscountLevelIndex = previousRokshDiscountLevel.level;
                this.previousRokshDiscountLevelBasketValue =
                    previousRokshDiscountLevel.basketValue;
            } else {
                this.previousRokshDiscountLevelIndex = currentRokshDiscountLevel.level;
                this.previousRokshDiscountLevelBasketValue =
                    currentRokshDiscountLevel.basketValue;
            }

            this.previousRokshDiscountLevelIndexSubject.next(
                this.previousRokshDiscountLevelIndex
            );
            this.previousRokshDiscountLevelBasketValueSubject.next(
                this.previousRokshDiscountLevelBasketValue
            );

            const rokshDiscountLevelCount = sortedRokshDiscountLevels.length;

            this.nextRokshDiscountLevelExists =
                currentRokshDiscountLevelArrayIndex !== rokshDiscountLevelCount - 1;
            this.nextRokshDiscountLevelExistsSubject.next(
                this.nextRokshDiscountLevelExists
            );

            if (this.nextRokshDiscountLevelExists) {
                const nextRokshDiscountLevel =
                    sortedRokshDiscountLevels[currentRokshDiscountLevelArrayIndex + 1];
                this.nextRokshDiscountLevelIndex = nextRokshDiscountLevel.level;
                this.nextRokshDiscountLevelBasketValue =
                    nextRokshDiscountLevel.basketValue;
            } else {
                this.nextRokshDiscountLevelIndex = currentRokshDiscountLevel.level;
                this.nextRokshDiscountLevelBasketValue =
                    currentRokshDiscountLevel.basketValue;
            }

            this.nextRokshDiscountLevelIndexSubject.next(
                this.nextRokshDiscountLevelIndex
            );
            this.nextRokshDiscountLevelBasketValueSubject.next(
                this.nextRokshDiscountLevelBasketValue
            );
        }
    }

    private setCurrentRokshDiscount(basket: BasketDto): void {
        if (
            basket &&
            this.rokshDiscounts &&
            this.rokshDiscounts.isRokshDiscountAvailable &&
            this.rokshDiscounts.rokshDiscountLevels &&
            0 < this.rokshDiscounts.rokshDiscountLevels.length
        ) {
            const availableRokshDiscountLevels =
                this.rokshDiscounts.rokshDiscountLevels.filter(
                    (rokshDiscountLevel) =>
                        rokshDiscountLevel.basketValue <=
                        this.currentTotalPrice + this.currentDepositTotalPrice
                );

            let sumRokshDiscount = 0;

            availableRokshDiscountLevels.forEach((availableRokshDiscountLevel) => {
                const rokshDiscountProducts =
                    availableRokshDiscountLevel.rokshDiscountProducts;

                rokshDiscountProducts.forEach((rokshDiscountProduct) => {
                    const basketItemExists =
                        0 <
                        basket.basketItemList.filter(
                            (basketItem) =>
                                basketItem.productProviderID ===
                                rokshDiscountProduct.productProviderID
                        ).length;

                    if (basketItemExists) {
                        sumRokshDiscount += rokshDiscountProduct.rokshDiscountPrice;
                    }
                });
            });

            this.currentRokshDiscount = sumRokshDiscount;

            this.currentRokshDiscountSubject.next(sumRokshDiscount);
        }
    }

    setHideDiscount(value: boolean): void {
        if (!this.isRokshDiscountAvailable) {
            this.hideDiscountMobile.next(true);
        } else {
            this.hideDiscountMobile.next(value);
        }
    }

    public emptyCurrentBasket(): Observable<MasterBasketDto> {
        if (!this.selectedBasket.providerID) {
            return throwError(new Error());
        }

        return this.emptyBasket(this.selectedBasket.providerID);
    }
}
