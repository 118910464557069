import { EventEmitter, Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseLogService } from '../base-log.service';
import { LogService } from '../log.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RestrictionCheckerService } from '../restriction-checker/restriction-checker.service';
import { ProviderSettingsService } from '../provider-settings.service';
import { StyleService } from '../style.service';
import { ProviderSettingType } from '../../models/provider/i-provider-settings';
import { ServiceBaseService } from '../service-base.service';
import {
    ConfigureSessionDto,
    ISessionDto,
    SessionClient,
    SessionDto,
    SetSessionZipDto,
    ShopAvailable,
} from '../..';
import { CountryCodes } from '../../types/country-codes';
import { IMyOrder } from '../../models/order/myorder';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class SessionService {
    isPostCodeSet = new BehaviorSubject<boolean>(false);
    isProviderSelectedForShopping = new BehaviorSubject<boolean>(false);
    selectedOpenOrder = new BehaviorSubject<{
        orderId: string;
        providerId: number;
        order: IMyOrder;
        postCode: string;
        streetName: string;
        houseNumber: string;
    }>(null);
    orderEditedEmitter = new EventEmitter<boolean>();

    private session: ISessionDto = null;
    sessionSubject = new BehaviorSubject<ISessionDto>(this.session);

    log: LogService;
    isMobile = false;
    readonly isWrapper = !!window.ReactNativeWebView;
    lockDiscountMobile = new BehaviorSubject<boolean>(false);
    isDiscoverPage = new BehaviorSubject(false);

    constructor(
        baseLogService: BaseLogService,
        private router: Router,
        private translate: TranslateService,
        private restrictionService: RestrictionCheckerService,
        private providerSettingsService: ProviderSettingsService,
        private styleService: StyleService,
        private dialog: MatDialog,
        private sessionClient: SessionClient
    ) {
        this.log = new LogService(baseLogService);
        this.log.mainClass = SessionService.name;
    }

    isPopUpsBlocked(): boolean {
        const open = window.open('', '_blank');
        try {
            open.close();
            return false;
        } catch (error) {
            return true;
        }
    }

    refreshSession(): Observable<SessionDto> {
        return this.sessionClient.getSession().pipe(
            map((session) => {
                this.setSession(session);
                return session;
            })
        );
    }

    /**
     * Session alapján visszaadja a város irányítószámát, vagy a nevét
     */
    getCityBySession(): Observable<SessionDto> {
        return this.sessionClient
            .getZipBySession(ServiceBaseService.getOwnWebShopProviderCode())
            .pipe(
                map((session) => {
                    this.setSession(session, false);
                    return session;
                })
            );
    }

    /**
     * Visszaadja string formájában az elérhető boltok neveit
     */
    getAvailableShopsByZipCode(
        zipCode: string,
        houseNubmer: string
    ): Observable<SessionDto> {
        return this.sessionClient
            .getAvailableShopsByZipCode(
                zipCode,
                houseNubmer,
                '',
                ServiceBaseService.getOwnWebShopProviderCode()
            )
            .pipe(
                map((session) => {
                    return session;
                })
            );
    }

    /**
     * Visszaadja string formájában az elérhető boltok neveit
     */
    getAvailableShopsByZipCodeAndAddress(
        zipCode: string,
        address: string,
        houseNumber: string
    ): Observable<SessionDto> {
        return this.sessionClient.getAvailableShopsByZipCode(
            zipCode,
            houseNumber,
            address,
            ServiceBaseService.getOwnWebShopProviderCode()
        );
    }

    /**
     * Beállítja a felhasználó sessionjéhez az irányítószámot és a város nevét
     * TODO: akárhol használjhuk, le kellene cserélni a configureusersession fv-re
     *
     */
    setSessionZip(
        zipCode: string,
        streetName: string,
        houseNumber: string,
        lat: number,
        long: number,
        availableShops: string[],
        city: string
    ) {
        return this.sessionClient
            .setSessionZip(
                new SetSessionZipDto({
                    availableShops: availableShops,
                    zip: zipCode,
                    city: city,
                    address: streetName + ' ' + houseNumber,
                    streetName: streetName,
                    houseNumber: houseNumber,
                    lat: lat,
                    long: long,
                    ownWebshopProviderCode:
                        ServiceBaseService.getOwnWebShopProviderCode(),
                })
            )
            .pipe(
                map((session) => {
                    this.setSession(session);
                    return session;
                })
            );
    }

    /**
     * If the user select shop to Available it sets to the UserSession
     */
    configureUserSession(dto: ConfigureSessionDto) {
        return this.sessionClient.configureUserSession(dto).pipe(
            map((session) => {
                if (session?.selectedShops?.length === 1) {
                    this.setCookie('selectedShop', session.selectedShops[0]);
                    this.isProviderSelectedForShopping.next(true);
                }

                this.restrictionService.getTimeslotRestrictions(
                    session.selectedShopIds[0],
                    session.zipCode
                );
                this.setSession(session);

                if (dto.redirectToDashboardNeeded) {
                    this.redirectToSelectedShop();
                }

                if (
                    session.selectedShopCodes[0] &&
                    ServiceBaseService.getCountryCode() == CountryCodes.at
                ) {
                    this.providerSettingsService
                        .getProviderThemes(session.selectedShopCodes[0])
                        .pipe(take(1))
                        .subscribe((settings) => {
                            if (settings) {
                                const newSettings = this.providerSettingsService.settings
                                    .getValue()
                                    .concat(
                                        settings.filter(
                                            (x) =>
                                                x.ProviderSettingType ===
                                                    ProviderSettingType.Text ||
                                                x.ProviderSettingType ===
                                                    ProviderSettingType.Image
                                        )
                                    );

                                this.providerSettingsService.settings.next(newSettings);

                                settings
                                    .filter(
                                        (x) =>
                                            x.ProviderSettingType ===
                                            ProviderSettingType.Css
                                    )
                                    .forEach((setting) => {
                                        this.styleService.setStyle(
                                            setting.Section,
                                            setting.Key,
                                            setting.Value
                                        );
                                    });
                            }
                        });
                }

                return session;
            })
        );
    }

    /*
     * General utils for managing cookies in Typescript.
     */
    setCookie(name: string, val: string) {
        const date = new Date();
        const value = val;

        // Set it expire in 7 days
        date.setTime(date.getTime() + 70 * 24 * 60 * 60 * 1000);

        // Set it
        document.cookie =
            name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
    }

    getCookie(name: string) {
        const value = '; ' + document.cookie;
        const parts = value.split('; ' + name + '=');

        if (parts.length == 2) {
            return parts.pop().split(';').shift();
        }
    }

    deleteCookie(name: string) {
        const date = new Date();

        // Set it expire in -1 days
        date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

        // Set it
        document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/';
    }

    private convertToShopAvailable(
        shops: string[],
        shopNamesToShow: string[]
    ): ShopAvailable[] {
        if (!shops) {
            return [];
        }
        const availableShops: ShopAvailable[] = [];

        shops.forEach((shop, index) => {
            if (shopNamesToShow) {
                availableShops.push(
                    new ShopAvailable({
                        shopName: shop,
                        shopNameToShow: shopNamesToShow[index],
                    })
                );
            }
        });

        return availableShops;
    }

    private setSession(sessionDto: ISessionDto, emitChanges = true): ISessionDto {
        sessionDto.shopsAvailable = this.convertToShopAvailable(
            sessionDto.availableShops,
            sessionDto.selectedShopNamesToShow ??
                sessionDto.dataBaseAvailableProviderNames
        );

        this.session = { ...sessionDto, isValidZip: !!sessionDto.city };

        if (emitChanges) {
            this.sessionSubject.next(this.session);
        }

        if (this.session.isValidZip) {
            this.isPostCodeSet.next(true);
        }

        return this.session;
    }

    private redirectToSelectedShop(): void {
        this.translate.get('router.dashboard').subscribe((text) => {
            void this.router.navigate([text], {
                queryParamsHandling: 'merge',
            });
        });
    }

    closeAllModals() {
        this.dialog.closeAll();
    }
}
