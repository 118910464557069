import { Component, OnInit } from '@angular/core';
import { CountryCodes, ServiceBaseService } from 'projects/library-shared/src/public-api';

@Component({
    selector: 'app-personal-shopper',
    templateUrl: './personal-shopper.component.html',
    styleUrls: ['./personal-shopper.component.scss'],
})
export class PersonalShopperComponent implements OnInit {
    CountryCodes = CountryCodes;
    countryCode = this.CountryCodes.hu;
    siteImageUrl = '';
    constructor(public baseService: ServiceBaseService) {
        this.countryCode = ServiceBaseService.getCountryCode();
    }

    ngOnInit() {
        this.siteImageUrl = this.baseService.getSiteImageUrl();
    }
}
