<div *ngIf="customBlock" class="custom-category-block position-relative ontop-ofbg mt-5">
    <h2 class="text-white text-size-46">{{customBlock.Title}}</h2>
    <div class="row container-magrgin d-lg-flex" style="max-width: 1280px">
        <app-category-card
            [category]="category"
            [smallCard]="true"
            [isSubCategory]="isSubCategory"
            *ngFor="let category of categories"
        ></app-category-card>
        <div class="fake-cat"></div>
        <div class="fake-cat"></div>
        <div class="fake-cat"></div>
        <div class="fake-cat"></div>
        <div class="fake-cat"></div>
    </div>
    <div
        class="position-absolute background"
        [ngClass]="{'main-cat-background': !isSubCategory}"
        [style.backgroundColor]="customBlock.BackColor"
        [style.backgroundImage]="customBlock.BackGUID"
    ></div>
</div>
