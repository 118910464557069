<div class="bg-gray h-100 w-100">
    <div *ngIf="navigationLevel.valueOf() === 0" @inOutAnimation class="h-100">
        <lib-archie-provider-select
            [providerSelectedEmitter]="providerSelectedEmitter"
            [threadId]="archieConversationThreadID"
        ></lib-archie-provider-select>
    </div>
    <div *ngIf="navigationLevel.valueOf() === 6" @inOutAnimation class="h-100">
        <lib-archie-provider-select
            [providerSelectedEmitter]="providerSelectedEmitter"
            [threadId]="archieConversationThreadID"
            [isNewChat]="false"
        ></lib-archie-provider-select>
    </div>
    <div
        [class.d-none]="navigationLevel.valueOf() !== 1"
        [class.in-animation]="navigationLevel.valueOf() === 1"
        class="h-100"
    >
        <div class="w-100 h-100 archie-chat-container position-relative">
            <div class="messaging-panel row" #messagePanel>
                <div *ngIf="archieConversationThread">
                    <ng-container
                        *ngFor="
                            let archieConversationThreadMessageDto of archieConversationThread.archieConversationThreadMessageDtoList
                        "
                    >
                        <div class="w-100">
                            <lib-message-box
                                [selectedProviders]="selectedProviders"
                                [streamEnabled]="streamEnabled"
                                [openProductListEmitter]="openProductListEmitter"
                                [archieConversationThread]="archieConversationThread"
                                [answerDoneEmitter]="answerDoneEmitter"
                                (clickProduct)="handleClickProduct($event)"
                                (clickShowTimeSlot)="handleClickShowTimeSlot($event)"
                                [archieConversationThreadMessageDto]="
                                    archieConversationThreadMessageDto
                                "
                                (autoMessage)="sendMessageToArchie($event)"
                            ></lib-message-box>
                        </div>
                        <div class="clearfix"></div>
                    </ng-container>
                    <div class="w-100 pb-3 pt-1 ps-3 position-relative">
                        <div
                            *ngIf="isSendingInProgress"
                            class="d-flex justify-content-center align-items-center text-size-13 position-absolute cancel-container w-100"
                            (click)="cancelRequest()"
                            @inOutAnimation
                        >
                            <button class="cancel-request">
                                <fa-icon [icon]="faStop" class="me-1"></fa-icon>
                                {{ 'archie.cancel-request' | translate }}
                            </button>
                        </div>
                        <div>
                            <img
                                *ngIf="messageSendingDisabled; else waitingForMessage"
                                src="https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExeDRoOHNpN3l0MTFjbDIwbjd0cm12OWdvMDhkYTRuOThheTl3cTdkNSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/3tLfKrc4pLWiTkAAph/giphy.gif"
                                class="loading-image"
                                alt="loading"
                            />
                            <ng-template #waitingForMessage>
                                <img
                                    src="https://cdn2.iconfinder.com/data/icons/greenline/512/check-512.png"
                                    class="loading-image"
                                />
                            </ng-template>
                            <i> &nbsp;{{ logMessage | translate }} </i>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="message-sender-panel w-100 px-3 d-flex flex-column justify-content-center"
            >
                <div class="d-flex w-100 position-relative">
                    <div
                        class="h-100 px-3 me-4 btn-blue d-none d-md-flex align-items-center"
                        (click)="createNewArchieThread()"
                    >
                        <img
                            src="{{ siteImageUrl }}images/shared-img/archie/newchat.svg"
                            alt="new chat icon"
                        />
                        <span class="ms-2 fw-bold">{{
                            'archie.new-chat' | translate
                        }}</span>
                    </div>
                    <div class="d-flex w-100">
                        <div class="d-block d-md-none">
                            <button
                                class="btn btn-basket pt-0 me-3"
                                (click)="navigationLevelEmitter.emit(3)"
                            >
                                <div class="position-relative">
                                    <div
                                        class="basket-count text-white fw-bold position-absolute"
                                    >
                                        {{ basketCount }}
                                    </div>
                                    <img
                                        class="basket-icon"
                                        src="./assets/footer-icons/kosar.svg"
                                        alt="basket"
                                    />
                                </div>
                            </button>
                        </div>
                        <input
                            placeholder="{{ 'archie.type-your-question' | translate }}"
                            [disabled]="messageSendingDisabled"
                            class="form-control chat-input"
                            [(ngModel)]="userMessage"
                            (keyup.enter)="sendMessageToArchie()"
                            (focusin)="clearNewMessageStatus()"
                            #userInput
                        />
                    </div>
                    <button
                        *ngIf="
                            !userInput.value || userInput.value === '';
                            else sendButton
                        "
                        [disabled]="messageSendingDisabled"
                        class="form-control ms-2 send-button p-0 d-flex justify-content-center align-items-center position-absolute end-0 top-0"
                        [class.active-mic]="isSpeaking"
                        (click)="startListening()"
                    >
                        <fa-icon
                            *ngIf="voiceSearch"
                            [class.active-mic]="isSpeaking"
                            [icon]="faMic"
                        ></fa-icon>
                    </button>
                    <ng-template #sendButton>
                        <button
                            [disabled]="messageSendingDisabled"
                            class="form-control ms-2 send-button p-0 d-flex justify-content-center align-items-center position-absolute end-0 top-0"
                            (click)="sendMessageToArchie()"
                        >
                            <fa-icon [icon]="faSend"></fa-icon>
                        </button>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="navigationLevel.valueOf() === 2" @inOutAnimation class="h-100">
        <lib-archie-product-list
            [openProductListEmitter]="openProductListEmitter"
            [selectedProducts]="selectedProducts"
            (clickProduct)="handleClickProduct($event)"
        ></lib-archie-product-list>
    </div>
    <div *ngIf="navigationLevel.valueOf() === 3" @inOutAnimation class="h-100">
        <lib-archie-all-basket
            [selectedProviders]="selectedProviders"
            [selectedBaskets]="selectedBaskets"
            (selectBasketEmitter)="onBasketSelected($event)"
        ></lib-archie-all-basket>
    </div>
    <div *ngIf="navigationLevel.valueOf() === 4" @inOutAnimation class="h-100">
        <lib-basket
            (goToCheckoutEmitter)="goToCheckout.emit($event)"
            [selectedBasket]="selectedBasket"
            [showProvider]="false"
            [isArchie]="true"
        ></lib-basket>
    </div>
</div>
