import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    faCircleCheck,
    faShoppingCart,
    faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import {
    AuthenticationService,
    ConfigureSessionDto,
    DeleteShoppingListDialogComponent,
    ServiceBaseService,
    SessionService,
    SharedModalService,
    SnackBarService,
    UserBasketDto,
    UserBasketService,
} from 'projects/library-shared/src/public-api';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CreateDialogService } from '../../../services/create-dialog.service';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
    selector: 'app-user-basket-details',
    templateUrl: './user-basket-details.component.html',
    styleUrls: ['./user-basket-details.component.scss'],
})
export class UserBasketDetailsComponent implements OnInit {
    @Output() close: EventEmitter<number> = new EventEmitter();
    @Input() canModify = true;
    saveInProgress = false;
    saved = false;
    private selectedProviderCode: string;
    @Input() set userBasket(userBasket: UserBasketDto) {
        this.inUserBasket = userBasket;
    }

    inUserBasket: UserBasketDto;
    addAllInProgress = false;
    isDeliveryAvailableByPostCode = false;
    isPostCodeSet = false;
    isProviderSelectedForShopping: boolean;
    isLoading = false;
    faSpinner = faSpinner;
    faShoppingCart = faShoppingCart;
    faCheck = faCircleCheck;
    siteImageUrl: string;
    private isLoggedIn: boolean;

    constructor(
        private sessionService: SessionService,
        private userBasketService: UserBasketService,
        private createDialogService: CreateDialogService,
        private sharedModalService: SharedModalService,
        private authService: AuthenticationService,
        private snackBar: SnackBarService,
        private dialog: MatDialog,
        private translate: TranslateService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        baseService: ServiceBaseService
    ) {
        this.siteImageUrl = baseService.siteImageUrl;
        this.sessionService.isPostCodeSet.subscribe((result) => {
            this.isPostCodeSet = result;
        });

        this.sessionService.sessionSubject.subscribe((sessionSubject) => {
            this.isDeliveryAvailableByPostCode =
                sessionSubject.isDeliveryAvailableByPostCode;
        });

        this.authService.getAuthStatusListener().subscribe((newAuthStatus) => {
            this.isLoggedIn = newAuthStatus;
        });

        this.sessionService.isProviderSelectedForShopping.subscribe(
            (value) => (this.isProviderSelectedForShopping = value)
        );
    }

    get basketPrice(): number {
        return this.inUserBasket.userBasketProductList.reduce(
            (partialSum, basketProduct) =>
                partialSum + basketProduct.product.price * basketProduct.unitValue,
            0
        );
    }

    ngOnInit(): void {
        this.sessionService.sessionSubject.subscribe((sessionSubject) => {
            this.isDeliveryAvailableByPostCode =
                sessionSubject.isDeliveryAvailableByPostCode;

            if (sessionSubject?.selectedShops?.length > 0) {
                this.selectedProviderCode =
                    sessionSubject.selectedShopCodes[0].toLocaleLowerCase();
            }
        });
    }

    onMenuClickRenameUserBasket(nameInput: HTMLInputElement): void {
        nameInput.disabled = false;
        nameInput.focus();
    }

    onRenameUserBasket(nameInput: HTMLInputElement): void {
        this.userBasketService
            .renameUserBasket(this.inUserBasket.masterBasketID, nameInput.value)
            .subscribe(
                () => {
                    nameInput.disabled = true;
                },
                () => {
                    this.resetInput(nameInput);
                }
            );
    }

    resetInput(nameInput: HTMLInputElement): void {
        nameInput.value = this.inUserBasket.basketName;
        nameInput.disabled = true;
    }

    onClickRemoveUserBasket(): void {
        const dialogRef = this.dialog.open(DeleteShoppingListDialogComponent, {
            width: '330px',
            height: '416px',
            position: { top: '10vh' },
            data: {
                userBasketId: this.inUserBasket.masterBasketID,
                basketName: this.inUserBasket.basketName,
            },
            panelClass: ['animate__animated', 'animate__bounceIn'],
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'Delete') {
                this.close.emit();
            }
        });
    }

    onAddAll(): void {
        if (!this.isLoggedIn) {
            void this.sharedModalService.presentSignInModal();

            return;
        }

        let canAddToCartByPostCode = false;

        if (
            !this.isDeliveryAvailableByPostCode &&
            (!this.sessionService.sessionSubject.value?.zipCode ||
                !this.sessionService.sessionSubject.value?.isValidZip ||
                this.sessionService.sessionSubject.value?.zipCode.trim() === '')
        ) {
            this.createDialogService
                .openAddressDialog()
                .afterClosed()
                .subscribe(() => {
                    if (!this.isPostCodeSet) {
                        this.translate
                            .get('messages.add-your-postcode-b4-add-to-basket')
                            .subscribe((text) => {
                                this.snackBar.openSnackBar(text);
                                return;
                            });
                    } else {
                        canAddToCartByPostCode = true;
                    }
                });
        } else {
            canAddToCartByPostCode = true;
        }
        if (!canAddToCartByPostCode) {
            return;
        }

        if (!this.addAllInProgress) {
            this.addAllInProgress = true;
            this.userBasketService
                .addUserBasketToMasterBasket(
                    this.inUserBasket.masterBasketID,
                    this.inUserBasket.providerID
                )
                .subscribe(
                    () => {
                        this.translate
                            .get('messages.all-products-in-basket')
                            .subscribe((success) => {
                                this.snackBar.openSuccessSnackBar(success);
                                this.addAllInProgress = false;
                            });
                    },
                    () => {
                        this.translate.get('payment.other-error').subscribe((error) => {
                            this.snackBar.openSnackBar(error);
                            this.addAllInProgress = false;
                        });
                    }
                );
        }
    }

    onAddSharedLink(): void {
        if (this.saveInProgress || this.saved) return;

        if (!this.isLoggedIn) {
            void this.sharedModalService.presentSignInModal();

            return;
        }

        this.saveInProgress = true;
        this.userBasketService
            .createUserBasket(
                this.inUserBasket.basketName,
                this.selectedProviderCode
                    ? this.selectedProviderCode
                    : this.inUserBasket.providerName.toLocaleLowerCase()
            )
            .subscribe(
                (masterBasketID) => {
                    if (
                        masterBasketID &&
                        this.inUserBasket.userBasketProductList?.length > 0
                    ) {
                        const productsObservableList = [];
                        this.inUserBasket.userBasketProductList.forEach((product) => {
                            productsObservableList.push(
                                this.userBasketService.addProductToUserBasket(
                                    masterBasketID,
                                    product.product.productID,
                                    product.unitValue
                                )
                            );
                        });

                        forkJoin(productsObservableList).subscribe(
                            (res) => {
                                if (res) {
                                    this.saveInProgress = false;
                                    this.saved = true;
                                }
                            },
                            () => {
                                this.translate
                                    .get('payment.other-error')
                                    .subscribe((error) => {
                                        this.snackBar.openErrorSnackBar(error);
                                        this.saved = false;
                                        this.saveInProgress = false;
                                    });
                            }
                        );
                    }
                },
                () => {
                    this.translate.get('payment.other-error').subscribe((error) => {
                        this.snackBar.openErrorSnackBar(error);
                        this.saved = false;
                        this.saveInProgress = false;
                    });
                }
            );
    }

    deleteFromList(productId: number) {
        this.userBasketService
            .removeProductFromUserBasket(
                this.inUserBasket.masterBasketID,
                productId,
                this.inUserBasket.providerID
            )
            .subscribe(() => {
                this.close.emit(productId);
            });
    }

    onShare(): void {
        void this.sharedModalService.openShareShoppingList(
            this.inUserBasket.masterBasketID,
            this.inUserBasket.userMasterBasketGUID,
            this.inUserBasket.providerID,
            true
        );
    }

    handleBack(): void {
        this.close.emit();
    }

    onProviderSelect(): void {
        if (this.isLoading) return;

        this.isLoading = true;

        this.sessionService.sessionSubject.pipe(take(1)).subscribe((session) => {
            if (!session.availableShops.includes(this.inUserBasket.providerName)) {
                this.translate
                    .get('messages.shop-not-available-postcode')
                    .subscribe((notAvailable) => {
                        this.snackBar.openErrorSnackBar(notAvailable);
                    });

                return;
            }

            const queryParams: Params = {
                providerName: this.inUserBasket.providerName,
            };

            void this.router.navigate([], {
                relativeTo: this.activatedRoute,
                queryParams,
                queryParamsHandling: 'merge',
            });
            const configureSessionDto = new ConfigureSessionDto({
                userSelectedShops: [this.inUserBasket.providerName],
            });
            this.sessionService
                .configureUserSession(configureSessionDto)
                .subscribe(() => {
                    this.sessionService.isProviderSelectedForShopping.next(true);
                    this.isLoading = false;
                });
        });
    }
}
