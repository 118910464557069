import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { DiscountNextLevelDialogComponent } from './discount-next-level-dialog/discount-next-level-dialog.component';
import { DiscountSummaryComponent } from './discount-summary/discount-summary.component';
import { DiscountInfoDialogComponent } from './discount-info-dialog/discount-info-dialog.component';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';

@NgModule({
    declarations: [
        DiscountNextLevelDialogComponent,
        DiscountSummaryComponent,
        DiscountInfoDialogComponent,
    ],
    imports: [CommonModule, TranslateModule, RouterModule, SharedPipesModule],
    exports: [
        DiscountNextLevelDialogComponent,
        DiscountSummaryComponent,
        DiscountInfoDialogComponent,
    ],
})
export class RokshDiscountModule {}
