import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlMarketingComponent } from './html-marketing.component';

@NgModule({
    declarations: [HtmlMarketingComponent],
    imports: [CommonModule],
    exports: [HtmlMarketingComponent],
})
export class HtmlMarketingModule {}
