<div class="clearfix"></div>
<div class="w-100 d-flex flex-column align-items-center">
    <div class="shop-container">
        <ng-container *ngIf="isShopChanging">
            <div class="text-center w-100">
                <img
                    alt="loading"
                    src="/assets/loading_kosar.gif"
                    style="margin-top: 50px"
                /><br />
                <p>{{ 'shop-select-dialog.shop-selected' | translate }}</p>
            </div>
        </ng-container>

        <ng-container *ngIf="!isShopChanging">
            <div>
                <ng-container *ngIf="!isValidZip">
                    <div class="col-12 my-2 text-center">
                        <h2 class="w-100 w-md-50 mx-auto mt-3">
                            {{ 'common.my-favourite-shops' | translate }}
                        </h2>
                    </div>
                    <div class="mt-4 mt-md-0 shops-order text-center">
                        <div
                            class="d-flex flex-column"
                            *ngFor="let shop of availableShops"
                            (click)="selectThisShop(shop)"
                        >
                            <div
                                class="shop-box shop-box-width d-flex justify-content-center align-items-center"
                            >
                                <img
                                    #img
                                    alt="provider logo"
                                    (error)="img.style.display = 'none'"
                                    class="shop-img"
                                    src="{{ siteImageUrl }}logos/{{ shop }}-logo-s.png"
                                />
                            </div>

                            <p
                                class="fs-14 shop-box-width mt-2"
                                *ngIf="shop.length < 10; else shorter"
                            >
                                {{ shop }}
                            </p>

                            <ng-template #shorter>
                                <p class="fs-14 shop-box-width mt-2">
                                    {{ shop.slice(0, 9) }}...
                                </p>
                            </ng-template>
                        </div>
                        <div *ngIf="isAtCountryCode" class="shop-box-width mb-5 me-4">
                            <div class="flex-container more-shop w-100">
                                <div class="text-size-14 more-shop-text">
                                    <i>
                                        {{ 'common.more-shops-are-coming' | translate }}
                                    </i>
                                </div>
                                <div class="text-white">.</div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <!----------------We dont deliver to this zipcode------->
                <ng-container *ngIf="isValidZip">
                    <!----------------Available providers to shopping------------------>
                    <ng-container *ngIf="availableShops?.length">
                        <div class="d-flex container-shop-box">
                            <form [formGroup]="ShopHandlerForm" class="w-100">
                                <div
                                    *ngIf="
                                        onlyOneShopSelected.length === 1 &&
                                        onlyOneShopSelected.indexOf(
                                            availableShopWBasketInfos[0].shopName
                                        ) > -1
                                    "
                                    class="mb-5"
                                >
                                    <h5 class="mt-4 mb-3 font-weight-bolder title-text">
                                        {{
                                            'common.your-shopping-in-progress' | translate
                                        }}
                                    </h5>
                                    <div
                                        class="w-100 selected-shop-box d-flex justify-content-between align-items-center px-4 px-md-5"
                                        (click)="
                                            selectThisShop(
                                                availableShopWBasketInfos[0].shopName
                                            )
                                        "
                                    >
                                        <div class="w-100 d-flex align-items-center">
                                            <img
                                                #img
                                                alt="provider logo"
                                                (error)="img.style.display = 'none'"
                                                class="selected-logo"
                                                src="{{ siteImageUrl }}logos/{{
                                                    availableShopWBasketInfos[0].shopName
                                                }}-logo-s.png"
                                            />
                                            <label
                                                class="m-0 selected-label"
                                                *ngIf="
                                                    userSelectedAvailableShops &&
                                                    onlyOneShopSelected.indexOf(
                                                        availableShopWBasketInfos[0]
                                                            .shopName
                                                    ) > -1
                                                "
                                            >
                                                <input
                                                    checked
                                                    type="radio"
                                                    style="float: left"
                                                    name="shop-selector"
                                                    value="{{
                                                        availableShopWBasketInfos[0]
                                                            .shopName
                                                    }}"
                                                />
                                                <input
                                                    checked
                                                    type="radio"
                                                    style="float: left"
                                                    name="shop-selector"
                                                    value="{{
                                                        availableShopWBasketInfos[0]
                                                            .shopName
                                                    }}"
                                                    (change)="
                                                        selectThisShop(
                                                            availableShopWBasketInfos[0]
                                                                .shopName
                                                        )
                                                    "
                                                />

                                                <span class="checkmark"></span>
                                            </label>

                                            <strong class="text-start ps-3">
                                                <span class="d-none d-md-block">
                                                    {{
                                                        availableShopWBasketInfos[0]
                                                            .shopNameToShow
                                                    }}
                                                </span>
                                                <span class="d-block d-md-none">
                                                    <span
                                                        *ngIf="
                                                            availableShopWBasketInfos[0]
                                                                .shopNameToShow.length <
                                                                10;
                                                            else shorter
                                                        "
                                                    >
                                                        {{
                                                            availableShopWBasketInfos[0]
                                                                .shopNameToShow
                                                        }}
                                                    </span>

                                                    <ng-template #shorter>
                                                        {{
                                                            availableShopWBasketInfos[0].shopNameToShow.slice(
                                                                0,
                                                                9
                                                            )
                                                        }}...
                                                    </ng-template>
                                                </span>
                                            </strong>
                                        </div>
                                        <div class="text-center text-md-right">
                                            <div
                                                class="d-flex selected-shop-name-container"
                                            >
                                                <div style="width: 55px">
                                                    <div
                                                        class="position-absolute text-right text-size-20 font-weight-400"
                                                        style="
                                                            width: 45px;
                                                            color: darkorange;
                                                            margin-top: -3px;
                                                        "
                                                    >
                                                        {{
                                                            availableShopWBasketInfos[0]
                                                                .basketTotalItems === -1
                                                                ? 0
                                                                : availableShopWBasketInfos[0]
                                                                      .basketTotalItems
                                                        }}
                                                    </div>
                                                    <img
                                                        src="assets/svg/basket_orange.svg"
                                                        alt="Kosár ikon"
                                                        class="basket-icon mt-2"
                                                        style="width: 45px"
                                                    />
                                                </div>
                                                <span
                                                    class="ms-3 mt-2 d-none d-md-block continue-text"
                                                    style="color: darkorange"
                                                >
                                                    {{
                                                        'common.i-am-continue-shopping'
                                                            | translate
                                                    }}
                                                    <fa-icon
                                                        [icon]="faAngleRight"
                                                        class="menu-nav-resp pointer"
                                                        size="lg"
                                                    ></fa-icon>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <h3
                                    class="d-none d-md-block text-black text-center font-weight-bolder my-4"
                                    id="selectShop"
                                >
                                    {{ 'common.choose-from-our-shops' | translate }}
                                </h3>
                                <div
                                    class="mt-4 mt-md-0 shops-order text-center"
                                    [ngClass]="{
                                        'more-shop-closed': showState
                                    }"
                                >
                                    <div
                                        class="d-flex flex-column"
                                        *ngFor="let shop of availableShopWBasketInfos"
                                        (click)="selectThisShop(shop.shopName)"
                                    >
                                        <div
                                            class="shop-box shop-box-width d-flex justify-content-center align-items-center"
                                        >
                                            <img
                                                #img
                                                alt="provider logo"
                                                (error)="img.style.display = 'none'"
                                                class="shop-img"
                                                src="{{ siteImageUrl }}logos/{{
                                                    shop.shopName
                                                }}-logo-s.png"
                                            />
                                        </div>

                                        <p
                                            class="fs-14 shop-box-width mt-2"
                                            *ngIf="
                                                shop.shopNameToShow.length < 12;
                                                else shorter
                                            "
                                        >
                                            {{ shop.shopNameToShow }}
                                        </p>

                                        <ng-template #shorter>
                                            <p class="fs-14 shop-box-width mt-2">
                                                {{ shop.shopNameToShow.slice(0, 9) }}...
                                            </p>
                                        </ng-template>
                                    </div>
                                    <div *ngIf="isAtCountryCode" class="shop-box-width">
                                        <div
                                            class="flex-container p-1 more-shop w-100 py-2 px-1 my-2"
                                        >
                                            <div class="text-size-14 more-shop-text">
                                                <i>
                                                    {{
                                                        'common.more-shops-are-coming'
                                                            | translate
                                                    }}
                                                </i>
                                            </div>
                                            <div class="text-white">.</div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!isAtCountryCode" class="text-center mt-2">
                                    <span
                                        class="show-more fw-bolder text-size-16"
                                        (click)="setShowMore(!showState)"
                                    >
                                        {{ showText }}
                                    </span>
                                    <span class="chevron-icon"
                                        ><fa-icon
                                            class="fa-2xs align-baseline"
                                            [icon]="
                                                showState ? faChevronDown : faChevronUp
                                            "
                                        ></fa-icon
                                    ></span>
                                </div>
                            </form>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-template></ng-template>
                <p *ngIf="!isValidZip && !isFirst" class="text-danger">
                    {{ 'messages.not-valid-postcode' | translate }}
                    <br />
                </p>
            </div>
        </ng-container>
    </div>
</div>
<hr class="d-block d-md-none opacity-8" />
