<div class="text-end w-100 close-button">
    <span class="pointer" (click)="dialogRef.close()">
        <img class="close-icon" src="assets/svg/rokshImages/icons/close.svg" />
    </span>
</div>

<div class="row">
    <div class="row w-100">
        <mat-panel-title class="col-12 text-center dialog-title">
            <strong class="w-100 title">
                {{ 'messages.add-postcode-and-address' | translate }}
            </strong>
        </mat-panel-title>
    </div>
    <div
        *ngIf="alertMessage"
        class="alert d-flex align-items-center justify-content-center mb-4"
    >
        {{ alertMessage }}
    </div>
    <form class="col-12 justify-content-center" [formGroup]="addressForm">
        <div class="row mb-3">
            <div class="column col-12 col-md-6">
                <div class="clearfix"></div>
                <mat-form-field appearance="standard" class="field py-0 w-100">
                    <mat-label>{{ 'common.post-code' | translate }}</mat-label>
                    <img
                        #icon
                        src="assets/address-dialog/zip.svg"
                        class="pe-2"
                        alt="zip code"
                    />
                    <input
                        (focus)="highlightIcon(icon)"
                        (blur)="deHighlightIcon(icon)"
                        formControlName="PostCode"
                        class="address-form-input"
                        matInput
                        maxlength="4"
                        placeholder="{{ 'common.post-code' | translate }}"
                        id="irsz"
                        required
                        (input)="resetPostCode()"
                    />
                    <mat-error
                        *ngIf="
                            addressForm.get('PostCode').errors &&
                            addressForm.get('PostCode').touched
                        "
                    >
                        {{ 'common.post-code-required' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard" class="field py-0 w-100">
                    <mat-label>{{ 'common.city-name' | translate }}</mat-label>
                    <img
                        #icon2
                        src="assets/address-dialog/city.svg"
                        class="pe-2"
                        alt="city"
                    />
                    <input
                        (focus)="highlightIcon(icon2)"
                        (blur)="deHighlightIcon(icon2)"
                        formControlName="City"
                        matInput
                        class="address-form-input"
                        placeholder="{{ 'common.city-name' | translate }}"
                        maxlength="49"
                        id="addr"
                        required
                        (input)="resetToDefault()"
                    />
                    <mat-error
                        *ngIf="
                            addressForm.get('City').errors &&
                            addressForm.get('City').touched
                        "
                    >
                        {{ 'common.city-name-required' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard" class="field py-0 w-100">
                    <mat-label>{{ 'common.street' | translate }}</mat-label>
                    <img
                        #icon3
                        src="../../../assets/address-dialog/house.svg"
                        class="pe-2"
                        alt="street"
                    />
                    <input
                        (focus)="highlightIcon(icon3)"
                        (blur)="deHighlightIcon(icon3)"
                        formControlName="StreetName"
                        matInput
                        class="address-form-input"
                        placeholder="{{ 'common.street' | translate }}"
                        maxlength="100"
                        id="streetName"
                        required
                        (input)="resetToDefault()"
                    />
                    <mat-error
                        *ngIf="
                            addressForm.get('StreetName').errors &&
                            addressForm.get('StreetName').touched
                        "
                    >
                        {{ 'common.street-required' | translate }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard" class="field py-0 w-100">
                    <mat-label>{{ 'common.housenumber' | translate }}</mat-label>
                    <img
                        #icon4
                        src="assets/address-dialog/number.svg"
                        class="pe-2"
                        alt="house number"
                    />
                    <input
                        (focus)="highlightIcon(icon4)"
                        (blur)="deHighlightIcon(icon4)"
                        formControlName="HouseNumber"
                        matInput
                        class="address-form-input"
                        placeholder="{{ 'common.housenumber' | translate }}"
                        maxlength="49"
                        id="houseNumber"
                        required
                        (input)="resetToDefault()"
                    />
                    <mat-error
                        *ngIf="
                            addressForm.get('HouseNumber').errors &&
                            addressForm.get('HouseNumber').touched
                        "
                    >
                        {{ 'common.housenumber-required' | translate }}
                    </mat-error>
                </mat-form-field>
                <div *ngIf="userMessage" class="user-message mb-3" role="alert">
                    {{ userMessage }}
                </div>
                <mat-action-row
                    *ngIf="!isShowNextButton"
                    class="text-center w-100 px-0 py-3"
                >
                    <button
                        class="roksh-btn-primary w-100 gradient mb-2 mt-3 address-btn"
                        (click)="checkAddress()"
                        [disabled]="!addressForm.valid"
                    >
                        {{ 'common.check-address' | translate }}
                    </button>
                </mat-action-row>
                <mat-action-row
                    *ngIf="isShowNextButton"
                    class="text-center w-100 px-0 py-3"
                >
                    <button
                        class="roksh-btn-primary w-100 gradient mb-2 mt-3 address-btn"
                        [disabled]="isLoading || isNextButtonDisabled"
                        (click)="nextStep()"
                    >
                        {{ 'common.next' | translate }}
                    </button>
                </mat-action-row>
                <mat-action-row *ngIf="isShowSubscriptionButton">
                    <a
                        class="btn btn-info w-100 mt-3"
                        href="{{ 'router.i-subscribe-form' | translate }}"
                        (click)="dialogRef.close()"
                        id="goToKezdooldal"
                        >{{ 'common.i-subscribe' | translate }}</a
                    >
                </mat-action-row>
            </div>
            <div class="column col-12 col-md-6 text-center">
                <div *ngIf="addressIsValid; else notValid">
                    <iframe
                        [src]="url | safe"
                        height="330"
                        width="341"
                        loading="lazy"
                        style="max-width: 100%"
                    >
                    </iframe>
                </div>
                <ng-template #notValid>
                    <img
                        src="assets/svg/rokshImages/images/location_img.svg"
                        class="location-image"
                        alt="Location image"
                    />
                </ng-template>
            </div>
        </div>
    </form>
</div>
