import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CategoryBlockType } from '../types/categoryblock-type';
import { ProductBlockListRequestDTO, ProductListClient } from '..';
import { ServiceBaseService } from './service-base.service';
import { ProductBlockDto } from '../models/DTO/product-block-dto';

@Injectable({ providedIn: 'root' })
export class DashboardService {
    shopServiceUrl: string;

    constructor(
        private http: HttpClient,
        private serviceBaseService: ServiceBaseService,
        private productListClient: ProductListClient
    ) {
        this.shopServiceUrl = this.serviceBaseService.getBaseUrl();
    }

    getProductBlockList(
        progId: string,
        page?: number,
        typeId?: number,
        rokshlanding?: boolean,
        isIncognitoRokshLanding = false
    ): Observable<ProductBlockDto[]> {
        const productBlockListRequestDto: ProductBlockListRequestDTO =
            new ProductBlockListRequestDTO();
        productBlockListRequestDto.progId = progId;
        productBlockListRequestDto.page = page;
        productBlockListRequestDto.numberOfItems =
            typeId == CategoryBlockType.commercial ? 4 : 24;
        productBlockListRequestDto.categoryBlockTypeId = typeId;
        productBlockListRequestDto.rokshLanding = rokshlanding;
        productBlockListRequestDto.isIncognitoRokshLanding = isIncognitoRokshLanding;

        return this.http.post<ProductBlockDto[]>(
            this.shopServiceUrl + 'productlist/ProductBlockList',
            productBlockListRequestDto
        );
    }
}
