import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CookieHandlerService {
    /*
     * General utils for managing cookies in Typescript.
     */
    setCookie(name: string, val: string) {
        const date = new Date();
        const value = val;

        // Set it expire in 7 days
        date.setTime(date.getTime() + 70 * 24 * 60 * 60 * 1000);

        // Set it
        document.cookie =
            name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
    }

    getCookie(name: string) {
        const value = '; ' + document.cookie;
        const parts = value.split('; ' + name + '=');

        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
    }

    deleteCookie(name: string) {
        const date = new Date();

        // Set it expire in -1 days
        date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

        // Set it
        document.cookie = name + '=; expires=' + date.toUTCString() + '; path=/';
    }
}
