import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShoppingListCardComponent } from './shopping-list-card/shopping-list-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { ShareBasketByEmailDialogComponent } from './share-basket-by-email-dialog/share-basket-by-email-dialog.component';
import { MatInputModule } from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CreateShoppingListComponent } from './create-shopping-list/create-shopping-list.component';
import { ProviderSelectModule } from '../provider-select/provider-select.module';
import { AddProductToListComponent } from './add-product-to-list/add-product-to-list.component';
import { AddToListRowComponent } from './add-product-to-list/product-to-user-basket-row/add-to-list-row.component';
import { DeleteShoppingListDialogComponent } from './delete-shopping-list/delete-shopping-list-dialog.component';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';

@NgModule({
    declarations: [
        ShoppingListCardComponent,
        ShareBasketByEmailDialogComponent,
        CreateShoppingListComponent,
        AddProductToListComponent,
        AddToListRowComponent,
        DeleteShoppingListDialogComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MatInputModule,
        FontAwesomeModule,
        ClipboardModule,
        ReactiveFormsModule,
        FormsModule,
        ProviderSelectModule,
        SharedPipesModule,
    ],
    exports: [
        ShoppingListCardComponent,
        ShareBasketByEmailDialogComponent,
        CreateShoppingListComponent,
        AddProductToListComponent,
        AddToListRowComponent,
        DeleteShoppingListDialogComponent,
    ],
})
export class ShoppingListModule {}
