<div
    *ngIf="isHeaderDisplayed"
    class="row p-3 px-md-4 px-lg-5 vw-100 m-0 navbar provider-header"
>
    <!--LOGO, STORES buttons-->
    <div class="col-6 col-md-3 col-lg-2" [class.d-md-none]="isOwnWebShop">
        <!--        MOBILE-->
        <div class="d-flex d-md-none align-items-center h-100">
            <div class="hamburger-container" (click)="showResponsiveMenu()">
                <div class="bar1"></div>
                <div class="bar2"></div>
                <div class="bar3"></div>
            </div>
            <a [routerLink]="['.']" class="ps-3" *ngIf="!isOwnWebShop">
                <img
                    src="assets/svg/Logo_slogen.svg"
                    alt="{{ 'info.alt-desktop-logo' | translate }}"
                    class="sk-logo-resp w-100"
                />
            </a>
        </div>
        <!--        OTHER SIZES-->
        <div class="d-none d-md-block h-100" *ngIf="!isOwnWebShop">
            <a [routerLink]="['.']" class="text-decoration-none d-flex h-100">
                <img
                    src="assets/svg/roksh-logo-wo-slogen.png"
                    alt="{{ 'info.alt-desktop-logo' | translate }}"
                    class="sk-logo h-100"
                />
                <div
                    *ngIf="!isInformativeLandingScreen"
                    class="pointer d-flex justify-content-center align-items-center text-white ps-3 fs-16"
                    [routerLink]="['.']"
                >
                    <fa-icon [icon]="faChevronLeft"></fa-icon>
                    &nbsp;{{ 'common.our-shops' | translate }}
                </div>
            </a>
        </div>
    </div>
    <ng-template [ngIf]="!isInformativeLandingScreen">
        <!--SEARCH-->
        <div
            class="col-12 col-md-5 col-lg-3 order-3 order-md-2 mt-2 mt-md-0 px-md-2"
            [class.col-lg-6]="!isRokshDiscountAvailable"
            [class.col-lg-4]="isOwnWebShop && isRokshDiscountAvailable"
        >
            <app-search></app-search>
        </div>
        <!--DISCOUNT-->
        <div
            *ngIf="isRokshDiscountAvailable"
            class="col-12 col-lg-4 order-last order-md-2 order-lg-3 mt-2 mt-lg-0"
            [class.col-md-4]="isOwnWebShop"
            [class.col-lg-6]="isOwnWebShop"
            [class.order-md-last]="!isOwnWebShop"
        >
            <lib-discount-summary
                (showRokshDiscountInfoEmitter)="showRokshDiscountInfo()"
            ></lib-discount-summary>
        </div>
    </ng-template>
    <!--ADDRESS INPUT-->
    <ng-template [ngIf]="isAddAddressInputDisplayed">
        <div class="col-12 col-md-4 col-lg-6 order-last order-md-2 mt-2 mt-md-0">
            <app-add-address-input></app-add-address-input>
        </div>
    </ng-template>
    <!--ADDRESS BUTTON-->
    <div *ngIf="isAddAddressButtonDisplayed" class="col-12 text-center order-last h-auto">
        <span class="d-none d-md-block text-white">
            {{ 'common.give-us-your-address' | translate }}
        </span>
        <app-add-address-button></app-add-address-button>
    </div>
    <!--LOGIN/LOGOUT, BASKET buttons-->
    <div class="col-6 col-md-3 col-lg-2 order-md-3 order-lg-4 d-flex justify-content-end">
        <app-log-in></app-log-in>
        <div *ngIf="isPostCodeSet">
            <div class="round-basket-btn pointer gradient" (click)="onOpenBasket()">
                <div
                    class="position-absolute text-white top-0 start-3px mt-1 text-center text-size-20 font-weight-600 w-100"
                >
                    {{ prodCount }}
                </div>
                <img
                    src="assets/svg/Kosar.svg"
                    alt="Kosár ikon"
                    class="basket-icon mt-3"
                />
            </div>
        </div>
    </div>
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</div>
<app-mobil-menu></app-mobil-menu>
