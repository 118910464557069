import { Component, Input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-aboutus',
    templateUrl: './aboutus.component.html',
    styleUrls: ['./aboutus.component.scss'],
})
export class AboutUsComponent {
    faAngleRight = faAngleRight;

    @Input() isReduced = false;
    constructor(public mat: MatExpansionModule) {}
}
