import { InjectionToken } from '@angular/core';
import { RedirectProductModel } from '../../models/data-layer/redirect-product.model';

export const DATALAYER_SERVICE_IMPL = new InjectionToken<IDataLayerService>(
    'dataLayerServiceImpl'
);

export interface IDataLayerService {
    datalayerUniversalPush(
        category?: string,
        action?: string,
        label?: string,
        value?: string
    ): void;

    purchaseProducts(redirectModel: RedirectProductModel);
    initiateCheckout(redirectModel: RedirectProductModel);
}
