import { ProductNutritionHeader } from './product-nutrition-header.model';
import { ProductNutritionElement } from './product-nutrition-element.model';

export class ProductNutritionShared {
    header: ProductNutritionHeader;
    nutritionArray: ProductNutritionElement[];

    constructor() {
        this.header = new ProductNutritionHeader();
        this.nutritionArray = [];
    }
}
