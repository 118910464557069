import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
    ConfirmDialogComponent,
    ConfirmDialogModel,
} from '../components/confirm-dialog/confirm-dialog.component';

@Injectable({ providedIn: 'root' })
export class DialogService {
    constructor(public matDialog: MatDialog, private translate: TranslateService) {}

    confirmDialog(message = this.translate.instant('common.are-you-sure')): Promise<any> {
        const p = new Promise<any>((resolve) => {
            const dialogData = new ConfirmDialogModel(
                this.translate.instant('common.confirmation'),
                message
            );

            const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
                maxWidth: '400px',
                data: dialogData,
            });

            dialogRef.afterClosed().subscribe((dialogResult) => {
                resolve(dialogResult);
            });
        });

        return p;
    }
}
