import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import {
    AddOrUpdateOrderItemDTOShared,
    BasketService,
    IMyOrder,
    IMyOrderItem,
    MyOrderItemProductDto,
    OrderCustomerStatus,
    OrderService,
    ProductProviderDto,
    RestrictionCheckerService,
    ServiceBaseService,
    TypeAheadResult,
} from 'projects/library-shared/src/public-api';
import { map } from 'rxjs/operators';
import {
    faMinus,
    faPlus,
    faSpinner,
    faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from '../../../services/dialog.service';
import { CreateDialogService } from '../../../services/create-dialog.service';

@Component({
    templateUrl: './add-or-update-order-item.component.html',
    styleUrls: ['./add-or-update-order-item.component.scss'],
})
export class AddOrUpdateOrderItemDialogComponent {
    faTimesCircle = faTimesCircle;

    faMinus = faMinus;
    faPlus = faPlus;
    faSpinner = faSpinner;

    isAddInProgress = false;
    quantityChangeInProgress = false;

    errorMessage = '';

    orderItem: IMyOrderItem;
    product: MyOrderItemProductDto;
    typeAheadRes: TypeAheadResult;

    addOrUpdateOrderItemDTO = new AddOrUpdateOrderItemDTOShared();

    order: IMyOrder;
    productProvider: ProductProviderDto;

    btnDelete: boolean;

    isTotalPriceValid = true;
    showTotalPriceWarningMessage = false;
    belowMinPrice = false;
    belowMinPriceForDelete = false;
    quantityList: [number, string][];

    constructor(
        public dialogRef: MatDialogRef<AddOrUpdateOrderItemDialogComponent>,
        private dialogService: DialogService,
        public baseService: ServiceBaseService,
        private orderService: OrderService,
        @Inject(MAT_DIALOG_DATA) public input: any,
        private restrictionService: RestrictionCheckerService,
        private createDialogService: CreateDialogService,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private basketService: BasketService
    ) {
        const data = JSON.parse(JSON.stringify(input));

        this.orderItem = data.orderItem;
        this.order = data.order;
        this.typeAheadRes = data.typeAheadResult;

        this.addOrUpdateOrderItemDTO.OrderCode = this.order.OrderCode;
        this.addOrUpdateOrderItemDTO.ProviderID = this.order.ProviderID;
        this.initDialogData();
    }

    initDialogData() {
        if (this.typeAheadRes) {
            this.order.OrderedItems.forEach((item) => {
                if (
                    item.ProductID == this.typeAheadRes.ProductID &&
                    this.order.OrderCustomerStatus === OrderCustomerStatus.STORED
                ) {
                    this.addOrUpdateOrderItemDTO.Quantity = item.Quantity;
                    this.product = item.Product;
                    this.product.productProvider.forEach((item) => {
                        if (item.providerID == this.order.ProviderID) {
                            this.productProvider = item;
                            this.addOrUpdateOrderItemDTO.ProductID =
                                this.productProvider.productID;
                        }
                    });
                }
            });

            if (this.product == null) {
                this.orderService
                    .getProduct(this.typeAheadRes.ProductID, this.order.ProviderID)
                    .subscribe((value) => {
                        this.product = value;
                        this.product.productProvider.forEach((item) => {
                            if (item.providerID == this.order.ProviderID) {
                                this.productProvider = item;
                                this.addOrUpdateOrderItemDTO.ProductID =
                                    this.productProvider.productID;
                            }
                        });
                        this.addOrUpdateOrderItemDTO.Quantity = this.getMinStep();

                        this.isAddInProgress = true;

                        this.quantityList = this.basketService.createQuantityList(
                            this.product.minWeightStep,
                            this.product.maxWeightStep,
                            this.product.isBulk,
                            this.product.priceUnitType
                        );
                    });
            }
        } else {
            this.addOrUpdateOrderItemDTO.Quantity = this.orderItem.Quantity;
            this.product = this.orderItem.Product;
            this.product.productProvider.forEach((item) => {
                if (item.providerID == this.order.ProviderID) {
                    this.productProvider = item;
                    this.addOrUpdateOrderItemDTO.ProductID =
                        this.productProvider.productID;
                }
            });
            this.quantityList = this.basketService.createQuantityList(
                this.product.minWeightStep,
                this.product.maxWeightStep,
                this.product.isBulk,
                this.product.priceUnitType
            );
        }

        this.checkBelowMinPriceForDelete();
        this.btnDelete = this.belowMinPriceForDelete;
    }

    deleteError() {
        this.translate
            .get('messages.min-order-value', {
                minValue: this.restrictionService.getMinBasketValue(),
            })
            .subscribe((text) => (this.errorMessage = text));
    }

    stepQuantity(dx: number) {
        if (this.quantityChangeInProgress) return;
        if (
            this.addOrUpdateOrderItemDTO.Quantity == this.getMinStep() &&
            dx == this.addOrUpdateOrderItemDTO.Quantity - 1
        )
            return;
        if (
            this.addOrUpdateOrderItemDTO.Quantity == this.getMaxStep() &&
            dx == this.addOrUpdateOrderItemDTO.Quantity + 1
        )
            return;

        this.quantityChangeInProgress = true;

        this.addOrUpdateOrderItemDTO.Quantity = dx;

        this.errorMessage = '';

        this.checkBelowMinPrice();
        this.checkOverWeight();

        this.quantityChangeInProgress = false;
    }

    getMinStep(): number {
        let calcMin =
            this.productProvider.minWeightStep ?? this.product.minWeightStep ?? 0;
        if (calcMin.valueOf() === 0) {
            calcMin = this.product?.isBulk ? 0.5 : 1;
        }

        return calcMin;
    }

    getMaxStep(): number {
        let calcMax =
            this.productProvider.maxWeightStep ?? this.product.maxWeightStep ?? 0;
        if (calcMax.valueOf() === 0) calcMax = this.product?.isBulk ? 5 : 24;

        return calcMax;
    }

    checkOverWeight() {
        let TotalWeight = 0;

        this.order.OrderedItems.forEach((item) => {
            if (item.ProductID != this.product?.productID) {
                item.Product?.productProvider?.forEach((pp) => {
                    if (pp.providerID == this.order.ProviderID) {
                        TotalWeight += pp?.packageQuantity * item.Quantity;
                    }
                });
            }
        });
        TotalWeight +=
            this.productProvider?.packageQuantity * this.addOrUpdateOrderItemDTO.Quantity;

        if (this.restrictionService.isMaxBasketWeightExceeded(TotalWeight)) {
            forkJoin([
                this.translate.get('messages.your-basket-now'),
                this.translate.get('messages.your-basket-now-above-limit-5', {
                    maxWeight: this.restrictionService.getMaxBasketWeight() / 1000,
                }),
            ]).subscribe(([first, second]: [string, string]) => {
                this.errorMessage = first + ' ' + TotalWeight / 1000 + 'kg.  ' + second;
            });

            return true;
        }

        return false;
    }

    btnModify() {
        this.checkBelowMinPrice();
        return !(this.belowMinPrice || this.checkOverWeight());
    }

    checkBelowMinPriceForDelete() {
        let TotalPrice = 0;

        let difference = 0;
        this.order.OrderedItems.forEach((item) => {
            if (item.IsNew) {
                const priceAct =
                    item.OrderReplacementItem != null
                        ? item.OrderReplacementItem.Price
                        : item.Price;
                if (
                    item.IsRemovedByCustomer ||
                    item.IsMissing ||
                    (item.OrderRemovedItemID != null && item.OrderReplacementItem == null)
                ) {
                    difference -= priceAct;
                } else {
                    difference += priceAct;
                }
            }
        });
        if (difference < 0.0001) difference = 0;

        difference -= this.orderItem?.Price * this.orderItem?.Quantity;

        this.orderService.getOrderTotalPrice(this.order.OrderId, difference).pipe(
            map((result) => {
                TotalPrice = Math.ceil(result as number);

                if (this.restrictionService.isMinBasketValueBelow(TotalPrice)) {
                    this.belowMinPriceForDelete = true;
                }

                this.belowMinPriceForDelete = false;
            })
        );
    }

    checkBelowMinPrice() {
        let TotalPrice = 0;

        let difference = 0;
        this.order.OrderedItems.forEach((item) => {
            if (item.IsNew || item.IsRemovedByCustomer) {
                const priceAct =
                    item.OrderReplacementItem != null
                        ? item.OrderReplacementItem.Price
                        : item.Price;
                if (
                    item.IsRemovedByCustomer ||
                    item.IsMissing ||
                    (item.OrderRemovedItemID != null && item.OrderReplacementItem == null)
                ) {
                    difference -= priceAct;
                } else {
                    difference += priceAct;
                }
            }
        });

        if (difference < 0.0001) difference = 0;

        difference += this.productProvider?.price * this.addOrUpdateOrderItemDTO.Quantity;
        this.orderService.getOrderTotalPrice(this.order.OrderId, difference).pipe(
            map((res) => {
                TotalPrice = Math.ceil(res as number);

                if (this.restrictionService.isMinBasketValueBelow(TotalPrice)) {
                    this.translate
                        .get('messages.min-order-value', {
                            minValue: this.restrictionService.getMinBasketValue(),
                        })
                        .subscribe((text) => (this.errorMessage = text));

                    this.belowMinPrice = true;
                }

                this.belowMinPrice = false;
            })
        );
    }

    onAdd() {
        this.checkTotalPrice();
        this.showTotalPriceWarningMessage = !this.isTotalPriceValid;

        if (this.checkOverWeight() || !this.isTotalPriceValid) return;

        this.orderService.AddOrUpdateOrderItem(this.addOrUpdateOrderItemDTO).subscribe(
            (res) => {
                this.dialogRef.close(res);
            },
            (err) => {
                if (err.error.indexOf('basket-now-above-limit') > -1) {
                    this.snackBar.open(
                        this.translate.instant(err.error.split('|')[0], {
                            maxWeight: err.error.split('|')[1],
                        }),
                        null,
                        {
                            verticalPosition: 'top',
                            horizontalPosition: 'center',
                            duration: 5000,
                        }
                    );
                } else if (err.error.indexOf('min-order-value') > -1) {
                    this.snackBar.open(
                        this.translate.instant(err.error.split('|')[0], {
                            minValue: err.error.split('|')[1],
                        }),
                        null,
                        {
                            verticalPosition: 'top',
                            horizontalPosition: 'center',
                            duration: 5000,
                        }
                    );
                } else {
                    this.snackBar.open(this.translate.instant(err.error), null, {
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                        duration: 5000,
                    });
                }
            }
        );
    }

    onModify() {
        this.checkTotalPrice();
        this.checkBelowMinPrice();
        this.showTotalPriceWarningMessage = !this.isTotalPriceValid;
        if (this.checkOverWeight() || !this.isTotalPriceValid) return;

        this.orderService.AddOrUpdateOrderItem(this.addOrUpdateOrderItemDTO).subscribe(
            (res) => {
                this.dialogRef.close(res);
            },
            (err) => {
                if (err.error.indexOf('basket-now-above-limit') > -1) {
                    this.snackBar.open(
                        this.translate.instant(err.error.split('|')[0], {
                            maxWeight: err.error.split('|')[1],
                        }),
                        null,
                        {
                            verticalPosition: 'top',
                            horizontalPosition: 'center',
                            duration: 5000,
                        }
                    );
                } else if (err.error.indexOf('min-order-value') > -1) {
                    this.snackBar.open(
                        this.translate.instant(err.error.split('|')[0], {
                            minValue: err.error.split('|')[1],
                        }),
                        null,
                        {
                            verticalPosition: 'top',
                            horizontalPosition: 'center',
                            duration: 5000,
                        }
                    );
                } else {
                    this.snackBar.open(this.translate.instant(err.error), null, {
                        verticalPosition: 'top',
                        horizontalPosition: 'center',
                        duration: 5000,
                    });
                }
            }
        );
    }

    onDelete() {
        if (this.btnDelete) return;

        this.translate.get('messages.cancel-order-item').subscribe((title) => {
            this.createDialogService
                .openConfirmCancellationDialog(title)
                .afterClosed()
                .subscribe((result) => {
                    if (result) {
                        this.orderService
                            .DeleteOrderItem(this.addOrUpdateOrderItemDTO)
                            .subscribe(
                                (res) => {
                                    this.dialogRef.close(res);
                                },
                                (err) => {
                                    if (
                                        err.error.indexOf('basket-now-above-limit') > -1
                                    ) {
                                        this.snackBar.open(
                                            this.translate.instant(
                                                err.error.split('|')[0],
                                                {
                                                    maxWeight: err.error.split('|')[1],
                                                }
                                            ),
                                            null,
                                            {
                                                verticalPosition: 'top',
                                                horizontalPosition: 'center',
                                                duration: 5000,
                                            }
                                        );
                                    } else if (
                                        err.error.indexOf('min-order-value') > -1
                                    ) {
                                        this.snackBar.open(
                                            this.translate.instant(
                                                err.error.split('|')[0],
                                                {
                                                    minValue: err.error.split('|')[1],
                                                }
                                            ),
                                            null,
                                            {
                                                verticalPosition: 'top',
                                                horizontalPosition: 'center',
                                                duration: 5000,
                                            }
                                        );
                                    } else {
                                        this.snackBar.open(
                                            this.translate.instant(err.error),
                                            null,
                                            {
                                                verticalPosition: 'top',
                                                horizontalPosition: 'center',
                                                duration: 5000,
                                            }
                                        );
                                    }
                                }
                            );
                    }
                });
        });
    }

    private checkTotalPrice() {
        const quantityDiff = this.orderItem
            ? this.addOrUpdateOrderItemDTO.Quantity - this.orderItem.OrderedQuantity
            : this.addOrUpdateOrderItemDTO.Quantity;

        const totalPrice =
            this.order.FinalPriceValueIfStored +
            this.productProvider?.price * quantityDiff;

        this.isTotalPriceValid = this.orderService.totalPriceValidator(
            totalPrice + this.order.DeliveryFee,
            this.order.DeliveryFee,
            this.order.TotalPrice
        );
    }
}
