import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './containers/aboutus/aboutus.component';
import { NewCheckoutComponent } from './containers/checkout/new-checkout.component';
import { ProductDetailsComponent } from './containers/product-details/product-details.component';
import { UserBasketListComponent } from './containers/user-basket-list/user-basket-list.component';
import { FavoriteListComponent } from './containers/favorite-list/favorite-list.component';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { CategoryPageComponent } from './containers/category-page/category-page.component';
import { ContestComponent } from './containers/contest/contest.component';
import { PasswordComponent } from './containers/password/password.component';
import { InformativeDashboardComponent } from './containers/informative-dashboard/informative-dashboard.component';
import { ProfileComponent } from './containers/profile/profile.component';
import { OrderListComponent } from './containers/order-list/order-list.component';
import { PaymentComponent } from './containers/payment/payment.component';
import { CookiesComponent } from './containers/cookies/cookies.component';
import { OrderComponent } from './containers/order-list/order.component';
import {
    Error404Component,
    OwnWebshopRedirect,
} from 'projects/library-shared/src/public-api';
import { ArchieDesktopComponent } from './containers/archie/archie-desktop.component';

const routes: Routes = [
    {
        path: '',
        component: InformativeDashboardComponent,
        canActivate: [OwnWebshopRedirect],
    },
    {
        path: 'kezdooldal',
        component: DashboardComponent,
    },
    {
        path: 'home',
        component: DashboardComponent,
    },
    /**********for ownwebshop doamin, like aldi.shop.hu or hofer.roksh.at*********** */
    {
        path: 'kinalat',
        loadChildren: () =>
            import('./containers/product-list-new/product-list.module').then(
                (m) => m.ProductListModule
            ),
    },
    {
        path: 'angebot',
        loadChildren: () =>
            import('./containers/product-list-new/product-list.module').then(
                (m) => m.ProductListModule
            ),
    },
    /*******************1 shop selected url**********************/
    {
        path: ':providercode/kezdooldal',
        component: DashboardComponent,
    },
    {
        path: ':providercode/home',
        component: DashboardComponent,
    },
    {
        path: ':providercode/kinalat',
        loadChildren: () =>
            import('./containers/product-list-new/product-list.module').then(
                (m) => m.ProductListModule
            ),
    },
    {
        path: ':providercode/angebot',
        loadChildren: () =>
            import('./containers/product-list-new/product-list.module').then(
                (m) => m.ProductListModule
            ),
    },
    {
        path: 'payment/spresult',
        component: PaymentComponent,
    },
    {
        path: 'kategoria',
        component: CategoryPageComponent,
    },
    {
        path: 'kategorie',
        component: CategoryPageComponent,
    },
    /*******************1 shop selected url***************************/
    {
        path: ':providercode/kategoria',
        component: CategoryPageComponent,
    },
    {
        path: ':providercode/kategorie',
        component: CategoryPageComponent,
    },
    {
        path: ':provid/kategoria',
        component: CategoryPageComponent,
    },
    {
        path: ':provid/kategorie',
        component: CategoryPageComponent,
    },
    {
        path: ':providercode/kategoria/:cat',
        component: CategoryPageComponent,
    },
    {
        path: ':providercode/kategorie/:cat',
        component: CategoryPageComponent,
    },
    /*******************************************************************/
    {
        path: 'kategoria/:cat',
        component: CategoryPageComponent,
    },
    {
        path: 'kategorie/:cat',
        component: CategoryPageComponent,
    },
    /*******************1 shop selected url***************************/
    {
        path: ':providercode/kategoria/:cat',
        component: CategoryPageComponent,
    },
    {
        path: ':providercode/kategorie/:cat',
        component: CategoryPageComponent,
    },
    {
        path: ':provid/kategoria/:cat',
        component: CategoryPageComponent,
    },
    {
        path: ':provid/kategorie/:cat',
        component: CategoryPageComponent,
    },
    /***********************************************************************/
    {
        path: 'termekek/:categ1/:seoname',
        component: ProductDetailsComponent,
    },
    {
        path: 'produkte/:categ1/:seoname',
        component: ProductDetailsComponent,
    },
    {
        path: 'termekek/:categ1/:categ2/:seoname',
        component: ProductDetailsComponent,
    },
    {
        path: 'produkte/:categ1/:categ2/:seoname',
        component: ProductDetailsComponent,
    },
    {
        path: 'termekek/:categ1/:categ2/:categ3/:seoname',
        component: ProductDetailsComponent,
    },
    {
        path: 'produkte/:categ1/:categ2/:categ3/:seoname',
        component: ProductDetailsComponent,
    },
    /*******************1 shop selected url***************************/
    {
        path: ':providercode/termekek/:categ1/:seoname',
        component: ProductDetailsComponent,
    },
    {
        path: ':providercode/produkte/:categ1/:seoname',
        component: ProductDetailsComponent,
    },
    {
        path: ':providercode/termekek/:categ1/:categ2/:seoname',
        component: ProductDetailsComponent,
    },
    {
        path: ':providercode/produkte/:categ1/:categ2/:seoname',
        component: ProductDetailsComponent,
    },
    {
        path: ':providercode/termekek/:categ1/:categ2/:categ3/:seoname',
        component: ProductDetailsComponent,
    },
    {
        path: ':providercode/produkte/:categ1/:categ2/:categ3/:seoname',
        component: ProductDetailsComponent,
    },
    /******************************************************************/
    {
        path: 'felhasznalo/bevasarlolista',
        component: UserBasketListComponent,
    },
    {
        path: 'benutzer/einkaufsliste',
        component: UserBasketListComponent,
    },
    {
        path: 'felhasznalo/kedvencek',
        component: FavoriteListComponent,
    },
    {
        path: 'benutzer/favoriten',
        component: FavoriteListComponent,
    },
    {
        path: 'felhasznalo/rendeleseim',
        component: OrderListComponent,
    },
    {
        path: 'benutzer/bestellungen',
        component: OrderListComponent,
    },
    {
        path: 'felhasznalo/rendelesem',
        component: OrderComponent,
    },
    {
        path: 'benutzer/bestellung',
        component: OrderComponent,
    },
    {
        path: 'felhasznalo/beallitasok',
        component: ProfileComponent,
    },
    {
        path: 'benutzer/einstellungen',
        component: ProfileComponent,
    },
    {
        path: 'gyik',
        component: AboutUsComponent,
        data: {
            title: 'Gyakran Ismételt Kérdések',
        },
    },
    {
        path: 'cookiek',
        component: CookiesComponent,
        data: {
            title: 'Cookiek, sütik',
        },
    },
    {
        path: 'penztar/:providerid',
        component: NewCheckoutComponent,
        data: {
            title: 'Pénztár',
        },
    },
    {
        path: 'kasse/:providerid',
        component: NewCheckoutComponent,
        data: {
            title: 'Kasse',
        },
    },
    {
        path: 'jelszo',
        component: PasswordComponent,
        data: {
            title: 'Új jelszó rögzítése',
        },
    },
    {
        path: 'passwort',
        component: PasswordComponent,
        data: {
            title: 'Neue Passwort',
        },
    },
    {
        path: 'password',
        component: PasswordComponent,
        data: {
            title: 'New Password',
        },
    },
    {
        path: 'nyeremenyjatek',
        component: ContestComponent,
        data: {
            title: 'Nyereményjáték',
        },
    },
    {
        path: 'archie',
        pathMatch: 'full',
        component: ArchieDesktopComponent,
        data: {
            title: 'Archie - ROKSH',
        },
    },
    {
        path: 'archie/:ArchieConversationThreadID',
        pathMatch: 'full',
        component: ArchieDesktopComponent,
        data: {
            title: 'Archie - ROKSH',
        },
    },
    {
        path: '404',
        component: Error404Component,
        data: {
            title: 'A keresett oldal nem található',
        },
    },
    {
        path: '**',
        redirectTo: '404',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
