import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LibrarySharedComponent } from './library-shared.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule } from '@angular/material/card';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ProviderSelectModalComponent } from './components/provider-select/provider-select-modal.component';

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [LibrarySharedComponent, ProviderSelectModalComponent],
    imports: [
        AccordionModule.forRoot(), // You could also split this up if you don't want the Entire Module imported
        CdkAccordionModule,
        MatExpansionModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MatInputModule,
        MatMenuModule,
        TranslateModule,
        MatCardModule,
        CarouselModule,
        FontAwesomeModule,
    ],
    exports: [
        CdkAccordionModule,
        MatExpansionModule,
        LibrarySharedComponent,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        MatMenuModule,
        CarouselModule,
    ],
})
export class LibrarySharedModule {}
