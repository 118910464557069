<div *ngIf="inUserBasket" class="pb-5">
    <div class="header-mobile d-flex justify-content-between w-100">
        <div class="w-100">
            <p
                class="d-block d-md-none d-flex align-items-center text-grey p-0 m-0"
                (click)="handleBack()"
            >
                <img
                    src="assets/images/back_arrow.svg"
                    alt="back"
                    class="pe-2 back-arrow"
                />
                <small>
                    {{ 'common.back' | translate }}
                </small>
            </p>
            <p class="text-grey p-0 py-2 m-0">
                <small>
                    {{ inUserBasket.providerName }} •
                    {{ inUserBasket.userBasketProductList.length }}
                    {{ 'common.product' | translate }}
                </small>
            </p>
            <div class="position-relative w-100">
                <input
                    class="basket-name-line mb-3 w-100"
                    [value]="inUserBasket.basketName | translate"
                    disabled
                    maxlength="25"
                    (keyup.enter)="onRenameUserBasket(nameInput)"
                    (keyup.escape)="resetInput(nameInput)"
                    #nameInput
                />
                <fa-icon
                    class="position-absolute save-name pointer"
                    [icon]="faCheck"
                    size="2x"
                    (click)="onRenameUserBasket(nameInput)"
                    *ngIf="!nameInput.disabled"
                ></fa-icon>
            </div>
            <h4 class="text-grey p-0 m-0 pb-3">
                {{ basketPrice | currencyFormatted }}&nbsp;
                {{ 'common.currency' | translate }}
            </h4>
        </div>
        <div *ngIf="canModify">
            <img
                [matMenuTriggerFor]="menu"
                src="{{ siteImageUrl }}images/shared-img/user-basket/ellipsis-menu.svg"
                alt="hamburger"
                class="pointer p-2"
            />
            <mat-menu #menu="matMenu">
                <button
                    class="menu-item"
                    (click)="onMenuClickRenameUserBasket(nameInput)"
                    mat-menu-item
                >
                    {{ 'common.rename' | translate }}
                </button>
                <button
                    class="menu-item"
                    mat-menu-item
                    (click)="onClickRemoveUserBasket()"
                >
                    {{ 'common.delete' | translate }}
                </button>
                <button
                    class="menu-item hide-on-desktop"
                    mat-menu-item
                    (click)="onShare()"
                >
                    {{ 'user-basket.send-in-mail-to-myself' | translate }}
                </button>
            </mat-menu>
        </div>
    </div>
    <div
        class="cart-container pb-3"
        *ngIf="inUserBasket.userBasketProductList?.length > 0; else goToProvider"
    >
        <div class="w-50">
            <a
                *ngIf="isProviderSelectedForShopping; else goToProvider"
                class="btn btn-warning pointer w-100 d-flex align-items-center justify-content-center"
                (click)="onAddAll()"
            >
                <fa-icon *ngIf="isLoading" [icon]="faSpinner" [spin]="true"></fa-icon>
                <p class="m-0 p-0 ps-2">
                    {{ 'common.add-all-to-basket' | translate }}
                </p>
            </a>
        </div>
        <app-share-basket-button
            *ngIf="canModify; else saveList"
            [userMasterBasketGUID]="inUserBasket.userMasterBasketGUID"
            [masterBasketID]="inUserBasket.masterBasketID"
            [providerId]="inUserBasket.providerID"
            [showLink]="true"
        ></app-share-basket-button>
        <ng-template #saveList>
            <a
                class="ms-3 d-flex align-items-center user-select-none"
                [class.text-decoration-none]="saved"
                (click)="onAddSharedLink()"
            >
                <img
                    *ngIf="!saveInProgress && !saved"
                    src="assets/svg/rokshImages/icons/save.svg"
                    alt="save"
                    class="me-2"
                />
                <fa-icon
                    *ngIf="saveInProgress && !saved"
                    [icon]="faSpinner"
                    [spin]="true"
                    class="text-black me-2"
                ></fa-icon>
                <fa-icon *ngIf="saved" [icon]="faCheck" class="text-black me-2"></fa-icon>
                <span>{{ 'user-basket.save-list' | translate }}</span>
            </a>
        </ng-template>
    </div>
    <hr class="mb-0 hide-on-mobile" />
    <ng-template #goToProvider>
        <a
            *ngIf="!isProviderSelectedForShopping"
            class="btn btn-blue pointer w-100 d-flex align-items-center justify-content-center"
            (click)="onProviderSelect()"
        >
            <fa-icon *ngIf="isLoading" [icon]="faSpinner" [spin]="true"></fa-icon>
            <p class="m-0 p-0 ps-2" *ngIf="!isLoading">
                {{ 'common.go-to-shop' | translate }} <small class="ps-1">ᐳ</small>
            </p>
        </a>
    </ng-template>
    <div
        *ngIf="!inUserBasket.userBasketProductList?.length; else products"
        class="w-100 h-100 d-flex justify-content-center align-items-center flex-column"
    >
        <img
            src="{{ siteImageUrl }}images/shared-img/user-basket/empty_list.svg"
            alt="empty list"
            class="mt-4"
        />
        <h6 class="px-3 pt-3 text-center">
            {{ 'user-basket.no-shipping-list-product' | translate }}
        </h6>
        <a class="go-to-shop" [routerLink]="['/', 'router.category' | translate]">
            {{ 'basket.go-to-shopping' | translate }}
        </a>
    </div>
    <ng-template #products>
        <div class="d-flex flex-wrap pb-5">
            <div
                class="pe-1 pe-md-2"
                *ngFor="let product of inUserBasket.userBasketProductList"
            >
                <app-product-card-wrapper
                    [product]="product.product"
                    [isShoppingList]="true"
                    [masterBasketId]="inUserBasket.masterBasketID"
                    [basketItemId]="product.basketItemId"
                    [selectedShoppingListQuantity]="product.unitValue"
                    (deleteEmitter)="deleteFromList($event)"
                    [providerName]="inUserBasket.providerName"
                >
                </app-product-card-wrapper>
            </div>
        </div>
    </ng-template>
</div>
<div
    class="z-99 w-100 hide-on-desktop position-fixed bottom-0 start-0 bg-white cart-button-mobile px-4"
    *ngIf="inUserBasket.userBasketProductList?.length > 0"
>
    <div class="w-100 d-flex justify-content-center">
        <a
            class="ms-3 d-flex align-items-center user-select-none"
            [class.text-decoration-none]="saved"
            (click)="onAddSharedLink()"
        >
            <img
                *ngIf="!saveInProgress && !saved"
                src="assets/svg/rokshImages/icons/save.svg"
                alt="save"
                class="me-2"
            />
            <fa-icon
                *ngIf="saveInProgress && !saved"
                [icon]="faSpinner"
                [spin]="true"
                class="text-black me-2"
            ></fa-icon>
            <fa-icon *ngIf="saved" [icon]="faCheck" class="text-black me-2"></fa-icon>
            <span>{{ 'user-basket.save-list' | translate }}</span>
        </a>
    </div>
    <div class="w-100">
        <a
            *ngIf="isProviderSelectedForShopping; else goToProvider"
            class="btn btn-warning pointer w-100 d-flex align-items-center justify-content-center"
            (click)="onAddAll()"
        >
            <fa-icon *ngIf="addAllInProgress" [icon]="faSpinner" [spin]="true"></fa-icon>
            <fa-icon *ngIf="!addAllInProgress" [icon]="faShoppingCart"></fa-icon>
            <p class="m-0 p-0 ps-2">
                {{ 'common.add-all-to-basket' | translate }}
            </p>
        </a>
        <ng-template #goToProvider>
            <a
                class="btn btn-blue pointer w-100 d-flex align-items-center justify-content-center"
                (click)="onProviderSelect()"
            >
                <fa-icon *ngIf="isLoading" [icon]="faSpinner" [spin]="true"></fa-icon>
                <p class="m-0 p-0 ps-2" *ngIf="!isLoading">
                    {{ 'common.go-to-shop' | translate }} <small class="ps-1">ᐳ</small>
                </p>
            </a>
        </ng-template>
    </div>
</div>
