import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    FormatterService,
    ProductDto,
    ProductListService,
    SessionService,
} from 'projects/library-shared/src/public-api';
import { Meta } from '@angular/platform-browser';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from '../../../services/home/home.service';
import { AddressDialogComponent } from '../../../components/address/address-dialog.component';
import { ShopSelectDialogSeparatedComponent } from '../../../components/shop-select-dialog-separated/shop-select-dialog-separated.component';

@Component({
    templateUrl: './product-details-dialog.component.html',
    styleUrls: ['./product-details-dialog.component.scss'],
})
export class ProductDetailsDialogComponent implements OnInit {
    faTimesCircle = faTimesCircle;

    prod: ProductDto = null;
    hasGallery = false;
    seoName: string;
    providerId: number;
    providerName: string;

    AddressDialogComponent = AddressDialogComponent;
    ShopSelectDialogSeparatedComponent = ShopSelectDialogSeparatedComponent;

    constructor(
        public dialogRef: MatDialogRef<ProductDetailsDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            seoName: string;
            providerId: number;
            providerName: string;
            isArchie: boolean | false;
        },
        private productListService: ProductListService,
        private sessionService: SessionService,
        private homeService: HomeService,
        public formatterService: FormatterService,
        private meta: Meta,
        private translate: TranslateService
    ) {
        this.seoName = data.seoName;
        this.providerId = data.providerId;
        this.providerName = data.providerName;
    }

    ngOnInit() {
        this.homeService.isSessionSet.subscribe((isSetted) => {
            if (isSetted) {
                this.productListService
                    .getProductDetails(this.seoName)
                    .subscribe((product) => {
                        this.prod = product;
                    });

                this.translate.get('meta.category-keywords').subscribe((text) => {
                    this.meta.updateTag({
                        name: 'keywords',
                        content:
                            this.prod?.productName +
                            ',' +
                            this.prod?.category.categoryName +
                            text,
                    });
                    this.meta.updateTag({
                        name: 'description',
                        content:
                            this.prod?.productName +
                            ', ' +
                            this.prod?.minPrice +
                            ' Ft-tól!   ' +
                            this.prod?.productName +
                            text,
                    });
                });

                if (this.prod?.productMediaList.length > 0) this.hasGallery = true;
            }
        });
    }
}
