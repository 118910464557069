import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { DashboardProductListDto } from '../models/DTO/DashboardProductList.dto';
import { catchError, map, retry } from 'rxjs/operators';
import { SessionService } from './session/session.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ProductListFilter } from '../models/product/product-list-filter.model';
import {
    ProductListResult,
    ProductListResultNew,
} from '../models/product/product-list-result.model';
import { ServiceBaseService } from './service-base.service';
import { ProductBlockDto } from '../models/DTO/product-block-dto';
import { ProductBlockListRequestDTO, ProductDetailsClient, ProductDto } from '../index';
import { CategoryBlockType } from '../types/categoryblock-type';

@Injectable({ providedIn: 'root' })
export class ProductListService {
    private shopServiceUrl: string;
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    sortFilters = [
        { value: 'popular.desc', text: 'Legnépszerűbb elöl' },
        { value: 'price.asc', text: 'Legolcsóbb elöl' },
        { value: 'price.desc', text: 'Legdrágább elöl' },
        { value: 'name.asc', text: 'Abc sorrend' },
    ];
    bioFilters = {
        bio: 'Bio',
        hu: 'Magyar Termék',
        lact: 'Laktózmentes',
        glut: 'Gluténmentes',
        sug: 'Cukormentes',
    };

    isProviderSelectedForShopping = false;

    constructor(
        private http: HttpClient,
        private router: Router,
        private sessionService: SessionService,
        private serviceBaseService: ServiceBaseService,
        private translate: TranslateService,
        private productDetailsClient: ProductDetailsClient
    ) {
        this.shopServiceUrl = this.serviceBaseService.getBaseUrl();
        ///if user selects different shops
        this.sessionService.isProviderSelectedForShopping.subscribe((result) => {
            this.isProviderSelectedForShopping = result;
        });
    }

    getProductList(
        productListFilter: ProductListFilter
    ): Observable<{ TagObject: ProductListResult }> {
        return this.http.post<{ TagObject: ProductListResult }>(
            this.shopServiceUrl + 'productlist/List/',
            productListFilter,
            { headers: this.headers }
        );
    }

    getNewProductList(productListFilter: ProductListFilter) {
        return this.http.post<ProductListResult>(
            this.shopServiceUrl + 'productlist/GetProductList',
            productListFilter,
            { headers: this.headers }
        );
    }

    getNewProductListForMobile(productListFilter: ProductListFilter) {
        return this.http.post<ProductListResultNew>(
            this.shopServiceUrl + 'productlist/GetProductList',
            productListFilter,
            { headers: this.headers }
        );
    }

    getCategoryProductFirstTime(
        progId: string,
        firstLoadProductListResultNum = '4',
        listResultProductNum = '24'
    ): Observable<DashboardProductListDto> {
        return this.http
            .get<DashboardProductListDto>(
                `${this.shopServiceUrl}productlist/CategoryProductList`,
                {
                    params: {
                        progId,
                        firstLoadProductListResultNum,
                        listResultProductNum,
                    },
                }
            )
            .pipe(
                retry(0),
                catchError((error: HttpErrorResponse) => {
                    if (
                        error.error.ErrorTxt == 'NotValidCategory' &&
                        this.isProviderSelectedForShopping
                    ) {
                        this.translate
                            .get('router.dashboard')
                            .subscribe((text) => (location.href = text));
                    }

                    let errorMessage = '';
                    if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                    } else {
                        // server-side error
                        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                    }
                    // window.alert(errorMessage);
                    return throwError(errorMessage);
                })
            );
    }

    getAdditionalCategoryProductList(
        progIdList: string[],
        listResultProductNum = '24'
    ): Observable<DashboardProductListDto> {
        return this.http.get<DashboardProductListDto>(
            this.shopServiceUrl + 'productlist/additionalCategoryProductList',
            {
                params: {
                    progIdList,
                    listResultProductNum,
                },
            }
        );
    }

    getProductDetails(seoName: string): Observable<ProductDto> {
        const res = this.productDetailsClient.details(seoName).pipe(
            map((data) => {
                return data.product;
            }),
            catchError(() => {
                this.router.navigate(['/404']);
                return new Observable<ProductDto>(null);
            })
        );
        return res;
    }

    createFilterQuery(queryParams: any) {
        return Object.keys(queryParams)
            .filter(
                (key) =>
                    queryParams[key] !== undefined &&
                    ((Array.isArray(queryParams[key]) && queryParams[key].length > 0) ||
                        !Array.isArray(queryParams[key]))
            )
            .map((key) => {
                if (Array.isArray(queryParams[key])) {
                    return queryParams[key].map((x: string) => `${key}=${x}`).join('&');
                } else {
                    return `${key}=${queryParams[key]}`;
                }
            })
            .join('&');
    }

    getProductBlockList(
        progId: string,
        page?: number,
        typeId?: number,
        rokshlanding?: boolean
    ): Observable<ProductBlockDto[]> {
        const productBlockListRequestDto: ProductBlockListRequestDTO =
            new ProductBlockListRequestDTO({
                progId: progId,
                page: page,
                numberOfItems: typeId == CategoryBlockType.commercial ? 4 : 24,
                categoryBlockTypeId: typeId,
                rokshLanding: rokshlanding,
            });

        return this.http.post<ProductBlockDto[]>(
            this.shopServiceUrl + 'productlist/ProductBlockList',
            productBlockListRequestDto
        );
    }
}
