import { Injectable } from '@angular/core';
import { ServiceBaseService } from '../service-base.service';
import { FrontendVersionDto } from '../dto/frontend-version/frontend-version.dto';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class FrontendVersionService {
    isMobile = false;
    constructor(private httpClient: HttpClient) {
        this.isMobile = location.origin.includes('roksh-application');
    }

    getFrontedUrl(): Observable<FrontendVersionDto> {
        const baseUrl = ServiceBaseService.getStaticBaseUrl();
        let envSuffix = '';

        if (baseUrl.includes('staging')) {
            envSuffix = 'Staging';
        } else if (baseUrl.includes('master')) {
            envSuffix = 'Master';
        }

        const platformSuffix = this.isMobile ? '' : 'Desktop';
        const key = `FrontendVersionNumber${platformSuffix}${envSuffix}`;

        return this.httpClient.get<FrontendVersionDto>(
            `${baseUrl}/mobileApplicationSettings/frontendVersionNumber/${key}`
        );
    }
}
