import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import {
    faArrowLeft,
    faInfo,
    faTruck,
    faWineBottle,
} from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Observable, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    BillingAddressType,
    CheckoutOrderDto,
    CheckoutService,
    DATALAYER_SERVICE_IMPL,
    DataLayerGa4Service,
    OrderStatus,
} from 'projects/library-shared/src/public-api';
import { HomeService } from '../../services/home/home.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-new-checkout',
    templateUrl: './new-checkout.component.html',
    styleUrls: ['./new-checkout.component.scss'],
})
export class NewCheckoutComponent implements OnInit {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    faArrowLeft = faArrowLeft;
    faTruck = faTruck;
    faInfo = faInfo;
    faWineBottle = faWineBottle;
    order: CheckoutOrderDto;

    orderStatus = OrderStatus;
    displayOrderPreferences = false;
    displayWishListProviderShops = false;

    defaultPaymentProviders: string[];

    orderStatus$: Observable<OrderStatus>;
    isArchie: boolean;

    constructor(
        private checkoutService: CheckoutService,
        private homeService: HomeService,
        private title: Title,
        private translate: TranslateService,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        @Inject(DATALAYER_SERVICE_IMPL) private dataLayerGa4Service: DataLayerGa4Service
    ) {
        this.homeService.setHeaderDisplayed(false);
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(
            (params) => (this.isArchie = params.isArchie)
        );
        this.createCheckoutForm();
        this.orderStatus$ = this.checkoutService.orderStatus$;
        this.checkoutService.getCheckoutDto();

        this.checkoutService.orderPreferences$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((settings) => {
                this.displayOrderPreferences = settings && settings.length !== 0;
            });

        this.checkoutService.isWishListAvailable$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((isWishListAvailable) => {
                this.displayWishListProviderShops = isWishListAvailable;
            });

        this.checkoutService.defaultPaymentProviders$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((paymentProviders) => {
                this.defaultPaymentProviders = paymentProviders;
            });

        this.checkoutService.order$.subscribe((order) => {
            if (order) this.order = order;
        });

        this.translate
            .get('checkout.finish-checkout')
            .subscribe((resp) => this.title.setTitle(resp));
    }

    createCheckoutForm(): void {
        const checkoutForm = this.formBuilder.group({
            addressForm: this.formBuilder.group({
                PostCode: [
                    null,
                    [
                        Validators.required,
                        Validators.minLength(4),
                        Validators.maxLength(4),
                        Validators.pattern(/^[0-9]*$/),
                    ],
                ],
                City: [null, [Validators.required, Validators.minLength(2)]],
                StreetName: [null, [Validators.required, Validators.minLength(2)]],
                HouseNumber: [null, [Validators.required, Validators.minLength(1)]],
                Comment: [null],
                FirstName: [null, [Validators.required, Validators.minLength(1)]],
                LastName: [null, [Validators.required, Validators.minLength(1)]],
                FullName: [null, [Validators.required, Validators.minLength(1)]],
                Email: [
                    null,
                    [
                        Validators.required,
                        Validators.pattern(
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        ),
                    ],
                ],
                Phone: [null, [Validators.required, Validators.minLength(7)]],
                Birthdate: [null, Validators.required],
                BillingName: [null, []],
                BillingAddressPostCode: [null, []],
                BillingAddress: [null, []],
                TaxNumber: [null, []],
                DifferentBillingAddressSelector: [BillingAddressType.SameBillingAddress],
            }),
            deliveryTimeForm: this.formBuilder.group({
                DeliveryDays: [null, [Validators.required, Validators.minLength(2)]],
                DeliveryTimeSlots: [null, [Validators.required, Validators.minLength(2)]],
                DeliveryDate: [null],
                DeliveryStartHour: [null, Validators.required],
                DeliveryEndHour: [null, Validators.required],
                TimeSlotID: [0, Validators.required],
            }),
            preferencesForm: this.formBuilder.group({
                OrderPreferenceSettingOptionsIds: [null, []],
            }),
            paymentForm: this.formBuilder.group({
                PaymentType: [null, Validators.required],
                Latitude: [null, Validators.required],
                Longitude: [null, Validators.required],
                CouponCode: [null, []],
                ToSAccepted: [false, [Validators.requiredTrue]],
                PrivacyAccepted: [false, [Validators.requiredTrue]],
                ProviderMarketingAccepted: [false],
            }),
            wishListForm: this.formBuilder.group({
                wishListProviderShops: this.formBuilder.array([]),
            }),
            discountForm: this.formBuilder.group({
                DiscountID: [null, []],
            }),
            tipForm: this.formBuilder.group({
                TipID: [null, []],
                TipAmount: [null, []],
            }),
        });

        this.checkoutService.setFormGroupSource(checkoutForm);
    }

    ngOnDestroy(): void {
        this.homeService.isHeaderDisplayed.next(true);
        this.homeService.isFooterDisplayed.next(true);
    }
}
