<h1 class="close-button-panel text-size-20" mat-dialog-title>
    <div class="text-end close-button">
        <span class="pointer" (click)="dialogRef.close()">
            <fa-icon [icon]="faTimesCircle"></fa-icon>
        </span>
    </div>
</h1>

<div class="row">
    <div class="col-10 px-0 mb-4 mt-6 border-bottom title">
        <p class="text-size">
            <span>
                {{ 'common.further-information' | translate }}
                <a
                    href="{{
                        'static-links.roksh-knowledge-contact'
                            | translate
                            | providerTheme : 'static-links.roksh-knowledge'
                            | async
                    }}"
                    target="_blank"
                    ><u>{{ 'common.customer-support' | translate }}</u></a
                >
            </span>
        </p>
    </div>

    <form
        class="col-12 justify-content-center"
        [formGroup]="orderCommentForm"
        (ngSubmit)="onSubmit()"
    >
        <div>
            <mat-form-field appearance="outline" class="field py-0 col">
                <mat-label>{{ 'order-list.comment-to-deliverer' | translate }}</mat-label>
                <textarea
                    matInput
                    type="text"
                    formControlName="Comment"
                    id="comment"
                    placeholder="{{ 'order-list.comment-to-deliverer' | translate }}"
                    aria-label="Comment"
                >
                </textarea>

                <mat-error
                    *ngIf="
                        orderCommentForm.get('Comment').errors &&
                        orderCommentForm.get('Comment').touched
                    "
                >
                    {{ 'order-list.comment-required' | translate }}</mat-error
                >
            </mat-form-field>
        </div>
        <div mat-dialog-actions class="d-flex justify-content-center w-100">
            <button
                mat-stroked-button
                class="save-btn btn-rounded mb-1 text-size-16 font-weight-600"
                mat-button
                type="submit"
            >
                {{ 'common.save' | translate }}
            </button>
        </div>
    </form>
</div>
