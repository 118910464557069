import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LogService, SharedModalService } from 'projects/library-shared/src/public-api';
import { EmailVerificationService } from './email-verification.service';
import { faCheck, faTimes, faUndo } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './email-activation.component.html',
    providers: [LogService, EmailVerificationService],
})
export class EmailActivationComponent implements OnInit {
    inProgress = true;
    isError = false;

    faCheck = faCheck;
    faUndo = faUndo;
    faTimes = faTimes;

    constructor(
        @Inject(MAT_DIALOG_DATA) public activateCode: string,
        private emailVerificationService: EmailVerificationService,
        private dialogRef: MatDialogRef<EmailActivationComponent>,
        private location: Location,
        private logService: LogService,
        private sharedModalService: SharedModalService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.logService.mainClass = 'EmailActivationComponent';
        this.logService.info('activateCode', this.activateCode);
        this.emailVerificationService.verificateEmail(this.activateCode).subscribe(
            () => {
                this.inProgress = false;
                this.logService.info('Email activation OK');
            },
            (error) => {
                this.inProgress = false;
                this.isError = true;
                this.logService.error('', error);
            }
        );
        this.dialogRef.beforeClosed().subscribe(() => {
            this.translate.get('router.dashboard').subscribe((text) => {
                this.location.go(text);
            });
        });
    }

    onOpenLoginDialog() {
        this.dialogRef.close();
        void this.sharedModalService.presentSignInModal();
    }
}
