<div class="py-3 mb-5" (click)="handleClick()">
    <div class="mt-5 w-100 d-flex align-items-center flex-column">
        <img
            #mediaImage
            [src]="coverImageUrl"
            class="block-img"
            alt="commercial image"
            (error)="mediaImage.style.visibility = 'hidden'"
        />
        <div class="custom-block-container text-center">
            <img
                #logo
                [src]="mainImageUrl"
                class="main-img my-3"
                alt="provider logo"
                (error)="logo.style.visibility = 'hidden'"
            />
            <h2 class="d-none d-md-block fw-bolder">{{ commercialBlock.Title }}</h2>
            <h4 class="d-block d-md-none fw-bolder">
                {{ commercialBlock.MobileApplicationTitle }}
            </h4>
        </div>

        <div
            class="product-container d-flex justify-content-around m-0 p-0"
            (click)="goToCustomBlockPage()"
        >
            <ng-container *ngFor="let product of productList">
                <div *ngIf="product.price && product.price > 0">
                    <app-product-card-wrapper
                        [product]="product"
                        [isShoppingList]="false"
                        [commercialBlock]="true"
                    >
                    </app-product-card-wrapper>
                </div>
            </ng-container>
        </div>
        <div class="product-container">
            <span
                class="w-100 btn btn-next mt-2 fw-bolder text-size-14 px-3"
                (click)="goToCustomBlockPage()"
            >
                {{ 'dashboard.show-all' | translate }}
            </span>
        </div>
    </div>
</div>
