import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { ServiceBaseService } from 'projects/library-shared/src/public-api';

import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'zone.js';

if (
    ServiceBaseService.getEnvironment.indexOf('prod') > -1 ||
    localStorage.getItem('isSentryLogEnabled') == '1'
) {
    Sentry.init({
        dsn: 'https://877e7561a9b343c78bc1ffa41be99b0d@o4504877451182080.ingest.sentry.io/4505130834788352',
        release: 'roksh-desktop-V1.0',
        tracesSampleRate: 0.2,
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        //replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        //replaysOnErrorSampleRate: 1.0,
        environment: ServiceBaseService.getEnvironment,
        integrations: [
            new Sentry.BrowserTracing({
                tracePropagationTargets: [
                    'http://localhost:4200/',
                    'http://localhost:5001/',
                    'https://master.roksh.com',
                    'https://master.roksh.at',
                    'https://staging.roksh.com',
                    'https://staging.roksh.at',
                    'https://roksh.com',
                    'https://gojaja',
                    'https://roksh.at',
                ],
                routingInstrumentation: Sentry.routingInstrumentation,
            }),
            new Sentry.Integrations.Breadcrumbs({
                console: false,
            }),
        ],
    });
}
export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}

const providers = [{ provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }];

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic(providers)
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
