<div class="list-container px-3 px-md-5 w-100 position-relative">
    <div
        class="row-on-mobile d-flex justify-content-between pt-4"
        [class.padding-on-mobile]="isDiscountAvailable"
    >
        <div>
            <h3 class="pt-3">
                {{ 'common.my-shopping-lists' | translate }}
            </h3>
            <p class="text-grey">
                {{ 'basket.only-selected-provider-products' | translate }}
            </p>
        </div>
        <button (click)="onCreateNewUserBasket()" class="not-shown-on-mobile btn-blue">
            {{ 'user-basket.create-new-list' | translate }}
        </button>
    </div>

    <div class="w-100-mobile col-5"></div>

    <ng-container class="h-100">
        <div class="row">
            <div
                class="w-100-mobile col-5"
                [class.not-shown-on-mobile]="selectedUserBasket"
            >
                <lib-provider-select
                    (providerChanged)="onProviderChange($event)"
                    [selectedProvider]="selectedProvider"
                ></lib-provider-select>
                <div
                    class="d-flex flex-column justify-content-center w-100 text-center pt-4"
                    *ngIf="isUserBasketInProgress"
                >
                    <div>
                        <img src="/assets/loading_kosar.gif" class="m-auto mt-2" />
                    </div>
                </div>
                <div *ngIf="userBasketList && userBasketList.length > 0; else noList">
                    <div *ngFor="let userBasket of userBasketList" class="pb-3">
                        <lib-shopping-list-card
                            [userBasket]="userBasket"
                            [isSelected]="userBasket === selectedUserBasket"
                            (selectBasket)="onUserBasketSelect(userBasket)"
                        ></lib-shopping-list-card>
                    </div>
                </div>
            </div>
            <div class="col-7 not-shown-on-mobile" *ngIf="selectedUserBasket">
                <app-user-basket-details
                    [userBasket]="selectedUserBasket"
                    (close)="onBasketDeleted($event)"
                ></app-user-basket-details>
            </div>
        </div>
        <ng-template #noList>
            <div
                *ngIf="
                    !isUserBasketInProgress &&
                    (!userBasketList || userBasketList.length === 0)
                "
                class="w-100 h-100 d-flex justify-content-center align-items-center flex-column pt-5"
            >
                <img
                    src="{{ siteImageUrl }}images/shared-img/user-basket/empty_list.svg"
                    alt="empty list"
                    class="mt-5"
                />
                <h5 class="px-3 fw-bold pt-3 text-center">
                    {{ 'user-basket.no-lists-yet' | translate }}
                    <br />
                    {{ 'user-basket.no-lists-yet_2' | translate }}
                </h5>
            </div>
        </ng-template>
    </ng-container>
    <div
        class="z-1 vw-100 vh-100 not-shown-on-desktop position-absolute top-0 start-0 bg-white padding-on-mobile"
        *ngIf="selectedUserBasket"
    >
        <app-user-basket-details
            [userBasket]="selectedUserBasket"
            (close)="onBasketDeleted($event)"
        ></app-user-basket-details>
    </div>
</div>
<div
    *ngIf="!selectedUserBasket"
    class="z-99 not-shown-on-desktop position-fixed bottom-0 start-0 w-100 bg-white p-4"
>
    <button (click)="onCreateNewUserBasket()" class="btn-blue w-100">
        {{ 'user-basket.create-new-list' | translate }}
    </button>
</div>
