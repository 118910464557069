import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import {
    AuthenticationService,
    Category,
    PaymentService,
    ServiceBaseService,
    SessionService,
    SharedModalService,
    UserShared,
} from 'projects/library-shared/src/public-api';
import {
    faAngleLeft,
    faAngleRight,
    faBars,
    faPhoneAlt,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { MobilMenuService } from './mobil-menu.service';
import { Subscription } from 'rxjs';
import { CreateDialogService } from '../../../services/create-dialog.service';
import { HomeService } from '../../../services/home/home.service';
import { Event, NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'app-mobil-menu',
    templateUrl: './mobil-menu.component.html',
    styleUrls: ['./mobil-menu.component.scss'],
})
export class MobilMenuComponent implements OnInit, OnDestroy {
    @Input() catList: Category[] = [];

    respActiveMenu = '';
    openRespMenu = false;
    navPosition = 0;
    activeLevel = 1;
    activeCategory: Category;
    mobileSubMenu: string;
    user: UserShared;

    defaultPaymentProviders: string[];

    faBars = faBars;
    faAngleRight = faAngleRight;
    faUser = faUser;
    faFacebook = faFacebook;
    faInstagram = faInstagram;
    faPhoneAlt = faPhoneAlt;
    faAngleLeft = faAngleLeft;

    menuStatusSub: Subscription;
    authStatusSub: Subscription;

    isAuthenticated = false;
    isCategoryMenuDisplayed = true;
    isOwnWebShop = ServiceBaseService.isProviderOwnWebShop();
    selectedProviderName: string;

    constructor(
        private renderer: Renderer2,
        public baseService: ServiceBaseService,
        private authService: AuthenticationService,
        private mobilMenuService: MobilMenuService,
        private homeService: HomeService,
        private sessionService: SessionService,
        private createDialogService: CreateDialogService,
        private sharedModalService: SharedModalService,
        private router: Router,
        private paymentService: PaymentService
    ) {}

    ngOnInit() {
        this.homeService.isCategoryMenuDisplayed.subscribe((isCategoryMenuDisplayed) => {
            this.isCategoryMenuDisplayed = isCategoryMenuDisplayed;
        });

        this.menuStatusSub = this.mobilMenuService.mobilMenuOpenerSubject.subscribe(
            (isOpened) => {
                if (isOpened) {
                    this.showResponsiveMenu();
                } else {
                    this.closeRespMenu();
                }
            }
        );

        //TODO: összevonni, kiemelni!!!
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                if (
                    event.url === '/' ||
                    event.urlAfterRedirects === '/404' ||
                    event.url.includes('/?') ||
                    event.url.includes('/#') ||
                    (event.urlAfterRedirects.includes('felhasznalo') &&
                        !event.url.includes('providerName') &&
                        !event.url.includes('providerId') &&
                        !event.url.includes('orderCode')) ||
                    (event.urlAfterRedirects.includes('benutzer') &&
                        !event.url.includes('providerName') &&
                        !event.url.includes('providerId') &&
                        !event.url.includes('orderCode'))
                ) {
                    this.sessionService.isProviderSelectedForShopping.next(false);
                    this.selectedProviderName = null;
                } else {
                    this.sessionService.sessionSubject.subscribe((session) => {
                        if (session && session.selectedShops) {
                            this.selectedProviderName = session.selectedShops[0];
                        }
                    });
                }
            }
        });

        this.authStatusSub = this.authService
            .getAuthStatusListener()
            .subscribe((newAuthStatus) => {
                this.isAuthenticated = newAuthStatus;
                this.user = this.authService.getLoggedInUser();
            });

        this.paymentService.paymentProviders.subscribe((paymentProviders) => {
            this.defaultPaymentProviders = paymentProviders;
        });
    }

    closeRespMenu() {
        this.activeLevel = 1;
        this.respActiveMenu = '';
        this.openRespMenu = false;
        this.renderer.removeClass(document.body, 'body-menu-open');
    }

    closeRespSubCategory() {
        this.activeLevel = 1;
    }

    showResponsiveMenu() {
        this.openRespMenu = true;
        this.renderer.addClass(document.body, 'body-menu-open');
    }

    onLogOut() {
        this.authService.logout().subscribe(
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {},
            (err) => console.error(err)
        );
    }

    onOpenDialog(): void {
        void this.sharedModalService.presentSignInModal();
    }

    onOpenPostcodeDialog() {
        this.createDialogService.openAddressDialog();
    }

    ngOnDestroy() {
        if (this.menuStatusSub) {
            this.menuStatusSub.unsubscribe();
        }
        if (this.authStatusSub) {
            this.authStatusSub.unsubscribe();
        }
    }
}
