<div *ngIf="customBlock.ProductList.length === 0"></div>

<div
    *ngIf="customBlock.ProductList.length > 0 && !isCustomBlock"
    class="product-block-container px-4 w-100"
>
    <div class="mt-5 mb-4 owl-carousel-container position-relative">
        <div class="carousel-header w-100">
            <h2 class="font-weight-normal pt-2 float-start ps-2">
                {{ productListResult?.CategoryHeader.CategoryName }}
            </h2>
            <div class="float-end d-flex pt-3">
                <div
                    *ngIf="nextUrl && nextUrl !== ''"
                    class="text-size-16"
                    style="z-index: 9"
                >
                    <a
                        class="no-hover font-weight-600 tovabi-url turquoise-text mx-3 url-next-desktop"
                        [routerLink]="[nextUrl]"
                    >
                        {{ 'common.go-to-category' | translate }}
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </a>
                    <a
                        class="no-hover font-weight-600 tovabi-url turquoise-text mx-3 url-next-mobile"
                        [routerLink]="[nextUrl]"
                    >
                        {{ 'common.next' | translate }}
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </a>
                </div>
                <div class="custom-block-arrows-container">
                    <a class="arrows prev-arrow mx-1" (click)="carousel1.prev()">
                        <fa-icon [icon]="faChevronLeft"></fa-icon>
                    </a>
                    <a class="arrows next-arrow mx-1" (click)="carousel1.next()">
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </a>
                </div>
            </div>
        </div>
        <owl-carousel-o [options]="customOptions" #carousel1>
            <ng-template *ngFor="let product of customBlock.ProductList" carouselSlide>
                <div class="px-1">
                    <app-product-card-wrapper
                        [product]="product"
                        [isShoppingList]="false"
                    >
                    </app-product-card-wrapper>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>

<!----------------------------->
<div *ngIf="customBlock.ProductList.length === 0"></div>
<div
    *ngIf="customBlock.ProductList.length > 0 && isCustomBlock && isMainCategory"
    class="position-relative product-group-container mt-4"
    [ngClass]="{ 'not-main-category-block-container': carouselView }"
>
    <div *ngIf="!isMobileView" class="product-block-container font-weight-500">
        <div class="mt-3 mb-3 owl-carousel-container custom pe-2">
            <div class="carousel-header w-100">
                <h2
                    *ngIf="title && customBlock.CategoryBlockTypeID !== 5"
                    class="font-weight-normal pt-2 float-start"
                >
                    {{ title }}
                </h2>
                <h2 class="font-weight-normal pt-2 float-start">
                    {{ customBlock.Description }}
                </h2>
                <div class="float-end d-flex pt-3 position-relative">
                    <div
                        *ngIf="nextUrl && nextUrl !== ''"
                        class="text-size-16"
                        style="z-index: 9"
                    >
                        <a
                            class="no-hover font-weight-600 tovabi-url turquoise-text mx-3 url-next-desktop"
                            [routerLink]="[nextUrl]"
                        >
                            {{ 'common.go-to-category' | translate }}
                            <fa-icon [icon]="faChevronRight"></fa-icon>
                        </a>
                        <a
                            class="no-hover font-weight-600 tovabi-url turquoise-text mx-3 url-next-mobile"
                            [routerLink]="[nextUrl]"
                        >
                            {{ 'common.next' | translate }}
                            <fa-icon [icon]="faChevronRight"></fa-icon>
                        </a>
                    </div>
                    <div class="custom-block-arrows-container">
                        <a class="arrows prev-arrow mx-1" (click)="carousel2.prev()">
                            <fa-icon [icon]="faChevronLeft"></fa-icon>
                        </a>
                        <a class="arrows next-arrow mx-1" (click)="carousel2.next()">
                            <fa-icon [icon]="faChevronRight"></fa-icon>
                        </a>
                    </div>
                </div>
            </div>
            <app-category-block-header
                [customImg]="
                    customBlock.CategoryBlockTypeID !== 5
                        ? baseImgUrl + categoryImgSrc
                        : siteImageUrl + 'images/megfon-marketing.png'
                "
                [backgroundColor]="customBlock.BackColor"
                [routerLink]="nextUrl"
            ></app-category-block-header>
            <owl-carousel-o
                [options]="customBlockOptions"
                #carousel2
                class="custom-block-container d-block"
            >
                <ng-template
                    *ngFor="let product of customBlock.ProductList"
                    carouselSlide
                >
                    <div class="px-1">
                        <app-product-card-wrapper
                            [product]="product"
                            [isShoppingList]="false"
                        >
                        </app-product-card-wrapper>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>

    <ng-container *ngIf="isMobileView">
        <div
            class="w-100 custom-block-header-container my-2 d-flex flex-column align-items-center position-relative"
        >
            <p
                *ngIf="title && customBlock.CategoryBlockTypeID !== 5"
                class="text-size-20 m-0 pt-2"
                [ngStyle]="{
                    color: customBlock?.TitleTextColor
                        ? customBlock.TitleTextColor
                        : 'text-black'
                }"
            >
                {{ title }}
            </p>
            <p
                [ngStyle]="{
                    color: customBlock?.TitleTextColor
                        ? customBlock.TitleTextColor
                        : 'text-black'
                }"
            >
                {{ customBlock.Description }}
            </p>
            <img
                class="col-prod-cat-image"
                *ngIf="customBlock.CategoryBlockTypeID !== 5"
                [src]="baseImgUrl + categoryImgSrc"
            />
            <img
                *ngIf="customBlock.CategoryBlockTypeID === 5"
                class="marketing-img"
                src="{{ siteImageUrl }}images/megafon-mobil.png"
            />

            <a
                *ngIf="nextUrl && nextUrl !== ''"
                [routerLink]="[nextUrl]"
                class="btn next-cat-btn d-block position-absolute"
            >
                {{ 'common.go-to-category' | translate }}
            </a>
        </div>
        <div class="custom-block-container font-weight-500 position-relative">
            <div class="custom-block-arrows-container custom-block">
                <a
                    class="arrows d-flex justify-content-center align-items-center prev-arrow mx-1"
                    (click)="carousel2.prev()"
                >
                    <fa-icon [icon]="faChevronLeft"></fa-icon>
                </a>
                <a
                    class="arrows d-flex justify-content-center align-items-center next-arrow mx-1"
                    (click)="carousel2.next()"
                >
                    <fa-icon [icon]="faChevronRight"></fa-icon>
                </a>
            </div>
            <owl-carousel-o [options]="customBlockOptions" #carousel2>
                <ng-template
                    *ngFor="let product of customBlock.ProductList"
                    class="productcard-container"
                    carouselSlide
                >
                    <div class="px-1">
                        <app-product-card-wrapper
                            [product]="product"
                            [isShoppingList]="false"
                        >
                        </app-product-card-wrapper>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
        <div
            class="position-absolute basket-background provider-productlist-header"
        ></div>
        <div
            class="position-absolute basket-background"
            *ngIf="customBlock"
            [style.background-color]="customBlock.BackColor"
        ></div>
        <div
            class="position-absolute basket-background marketing-background"
            [style.background-image]="
                'url(' + siteImageUrl + 'images/megfon-marketing.png)'
            "
            *ngIf="customBlock.CategoryBlockTypeID === 5"
        ></div>
    </ng-container>
    <div
        *ngIf="htmlMarketingElementDto"
        [style]="htmlMarketingElementDto?.cssFormats"
        [innerHTML]="htmlMarketingElementDto?.safeHtml"
    ></div>
</div>

<!------------------------->
<div
    *ngIf="customBlock.ProductList.length > 0 && isCustomBlock && !isMainCategory"
    [routerLink]="[nextUrl]"
    class="pointer position-relative product-group-container"
>
    <div class="leaf-product-group-container">
        <div
            class="leaf-img-container"
            [style.background-image]="'url(' + baseImgUrl + coverImgSrc + ')'"
        ></div>
        <div class="leaf-description-container">
            <p *ngIf="title" class="text-size-24 text-dark">
                {{ title }}
            </p>
            <br />
            <p class="text-dark">
                {{ customBlock.Description }}
            </p>
        </div>
    </div>
</div>
