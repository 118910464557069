import { Pipe, PipeTransform } from '@angular/core';
import { FormatterService } from '../services/formatter.service';

@Pipe({
    name: 'currencyFormatted',
})
export class CurrencyFormattedPipe implements PipeTransform {
    constructor(private formatterService: FormatterService) {}

    transform(value: number, ...args: unknown[]): string {
        const countryCode = args['countryCode'];
        return this.formatterService.formatCurrency(value, countryCode);
    }
}
