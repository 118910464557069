import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    CategoryService,
    CategoryShared,
    SessionService,
} from 'projects/library-shared/src/public-api';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FooterService } from '../../components/footer/footer.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-category-page',
    templateUrl: './category-page.component.html',
    styleUrls: ['./category-page.component.scss'],
})
export class CategoryPageComponent implements OnInit, OnDestroy {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private categoryService: CategoryService,
        private location: Location,
        private footerService: FooterService,
        private sessionService: SessionService,
        private translate: TranslateService
    ) {}

    allCategories: CategoryShared[] = [];
    isSubCategory = false;
    routeLink: string;
    categorySub: Subscription;
    categoryParentName: string;

    footerStatus: Subscription;
    isFooterFloating = false;

    ngOnInit() {
        this.translate
            .get('router.offer')
            .subscribe((text) => (this.routeLink = '/' + text + '/'));

        this.categorySub = this.route.params.subscribe((params) => {
            if (params && params['cat']) {
                this.loadSubCategories(params['cat']); // Load Sub subCatChilds
                this.isSubCategory = true; // Change view state

                //On shopselection we refresh the whole site
                this.sessionService.sessionSubject.subscribe(() => {
                    this.loadSubCategories(params['cat']); // Load Sub subCatChilds
                    this.isSubCategory = true; // Change view state
                });
            } else {
                this.categoryService.categories.subscribe((allCategories) => {
                    this.allCategories = allCategories; // Get All main Categories [POST]
                });
                //On shopselection we refresh the whole site
                this.sessionService.sessionSubject.subscribe(() => {
                    this.categoryService.categories.subscribe((allCategories) => {
                        this.allCategories = allCategories; // Get All main Categories [POST]
                    });
                });
            }
        });

        this.footerStatus = this.footerService
            .getFooterStatusListener()
            .subscribe((isFloat) => (this.isFooterFloating = isFloat));
    }

    goBackClick() {
        this.location.back();
    }

    loadSubCategories(categoryParentProgID: string) {
        this.categoryService.getCatChildrenList(categoryParentProgID).subscribe(
            (subCatChilds) => {
                this.allCategories = subCatChilds;
                this.categoryParentName =
                    subCatChilds[0]['ParentCategory']['CategoryName'];

                this.translate.get('router.offer').subscribe((text) => {
                    this.routeLink = '/' + text + '/${this.categoryParentName}/'; // Change route link for <a> of every category-card
                });

                this.isSubCategory = true;
            },
            () => {
                this.router.navigate(['404'], {});
            }
        );
    }

    ngOnDestroy() {
        if (this.categorySub) {
            this.categorySub.unsubscribe();
        }
        if (this.footerStatus) {
            this.footerStatus.unsubscribe();
        }
    }
}
