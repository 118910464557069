import { LocationTypes } from '../../types/google-maps-types';
import { GeoLocation } from '../geolocation/geolocation';

export class ValidatedAddress {
    public isValid: boolean;
    public location: GeoLocation;
    public address: string;
    public locationType: string;
    public streetName: string;
    public cityName: string;

    constructor(
        isValid: boolean,
        location: GeoLocation,
        address: string = null,
        streetName: string = null,
        cityName: string = null,
        locationType: string = LocationTypes.UNKNOWN
    ) {
        this.isValid = isValid;
        this.location = location;
        this.address = address;
        this.locationType = locationType;
        this.streetName = streetName;
        this.cityName = cityName;
    }
}
