<div (click)="showRokshDiscountInfo()" class="d-flex align-items-end order w-100 h-100">
    <div class="progress-bar-container-desktop pe-3 w-100">
        <div class="progress position-relative" #wrapper>
            <div
                class="progress-bar"
                role="progressbar"
                [style]="'width:' + getProgressBarLength + '%;'"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
            >
                <div
                    *ngIf="nextRokshDiscountLevelIndex"
                    [style.width]="wrapper.offsetWidth + 'px'"
                    class="text-white text-end"
                >
                    {{ 'common.roksh-discount-progress-bar-level' | translate }}
                    {{ nextRokshDiscountLevelIndex }}
                </div>
            </div>
            <div class="progress-completed position-absolute">
                {{ 'common.roksh-discount-progress-bar-level' | translate }}
                {{ nextRokshDiscountLevelIndex }}
            </div>
        </div>
        <div class="progress-bar-footer">
            <div>
                {{ currentTotalPrice + currentDepositPrice | currencyFormatted }}
                {{ 'common.currency' | translate }}
            </div>
            <div class="ms-auto">
                {{ nextRokshDiscountLevelBasketValue | currencyFormatted }}
                {{ 'common.currency' | translate }}
            </div>
        </div>
    </div>

    <div class="badge-container-desktop h-100">
        <span class="badge h-100 pointer">
            <span class="discount-text" [class.my-2]="countryCode === 'at'">{{
                'common.roksh-discount-badge' | translate
            }}</span>
            <span *ngIf="countryCode !== 'at'"
                >{{ currentRokshDiscount | currencyFormatted }}
                {{ 'common.currency' | translate }}</span
            >
        </span>
    </div>
</div>
