import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs/operators';
import { InstantTimeslotDTO, TimeSlotClient } from '../..';
import { SessionService } from '../session/session.service';
import { BasketService } from '../basket.service';
import { DateService } from '../date/date.service';
import { FormatterService } from '../formatter.service';
import {
    AvailableTimeSlotsDTO,
    InstantDeliveryAreaProvider,
    InstantWorktimeQueryDTO,
    Supplier,
    TimeSlotListRequestDTO,
    TimeSlotPriceDiscountDto,
} from '../../index';
import { CountryCodes } from '../../types/country-codes';
import { ServiceBaseService } from '../service-base.service';

export interface NotAvailableTextOptions {
    instantWorkTime: InstantTimeslotDTO;
    selectionAllowed: boolean;
    selectedExpressTimeSlotInterval: number;
    configuredInterval: number;
    discountPercentage: number;
    discountedDeliveryFee: number;
    isOverWeightLimit: boolean;
    isUnderPriceLimit: boolean;
    areaHasInstantProvider: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class TimeSlotService {
    activeEarliestTimeSlotId = new BehaviorSubject<string>('');
    activeTimeSlotId = new BehaviorSubject<string>('');

    private _isFinishedLoadingSource: BehaviorSubject<boolean>;
    public readonly isFinishedLoading$: Observable<boolean>;

    constructor(
        private timeSlotClient: TimeSlotClient,
        private sessionService: SessionService,
        private basketService: BasketService,
        private dateService: DateService,
        private translate: TranslateService,
        private formatterService: FormatterService
    ) {
        this._isFinishedLoadingSource = new BehaviorSubject(false);
        this.isFinishedLoading$ = this._isFinishedLoadingSource.asObservable();
    }

    getAvailableTimeSlotList(
        providerID: number,
        postCode: string,
        orderCode = ''
    ): Observable<AvailableTimeSlotsDTO> {
        const timeSlotListRequestDTO = new TimeSlotListRequestDTO({
            providerID: providerID,
            postCode: postCode,
            orderCode: orderCode,
        });
        return this.timeSlotClient.getAvailableTimeSlotList(timeSlotListRequestDTO);
    }

    getRealTimeInstantSuppliersByBoundary(
        providerID: number,
        zipCode: string,
        itemCount: number,
        instantIntervalHours: number
    ): Observable<Supplier[]> {
        return this.timeSlotClient.getRealTimeInstantSuppliersByBoundary(
            providerID,
            zipCode,
            itemCount,
            instantIntervalHours
        );
    }

    getRealTimeInstantTimeSlotsByBoundary(
        worktimeQuery: InstantWorktimeQueryDTO
    ): Observable<InstantTimeslotDTO[]> {
        return this.timeSlotClient.getRealTimeInstantTimeSlotsByBoundary(worktimeQuery);
    }

    getInstantDeliveryAreaProvider(
        providerID: number,
        zipCode: string
    ): Observable<InstantDeliveryAreaProvider> {
        return this.timeSlotClient.getInstantDeliveryAreaProvider(providerID, zipCode);
    }

    getConfiguredExpressWorktimesByProvider(
        providerID: number,
        postCode: string
    ): Observable<InstantTimeslotDTO[]> {
        return this.timeSlotClient.getConfiguredExpressWorktimesByProvider(
            providerID,
            postCode
        );
    }

    setFinishedLoadingSource(isFinishedLoading: boolean): void {
        this._isFinishedLoadingSource.next(isFinishedLoading);
    }

    getCurrentBasketValue(
        customCurrentBasketValue: number,
        isModifyDeliveryTime: boolean
    ): number {
        let currentBasketValue: number;
        if (customCurrentBasketValue) {
            currentBasketValue = customCurrentBasketValue;
        } else if (!isModifyDeliveryTime) {
            this.sessionService.sessionSubject.subscribe((userSelectedShops) => {
                if (userSelectedShops.selectedShops.length === 1) {
                    this.basketService.masterBasketSubject.subscribe((mb) => {
                        const currentBasket = mb.basketList.find(
                            (b) =>
                                b.providerName.toLocaleLowerCase() ===
                                userSelectedShops.selectedShops[0].toLocaleLowerCase()
                        );
                        currentBasketValue = currentBasket.totalPrice;
                    });
                }
            });
        }

        return currentBasketValue;
    }

    getCurrentBasketWeight(
        customCurrentBasketWeight: number,
        isModifyDeliveryTime: boolean
    ): number {
        if (!isModifyDeliveryTime) {
            return this.basketService.getCurrentTotalWeigth();
        }

        return customCurrentBasketWeight;
    }

    getDiscountedDeliveryFee(
        deliveryFee: number,
        timeSlotPriceDiscountDtoList: TimeSlotPriceDiscountDto[],
        currentBasketValue: number
    ): number {
        let discountPercentage = 0;

        if (!timeSlotPriceDiscountDtoList || timeSlotPriceDiscountDtoList?.length === 0)
            return discountPercentage;

        timeSlotPriceDiscountDtoList = timeSlotPriceDiscountDtoList.sort((a, b) =>
            a.discountFromBasketValue > b.discountFromBasketValue ? 1 : -1
        );

        for (let i = 0; i < timeSlotPriceDiscountDtoList.length; i++) {
            if (
                timeSlotPriceDiscountDtoList[i].discountFromBasketValue <=
                currentBasketValue
            ) {
                discountPercentage = timeSlotPriceDiscountDtoList[i].discountPercentage;
            }
        }

        return (1 - discountPercentage / 100) * deliveryFee;
    }

    getDiscountPercentage(
        deliveryFee: number,
        timeSlotPriceDiscountDtoList: TimeSlotPriceDiscountDto[],
        currentBasketValue: number
    ): number {
        let discountPercentage = 0;

        if (!timeSlotPriceDiscountDtoList || timeSlotPriceDiscountDtoList?.length === 0)
            return discountPercentage;

        timeSlotPriceDiscountDtoList = timeSlotPriceDiscountDtoList.sort((a, b) =>
            a.discountFromBasketValue > b.discountFromBasketValue ? 1 : -1
        );

        for (let i = 0; i < timeSlotPriceDiscountDtoList.length; i++) {
            if (
                timeSlotPriceDiscountDtoList[i].discountFromBasketValue <=
                currentBasketValue
            ) {
                discountPercentage = timeSlotPriceDiscountDtoList[i].discountPercentage;
            }
        }

        return discountPercentage;
    }

    prepareTimeSlotList(availableTimeSlotDto: AvailableTimeSlotsDTO): void {
        this.translate.get('time-slot.full-slot').subscribe((text) => {
            availableTimeSlotDto?.timeSlotRangeList.forEach((timeSlotRange, index) => {
                timeSlotRange.timeSlotDayList.forEach((timeSlotDay, indexDay) => {
                    const dayText = this.dateService.getDayString(timeSlotDay.date);

                    const dateText = this.dateService.getDateString(timeSlotDay.date);

                    if (!timeSlotDay.isAvailableForToday) {
                        availableTimeSlotDto.timeSlotRangeList[index].timeSlotDayList[
                            indexDay
                        ].displayText =
                            '<strong>' +
                            text +
                            '</strong><span>' +
                            dayText +
                            '</span><span>' +
                            dateText +
                            '</span>';
                    } else {
                        availableTimeSlotDto.timeSlotRangeList[index].timeSlotDayList[
                            indexDay
                        ].displayText = '<strong>' + dayText + '</strong>' + dateText;
                    }
                });
            });
        });
    }

    getDeliverFee(
        deliveryFee: number,
        timeSlotPriceDiscountDtoList: TimeSlotPriceDiscountDto[],
        currentBasketValue: number
    ): number {
        const discountPercentage = this.getDiscountPercentage(
            deliveryFee,
            timeSlotPriceDiscountDtoList,
            currentBasketValue
        );
        if (discountPercentage !== 0) {
            return this.getDiscountedDeliveryFee(
                deliveryFee,
                timeSlotPriceDiscountDtoList,
                currentBasketValue
            );
        }

        return deliveryFee;
    }

    getNotAvailableText(textOptions: NotAvailableTextOptions): Observable<string> {
        if (!textOptions.areaHasInstantProvider) {
            return this.translate.get('time-slot.full-express-slot');
        } else if (
            textOptions.isOverWeightLimit &&
            textOptions.instantWorkTime?.maxBasketWeightRestriction
        ) {
            return this.translate.get('common.under').pipe(
                switchMap((transl) => {
                    return ServiceBaseService.countrycode == CountryCodes.at
                        ? transl +
                              ' ' +
                              textOptions.instantWorkTime.maxBasketWeight / 1000 +
                              'kg'
                        : textOptions.instantWorkTime.maxBasketWeight / 1000 +
                              'kg' +
                              ' ' +
                              transl;
                })
            );
        } else if (
            textOptions.isUnderPriceLimit &&
            textOptions.instantWorkTime?.minBasketValueRestriction
        ) {
            return forkJoin([
                this.translate.get('common.and'),
                this.translate.get('common.above'),
                this.translate.get('common.currency'),
            ]).pipe(
                switchMap(([and, above, currency]: [string, string, string]) => {
                    let text = ' ' + and + ' ';
                    text +=
                        ServiceBaseService.countrycode == CountryCodes.at
                            ? above +
                              ' ' +
                              this.formatterService.formatCurrency(
                                  textOptions.instantWorkTime.minBasketValue
                              ) +
                              currency
                            : this.formatterService.formatCurrency(
                                  textOptions.instantWorkTime.minBasketValue
                              ) +
                              currency +
                              ' ' +
                              above;

                    return text;
                })
            );
        }
    }

    removeActiveClass(elements: HTMLCollectionOf<Element>): void {
        for (let i = 0; i < elements.length; i++) elements[i].classList.remove('active');
    }
}
