import { Component, Input, OnInit } from '@angular/core';
import {
    BasketService,
    CountryCodes,
    ProductProviderDto,
    ServiceBaseService,
    SessionService,
} from 'projects/library-shared/src/public-api';

@Component({
    selector: 'app-product-price-row',
    templateUrl: 'product-price-row.component.html',
    styleUrls: ['product-price-row.component.scss'],
})
export class ProductPriceRowComponent implements OnInit {
    @Input() productProvider: ProductProviderDto;
    @Input() minPrice: number;
    @Input() zipCode: string;
    @Input() hasMoreCheapest = false;
    @Input() isAllShop = true;

    cheapestShop: boolean;
    siteImageUrl = '';
    hasAnyProviderDepositProduct = false;
    CountryCodes = CountryCodes;
    countryCode = this.CountryCodes.hu;
    public get isAtCountryCode(): boolean {
        return this.countryCode === CountryCodes.at;
    }

    constructor(
        public basketService: BasketService,
        private sessionService: SessionService,
        public baseService: ServiceBaseService
    ) {
        this.countryCode = ServiceBaseService.getCountryCode();
    }

    ngOnInit() {
        if (
            this.productProvider.productProviderProviderDepositProducList &&
            this.productProvider.productProviderProviderDepositProducList.length > 0
        ) {
            this.hasAnyProviderDepositProduct = true;
        }

        this.siteImageUrl = this.baseService.getSiteImageUrl();

        if (this.hasMoreCheapest) {
            this.cheapestShop = false;
        } else {
            this.cheapestShop =
                (this.productProvider.isBulk &&
                    this.minPrice === Number(this.productProvider.unitPrice)) ||
                (!this.productProvider.isBulk &&
                    this.minPrice === this.productProvider.price);
        }
    }
}
