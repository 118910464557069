import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BasketService } from '../../services/basket.service';
import { ServiceBaseService } from '../../services/service-base.service';
import { SnackBarService } from '../../services/snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'lib-empty-basket-dialog',
    templateUrl: './empty-basket-dialog.component.html',
    styleUrls: ['./empty-basket-dialog.component.scss'],
})
export class EmptyBasketDialogComponent {
    siteImageUrl: string;
    deleteInProgress = false;
    faSpinner = faSpinner;

    constructor(
        public dialogRef: MatDialogRef<EmptyBasketDialogComponent>,
        public basketService: BasketService,
        private snack: SnackBarService,
        private translate: TranslateService,
        baseService: ServiceBaseService
    ) {
        this.siteImageUrl = baseService.siteImageUrl;
    }

    noClicked(): void {
        this.dialogRef.close();
    }

    yesClicked(): void {
        if (this.deleteInProgress) return;

        this.deleteInProgress = true;
        this.basketService.emptyCurrentBasket().subscribe(
            () => {
                this.dialogRef.close();
                this.deleteInProgress = false;
            },
            () => {
                this.translate.get('payment.other-error').subscribe((text) => {
                    this.snack.openErrorSnackBar(text);
                });
                this.deleteInProgress = false;
            }
        );
    }
}
