<div id="accordion" class="mb-5">
    <ul class="category-page-sidebar-categories mb-5">
        <ng-container #liContainer *ngFor="let category of categoryList; let i = index">
            <li
                class="category-page-sidebar-category"
                [attr.id]="
                    category.ProgID === activeCategory ? 'active-li' : category.ProgID
                "
            >
                <a
                    class="category-page-sidebar-category-link category-page-sidebar-category-text"
                    [attr.data-bs-toggle]="
                        category.ProgID !== activeCategory ? 'collapse' : null
                    "
                    [attr.id]="
                        category.ProgID === activeCategory ? 'active' : category.ProgID
                    "
                    [attr.data-bs-target]="category.ProgID"
                    [routerLink]="category.Url"
                    (click)="scroll(category.ProgID)"
                >
                    {{ category.CategoryName }}
                </a>
                <ul
                    class="category-page-sidebar-subcategories"
                    [ngClass]="{ collapse: category.ProgID !== activeCategory }"
                    id="{{ category.ProgID }}"
                    data-bs-parent="#accordion"
                >
                    <ng-container *ngFor="let categoryChild of category.ChildList">
                        <li class="category-page-sidebar-subcategory">
                            <a
                                class="category-page-sidebar-subcategory-link"
                                [ngClass]="{
                                    'active-child':
                                        categoryChild.ProgID === activeChildCategory
                                }"
                                [routerLink]="categoryChild.Url"
                            >
                                <span class="category-page-sidebar-subcategory-text">{{
                                    categoryChild.CategoryName
                                }}</span>
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </li>
            <ng-container *ngIf="i === categoryList.length - 1 && !this.viewInited">
                {{ this.scroll(this.activeCategory) }}
            </ng-container>
        </ng-container>
        <ng-container *ngIf="categoryList.length === 0">
            <div *ngFor="let item of dummyArray" class="skeleton my-2 mx-3"></div>
        </ng-container>
    </ul>
</div>
