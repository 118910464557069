<div class="text-end w-100 close-button">
    <span class="pointer" (click)="close()">
        <img
            class="expansion-header-svg close-icon"
            src="{{ siteImageUrl }}images/shared-img/close.svg"
            alt="close"
        />
    </span>
</div>
<div class="w-100 h-100 position-relative list-container">
    <div class="p-3 pt-5 h-100 d-flex flex-column justify-content-between">
        <div class="overflow-x-hidden">
            <h4 class="fw-bold">{{ 'product-card.add-to-list' | translate }}</h4>
            <p class="text-grey">
                {{ 'product-card.select-or-create-list' | translate }}
            </p>
            <div class="w-100 py-3" (click)="onCreateNewUserBasket()">
                <button class="pointer add-list w-100 my-1 mx-0">
                    + {{ 'product-card.create-new-list' | translate }}
                </button>
            </div>
            <div *ngIf="shoppingList">
                <lib-add-product-to-list-row
                    [userBasket]="userBasket"
                    [selectedProduct]="product"
                    [quantityList]="quantityList"
                    *ngFor="let userBasket of shoppingList"
                ></lib-add-product-to-list-row>
            </div>
        </div>
    </div>
</div>
