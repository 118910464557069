<div class="dialog-header mt-4">
    <span class="title font-weight-bold d-flex justify-content-center">
        <img
            class="expansion-header-svg me-2"
            src="assets/svg/rokshImages/icons/clock.svg"
        />
        {{ dialogTitle }}</span
    >
    <div class="text-end w-100 close-button">
        <span class="pointer" (click)="dialogRef.close()">
            <img
                class="expansion-header-svg close-icon"
                src="assets/svg/rokshImages/icons/close.svg"
            />
        </span>
    </div>
</div>

<app-provider-timeslots
    *ngIf="deliveryTimeQueriesDone"
    [selectionAllowed]="true"
    [availableTimeSlotDto]="availableTimeSlotsDto"
    [customCurrentBasketValue]="totalPrice"
    [customCurrentBasketWeight]="totalWeight"
    [postCode]="postCode"
    [isModifyDeliveryTime]="true"
    (onSetDeliveryTimeSlot)="setDeliveryTimeSlot($event)"
    (onTimeSlotDaySelection)="showAvailableTimeSlots($event)"
>
</app-provider-timeslots>

<div class="m-3" *ngIf="isShowSaveButton">
    <button (click)="onSave()" class="btn pull-right btn-warning w-100">
        {{ 'common.save' | translate }}
    </button>
</div>
