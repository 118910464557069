<div id="wishListPanel" [formGroup]="checkoutForm">
    <div formGroupName="wishListForm">
        <mat-expansion-panel
            [expanded]="wishListStepIsExpanded"
            [disabled]="wishListStepIsDisabled"
            [class.activated-step]="wishListStepIsActive"
            style="margin-bottom: 5px"
            (opened)="setStep(wishListStepIndex)"
            hideToggle
        >
            <mat-expansion-panel-header
                class="checkout-panel-header"
                style="height: 90px !important; width: calc(100% - 10px)"
            >
                <div class="checkout-header-img">
                    <img src="assets/checkout/wish-list.svg" class="expansion-header" />
                </div>
                <div class="column w-100">
                    <mat-panel-title>
                        <strong class="w-100">
                            <div class="row ms-0">
                                <div class="col ps-0">
                                    {{ 'common.wishlist' | translate }}
                                </div>
                            </div>
                        </strong>
                    </mat-panel-title>
                </div>
                <img
                    src="assets/svg/valid.svg"
                    class="d-none"
                    style="margin: auto 10px; height: 1rem"
                />
            </mat-expansion-panel-header>

            <div class="col-12 font-weight-normal pt-2 pe-3 float-start description">
                {{ 'checkout.wish-list-description' | translate }}
            </div>
            <br />

            <div
                *ngIf="
                    wishListProviderShops && wishListProviderShops.length > 0;
                    else noWishlistShopAvailable
                "
                [class.panel-opacity]="!isExtraProductItem"
                class="mt-4"
            >
                <div class="col-12 fw-bold font-weight-normal mt-4 pe-3 mb-3 float-start">
                    {{ 'checkout.choose-shop' | translate }}
                </div>
                <div class="d-inline-flex">
                    <div
                        *ngFor="
                            let wishListProviderShop of wishListProviderShops;
                            let idx = index
                        "
                        class="me-2 md-2"
                    >
                        <div
                            class="p-2 m-1 wish-list-shop d-flex justify-content-center"
                            (click)="
                                addProviderShop(
                                    wishListProviderShop.imageUrl,
                                    wishListProviderShop.wishListProviderShopName,
                                    wishListProviderShop.maxNumberOfProductTypes,
                                    wishListProviderShop.wishListProviderShopID
                                )
                            "
                            style="white-space: pre-wrap"
                            [ngClass]="{
                                active: isActiveShop(
                                    wishListProviderShop.wishListProviderShopID
                                )
                            }"
                        >
                            <ng-container>
                                <img
                                    [src]="wishListProviderShop.imageUrl"
                                    itemprop="image"
                                    class="image-shop"
                                />
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="col-12 fw-bold font-weight-normal mt-4 pe-3 float-start">
                    {{ 'checkout.extra-products' | translate }}
                </div>
                <div
                    class="col-12 font-weight-normal pt-2 pe-3 mb-2 float-start description mb-4"
                    [innerHTML]="
                        'checkout.extra-products-description'
                            | translate : { maxProductNumber: maxNumberOfProduct }
                    "
                ></div>

                <ng-container formArrayName="wishListProviderShops">
                    <div
                        *ngFor="
                            let wishListProviderShop of wishListProviderShopsForm.controls;
                            index as shop
                        "
                    >
                        <ng-container [formGroupName]="shop">
                            <div class="container container-wish-list ps-0">
                                <div class="row justify-content-start row-wish-list">
                                    <!-- Desktop and Tablet WishListShop remove btn-->
                                    <div
                                        class="col-1 d-none d-sm-block"
                                        style="padding-top: 0.25em"
                                    >
                                        <button
                                            (click)="
                                                removeProviderShop(
                                                    shop,
                                                    wishListProviderShop.value
                                                        .WishListProviderShopID
                                                )
                                            "
                                            class="btn btn-remove"
                                        >
                                            <img
                                                alt="remove"
                                                src="/assets/svg/rokshImages/icons/remove.svg"
                                                width="10"
                                            />
                                        </button>
                                    </div>
                                    <div
                                        class="col-9 col-sm-3 text-center"
                                        style="padding-top: 0.25em"
                                    >
                                        <div
                                            class="wish-list-shop wish-list-shop-mobile p-2 d-flex justify-content-sm-center"
                                        >
                                            <ng-container>
                                                <img
                                                    [src]="
                                                        wishListProviderShop.value.Shop
                                                    "
                                                    itemprop="image"
                                                    class="image-shop"
                                                />
                                                <span
                                                    class="ps-2 d-inline-block d-sm-none"
                                                >
                                                    {{
                                                        wishListProviderShop.value
                                                            .ShopName
                                                    }}
                                                </span>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <!-- Mobile WishListShop remove btn-->
                                    <div
                                        class="col-3 d-block d-sm-none"
                                        style="padding-top: 0.25em"
                                    >
                                        <button
                                            (click)="
                                                removeProviderShop(
                                                    shop,
                                                    wishListProviderShop.value
                                                        .WishListProviderShopID
                                                )
                                            "
                                            class="btn btn-remove"
                                        >
                                            <img
                                                alt="remove"
                                                src="/assets/svg/rokshImages/icons/remove.svg"
                                                width="10"
                                            />
                                        </button>
                                    </div>

                                    <div class="col-12 col-sm-8">
                                        <ng-container
                                            formArrayName="wishListProductItems"
                                        >
                                            <div
                                                *ngFor="
                                                    let productItem of getProductItems(
                                                        wishListProviderShop
                                                    );
                                                    index as productItemIndex
                                                "
                                                class="row"
                                            >
                                                <ng-container
                                                    [formGroupName]="productItemIndex"
                                                >
                                                    <mat-form-field
                                                        class="col-8"
                                                        appearance="outline"
                                                    >
                                                        <textarea
                                                            formControlName="ProductName"
                                                            matInput
                                                            class="checkout-form-input w-100"
                                                            placeholder="{{
                                                                'checkout.product-item-description'
                                                                    | translate
                                                            }}"
                                                            id="ProductName"
                                                        >
                                                        </textarea>
                                                    </mat-form-field>
                                                    <mat-form-field
                                                        class="col-3 quantity"
                                                        appearance="outline"
                                                    >
                                                        <textarea
                                                            formControlName="QuantityText"
                                                            matInput
                                                            class="checkout-form-input w-100"
                                                            placeholder="{{
                                                                'checkout.quantity-description'
                                                                    | translate
                                                            }}"
                                                            id="QuantityText"
                                                        >
                                                        </textarea>
                                                    </mat-form-field>
                                                    <div class="col-1 m-1 mt-4 remove">
                                                        <button
                                                            (click)="
                                                                removeProductItem(
                                                                    shop,
                                                                    productItemIndex
                                                                )
                                                            "
                                                            class="btn btn-remove-items btn-rounded d-flex justify-content-center align-items-center"
                                                        >
                                                            <img
                                                                alt="remove"
                                                                src="/assets/svg/rokshImages/icons/minus-without-border.svg"
                                                                width="10"
                                                            />
                                                        </button>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-4"></div>
                                        <div class="col-12 col-sm-8 pe-4">
                                            <button
                                                class="btn btn-add w-100"
                                                *ngIf="validateSize(wishListProviderShop)"
                                                (click)="addProductItem(shop)"
                                            >
                                                <img
                                                    alt="add"
                                                    src="/assets/svg/add.png"
                                                    width="10"
                                                />
                                                {{
                                                    'checkout.add-extra-product'
                                                        | translate
                                                }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>

            <ng-template #noWishlistShopAvailable>
                <br /><br />
                <div
                    class="text-center px-4 py-3 color-danger no-timeslot-available-message mt-5"
                >
                    {{ 'wishlist.no-wishlist-shop-available-message' | translate }}
                </div>
            </ng-template>

            <div class="pt-2 mt-4 fw-bold">
                <input
                    id="extraProductItemCheckBox"
                    type="checkbox"
                    (change)="onChangeExtraProductItemCheckBox($event)"
                />
                {{ 'checkout.no-extra-product' | translate }}
            </div>
            <br />
            <mat-action-row class="text-center">
                <span
                    mat-button
                    class="col-12 col-md-6 m-auto m-md-0 btn btn-tovabb btn-rounded mb-2"
                    (click)="nextStep()"
                    >{{ 'common.next' | translate }}</span
                >
            </mat-action-row>
        </mat-expansion-panel>
    </div>
</div>
