import { Component, Input, OnInit } from '@angular/core';
import { ConfigureSessionDto, ProviderDTO } from '../../index';
import { ProvidersService } from '../../services/provider.service';
import { ServiceBaseService } from '../../services/service-base.service';
import { CategoryService } from '../../services/category.service';
import { SessionService } from '../../services/session/session.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
    selector: 'lib-provider-select-modal',
    templateUrl: './provider-select-modal.component.html',
    styleUrls: ['./provider-select-modal.component.css'],
})
export class ProviderSelectModalComponent implements OnInit {
    providers: ProviderDTO[];

    @Input() closeEmitter: (provider: ProviderDTO) => void;
    siteImageUrl: string;

    constructor(
        private providerService: ProvidersService,
        private categoryService: CategoryService,
        private sessionService: SessionService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        baseService: ServiceBaseService
    ) {
        this.siteImageUrl = baseService.getSiteImageUrl();
    }

    ngOnInit(): void {
        this.providerService.getAllProviders().subscribe((providers) => {
            this.providers = providers;
        });
    }

    handleChange(provider: ProviderDTO): void {
        const configureSessionDto = new ConfigureSessionDto({
            userSelectedShops: [provider.name],
            redirectToDashboardNeeded: false,
        });

        const queryParams: Params = {
            providerName: provider.name,
        };

        void this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams,
            queryParamsHandling: 'merge',
        });

        this.categoryService.categories.next([]);
        this.sessionService
            .configureUserSession(configureSessionDto)
            .pipe(take(1))
            .subscribe();

        this.closeEmitter(provider);
    }
}
