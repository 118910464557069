import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import {
    LocationService,
    OrderDeliveryAddressDTO,
    OrderService,
    SnackBarService,
} from 'projects/library-shared/src/public-api';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { CreateDialogService } from '../../../services/create-dialog.service';

@Component({
    selector: 'app-delivery-address-dialog',
    templateUrl: './delivery-address-dialog.component.html',
    styleUrls: ['./delivery-address-dialog.component.scss'],
})
export class DeliveryAddressDialogComponent implements OnInit {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    faTimesCircle = faTimesCircle;
    selectedIndex: number;
    addressValidated = false;
    zipIsValid = false;
    deliveryAvailablePostcodes: string[] = [];
    isShowNextButton = false;

    constructor(
        private snackBarService: SnackBarService,
        private translate: TranslateService,
        private _locationService: LocationService,
        private orderService: OrderService,

        public dialogRef: MatDialogRef<DeliveryAddressDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private createDialogService: CreateDialogService
    ) {}

    deliveryAddressForm = this.formBuilder.group({
        PostCode: [
            null,
            [
                Validators.required,
                Validators.minLength(4),
                Validators.maxLength(4),
                Validators.pattern(/^[0-9]*$/),
            ],
        ],
        City: [null, [Validators.required, Validators.minLength(2)]],
        StreetName: [null, [Validators.required, Validators.minLength(2)]],
        HouseNumber: [null, [Validators.required, Validators.minLength(1)]],
    });

    ngOnInit(): void {
        this.orderService
            .getDeliveryAvailablePostcodes(this.data.providerID)
            .subscribe((postcodes) => {
                this.deliveryAvailablePostcodes = postcodes;
                this.isShowNextButton = true;
            });
    }

    nextStep(): void {
        const postCode = this.deliveryAddressForm.value.PostCode;
        const city = this.deliveryAddressForm.value.City;
        const streetName = this.deliveryAddressForm.value.StreetName;
        const houseNumber = this.deliveryAddressForm.value.HouseNumber;
        if (!postCode && !city && !streetName && !houseNumber) {
            this.translate
                .get('checkout.address-required')
                .subscribe((text) => this.snackBarService.openSnackBar(text));
        } else {
            this.getGoogleResult(postCode, city, streetName, houseNumber);
        }
    }

    getGoogleResult(
        postCode: string,
        city: string,
        streetName: string,
        houseNumber: string
    ): void {
        if (
            postCode.length === 4 &&
            city &&
            streetName &&
            houseNumber &&
            this.deliveryAvailablePostcodes.indexOf(postCode) == -1
        ) {
            this.translate
                .get('messages.we-dont-deliver-for-this-zip')
                .subscribe((text) => this.snackBarService.openSnackBar(text));

            this.zipIsValid = false;
            this.addressValidated = false;
        } else {
            this._locationService
                .validateAddress(postCode, city, streetName, houseNumber)
                .subscribe((addressIsvalid) => {
                    if (addressIsvalid.isValid) {
                        this.addressValidated = true;
                        this.zipIsValid = true;
                        this.forwardToNextStep();
                    } else {
                        this.addressValidated = false;
                        this.zipIsValid = false;
                    }
                });
        }
    }

    forwardToNextStep(): void {
        this.data.order.DeliveryAddressPostalCode =
            this.deliveryAddressForm.value.PostCode;
        this.createDialogService
            .openOrderTimeSlotsDialog(this.data.providerID, this.data.order)
            .afterClosed()
            .subscribe(
                (data) => {
                    if (data) {
                        const dto: OrderDeliveryAddressDTO = {
                            OrderCode: this.data.order.OrderCode,
                            PostCode: this.deliveryAddressForm.value.PostCode,
                            StreetName: this.deliveryAddressForm.value.StreetName,
                            HouseNumber: this.deliveryAddressForm.value.HouseNumber,
                        };

                        this.orderService.modifyOrderDeliveryAddress(dto).subscribe(
                            (data) => {
                                this.dialogRef.close(data);
                            },
                            () => {
                                this.dialogRef.close();
                            }
                        );
                    } else {
                        this.dialogRef.close();
                    }
                },
                () => {
                    this.dialogRef.close();
                }
            );
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
