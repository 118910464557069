<ng-container>
    <p class="ml-3 opacity-60 nutrition-text-size" *ngIf="!isProductNutritionXml">
        {{ productNutritionText }}
    </p>
    <table *ngIf="isProductNutritionXml" class="ml-3 nutrition-table nutrition-text-size">
        <tr>
            <th class="pe-1">{{ productNutrition.header.nameValue }}</th>
            <th class="px-1">{{ productNutrition.header.firstValue }}</th>
            <th class="px-1" *ngIf="productNutrition.header.secondValue">
                {{ productNutrition.header.secondValue }}
            </th>
            <th class="px-1" *ngIf="productNutrition.header.thirdValue">
                {{ productNutrition.header.thirdValue }}
            </th>
        </tr>
        <tr *ngFor="let nutritionElement of productNutrition.nutritionArray">
            <ng-container *ngIf="nutritionElement.name">
                <td
                    class="pe-1 pt-1"
                    [attr.colspan]="calculateColspanForNutrient(nutritionElement)"
                >
                    {{ nutritionElement.name }}
                </td>
                <td
                    class="px-1 pt-1"
                    *ngIf="calculateColspanForNutrient(nutritionElement) === 1"
                >
                    {{ nutritionElement.firstValue }}
                </td>
                <td
                    class="px-1 pt-1"
                    *ngIf="
                        calculateColspanForNutrient(nutritionElement) === 1 &&
                        productNutrition.header.secondValue
                    "
                >
                    {{ nutritionElement.secondValue }}
                </td>
                <td
                    class="px-1 pt-1"
                    *ngIf="
                        calculateColspanForNutrient(nutritionElement) === 1 &&
                        productNutrition.header.thirdValue
                    "
                >
                    {{ nutritionElement.thirdValue }}
                </td>
            </ng-container>
        </tr>
    </table>
</ng-container>
