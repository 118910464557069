/* eslint-disable @typescript-eslint/no-array-constructor */
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CountryCodes } from '../../types/country-codes';
import { ServiceBaseService } from '../service-base.service';

@Injectable({
    providedIn: 'root',
})
export class DateService {
    constructor(private translate: TranslateService) {}

    getReadableDate(dateStr: string): string {
        dateStr = dateStr.split('T')[0];
        const date = new Date(dateStr);

        return this.getReadableDateString(date);
    }

    getReadableDateString(date: Date) {
        const dayString = this.DayAsString(date.getDay() - 1);
        const monthString = this.MonthAsString(date.getMonth());
        const dayDigit = date.getDate();

        return ServiceBaseService.getCountryCode() == CountryCodes.at
            ? dayString + ', ' + dayDigit + '. ' + monthString
            : dayString + ' ' + monthString + ' ' + dayDigit + '.';
    }

    getDayString(date: Date): string {
        return this.DayAsString(date.getDay() - 1);
    }

    getDateString(date: Date): string {
        const monthString = this.MonthAsString(date.getMonth());
        const dayDigit = date.getDate();

        return ServiceBaseService.getCountryCode() == CountryCodes.at
            ? dayDigit + '. ' + monthString
            : monthString + ' ' + dayDigit + '.';
    }

    MonthAsString(monthIndex: number) {
        const month = new Array(12);
        month[0] = this.translate.instant('date.january');
        month[1] = this.translate.instant('date.february');
        month[2] = this.translate.instant('date.march');
        month[3] = this.translate.instant('date.april');
        month[4] = this.translate.instant('date.may');
        month[5] = this.translate.instant('date.june');
        month[6] = this.translate.instant('date.july');
        month[7] = this.translate.instant('date.august');
        month[8] = this.translate.instant('date.september');
        month[9] = this.translate.instant('date.october');
        month[10] = this.translate.instant('date.november');
        month[11] = this.translate.instant('date.december');

        return month[monthIndex];
    }

    DayAsString(dayIndex: number) {
        const weekdays = new Array(7);
        weekdays[-1] = this.translate.instant('date.sunday');
        weekdays[0] = this.translate.instant('date.monday');
        weekdays[1] = this.translate.instant('date.tuesday');
        weekdays[2] = this.translate.instant('date.wednesday');
        weekdays[3] = this.translate.instant('date.thursday');
        weekdays[4] = this.translate.instant('date.friday');
        weekdays[5] = this.translate.instant('date.saturday');
        weekdays[6] = this.translate.instant('date.sunday');

        return weekdays[dayIndex];
    }
}
