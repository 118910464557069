<div class="container position-relative w-100 h-100 pt-4">
    <img
        src="https://weshopsiteimagesmaster.azureedge.net/images/shared-img/close.svg"
        role="button"
        class="position-absolute top-0 end-0"
        (click)="back()"
        alt="close"
    />
    <div
        class="pt-3"
        *ngIf="
            loggedInUser && (!loggedInUser.ToSAccepted || !loggedInUser.PrivacyAccepted);
            else tabContainer
        "
    >
        <form
            class="w-100 container px-0 pt-5"
            [formGroup]="termsAcceptationForm"
            (ngSubmit)="onTermsAccept()"
        >
            <h4 class="m-2 text-center">
                {{ 'log-in.oops-you-havent-accepted-the-terms-title' | translate }}
            </h4>

            <div class="m-2">
                <label class="mt-2">
                    <input type="checkbox" formControlName="Aszf" />
                    {{ 'log-in.i-accept-the' | translate }}
                    <a
                        style="color: blue; text-decoration: underline"
                        href="{{ 'static-links.roksh-terms' | translate }}"
                        target="_blank"
                    >
                        {{ 'log-in.terms' | translate }}
                    </a>
                    <mat-error
                        *ngIf="
                            termsAcceptationForm.get('Aszf').errors?.required &&
                            submitTermsAcceptationForm
                        "
                    >
                        {{ 'log-in.terms-required-message' | translate }}
                    </mat-error>
                </label>
                <label class="mt-2">
                    <input type="checkbox" formControlName="Privacy" />
                    {{ 'log-in.i-accept-the-2' | translate }}
                    <a
                        style="color: blue; text-decoration: underline"
                        href="{{ 'static-links.data-protect' | translate }}"
                        target="_blank"
                    >
                        {{ 'log-in.data-protect' | translate }}
                    </a>
                    <mat-error
                        *ngIf="
                            termsAcceptationForm.get('Privacy').errors?.required &&
                            submitTermsAcceptationForm
                        "
                    >
                        {{ 'log-in.data-protect-required-message' | translate }}
                    </mat-error>
                </label>

                <label class="mt-2">
                    <input type="checkbox" formControlName="MarketingEmail" />
                    {{ 'log-in.i-accept-the-marketing-email' | translate }}
                </label>

                <label class="mt-2">
                    <input type="checkbox" formControlName="MarketingSMS" />
                    {{ 'log-in.i-accept-the-marketing-sms' | translate }}
                </label>
                <button
                    id="btnLDoLogin"
                    type="submit"
                    class="btn btn-success btn-rounded w-100 mb-3 mt-2"
                >
                    {{ 'common.accept' | translate }}
                </button>
            </div>
        </form>
    </div>

    <ng-template #tabContainer>
        <div class="tab-container" *ngIf="platform$ | async as platform">
            <mdb-tabs #tabsComponent>
                <mdb-tab title="{{ 'common.login' | translate }}">
                    <form class="px-3 pt-3" [formGroup]="loginForm">
                        <div class="w-100 page-1" *ngIf="currentRegistrationPage === 1">
                            <mat-form-field appearance="outline" class="field py-0 w-100">
                                <mat-label>{{ 'common.email' | translate }}</mat-label>
                                <input
                                    formControlName="email"
                                    matInput
                                    type="email"
                                    [placeholder]="'common.email' | translate"
                                    required
                                />
                                <mat-error
                                    *ngIf="
                                        loginForm.get('email').errors &&
                                        loginForm.get('email').touched
                                    "
                                >
                                    {{ 'log-in.wrong-email' | translate }}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="field py-0 w-100">
                                <mat-label>{{ 'common.password' | translate }}</mat-label>
                                <input
                                    formControlName="password"
                                    matInput
                                    #loginPassword
                                    type="password"
                                    placeholder="{{ 'common.password' | translate }}"
                                    required
                                />
                                <mat-icon
                                    [fontIcon]="
                                        loginPassword.type === 'text'
                                            ? 'visibility_off'
                                            : 'visibility'
                                    "
                                    matSuffix
                                    (click)="togglePasswordVisibility(loginPassword)"
                                >
                                    {{
                                        loginPassword.type === 'text'
                                            ? 'visibility_off'
                                            : 'visibility'
                                    }}
                                </mat-icon>
                                <mat-error
                                    *ngIf="
                                        loginForm.get('password').errors?.required &&
                                        loginForm.get('password').touched
                                    "
                                >
                                    {{ 'log-in.password-required' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <label>
                            <input
                                type="checkbox"
                                class="form-check-input"
                                [checked]="rememberMe"
                                (change)="handleChangeRememberMe($event.target)"
                            />
                            {{ 'password.save-password' | translate }}
                        </label>

                        <button
                            class="btn btn-outline-success btn-block text-center w-100 mt-3"
                            (click)="submitLoginForm()"
                        >
                            {{ 'common.login' | translate }}
                        </button>
                    </form>
                    <div class="px-3">
                        <p class="m0 mt-2 text-center text-black-50">
                            {{ 'common.or' | translate }}
                        </p>
                        <hr />
                        <ng-container *ngIf="isWrapper; else browserLogin">
                            <button
                                class="btn btn-block text-center w-100 mt-3 red"
                                (click)="onGoogleLoginOrRegistration()"
                            >
                                {{ 'log-in.log-in-with-google' | translate }}
                            </button>
                        </ng-container>
                        <ng-template #browserLogin>
                            <p class="color-red text-center" *ngIf="isPopupBlocked">
                                {{ 'login.pop-ups-blocked' | translate }}
                            </p>
                            <div
                                class="w-100 d-flex justify-content-center align-items-center py-3 google"
                            >
                                <asl-google-signin-button
                                    (click)="checkPopup()"
                                    type="standard"
                                    text="continue_with"
                                    logo_alignment="center"
                                    [locale]="locale"
                                    theme="filled_blue"
                                    width="300"
                                ></asl-google-signin-button>
                            </div>
                        </ng-template>
                        <button
                            [class.mb-4]="platform.result?.OS !== 'ios'"
                            class="btn btn-block text-center w-100 mt-3 blue"
                            (click)="onFacebookLoginOrRegistration()"
                        >
                            {{ 'log-in.log-in-with-facebook' | translate }}
                        </button>
                        <button
                            *ngIf="platform.result?.OS === 'ios'"
                            class="btn btn-block text-center w-100 mt-3 mb-4 apple"
                            (click)="onAppleLoginOrRegistration()"
                        >
                            {{ 'log-in.log-in-with-apple' | translate }}
                        </button>
                        <p
                            class="text-black-50 text-center pointer"
                            (click)="resendPassword()"
                        >
                            {{ 'log-in.did-you-forgot-password' | translate }}
                        </p>
                    </div>
                </mdb-tab>
                <mdb-tab title="{{ 'common.registration' | translate }}">
                    <div class="p-3">
                        <button
                            *ngIf="isWrapper; else browserLogin"
                            class="btn btn-block text-center w-100 mt-3 red"
                            (click)="onGoogleLoginOrRegistration()"
                        >
                            {{ 'log-in.register-with-google' | translate }}
                        </button>
                        <ng-template #browserLogin>
                            <p class="color-red text-center" *ngIf="isPopupBlocked">
                                {{ 'login.pop-ups-blocked' | translate }}
                            </p>
                            <div
                                class="w-100 d-flex justify-content-center align-items-center py-3 google"
                            >
                                <asl-google-signin-button
                                    (click)="checkPopup()"
                                    type="standard"
                                    text="signup_with"
                                    logo_alignment="center"
                                    [locale]="locale"
                                    theme="filled_blue"
                                    width="300"
                                ></asl-google-signin-button>
                            </div>
                        </ng-template>
                        <button
                            [class.mb-4]="
                                platform.result?.OS !== 'ios' && registerWithEmail
                            "
                            class="btn btn-block text-center w-100 mt-3 blue"
                            (click)="onFacebookLoginOrRegistration()"
                        >
                            {{ 'log-in.register-with-facebook' | translate }}
                        </button>
                        <button
                            *ngIf="!registerWithEmail"
                            class="btn btn-block text-center w-100 mt-3 registration-btn"
                            (click)="showRegistrationForm()"
                        >
                            {{ 'log-in.register-with-email' | translate }}
                        </button>
                        <button
                            *ngIf="platform.result?.OS === 'ios'"
                            class="btn btn-block text-center w-100 mt-3 apple"
                            (click)="onAppleLoginOrRegistration()"
                        >
                            {{ 'log-in.register-with-apple' | translate }}
                        </button>
                        <form
                            *ngIf="registerWithEmail"
                            [formGroup]="registrationForm"
                            (ngSubmit)="submit()"
                            class="position-relative"
                        >
                            <mat-form-field appearance="outline" class="field py-0 w-100">
                                <mat-label>{{ 'common.email' | translate }}</mat-label>
                                <input
                                    formControlName="email"
                                    matInput
                                    type="email"
                                    required
                                />
                                <mat-error
                                    *ngIf="
                                        registrationForm.get('email').errors &&
                                        registrationForm.get('email').touched
                                    "
                                >
                                    {{ 'log-in.wrong-email' | translate }}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="field py-0 w-100">
                                <mat-label>{{
                                    'common.firstname' | translate
                                }}</mat-label>
                                <input
                                    formControlName="firstName"
                                    matInput
                                    type="text"
                                    required
                                />
                                <mat-error
                                    *ngIf="
                                        registrationForm.get('firstName').errors &&
                                        registrationForm.get('firstName').touched
                                    "
                                >
                                    {{ 'log-in.firstname-required' | translate }}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="field py-0 w-100">
                                <mat-label>{{ 'common.lastname' | translate }}</mat-label>
                                <input
                                    formControlName="lastName"
                                    matInput
                                    type="text"
                                    required
                                />
                                <mat-error
                                    *ngIf="
                                        registrationForm.get('lastName').errors &&
                                        registrationForm.get('lastName').touched
                                    "
                                >
                                    {{ 'log-in.lastname-required' | translate }}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="field py-0 w-100">
                                <mat-label>{{
                                    'common.birthdate' | translate
                                }}</mat-label>
                                <input
                                    formControlName="birthDate"
                                    matInput
                                    [matDatepicker]="picker"
                                    readonly
                                    (focus)="picker.open()"
                                    (click)="picker.open()"
                                    [max]="maxBirthdate"
                                />
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-error
                                    *ngIf="
                                        registrationForm.get('birthDate').errors
                                            ?.required &&
                                        registrationForm.get('birthDate').touched
                                    "
                                >
                                    {{ 'log-in.birthdate-required' | translate }}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field
                                appearance="outline"
                                class="field py-0 w-100"
                                [class.mb-3]="
                                    registrationForm.get('password').errors?.pattern ||
                                    registrationForm.get('password').errors?.minlength
                                "
                            >
                                <mat-label>{{ 'common.password' | translate }}</mat-label>
                                <input
                                    formControlName="password"
                                    matInput
                                    #password
                                    type="password"
                                    required
                                />
                                <mat-icon
                                    [fontIcon]="
                                        password.type === 'text'
                                            ? 'visibility_off'
                                            : 'visibility'
                                    "
                                    class="text-black"
                                    matSuffix
                                    (click)="togglePasswordVisibility(password)"
                                    >{{
                                        password.type === 'text'
                                            ? 'visibility_off'
                                            : 'visibility'
                                    }}
                                </mat-icon>
                                <mat-error
                                    *ngIf="
                                        registrationForm.get('password').errors
                                            ?.required &&
                                        registrationForm.get('password').touched
                                    "
                                >
                                    {{ 'log-in.password-required' | translate }}
                                </mat-error>

                                <mat-error
                                    class="mb-2"
                                    *ngIf="
                                        registrationForm.get('password').errors
                                            ?.pattern ||
                                        registrationForm.get('password').errors?.minlength
                                    "
                                >
                                    {{ 'log-in.password-policiy-message' | translate }}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="field py-0 w-100">
                                <mat-label>{{
                                    'common.confirm-password' | translate
                                }}</mat-label>
                                <input
                                    formControlName="confirmPassword"
                                    matInput
                                    #confirmPassword
                                    type="password"
                                    required
                                />
                                <mat-icon
                                    [fontIcon]="
                                        confirmPassword.type === 'text'
                                            ? 'visibility_off'
                                            : 'visibility'
                                    "
                                    matSuffix
                                    (click)="togglePasswordVisibility(confirmPassword)"
                                    >{{
                                        confirmPassword.type === 'text'
                                            ? 'visibility_off'
                                            : 'visibility'
                                    }}
                                </mat-icon>
                                <mat-error
                                    *ngIf="
                                        registrationForm.get('confirmPassword').errors
                                            ?.validateNotEqual
                                    "
                                >
                                    {{
                                        'application.confirm-password-not-match'
                                            | translate
                                    }}
                                </mat-error>
                            </mat-form-field>

                            <label class="mt-2 mb-4">
                                <input
                                    type="checkbox"
                                    formControlName="aszf"
                                    class="form-check-input me-3"
                                />
                                {{ 'log-in.i-accept-the' | translate }}
                                <a
                                    (click)="handlePostMessageOpenUrl($event)"
                                    href="{{ 'static-links.roksh-terms' | translate }}"
                                    target="_blank"
                                >
                                    {{ 'log-in.terms' | translate }}
                                </a>
                                <mat-error
                                    *ngIf="
                                        registrationForm.get('aszf').errors?.required &&
                                        submitRegisterForm
                                    "
                                >
                                    {{ 'log-in.terms-required-message' | translate }}
                                </mat-error>
                            </label>
                            <label class="mb-4">
                                <input
                                    type="checkbox"
                                    formControlName="privacy"
                                    class="form-check-input me-3"
                                />
                                {{ 'log-in.i-accept-the-2' | translate }}
                                <a
                                    (click)="handlePostMessageOpenUrl($event)"
                                    href="{{ 'static-links.data-protect' | translate }}"
                                    target="_blank"
                                >
                                    {{ 'log-in.data-protect' | translate }}
                                </a>
                                {{ 'log-in.i-accept-the-dp-3' | translate }}
                                <mat-error
                                    *ngIf="
                                        registrationForm.get('privacy').errors
                                            ?.required && submitRegisterForm
                                    "
                                >
                                    {{
                                        'log-in.data-protect-required-message' | translate
                                    }}
                                </mat-error>
                            </label>

                            <label class="mb-4 d-flex">
                                <input
                                    type="checkbox"
                                    formControlName="marketingEmail"
                                    class="form-check-input me-3"
                                />
                                {{ 'log-in.i-accept-the-marketing-email' | translate }}
                            </label>

                            <label class="mb-4 d-flex">
                                <input
                                    type="checkbox"
                                    formControlName="marketingSMS"
                                    class="form-check-input me-3"
                                />
                                {{ 'log-in.i-accept-the-marketing-sms' | translate }}
                            </label>

                            <button
                                class="btn btn-block text-center btn-outline-success w-100 my-1 orange"
                                type="submit"
                            >
                                {{ 'common.registration' | translate }}
                            </button>

                            <button
                                class="btn btn-outline-dark btn-block text-center w-100 mt-3 mb-5"
                                (click)="closeRegistrationForm()"
                            >
                                {{ 'log-in.other-registration-method' | translate }}
                            </button>
                        </form>
                    </div>
                </mdb-tab>
            </mdb-tabs>
        </div>
    </ng-template>
</div>
