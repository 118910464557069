<div
    class="basket-fade"
    (click)="onToggleMenu()"
    [ngClass]="{ 'menu-open': menuOpen, 'basket-wrapper-empty': isBasketEmpty }"
></div>
<div
    *ngIf="!isCheckoutScreen"
    class="basket-container d-inline-block h-100"
    [ngClass]="{
        'menu-open': menuOpen,
        'basket-wrapper-empty': isBasketEmpty,
        'basket-container-hide': !menuOpen
    }"
>
    <lib-basket *ngIf="!isBasketSelectorActive"></lib-basket>
    <app-basket-provider-selector
        (backToShopping)="onToggleMenu()"
        *ngIf="isBasketSelectorActive"
    >
    </app-basket-provider-selector>
</div>
