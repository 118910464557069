import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BasketService } from '../basket.service';
import { DataLayerProduct } from '../../models/data-layer/data-layer-product.model';
import { RedirectProductModel } from '../../models/data-layer/redirect-product.model';
import { take } from 'rxjs/operators';
import { AdditionalShopInfo } from '../../models/basket/additional-shop-info.model';
import { AddressDto } from '../dto/address/address.dto';

export enum GA4EventType {
    add_to_cart = 'add_to_cart',
    add_address = 'add_address',
    remove_from_cart = 'remove_from_cart',
    view_item = 'view_item',
    view_cart = 'view_cart',
    begin_checkout = 'begin_checkout',
    personal_info = 'personal_info',
    delivery_time_picked = 'delivery_time_picked',
    delivery_time_finished = 'delivery_time_finished',
    payment_info = 'payment_info',
    start_order = 'start_order',
    purchase = 'purchase',
    register = 'register',
    login = 'login',
}

export enum GA4Category {
    authentication = 'authentication',
    registration = 'registration',
    startOrder = 'StartOrder',
    Checkout2 = 'Checkout2',
}

@Injectable({ providedIn: 'root' })
export class DataLayerGa4Service {
    constructor(
        private translate: TranslateService,
        private basketService: BasketService
    ) {
        window['dataLayer'] = window['dataLayer'] || {};
    }

    eCommerceItem(basketItem: any, event: GA4EventType) {
        window['dataLayer'] = window['dataLayer'] || [];
        this.translate.get('common.currency-code').subscribe((text) =>
            window['dataLayer'].push({
                event: event.toString(),
                ecommerce: {
                    currency: text,
                    items: [
                        {
                            item_id: basketItem.productID ?? basketItem.ProductID,
                            item_name: basketItem.productName ?? basketItem.ProductName,
                            item_category:
                                basketItem.category ?? basketItem.Category?.CategoryName,
                            item_brand: basketItem.brand ?? basketItem.Brand,
                            price: basketItem.unitPrice ?? basketItem.MinUnitPrice,
                            quantity: basketItem.quantity ?? 1,
                        },
                    ],
                },
            })
        );

        window['dataLayer'] = window['dataLayer'] || {};
    }

    eCommerceBasket(basketItems: any[], event: GA4EventType) {
        window['dataLayer'] = window['dataLayer'] || [];
        this.translate.get('common.currency-code').subscribe((text) =>
            window['dataLayer'].push({
                event: event.toString(),
                ecommerce: {
                    currency: text,
                    value: basketItems.reduce(
                        (sum, currentItem) =>
                            (sum += currentItem.unitPrice * currentItem.unitValue),
                        0
                    ),
                    items: basketItems.map((item) => {
                        return {
                            item_id: item.productID ?? item.ProductID,
                            item_name: item.productName ?? item.ProductName,
                            item_category: item.progID ?? item.ProgID,
                            item_brand: item.brand ?? item.Brand,
                            price: item.unitPrice ?? item.MinUnitPrice,
                            quantity: item.unitValue ?? 1,
                            event: event,
                        };
                    }),
                },
            })
        );

        window['dataLayer'] = window['dataLayer'] || {};
    }

    datalayerUniversalPush(category = '', action = '', label = '', value = '') {
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({
            event: 'gtm.click',
            'gtm.element.dataset.category': category,
            'gtm.element.dataset.action': action,
            'gtm.element.dataset.label': label,
            'gtm.element.dataset.value': value,
        });
    }

    sendDataLayer(
        redirectionJobId: string,
        isConnected: boolean,
        providerCode: string,
        additionalShopInfo: AdditionalShopInfo
    ) {
        const { totalPrice, providerID } = additionalShopInfo;

        this.basketService.masterBasketSubject.pipe(take(1)).subscribe((masterBasket) => {
            if (masterBasket) {
                const provBasketAlt = masterBasket.basketList.find(
                    (x) => x.providerID == providerID
                );

                const prodArr: DataLayerProduct[] = provBasketAlt.basketItemList.map(
                    (basketItem) => this.createProductForDataLayer(basketItem)
                );

                const redirectProductModel: RedirectProductModel = {
                    isConnected,
                    redirectionJobId,
                    providerCode,
                    totalPrice,
                    prodArr,
                };
                this.purchaseProducts(redirectProductModel);
            }
        });
    }

    purchaseProducts(redirectModel: RedirectProductModel) {
        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({
            event: GA4EventType.purchase.toString(),
            ecommerce: {
                transaction_id: redirectModel.redirectionJobId,
                currency: this.translate.instant('common.currency-code'),
                value: redirectModel.prodArr.reduce(
                    (sum, currentItem) => (sum += currentItem.price),
                    0
                ),
                items: redirectModel.prodArr.map((item) => {
                    return {
                        item_id: item.id,
                        item_name: item.name,
                        item_category: item.category,
                        item_brand: item.brand,
                        price: item.price / item.quantity,
                        quantity: item.quantity ?? 1,
                    };
                }),
            },
        });
    }

    private createProductForDataLayer(basketItem: any): DataLayerProduct {
        const prc = basketItem.unitPrice;
        return {
            name: basketItem.productName,
            id: basketItem.productID,
            category: basketItem.progID,
            brand: basketItem.brand,
            unitPrice: prc,
            quantity: basketItem.unitValue,
            price: basketItem.price,
        };
    }

    addAddress(addressDto: AddressDto): void {
        window['dataLayer'].push({
            event: GA4EventType.add_address,
            address: addressDto,
        });
    }
}
