import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HtmlMarketingElementDto, MarketingClient } from '../..';
import { switchMap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { HtmlMarketingElementsExtendsDto } from '../dto/html-marketing-elements/html-marketing-elements-extends.dto';

export enum ContainerIds {
    productDetails = 'ProductDetails',
    basket = 'Basket',
    leftSideMenu = 'LeftSideMenu',
    landingTileDesktop = 'LandingTileDesktop',
    landingTileMobile = 'LandingTileMobile',
    landingPageDesktop = 'LandingPageDesktop',
    landingPageMobile = 'LandingPageMobile',
}

@Injectable({
    providedIn: 'root',
})
export class MarketingService {
    constructor(
        private marketingClient: MarketingClient,
        private sanitizer: DomSanitizer
    ) {}

    getHtmlMarketingElements(
        containerId: string
    ): Observable<HtmlMarketingElementsExtendsDto | null> {
        return this.marketingClient.getHtmlMarketingElements([containerId]).pipe(
            switchMap((htmlMarketingElementDto) => {
                return of(this.filteredHtmlMarketingElements(htmlMarketingElementDto));
            })
        );
    }

    filteredHtmlMarketingElements(
        htmlMarketingElementDto: HtmlMarketingElementDto[]
    ): HtmlMarketingElementsExtendsDto | null {
        const htmlMarketingElementDtoWithSafeHtml: HtmlMarketingElementsExtendsDto[] =
            htmlMarketingElementDto?.map((item) => {
                item.code = item.code || item['Code'];
                item.cssFormats = item.cssFormats || item['CssFormats'];
                item.containerBreakpointStart =
                    item.containerBreakpointStart ||
                    item['ContainerBreakpointStart'] ||
                    0;
                item.containerBreakpointEnd =
                    item.containerBreakpointEnd || item['ContainerBreakpointEnd'] || 0;
                item['safeHtml'] = this.sanitizer.bypassSecurityTrustHtml(item.code);
                return item as unknown as HtmlMarketingElementsExtendsDto;
            });

        const filteredItems = htmlMarketingElementDtoWithSafeHtml?.filter((item) => {
            return (
                window.innerWidth >= item.containerBreakpointStart &&
                window.innerWidth <= item.containerBreakpointEnd
            );
        });
        return filteredItems?.length > 0 ? filteredItems[0] : null;
    }
}
