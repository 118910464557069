<div
    class="category-card provider-categorycard d-inline-block px-2"
    [class.subCategory]="isSubCategory"
    [class.mainCategory]="!isSubCategory"
    [class.smallCard]="smallCard"
>
    <a [title]="category.CategoryName" [routerLink]="category.Url">
        <div class="card-container">
            <img
                class="mt-4 mb-2"
                [alt]="category.CategoryName"
                [src]="imgUrl"
                [ngClass]="{ 'no-filter': !category.CategoryID }"
            />
            <div *ngIf="!smallCard" class="title px-2 p-1">
                {{ category.CategoryName }}
            </div>
            <span *ngIf="smallCard" class="small-card-title p-2">{{
                category.CategoryName
            }}</span>
        </div>
    </a>
</div>
