import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
    DataLayerGa4Service,
    IMyOrder,
    SearchService,
    ServiceBaseService,
    TypeAheadResult,
} from 'projects/library-shared/src/public-api';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map, mergeMap } from 'rxjs/operators';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { AddOrUpdateOrderItemDialogComponent } from '../add-or-update-order-item/add-or-update-order-item.component';

@Component({
    templateUrl: './add-item-to-order.component.html',
    styleUrls: ['./add-item-to-order.component.scss'],
})
export class AddItemToOrderDialogComponent implements OnInit {
    faTimesCircle = faTimesCircle;

    faSearch = faSearch;

    searchText = '';
    typeAheadResults: Observable<TypeAheadResult[]>;
    mediaUrl = '';

    order: IMyOrder;

    constructor(
        public dialogRef: MatDialogRef<AddItemToOrderDialogComponent>,
        private searchService: SearchService,
        private router: Router,
        private DataLayerGa4Service: DataLayerGa4Service,
        private ref: ChangeDetectorRef,
        public baseService: ServiceBaseService,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public input: any
    ) {
        this.mediaUrl = this.baseService.getMediaServiceUrl();

        this.order = JSON.parse(JSON.stringify(input)).order;
    }

    ngOnInit() {
        this.refreshSearch();
    }

    searchClick() {
        if (this.searchText.length < 3) {
            return;
        }

        this.refreshSearch();
    }

    refreshSearch() {
        this.typeAheadResults = new Observable((observer: any) => {
            // Runs on every search
            observer.next(this.searchText);
        }).pipe(
            mergeMap((token: string) => {
                return this.searchService.typeAhead(token, true, this.order.ProviderID);
            }),
            map((x) => {
                this.DataLayerGa4Service.datalayerUniversalPush(
                    'Functions',
                    'Search',
                    this.searchText
                );
                // Please don't remove magic....
                setTimeout(() => {
                    this.ref.detectChanges();
                }, 100);
                return x;
            })
        );
    }

    public typeaheadOnSelect(event) {
        const selected: TypeAheadResult = event.item;
        this.searchService.setSearchPhrase(selected.ProductName);

        this.dialog
            .open(AddOrUpdateOrderItemDialogComponent, {
                maxWidth: '60vw',
                minWidth: '150px',
                maxHeight: '85vh',
                position: { top: '10vh' },
                data: { typeAheadResult: selected, order: this.order },
            })
            .afterClosed()
            .subscribe((data) => this.dialogRef.close(data));
    }
}
