import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BasketService } from '../../../services/basket.service';
import { SessionService } from '../../../services/session/session.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-discount-info-dialog',
    templateUrl: './discount-info-dialog.component.html',
    styleUrls: ['./discount-info-dialog.component.scss'],
})
export class DiscountInfoDialogComponent {
    currentRokshDiscount: number;
    providerCode: string;
    @Input() closeEmitter: () => void;

    constructor(
        private basketService: BasketService,
        private translate: TranslateService,
        private router: Router,
        private sessionService: SessionService
    ) {
        this.currentRokshDiscount =
            this.basketService.currentRokshDiscountSubject.getValue();
        this.providerCode =
            this.sessionService.sessionSubject.getValue().selectedShopCodes[0];
    }

    close(redirect: boolean): void {
        if (!redirect) {
            this.closeEmitter();
            return;
        }

        let url = '';
        if (!this.sessionService.isMobile) {
            const offer = this.translate.instant('router.offer');
            const categoryBlock = this.translate.instant('router.category-block');
            url = `/${this.providerCode}/${offer}/${categoryBlock}/marketing-block`;
        } else {
            url = `/shop/${this.providerCode}/kinalat/kategoria-blokk/marketing-block`;
        }

        this.closeEmitter();
        void this.router.navigate([url]);
    }
}
