import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from '../auth/auth.service';
import { map } from 'rxjs/operators';
import {
    BasketItemDTO,
    FavoriteDto,
    FavoriteItemDto,
    FavouriteProductClient,
} from '../index';

@Injectable({ providedIn: 'root' })
export class FavoriteListService {
    private favoriteProductListSubject = new BehaviorSubject<FavoriteItemDto[]>(null);
    favoriteProductListObservable = this.favoriteProductListSubject.asObservable();
    private favoriteListSubject = new BehaviorSubject<FavoriteDto[]>(null);
    favoriteListObservable = this.favoriteListSubject.asObservable();

    constructor(
        private authService: AuthenticationService,
        private favouriteProductClient: FavouriteProductClient
    ) {
        this.authService.getAuthStatusListener().subscribe((loggedIn) => {
            if (loggedIn) {
                this.getFavoriteProductList(null).subscribe();
            } else {
                this.cleanFavoriteProductList();
            }
        });
    }

    getFavoriteProductList(providerId: number | null): Observable<FavoriteDto[]> {
        return this.favouriteProductClient.getFavoriteProductList(providerId).pipe(
            map((data) => {
                this.favoriteListSubject.next(data);

                if (providerId) {
                    const favouriteByProvider = data.find(
                        (x) => x.providerID === providerId
                    );

                    if (favouriteByProvider?.favoriteItemDtos)
                        this.favoriteProductListSubject.next(
                            favouriteByProvider.favoriteItemDtos
                        );
                } else {
                    let favoriteItems = [];
                    data.forEach((favorites) => {
                        favoriteItems = [...favoriteItems, ...favorites.favoriteItemDtos];
                    });

                    this.favoriteProductListSubject.next(favoriteItems);
                }
                return data;
            })
        );
    }

    private cleanFavoriteProductList() {
        this.favoriteProductListSubject.next(null);
    }

    addProductToFavorite(productId: number): Observable<any> {
        const basketItem: BasketItemDTO = new BasketItemDTO();
        basketItem.originalProductID = productId;

        return this.favouriteProductClient.addProductToFavorite(basketItem).pipe(
            map((data) => {
                this.favoriteProductListSubject.next(data[0].favoriteItemDtos);
                return data;
            })
        );
    }

    private removeItemFromFavoriteList(productId: number) {
        let basketId = null;
        let favoriteList = this.favoriteProductListSubject.getValue();
        if (favoriteList) {
            favoriteList = favoriteList.filter((data) => {
                if (data.originalProduct.productID === productId) {
                    basketId = data;
                    return false;
                }
                return true;
            });
            this.favoriteProductListSubject.next(favoriteList);
        }
        return basketId;
    }

    removeProductFromFavorite(productId: number) {
        const favoriteItemDto: FavoriteItemDto =
            this.removeItemFromFavoriteList(productId);
        return this.favouriteProductClient.removeProductFromFavorite(
            favoriteItemDto.basketItemId
        );
    }
}
