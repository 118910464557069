import { InjectionToken } from '@angular/core';

export const HOME_SERVICE_IMPL = new InjectionToken<ISharedHomeService>(
    'homeServiceImpl'
);

export interface ISharedHomeService {
    setFooterDisplayed(displayed: boolean): void;
    setHeaderDisplayed(displayed: boolean): void;
}
