export const GOOGLE_AUTH = 'google-auth';
export const FACEBOOK_AUTH = 'facebook-auth';
export const APPLE_SIGN_IN = 'apple-sign-in';
export const GET_BUILD_NUMBER = 'getBuildNumber';
export const GET_SYSTEM_NAME = 'getSystemName';
export const GET_VERSION = 'getVersion';
export const SYSTEM_VERSION = 'getSystemVersion';
export const HASH_NOTCH = 'hasNotch';
export const HEADER_COLOR = 'headerColor';
export const FOOTER_COLOR = 'footerColor';
export const GET_PLATFORM = 'getPlatform';
export const CURRENT_URL = 'currentUrl';
export const ORIGIN = 'origin';
export const CLOSE_APP = 'closeApp';
export const KEYBOARD_STATUS = 'keyboardStatus';
export const OPEN_URL = 'openUrl';
export const HARDWARE_BACK_PRESS = 'hardwareBackPress';
export const REQUEST_NOTIFICATION_USER_PERMISSION = 'requestNotificationUserPermission';
export const GET_FCM_APNS_TOKEN = 'getFCMAPNSToken';
export const GET_FCM_TOKEN = 'getFCMToken';
export const GEOLOCATION = 'geolocation';
export const FOREGROUND_MESSAGE = 'foregroundMessage';
export const COPY_TO_CLIPBOARD = 'copyToClipboard';
export const GOOGLE_PAY = 'googlePay';
export const ONE_SIGNAL_SET_EMAIL = 'OneSignalSetEmail';
export const ONE_SIGNAL_SET_TAG = 'OneSignalSetTag';
export const OPEN_SENDBIRD_CHAT = 'openSendBirdChat';
export const CLOSE_SENDBIRD_CHAT = 'closeSendBirdChat';
