import { ServiceBaseService } from 'projects/library-shared/src/public-api';

export const environment = {
    production: true,
    apiUrl: 'https://localhost:5001/',
    facebookClientId: '516711728807278',
    googleClientId:
        '569587751630-i8lk28u43e277h7dittrqovf72q8k78q.apps.googleusercontent.com',
    digimarketerid: '94744893-3ade-4cb5-9fd8-579e03f9fd1e',
    loggedEnvironments: ['localhost', 'master', 'staging', 'production'],
    API_BASE_URL: ServiceBaseService.getStaticBaseUrl(),
    GOOGLE_API_KEY: 'AIzaSyBcIFCMxEax202tsFH5wJ-g51JpqNUdYhc',
    SENTRY_AUTH_TOKEN: 'bb2e6bec090b49fc82a671de0ffe32d76bc3e6e441bc433585a43477d4c06c29',
};
