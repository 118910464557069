<div class="row justify-content-center">
    <a
        class="mx-4 mb-2 p-2 link"
        [cdkCopyToClipboard]="guidFullLink"
        [cdkCopyToClipboardAttempts]="5"
        (click)="onCopy()"
    >
        {{ guidFullLink }}
    </a>

    <button
        mat-stroked-button
        class="btn"
        style="
            background-color: #008091;
            color: #ffffff;
            white-space: nowrap;
            max-height: 40px;
            align-self: center;
        "
        mat-button
        [cdkCopyToClipboard]="guidFullLink"
        [cdkCopyToClipboardAttempts]="5"
        (click)="onCopy()"
    >
        {{ 'common.copy-link' | translate }}
    </button>
</div>
