import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    BasketService,
    MasterBasketDto,
    SessionService,
} from 'projects/library-shared/src/public-api';
import { Subscription } from 'rxjs';
import { Event, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from '../../../services/home/home.service';

@Component({
    selector: 'app-basket-new-wrapper',
    templateUrl: './basket-new-wrapper.component.html',
    styleUrls: ['./basket-new-wrapper.component.scss'],
})
export class BasketNewWrapperComponent implements OnInit, OnDestroy {
    menuOpen = false;
    openMenuSubjectSub: Subscription;
    isBasketEmpty = true;

    isCheckoutScreen = false;

    isBasketSelectorActive = false;

    constructor(
        public basketService: BasketService,
        private router: Router,
        private sessionService: SessionService,
        private homeService: HomeService,
        private translate: TranslateService
    ) {
        router.events.subscribe(() => {
            this.translate.get('router.cassa').subscribe((text) => {
                if (window.location.pathname.split('/')[1] == text)
                    this.isCheckoutScreen = true;
                else this.isCheckoutScreen = false;
            });
        });
    }

    ngOnInit() {
        this.openMenuSubjectSub = this.basketService.openMenuSubject.subscribe(
            (menuOpen) => {
                this.menuOpen = menuOpen;
                if (this.menuOpen) {
                    this.openBasketAndScroll();
                } else {
                    this.closeBasketAndScroll();
                }
            }
        );

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                this.isBasketSelectorActive =
                    event.url === '/' ||
                    event.url.includes('selectShop') ||
                    (event.url.includes('felhasznalo') &&
                        !event.url.includes('providerName') &&
                        !event.url.includes('providerId') &&
                        !event.url.includes('orderCode')) ||
                    (event.url.includes('benutzer') &&
                        !event.url.includes('providerName') &&
                        !event.url.includes('providerId') &&
                        !event.url.includes('orderCode'));
            }
        });

        // if the selected shop does not have any product left, we always close the basket
        this.homeService.isSessionSet.subscribe((isSessionSet) => {
            if (isSessionSet) {
                this.sessionService.sessionSubject.subscribe((session) => {
                    this.basketService.masterBasketSubject.subscribe((masterBasket) => {
                        if (session && masterBasket) {
                            const selectedShop =
                                this.sessionService.sessionSubject.getValue()
                                    .selectedShops[0];
                            this.isBasketEmpty = this.calculateisBasketEmpty(
                                masterBasket,
                                selectedShop
                            );
                        }
                    });
                });
            }
        });
    }

    private closeBasketAndScroll() {
        document
            .getElementsByTagName('html')[0]
            .classList.remove('cdk-global-scrollblock');
        const el = document.querySelector('html');
        const scrollY = el.style['top'];
        el.style['top'] = '';
        el.style['left'] = '';
        if (scrollY) {
            window.scrollTo(0, parseInt(scrollY || '0', 0) * -1);
        }
    }

    private openBasketAndScroll() {
        const el = document.querySelector('html');
        const scrollY = window.scrollY;
        el.style['top'] = `-${scrollY}px`;
        const basketContainer: HTMLDivElement = document.querySelector(
            '#side-basket-container'
        );
        basketContainer.style['top'] = `${scrollY + 120}px`;

        el.style['left'] = '0';
        document.getElementsByTagName('html')[0].classList.add('cdk-global-scrollblock');
    }

    private calculateisBasketEmpty(
        masterBasket: MasterBasketDto,
        providername: string
    ): boolean {
        const selectedBasketItemList = masterBasket.basketList.find(
            (b) => b.providerName.toLocaleLowerCase() === providername.toLocaleLowerCase()
        )?.basketItemList;

        return (
            !masterBasket ||
            masterBasket.basketList.length === 0 ||
            selectedBasketItemList?.length === 0
        );
    }

    onToggleMenu() {
        this.basketService.openMenuSubject.next(!this.menuOpen);
    }

    onHideBasket() {
        this.basketService.openMenuSubject.next(!this.menuOpen);
    }

    ngOnDestroy() {
        if (this.openMenuSubjectSub) {
            this.openMenuSubjectSub.unsubscribe();
        }
    }
}
