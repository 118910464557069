<div
    *ngIf="!isPostCodeSetted; else afterSettedPostCode"
    class="landing first"
    id="landing-first"
>
    <div class="container mt-5">
        <!-- content here -->

        <div *ngIf="!isPostCodeSetted" class="row">
            <div class="col-12 col-lg-6 text-left">
                <div *ngIf="isSessionSet && !isPostCodeSetted">
                    <br />

                    <h1 class="mt-5 mb-3 mobile-description text-size-42 w-100 text-left">
                        {{ 'dashboard.landing-message' | translate }}
                    </h1>

                    <h2 class="mobile-description text-size-42 w-100 text-left mt-3">
                        {{ 'dashboard.welcome-message-2' | translate }}
                    </h2>

                    <div class="address-title mb-4">
                        {{ 'messages.add-postcode-and-select-shop' | translate }}
                    </div>

                    <div class="app-address-button-container">
                        <app-add-address-button></app-add-address-button>
                    </div>

                    <div class="clearfix"></div>
                </div>
            </div>
            <div class="col-12 col-lg-6 text-right">
                <img
                    class="mt-5 align-self-start img-landing"
                    src="https://weshopsiteimages.blob.core.windows.net/images/infolanding.png"
                />
            </div>
        </div>
    </div>
</div>
<div *ngIf="!isPostCodeSetted">
    <app-information-block></app-information-block>
</div>

<ng-template #afterSettedPostCode>
    <ng-container *ngIf="!isViewInited">
        {{ scrollToShopSelect() }}
    </ng-container>
    <div class="welcome" id="welcome">
        <div class="container mt-5">
            <div class="welcome-flex w-100 main-container d-md-block d-md-flex">
                <div
                    class="text-left w-100 justify-content-between pt-0 first-block d-inline-block"
                    style="max-width: 70%"
                >
                    <div>
                        <br />

                        <h1 class="mt-5 pt-lg-5 mb-3 mx-0 welcome-message">
                            {{ 'dashboard.landing-message' | translate }}
                        </h1>

                        <br />

                        <div class="d-none d-md-block welcome-details mb-4">
                            <div class="block-element link col col-lx-3">
                                <div class="row ms-0 align-items-center">
                                    <div class="icon rounded-circle">
                                        <img
                                            src="/assets/svg/rokshImages/icons/arrow-link.svg"
                                        />
                                    </div>
                                    <div class="col information-text">
                                        <a
                                            class="information-text"
                                            href="{{
                                                'static-links.roksh-informations'
                                                    | translate
                                            }}"
                                            target="_blank"
                                        >
                                            {{ 'info.info-link' | translate }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="clearfix"></div>
                    </div>
                </div>

                <img
                    class="img-roksh-basket mt-5"
                    src="https://weshopsiteimages.blob.core.windows.net/images/landing2.png"
                />
            </div>
        </div>
    </div>
</ng-template>
