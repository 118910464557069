import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
    DataLayerGa4Service,
    SearchService,
    ServiceBaseService,
    SessionService,
    TypeAheadResult,
} from 'projects/library-shared/src/public-api';
import { forkJoin, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map, mergeMap } from 'rxjs/operators';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
    faSearch = faSearch;

    searchText = '';
    typeAheadResults: Observable<TypeAheadResult[]>;
    mediaUrl = '';

    constructor(
        private searchService: SearchService,
        private router: Router,
        private DataLayerGa4Service: DataLayerGa4Service,
        private ref: ChangeDetectorRef,
        public baseService: ServiceBaseService,
        private translate: TranslateService,
        private sessionService: SessionService
    ) {
        this.mediaUrl = this.baseService.getMediaServiceUrl();
    }

    ngOnInit() {
        this.typeAheadResults = new Observable((observer: any) => {
            // Runs on every search
            observer.next(this.searchText);
        }).pipe(
            mergeMap((token: string) => {
                return this.searchService.typeAhead(token);
            }),
            map((x) => {
                this.DataLayerGa4Service.datalayerUniversalPush(
                    'Functions',
                    'Search',
                    this.searchText
                );
                // Please don't remove magic....
                setTimeout(() => {
                    this.ref.detectChanges();
                }, 100);
                return x;
            })
        );
    }

    searchClick() {
        if (this.searchText.length < 3) {
            return;
        }
        this.startSearch(this.searchText);
    }

    private startSearch(searchTerm: string) {
        this.searchService.setSearchPhrase(this.searchText);
        const provider = this.sessionService.sessionSubject
            .getValue()
            .selectedShops[0].toLowerCase();

        forkJoin([
            this.translate.get('router.offer'),
            this.translate.get('router.search'),
        ]).subscribe(([offer, search]: [string, string]) => {
            const navUrl =
                provider +
                '/' +
                offer +
                '/' +
                search +
                '/' +
                encodeURIComponent(searchTerm);
            this.router
                .navigate([navUrl], {
                    queryParams: {
                        s: encodeURIComponent(searchTerm),
                    },
                })
                .then(() => {
                    this.searchText = '';
                });
        });
    }

    public typeaheadOnSelect(event) {
        const selected: TypeAheadResult = event.item;
        this.searchService.setSearchPhrase(selected.ProductName);
        forkJoin([
            this.translate.get('router.offer'),
            this.translate.get('router.search'),
        ]).subscribe(([offer, search]: [string, string]) => {
            const provider = this.sessionService.sessionSubject
                .getValue()
                .selectedShops[0].toLowerCase();

            if (selected.Url.startsWith('/' + offer + '/' + search)) {
                const arr1: string[] = selected.Url.split('/');
                const arr2: string[] = arr1[3].split('-');

                if (isNaN(Number(arr2[0]))) {
                    this.router
                        .navigate(['/', provider, offer, search, selected.ProductName], {
                            queryParams: { s: selected.ProductName },
                        })
                        .then(() => {
                            this.searchText = '';
                        });
                } else {
                    this.router
                        .navigate([selected.Url], {
                            queryParams: { s: selected.SEOName },
                        })
                        .then(() => {
                            this.searchText = '';
                        });
                }
            } else if (selected.Url.startsWith('/' + offer)) {
                selected.Url = `/${provider}`.concat(selected.Url);
                this.router.navigate([selected.Url]);
            } else {
                this.router
                    .navigate([selected.Url], {
                        queryParams: { s: selected.SEOName || selected.ProductName },
                    })
                    .then(() => {
                        this.searchText = '';
                    });
            }
        });
    }
}
