import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductDto } from '../../../index';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ArchieProductList } from '../message-box/message-box.component';

@Component({
    selector: 'lib-archie-product-list',
    templateUrl: './archie-product-list.component.html',
    styleUrls: ['./archie-product-list.component.scss'],
})
export class ArchieProductListComponent {
    @Input() selectedProducts: ArchieProductList;
    @Input() openProductListEmitter: EventEmitter<ArchieProductList>;
    @Output() clickProduct: EventEmitter<{
        product: ProductDto;
        providerId: number;
        providerName: string;
    }> = new EventEmitter();
    faArrow = faArrowLeft;

    onBack(): void {
        this.openProductListEmitter.next(null);
    }
}
