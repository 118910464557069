import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
    CheckoutService,
    CountryCodes,
    ServiceBaseService,
} from 'projects/library-shared/src/public-api';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TipDto } from '../../../models/order/tipDto';

@Component({
    selector: 'lib-tip-list',
    templateUrl: './tip-list.component.html',
    styleUrls: ['./tip-list.component.scss'],
})
export class TipListComponent implements OnInit {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    checkoutForm: FormGroup;
    tipListForm: FormGroup;
    selectedTipID: string;
    tips: TipDto[];
    isRokshTipAmountEnabled = true;
    CountryCodes = CountryCodes;
    countryCode = this.CountryCodes.hu;

    public get isAtCountryCode(): boolean {
        return this.countryCode === CountryCodes.at;
    }

    public isActiveTip(tipID: string): boolean {
        return this.selectedTipID === tipID;
    }

    constructor(
        private checkoutService: CheckoutService,
        public baseService: ServiceBaseService
    ) {
        this.countryCode = ServiceBaseService.getCountryCode();
    }

    ngOnInit(): void {
        this.checkoutService.formGroup$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((form) => {
                this.checkoutForm = form;
            });

        this.checkoutService.order$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((order) => {
                if (order) {
                    this.isRokshTipAmountEnabled = order.IsRokshTipAmountEnabled;
                    this.tips = order.Tips;
                    const defaultTipID = this.tips?.find(
                        (tip) => tip.IsDefault
                    )?.RokshTipID;
                    if (defaultTipID) {
                        this.selectedTip(defaultTipID);
                    }
                }
            });
    }

    selectedTip(tipID: string) {
        if (tipID) {
            this.selectedTipID = tipID;
            this.checkoutForm.get('tipForm.TipID').setValue(tipID);
            this.checkoutForm.get('tipForm.TipAmount').setValue(null);
        }

        const tipAmount = this.checkoutForm.get('tipForm.TipAmount').value;
        if (tipAmount && tipAmount > 0) {
            this.checkoutForm.get('tipForm.TipAmount').setValue(null);
        }
    }

    setTipAmount(event: any): void {
        const tip = event.target.value;
        this.checkoutForm.get('tipForm.TipAmount').setValue(tip);
        const selectedTipId = this.checkoutForm.get('tipForm.TipID').value;
        if (selectedTipId || this.selectedTipID) {
            this.selectedTipID = null;
            this.checkoutForm.get('tipForm.TipID').setValue(null);
        }
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
