<div class="input-group w-100 search-group h-100 w-100">
    <ng-template #typeAheadTemplate let-model="item" let-index="index">
        <div *ngIf="model.ProductID; else notAProduct">
            <div class="typeahead-result" [id]="model.ProductID">
                <ng-container
                    *ngIf="model.MediaUrl === mediaUrl + 'interface/thumbnail/'"
                >
                    <img
                        #img
                        src="assets/images/missing.jpg"
                        style="height: auto; margin-top: 5px; max-height: 25px"
                        (error)="img.src = 'assets/images/roksh_logo.svg'"
                        alt="not found"
                    />
                </ng-container>
                <ng-container
                    *ngIf="model.MediaUrl !== mediaUrl + 'interface/thumbnail/'"
                >
                    <img
                        #img
                        class="search-result-img"
                        [src]="model.MediaUrl"
                        (error)="img.src = 'assets/images/roksh_logo.svg'"
                        alt="product"
                    />
                </ng-container>

                <div class="item-body">
                    <p class="item-heading" [innerHtml]="model.ProductName"></p>
                    <div
                        class="item-price"
                        *ngIf="
                            model.IsBulk && model.MinUnitPrice && model.MinUnitPrice > 0;
                            else notBulk
                        "
                    >
                        {{ model.MinUnitPrice | currencyFormatted }}
                        {{ 'common.currency' | translate }}/kg
                        <!---tól-->
                    </div>
                    <ng-template #notBulk>
                        <div class="item-price">
                            {{ model.Price | currencyFormatted }}
                            {{ 'common.currency' | translate }}
                            <!---tól-->
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
        <ng-template #notAProduct>
            <div>
                <strong>{{ model.ProductName }}</strong>
            </div>
        </ng-template>
    </ng-template>
    <label class="d-flex search-inp align-items-center ps-3 w-100">
        <fa-icon style="color: #333" (click)="searchClick()" [icon]="faSearch"></fa-icon>
        <input
            class="form-control w-100 h-100 fw-semibold"
            type="text"
            placeholder="{{ 'common.search' | translate }}"
            [(ngModel)]="searchText"
            [typeaheadAsync]="true"
            [typeahead]="typeAheadResults"
            [typeaheadMinLength]="2"
            (typeaheadOnSelect)="typeaheadOnSelect($event)"
            typeaheadOptionField="ProductName"
            [typeaheadWaitMs]="100"
            [typeaheadItemTemplate]="typeAheadTemplate"
        />
    </label>
</div>
