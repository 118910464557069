import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SessionService } from 'projects/library-shared/src/public-api';
import { CreateDialogService } from '../../../services/create-dialog.service';
import { HomeService } from '../../../services/home/home.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-landing-static-first',
    templateUrl: './landing-static-first.component.html',
    styleUrls: ['./landing-static-first.component.scss'],
})
export class LandingStaticFirstComponent implements OnInit {
    @Output() tryItBtn = new EventEmitter<void>();
    postCodeValue = '';
    city: string;
    isSessionSet: boolean;
    isDeliveryAvailableByPostCode: boolean;
    availableProviderNames: string[] = [];
    isPostCodeSetted = false;
    isProviderSelectedForShopping = false;
    userSelectedShops: string[];

    isViewInited = false;

    constructor(
        private sessionService: SessionService,
        private createDialogService: CreateDialogService,
        private homeService: HomeService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.homeService.isSessionSet.subscribe((isSetted) => {
            this.isSessionSet = isSetted;
            this.availableProviderNames = this.homeService.selectedProviderNames.value;
            this.sessionService.isPostCodeSet.subscribe((isSet) => {
                this.isPostCodeSetted = isSet;
            });
        });

        this.sessionService.sessionSubject.subscribe((session) => {
            if (session) {
                this.isDeliveryAvailableByPostCode =
                    session.isDeliveryAvailableByPostCode;

                this.userSelectedShops = session.selectedShops;

                if (session.city && session.zipCode) {
                    this.city = session.city;
                    this.postCodeValue = session.zipCode;
                }
            }
        });
    }

    scrollToShopSelect(): void {
        const landing = document.getElementById('landing-first');
        const sidebar = document.getElementById('sb');

        // need to wait until elements appear and dissapear to calculate positions
        if (landing || sidebar) {
            return;
        }

        this.isViewInited = true;

        if (this.route.snapshot.fragment === 'selectShop') {
            const selectShop = document.getElementById('selectShop');
            const header = document
                .getElementsByClassName('provider-header')
                .item(0) as HTMLElement;
            const container = document.getElementById('shopSelector');

            if (selectShop) {
                window.scrollTo({
                    top:
                        container?.offsetTop +
                        selectShop?.offsetTop -
                        header?.offsetHeight,
                    behavior: 'smooth',
                });
            }
        }
    }
}
