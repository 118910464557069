export class DiscountForUser {
    DiscountID: string;
    DiscountName: string;
    DiscountDisplayName: string;
    DiscountTypeID: string;
    DiscountPercent: number;
    DiscountAmount: number;
    DiscountTitle: string;
    ValidTo: Date;
}
