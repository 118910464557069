import { Component, Inject, OnInit } from '@angular/core';
import {
    FormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    DATALAYER_SERVICE_IMPL,
    DataLayerGa4Service,
    ISessionDto,
    LocationService,
    SessionDto,
    SessionService,
} from 'projects/library-shared/src/public-api';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-address-dialog',
    templateUrl: './address-dialog.component.html',
    styleUrls: ['./address-dialog.component.scss'],
})
export class AddressDialogComponent implements OnInit {
    addressIsValid = false;
    isShowNextButton = false;
    isNextButtonDisabled = false;
    isShowSubscriptionButton = false;
    showAddressConfirmationBox = false;
    isLoading = false;
    session: ISessionDto;
    userMessage: string;
    nextRoute: string;
    address: string;
    addressValidationAllowed = false;
    scrollToShopSelect = false;
    url: SafeResourceUrl =
        'https://www.google.com/maps/embed/v1/place?key=' +
        environment.GOOGLE_API_KEY +
        '&q=Space+Needle, Seattle + WA';
    alertMessage: string;

    constructor(
        private translate: TranslateService,
        private _locationService: LocationService,
        public dialogRef: MatDialogRef<AddressDialogComponent>,
        private formBuilder: FormBuilder,
        private sessionService: SessionService,
        @Inject(DATALAYER_SERVICE_IMPL) private DataLayerGa4Service: DataLayerGa4Service,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    addressForm = new UntypedFormGroup({
        PostCode: new UntypedFormControl(null, [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(4),
            Validators.pattern(/^[0-9]*$/),
        ]),
        City: new UntypedFormControl(null, [
            Validators.required,
            Validators.minLength(2),
        ]),
        StreetName: new UntypedFormControl(null, [
            Validators.required,
            Validators.minLength(2),
        ]),
        HouseNumber: new UntypedFormControl(null, [
            Validators.required,
            Validators.minLength(1),
        ]),
    });

    ngOnInit(): void {
        this.dialogRef.updateSize('768px', '75vh');
        this.dialogRef.updatePosition({ top: '13vh' });

        this.sessionService.sessionSubject.subscribe((sessionSubject) => {
            if (sessionSubject.isValidZip && sessionSubject.zipCode) {
                this.DataLayerGa4Service.addAddress({
                    postCode: sessionSubject.zipCode,
                    streetName: sessionSubject.streetName,
                    city: sessionSubject.city,
                    houseNumber: sessionSubject.houseNumber,
                });

                this.addressValidationAllowed = true;
                this.addressForm.get('PostCode').setValue(sessionSubject.zipCode);
                this.addressForm.get('City').setValue(sessionSubject.city);
                this.addressForm.get('StreetName').setValue(sessionSubject.streetName);
                this.addressForm.get('HouseNumber').setValue(sessionSubject.houseNumber);
                this.url =
                    'https://www.google.com/maps/embed/v1/place?key=' +
                    environment.GOOGLE_API_KEY +
                    '&q=' +
                    sessionSubject.address +
                    '&center=' +
                    sessionSubject.lat +
                    ',' +
                    sessionSubject.long;
                this.addressIsValid = true;
                this.isShowNextButton = true;
            } else {
                this._locationService.geoCode().subscribe((address) => {
                    if (address) {
                        const postCode = this.addressForm.value.PostCode;
                        const city = this.addressForm.value.City;
                        const streetName = this.addressForm.value.StreetName;
                        const houseNumber = this.addressForm.value.HouseNumber;
                        if (!postCode || !city || !streetName || !houseNumber) {
                            this.addressForm.get('PostCode').setValue(address?.postCode);
                            this.addressForm.get('City').setValue(address?.city);
                            this.addressForm
                                .get('StreetName')
                                .setValue(address?.streetName);
                            this.addressForm
                                .get('HouseNumber')
                                .setValue(address?.houseNumber);
                        }
                    }
                });
                this.addressValidationAllowed = false;
            }
        });

        this.alertMessage = this.data.alertMessage;

        this.DataLayerGa4Service.datalayerUniversalPush('ZIP', 'Open');
    }

    checkAddress(): void {
        this.translate.get('checkout.address-required').subscribe((text) => {
            const postCode = this.addressForm.value.PostCode;
            const city = this.addressForm.value.City;
            const streetName = this.addressForm.value.StreetName;
            const houseNumber = this.addressForm.value.HouseNumber;
            if (!postCode || !city || !streetName || !houseNumber) {
                this.userMessage = text;
            } else {
                this.validateAddress(postCode, city, streetName, houseNumber);
            }
        });
    }

    nextStep(): void {
        let fragment = null;
        if (this.scrollToShopSelect) {
            fragment = 'selectShop';
        }

        if (this.session) {
            this.isLoading = true;
            this.setUserSession(this.session).subscribe(() => {
                this.dialogRef.close();
                this.isLoading = false;
                const isArchie = this.router.url.includes('archie');
                if (!this.data.stayHere && this.nextRoute && !isArchie) {
                    void this.router.navigate([this.nextRoute], {
                        fragment: fragment,
                        queryParamsHandling: 'merge',
                    });
                }
            });
        } else {
            this.dialogRef.close();
        }
    }

    validateAddress(
        postCode: string,
        city: string,
        streetName: string,
        houseNumber: string
    ): void {
        this._locationService
            .validateAddress(postCode, city, streetName, houseNumber)
            .subscribe((validatedAddress) => {
                if (validatedAddress.isValid) {
                    this.getAvailableShops(
                        postCode,
                        validatedAddress.address,
                        houseNumber
                    );
                    this.addressForm
                        .get('StreetName')
                        .setValue(validatedAddress.streetName);

                    this.addressForm.get('City').setValue(validatedAddress.cityName);
                } else {
                    this.addressIsValid = false;
                    this.isShowNextButton = false;
                }
            });
    }

    resetPostCode(): void {
        const city = this.addressForm.get('City');
        if (city) {
            city.setValue(null);
        }

        const streetName = this.addressForm.get('StreetName');
        if (streetName) {
            streetName.setValue(null);
        }

        const houseNumber = this.addressForm.get('HouseNumber');
        if (houseNumber) {
            houseNumber.setValue(null);
        }
        this.resetToDefault();
    }

    onChangeAddressConfirmationCheckBox(event: any): void {
        this.isNextButtonDisabled = !event.target.checked;
    }

    resetToDefault(): void {
        this.userMessage = null;
        this.nextRoute = null;
        this.addressIsValid = false;
        this.isShowNextButton = false;
        this.alertMessage = '';

        const postCode = this.addressForm.value.PostCode;
        const city = this.addressForm.value.City;
        const streetName = this.addressForm.value.StreeName;
        const houseNumber = this.addressForm.value.HouseNumber;
        this.addressValidationAllowed =
            !postCode || !city || !streetName || !houseNumber ? false : true;
        this.showAddressConfirmationBox = false;
    }

    private getAvailableShops(postCode: string, address: string, houseNumber: string) {
        this.sessionService
            .getAvailableShopsByZipCodeAndAddress(postCode, address, houseNumber)
            .subscribe(
                (session) => {
                    this.address = session.address.replace(' ', '+');

                    this.url =
                        'https://www.google.com/maps/embed/v1/place?key=' +
                        environment.GOOGLE_API_KEY +
                        '&q=' +
                        this.address +
                        '&center=' +
                        session.lat +
                        ',' +
                        session.long;

                    this.addressIsValid = true;

                    this.setUserMessageAndNextRoute(session);
                },
                () => {
                    this.addressIsValid = false;
                }
            );
    }

    private setUserSession(session: ISessionDto) {
        this.translate.get('checkout.invalid-address').subscribe((text) => {
            this.sessionService
                .setSessionZip(
                    session.zipCode,
                    session.streetName,
                    session.houseNumber,
                    session.lat,
                    session.long,
                    session.availableShops,
                    session.city
                )
                .subscribe(
                    (data) => {
                        if (!data.isValidZip) {
                            this.userMessage = text;
                        }
                    },
                    () => {
                        this.addressIsValid = false;
                    }
                );
        });

        this.DataLayerGa4Service.datalayerUniversalPush(
            'ZIP',
            'Added',
            session.zipCode,
            JSON.stringify(session.availableShops)
        );

        return this.sessionService.setSessionZip(
            session.zipCode,
            session.streetName,
            session.houseNumber,
            session.lat,
            session.long,
            session.availableShops,
            session.city
        );
    }

    private setUserMessageAndNextRoute(session: SessionDto) {
        forkJoin([
            this.translate.get('router.dashboard'),
            this.translate.get('messages.shop-not-available-on-zip'),
            this.translate.get('messages.we-dont-deliver-for-this-zip-long'),
        ]).subscribe(
            ([dashboard, weDontDeliveryZipFromShop, weDontDeliveryZip]: [
                string,
                string,
                string
            ]) => {
                this.isShowNextButton = true;
                this.showAddressConfirmationBox = false;
                this.isNextButtonDisabled = false;
                const shopAvailableOnNewAddress =
                    session.selectedShops?.length === 1 &&
                    session.availableShops
                        ?.map((shops) => shops.toLocaleLowerCase())
                        ?.includes(session.selectedShops[0].toLocaleLowerCase());

                if (session.availableShops?.length === 0) {
                    this.userMessage = weDontDeliveryZip;
                    this.isShowSubscriptionButton = true;
                    this.isShowNextButton = false;
                    return;
                } else if (session.selectedShops?.length > 1) {
                    this.scrollToShopSelect = true;
                    this.session = session;
                } else if (shopAvailableOnNewAddress) {
                    this.nextRoute = dashboard;
                    this.session = session;
                    return;
                } else if (!shopAvailableOnNewAddress) {
                    this.nextRoute = '/';
                    this.session = session;
                    this.scrollToShopSelect = true;
                    this.userMessage = weDontDeliveryZipFromShop;
                } else {
                    if (this.addressIsValid) {
                        this.session = session;
                        return;
                    }
                }
            }
        );
    }

    highlightIcon(icon: HTMLImageElement): void {
        icon.classList.add('onFocus');
    }

    deHighlightIcon(icon: HTMLImageElement): void {
        icon.classList.remove('onFocus');
    }
}
