<div
    class="instant-delivery position-relative"
    (click)="selectInstantDelivery()"
    [ngClass]="{
        active: selected && areaHasInstantProvider,
        disabled: selectionBlocked || !areaHasInstantProvider,
        instantWithhover: selectable && !selectionBlocked && areaHasInstantProvider,
        notavailable: !available || !areaHasInstantProvider
    }"
    [ngStyle]="
        instantWorkTime?.timeSlotPriceDiscountDtoList &&
        !areaHasInstantProvider && { height: '100px' }
    "
>
    <div>
        <ng-container *ngIf="instantWorkTime; else full">
            <img
                class="dollar-img"
                src="assets/svg/rokshImages/icons/rocket_blue.svg"
                alt="free"
            />
        </ng-container>
        <ng-template #full>
            <img
                class="dollar-img"
                src="assets/svg/rokshImages/icons/rocket.svg"
                alt="full"
            />
        </ng-template>
    </div>
    <div class="express-text" *ngIf="areaHasInstantProvider">
        <span class="express-delivery">
            {{
                'time-slot.express-delivery'
                    | translate : { interval: configuredInterval }
            }}<br />
        </span>
        <p
            class="text-success expres-sprice mb-0"
            *ngFor="
                let timeSlotPriceDiscount of instantWorkTime?.timeSlotPriceDiscountDtoList
            "
        >
            <span
                *ngIf="timeSlotPriceDiscount.discountPercentage > 0"
                class="text-start text-success"
            >
                <img src="assets/svg/rokshImages/icons/dollar.svg" />
                <span class="other-information">
                    {{ 'common.german-above' | translate }}
                </span>
                <span class="currency">
                    {{ timeSlotPriceDiscount.discountFromBasketValue }}
                    {{ 'common.currency' | translate }}
                </span>
                <span class="other-information">
                    {{ 'common.hungarian-above' | translate }}
                    -{{ timeSlotPriceDiscount.discountPercentage }}%
                    {{ 'common.delivery-fee' | translate }}
                </span>
            </span>
        </p>
    </div>
    <span class="d-flex justify-content-end col">
        <div
            class="text-end price-green-box p-2 me-2"
            *ngIf="discountPercentage !== 0 && areaHasInstantProvider"
        >
            <span class="title-currency">
                {{ expressPriceText }}
            </span>
        </div>

        <strong
            class="expres-sprice text-start text-success"
            *ngIf="discountPercentage !== 0 && areaHasInstantProvider"
        >
            {{ discountedDeliveryFee }}
            {{ 'common.currency' | translate }}
        </strong>
    </span>
    <span> </span>
    <div
        *ngIf="overWeight || tooLowPrice || !areaHasInstantProvider"
        class="disable-layer"
    >
        {{ text$ | async }}
    </div>
</div>
