import { Injectable } from '@angular/core';
import { CountryCodes } from '../types/country-codes';
import { ServiceBaseService } from './service-base.service';

@Injectable({ providedIn: 'root' })
export class FormatterService {
    currentCountryCode: CountryCodes;
    countryCodes = CountryCodes;

    constructor() {
        this.currentCountryCode = ServiceBaseService.getCountryCode();
    }

    formatCurrency(currency: number, countryCode: CountryCodes = null): string {
        if (countryCode) this.currentCountryCode = countryCode;

        if (this.currentCountryCode === this.countryCodes.hu)
            currency = Math.ceil(currency);

        const locale =
            localStorage.getItem('locale') ?? window.navigator.language.split('-')[0];

        if (
            (currency && this.currentCountryCode !== this.countryCodes.hu) ||
            locale === 'de'
        ) {
            return Number(currency).toLocaleString(locale, {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            });
        } else {
            return currency?.toLocaleString('fr-FR');
        }
    }

    round(num, decimalPlaces = 0) {
        if (num < 0) return -this.round(-num, decimalPlaces);
        const p = Math.pow(10, decimalPlaces);
        const n = num * p;
        const f = n - Math.floor(n);
        const e = Number.EPSILON * n;

        // Determine whether this fraction is a midpoint value.
        return f >= 0.5 - e ? Math.ceil(n) / p : Math.floor(n) / p;
    }

    percentageOfDiscount(
        isBulk: boolean,
        price: number,
        depositFee: number,
        unitPrice: number,
        originalPriceIfOffer: number
    ): string {
        let percentage: number;
        if (!isBulk) {
            percentage = !depositFee ? price : depositFee;
        } else {
            percentage = unitPrice;
        }

        const discount =
            ((originalPriceIfOffer - percentage) / originalPriceIfOffer) * 100;

        return Math.floor(discount).toString();
    }

    weightFormat(basketWeight: number): string {
        if (this.currentCountryCode === this.countryCodes.at) {
            return Number(basketWeight).toLocaleString('fr-FR', {
                maximumFractionDigits: 1,
                minimumFractionDigits: 1,
            });
        } else {
            return basketWeight?.toLocaleString('fr-FR');
        }
    }
}
