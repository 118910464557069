import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyBasketDialogComponent } from './empty-basket-dialog/empty-basket-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
    declarations: [EmptyBasketDialogComponent],
    imports: [CommonModule, TranslateModule, FontAwesomeModule],
})
export class DialogsModule {}
