<div class="margin-to-header"></div>

<div
    class="shop-info-panel-container text-center"
    [ngClass]="shopCoverImageUrl ? '' : 'skeleton'"
>
    <div class="shop-info-panel">
        <ng-container>
            <img
                class="provider-logo"
                src="{{ siteImageUrl }}logos/{{ shop }}-logo-s.png"
            />
        </ng-container>

        <br />

        <div class="w-100 p-1 d-flex py-1" *ngIf="isPostCodeSetted">
            <div class="w-100 text-center text-size-14 text-dark">
                <a (click)="showAvailableTimeSlots()">
                    <img
                        style="max-width: 30px; height: auto"
                        src="assets/svg/deliverycar.svg"
                    />
                    <span
                        class="provider-deliverytimes-link"
                        style="
                            color: #40b9c7;
                            font-weight: 600;
                            margin-bottom: 5px;
                            text-decoration: underline;
                            padding-left: 5px;
                        "
                    >
                        {{ 'dashboard.check-timeslots' | translate }}
                    </span>
                </a>
            </div>
        </div>

        <div class="w-100 p-1 d-flex py-1" *ngIf="!isPostCodeSetted">
            <div class="w-100 text-center text-size-14 text-dark">
                <a (click)="showShopSelectDialog()">
                    <img
                        style="max-width: 30px; height: auto"
                        src="assets/svg/deliverycar.svg"
                    />
                    <span
                        class="provider-deliverytimes-link"
                        style="
                            color: #40b9c7;
                            font-weight: 600;
                            margin-bottom: 5px;
                            text-decoration: underline;
                            padding-left: 5px;
                        "
                    >
                        {{ 'dashboard.clik-for-check-slots' | translate }}!
                    </span>
                </a>
            </div>
        </div>
    </div>
</div>

<app-provider-landing-carousel></app-provider-landing-carousel>

<!-- HUBSPOT INJECTED HTML CODE START -->
<div
    [id]="ContainerIds.leftSideMenu"
    *ngIf="htmlMarketingElementDto$ | async as htmlMarketingElementDto"
    [style]="htmlMarketingElementDto?.cssFormats"
    [innerHTML]="htmlMarketingElementDto?.safeHtml"
></div>
<!-- HUBSPOT INJECTED HTML CODE END -->

<div class="main-categories pt-4 w-100 text-center mt-4 d-md-none">
    <h1 class="w-100 text-center">{{ 'dashboard.explore-our-products' | translate }}!</h1>
    <br />
    <div class="clearfix"></div>
    <div class="text-start category-card-container" *ngIf="allCategories">
        <app-category-card
            [category]="category"
            [isSubCategory]="false"
            *ngFor="let category of allCategories"
        >
        </app-category-card>
        <ng-container *ngIf="!allCategories?.length">
            <div
                *ngFor="let item of dummyArray"
                class="category-card provider-categorycard d-inline-block skeleton"
            ></div>
        </ng-container>
    </div>
</div>

<div class="router-container position-relative mt-4 main-products-list-container">
    <!-- Custom Dashboard - START -->
    <container-element
        *ngFor="let customBlock of allCustomBlocks"
        [ngSwitch]="customBlock.CategoryBlockTypeID"
    >
        <ng-container>
            <some-element *ngSwitchCase="categoryBlockTypes.popular">
                <!-- Popular -->
                <app-popular-products-new
                    [customBlock]="customBlock"
                ></app-popular-products-new>
            </some-element>
            <some-element *ngSwitchCase="categoryBlockTypes.prior">
                <!-- Prior -->
                <app-product-group [customBlock]="customBlock" [isMainCategory]="true">
                </app-product-group>
            </some-element>
            <some-element *ngSwitchCase="categoryBlockTypes.category">
                <!-- Category -->
                <app-custom-category-block
                    [customBlock]="customBlock"
                ></app-custom-category-block>
            </some-element>
            <some-element *ngSwitchCase="categoryBlockTypes.marketing">
                <!-- Prior -->
                <app-product-group [customBlock]="customBlock" [isMainCategory]="true">
                </app-product-group>
            </some-element>
        </ng-container>
        <!---->
        <!-- IDE KÉNE RAKNI A KONTÉNEREKET, BENNE LESZ AZ ADAT AZ allCustomBlocks-ban -->
        <!---->
    </container-element>
    <!-- Custom Dashboard - END -->

    <div #target></div>
    <ng-container *ngIf="dashboardArr && dashboardArr.ProductListResults">
        <app-product-group
            *ngFor="let dummy of dashboardArr.ProductListResults"
            [productListResult]="dummy"
            [isMainCategory]="true"
        ></app-product-group>
    </ng-container>
    <img
        src="/assets/loading_kosar.gif"
        *ngIf="isDashboardCatLoading"
        class="d-block"
        style="margin: auto; margin-top: 30px"
    />
    <div class="btn-margin-bottom"></div>
</div>
<div class="clearfix"></div>
