export interface IProviderSettings {
    ProviderID: number;
    Key: string;
    Section: string;
    Value: string;
    ProviderSettingType: ProviderSettingType;
}

export enum ProviderSettingType {
    Css = 0,
    Text = 1,
    Image = 2,
}
