<div class="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
    <img
        src="{{ siteImageUrl }}images/shared-img/user-basket/delete_sign.svg"
        alt="delete"
    />
    <h6 class="fw-bolder pb-4">{{ 'user-basket.delete-list' | translate }}</h6>

    <p class="pb-3">
        {{ 'user-basket.confirm-delete' | translate }}
        <br />
        <span class="fw-bolder">{{ userBasket.basketName }}</span>
    </p>

    <div class="d-flex justify-content-between w-100">
        <button class="btn btn-no" (click)="dialogRef.close('Vissza')">
            {{ 'common.back' | translate }}
        </button>
        <button
            class="btn btn-yes"
            [disabled]="removeIsProgress"
            (click)="onRemoveUserBasket()"
        >
            {{ 'common.delete' | translate }}
        </button>
    </div>
</div>
