import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    AdditionalLogicType,
    CheckoutService,
    CheckoutStep,
    DataLayerGa4Service,
    PreferenceSettingDTO,
    PreferenceSettingOptionDTO,
    SelectedOrderPreferenceDTO,
    SnackBarService,
} from 'projects/library-shared/src/public-api';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-checkout-preferences',
    templateUrl: './checkout-preferences.component.html',
    styleUrls: ['./checkout-preferences.component.scss'],
})
export class CheckoutPreferencesComponent implements OnInit {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    checkoutForm: FormGroup;
    orderPreferenceSettings: PreferenceSettingDTO[];
    selectedIndex: number;
    preferencesStepIndex = CheckoutStep.Preferences;
    hasBeenActivated = false;
    isSubmitted = false;
    selectedPreferences: SelectedOrderPreferenceDTO[];
    isPaperbagInfoTextVisible = false;
    paperBagInfoTextExpanded = false;

    public get preferencesStepIsExpanded(): boolean {
        return this.selectedIndex === this.preferencesStepIndex;
    }

    public get preferencesStepIsActive(): boolean {
        return this.selectedIndex > this.preferencesStepIndex;
    }

    public get preferencesStepIsDisabled(): boolean {
        return !this.hasBeenActivated;
    }

    constructor(
        private _DataLayerGa4Service: DataLayerGa4Service,
        private translate: TranslateService,
        private snackBar: SnackBarService,
        private checkoutService: CheckoutService
    ) {}

    ngOnInit(): void {
        this.checkoutService.formGroup$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((form) => {
                this.checkoutForm = form;
            });

        this.checkoutService.selectedStep$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((index) => {
                if (index == this.preferencesStepIndex) {
                    this.hasBeenActivated = true;
                }
                this.selectedIndex = index;
            });

        this.checkoutService.orderPreferences$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((settings) => {
                if (settings) {
                    this.orderPreferenceSettings = settings;

                    this.orderPreferenceSettings.map((items) => {
                        items.preferenceSettingOptionList.map((item) => {
                            if (
                                item.additionalLogicID == AdditionalLogicType.UsePaperBag
                            ) {
                                this.isPaperbagInfoTextVisible = true;
                            }
                        });
                    });
                }
            });

        this.setSelectedPreference();

        this.checkoutService.isSubmitted$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((isSubmitted) => (this.isSubmitted = isSubmitted));
    }

    private setSelectedPreference() {
        this.selectedPreferences = [];
        this.orderPreferenceSettings.map((setting) => {
            setting.preferenceSettingOptionList.map((option) => {
                if (option.isChecked) {
                    this.selectedPreferences.push(
                        this.setSelectedOrderPrefDto(setting, option)
                    );
                }
            });
        });

        this.checkoutService.setSelectedOrderPreferenceSource(this.selectedPreferences);

        this.checkoutForm
            .get('preferencesForm.OrderPreferenceSettingOptionsIds')
            .setValue(
                this.selectedPreferences.map(
                    (pref) => pref.orderPreferenceSettingOptionId
                )
            );
    }

    private setSelectedOrderPrefDto(
        setting: PreferenceSettingDTO,
        option: PreferenceSettingOptionDTO
    ): SelectedOrderPreferenceDTO {
        const pref: SelectedOrderPreferenceDTO = {
            settingText: setting.settingText,
            orderPreferenceSettingOptionId: option.orderPreferenceSettingOptionId,
        };
        return pref;
    }

    nextStep(): void {
        if (!this.checkoutService.validateOrderPreferences()) {
            this.translate
                .get('checkout.select-information-required')
                .subscribe((text) => {
                    this.snackBar.openErrorSnackBar(text);
                });

            return;
        }

        const scrWidth = window.innerWidth;
        if (scrWidth < 992) window.scroll(0, 0);

        const summaryPanel: HTMLElement = <HTMLElement>(
            document.getElementById('summaryPanel')
        );

        summaryPanel.classList.add('in-focus');

        this._DataLayerGa4Service.datalayerUniversalPush('Checkout', 'ToPayment');

        this.setStep(CheckoutStep.Payment);
    }

    setStep(index: number): void {
        this.checkoutService.setSelectedStepSource(index);
        const scrWidth = window.innerWidth;

        if (scrWidth < 992) {
            window.scroll(0, 0);
        } else {
            if (index != 0) document.getElementById('summaryPanel').scrollIntoView();
        }
    }

    selectPreferenceSetting(selectedOptionId: string, settingText: string) {
        const preferenceSetting = this.orderPreferenceSettings.find(
            (setting) => setting.settingText === settingText
        );
        preferenceSetting.preferenceSettingOptionList.map(
            (option) => (option.isChecked = false)
        );
        preferenceSetting.preferenceSettingOptionList.find(
            (option) => option.orderPreferenceSettingOptionId === selectedOptionId
        ).isChecked = true;

        this.setSelectedPreference();
    }
}
