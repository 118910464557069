<div class="basket-panel">
    <div class="dialog-header-cover-for-small-view"></div>
    <div class="dialog-header-cover-for-small-view right-panel"></div>
    <mat-tab-group>
        <mat-tab>
            <div class="basket-panel-header p-2">
                <div class="d-flex flex-column w-100">
                    <div
                        class="d-flex d-row w-100 align-items-center justify-content-start"
                    >
                        <div class="d-flex justify-content-center basket-text">
                            {{ 'basket.your-baskets' | translate }}:
                        </div>
                        <div class="vl mx-3"></div>
                        <div class="zip-title text-center">
                            {{ 'shop-select-dialog.delivery-here' | translate }}:
                            {{ zipCode }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="basket-panel-container">
                <div class="content-separator w-100"></div>
                <div class="basket-panel-item-list">
                    <ng-container *ngFor="let basket of masterBasket">
                        <app-basket-provider
                            (backToShopping)="backToShopping.emit()"
                            [basket]="basket"
                        >
                        </app-basket-provider>
                    </ng-container>

                    <div
                        *ngIf="!masterBasket || masterBasket.length == 0"
                        class="d-flex flex-column align-items-center justify-content-center h-100"
                    >
                        <img
                            src="assets/svg/rokshImages/icons/sad basket.svg"
                            alt="Empty basket"
                        />
                        <div class="my-4 empty-basket-text">
                            {{ 'basket.basket-is-empty-yet' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
