import { Component, Input, OnInit } from '@angular/core';
import {
    CategoryShared,
    ServiceBaseService,
} from 'projects/library-shared/src/public-api';

@Component({
    selector: 'app-category-card',
    templateUrl: './category-card.component.html',
    styleUrls: ['category-card.component.scss'],
})
export class CategoryCardComponent implements OnInit {
    @Input() category: CategoryShared;
    @Input() isSubCategory = true;
    @Input() smallCard = false;
    imgUrl: string;
    siteImageUrl = '';

    constructor(public baseService: ServiceBaseService) {}

    ngOnInit() {
        this.siteImageUrl = this.baseService.getSiteImageUrl();
        if (
            this.category.CategoryProviderImage &&
            this.category.CategoryProviderImage.MainCategoryIconFileName
        ) {
            this.imgUrl =
                this.siteImageUrl +
                `category-images/category-provider-images/${this.category.CategoryProviderImage.MainCategoryIconFileName}`;
        } else if (
            this.category.CategoryProviderImage &&
            this.category.CategoryProviderImage.CategoryImageFileName
        ) {
            this.imgUrl =
                this.siteImageUrl +
                `category-images/category-provider-images/${this.category.CategoryProviderImage.CategoryImageFileName}`;
        } else {
            if (
                this.category &&
                this.category.CategoryImageFileName &&
                this.category.CategoryImageFileName.indexOf('own-categories') > -1
            ) {
                this.imgUrl =
                    this.siteImageUrl +
                    `category-images/${this.category.CategoryIconFileName}`;
            } else {
                if (this.isSubCategory) {
                    if (
                        this.category.CategoryIconFileName &&
                        this.category.CategoryIconFileName.indexOf('http') > -1
                    ) {
                        this.imgUrl = this.category.CategoryIconFileName;
                        return;
                    }
                    this.imgUrl =
                        this.siteImageUrl +
                        `category-images/categories/${this.category.ProgID}.png`;
                } else {
                    this.imgUrl =
                        this.siteImageUrl +
                        `category-images/main-categories-icon/${this.category.CategoryIconFileName}.svg`;
                }
            }
        }
    }
}
