<div class="container">
    <div class="row details-page-row mt-0">
        <div class="ms-3 ms-s-5 pt-2 mb-3" *ngIf="prod">
            <a
                [routerLink]="['/', 'router.dashboard' | translate]"
                [routerLinkActive]="['active']"
                class="bc-link"
            >
                {{ 'router.start-page' | translate }}
            </a>
            <fa-icon [icon]="faAngleRight" class="ms-2 me-2"></fa-icon>

            <ng-container *ngFor="let bc of prod['BreadCrumb']; index as i">
                <span>
                    <a [routerLink]="[bc.Url]">{{ bc.CategoryName }}</a>
                    <fa-icon [icon]="faAngleRight" class="mt-1 ms-2 me-2"></fa-icon>
                </span>
            </ng-container>

            <a
                [routerLink]="[prod.category.url]"
                [routerLinkActive]="['active']"
                class="bc-link"
            >
                {{ prod.category.categoryName }}
            </a>
            <fa-icon [icon]="faAngleRight" class="ms-2 me-2"></fa-icon>
            <span>{{ prod.productName }}</span>
        </div>

        <div class="w-100 m-3 ms-3 ms-s-5 bg-white details-page-content">
            <!-- HUBSPOT INJECTED HTML CODE START -->
            <div
                [id]="ContainerIds.productDetails"
                *ngIf="htmlMarketingElementDto$ | async as htmlMarketingElementDto"
                [style]="htmlMarketingElementDto?.cssFormats"
                [innerHTML]="htmlMarketingElementDto?.safeHtml"
            ></div>
            <!-- HUBSPOT INJECTED HTML CODE END -->

            <app-product-details-content
                *ngIf="prod"
                [prod]="prod"
            ></app-product-details-content>
        </div>
    </div>
</div>
