import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { HtmlMarketingElementsExtendsDto } from '../../services/dto/html-marketing-elements/html-marketing-elements-extends.dto';
import {
    ContainerIds,
    MarketingService,
} from '../../services/marketing/marketing.service';

@Component({
    selector: 'lib-html-marketing',
    templateUrl: './html-marketing.component.html',
})
export class HtmlMarketingComponent implements OnInit {
    public get ContainerIds(): typeof ContainerIds {
        return ContainerIds;
    }

    htmlMarketingElementDto$: Observable<HtmlMarketingElementsExtendsDto>;
    @Input() containerId: string;

    constructor(private marketingService: MarketingService) {}

    ngOnInit(): void {
        this.setMarketingElement();
    }

    private setMarketingElement(): void {
        this.htmlMarketingElementDto$ = this.marketingService.getHtmlMarketingElements(
            ContainerIds[this.containerId]
        );
    }
}
