import { Component, Input, OnInit } from '@angular/core';
import {
    IShopAvailable,
    LogService,
    SessionService,
} from 'projects/library-shared/src/public-api';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-postcode',
    templateUrl: './postcode.component.html',
    styleUrls: ['./postcode.component.scss'],
    providers: [LogService],
})
export class PostcodeComponent implements OnInit {
    @Input() onlyTextShown = false;

    selectedCityName = 'Irsz.';
    shopsAvailable: IShopAvailable[] = [];
    zipCode = '';
    isValidZip = false;
    faAngleRight = faAngleRight;

    constructor(private sessionService: SessionService, private log: LogService) {}

    ngOnInit() {
        this.log.mainClass = PostcodeComponent.name;
        this.sessionService.sessionSubject.subscribe((session) => {
            this.isValidZip = session?.isValidZip;
            if (this.selectedCityName !== session?.city) {
                this.zipCode = session?.zipCode;
            }
        });
    }
}
