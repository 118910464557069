<div class="category-page-sidebar" id="sb" *ngIf="isCategoryMenuDisplayed">
    <div class="category-page-sidebar-header-container">
        <div class="category-page-sidebar-header" id="category-page-sidebar-header">
            <div class="category-page-sidebar-header-current-shop">
                <a
                    class="category-page-sidebar-header-current-shop-link"
                    [routerLink]="['router.dashboard' | translate]"
                >
                    <div
                        class="provider-logo category-page-sidebar-header-current-shop-image"
                        [ngStyle]="{ padding: shop ? '5px' : '0' }"
                    >
                        <ng-container>
                            <img
                                *ngIf="shop; else loading"
                                class="provider-image"
                                src="{{ siteImageUrl }}logos/{{ shop }}-logo-s.png"
                            />
                            <ng-template #loading>
                                <div class="skeleton"></div>
                            </ng-template>
                        </ng-container>
                    </div>

                    <h2 class="category-page-sidebar-header-current-shop-text" #shopText>
                        {{ shop }}
                    </h2>
                </a>
            </div>
        </div>
    </div>

    <div class="d-flex flex-column overflow-auto position-relative" id="scroll-container">
        <div class="d-flex flex-column justify-content-center zip-timeslot w-100">
            <div class="w-100 d-flex justify-content-center">
                <a
                    class="category-page-sidebar-header-shipping-address-link"
                    (click)="onClickGiveAddress()"
                >
                    {{ 'shop-select-dialog.delivery-here' | translate }}: {{ zipCode }}
                    <svg
                        width="12"
                        height="12"
                        viewBox="0 0 24 24"
                        fill="#333333"
                        xmlns="http://www.w3.org/2000/svg"
                        color="systemGrayscale30"
                        size="12"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.879 12l-5.94-5.94a1.5 1.5 0 012.122-2.12l7 7a1.5 1.5 0 010 2.12l-7 7a1.5 1.5 0 01-2.122-2.12L16.88 12z"
                        ></path>
                    </svg>
                </a>
            </div>

            <button
                class="category-page-sidebar-header-check-timeslot-button ps-0"
                (click)="showAvailableTimeSlots()"
            >
                <div class="category-page-sidebar-header-check-timeslot-container">
                    <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M7.5 13.3367C11.0899 13.3367 14 10.5751 14 7.16837C14 3.76167 11.0899 1 7.5 1C3.91015 1 1 3.76167 1 7.16837C1 10.5751 3.91015 13.3367 7.5 13.3367Z"
                            stroke="#FFBF00"
                            stroke-width="1.63528"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M9.45 9.63565L7.8809 8.14661C7.63709 7.9153 7.50007 7.60157 7.5 7.2744V3.46729"
                            stroke="#FFBF00"
                            stroke-width="1.63528"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>

                    <p class="category-page-sidebar-header-check-timeslot-text">
                        {{ 'common.check-timeslots' | translate }}
                    </p>
                    <svg
                        width="12"
                        height="12"
                        viewBox="0 0 24 24"
                        fill="#FFBF00"
                        xmlns="http://www.w3.org/2000/svg"
                        size="12"
                        data-testid="chevron-cta"
                        class="css-1hchw5q"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.879 12l-5.94-5.94a1.5 1.5 0 012.122-2.12l7 7a1.5 1.5 0 010 2.12l-7 7a1.5 1.5 0 01-2.122-2.12L16.88 12z"
                        ></path>
                    </svg>
                </div>
            </button>
            <!-- HUBSPOT INJECTED HTML CODE START -->
            <div
                [id]="ContainerIds.leftSideMenu"
                *ngIf="htmlMarketingElementDto$ | async as htmlMarketingElementDto"
                [style]="htmlMarketingElementDto?.cssFormats"
                [innerHTML]="htmlMarketingElementDto?.safeHtml"
            ></div>
            <!-- HUBSPOT INJECTED HTML CODE END -->
        </div>

        <ul class="category-page-sidebar-header-home px-2">
            <li>
                <a
                    [routerLink]="['router.dashboard' | translate]"
                    [class.active-category]="!activeCategory"
                    class="category-page-sidebar-category-link category-page-sidebar-header-home-link d-flex align-items-center w-100 mt-2"
                >
                    <span class="category-page-sidebar-header-home-icon">
                        <svg
                            width="20"
                            height="19"
                            viewBox="0 0 20 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M18.3814 11.7379H18.5889C18.9639 11.7379 19.3235 11.5895 19.5883 11.3217C20.1376 10.7683 20.1376 9.87184 19.5861 9.31845L18.3814 11.7379ZM10.0004 0.76966L19.0549 9.84789C19.3149 10.1088 19.3149 10.5326 19.056 10.7933L19.0549 10.7945C18.9325 10.9183 18.7656 10.9879 18.5889 10.9879H17.6314H16.8814V11.7379V18.1974H11.8094V13.989V13.239H11.0594H8.58849H7.83849V13.989V18.1974H3.11939V11.7379V10.9879H2.36939H1.41193C1.04974 10.9879 0.75 10.6907 0.75 10.3212C0.75 10.1442 0.820717 9.97357 0.945972 9.84789L10.0004 0.76966ZM9.96735 0.736509L9.96797 0.737136C9.96776 0.736931 9.96756 0.736717 9.96735 0.736509Z"
                                fill="#232323"
                                stroke="#232323"
                                [class.selected-icon]="!activeCategory"
                                stroke-width="1"
                            />
                        </svg>
                    </span>
                    <span class="category-page-sidebar-header-home-text">{{
                        'info.home-page' | translate
                    }}</span>
                </a>
            </li>
        </ul>
        <div class="separator" id="separator"></div>

        <div class="h-100" id="accContainer" (click)="subscribeToCollapse()">
            <ul
                *ngIf="categoryList.length > 0"
                class="category-page-sidebar-categories p-2 mb-5"
                id="accordionExample"
            >
                <ng-container *ngFor="let category of categoryList; let i = index">
                    <li
                        class="category-page-sidebar-category pb-0"
                        id="{{ category.ProgID }}"
                    >
                        <a
                            class="category-page-sidebar-category-link category-page-sidebar-category-text collapsed"
                            data-bs-toggle="collapse"
                            [attr.data-bs-target]="
                                '#' + category.ProgID + '-subcategories'
                            "
                            aria-expanded="false"
                            [attr.aria-controls]="category.ProgID + '-subcategories'"
                            [ngClass]="
                                activeCategory === category.ProgID
                                    ? 'active-category'
                                    : ''
                            "
                            (click)="activeCategory = category.ProgID"
                            [routerLink]="category.Url"
                        >
                            {{ category.CategoryName }}
                        </a>
                        <div
                            id="{{ category.ProgID }}-subcategories"
                            class="accordion-collapse collapse overflow-hidden"
                            data-bs-parent="#accordionExample"
                            [ngClass]="activeCategory === category.ProgID ? 'show' : ''"
                        >
                            <ul
                                class="accordion-body category-page-sidebar-subcategories"
                            >
                                <li
                                    *ngFor="let categoryChild of category.ChildList"
                                    class="category-page-sidebar-subcategory"
                                >
                                    <div class="d-flex flex-row">
                                        <div class="line-container">
                                            <div
                                                class="vertical-line h-100"
                                                [ngClass]="{
                                                    'active-child':
                                                        categoryChild.ProgID ===
                                                        activeChildCategory
                                                }"
                                            ></div>
                                        </div>
                                        <a
                                            class="category-page-sidebar-subcategory-link"
                                            [routerLink]="categoryChild.Url"
                                        >
                                            <span
                                                class="category-page-sidebar-subcategory-text"
                                                [ngStyle]="{
                                                    color:
                                                        activeChildCategory ===
                                                        categoryChild.ProgID
                                                            ? 'black'
                                                            : ''
                                                }"
                                                >{{ categoryChild.CategoryName }}</span
                                            >
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <ng-container
                            *ngIf="i === categoryList.length - 1 && !this.isViewInited"
                        >
                            {{
                                this.scrollToActiveCategory(
                                    this.activeCategory + '-subcategories'
                                )
                            }}
                        </ng-container>
                    </li>
                </ng-container>
            </ul>
            <ng-container *ngIf="categoryList.length === 0">
                <div
                    *ngFor="let item of dummyArray"
                    class="skeleton-categories my-2 mx-4"
                ></div>
            </ng-container>
        </div>
    </div>
</div>
