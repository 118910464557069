import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { TimeSlotRestrictionsResponseDTOShared } from '../../models/DTO/timeslot-restriction.dto';
import { ServiceBaseService } from '../service-base.service';
import { IRestrictionCheckerService } from './irestriction-checker.service';
import { FormatterService } from '../formatter.service';

@Injectable({
    providedIn: 'root',
})
export class RestrictionCheckerService implements IRestrictionCheckerService {
    private shopServiceUrl: string;
    private restrictions = new TimeSlotRestrictionsResponseDTOShared();
    private weightMessageTemplate;
    private weightMessage;

    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(
        private http: HttpClient,
        private serviceBaseService: ServiceBaseService,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private formatterService: FormatterService
    ) {
        this.shopServiceUrl = this.serviceBaseService.getBaseUrl();
        this.translate
            .get('messages.your-basket-now-above-limit-3')
            .subscribe((translation) => (this.weightMessageTemplate = translation));
    }

    getTimeslotRestrictions(providerID: number, postCode: string): void {
        if (providerID && postCode) {
            this.http
                .get<TimeSlotRestrictionsResponseDTOShared>(
                    this.shopServiceUrl + 'api/timeslot/GetTimeSlotRestrictions',
                    {
                        headers: this.headers,
                        params: { providerID: providerID.toString(), postCode: postCode },
                    }
                )
                .subscribe((resp) => {
                    this.restrictions = resp;
                });
        }
    }

    checkRestrictions(basketWeight: number): void {
        if (this.isMaxBasketWeightExceeded(basketWeight)) {
            this.insertWeightMessageParameters(basketWeight);

            this.snackBar.open(this.weightMessage, null, {
                verticalPosition: 'top',
                horizontalPosition: 'center',
                duration: 5000,
            });
        }
    }

    getMaxBasketWeight(): number {
        return this.restrictions.MaxBasketWeight;
    }

    isMaxBasketWeightExceeded(weight: number): boolean {
        return (
            this.restrictions.IsMaxBasketWeightRestricted &&
            weight > this.restrictions.MaxBasketWeight
        );
    }

    getMinBasketValue(): number {
        return this.restrictions.MinBasketValue;
    }

    isMinBasketValueBelow(price: number): boolean {
        return (
            this.restrictions.IsMinBasketValueRestricted &&
            price < this.restrictions.MinBasketValue
        );
    }

    // hogy a fordításokat ne kelljen feldarabolni, és ne kelljen TypeScript-et menteni db-be, így oldom meg a konkrét szám behelyettesítést
    private insertWeightMessageParameters(basketWeight: number): void {
        const basketWeightFormatted = this.formatterService.weightFormat(
            basketWeight / 1000
        );

        this.weightMessage = this.weightMessageTemplate.replace(
            '{0}',
            basketWeightFormatted
        );
        this.weightMessage = this.weightMessage.replaceAll(
            '{1}',
            this.restrictions.MaxBasketWeight / 1000
        );
    }
}
