import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ISnackBarService } from './i-snackbar.service';

@Injectable({
    providedIn: 'root',
})
export class SnackBarService implements ISnackBarService {
    constructor(private snackBar: MatSnackBar) {}

    openSnackBar(
        message: string,
        verticalPosition: MatSnackBarVerticalPosition = 'top'
    ): void {
        this.snackBar.open(message, null, {
            verticalPosition,
            horizontalPosition: 'center',
            duration: 5000,
            panelClass: 'custom-snack-bar',
        });
    }

    openErrorSnackBar(message: string): void {
        this.snackBar.open(message, null, {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 5000,
            panelClass: ['error-snackbar'],
        });
    }

    openSuccessSnackBar(message: string): void {
        this.snackBar.open(message, null, {
            verticalPosition: 'top',
            horizontalPosition: 'center',
            duration: 5000,
            panelClass: ['success-snackbar'],
        });
    }
}
