import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceBaseService } from 'projects/library-shared/src/public-api';
import { Observable } from 'rxjs';

@Injectable()
export class EmailVerificationService {
    constructor(
        private http: HttpClient,
        private serviceBaseService: ServiceBaseService
    ) {}

    verificateEmail(activateCode: string): Observable<any> {
        return this.http.get(
            this.serviceBaseService.getBaseUrl() + `auth/activation/${activateCode}`
        );
    }
}
