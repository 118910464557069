import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ServiceBaseService } from './service-base.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class OwnWebshopRedirect implements CanActivate {
    constructor(private router: Router, private translateService: TranslateService) {}

    canActivate() {
        if (
            window.location.pathname === '/' &&
            ServiceBaseService.isProviderOwnWebShop()
        ) {
            this.translateService.get('router.dashboard').subscribe((text) =>
                this.router.navigate([text], {
                    queryParamsHandling: 'merge',
                })
            );

            return false;
        }

        return true;
    }
}
