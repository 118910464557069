import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProductDto, UserBasketDto } from '../../../index';
import { BasketService } from '../../../services/basket.service';
import { FormatterService } from '../../../services/formatter.service';
import { ServiceBaseService } from '../../../services/service-base.service';
import { SessionService } from '../../../services/session/session.service';
import { UserBasketService } from '../../../services/user-basket.service';

@Component({
    templateUrl: './add-product-to-list.component.html',
    styleUrls: ['./add-product-to-list.component.scss'],
})
export class AddProductToListComponent implements OnInit, OnDestroy {
    private shoppingListSubscription: Subscription;
    private sessionSub: Subscription;
    shoppingList: UserBasketDto[];
    quantityList: [number, string][];
    @Input() newListEmitter: (data?) => void;
    @Input() closeEmitter: () => void;
    @Input() product: ProductDto;
    siteImageUrl: string;

    constructor(
        private userBasketService: UserBasketService,
        public formatterService: FormatterService,
        private basketService: BasketService,
        private sessionService: SessionService,
        baseService: ServiceBaseService
    ) {
        this.siteImageUrl = baseService.siteImageUrl;
    }

    ngOnInit(): void {
        this.loadUserBaskets();

        this.quantityList = this.basketService.createQuantityList(
            this.product.minWeightStep,
            this.product.maxWeightStep,
            this.product.isBulk,
            this.product.priceUnitType
        );
    }

    loadUserBaskets(): void {
        this.sessionSub = this.sessionService.sessionSubject.subscribe((session) => {
            this.shoppingListSubscription = this.userBasketService
                .getUserBaskets(session.selectedShopIds[0])
                .subscribe((shoppingLists) => {
                    if (!shoppingLists) return;

                    if (shoppingLists.length > 0) {
                        this.shoppingList = shoppingLists;
                    } else {
                        this.closeEmitter();
                        this.newListEmitter();
                    }
                });
        });
    }

    onCreateNewUserBasket(): void {
        this.closeEmitter();
        this.newListEmitter();
    }

    ngOnDestroy(): void {
        if (this.shoppingListSubscription) {
            this.shoppingListSubscription.unsubscribe();
        }
        if (this.sessionSub) {
            this.sessionSub.unsubscribe();
        }
    }

    close(): void {
        this.closeEmitter();
    }
}
