<div *ngIf="availableTimeSlotDto">
    <div class="clearfix"></div>

    <div
        *ngIf="availableTimeSlotDto.restriction.isUnderPriceLimit"
        class="alert-restriction text-center pt-3 px-2"
    >
        {{ 'time-slot.in-this-shop-only' | translate }}
        <span class="text-size-18 text-green"
            >&nbsp;{{ availableTimeSlotDto.restriction.minBasketValue }}&nbsp;{{
                'common.currency' | translate
            }}&nbsp;</span
        >
        {{ 'time-slot.-above-currency-available' | translate }}
    </div>
    <div
        *ngIf="availableTimeSlotDto.restriction.isOverWeightLimit"
        class="alert-restriction text-center"
    >
        {{ 'time-slot.the-basket-weighs-more-than' | translate }}
        {{ availableTimeSlotDto.restriction.maxBasketWeight / 1000 }}
        {{ 'time-slot.-kg-weight-maximum' | translate }}
    </div>
    <div *ngIf="noSlot" class="full text-center">
        <fa-icon [icon]="faInfoCircle"></fa-icon>
        {{ 'time-slot.no-slot' | translate }}
    </div>
    <hr />
    <div *ngIf="earliestTimeSlots" class="earliest-timeslots-container">
        <div class="mt-3 mb-3">
            <div class="carousel-header w-100">
                <h4 class="title pt-2 ps-3 float-start">
                    {{ 'time-slot.earliest-available-dates' | translate }}
                </h4>
                <div class="float-end d-flex pt-3">
                    <div>
                        <a class="arrows prev-arrow mx-1" (click)="carousel1.prev()">
                            <fa-icon [icon]="faChevronLeft"></fa-icon>
                        </a>
                        <a class="arrows next-arrow mx-1" (click)="carousel1.next()">
                            <fa-icon [icon]="faChevronRight"></fa-icon>
                        </a>
                    </div>
                </div>
            </div>
            <div *ngIf="!isAvailableTimeSlots" class="full ps-3">
                <fa-icon [icon]="faInfoCircle"></fa-icon>
            </div>
            <owl-carousel-o [options]="customOptions" #carousel1>
                <ng-template
                    *ngFor="let timeSlot of earliestTimeSlots"
                    class="timeslots-container"
                    carouselSlide
                    [width]="170"
                >
                    <app-earliest-timeslot
                        [earliestTimeSlot]="timeSlot"
                        [currentBasketValue]="currentBasketValue"
                        [currentBasketWeight]="currentBasketWeight"
                        (onSelectEarliestTimeSlot)="
                            selectEarliestTimeSlotDelivery(timeSlot)
                        "
                    >
                    </app-earliest-timeslot>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>

    <div class="slot-title mb-3 w-100">
        <h4 class="title pt-2 float-start">
            {{ 'time-slot.more-dates' | translate }}
        </h4>
    </div>
    <ng-template [ngIf]="areaHasInstantProvider || configuredInstantTimeSlots">
        <div>
            <div
                class="col-12 mb-3 ms-3 express-container"
                *ngFor="let timeSlot of configuredInstantTimeSlots"
            >
                <app-express-timeslot
                    [instantWorkTime]="getCorrespondTimeSlot(timeSlot)"
                    [selectionAllowed]="selectionAllowed"
                    [areaHasInstantProvider]="areaHasInstantProvider"
                    (onInstantDeliverySelected)="selectInstantDelivery(timeSlot)"
                    [selectedExpressTimeSlotInterval]="selectedInstantWorktimeInterval"
                    [configuredInterval]="timeSlot.interval"
                    [discountPercentage]="
                        getDiscountPercentage(
                            getCorrespondTimeSlot(timeSlot)?.deliveryFee,
                            getCorrespondTimeSlot(timeSlot)?.timeSlotPriceDiscountDtoList
                        )
                    "
                    [discountedDeliveryFee]="
                        getDiscountedDeliveryFee(
                            getCorrespondTimeSlot(timeSlot)?.deliveryFee,
                            getCorrespondTimeSlot(timeSlot)?.timeSlotPriceDiscountDtoList
                        )
                    "
                    [isOverWeightLimit]="
                        currentBasketWeight >=
                        getCorrespondTimeSlot(timeSlot)?.maxBasketWeight
                    "
                    [isUnderPriceLimit]="
                        currentBasketValue <=
                        getCorrespondTimeSlot(timeSlot)?.minBasketValue
                    "
                >
                </app-express-timeslot>
            </div>
        </div>
    </ng-template>
    <mat-accordion [multi]="false">
        <div class="delivery-days-container" *ngIf="doDisplayTimeSlots; else ZipToSelect">
            <mat-expansion-panel
                hideToggle
                *ngFor="let timeSlotRange of availableTimeSlotDto.timeSlotRangeList"
                class="panel mb-3"
                (opened)="setResetState()"
            >
                <mat-expansion-panel-header class="header px-4">
                    <mat-panel-title
                        class="p-0 m-0 d-flex justify-content-between align-items-start"
                    >
                        <div>
                            <div class="periods col">
                                {{ timeSlotRange.title }}
                                {{ 'time-slot.delivery' | translate }}
                            </div>
                            <div *ngIf="hasDiscount(timeSlotRange)" class="periods col">
                                <p class="text-start text-success mb-0">
                                    <img
                                        class="dollar-img"
                                        src="assets/svg/rokshImages/icons/dollar.svg"
                                    />
                                    <span
                                        *ngIf="
                                            timeSlotRange.timeSlotDayList[0]
                                                .timeSlotPriceDiscountDtoList[0]
                                                ?.discountPercentage === 100;
                                            else discountUnder100
                                        "
                                        class="currency"
                                    >
                                        {{ 'common.delivery-free' | translate }}
                                        {{
                                            timeSlotRange.timeSlotDayList[0]
                                                .timeSlotPriceDiscountDtoList[0]
                                                ?.discountFromBasketValue
                                        }}
                                        {{ 'common.from-currency' | translate }}</span
                                    >
                                    <ng-template #discountUnder100>
                                        <span class="other-information">
                                            {{ 'common.german-above' | translate }}
                                        </span>
                                        <span class="currency">
                                            {{
                                                timeSlotRange.timeSlotDayList[0]
                                                    .timeSlotPriceDiscountDtoList[0]
                                                    ?.discountFromBasketValue
                                            }}
                                            {{ 'common.currency' | translate }}
                                        </span>
                                        <span class="other-information">
                                            {{ 'common.hungarian-above' | translate }}
                                            -{{
                                                timeSlotRange.timeSlotDayList[0]
                                                    .timeSlotPriceDiscountDtoList[0]
                                                    ?.discountPercentage
                                            }}%
                                            {{ 'common.delivery-fee' | translate }}
                                        </span>
                                    </ng-template>
                                </p>
                            </div>
                            <div *ngIf="timeSlotRange.isAvailableForRange">
                                <p
                                    class="text-start text-danger restriction-err-msg"
                                    *ngIf="
                                        !timeSlotRange.restrictionForRange
                                            .isUnderPriceLimit &&
                                        timeSlotRange.restrictionForRange
                                            .isOverWeightLimit
                                    "
                                >
                                    <fa-icon [icon]="faInfoCircle"></fa-icon>
                                    {{
                                        'time-slot.this-delivery-mode-available-only-under'
                                            | translate
                                    }}
                                    <strong>{{
                                        timeSlotRange.restrictionForRange
                                            .maxBasketWeight / 1000
                                    }}</strong>
                                    {{ 'time-slot.-under-kg-available' | translate }}
                                </p>
                            </div>
                        </div>
                        <div class="text-end price-green-box py-1 px-2">
                            <span class="title-currency">
                                {{
                                    getTitleDeliveryFee(timeSlotRange.timeSlotDayList[0])
                                }}
                                {{ 'common.currency' | translate }}
                            </span>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="owl-carousel-container">
                    <div class="w-100 justify-content-end d-none d-md-flex">
                        <a class="arrows prev-arrow mx-1" (click)="carousel2.prev()">
                            <fa-icon [icon]="faChevronLeft"></fa-icon>
                        </a>
                        <a class="arrows next-arrow mx-1" (click)="carousel2.next()">
                            <fa-icon [icon]="faChevronRight"></fa-icon>
                        </a>
                    </div>
                    <owl-carousel-o [options]="customOptions" #carousel2>
                        <ng-template
                            *ngFor="let timeSlotDay of timeSlotRange.timeSlotDayList"
                            carouselSlide
                            [width]="120"
                        >
                            <div
                                class="p-2 m-1 delivery-days"
                                (click)="
                                    showAvailableTimeSlots(
                                        $event,
                                        timeSlotDay.dateKey,
                                        timeSlotRange.timeSlotID
                                    )
                                "
                                style="white-space: pre-wrap"
                                [innerHTML]="timeSlotDay.displayText"
                            ></div>
                        </ng-template>
                    </owl-carousel-o>
                </div>

                <div class="mt-2" *ngIf="!doDisplayTimeSlotRanges">
                    <ng-container *ngIf="selectedTimeSlotDayDTO">
                        <div class="notAvailableInfo">
                            <span>{{ notAvailableInfo }}</span>
                        </div>
                    </ng-container>
                </div>
                <div class="w-100">
                    <br />
                    <div
                        class="available-timeslot-ranges column w-100 m-1"
                        *ngIf="doDisplayTimeSlotRanges"
                    >
                        <ng-container
                            *ngFor="
                                let timeSlotRangeItem of selectedTimeSlotDayDTO.timeSlotRangeItemList
                            "
                        >
                            <ng-container>
                                <label class="w-100 pt-2" (click)="selectTimeSlot()">
                                    <div
                                        class="available-timeslot container-radio"
                                        [ngClass]="{
                                            disabled: !timeSlotRangeItem.isAvailable,
                                            active:
                                                timeSlotRangeItem ===
                                                selectedTimeSlotRangeItemDTO,
                                            selectable: selectionAllowed
                                        }"
                                    >
                                        <input
                                            type="radio"
                                            name="timeSlotRB"
                                            *ngIf="
                                                !underThreshold() &&
                                                timeSlotRangeItem.isAvailable
                                            "
                                            id="timeSlotRB{{
                                                selectedTimeSlotDayDTO.dateKey
                                            }}{{ timeSlotRangeItem.startHour }}{{
                                                timeSlotRangeItem.endHour
                                            }}"
                                            style="z-index: 1"
                                            [ngClass]="{
                                                selectable: selectionAllowed
                                            }"
                                            (change)="
                                                selectDeliveryTimeSlot(
                                                    timeSlotRange,
                                                    timeSlotRangeItem,
                                                    getDiscountPercentage(
                                                        selectedTimeSlotDayDTO.deliveryFee -
                                                            selectedTimeSlotDayDTO.additionalFee,
                                                        selectedTimeSlotDayDTO.timeSlotPriceDiscountDtoList
                                                    )
                                                )
                                            "
                                        />

                                        <div
                                            class="timeslot-header d-flex align-items-center flex-row"
                                        >
                                            <div class="mx-3 col">
                                                {{ timeSlotRangeItem.startHour }}.00 -
                                                {{ timeSlotRangeItem.endHour }}.00<br />
                                            </div>
                                            <div *ngIf="!timeSlotRangeItem.isAvailable">
                                                {{ 'time-slot.full-slot' | translate }}
                                            </div>
                                            <div
                                                *ngIf="
                                                    underThreshold() &&
                                                    timeSlotRangeItem.isAvailable
                                                "
                                                class="disable-layer"
                                            >
                                                {{
                                                    'time-slot.not-available' | translate
                                                }}
                                            </div>
                                            <div
                                                class="d-flex justify-content-end col prices me-3"
                                            >
                                                <strong
                                                    [ngClass]="{
                                                        'discounted-price':
                                                            getDiscountPercentage(
                                                                selectedTimeSlotDayDTO.deliveryFee -
                                                                    selectedTimeSlotDayDTO.additionalFee,
                                                                selectedTimeSlotDayDTO.timeSlotPriceDiscountDtoList
                                                            ) !== 0
                                                    }"
                                                >
                                                    {{
                                                        selectedTimeSlotDayDTO.deliveryFee -
                                                            selectedTimeSlotDayDTO.additionalFee
                                                            | currencyFormatted
                                                    }}
                                                    {{ 'common.currency' | translate }}
                                                </strong>

                                                <strong
                                                    class="text-start text-success"
                                                    *ngIf="
                                                        getDiscountPercentage(
                                                            selectedTimeSlotDayDTO.deliveryFee -
                                                                selectedTimeSlotDayDTO.additionalFee,
                                                            selectedTimeSlotDayDTO.timeSlotPriceDiscountDtoList
                                                        ) !== 0
                                                    "
                                                >
                                                    {{
                                                        getDiscountedDeliveryFee(
                                                            selectedTimeSlotDayDTO.deliveryFee -
                                                                selectedTimeSlotDayDTO.additionalFee,
                                                            selectedTimeSlotDayDTO.timeSlotPriceDiscountDtoList
                                                        )
                                                    }}
                                                    {{ 'common.currency' | translate }}
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </mat-expansion-panel>
        </div>
    </mat-accordion>

    <i
        class="col-12"
        style="color: #e100ca"
        *ngIf="postCode && postCode.length === 4 && noDeliveryTimeAvailable"
    >
        <!-- &nbsp;&nbsp;&nbsp; -->
        <strong>
            {{ 'time-slot.no-slot-for-this-postcode' | translate }}
            {{ postCode }}!<br />
            {{ 'time-slot.we-are-expanding-slots' | translate }}
        </strong>
    </i>
    <ng-template #ZipToSelect>
        <i class="text-info" *ngIf="postCode.length !== 4">
            &nbsp;&nbsp;&nbsp;{{ 'time-slot.for-slots-add-your-mail' | translate }}
        </i>
        <hr />
    </ng-template>
</div>
