<div class="d-flex justify-content-center" *ngIf="!prod">
    <mat-spinner></mat-spinner>
</div>

<div class="text-end w-100 close-button">
    <span class="pointer" (click)="dialogRef.close()">
        <img
            class="close-icon"
            src="assets/svg/rokshImages/icons/close.svg"
            alt="close button"
        />
    </span>
</div>

<div *ngIf="prod">
    <div class="scroll-bottleneck-fix" mat-dialog-content>
        <app-product-details-content
            [providerId]="providerId"
            [providerName]="providerName"
            [isArchie]="data.isArchie"
            *ngIf="prod"
            [prod]="prod"
            [isDialog]="true"
        >
        </app-product-details-content>
    </div>
    <div class="d-md-none bottom-price-mobile" *ngIf="prod">
        <div *ngIf="prod.available == true">
            <lib-add-to-cart-button
                [isArchie]="data.isArchie"
                [providerId]="providerId"
                [providerName]="providerName"
                [prod]="prod"
                class="pull-right"
                [buttonText]="'common.add-to-basket' | translate"
                [buttonPadding]="'32px'"
                style="line-height: 21px"
                [addressDialogComponent]="AddressDialogComponent"
                [providerDialogComponent]="ShopSelectDialogSeparatedComponent"
            >
            </lib-add-to-cart-button>
        </div>
        <div class="clearfix"></div>
    </div>
</div>
