import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ValidatedAddress } from '../../models/address/validatedAddress';

export const LOCATION_SERVICE_IMPL = new InjectionToken<ILocationService>(
    'locationServiceImpl'
);

export interface ILocationService {
    getLocation(address: string): Observable<string>;
    validateAddress(
        postCode: string,
        city: string,
        streetName: string,
        houseNumber: string
    ): Observable<ValidatedAddress>;
}
