import { Filter } from '../entities/entities';

export class ListContextDTO {
    BaseUrl: string;
    TotalItems: number;
    ItemsPerPage: number;
    Page: number;
    TotalPages: number;
    IsSearch: boolean;
    FilterList: Filter[];
}
