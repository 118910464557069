<div class="block">
    <div class="row row-cols-4 row-cols-md-2 row-cols-lg-4 ms-5 justify-content-center">
        <div class="block-element col col-lx-3">
            <div class="row align-items-center">
                <div class="icon rounded-circle">
                    <img src="/assets/svg/rokshImages/icons/information-time.svg" />
                </div>
                <div class="col information-text">
                    {{ 'info.info-time' | translate }}
                </div>
            </div>
        </div>
        <div class="block-element col col-lx-3">
            <div class="row align-items-center">
                <div class="icon rounded-circle">
                    <img src="/assets/svg/rokshImages/icons/information-shops.svg" />
                </div>
                <div class="col information-text">
                    {{ 'info.info-shops' | translate }}
                </div>
            </div>
        </div>
        <div class="block-element col col-lx-3">
            <div class="row align-items-center">
                <div class="icon rounded-circle">
                    <img src="/assets/svg/rokshImages/icons/information-discount.svg" />
                </div>
                <div class="col information-text">
                    {{ 'info.info-discount' | translate }}
                </div>
            </div>
        </div>
        <div class="block-element link col col-lx-3">
            <div class="row align-items-center">
                <div class="icon rounded-circle">
                    <img src="/assets/svg/rokshImages/icons/arrow-link.svg" />
                </div>
                <div class="col information-text">
                    <a
                        class="information-text linkable-information-text"
                        href="{{ 'static-links.roksh-informations' | translate }}"
                        target="_blank"
                    >
                        {{ 'info.info-link' | translate }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
