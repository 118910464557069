<div id="paymentDataPanel" [formGroup]="checkoutForm">
    <div formGroupName="paymentForm">
        <mat-expansion-panel
            [expanded]="paymentStepIsExpanded"
            [disabled]="paymentStepIsDisabled"
            [class.activated-step]="paymentStepIsActive"
            (opened)="setStep(paymentStepIndex)"
            hideToggle
        >
            <mat-expansion-panel-header
                class="checkout-panel-header"
                style="height: 90px !important; width: calc(100% - 10px)"
            >
                <div class="checkout-header-img">
                    <img
                        src="assets/svg/rokshImages/icons/creditCard.svg"
                        class="expansion-header"
                    />
                </div>
                <div class="column w-100">
                    <mat-panel-title>
                        <strong class="w-100">
                            <div class="row ms-0">
                                <div class="col ps-0">
                                    {{ 'common.payment' | translate }}
                                </div>
                            </div>
                        </strong>
                    </mat-panel-title>
                </div>
                <img
                    src="assets/svg/valid.svg"
                    class="d-none"
                    style="margin: auto 10px; height: 1rem"
                />
            </mat-expansion-panel-header>

            <div class="row">
                <div
                    class="col-12 col-md-6"
                    *ngIf="
                        defaultPaymentProviders &&
                        defaultPaymentProviders.indexOf('BarionCard') > -1
                    "
                >
                    <label class="payment-pointer" for="Barion">
                        <div
                            class="m-1 p-1 payment-type-border container"
                            [ngClass]="{
                                active: paymentControl.value.trim() === 'BarionCard'
                            }"
                        >
                            <div class="row">
                                <div class="col payment-title ms-3 my-2">
                                    {{ 'checkout.online-credit-card' | translate }}
                                </div>
                                <div class="col-1 pe-4 pt-1">
                                    <input
                                        id="Barion"
                                        type="radio"
                                        value="BarionCard"
                                        formControlName="PaymentType"
                                        checked
                                    />
                                </div>
                            </div>

                            <div class="row ms-3">
                                <img
                                    [src]="
                                        siteImageUrl + 'logos/Payment-Creditcard-hu-1.png'
                                    "
                                    class="payment-provider-block-img"
                                    title=" Barion"
                                    alt=""
                                />

                                <mat-error
                                    *ngIf="isSubmitted && !paymentControl.value"
                                    style="font-size: 75%; margin-left: 24px"
                                >
                                    {{ 'checkout.payment-required' | translate }}
                                </mat-error>
                            </div>
                        </div>
                    </label>
                </div>

                <div
                    class="col-12 col-md-6"
                    *ngIf="
                        defaultPaymentProviders &&
                        defaultPaymentProviders.indexOf('RevolutCard') > -1
                    "
                >
                    <label class="payment-pointer" for="Revolut">
                        <div
                            class="m-1 p-1 payment-type-border container"
                            [ngClass]="{
                                active: paymentControl.value.trim() === 'RevolutCard'
                            }"
                        >
                            <div class="row">
                                <div class="col payment-title ms-3 my-2">
                                    {{ 'checkout.online-credit-card' | translate }}
                                </div>
                                <div class="col-1 pe-4 pt-1">
                                    <input
                                        id="Revolut"
                                        type="radio"
                                        value="RevolutCard"
                                        formControlName="PaymentType"
                                    />
                                </div>
                            </div>
                            <div class="row ms-3">
                                <img
                                    [src]="
                                        siteImageUrl + 'logos/Payment-Creditcard-at.png'
                                    "
                                    class="payment-provider-block-img"
                                    title=" Revolut"
                                    alt=""
                                />

                                <mat-error
                                    *ngIf="isSubmitted && !paymentControl.value"
                                    style="
                                        font-size: 75%;
                                        margin-left: 24px;
                                        padding-left: 0px;
                                    "
                                >
                                    {{ 'checkout.payment-required' | translate }}
                                </mat-error>
                            </div>
                        </div>
                    </label>
                </div>

                <div
                    class="col-12 col-md-6"
                    *ngIf="
                        defaultPaymentProviders &&
                        defaultPaymentProviders.indexOf('PayOnDeliveryCard') > -1
                    "
                >
                    <label class="payment-pointer" for="PayOnDelivery">
                        <div
                            class="m-1 p-1 payment-type-border container"
                            [ngClass]="{
                                active:
                                    paymentControl.value.trim() === 'PayOnDeliveryCard'
                            }"
                        >
                            <div class="row">
                                <div class="col payment-title ms-3 my-2">
                                    {{ 'checkout.cash-delivery-payment' | translate }}
                                </div>
                                <div class="col-1 pe-4 pt-1">
                                    <input
                                        id="PayOnDelivery"
                                        type="radio"
                                        value="PayOnDeliveryCard"
                                        formControlName="PaymentType"
                                    />
                                </div>
                            </div>

                            <div class="row ms-3">
                                <img
                                    *ngIf="isAtCountryCode"
                                    [src]="
                                        siteImageUrl +
                                        'logos/Payment-PayOnDelivery-at.png'
                                    "
                                    class="payment-provider-block-img"
                                    title="PayOnDelivery"
                                    alt=""
                                />

                                <img
                                    *ngIf="isHuCountryCode"
                                    [src]="
                                        siteImageUrl +
                                        'logos/Payment-PayOnDelivery-hu-1.png'
                                    "
                                    class="payment-provider-block-img"
                                    title="PayOnDelivery"
                                    alt=""
                                />

                                <mat-error
                                    *ngIf="isSubmitted && !paymentControl.value"
                                    style="font-size: 75%; margin-left: 24px"
                                >
                                    {{ 'checkout.payment-required' | translate }}
                                </mat-error>
                            </div>
                        </div>
                    </label>
                </div>
                <div
                    class="col-12 col-md-6"
                    *ngIf="
                        defaultPaymentProviders &&
                        defaultPaymentProviders.indexOf('PayOnDeliveryCash') > -1
                    "
                >
                    <label class="payment-pointer" for="CashOnDelivery">
                        <div
                            class="m-1 p-1 payment-type-border container"
                            [ngClass]="{
                                active:
                                    paymentControl.value.trim() === 'PayOnDeliveryCash'
                            }"
                        >
                            <div class="row">
                                <div class="col payment-title ms-3 my-2">
                                    {{ 'checkout.cash-on-delivery-payment' | translate }}
                                </div>
                                <div class="col-1 pe-4 pt-1">
                                    <input
                                        id="CashOnDelivery"
                                        type="radio"
                                        value="PayOnDeliveryCash"
                                        formControlName="PaymentType"
                                    />
                                </div>
                            </div>

                            <div class="row ms-3">
                                <img
                                    *ngIf="isAtCountryCode"
                                    [src]="
                                        siteImageUrl +
                                        'logos/Payment-CashOnDelivery-AT.png'
                                    "
                                    class="payment-provider-block-img"
                                    style="bottom: 15px"
                                    title="CashOnDelivery"
                                    alt=""
                                />

                                <img
                                    *ngIf="isHuCountryCode"
                                    [src]="
                                        siteImageUrl +
                                        'logos/Payment-CashOnDelivery-HU.png'
                                    "
                                    class="payment-provider-block-img"
                                    style="bottom: 15px"
                                    title="CashOnDelivery"
                                    alt=""
                                />

                                <mat-error
                                    *ngIf="isSubmitted && !paymentControl.value"
                                    style="font-size: 75%; margin-left: 24px"
                                >
                                    {{ 'checkout.payment-required' | translate }}
                                </mat-error>
                            </div>
                        </div>
                    </label>
                </div>
            </div>

            <lib-tip-list></lib-tip-list>

            <div class="col-12">
                <img src="{{ paymentProviderInfoImgSrc }}" style="max-width: 100%" />
            </div>

            <div class="clearfix"></div>

            <hr *ngIf="isAtCountryCode" />

            <div class="row col-12 message" *ngIf="isAtCountryCode">
                <p class="px-4">{{ 'checkout.legal-extra-text' | translate }}</p>
                <p>
                    {{ 'checkout.payment-message-1' | translate }}
                </p>

                <p>
                    {{ 'checkout.payment-message-3' | translate }}
                </p>
            </div>

            <div class="col-12 col-md-6 d-none">
                <br />

                <div class="float-start pe-2" style="padding-top: 2px">
                    <mat-checkbox formControlName="ToSAccepted"></mat-checkbox>
                </div>

                <div>
                    <div [innerHTML]="'common.accept-terms' | translate"></div>

                    <mat-error
                        *ngIf="isSubmitted && !toSControl.value"
                        style="font-size: 75%; margin-left: 24px"
                    >
                        {{ 'checkout.terms-required' | translate }}
                    </mat-error>
                </div>
            </div>

            <div class="col-12 col-md-6 d-none">
                <br />

                <div class="float-start pe-2" style="padding-top: 2px">
                    <mat-checkbox formControlName="PrivacyAccepted"></mat-checkbox>
                </div>

                <div>
                    <div [innerHTML]="'common.accept-data-protection' | translate"></div>

                    <mat-error
                        *ngIf="isSubmitted && privacyControl && !privacyControl.value"
                        style="font-size: 75%; margin-left: 24px"
                    >
                        {{ 'checkout.data-protection-required' | translate }}
                    </mat-error>
                </div>
            </div>

            <mat-action-row class="bg-gray d-flex flex-column">
                <label class="form-check w-100 pb-2">
                    <input
                        type="checkbox"
                        checked="checked"
                        formControlName="ToSAccepted"
                        class="checkbox form-check-input"
                        #conditionCheck_1
                    />&nbsp;
                    <a
                        [class.active]="conditionCheck_1.checked"
                        class="text-muted links"
                        href="{{
                            'static-links.roksh-terms'
                                | translate
                                | providerTheme : 'static-links.roksh-terms'
                                | async
                        }}"
                        target="_blank"
                        rel="noopener noreferrer"
                        >{{ 'checkout.i-accept-the-terms' | translate }}
                    </a>
                </label>
                <label class="form-check w-100">
                    <input
                        type="checkbox"
                        checked="checked"
                        formControlName="PrivacyAccepted"
                        class="checkbox form-check-input"
                        #conditionCheck_2
                    />&nbsp;
                    <a
                        class="text-muted links"
                        [class.active]="conditionCheck_2.checked"
                        href="{{
                            'static-links.roksh-data-protect'
                                | translate
                                | providerTheme : 'static-links.roksh-data-protect'
                                | async
                        }}"
                        target="_blank"
                        rel="noopener noreferrer"
                        >{{ 'checkout.i-accept-the-data-protect' | translate }}
                    </a>
                </label>
            </mat-action-row>

            <mat-action-row class="text-center border-0">
                <span
                    mat-button
                    class="col-12 col-md-6 m-auto m-md-0 btn btn-tovabb btn-rounded mb-2"
                    (click)="nextStep()"
                >
                    {{ 'common.next' | translate }}
                </span>
            </mat-action-row>
        </mat-expansion-panel>
    </div>
</div>
