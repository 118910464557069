import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { filter, startWith } from 'rxjs/operators';
import {
    AuthenticationService,
    ServiceBaseService,
    SessionService,
    UserBasketService,
    UserShared,
} from 'projects/library-shared/src/public-api';
import { CreateDialogService } from '../../services/create-dialog.service';
import { HomeService } from '../../services/home/home.service';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: [
        './user-menu.component.scss',
        '../category-menu/category-menu.component.scss',
    ],
})
export class UserMenuComponent implements OnInit, OnDestroy {
    user: UserShared;
    selectedProvider: string;
    providerNameToShow: string;
    selectedProviderName: string;
    siteImageUrl = this.baseService.getSiteImageUrl();
    selectedProviderId: number;
    selectedZip: string;
    activeURL = '';
    private routeSub: Subscription;

    constructor(
        private authService: AuthenticationService,
        private sessionService: SessionService,
        private baseService: ServiceBaseService,
        private homeService: HomeService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private userBasketService: UserBasketService,
        private translate: TranslateService,
        private location: Location,
        private createDialogService: CreateDialogService
    ) {}

    ngOnInit(): void {
        this.homeService.isUserMenuDisplayed.next(true);
        this.authService.getAuthStatusListener().subscribe((newAuthStatus) => {
            this.authService.checkServerLoggedInUser().subscribe((loggedInUser) => {
                if (!newAuthStatus && !loggedInUser) {
                    delete this.user;
                    if (ServiceBaseService.isProviderOwnWebShop()) {
                        this.translate.get('router.dashboard').subscribe((dashboard) => {
                            void this.router.navigate([dashboard]);
                        });
                    } else {
                        void this.router.navigate(['/']);
                    }
                }

                if (newAuthStatus) this.user = this.authService.getLoggedInUser();
            });
        });

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                startWith(this.router)
            )
            .subscribe((navEnd: NavigationEnd) => {
                this.activeURL = navEnd.url;
            });

        this.routeSub = this.activatedRoute.queryParams.subscribe((params) => {
            const providerName = params.providerName as string; //It comes from pagintation if we shop in a provider
            const providerId = params.providerId as number; //It comes only from shared basket link
            let userMasterBasketGUID = params.userMasterBasketGUID as string;
            if (providerId && userMasterBasketGUID) {
                userMasterBasketGUID =
                    userMasterBasketGUID.length !== 36
                        ? userMasterBasketGUID.substring(0, 36)
                        : userMasterBasketGUID;
                this.userBasketService
                    .getUserBasketDetails(providerId, userMasterBasketGUID)
                    .subscribe((shoppingList) => {
                        this.translate.get('router.dashboard').subscribe((dashboard) => {
                            if (!shoppingList.providerName)
                                shoppingList.providerName = 'aldi';
                            this.routeSub?.unsubscribe();
                            this.router
                                .navigate([shoppingList.providerName + '/' + dashboard])
                                .then(() => {
                                    this.createDialogService
                                        .openSingleUserBasketDialog(shoppingList)
                                        .subscribe(() => {
                                            this.afterClosingSingleUserBasketDialog();
                                        });
                                });
                        });
                    });

                return;
            }

            if (providerName) {
                this.selectedProviderName = providerName;

                this.sessionService.sessionSubject.subscribe((session) => {
                    if (session) {
                        this.selectedProvider = session.selectedShops[0];
                        this.providerNameToShow = session.selectedShopNamesToShow[0];
                        this.selectedProviderId = session.selectedShopIds[0];
                        this.selectedZip = session.zipCode;
                        this.sessionService.isProviderSelectedForShopping.next(true);
                    } else {
                        delete this.selectedProvider;
                        delete this.selectedProviderName;
                        delete this.selectedProviderId;
                    }
                });
            } else {
                this.sessionService.sessionSubject.subscribe((session) => {
                    if (session.availableShops.length === 1) {
                        const queryParams: Params = {
                            providerName: session.availableShops[0],
                        };

                        void this.router.navigate([], {
                            relativeTo: this.activatedRoute,
                            queryParams,
                            queryParamsHandling: 'merge',
                        });
                    } else {
                        delete this.selectedProvider;
                        delete this.selectedProviderName;
                        delete this.selectedProviderId;
                    }
                });
            }
        });
    }

    afterClosingSingleUserBasketDialog(): void {
        forkJoin([
            this.translate.get('router.user'),
            this.translate.get('router.shopping-list'),
            this.translate.get('router.dashboard'),
        ]).subscribe(([user, shoppingList, dashboard]: [string, string, string]) => {
            this.location.replaceState(user + '/' + shoppingList);
            if (this.user === null) {
                void this.router.navigate([dashboard]);
            }
        });
    }

    showAvailableTimeSlots() {
        this.createDialogService.showAvailableTimeSlotsDialog(
            this.sessionService.sessionSubject.getValue().selectedShopCodes[0],
            this.selectedProviderId
        );
    }

    onClickGiveAddress() {
        this.createDialogService.openAddressDialog().afterClosed().subscribe();
    }

    onLogOut(): void {
        this.authService.logout().subscribe(
            () => {
                delete this.user;
                if (ServiceBaseService.isProviderOwnWebShop()) {
                    this.translate.get('router.dashboard').subscribe((dashboard) => {
                        void this.router.navigate([dashboard]);
                    });
                } else {
                    void this.router.navigate(['/']);
                }
            },
            (err) => console.error(err)
        );
    }

    ngOnDestroy(): void {
        if (this.routeSub) this.routeSub.unsubscribe();
        this.homeService.isUserMenuDisplayed.next(false);
    }
}
