import { Component, OnDestroy, OnInit } from '@angular/core';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faInfoCircle, faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { FooterService } from './footer.service';
import { Observable, Subscription } from 'rxjs';
import {
    FrontendVersionDto,
    FrontendVersionService,
    PaymentService,
    ProviderFooterLegalInfoDTO,
    SessionService,
} from 'projects/library-shared/src/public-api';
import { HomeService } from '../../services/home/home.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
    isCategoryMenuDisplayed = true;
    isUserMenuDisplayed: boolean;

    constructor(
        private paymentService: PaymentService,
        private footerService: FooterService,
        private homeService: HomeService,
        private sessionService: SessionService,
        private frontendVersionService: FrontendVersionService
    ) {}

    isFooterFloating = false;
    isFooterDisplayed = true;
    footerClassName = '';

    defaultPaymentProviders: string[];
    providerLegalInfos: ProviderFooterLegalInfoDTO[];

    footerStatus: Subscription;

    faFacebook = faFacebook;
    faInstagram = faInstagram;
    faInfoCircle = faInfoCircle;
    faMinusSquare = faMinusSquare;
    frontendVersion$: Observable<FrontendVersionDto>;

    ngOnInit(): void {
        this.homeService.isCategoryMenuDisplayed.subscribe((isCategoryMenuDisplayed) => {
            this.isCategoryMenuDisplayed = isCategoryMenuDisplayed;
        });
        this.homeService.isUserMenuDisplayed.subscribe((isUserMenuDisplayed) => {
            this.isUserMenuDisplayed = isUserMenuDisplayed;
        });

        this.footerStatus = this.footerService
            .getFooterStatusListener()
            .subscribe((isFloat) => (this.isFooterFloating = isFloat));

        this.homeService.isFooterDisplayed.subscribe((value) => {
            this.isFooterDisplayed = value;
        });

        this.paymentService.paymentProviders.subscribe((providers) => {
            this.defaultPaymentProviders = providers;
        });

        this.sessionService.sessionSubject.subscribe((session) => {
            if (session && session.selectedShopIds.length === 1) {
                this.footerService
                    .getLegalInfos(session.selectedShopIds[0])
                    .subscribe((legalInfos) => {
                        this.providerLegalInfos = legalInfos;
                    });
            }
        });

        this.frontendVersion$ = this.frontendVersionService.getFrontedUrl();
    }

    onClickFloat() {
        this.isFooterFloating = this.isFooterFloating ? false : true;
        this.footerClassName = this.isFooterFloating ? 'footer-float' : '';
        this.footerService.changeFooterStatus();
    }

    ngOnDestroy(): void {
        if (this.footerStatus) {
            this.footerStatus.unsubscribe();
        }
    }
}
